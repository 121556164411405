import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { BorderedTextInput } from "../../HouseStyle/Components/Inputs";
import { ButtonBlue } from "../../HouseStyle/Components/Buttons";
import { ButtonLightGrey } from "../../HouseStyle/Components/Buttons";
import ToastError from "../../Toasts/ToastError";
import middleware from "../../Api/Middleware";
import ToastSuccess from "../../Toasts/ToastSuccess";
import ToastInfo from "../../Toasts/ToastInfo";
import { Toast } from "bootstrap/dist/js/bootstrap.bundle.min";
import { IoMdReturnLeft } from "react-icons/io";

const ChangePasswordModal = ({ user, setUser, show, close, reloadOnSubmit = true }) => {
    const [showPassModal, setShowPassModal] = useState(false);
    const [generatedPassword, setGeneratedPassword] = useState(false);
    const [userPassword, setUserPassword] = useState({
        password: "",
        repeated_password: "",
        current_password: "",
    });
    const [pendingUserPassword, setPendingUserPassword] = useState({
        password: "",
        repeated_password: "",
        current_password: ""
    });

    useEffect(() => {
        if (show) {
            setShowPassModal(true);
        }
    }, [show]);

    useEffect(() => {
        setPendingUserPassword(userPassword);
    }, [userPassword]);

    const closePassModal = () => {
        setShowPassModal(false);
        setUserPassword({});
        setPendingUserPassword({});
        close(true);
    }

    const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\S]{8,}$/;

    const generateRandomPassword = async (event) => {
        event.preventDefault();

        const length = 15; // Minimum length is 8, so 15 is sufficient
        const lowercase = "abcdefghijklmnopqrstuvwxyz";
        const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const digits = "0123456789";
        const specialChars = "!@#$%^&*";

        // Ensure each type is included at least once
        let password = [
            lowercase[Math.floor(Math.random() * lowercase.length)],
            uppercase[Math.floor(Math.random() * uppercase.length)],
            digits[Math.floor(Math.random() * digits.length)],
            specialChars[Math.floor(Math.random() * specialChars.length)],
        ];

        // Fill the rest of the password
        const allChars = lowercase + uppercase + digits + specialChars;
        while (password.length < length) {
            password.push(allChars[Math.floor(Math.random() * allChars.length)]);
        }

        // Shuffle the password to ensure randomness
        password = password.sort(() => Math.random() - 0.5).join("");

        // Update state
        setUserPassword((prev) => {
            return {
                ...prev,
                password: password,
            }
        });
        setGeneratedPassword(true);

        try {
            await navigator.clipboard.writeText(password);
            ToastInfo(
                "Wachtwoord gekopieerd naar clipboard. Vergeet niet je wachtwoord ergens op te slaan in bijvoorbeeld een passwordmanager.",
                7500
            );
        } catch (err) {
            console.error("Failed to copy password: ", err);
        }
    };

    const updatePassword = async () => {
        if (userPassword.current_password === "" || userPassword.current_password === undefined) {
            ToastError("Vul je huidige wachtwoord in");
            return;
        }

        if (userPassword.password !== userPassword.repeated_password) {
            ToastError("Herhaald wachtwoord komt niet overeen.");
            return;
        }

        if (!passwordRegex.test(userPassword.password)) {
            ToastError(
                "Wachtwoord moet minimaal 8 tekens bevatten, inclusief hoofdletters, kleine letters, een cijfer en een speciaal teken."
            );
            return;
        }

        try {
            const response = await middleware.post("change-password", {
                old_password: userPassword.current_password,
                new_password: userPassword.password,
                new_password_confirmation: userPassword.repeated_password
            })

            ToastSuccess("Het wachtwoord is veranderd!");
            closePassModal();

        } catch (err) {
            const error_message = err.response.data;
            console.error(err);
            ToastError(error_message[Object.keys(error_message)[0]]);
            return;
        }
    };
    console.log(userPassword)
    return (
        <Modal
            id="modalBlueprint"
            className="passModal"
            show={showPassModal}
            onHide={() => closePassModal()}
            size="lg"
            centered
            backdrop="static"
        >
            <Modal.Header className="modalTop modalChangePassword">
                <div className="d-flex flex-row justify-content-between w-100">

                    <h3 className="ms-3 text-nowrap">Wachtwoord wijzigen</h3>
                    <button className="btn" onClick={closePassModal}>
                        <FontAwesomeIcon className="closeModal" icon={faXmark} />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className="modalContent pt-0">
                <div className="innerBox d-flex flex-column gap-2">
                    <div className="d-flex flex-row">
                    </div>

                    <div className="d-flex flex-column gap-4">
                        <div className="d-flex flex-column w-100 gap-4">
                            <BorderedTextInput
                                label="Huidig wachtwoord"
                                size="l"
                                updateWaitTimer={250}
                                updateFunction={() =>
                                    setUserPassword((prev) => ({
                                        ...prev,
                                        current_password: pendingUserPassword.current_password,
                                    }))
                                }
                                inputValue={pendingUserPassword.current_password}
                                setInputValue={(value) =>
                                    setPendingUserPassword((prev) => ({
                                        ...prev,
                                        current_password: value,
                                    }))
                                }
                            />
                            <div className="d-flex flex-row gap-4">
                                <BorderedTextInput
                                    label="Nieuw wachtwoord"
                                    size="l"
                                    updateWaitTimer={250}
                                    updateFunction={() =>
                                        setUserPassword((prev) => ({
                                            ...prev,
                                            password: pendingUserPassword.password,
                                        }))
                                    }
                                    inputValue={pendingUserPassword.password}
                                    setInputValue={(value) =>
                                        setPendingUserPassword((prev) => ({
                                            ...prev,
                                            password: value,
                                        }))
                                    }
                                />
                                <BorderedTextInput
                                    label="Herhaal wachtwoord"
                                    size="l"
                                    updateWaitTimer={250}
                                    updateFunction={() =>
                                        setUserPassword((prev) => ({
                                            ...prev,
                                            repeated_password: pendingUserPassword.repeated_password,
                                        }))
                                    }
                                    inputValue={pendingUserPassword.repeated_password}
                                    setInputValue={(value) =>
                                        setPendingUserPassword((prev) => ({
                                            ...prev,
                                            repeated_password: value,
                                        }))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modalBottom px-2">
                <div className="mx-3 my-0">
                    <div className="d-flex gap-2 w-100">
                        <ButtonLightGrey
                            size="s"
                            text={"Willikeurig wachtwoord"}
                            onClickFunction={generateRandomPassword}
                        />
                        <ButtonBlue
                            size="l"
                            text={"Wijzig wachtwoord"}
                            onClickFunction={updatePassword}
                        />
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangePasswordModal;