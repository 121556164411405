import React, { useState } from "react";
import { BorderedDropDown } from "../../HouseStyle/Components/DropDowns";


const Dropdownfilter = ({ setFilters, filter }) => {
    const setFilterValue = (e) => {
        setFilters((filters) => {
            const newFilters = [...filters];
            newFilters[filters.findIndex((e) => e.index == filter.index)].value = e?.target?.value ?? e;
            return newFilters
        })
    }
    console.log(filter);
    return <div className="d-flex w-100 h-100">
        <div className="bg-white rounded-4 p-4 h-100 border border-gray w-100">
            <h6 className="fw-bold">{filter.name}</h6>
            <BorderedDropDown selectedValue={String(filter.value)} setSelectedValue={setFilterValue} options={filter.options.map(e => {return {title: e.name, value: String(e.value)}})} />
        </div>
    </div>
}

export default Dropdownfilter;