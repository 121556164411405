import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  faPencil,
  faPlus,
  faX,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import middleware from "../../../Api/Middleware";
import { useParams } from "react-router";
import ToastError from "../../../Toasts/ToastError";
import { BorderedTextInput } from "../../../HouseStyle/Components/Inputs";
import { BorderedDropDown } from "../../../HouseStyle/Components/DropDowns";
import { ButtonBlue } from "../../../HouseStyle/Components/Buttons";
import { PointOfSale } from "@mui/icons-material";

const RemovePointsModal = ({
  openRemovePoints,
  closeRemovePoints,
  setUpdatedLoyalty,
  calculateTotalPoints,
}) => {
  const [showRemovePoints, setShowRemovePoints] = useState(false);
  const [points, setPoints] = useState("");
  const [reasons, setReasons] = useState([]);
  const [reason, setReason] = useState(0);
  const [newReason, setNewReason] = useState(null);
  const [editReason, setEditReason] = useState(null);
  const [addNewReason, setAddNewReason] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { internal_id } = useParams();
  const fetchAll = async () => {
    const reasonsResponse = await middleware.get(
      "/loyaltypointreasons?type=1&base=1"
    );
    setReasons(reasonsResponse.data.data);
  };

  const clearFields = () => {
    setPoints("");
    setAddNewReason(false);
    setReason("");
    setNewReason("");
  }

  useEffect(() => {
    setTotalPoints(calculateTotalPoints());
  }, []);

  useEffect(() => {
    if (parseInt(points) > totalPoints) {
      ToastError("Het totale puntenaantal kan niet negatief zijn");
    }
  }, [points]);

  useEffect(() => {
    if (openRemovePoints) {
      setShowRemovePoints(true);
    }
  }, [openRemovePoints]);

  const closeRemovePointsModal = () => {
    setShowRemovePoints(false);
    clearFields();
    closeRemovePoints(true);
  };
  useEffect(() => {
    if (openRemovePoints) {
      fetchAll();

      setShowRemovePoints(true);
    }
  }, [openRemovePoints]);
  useEffect(() => {
    if (reason == "overig" || reason == "overig") {
      setAddNewReason(true);
    } else {
      setAddNewReason(false);
    }
  }, [reason]);
  const submitRemovePoints = async (event) => {


    if (addNewReason && newReason == "") {
      ToastError("Vul een nieuwe reden in.");
      return;
    }

    if (reasons.some(r => r.reason === newReason)) {
      ToastError(`${newReason} bestaat al, selecteer het via de dropdown`);
      return;
    }

    if (points < 1) {
      ToastError("Je mag niet meer dan 1 miljoen punten verwijderen.");
      return;
    }
    if (points > 1000000) {
      ToastError("Je mag niet meer dan 1 miljoen punten verwijderen.");
      return;
    }
    try {
      if (event) {
        event.preventDefault();
      }
      // Convert points to a string with a plus sign
      const pointsWithMin = `-${points}`;
      if (addNewReason) {
        let res;
        res = await middleware.post("loyaltypointreasons", {
          reason: newReason,
          type: 1,
        });
        let reason_id = res.data.reason_id;
        await middleware
          .post("loyaltymutations", {
            internal_id: internal_id,
            amount: pointsWithMin, // Send points with a plus sign
            reason_id: reason_id,
          })
          .then(() => setUpdatedLoyalty(true) + closeRemovePointsModal());
      } else {
        await middleware
          .post("loyaltymutations", {
            internal_id: internal_id,
            amount: pointsWithMin, // Send points with a plus sign
            reason_id: reason,
          })
          .then(() => setUpdatedLoyalty(true) + closeRemovePointsModal());
      }
      setAddNewReason(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      id="modalBlueprint"
      show={showRemovePoints}
      onHide={() => closeRemovePointsModal()}
      className="colormodals"
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header className="modalTop ps-5 pt-5 justify-content-between">
        <h3>Punten verwijderen</h3>
        <button
          variant="transparent"
          className=" btn"
          onClick={() => closeRemovePointsModal()}
        >
          <FontAwesomeIcon icon={faXmark} className="closeModal" />
        </button>
      </Modal.Header>
      <Modal.Body className="modalCenter" style={{ height: "300px" }}>
        <div className="col-12">
          <div className="d-flex flex-column col-4 mb-3">
            <div className="d-flex align-items-center">
              <BorderedTextInput
                label={"Punten verwijderen"}
                inputValue={points}
                setInputValue={(value) => {
                  const reg = /^$|^[1-9][0-9]*$/;
                  const newValue = value;
                  if (newValue > totalPoints) ToastError(`Totale punten kan niet negatief zijn. Puntentotaal is nu ${totalPoints}`);
                  if (reg.test(newValue) && newValue <= totalPoints) {
                    setPoints(newValue);
                  }
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-2 col-12">
            <label className="mb-2 fw-medium">
              Reden
              <span className="ms-2 p-2" role="button"></span>
            </label>
            <BorderedDropDown
              selectedValue={reason}
              placeholder="Kies een reden"
              setSelectedValue={(value) => {
                setNewReason("");
                setReason(value, "reason")
              }
              }
              options={reasons.length > 0 ?
                [
                  ...reasons.map((reason, index) => ({
                    key: index,
                    value: reason.reason_id,
                    title: reason.reason,
                  })),
                  {
                    value: "overig",
                    title: "Overig",
                  }
                ] :
                [{
                  value: "overig",
                  title: "Overig",
                }]
              }
            />
            {addNewReason && (
              <BorderedTextInput
                placeholder="Vul een reden in"
                inputValue={newReason}
                setInputValue={(value) => setNewReason(value, "newReason")}
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottom">
        <div className="d-flex">
          <ButtonBlue
            size="xs"
            text={"Opslaan"}
            disabled={
              (reason == 0 && newReason === "") || points == "" || (reason == "overig" && newReason === "") || parseInt(points) > totalPoints
            }
            onClickFunction={(value) => submitRemovePoints(value)}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RemovePointsModal;
