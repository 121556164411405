import React, { useEffect, useMemo, useState, useCallback } from 'react';
import salesChannelsApi from '../Api/SalesChannels';
import PageTemplate from '../Templates/PageTemplate';
import TablePreset from '../Table/TablePreset';
import CheckboxHeader from '../Table/TableComponents/CheckboxHeader';
import CheckboxCell from '../Table/TableComponents/CheckboxCell';
import { useTable } from 'react-table';
import SortableHeader from '../Table/TableComponents/SortableHeader';
import TDSTextNavigation from '../Templates/TDSTextNavigation';
import { useNavigate } from 'react-router';

const SalesChannels = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [salesChannels, setSalesChannels] = useState([]);

    const [selectedIds, setSelectedIds] = useState([]);
    const [searchBar, setSearchBar] = useState("");
    const [pagination, setPagination] = useState({
        "page_size": 10,
        "page": 1,
        "max_items": 0,
        "begin_of_page": 0,
        "end_of_page": 0,
    });
    const [order, setOrder] = useState({ orderBy: '', direction: '' });

    const fetchAll = async () => {
        setLoading(false);
        setTableLoading(true);
        try {
            const searchQuery = searchBar.trim() ? `&searchTerm=${encodeURIComponent(searchBar.trim())}` : "";
            const res = await salesChannelsApi.get(`sp/salesChannelsTableAll?page=${pagination.page}&page_size=${pagination.page_size}${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}` : ''}${searchQuery}`);
            setSalesChannels(res.data.data);
            setPagination({ ...pagination, "max_items": res.data.total, "begin_of_page": res.data.from, "end_of_page": res.data.to });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setTableLoading(false);
    }

    useEffect(() => {
        fetchAll();
    }, [pagination.page, pagination.page_size, order.orderBy, order.direction, searchBar]);

    const handleSearchChange = useCallback((value) => {
        setSearchBar(value);
        setPagination(prev => ({ ...prev, page: 1 }));
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: (
                    <CheckboxHeader currentItem={salesChannels} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'sales_channel_id'}>
                    </CheckboxHeader>
                ),
                accessor: "select",
                Cell: ({ row }) => {
                    return <CheckboxCell row={row} selectedIds={selectedIds} pk={'sales_channel_id'} />
                }
            },
            {
                Header: () => (
                    <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field="name">
                        Verkoopkanaal
                    </SortableHeader>
                ),
                accessor: 'sales_channel_name',
                Cell: ({ row }) => {
                    return <TDSTextNavigation text={row.original.sales_channel_name} link={`${row.original.sales_channel_id}`}/>
                }
            },
            {
                Header: () => (
                    <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field="name">
                        Type
                    </SortableHeader>
                ),
                accessor: 'type',
            },
            {
                Header: () => (
                    <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field="name">
                        Status
                    </SortableHeader>
                ),
                accessor: 'status',
            },
        ],
        [order.orderBy, order.direction, selectedIds, setSelectedIds, salesChannels]
    );

    const tableInstance = useTable({ columns, data: salesChannels });

    const handleSelection = (salesChannelId, isSelected) => {
        setSelectedIds(previous =>
            isSelected ? [...previous, salesChannelId] : previous.filter(id => id !== salesChannelId)
        );
    };

    const Action = (methode) => {
        if (methode == 'Select All') {
            console.log('true');
            setSelectedIds([...selectedIds, ...salesChannels.filter(sc => !selectedIds.includes(sc.sales_channel_id)).map(sc => sc.sales_channel_id)]);
        }
        else if (methode == 'De-select All') {
            setSelectedIds([]);
        }
    }

    return (
        <PageTemplate navbarTitle={"Pos HUB"} pageIsLoading={loading}>
            <div className="w-100">
                <TablePreset
                    data={[{ 'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'sales_channel_id' }]}
                    setPagination={setPagination}
                    tableLoading={tableLoading}
                    handleSelection={handleSelection}
                    searchBar={[{ shown: true, searchFunction: handleSearchChange, placeholder: "Zoeken op verkoopkanaal, type, etc." }]}
                    actionOptions={[{ title: "Selecteer alles", function: () => Action('Select All') }, { title: "De-selecteer alles", function: () => Action('De-select All') }]}
                    leftButtons={[{ title: "Filteren", function: () => {} }]}
                    rightButtons={[{ title: "Nieuw verkoopkanaal", function: () => {navigate('nieuw')} }]}
                />
            </div>
        </PageTemplate>
    );
}

export default SalesChannels;