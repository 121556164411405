import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { debounce, max } from "lodash";
import middleware from "../Api/Middleware";
import saleschannels from "../Api/SalesChannels";
import PageTemplate from "../Templates/PageTemplate";
import Backbutton from "../Products/subpages/images/BackIcon.svg";
import CustomerComponent from "./Components/CustomerComponent";
import ToastSuccess from "../Toasts/ToastSuccess";
import ToastError from "../Toasts/ToastError";
import "./Orders.css";
import "../Table/Table.css";
import ToolTip from "./Components/ToolTipComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faSearch } from "@fortawesome/free-solid-svg-icons";
import ItemNoteModalComponent from "./Components/ItemNoteModalComponent";
import { ButtonBlue, ButtonDarkBlue } from "../HouseStyle/Components/Buttons";
import { hourglass } from "ldrs";

const MAX_NOTE_LENGTH = 5000;
const MAX_REFERENCE_LENGTH = 500;
const OrdersNew = () => {
  /*
   [[ Use Effects ]]
  */

  // Navigation
  const navigate = useNavigate();
  hourglass.register()
  const [loading, setLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);

  // Customer info
  const [customer, setCustomer] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [searchParams] = useSearchParams();
  const referredCustomerId = searchParams.get('u');

  // Sales info
  const [allSaleschannels, setAllSaleschannels] = useState([]);
  const [activeSaleTypeVerzend, setActiveSaleTypeVerzend] = useState(1);
  const [salesChannel, setSalesChannel] = useState(1);
  const [orderProcessing, setOrderProcessing] = useState("2");
  const [status, setStatus] = useState("0");

  // Order information
  const [reference, setReference] = useState('');
  const [orderInfo, setOrderInfo] = useState({
    note: '',
    noteError: ''
  });

  // Order items and discounts
  const [orderItems, setOrderItems] = useState([]);
  const [productSearch, setProductSearch] = useState('');
  const [products1, setProducts1] = useState([]);
  const [orderDiscount, setOrderDiscount] = useState(null);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalExclTax, setTotalExclTax] = useState(0);
  const [totalInclTax, setTotalInclTax] = useState(0);
  const [maxAmount, setMaxAmount] = useState(false);

  // Shipping information
  const [shippingCost, setShippingCost] = useState(0.00);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState(null);

  // Total and tax
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);

  // Modals and ui states
  const dropdownRef = useRef(null);
  const [itemNoteModalVisible, setItemNoteModalVisible] = useState(false);
  const [itemNoteProduct, setItemNoteProduct] = useState(null);

  /*
   [[ Functions ]]
  */
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [invoiceAddress, setInvoiceAddress] = useState(null);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    }).format(price);
  };

  // Fetch data initial dynamic data like sale channels 
  const fetchInitialData = async () => {
    const [saleChannelsResponse] = await Promise.all([
      saleschannels.get(`saleschannels`),
    ]);

    setAllSaleschannels(saleChannelsResponse.data);
  };

  const getReferredCustomer = async (internal_id) => {
    try {
      if (customer?.internal_id == undefined && internal_id != undefined) {
        if (Array.from(internal_id)[0] == "C") { // Check of consumer een consument of business is door de eerst character van de internal_id te lezen
          const customerResponse = await middleware.get(`consumers?internal_id=${internal_id}`);
          setCustomer(customerResponse.data.data[0]);
        } else if (Array.from(internal_id)[0] == "B") {
          const customerResponse = await middleware.get(`businesses?internal_id=${internal_id}`);
          setCustomer(customerResponse.data.data[0]);
        } else return;
        await fetchCustomerData(internal_id);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getReferredCustomer(referredCustomerId);
    fetchInitialData();
  }, []);

  // Fetch data from the selected customer
  const fetchCustomerData = async (internal_id) => {
    try {
      setLoadingCustomer(true);
      const [
        addressResponse,
        emailResponse,
        phoneNumberResponse,
        countryCodesResponse
      ] = await Promise.all([
        middleware.get(`addresses?internal_id=${internal_id}`),
        middleware.get(`emails?internal_id=${internal_id}`),
        middleware.get(`phonenumbers?internal_id=${internal_id}`),
        middleware.get(`countryCodes`),
      ]);
      console.log(addressResponse);


      if (addressResponse.data.data.length > 0) {
        addressResponse.data.data.forEach((address) => {
          const countryCode = countryCodesResponse.data.data.find(code => code.country_code_id === address.country_code_id);
          address.country = countryCode;

          (address.address_kind === 0 || address.address_kind === 2) ? setDeliveryAddress(address) : setInvoiceAddress(address);
        });
      }

      setAddresses(addressResponse.data.data);
      setEmails(emailResponse.data.data);
      setPhoneNumbers(phoneNumberResponse.data.data);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    } finally {
      setLoadingCustomer(false);
    }

  };

  // Fetch products based on the search input from the user
  const debouncedFetchProducts = useCallback(
    debounce(async (searchTerm) => {
      try {
        const response = await middleware.get(`/sp/productsTableAll?page=1&page_size=10&LIKE=true&searchTerm=${searchTerm}&noSearch=["product_id","image_path","incl_tax","excl_tax","stock","barcode","created_at","status"]`);
        const products = response.data.data || [];

        const validProducts = products.filter(({ excl_tax, incl_tax }) =>
          excl_tax !== null && excl_tax !== 0 &&
          incl_tax !== null && incl_tax !== 0
        );

        setProducts1(validProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }, 500),
    []
  );

  // The function that gets fired from the CustomerComponent to set the selected customer and fetch their data
  const handleCustomerSelection = async (selectedCustomer) => {
    setCustomer(selectedCustomer);
    await fetchCustomerData(selectedCustomer.internal_id);
  };

  //  When the search input changes, we debounce a fetch after 0.5s
  useEffect(() => {
    if (productSearch) {
      debouncedFetchProducts(productSearch);
    } else {
      setProducts1([]);
    }
  }, [productSearch, debouncedFetchProducts]);

  // Selecting a product from the search list
  const handleProductSelection = (product) => {
    const stockQuantity = parseInt(product.stock);

    const existingItem = orderItems.find(item => item.id === product.product_id);
    if (existingItem) {
      if (existingItem.quantity >= stockQuantity) {
        ToastError(`Maximum voorraad (${stockQuantity}) bereikt voor "${existingItem.name}"`);
        return;
      }
      updateQuantity(product.product_id, existingItem.quantity + 1);
      ToastSuccess(`Added "${existingItem.name}" x ${existingItem.quantity + 1}`);
    } else {

      const newItem = {
        id: product.product_id,
        name: product.product_name,
        price: parseFloat(product.incl_tax),
        sale_price: parseFloat(product.sale_incl_tax),
        price_excl_tax: parseFloat(product.excl_tax),
        sale_price_excl_tax: parseFloat(product.sale_excl_tax),
        quantity: 1,
        stock: stockQuantity,
        discount: null,
        discountType: '€'
      };
      setOrderItems(prevItems => [...prevItems, newItem]);
      ToastSuccess(`Added "${newItem.name}" x 1`);
    }

    setProductSearch('');
    setProducts1([]);
  };

  // Increase or decrease quantity of the products
  const updateQuantity = (id, newQuantity) => {
    const numericValue = parseFloat(newQuantity);
    if (!isNaN(numericValue) && numericValue > 0) {
      setOrderItems((prevItems) =>
        prevItems.map((item) => {
          if (item.id === id) {
            if (numericValue > item.stock) {
              ToastError(`Maximum voorraad voor dit product is bereikt.`);
              setMaxAmount(true);
            } else {
              setMaxAmount(false);
            }
            return { ...item, quantity: numericValue };
          }
          return item;
        })
      );
    }
    calculateTotals();
  };

  // Update discount based on the discount type
  const updateDiscount = (id, newDiscount, newDiscountType) => {
    setOrderItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            discount: parseFloat(newDiscount) || null,
            discountType: newDiscountType,
          };
        }
        return item;
      })
    );
  };

  // Calculate discounted price of item
  function calculateDiscountedPrice(item) {
    // baseprice: sale or regular price
    const basePrice = item.sale_price > 0 ? item.sale_price : item.price;
  
    if (item.discountType === "€") {
      if (item.discount >= basePrice) {
        return 0;
      }
      return Math.max(0, basePrice - item.discount);
    } else {
      if (item.discount >= 100) {
        return 0;
      }
      return Math.max(0, basePrice * (1 - item.discount / 100));
    }
  }

  // Total price of all items together with discount
  function calculateRowTotal(item) {
    const discountedPrice = calculateDiscountedPrice(item);
    return discountedPrice * item.quantity;
  }

  // Discount cell with € and % buttons
  const renderDiscountCell = (item) => (
    <td>
      <div className="d-flex align-items-center">
        <div className="d-flex">
          <button
            type="button"
            className={`euroSaleButton p-2 fw-semibold ${item.discountType === "€" ? "activeSaleType" : ""
              }`}
            onClick={() => updateDiscount(item.id, item.discount, "€")}
          >
            €
          </button>
          <button
            type="button"
            className={`percentageSaleButton p-2 fw-semibold ${item.discountType === "%" ? "activeSaleType" : ""
              }`}
            onClick={() => updateDiscount(item.id, item.discount, "%")}
          >
            %
          </button>
        </div>
        <input
          className="p-2 mx-2 inputSaleOrder text-center fw-semibold"
          placeholder="0"
          step="any"
          type="number"
          min={0}
          value={item.discount}
          onChange={(e) =>
            updateDiscount(item.id, e.target.value, item.discountType)
          }
          onBlur={(e) =>
            handleDiscountBlur(item.id, e.target.value, item.discountType)
          }
        />
      </div>
    </td>
  );

  // Total price
  const updateTotal = (type, value, isShipping = false) => {
    // Disable extra discount if subtotal is 0
    if (subtotal === 0) {
      setOrderDiscount(0);
      setShippingCost(0);
      return;
    }

    const numericValue = parseFloat(value);

    if (isShipping) {
      if (selectedShippingMethod === "pickup") {
        setShippingCost(0);
        return;
      }

      if (type === "€") {
        setShippingCost(numericValue);
      } else if (type === "%") {
        const percentValue = Math.min(numericValue, 100);
        const newShippingCost = (subtotal * percentValue) / 100;
        setShippingCost(newShippingCost);
      }
    } else {
      if (type === "€") {
        const maxDiscount = subtotal - calculateTotalProductDiscounts();
        setOrderDiscount(Math.min(numericValue, maxDiscount));
        setActiveSaleTypeVerzend(1);
      } else if (type === "%") {
        setOrderDiscount(Math.min(numericValue, 100));
        setActiveSaleTypeVerzend(2);
      }
    }

    calculateTotals();
  };

  const calculateTotalProductDiscounts = () => {
    return orderItems.reduce((sum, item) => {
      const originalTotal = item.price * item.quantity;
      const discountedTotal = calculateRowTotal(item);
      return sum + (originalTotal - discountedTotal);
    }, 0);
  };

  const handleShippingCostChange = (value) => {
    if (value === "") {
      setShippingCost("");
      return;
    }
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      setShippingCost(numericValue);
    }
    calculateTotals();
  };

  const handleOrderDiscountChange = (value) => {
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      if (activeSaleTypeVerzend === 1) {
        setOrderDiscount(Math.max(0, Math.min(numericValue, subtotal)));
      } else {
        // Percentage
        setOrderDiscount(Math.max(0, Math.min(numericValue, 100)));
      }
    } else {
      setOrderDiscount(null);
    }
    calculateTotals();
  };

  const handleDiscountBlur = (id, value, discountType) => {
    setOrderItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === id) {
          let clampedValue = parseFloat(value) || null;

          if (clampedValue != null) {
            if (discountType === "€") {
              clampedValue = Math.max(0, Math.min(clampedValue, item.price));
            } else {
              clampedValue = Math.max(0, Math.min(clampedValue, 100));
            }
          }

          return {
            ...item,
            discount: clampedValue,
            discountType: discountType,
          };
        }
        return item;
      })
    );
  };
console.log("orderItems", orderItems);
  const handleOrderDiscountBlur = (value) => {
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      if (activeSaleTypeVerzend === 1) {
        setOrderDiscount(Math.max(0, Math.min(numericValue, subtotal)));
      } else {
        setOrderDiscount(Math.max(0, Math.min(numericValue, 100)));
      }
    } else {
      setOrderDiscount(null);
    }
    calculateTotals();
  };

  const removeItem = (id) => {
    setOrderItems((prevItems) => prevItems.filter((item) => item.id !== id));
    calculateTotals();
  };

  useEffect(() => {
    const totaalProducten = orderItems.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
    const totaalKorting = calculateTotalProductDiscounts();
    const finalTotal = Math.max(0, totaalProducten - totaalKorting);

    setSubtotal(totaalProducten);
    setTotalDiscount(totaalKorting);
    setTotalInclTax(finalTotal);
  }, [
    orderItems,
    orderDiscount,
    activeSaleTypeVerzend,
    shippingCost,
    selectedShippingMethod,
  ]);

  const handleNoteChange = (e) => {
    const value = e.target.value;
    if (value.length <= MAX_NOTE_LENGTH) {
      setOrderInfo({
        ...orderInfo,
        note: value,
        noteError: "",
      });
    } else {
      setOrderInfo({
        ...orderInfo,
        noteError: `Notitie mag niet langer zijn dan ${MAX_NOTE_LENGTH} tekens`,
      });
      ToastError(`Notitie mag niet langer zijn dan ${MAX_NOTE_LENGTH} tekens`);
    }
  };

  useEffect(() => {
    calculateTotals();
  }, [
    shippingCost,
    subtotal,
    totalDiscount,
    totalExclTax,
    totalInclTax,
    orderDiscount,
    orderItems,
  ]);

  const calculateTotals = useCallback(() => {
    const productTotal = orderItems.reduce((sum, item) => {
      const discountedPrice = calculateDiscountedPrice(item);
      return sum + discountedPrice * item.quantity;
    }, 0);

    const extraDiscountAmount =
      activeSaleTypeVerzend === 1
        ? Math.min(parseFloat(orderDiscount) || 0, productTotal)
        : (productTotal * Math.min(parseFloat(orderDiscount) || 0, 100)) / 100;

    const amountAfterExtraDiscount = Math.max(
      0,
      productTotal - extraDiscountAmount
    );

    const shippingCostValue =
      selectedShippingMethod === "pickup" ? 0 : parseFloat(shippingCost) || 0;

    const subtotalAmount = amountAfterExtraDiscount + shippingCostValue;

    const exclBTW = subtotalAmount / 1.21;
    const btwAmount = subtotalAmount - exclBTW;

    setSubtotal(productTotal);
    setTotalDiscount(extraDiscountAmount);
    setTotalExclTax(exclBTW);
    setTotalInclTax(subtotalAmount);
    setTax(btwAmount);
  }, [
    orderItems,
    orderDiscount,
    activeSaleTypeVerzend,
    shippingCost,
    selectedShippingMethod,
  ]);

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    calculateTotals();
  }, [
    orderItems,
    orderDiscount,
    activeSaleTypeVerzend,
    shippingCost,
    calculateTotals,
  ]);

  const handleReferenceChange = (e) => {

    const value = e.target.value;
    if (value.length <= MAX_REFERENCE_LENGTH) {
      setReference(value);
    } else {
      ToastError(
        `Referentie mag niet langer zijn dan ${MAX_REFERENCE_LENGTH} tekens`
      );
    }
  };

  const NewOrder = async (submitType) => {
    if (orderInfo?.note?.length > MAX_NOTE_LENGTH) {
      ToastError(`Notitie mag niet langer zijn dan ${MAX_NOTE_LENGTH} tekens`);
      return;
    }

    if (reference.length > MAX_REFERENCE_LENGTH) {
      ToastError(
        `Referentie mag niet langer zijn dan ${MAX_REFERENCE_LENGTH} tekens`
      );
      return;
    }

    if (orderItems.length === 0) {
      ToastError("Voeg eerst producten toe aan de bestelling");
      return;
    }

    if (!customer.internal_id) {
      ToastError("Selecteer eerst een klant");
      return;
    }

    if (!selectedShippingMethod) {
      ToastError("Selecteer eerst een verzendmethode");
      return;
    }

    if (!salesChannel) {
      ToastError("Selecteer eerst een verkooplocatie");
      return;
    }

    if (totalInclTax <= 0) {
      ToastError("Totaal inclusief BTW moet groter of gelijk aan 0 zijn");
      return;
    }

    if (!orderProcessing || !status) {
      ToastError("Graag de bestelling status en verzend status invullen!");
      return;
    }

    const orderData = {
      internal_id: customer.internal_id,
      sales_channel_id: parseInt(salesChannel),
      order_status: parseInt(orderProcessing),
      note: orderInfo.note,
      total_price_incl_tax: totalInclTax,
      total_price_excl_tax: totalExclTax,
      sale_amount: orderDiscount ?? 0,
      sale_unit: activeSaleTypeVerzend - 1,
      payment_status: 1,
      delivery_status: parseInt(status),
      delivery_cost: shippingCost,
      delivery_address_id: orderInfo.delivery_address_id,
      invoice_address_id: orderInfo.invoice_address_id,
      email_address_id: orderInfo.email_address_id,
      phone_number_id: orderInfo.phone_number_id,
      order_sold_products: orderItems.map((item) => ({
        product_id: item.id,
        amount: item.quantity,
        product_name: item.name,
        product_description: item.product_description,
        incl_tax: item.price,
        excl_tax: item.price_excl_tax,
        sale_incl_tax: item.sale_price,
        sale_excl_tax: item.sale_price_excl_tax,
        sale_amount: item.discount == null ? 0 : item.discount,
        sale_unit: item.discountType === "%" ? 0 : 1,
      })),
      reference: reference,
    };

    try {
      setLoading(true);

      const orderResponse = await middleware.post("orders", orderData);

      ToastSuccess(`Bestelling ${orderResponse.data.order_number} aangemaakt`);

      const invoiceData = {
        internal_id: customer.internal_id,
        order_number: orderResponse.data.order_number,
        price: totalInclTax,
        note: orderInfo.note,
        billed_on: new Date().toISOString(),
        status: submitType === "withInvoice" ? 1 : 0,
      };

      const invoiceResponse = await middleware.post("invoices", invoiceData);

      if (submitType === "withInvoice") {
        console.log(orderResponse);

        ToastSuccess(`Factuur verstuurd voor ${orderResponse.data.order_number}`);
        console.log(orderResponse.data.order_number);
      } else {
        console.log(orderResponse);

        ToastError(`Nog geen factuur verstuurd voor ${orderResponse.data.order_number}`);
      }

      const order_id = orderResponse.data.order_id;
      const sender_email = "noreply@techdogsoftware.nl";
      const receiver_email = customer.invoicemail || "";
      const subject = "Bedankt voor je bestelling !";
      const content =
        "Dit is de order bevestegings e-mail voor je order bij unified smb. Hieronder meer details over de order.";
      if (receiver_email.length > 0) {
        try {
          const response = await middleware.post(
            "https://api-middleware.dev.techdogcloud.com/orders/emails",
            {
              order_id,
              sender_email,
              receiver_email,
              subject,
              content,
            }
          );

          if (response.status !== 200) {
            throw new Error("Failed to send email");
          }

          ToastSuccess("E-mail is succesvol verzonden.");
        } catch (err) {
          ToastError(
            `Er is een fout opgetreden bij het verzenden van de e-mail: ${err.message}`
          );
        }
      }
    } catch (error) {
      console.error("Error occurred:", error);
      console.error(
        "Error details:",
        error.response ? error.response.data : "No response data"
      );
      ToastError(
        "Er is een fout opgetreden bij het aanmaken van de bestelling"
      );
    } finally {
      setLoading(false);
    }
    navigate("/bestellingen");
  };

  const openModal = (item) => {
    console.log(item);
    setItemNoteProduct(item);
    setItemNoteModalVisible(true);
  };

  const setOrderRegel = (productId, name, description) => {
    setOrderItems((prev) =>
      prev.map((e) =>
        e.id == productId
          ? { ...e, name: name, product_description: description }
          : e
      )
    );
  };

  return (
    <PageTemplate navbarTitle="Order HUB" pageIsLoading={loading}>
      <ItemNoteModalComponent
        itemNoteModalVisible={itemNoteModalVisible}
        setItemNoteModalVisible={setItemNoteModalVisible}
        setOrderRegel={setOrderRegel}
        product={itemNoteProduct}
      />

      <div className="NewOrder w-100">
        <div className="row">
          {/* Header */}
          <div className="d-flex flex-row mb-4 pb-3 pt-5 justify-content-between mx-4">
            <div className="d-flex flex-row align-items-center" onClick={() => navigate("/bestellingen")}>
              <img style={{ width: "32px" }} className="me-3" src={Backbutton} alt="Back" />
              <p className="mb-0 fw-semibold fs-5">Alle bestellingen</p>
            </div>
            <div className="d-flex gap-3">
              <ButtonDarkBlue
                text={"Maak order aan"}
                onClickFunction={() => NewOrder("withInvoice")}
              />
              <ButtonBlue
                text={"Sla op als concept"}
                onClickFunction={() => NewOrder("asConcept")}
              />
            </div>
          </div>

          {/* Products Section - Second on mobile, First on left desktop */}
          {/* Products Section */}
          <div className="col-12 col-lg-8 order-1 mb-4">
            <div className="bg-white rounded-4">
              <div className="p-4">
                <div className="fs-5 mb-4 fw-bold">Producten</div>

                {/* Search with dropdown */}
                <div className="position-relative mb-4" ref={dropdownRef}>
                  <div className="position-relative">
                    <input
                      type="text"
                      value={productSearch || ""}
                      onChange={(e) => setProductSearch(e.target.value)}
                      onBlur={(e) => {
                        const relatedTarget = e.relatedTarget;
                        if (!dropdownRef.current.contains(relatedTarget)) {
                          setProducts1([]);
                        }
                      }}
                      placeholder="Zoek product"
                      className="form-control rounded-pill"
                      style={{
                        padding: "10px 40px 10px 20px",
                        border: "1px solid #E5E7EB",
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
                    />
                  </div>

                  {/* Products Dropdown */}
                  {products1.length > 0 && (
                    <div
                      className="position-absolute w-100 bg-white border rounded mt-1 shadow-lg overflow-hidden"
                      style={{
                        zIndex: 1050,
                        maxHeight: "300px",
                        overflowY: "auto",
                      }}
                    >
                      {loading ? (
                        <div className="text-center p-3">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        products1
                          .filter((product) => product.stock > 0)
                          .map((product) => (
                            <button
                              key={product.product_id}
                              className="w-100 text-start px-3 py-2 border-0 bg-transparent hover:bg-gray-50"
                              onClick={() => handleProductSelection(product)}
                              style={{
                                borderBottom: '1px solid #E5E7EB',
                                transition: 'background-color 0.2s'
                              }}
                            >
                              <div className="fw-medium">{product.product_name}</div>
                              <div className="text-muted small d-flex justify-content-between">
                                <span>€{parseFloat(product.incl_tax).toFixed(2)}</span>
                                <span>Voorraad: {product.stock}</span>
                              </div>
                            </button>
                          ))
                      )}
                    </div>
                  )}
                </div>

                {/* Selected Products Table */}
                {orderItems.length > 0 && (
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="text-center ">
                        <tr>
                          <th>Aantal</th>
                          <th>Product</th>
                          <th>Prijs incl. BTW</th>
                          <th>Extra Korting</th>
                          <th>Stukprijs</th>
                          <th>Totaal</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderItems.map((item) => (
                          <tr
                            key={item.id}
                            className="align-middle text-center"
                          >
                            <td>
                              <div className="d-flex align-items-center">
                                <button
                                  type="button"
                                  className="btn border rounded-0 rounded-start d-flex align-items-center justify-content-center"
                                  onClick={() =>
                                    updateQuantity(
                                      item.id,
                                      Math.max(1, item.quantity - 1)
                                    )
                                  }
                                >
                                  -
                                </button>
                                <input
                                  type="number"
                                  className="form-control rounded-0 text-center"
                                  style={{ width: "60px" }}
                                  value={item.quantity}
                                  disabled={maxAmount}
                                  onChange={(e) =>
                                    updateQuantity(
                                      item.id,
                                      Math.max(1, parseInt(e.target.value) || 0)
                                    )
                                  }
                                  min="1"
                                />
                                <button
                                  type="button"
                                  className="btn border rounded-0 rounded-end d-flex align-items-center justify-content-center"
                                  disabled={maxAmount}
                                  onClick={() =>
                                    updateQuantity(item.id, item.quantity + 1)
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td>
                              <div>
                                <span onClick={() => { openModal(item) }} className="text-info product-description-width text-decoration-none">
                                  {item.name}
                                </span>
                                <div className="text-muted small product-description-width">
                                  {item.product_description}
                                </div>
                              </div>
                            </td>
                            <td>
                              {item.sale_price === 0 ? (
                                // no sale
                                <span>{formatPrice(item.price.toFixed(2))}</span>
                              ) : (
                                // sale
                                <>
                                  <s className="d-block">{formatPrice(item.price.toFixed(2))}</s>
                                  <span className="d-block">{formatPrice(item.sale_price.toFixed(2))}</span>
                                </>
                              )}
                            </td>

                            {renderDiscountCell(item)}
                            <td>{formatPrice(calculateDiscountedPrice(item))}</td>
                            <td>
                              {formatPrice(calculateRowTotal(item).toFixed(2))}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-sm rounded-circle d-flex align-items-center justify-content-center"
                                onClick={() => removeItem(item.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faX}
                                  style={{ color: "#000000" }}
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Totals at bottom of products table */}
                    <div className="d-flex justify-content-end mt-4 me-5">
                      <div style={{ width: "250px" }}>
                        <div className="d-flex justify-content-between mb-2">
                          <span className="fw-bold">Totaal producten</span>
                          <span>
                            €{" "}
                            {orderItems
                              .reduce(
                                (sum, item) => sum + item.price * item.quantity,
                                0
                              )
                              .toFixed(2)}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between pb-2 mb-2 bottom-line-overzicht">
                          <span className="fw-bold">Totaal korting</span>
                          <span className="text-danger">
                            -{" "}
                            {formatPrice(
                              Math.abs(
                                calculateTotalProductDiscounts()
                              ).toFixed(2)
                            )}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between fw-bold">
                          <span>Totaal</span>
                          <span>
                            {formatPrice(
                              orderItems
                                .reduce((sum, item) => {
                                  const discountedPrice =
                                    calculateDiscountedPrice(item);
                                  return sum + discountedPrice * item.quantity;
                                }, 0)
                                .toFixed(2)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Notes Section */}
                <div className="row mt-4">
                  <div className="col-md-6 mb-4">
                    <div className="fw-bold mb-2">Opmerking op factuur</div>
                    <textarea
                      className="form-control rounded-4"
                      rows="4"
                      placeholder="Opmerking op factuur"
                      value={orderInfo.note}
                      onChange={handleNoteChange}
                    ></textarea>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div className="fw-bold mb-2">Extra informatie</div>
                    <textarea
                      className="form-control rounded-4"
                      rows="4"
                      placeholder="Referentie op factuur"
                      value={reference}
                      onChange={handleReferenceChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="right-side-ordernew col-12 col-lg-4 order-2 row overflow-hidden">
            {/* Customer Card - First on mobile, First on right desktop */}
            <div className="pe-0 mb-4">
              {loadingCustomer ? (
                <div className="whiteBox p-4 py-5 d-flex justify-content-center align-items-center">
                  <l-hourglass
                    size="40"
                    bg-opacity="0.1"
                    speed="1"
                    color="gray"
                  ></l-hourglass>
                </div>
              ) : (
                <CustomerComponent
                  mode="add"
                  customer={customer}
                  phoneNumbers={phoneNumbers}
                  addresses={addresses}
                  setAddresses={setAddresses}
                  setPhones={setPhoneNumbers}
                  setEmails={setEmails}
                  setOrder={setOrderInfo}
                  emails={emails}
                  onSelectCustomer={handleCustomerSelection}
                  addCustomer={true}
                  loading={loadingCustomer}
                />
              )}
            </div>
            {/* Order Overview Card */}
            <div className="pe-0 mb-4">
              <div className="whiteBox p-4 pt-2 pb-2">
                <h2 className="fs-5 mt-4 mb-4 fw-bold">Bestelling overzicht</h2>

                {/* Total Section */}
                <div className="bottom-line-overzicht mt-3 mb-3">
                  <div className="d-flex justify-content-between">
                    <p className="fw-bold">Totaal</p>
                    <p className="fw-bold">{formatPrice(subtotal.toFixed(2))}</p>
                  </div>

                  {/* Extra Discount Section */}
                  <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                      Extra korting
                    </div>
                    <div className="d-flex buttonswitch">
                      <div className="d-flex align-items-center">
                        <div className="d-flex">
                          <button
                            type="button"
                            className={`euroSaleButton p-2 fw-semibold ${activeSaleTypeVerzend === 1 ? "activeSaleType" : ""}`}
                            onClick={() => updateTotal("€", orderDiscount, false)}
                            disabled={subtotal === 0}
                          >€</button>
                          <button
                            type="button"
                            className={`percentageSaleButton p-2 fw-semibold ${activeSaleTypeVerzend === 2 ? "activeSaleType" : ""}`}
                            onClick={() => updateTotal("%", orderDiscount, false)}
                            disabled={subtotal === 0}
                          >%</button>
                        </div>
                        <input
                          className="p-2 mx-2 inputSaleOrder text-center fw-semibold"
                          placeholder="0"
                          type="number"
                          step="any"
                          value={orderDiscount}
                          onChange={(e) => setOrderDiscount(e.target.value)}
                          onBlur={(e) => handleOrderDiscountBlur(e.target.value)}
                        />
                        <div className="ms-3">
                          {formatPrice((activeSaleTypeVerzend === 1
                            ? Math.min(orderDiscount || 0, subtotal)
                            : (subtotal * Math.min(orderDiscount || 0, 100) / 100)).toFixed(2))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Bedrag na extra korting Section */}
                <div className="bottom-line-overzicht pb-2">
                  <div className="d-flex justify-content-between fw-bold">
                    <p className="mb-0">Bedrag na extra korting</p>
                    <p className="mb-0">{formatPrice(Math.max(0, subtotal - (activeSaleTypeVerzend === 1
                      ? Math.min(orderDiscount || 0, subtotal)
                      : (subtotal * Math.min(orderDiscount || 0, 100) / 100))).toFixed(2))}</p>
                  </div>

                  {/* Shipping Section */}
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Verzendmethode</p>
                      <select
                        className="form-select-shipping rounded-3"
                        style={{ width: 'auto' }}
                        value={selectedShippingMethod}
                        onChange={(e) => {
                          setSelectedShippingMethod(e.target.value);
                          if (e.target.value === 'pickup') {
                            setShippingCost(0);
                          } else if (e.target.value === 'shipping') {
                            setShippingCost(6.95);
                          }
                        }}
                      >
                        <option value="" hidden>Selecteer</option>
                        <option value="pickup">Ophalen</option>
                        <option value="shipping">Verzenden</option>
                      </select>
                    </div>
                    {selectedShippingMethod ? <div className="d-flex align-items-center">
                      <span className="me-2">€</span>
                      <input
                        className="shipping-cost px-3 py-1 rounded"
                        placeholder="0"
                        type="number"
                        step="0.01"
                        min="0"
                        value={selectedShippingMethod === 'pickup' ? '0' : shippingCost}
                        onChange={(e) => handleShippingCostChange(e.target.value)}
                        disabled={selectedShippingMethod === 'pickup'}
                      />
                    </div> : ""}
                  </div>
                </div>

                {/* Subtotal and BTW Section */}
                <div className="pt-3 bottom-line-overzicht pb-3">
                  <div className="d-flex justify-content-between fw-bold mb-2">
                    <h4 className="fs-5 mb-0 fw-bold">Subtotaal</h4>
                    <h5 className="fs-5 fw-bold">{formatPrice(totalInclTax.toFixed(2))}</h5>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>Totaal excl. BTW</div>
                    <div>{formatPrice(totalExclTax.toFixed(2))}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>BTW</div>
                    <div className="d-flex gap-5">
                      <div>NL 21%</div>
                      <div>{formatPrice(tax.toFixed(2))}</div>
                    </div>
                  </div>
                </div>

                {/* Total Including VAT */}
                <div className="d-flex justify-content-between pt-3 pb-3">
                  <h5 className="fw-bold mb-0">Totaal incl. BTW</h5>
                  <h5 className="fs-5 fw-bold">{formatPrice(totalInclTax.toFixed(2))}</h5>
                </div>
              </div>
            </div>

            {/* Status Card */}
            <div className="pe-0">
              <div className="whiteBox p-4">
                <div className="fs-5 mb-2 fw-bold">Status</div>
                <label className="form-label">Bestelling status</label><ToolTip message={"Wat is de status van de bestelling."} className="pl-1 ms-2" />
                <select className="form-select mb-3 rounded-pill" onChange={(e) => setOrderProcessing(e.target.value)} value={orderProcessing}>
                  <option value="0" >Geannuleerd</option>
                  <option value="2">Nieuw</option>
                  <option value="3">Voltooid</option>
                </select>

                <label className="form-label">Verkooplocatie</label><ToolTip message={"De verkoop locatie"} className="pl-1 ms-2"></ToolTip>
                <select className="form-select mb-3 rounded-pill" onChange={(e) => setSalesChannel(e.target.value)} value={salesChannel}>
                  {allSaleschannels.map((channel) => (
                    <option key={channel.sales_channel_id} value={channel.sales_channel_id}>{channel.sales_channel_name}</option>
                  ))}
                </select>

                <label className="form-label">Verzendstatus</label><ToolTip message={"Verzend status van de order"} className="pl-1 ms-2"></ToolTip>
                <select className="form-select rounded-pill" onChange={(e) => setStatus(e.target.value)} value={status}>
                  <option value="0">Niet verzonden</option>
                  <option value="1">Verzonden</option>
                  <option value="2">Afgeleverd</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default OrdersNew;
