import React, { useEffect, useMemo, useState, useRef } from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TableContainer,
    Autocomplete,
    Switch,
    FormControlLabel,
    TextField,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import middleware from "../Api/Middleware";
import PageTemplate from "../Templates/PageTemplate";
import allOptions from "./Components/tableOptions";
import ToastSuccess from "../Toasts/ToastSuccess";
import ToastError from "../Toasts/ToastError";

import "react-toastify/dist/ReactToastify.css";

function ColumnConfiguration({
    csvData,
    downloadfiles,
    selectedTable,
}) {
    const [tableData, setTableData] = useState([]);
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [updateColumn, setUpdateColumn] = useState(null);
    const [currentFileData, setCurrentFileData] = useState([]);
    const [disabledRows, setDisabledRows] = useState([]);
    const [enabledRows, setEnabledRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(1);
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [requiredColumns, setRequiredColumns] = useState([]);
    const [updateKeyOptions, setUpdateKeyOptions] = useState([]);
    const [displayNames, setDisplayNames] = useState([]);
    const [disabledOptions, setDisabledOptions] = useState([]);
    const [columnAssignments, setColumnAssignments] = useState([]);
    const [enabledColumns, setEnabledColumns] = useState([]);
    const [disabledColumns, setDisabledColumns] = useState([]);
    const [enableUpdateSwitch, setEnableUpdateSwitch] = useState(false);
    const [processedData, setProcessedData] = useState({});
    const [openAutocompleteIndex, setOpenAutocompleteIndex] = useState(null);
    const [tableNames, setTableNames] = useState([]);
    const [tableIds, setTableIds] = useState([]);
    const [lastTableId, setLastTableId] = useState(null);
    const [foreignKey, setForeignKey] = useState("");
    const [loading, setLoading] = useState(true);
    const [columnToTableMap, setColumnToTableMap] = useState([]);
    const [imageColumns, setImageColumns] = useState([]);
    const autocompleteRefs = useRef([]);

    console.log(csvData, downloadfiles, selectedTable);

    const navigate = useNavigate();
    const { state } = useLocation();

    // Find main and sub-table information by column name
    const getTableInfoByColumnName = (columnName) => {
        for (const option of allOptions) {
            for (const table in option.TableColumns) {
                if (option.TableColumns[table].includes(columnName)) {
                    return {
                        mainTable: option.TableName,
                        subTable: table === option.TableName ? null : table,
                    };
                }
            }
        }
        return null;
    };

    // Initialize options for the selected table
    const initializeOptions = () => {
        if (!Array.isArray(allOptions) || !selectedTable) return;
    
        const tableOptions = allOptions.find((option) => option.TableName === selectedTable);
    
        if (!tableOptions) return;
    
        const columns = Object.values(tableOptions.TableColumns).flat();
        const requiredColumnsArray = tableOptions.RequiredColumns || [];
        const allRequiredColumns = requiredColumnsArray.flat();
        if (state.selectedProductIdentifier !== "") {
            allRequiredColumns.push(state.selectedProductIdentifier);
        }
    
        const updateKeyOptionsArray = Object.values(tableOptions.UpdateKeys).flat();
    
        // Sort columns so that required columns appear first
        const sortedColumns = columns.sort((a, b) => {
            const isARequired = allRequiredColumns.includes(a);
            const isBRequired = allRequiredColumns.includes(b);
            if (isARequired && !isBRequired) return -1;
            if (!isARequired && isBRequired) return 1;
            return 0;
        });
    
        setForeignKey(tableOptions.SharedId);
        setColumnToTableMap(
            Object.entries(tableOptions.TableColumns)
                .flatMap(([table, columns]) => columns.map(column => ({ [column]: table })))
                .reduce((acc, curr) => ({ ...acc, ...curr }), {})
        );
        setDisplayNames(tableOptions.DisplayNames);
        setRequiredColumns(allRequiredColumns);
        setAutocompleteOptions(sortedColumns); // Set sorted columns here
        setUpdateKeyOptions(updateKeyOptionsArray);
    };    

    // Check and validate page navigation
    const validateNavigation = () => {
        const isCsvDataValid = Array.isArray(csvData) && csvData.length > 0;
        const isDownloadFilesValid = Array.isArray(downloadfiles) && downloadfiles.length > 0;
        const isSelectedTableValid = typeof selectedTable === 'string' && selectedTable.length > 0;

        if (!isSelectedTableValid && !isCsvDataValid && !isDownloadFilesValid) {
            navigate(-1);
        }
    };

    // Generate the final data to be processed
    const processFinalData = () => {
        if (!tableData.length) {
            setProcessedData({});
            return;
        }

        const newData = enabledRows.map((rowIndex) => {
            const rowData = {};
            if (!currentFileData[rowIndex]) return rowData;

            enabledColumns.forEach((columnIndex) => {
                const columnName = columnAssignments[columnIndex];
                if (!columnName) return;

                rowData[columnName] = currentFileData[rowIndex][columnIndex];
            });
            return rowData;
        });

        setProcessedData(newData);
    };

    const importCSVData = () => {
        if (!Array.isArray(csvData) || csvData.length === 0) return;
        
        const newTableData = [...csvData];
        setTableData(newTableData);
        setCurrentFileData(newTableData[currentFileIndex]);

        if (Array.isArray(downloadfiles) && downloadfiles.length > 0) {
            const fileNames = downloadfiles.map((file) => file.name);
            setTableNames(fileNames);
        } else {
            setTableNames([]);
        }

        setLoading(false);
    };

    // Adjust column lengths
    const adjustColumnLength = () => {
        if (!tableData.length) return;

        const initialDisabledColumns = Array.from(
            { length: tableData[currentFileIndex][0].length },
            (_, index) => index
        );

        setDisabledColumns(initialDisabledColumns);
        setEnabledColumns(new Array(tableData[currentFileIndex][0].length).fill(""));
    };

    // Set the column assignment options based on current file data
    const setColumnAssignmentOptions = () => {
        if (!currentFileData.length || !currentFileData[0].length) return;

        setColumnAssignments(new Array(currentFileData[0].length).fill(""));
    };

    // Navigate through multiple tables if lastTableId exists
    const navigateMultipleTables = () => {
        if (lastTableId) {
            const destination = tableIds.length > 0 ? tableIds[0] : lastTableId;
            navigate(`/result/${destination}`);
        }
    };

    // Toggle table navigation
    const toggleTable = (direction) => {
        let newIndex = currentFileIndex;
        if (direction === "next" && currentFileIndex < tableData.length - 1) {
            newIndex++;
        }

        setCurrentFileIndex(newIndex);
        setCurrentFileData(tableData[newIndex]);
        setSelectedRow(1);
        setDisabledOptions([]);
        setDisabledRows([]);
        setEnabledRows([]);
        setColumnAssignments([]);
        setEnabledColumns([]);
        setDisabledColumns([]);
        setEnableUpdateSwitch(false);
    };

    // Select rows to be disabled or enabled
    const selectRows = (rowIndex) => {
        setSelectedRow(rowIndex);

        const newDisabledRows = [];
        const newEnabledRows = [];

        for (let i = 0; i < tableData[currentFileIndex].length; i++) {
            if (i < rowIndex) {
                newDisabledRows.push(i);
            } else {
                newEnabledRows.push(i);
            }
        }
        setDisabledRows(newDisabledRows);
        setEnabledRows(newEnabledRows);
    };

    // Handle Autocomplete change
    const handleAutocompleteChange = (event, newValue, columnIndex) => {
        const updatedAssignments = [...columnAssignments];
        updatedAssignments[columnIndex] = newValue || "";
    
        setColumnAssignments(updatedAssignments);
    
        if (newValue) {
            if (newValue === "image") {
                setDisabledOptions((prev) => prev.filter((opt) => opt !== columnAssignments[columnIndex]));
    
                setImageColumns((prev) => [...prev, columnIndex]);
                setEnabledColumns((prev) => {
                    const updated = [...prev];
                    updated[columnIndex] = columnIndex;
                    return updated;
                });
                setDisabledColumns((prev) => prev.filter((colIndex) => colIndex !== columnIndex));
            } else {
                setEnabledColumns((prev) => {
                    const updated = [...prev];
                    updated[columnIndex] = columnIndex;
                    return updated;
                });
    
                setDisabledOptions((prev) => [
                    ...prev.filter((opt) => opt !== columnAssignments[columnIndex]),
                    newValue,
                ]);
                setDisabledColumns((prev) => prev.filter((colIndex) => colIndex !== columnIndex));
            }
        } else {
            if (imageColumns.includes(columnIndex)) {
                setImageColumns((prev) => prev.filter((col) => col !== columnIndex));
            }
            skipColumn(columnIndex);
        }
    
        const nextIndex = columnIndex + 1;
    
        if (nextIndex >= autocompleteRefs.current.length) {
            if (autocompleteRefs.current[columnIndex]) {
                autocompleteRefs.current[columnIndex].blur();
            }
        } else {
            setOpenAutocompleteIndex(nextIndex);
            if (autocompleteRefs.current[nextIndex]) {
                autocompleteRefs.current[nextIndex].focus();
            }
        }
    };

    // Skip column selection
    const skipColumn = (columnIndex) => {
        const clearedValue = columnAssignments[columnIndex];
        setColumnAssignments((prev) => {
            const updated = [...prev];
            updated[columnIndex] = "";
            return updated;
        });

        setDisabledOptions((prev) => prev.filter((opt) => opt !== clearedValue));
        setEnabledColumns((prev) => {
            const updated = [...prev];
            updated[columnIndex] = "";
            return updated;
        });

        setImageColumns(prev => prev.filter(col => col !== columnIndex));
        setDisabledColumns((prev) => [...prev, columnIndex]);

        const nextIndex = columnIndex + 1;
        if (autocompleteRefs.current[nextIndex]) {
            autocompleteRefs.current[nextIndex].focus();
        }
    };

    // Reset data and navigate back to import page
    const resetImport = () => {
        setTableData([]);
        setCurrentFileIndex(0);
        setCurrentFileData([]);
        navigate("/import");
    };

    // Generate data structure for import
    const generateImportData = () => {
        const importData = {};

        enabledRows.forEach((rowIndex) => {
            enabledColumns.forEach((columnIndex) => {
                const columnName = columnAssignments[columnIndex];
                if (!columnName) return;

                let columnValue = currentFileData[rowIndex][columnIndex];
                
                // Special handling for image columns
                if (columnName === "image" && imageColumns.length > 0) {
                    // For the first image column in a row, collect all image values
                    if (columnIndex === imageColumns[0]) {
                        columnValue = imageColumns
                            .map(imgCol => {
                                const val = currentFileData[rowIndex][imgCol];
                                return typeof val === "string" ? val.trim() : val || ""; // Trim only if it's a string
                            })
                            .filter(val => val && val !== "-") // Remove empty values and "-"
                            .join(",")
                            .replace(/\s+/g, '')    // Remove all remaining whitespace
                            .replace(/,+/g, ',');   // Replace multiple commas with a single comma
                    } else {
                        // Skip other image columns as they're handled with the first one
                        return;
                    }
                }
                

                const tableInfo = getTableInfoByColumnName(columnName);

                if (tableInfo) {
                    const { mainTable, subTable } = tableInfo;

                    if (!importData[mainTable]) importData[mainTable] = {};
                    if (!importData[mainTable][rowIndex]) importData[mainTable][rowIndex] = {};

                    if (subTable) {
                        if (!importData[mainTable][rowIndex][subTable]) {
                            importData[mainTable][rowIndex][subTable] = {};
                        }
                        importData[mainTable][rowIndex][subTable][columnName] = columnValue;
                    } else {
                        importData[mainTable][rowIndex][columnName] = columnValue;
                    }
                }
            });
        });

        return Object.keys(importData).reduce((acc, tableName) => {
            acc[tableName] = Object.values(importData[tableName]);
            return acc;
        }, {});
    };

    // Import data to the database
    const importToDatabase = async () => {
        const selectedColumns = columnAssignments.filter((option) => option);

        // Check if all required columns are assigned
        for (const requiredColumn of requiredColumns) {
            if (!selectedColumns.includes(requiredColumn)) {
                ToastError("Niet alle vereiste kolommen aangewezen. Wijs alle blauwe kolommen aan en probeer opnieuw.");
                return false;
            }
        }

        // Check if update switch is enabled without a selected leading column
        if (enableUpdateSwitch && !updateColumn) {
            ToastError("Als je objecten wilt updaten moet je een leidende kolom selecteren.");
            return false;
        }

        const importData = generateImportData();
        const requestPayload = {
            updateSwitch: enableUpdateSwitch,
            leadingUpdateKey: updateColumn,
            leadingUpdateModel: columnToTableMap[updateColumn],
            sharedForeignKey: foreignKey,
            downloadFileName: tableNames[currentFileIndex],
            selectedImportMethod: selectedTable,
            importData,
            csvData: currentFileData,
            selectedColumns,
        };

        setLoading(true);

        const response = await middleware.post("/import", requestPayload);

        if (response.status === 200) {
            ToastSuccess("Bestand geïmporteerd!");
            const importId = response.data.import_id;

            if (currentFileIndex + 1 < tableData.length) {
                window.open(`/import/valideren/${importId}`, "_blank");
                toggleTable("next");
                setLoading(false);
            } else {
                navigate(`/import/valideren/${importId}`);
            }

            return true;
        } else {
            ToastError("Something went wrong");
            return false;
        }
    };

    // Determine cell style based on column and row statuses
    const getCellStyle = (rowIndex, columnIndex) => {
        if (disabledColumns.includes(columnIndex) || disabledRows.includes(rowIndex)) {
            return { color: "gray", backgroundColor: "#f0f0f0" };
        }

        return { backgroundColor: "transparent" };
    };

    // Custom render option function for regular Autocomplete components
    const renderOption = (props, option, state) => {
        const { key, ...otherProps } = props;
        const isRequired = requiredColumns.includes(option);
        return (
            <li key={key} {...otherProps} style={{ color: isRequired ? "#007BFF" : "black" }}>
                {isRequired ? "* " : ""}
                {displayNames[option] || option}
            </li>
        );
    };

    // New render option function for the leading update key Autocomplete
    const renderUpdateKeyOption = (props, option, state) => {
        const { key, ...otherProps } = props;
        return (
            <li key={key} {...otherProps}>
                {displayNames[option] || option}
            </li>
        );
    };

    // Effect hooks
    useEffect(() => {
        if (tableData.length && tableData[currentFileIndex].length > 1) selectRows(0);
    }, [tableData, currentFileIndex]);

    useEffect(initializeOptions, [selectedTable]);
    useEffect(validateNavigation, [csvData, downloadfiles, selectedTable]);
    useMemo(processFinalData, [tableData, enabledRows, enabledColumns, columnAssignments, currentFileData]);
    useEffect(importCSVData, [csvData, downloadfiles, currentFileIndex]);
    useEffect(adjustColumnLength, [tableData, currentFileIndex]);
    useEffect(setColumnAssignmentOptions, [currentFileData]);
    useEffect(navigateMultipleTables, [lastTableId, tableIds, navigate]);

    return (
        <PageTemplate pageIsLoading={loading} loadingTitle={"Bestanden aan het uploaden..."} loadingLogoutOption={false} loadingRetryOption={false} navbarTitle={"Import & Export HUB"}>
            <div className="w-100">
                <div className="d-flex justify-content-between gap-3 mb-3">
                    <div className="whiteBox flex-grow-1">
                        <h4>Informatie</h4>
                        <p>Bestandsnaam: {tableNames[currentFileIndex]}</p>
                        <p>Type: {selectedTable}</p>
                    </div>
                    <div className="whiteBox flex-grow-1">
                        <h4>Instellingen:</h4>
                        <div className="d-flex flex-column gap-2">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={enableUpdateSwitch}
                                        onChange={() => setEnableUpdateSwitch(!enableUpdateSwitch)}
                                    />
                                }
                                label="Bestaande objecten updaten"
                            />
                            {enableUpdateSwitch && (
                                <Autocomplete
                                    options={updateKeyOptions}
                                    value={updateColumn}
                                    onChange={(event, newValue) => setUpdateColumn(newValue)}
                                    getOptionDisabled={(option) => option === updateColumn}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    getOptionLabel={(option) => displayNames[option] || option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Leidende kolom"
                                            variant="filled"
                                            inputRef={(el) => (autocompleteRefs.current[1] = el)}
                                        />
                                    )}
                                    renderOption={renderUpdateKeyOption}
                                    noOptionsText="Geen opties"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <TableContainer component={Paper} style={{ maxHeight: 900, borderRadius: "15px", boxShadow: "none" }}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{"width": "0px"}}>Start vanaf</TableCell>
                                {tableData[currentFileIndex]?.[0]?.map((_, columnIndex) => (
                                    <TableCell key={columnIndex} style={{ fontWeight: "bold", textAlign: "center" }}>
                                        <button className="btn btn-primary mb-2" onClick={() => skipColumn(columnIndex)}>Overslaan</button>
                                        <Autocomplete
                                            options={autocompleteOptions}
                                            value={columnAssignments[columnIndex] || null}
                                            onChange={(event, newValue) => handleAutocompleteChange(event, newValue, columnIndex)}
                                            open={openAutocompleteIndex === columnIndex}
                                            onFocus={() => setOpenAutocompleteIndex(columnIndex)}
                                            onBlur={() => setOpenAutocompleteIndex(null)} // Collapse on blur
                                            getOptionDisabled={(option) => disabledOptions.includes(option)}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            getOptionLabel={(option) => displayNames[option] || option}
                                            noOptionsText="Geen opties"
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    label="Kolom matchen of overslaan" 
                                                    variant="filled" 
                                                    style={{width: "200px"}} 
                                                    inputRef={(el) => (autocompleteRefs.current[columnIndex] = el)} 
                                                />
                                            )}
                                            renderOption={renderOption}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData[currentFileIndex]?.slice(0, 10)?.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    <TableCell>
                                        <input type="radio" name="group" checked={selectedRow === rowIndex} onChange={() => selectRows(rowIndex)} />
                                    </TableCell>
                                    {row.map((cell, cellIndex) => (
                                        <TableCell key={cellIndex} style={{ textAlign: "center", ...getCellStyle(rowIndex, cellIndex) }}>
                                            {cell instanceof Date 
                                                ? cell.toLocaleString() 
                                                : (typeof cell === "string" && cell.length > 40 
                                                    ? `${cell.slice(0, 40)}...` 
                                                    : cell)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="d-flex gap-2 mt-3">
                    <button className="btn btn-success" onClick={async () => {
                        const success = await importToDatabase();
                        if (success && currentFileIndex + 1 < tableData.length) toggleTable("next");
                    }}>
                        {currentFileIndex + 1 < tableData.length ? "Verwerken en ga naar het volgende bestand" : "Verwerken"}
                    </button>
                    <button className="btn btn-danger" onClick={resetImport}>Annuleren</button>
                    {tableData.length > 1 && (
                        <button className="btn btn-secondary" onClick={() => toggleTable("next")} disabled={currentFileIndex === tableData.length - 1}>Overslaan</button>
                    )}
                </div>
            </div>
        </PageTemplate>
    );
}

export default ColumnConfiguration;
