import React, { useEffect, useRef, useState } from "react";
import PageTemplate from "../../../Templates/PageTemplate";
import { useNavigate } from "react-router";
import Cookies from 'universal-cookie';
import middleware from "../../../Api/Middleware";
import { BackButton } from "../../../HouseStyle/Components/Buttons";
import { BorderedTextInput } from "../../../HouseStyle/Components/Inputs";
import { BorderedDropDown } from "../../../HouseStyle/Components/DropDowns";
import TextEditor from "../../../Main/TextEditor";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import NormalMediaUploader from "../../../MediaUpload/NormalMediaUpload/NormalMediaUpload";

const FacturizationDetails = () => {
    const [loading, setLoading] = useState(true);
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [companyInfo, setCompanyInfo] = useState({});
    const [companyInfoOld, setCompanyInfoOld] = useState({});
    const [uploadedImage, setUploadedImage] = useState({});
    const [modals, setModals] = useState({
        deactivateCompanyInfo: false
    });
    const isInitialRender = useRef(true);
    const toggleModal = (modal) => setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));

    // Maak een pending state voor de invoer
    const [pendingCompanyInfo, setPendingCompanyInfo] = useState(companyInfo);
    useEffect(() => {
        setPendingCompanyInfo(companyInfo);
    }, [companyInfo]);

    const fetchCompanyInfo = async () => {
        const settings = await middleware.get("/settings/backoffice?setting_id=1");
        console.log(settings.data.data);
        
        setCompanyInfo(settings.data.data);
        setCompanyInfoOld(settings.data.data);
        setLoading(false);
        setCompanyInfo((prev) => ({ ...prev, product_number_display: settings.data.data.product_number_display.toString() }));
        setCompanyInfo((prev) => ({ ...prev, address_display: settings.data.data.address_display.toString() }));
        setCompanyInfo((prev) => ({ ...prev, logo_display: settings.data.data.logo_display.toString() }));
    };

    useEffect(() => {
        fetchCompanyInfo();
    }, []);

    useEffect(() => {
        // Vergelijk de nieuwe companyInfo met de oude. Als ze gelijk zijn, doe niets.
        if (JSON.stringify(companyInfo) === JSON.stringify(companyInfoOld)) return;
        
        UpdateCompanyInfo();
        // Werk companyInfoOld bij zodat volgende wijzigingen wel herkend worden.
        setCompanyInfoOld(companyInfo);
    }, [companyInfo]);

    useEffect(() => {
        if (uploadedImage?.file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // reader.result will be a base64 encoded data URL
                const base64String = reader.result;
                if (base64String && base64String !== companyInfo.invoice_background) {
                    setCompanyInfo((prev) => ({ ...prev, invoice_background: base64String }));
                }
            };
            reader.readAsDataURL(uploadedImage.file);
        } else if (uploadedImage?.url && uploadedImage.url !== companyInfo.invoice_background) {
            // fallback if there's no file object, use the URL directly
            setCompanyInfo((prev) => ({ ...prev, invoice_background: uploadedImage.url }));
        }
    }, [uploadedImage]); 
    
    
    const UpdateCompanyInfo = async () => {
        try {
          await middleware.put("/settings/backoffice", {
            setting_id: 1,
            start_invoicenumber: companyInfo.start_invoicenumber,
            payment_term: companyInfo.payment_term,
            product_number_display: Number(companyInfo.product_number_display),
            logo_display: Number(companyInfo.logo_display),
            address_display: Number(companyInfo.address_display),
            receipt_footnote: companyInfo.receipt_footnote,
            invoice_background: companyInfo.invoice_background,
          });
          ToastSuccess('Company information updated successfully');
        } catch (error) {
          console.error('Error updating company information:', error);
        }
    };

    return <PageTemplate pageIsLoading={loading} navbarTitle={"Bedrijfsinformatie"}>
        <div className="col-12 d-flex flex-column">
            <div className="d-flex mb-4 mt-4">
                <BackButton size="l" text={"Terug"} onClickFunction={() => navigate("/algemene/instellingen/bedrijfsinformatie")} />
                <div className="d-flex flex-column align-items-center">
                    {companyInfo.public === 0 ? <p className="mb-1 p-0">Gepubliceerd</p> : <p className="mb-1 p-0">Gearchiveerd</p>}
                    <label className="switchActiveProduct">
                        <input type="checkbox" checked={companyInfo.public}
                            onChange={(e) => toggleModal("deactivateCompanyInfo")} />
                        <span className="sliderActiveProduct round">
                        </span>
                    </label>
                </div>
            </div>
            <div className="whiteBox px-5 py-4">
                <h2 className="fw-semibold">Administratie informatie aanpassen</h2>
                <div className="row">
                    <div className="col-4">
                        <h6 className="fw-bold ms-4 py-2 pt-4">Volgende factuurnummer</h6>
                        <BorderedTextInput 
                            placeholder='e.g. 0000' 
                            size='l'
                            inputValue={pendingCompanyInfo.start_invoicenumber || ""}
                            setInputValue={(value) =>
                                setPendingCompanyInfo((prev) => ({ ...prev, start_invoicenumber: value }))
                            }
                            updateFunction={() =>
                                setCompanyInfo((prev) => ({ ...prev, start_invoicenumber: pendingCompanyInfo.start_invoicenumber }))
                            }
                            updateWaitTimer={1000}
                        />
                    </div>
                    <div className="col-4">
                        <h6 className="fw-bold ms-4 py-2 pt-4">Factuur vervaltermijn</h6>
                        <BorderedDropDown 
                            placeholder="Selecteer een vervaltermijn"
                            selectedValue={companyInfo.payment_term || ""} 
                            setSelectedValue={(e) => setCompanyInfo((prev) => ({ ...prev, payment_term: e }))} 
                            options={[
                                { title: "7 dagen", value: 7 }, 
                                { title: "14 dagen", value: 14 },
                                { title: "30 dagen", value: 30 }
                            ]} 
                        />
                    </div>
                    <div className="col-4">
                    </div>
                    <div className="col-4">
                        <h6 className="fw-bold ms-4 py-2 pt-4">Productnummer weergeven op factuur regels</h6>
                        <BorderedDropDown 
                            placeholder="Selecteer een optie" 
                            selectedValue={companyInfo.product_number_display || ""} 
                            setSelectedValue={(e) => setCompanyInfo((prev) => ({ ...prev, product_number_display: e }))}
                            options={[
                                { title: "Ja", value: "1" },
                                { title: "Nee", value: "0" }
                            ]}
                        />
                    </div>
                    <div className="col-4">
                        <h6 className="fw-bold ms-4 py-2 pt-4">Logo weergeven op factuur</h6>
                        <BorderedDropDown 
                            placeholder="Selecteer een optie"
                            options={[{ title: "Ja", value: "1" }, { title: "Nee", value: "0" }]}
                            selectedValue={companyInfo.logo_display || ""} 
                            setSelectedValue={(e) => setCompanyInfo((prev) => ({ ...prev, logo_display: e }))}
                        />
                    </div>
                    <div className="col-4">
                        <h6 className="fw-bold ms-4 py-2 pt-4">Adresgegevens weergeven op factuur</h6>
                        <BorderedDropDown
                            placeholder="Selecteer een optie" 
                            options={[{ title: "Ja", value: "1" }, { title: "Nee", value: "0" }]}
                            selectedValue={companyInfo.address_display || ""} 
                            setSelectedValue={(e) => setCompanyInfo((prev) => ({ ...prev, address_display: e }))} 
                        />
                    </div>
                    <div>
                        <h6 className="fw-bold ms-4 py-2 pt-4">Standaard factuur omschrijving (onderaan factuur)</h6>
                        <TextEditor value={pendingCompanyInfo.receipt_footnote || ""} onChange={(value) => setCompanyInfo((prev) => ({ ...prev, receipt_footnote: value }))} />
                    </div>
                </div>
                <div className="pdfInput">
                    <h6 className="fw-bold ms-4 py-2 pt-4">Factuur briefpapier</h6>
                    <NormalMediaUploader imageValue={companyInfo.invoice_background || null} imgPreview={true} onImageChange={setUploadedImage}/>
                </div>
            </div>
        </div>
    </PageTemplate >
}

export default FacturizationDetails;