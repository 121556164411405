// resultsLogic.js

import { useEffect, useMemo, useRef, useState } from "react";
import middleware from "../../Api/Middleware";
import { animate } from "framer-motion";
import allOptions from "./tableOptions";
import handleExcelDownload from "./handleExcelDownload";
import handleExcelDownloadCloud from "./handleExcelDownloadCloud";
import getStatusText from "./statusText";
// Helper functions and state logic for the Results component

export const useResultsLogic = (import_id, order, pagination, setPagination) => {
    // Use-states
    const [loading, setLoading] = useState(true);
    const [downloadable, setDownloadable] = useState(false);
    const [importResults, setImportResults] = useState([]);
    const [importDetails, setImportDetails] = useState({});
    const [progressCount, setProgressCount] = useState(0);
    const [succesfulCount, setSuccesfulCount] = useState(0);
    const [processedCount, setProcessedCount] = useState(0);
    const [pressedCancel, setPressedCancel] = useState(false);
    const [statusText, setStatusText] = useState(<span>???</span>);
    const [currentTableOptions, setCurrentTableOptions] = useState({});
    const [isFetching, setIsFetching] = useState(false);

    // Refs
    const paginationRef = useRef(pagination);
    const orderRef = useRef(order);
    const intervalIdRef = useRef(null);
    const isMountedRef = useRef(true);
    const downloadDebounce = useRef(false);

    // Refs for animation control
    const progressAnimationRef = useRef(null);
    const succesfulAnimationRef = useRef(null);
    const processedAnimationRef = useRef(null);

    // Function to find table options based on table name
    function findTableByName(tableName) {
        return allOptions.find((option) => option.TableName === tableName);
    }

    // Function to fetch all necessary data
    const fetchAll = async (isFirstFetch) => {
        if (isFetching) return;  // Prevent concurrent fetches
        setIsFetching(true);

        try {
            const currentPagination = paginationRef.current;
            const currentOrder = orderRef.current;

            // Fetch import results and details
            const [importResultsResponse, importDetailsResponse] = await Promise.all([
                middleware.get(
                    `import?import_id=${import_id}&page=${currentPagination.page}&page_size=${
                        currentPagination.page_size
                    }${
                        currentOrder.orderBy && currentOrder.direction
                            ? `&orderBy=${currentOrder.orderBy}&direction=${currentOrder.direction}`
                            : ""
                    }`
                ),
                middleware.get(`sp/importDetails?import_id=${import_id}`),
            ]);

            // Check if component is still mounted before updating state
            if (!isMountedRef.current) return;

            // Update state with fetched data
            setCurrentTableOptions(findTableByName(importDetailsResponse.data[0].import_type));
            setImportResults(importResultsResponse.data.pagination.data);
            setImportDetails(importDetailsResponse.data[0]);

            // Update pagination
            const newPagination = {
                ...currentPagination,
                max_items: importResultsResponse.data.pagination.total,
                begin_of_page: importResultsResponse.data.pagination.from,
                end_of_page: importResultsResponse.data.pagination.to,
            };
            setPagination(newPagination);
            paginationRef.current = newPagination;
            
            // Status message text
            setStatusText(getStatusText(importDetailsResponse.data[0].status));
            
            // Update and animate count values
            updateCounts(importDetailsResponse.data[0], isFirstFetch);

            // Downloadable or not based on if original_file_path is present.
            setDownloadable(importResultsResponse.data.importData.original_file_path != null);
            console.log("Done");
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            console.log("Done fetch");
            setIsFetching(false)
            
            if (isMountedRef.current) {
                setLoading(false);
            }
        }
    };

    // Function to update and animate count values
    const updateCounts = (details, isFirstFetch) => {
        const totalRows = details?.total_rows || 1;
        const processedRows = details?.processed_rows || 0;
        const successfulCount = details?.successful_rows || 0;

        if (isFirstFetch) { // Set values without animation
            setProgressCount((processedRows * 100) / totalRows);
            setSuccesfulCount(successfulCount);
            setProcessedCount(processedRows);
        } else { // Animate count changes
            animateCounts(
                setProgressCount,
                progressAnimationRef,
                (processedRows * 100) / totalRows,
                2
            );
            animateCounts(setSuccesfulCount, succesfulAnimationRef, successfulCount, 0);
            animateCounts(setProcessedCount, processedAnimationRef, processedRows, 0);
        }
    };

    // Function to animate count changes
    const animateCounts = (setter, animationRef, targetValue, decimals) => {
        setter((prevValue) => {
            animationRef.current = animate(prevValue, targetValue, {
                duration: 5,
                ease: "linear",
                onUpdate: (latest) => setter(Number(latest.toFixed(decimals))),
            });
            return prevValue;
        });
    };

    // Function to handle cancelling of import
    const handleCancel = async () => {
        try {
            await middleware.post("cancelimport", { import_id });
            stopAnimations();
            setPressedCancel(true);
            clearInterval(intervalIdRef.current);
            setTimeout(() => fetchAll(true), 1000);
        } catch (error) {
            console.error("Error canceling import:", error);
        }
    };

    // Function to start repeated data fetching every 5s
    const startRepeatedFetch = async () => {
        intervalIdRef.current = setInterval(() => {
            setImportDetails((prevDetails) => {
                if (prevDetails.status == 1 || prevDetails.status == 0 || prevDetails.status == 4) {
                    clearInterval(intervalIdRef.current);
                } else {
                    fetchAll(false);
                }
                return prevDetails;
            });
        }, 5000);
    };

    // Function to stop all ongoing animations
    const stopAnimations = () => {
        progressAnimationRef.current && progressAnimationRef.current.stop();
        succesfulAnimationRef.current && succesfulAnimationRef.current.stop();
        processedAnimationRef.current && processedAnimationRef.current.stop();
    };

    // Function to download the original imported file
    const downloadOriginalFile = async () => {
        if (!downloadDebounce.current) {
            downloadDebounce.current = true;
            handleExcelDownloadCloud(import_id, "import/get-file-url");
            // handleExcelDownload(base64Data, importDetails.file_name);
            setTimeout(function() {downloadDebounce.current = false}, 1000)
        }
    };

    // Effect to update pagination ref when pagination changes
    useEffect(() => {
        paginationRef.current = pagination;
    }, [pagination]);

    // Effect to update order ref when order changes
    useEffect(() => {
        orderRef.current = order;
    }, [order]);

    // Effect to fetch initial data and start repeated fetching
    useEffect(() => {
        fetchAll(true);
        startRepeatedFetch();

        // Cleanup function to clear interval when component unmounts
        return () => {
            clearInterval(intervalIdRef.current);
        };
    }, []);

    // Return all necessary state and functions
    return {
        loading,
        downloadable,
        importResults,
        importDetails,
        progressCount,
        succesfulCount,
        processedCount,
        pressedCancel,
        statusText,
        currentTableOptions,
        fetchAll,
        handleCancel,
        downloadOriginalFile,
    };
};
