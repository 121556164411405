import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import middleware from "../../Api/Middleware";
import CreateNoteModal from "../../Modals/Orders/CreateNoteModal";
import CheckboxCell from "../../Table/TableComponents/CheckboxCell";
import CheckboxHeader from "../../Table/TableComponents/CheckboxHeader";
import "../../Modals/Orders/ReceiveEmailModal.css";
import { ButtonBlue } from "../../HouseStyle/Components/Buttons";
import { use } from "react";

const NotitieComponent = ({ order, setOrder }) => {
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [createNoteModalVisible, setCreateNoteModalVisible] = useState(false);
  const [updatedNotes, setUpdatedNotes] = useState(false);
  const [notes, setNotes] = useState([]);
  const [existing, setExisting] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('');

  const [selectedIds, setSelectedIds] = useState([]);

  // Ophalen van notities
  const fetchNotes = async () => {
    const notesResponse = await middleware.get(`/orders/notes?order_id=${order.order_id}`);
    setNotes(notesResponse.data.data);
  };

  useEffect(() => {
    setUpdatedNotes(false);
    fetchNotes();
    setExisting(null);
  }, [updatedNotes]);

  // Filteren en sorteren van de data
  const filteredData = notes
    .filter(item =>
      item.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.created_at.includes(searchTerm)
    )
    .sort((a, b) => {
      if (sortField === 'subject') {
        return sortDirection === 'asc'
          ? a.subject.localeCompare(b.subject)
          : b.subject.localeCompare(a.subject);
      } else if (sortField === 'date') {
        return sortDirection === 'asc'
          ? new Date(a.created_at) - new Date(b.created_at)
          : new Date(b.created_at) - new Date(a.created_at);
      }
      return 0;
    });

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  const openNote = (id) => {
    setExisting(notes.find(e => id === e.client_order_note_id));
    setCreateNoteModalVisible(true);
  };
  // Functie om de selectie te beheren
  const handleSelection = (id, isSelected) => {
    if (isSelected) {
      setSelectedIds(prev => [...prev, id]);
    } else {
      setSelectedIds(prev => prev.filter(item => item !== id));
    }
  };

  return (
    <div className="whiteBox email-main-container pt-4 pb-4">
      <CreateNoteModal
        createNoteModalVisible={createNoteModalVisible}
        setCreateNoteModalVisible={setCreateNoteModalVisible}
        order_id={order.order_id}
        setUpdatedNotes={setUpdatedNotes}
        existingnote={existing}
        edit={true}
      />
      {/* Header en nieuwe notitie knop */}
      <div className="email-header">
        <div className="BlockTitle">Notities</div>
        <ButtonBlue
          text={"Nieuwe notitie"}
          onClickFunction={() => {
            setExisting(null);
            setCreateNoteModalVisible(true);
          }}
        />

      </div>
      {/* Zoekbalk */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Zoeken"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setCurrentPage(1);
          }}
          className="ps-4 search-input rounded-5"
        />
        <div
          className="SearchIcon"
          style={{ width: "42px", height: "42px", position: "absolute", right: "52px" }}
        />
      </div>
      {/* Notities tabel */}
      <div>
        <div className="email-container pt-2 pb-0 rounded-4 mt-4">
          <table className="w-100" style={{ minHeight: "140px" }}>
            {/* Table Header */}
            <thead className="bottom-border-line">
              <tr>
                <th className="ps-3">
                  <CheckboxHeader
                    currentItem={paginatedData}
                    selectedIds={selectedIds}
                    setSelectedIds={() => setSelectedIds(selectedIds.length === paginatedData.length ? [] : paginatedData.map(item => item.client_order_note_id))}
                    pk={'client_order_note_id'}
                  />
                </th>
                <th onClick={() => {
                  // Sorteer op onderwerp
                  if (sortField === 'subject') {
                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                  } else {
                    setSortField('subject');
                    setSortDirection('asc');
                  }
                }}>
                  <div className="flex items-center gap-2">
                    <span>Onderwerp </span>
                    <FontAwesomeIcon icon={faSort} style={{ fontSize: "10px" }} />
                  </div>
                </th>
                <th onClick={() => {
                  // Sorteer op datum
                  if (sortField === 'date') {
                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                  } else {
                    setSortField('date');
                    setSortDirection('asc');
                  }
                }}>
                  <div className="flex items-center gap-2">
                    <span>Datum aangemaakt </span>
                    <FontAwesomeIcon icon={faSort} style={{ fontSize: "10px" }} />
                  </div>
                </th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {paginatedData.length ? paginatedData.map((e) => (
                <tr key={e.client_order_note_id} >
                  <td className="ps-3">
                    <CheckboxCell
                      row={{ original: e }}
                      pk="client_order_note_id"
                      selectedIds={selectedIds}
                      handleSelection={handleSelection}
                    />
                  </td>
                  <td>
                    <a
                      onClick={() => openNote(e.client_order_note_id)}
                      className="new-link-color"
                      style={{ fontSize: "14px", cursor: "pointer" }}
                    >
                      {e.subject}
                    </a>
                  </td>
                  <td className="pr-4">
                    <div className="flex flex-col" style={{ fontSize: "14px" }}>
                      <span>
                        {new Date(e.created_at).toLocaleDateString("nl-NL", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        }) + " "}
                      </span>
                      <span>
                        {new Date(e.created_at).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                    </div>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan="3" className="px-4 py-8 text-center">
                    Er zijn nog geen notities
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Paginatie */}
        <div className="pagination flex items-center justify-between mt-2 mb-4">
          <div className="d-flex gap-2">
            <select
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
                setCurrentPage(1); // reset de pagina wanneer de page size verandert
              }}
              className="page-sizer email-page-sizer"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
            <div className="page-display text-nowrap me-4">
              {filteredData.length > 0
                ? `${startIndex + 1}-${Math.min(startIndex + itemsPerPage, filteredData.length)} van ${filteredData.length}`
                : `0-0 van 0`}
            </div>
          </div>
          <div className="pagination-controls d-flex gap-1">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="pagination-btn"
            >
              <FontAwesomeIcon width={7} icon={faChevronLeft} />
            </button>
            {/* Paginaknoppen */}
            {Array.from({ length: pageCount }, (_, index) => index + 1).map(pageNumber => (
              <button
                key={pageNumber}
                onClick={() => setCurrentPage(pageNumber)}
                className={`pagination-btn ${currentPage === pageNumber ? 'active' : ''}`}
              >
                {pageNumber}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, pageCount))}
              disabled={currentPage === pageCount}
              className="pagination-btn"
            >
              <FontAwesomeIcon width={7} icon={faChevronRight} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotitieComponent;
