import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faClipboardList, faHouse } from '@fortawesome/free-solid-svg-icons';
import middleware from '../../Api/Middleware';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import PrinterIcon from "../Icons/printer.png";
import placeholder from '../Icons/empty-placeholder.jpg';
import packageIcon from '../Icons/package.png';
import clipboardWithCheckIcon from '../Icons/order-verified.png';
import homeIcon from '../Icons/home.png';
import { ButtonLightGrey } from '../../HouseStyle/Components/Buttons';
import * as Icons from "../../HouseStyle/Icons";
import { string } from 'prop-types';

const VerzendComponent = ({ orderId, orderNumber, shipmentMethod, productsToProcess = [] }) => {
  const [shipments, setShipments] = useState([]);
  const [shipmentProducts, setShipmentsProducts] = useState([]);
  const [providers, setProviders] = useState([]);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  function getDate(amount) {
    const today = new Date();
    const months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
    const month = months[today.getMonth()];
    const year = today.getFullYear();
    const date = today.getDate() - amount;
    return `${date} ${month} ${year}`;
  }
  const strip = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  const formatPrice = (price) => {
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price);
  };

  useEffect(() => {
    const fetchShipmentsAndProducts = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await middleware.get(`shipments/order?order_id=${orderId}`);
        const shipmentsData = response.data.Response || [];
        setShipments(shipmentsData);

        const productsPromises = shipmentsData.map(shipment => {
          return middleware.get(`shipments/soldProducts?shipment_id=${shipment.shipment_id}`);
        });
        const productsResponses = await Promise.all(productsPromises);

        setShipmentsProducts(productsResponses);


      } catch (error) {
        console.error('Error fetching shipments or products:', error);
        setError('Er is een fout opgetreden bij het ophalen van de verzendingen of producten');
      } finally {
        setLoading(false);
      }
    };
    fetchShipmentsAndProducts();
  }, [orderId]);

  useEffect(() => {

    const fetchProviders = async () => {
      try {
        const providersResponse = await middleware.get(`shipments/providers`);
        const providers = providersResponse.data;
        setProviders(providers);
      } catch (error) {
        console.error('Error fetching providers:', error);
      }
    };
    const fetchPackages = async () => {
      try {
        const packageResponse = await middleware.get(`shipments/packages?shipment_id=${shipments[0].shipment_id}`);
        const packages = packageResponse.data;
        setPackages(packages.data);
      } catch (error) {
        console.error('Error fetching packages:', error);
      }
    };
    fetchProviders();
    fetchPackages();
  }, [shipments]);

  const getProviderAndMethod = (shipment, providers) => {
    if (!shipment || !providers) return '';

    for (const provider of providers) {
      if (provider.methods && Array.isArray(provider.methods)) {
        const method = provider.methods.find(
          (m) => m.provider_method_id === shipment.provider_method_id
        );
        if (method) {
          return `${provider.provider_name} - ${method.name}`;
        }
      }
    }
    return '';
  };

  const PackageStatus = ({ status }) => {
    const steps = [
      {
        label: "Aangemaakt",
        completedIcon: packageIcon,
        incompleteIcon: packageIcon,
      },
      {
        label: "Verzonden",
        completedIcon: Icons.Verify,
        incompleteIcon: clipboardWithCheckIcon,
      },
      {
        label: "Afgeleverd",
        completedIcon: Icons.Home,
        incompleteIcon: homeIcon,
      },
    ];
  
    return (
      <div className="package-status-container w-100">
        {steps.map((step, i) => {
          const isCompleted = status >= i;
          return (
            <div className="packaging-status" key={i}>
              <div className="package-status-icon" id={isCompleted ? "completed" : undefined}>
                <img src={isCompleted ? step.completedIcon : step.incompleteIcon} alt={step.label} />
              </div>
              <span>{step.label}</span>
            </div>
          );
        })}
      </div>
    );
  };
  
  
  return (
    <>
      {shipments.map((shipment, index) => {
        let productsForShipment = {};

        try {
          if (shipmentProducts[index]) {
            productsForShipment = shipmentProducts[index].data.data || {};
          } else {
            productsForShipment = {};
          }
        } catch (error) {
          console.log(error);
        }

        return (
          <div className="whiteBox p-5 pb-0 pt-4 mt-4 align-center" key={index}>
            <div className="d-flex justify-content-between align-items-center pb-4 creditering-header">
              <h5 className="mb-0 fw-bold BlockTitle">Verzending: #{shipment.shipment_number}</h5>
              <ButtonLightGrey text={"Print verzendlabel"} icon={PrinterIcon} />
            </div>

            {Array.isArray(productsForShipment) && productsForShipment.length > 0 ? (
              <ul className='list-group list-group-flush mb-5'>
                {productsForShipment.map((item, idx) => {

                  const product = productsToProcess.find(
                    (p) => p.sold_product_id === item.order_sold_product_id
                  );

                  const price = product.sale_price_incl_tax == null ? product.price_incl_tax : product.sale_price_incl_tax;

                  return (
                    <li key={idx} className='verwerk-list-group-item bottom-border-line d-flex justify-content-between align-items-center gap-3 pb-2 pt-2'>
                      <div className='d-flex flex-row gap-3 flex-grow-1'>
                        <div className='d-flex align-items-center'>
                          {/* Product image */}
                          <img
                            src={
                              product.image?.path
                                ? middleware.defaults.baseURL + "/public/storage/" + product.image?.path
                                : placeholder
                            }
                            className="rounded-circle p-2 me-3"
                            height={100}
                            width={100}
                            alt="placeholder..."
                          />

                          {/* Product info */}
                          <div className='d-flex flex-column justify-content-center align-items-start'>
                            <a href={`/producten/bewerken/${product.product_id}`} target='_blank' className='new-link-color'>
                              {product.product_name}
                            </a>
                            {product.product_description && (
                              <p className='text-muted small mb-0'>
                                {strip(product.product_description)?.length < 100
                                  ? strip(product.product_description)
                                  : strip(product.product_description)?.slice(0, 100) + "..."
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Product price info */}
                      <div className='d-flex align-items-center justify-content-end gap-3 gap-xxl-5 flex-grow-1'>
                        {item.sale_amount > 0 ? (
                          <div className='d-flex flex-column'>
                            <span className='text-nowrap text-muted text-decoration-line-through text-secondary'>
                              {formatPrice(parseFloat(product.price_incl_tax).toFixed(2))}
                            </span>
                            <span>
                              {formatPrice(
                                parseFloat(
                                  product.sale_unit ? price - product.sale_amount : price * (100 - product.sale_amount) / 100
                                ).toFixed(2)
                              )}
                            </span>
                          </div>
                        ) : (
                          <span className='text-nowrap'>{formatPrice(parseFloat(price).toFixed(2))}</span>
                        )}
                        <span className='text-nowrap'>x{item.amount}</span>
                        <span className='fw-semibold'>
                          {formatPrice(
                            (
                              (product.sale_unit ? price - product.sale_amount : price * (100 - product.sale_amount) / 100)
                              * item.amount
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className="text-center p-3">
                <div className="text-center" role="status">
                  <h5>There are no product to ship...</h5>
                </div>
              </div>
            )}
            {packages.map((pkg, index) => (
              <div key={index}>
                <div className='d-flex justify-content-start pb-3 bottom-border-line'>
                  <h5 className='fw-bold'> Pakket: #{pkg.package_number}</h5>
                </div>
                <div className='shipment-status-info-container d-flex column align-center justify-content-between pt-4 pb-4'>
                  <div className='row border-right-gray col-6 packaging-info w-50'>
                    <div className='col-6'>
                      {shipmentMethod === 1 && (
                        <p>Track en trace:</p>
                      )}
                      <p>Datum aangemaakt:</p>
                      <p>Gekozen provider: </p>
                    </div>
                    <div className='col-6 pe-0 d-flex flex-column align-items-end'>
                      {shipmentMethod === 1 && (
                        <p className='new-link-color text-decoration-underline'>
                          -track and trace code -
                        </p>
                      )}
                      <p>
                        {format(
                          new Date(pkg.created_at.replace(/(\.\d{3})\d*Z$/, '$1Z')),
                          'dd MMMM yyyy HH:mm',
                          { locale: nl }
                        )}
                      </p>
                      <p>{getProviderAndMethod(shipment, providers)}</p>
                    </div>
                  </div>
                  {pkg.status !== 3 &&
                    <PackageStatus status={pkg.status} />
                  }
                </div>
              </div>
            ))}

          </div>
        )
      })}
    </>
  );

};

export default React.memo(VerzendComponent);