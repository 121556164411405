import React, { useEffect, useState } from "react";
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { set } from "lodash";
import { logDOM } from "@testing-library/react";


const BetalingComponent = ({ order, setPayRequestModalVisible }) => {
    const calculateRowTotal = (item) => {
        if (item.sale_unit === 1) {
            const discountAmount = Math.min(item.price_incl_tax, parseFloat(item.sale_amount) || 0);
            const priceAfterDiscount = Math.max(0, item.price_incl_tax - discountAmount);

            return priceAfterDiscount * item.amount;
        }
        else {
            const percentageDiscount = Math.min(100, parseFloat(item.sale_amount) || 0);
            const priceAfterDiscount = item.price_incl_tax * (1 - percentageDiscount / 100);

            return priceAfterDiscount * item.amount;
        }
    };

    const [values, setValues] = useState({});
    const CalculateValues = () => {
        if (order.orders_sold_products) {
            let total = 0;
            let subtotal = order.orders_sold_products.reduce((sum, item) => sum + calculateRowTotal(item), 0);
            setValues({ ...values, subtotal: subtotal, extra_discount: order.sale_unit ? (subtotal * (order.sale_amount / 100)) : order.sale_amount });
        }
    }

    useEffect(() => {
        CalculateValues();
    }, [order])


    const formatPrice = (price) => {
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(price);
    };

    const PricesPaymentHistory = () => {
        const totalTransactionPaid = order.transactions.reduce(
          (sum, t) => sum + Number(t.amount_paid),
          0
        );
        const totalCreditationPaid = order.creditations.reduce(
          (sum, c) => sum + (Number(c.payment_completed) !== 0 ? Number(c.restitution_total) : 0),
          0
        );
      
        const leftOverPrice = order.total_price_incl_tax - totalTransactionPaid - totalCreditationPaid;
      
        return (
          <div className="d-flex justify-content-between pt-2 pb-2">
            <div className="d-flex flex-column">
              <span className="price-title">Openstaand</span>
              {order.transactions.length > 0 && (
                <span className="price-title">Betaald</span>
              )}
              {order.creditations.length > 0 && (
                <span className="price-title">Terugbetaald</span>
              )}
            </div>
            <div className="d-flex flex-column">
              <span className="price">{formatPrice(leftOverPrice)}</span>
              {order.transactions.length > 0 && (
                <span className="price">{formatPrice(totalTransactionPaid)}</span>
              )}
              {order.creditations.length > 0 && (
                <span className="price">{formatPrice(totalCreditationPaid)}</span>
              )}
            </div>
          </div>
        );
      };
      

    console.log(order);

    return (
        <div className="whiteBox p-5 pt-4 mt-4 align-center">
            <h5 className="mb-4 fw-bold BlockTitle" style={{ color: "#A9D974" }}>Betaling</h5>
            <div className="d-flex justify-content-between">
                <div className="col-6 ps-0 pe-0 betaling-overzicht-container">
                    <div className="fw-bold betaling-overzicht-container-headers">
                        <p className="ms-3 pt-2 pb-2">Bestelling overzicht</p>
                    </div>
                    <div className="ms-3 me-3 mb-1">
                        <div className="bottom-border-gray pb-2">
                            <div className="d-flex justify-content-between pb-1">
                                <div>Totaal</div>
                                <div>{formatPrice(Number(order.total_price_incl_tax - order.delivery_cost).toFixed(2))}</div>
                            </div>
                            {values.extra_discount > 0 &&
                                <div className="d-flex justify-content-between pb-1">
                                    <div>Extra korting</div>
                                    <div className="d-flex gap-2">
                                        <div>- {formatPrice(Number(values.extra_discount).toFixed(2))}</div>
                                    </div>
                                </div>}
                            {order.shipment_method &&
                                <div className="d-flex justify-content-between pb-1">
                                    <div>Verzendkosten</div>
                                    <div>€ {order.shipment_method && order.delivery_cost}</div>
                                </div>
                            }
                        </div>

                        <div className="bottom-border-gray pt-2 pb-2">
                            <div className="d-flex justify-content-between fw-bold pb-3">
                                <div>Subtotaal</div>
                                <div>{formatPrice(Number(order.total_price_incl_tax).toFixed(2))}</div>
                            </div>
                            <div className="d-flex justify-content-between pb-1">
                                <div>Totaal excl. BTW</div>
                                <div>{formatPrice(Number(order.total_price_excl_tax).toFixed(2))}</div>
                            </div>
                            <div className="d-flex justify-content-between pb-2">
                                <div>BTW</div>
                                <div className="d-flex gap-4">
                                    <div>NL 21%</div>
                                    <div>{formatPrice(Number(order.total_price_incl_tax - order.total_price_excl_tax).toFixed(2))}</div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="d-flex justify-content-between fw-bold pt-2 pb-4">
                                <div>Totaal incl. BTW</div>
                                <div>{formatPrice(Number(order.total_price_incl_tax).toFixed(2))}</div>
                            </div>
                        </div>

                    </div>
                </div>


                {(order.transactions.length > 0 || order.creditations.length > 0) &&
                    <div className="col-6 ps-0 pe-0 betaling-overzicht-container">
                        <div className="fw-bold betaling-overzicht-container-headers">
                            <p className="ms-3 pt-2 pb-2">Betaal historie</p>
                        </div>
                        <div className="ms-3 me-3 mb-1">
                            {order.transactions.length > 0 &&
                            order.transactions.map((transaction, index) => (
                                <div key={transaction.transaction_id || index} className="bottom-border-gray pb-2">
                                    <div className="d-flex justify-content-between">
                                        <div>Bestelling: #{order.order_number}</div>
                                        <div className="text-nowrap">{formatPrice(transaction.amount_paid)}</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>Betalingsmethode: {transaction.method.method_name}</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            {format(
                                                new Date(transaction.date_paid.replace(' ', 'T')),
                                                'dd MMMM yyyy HH:mm',
                                                { locale: nl }
                                            )}
                                        </div>

                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <a href="#" className="new-link-color text-decoration-underline" onClick={() => { setPayRequestModalVisible(true) }}>Link naar betaalverzoek</a>
                                    </div>
                                </div>
                            ))
                            }

                            {/* Terugbetaling */}
                            {order.creditations.length > 0 &&
                                order.creditations.map((creditation, index) => (
                                    <div key={creditation.creditation_id || index} className="bottom-border-gray pb-2 pt-2">
                                        <div className="d-flex justify-content-between">
                                            <div>Bestelling: #{order.order_number}</div>
                                            <span className="text-nowrap" style={{ color: "red" }}>
                                                - {formatPrice(creditation.restitution_total)}
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>Betalingsmethode: {creditation.payment_method ? creditation.payment_method : 'N.v.t.'}</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                {format(
                                                    new Date(
                                                        creditation.created_at.replace(/(\.\d{3})\d*Z$/, '$1Z')
                                                    ),
                                                    'dd MMMM yyyy HH:mm',
                                                    { locale: nl }
                                                )}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <a
                                                href="#"
                                                className="new-link-color text-decoration-underline"
                                                onClick={() => {
                                                    setPayRequestModalVisible(true);
                                                }}
                                            >
                                                Link naar betaalverzoek
                                            </a>
                                        </div>
                                    </div>
                                ))
                            }
                            {PricesPaymentHistory()}
                        </div>
                    </div>
                }

            </div>

        </div>
    )
}
export default BetalingComponent;
