import React, { useState, useEffect } from "react";
import PageTemplate from "../../../Templates/PageTemplate";
import middleware from "../../../Api/Middleware";
import { useNavigate, useParams } from "react-router";
import Key from "./Icons/key.svg";
import Eye from "./Icons/eye.svg";
import EyeClosed from "./Icons/eye-closed.svg";
import "./EditUsers.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faBan, faUnlockKeyhole } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import ToastSuccess from "../../../Toasts/ToastSuccess";
import ToastError from "../../../Toasts/ToastError";
import PasswordRequirementsModal from './Modals/PasswordRequirementsModal';
import SecurityCodeModal from './Modals/SecurityCodeModal';
import RoleInfoModal from './Modals/RoleInfoModal';
import ToastInfo from "../../../Toasts/ToastInfo";
import DeactivateUserModal from "./Modals/DeactivateUserModal";
import { BackButton, ButtonBlue } from "../../../HouseStyle/Components/Buttons";
import { BorderedTextInput, RegularTextInput } from "../../../HouseStyle/Components/Inputs";
import { BorderedDropDown } from "../../../HouseStyle/Components/DropDowns";

const roleOptions = [
  { value: 0, label: "Alleen bestellingen" },
  { value: 1, label: "Medewerker" },
  { value: 2, label: "Leidinggevende" },
  { value: 3, label: "Manager" },
  { value: 4, label: "Admin" },
];

const languageOptions = [
  {value: "NL", label: "Nederlands"},
  {value: "BE", label: "Belgisch"},
  {value: "DE", label: "Deutsch"},
  {value: "FR", label: "Français"}
];

const EditUsers = ({ ownUserRole, ownUserId }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [updateUserValues, setUpdateUserValues] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [languageDropdown, setLanguageDropdown] = useState();
  const [rolDropdown, setRolDropdown] = useState();
  const [showSecurityCode, setShowSecurityCode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { user_id } = useParams();
  const [modals, setModals] = useState({
    password: false,
    role: false,
    securityCode: false,
    deactivateUser: false
  });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\S]{8,}$/;

  const toggleModal = (modal) => setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));


  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchUser] = await Promise.all([
        middleware.get(`users?user_id=${user_id}`),
      ]);
      setUser(fetchUser.data);
      setUpdateUserValues(fetchUser.data)
      setLoading(false)
    } catch (error) {
      ToastError(error.message)
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const validateEmail = (email) => {
    if (!emailRegex.test(email)) {
      setEmailError("Ongeldig e-mailadres.");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = (password) => {
    if (!passwordRegex.test(password)) {
      setPasswordError("Wachtwoord moet minimaal 8 tekens bevatten, inclusief hoofdletters, kleine letters, een cijfer en een speciaal teken.");
    } else {
      setPasswordError("");
    }
  };

  const hasChanges = () => {
    for (const key in updateUserValues) {
      if (updateUserValues[key] !== user[key]) {
        console.log(updateUserValues[key], user[key]);
        
        return true;
      }
    }
    return false;
  };

  const generateRandomPassword = async (event) => {
    event.preventDefault();
  
    const length = 15; // Minimum length is 8, so 15 is sufficient
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const digits = "0123456789";
    const specialChars = "!@#$%^&*";
    
    // Ensure each type is included at least once
    let password = [
      lowercase[Math.floor(Math.random() * lowercase.length)],
      uppercase[Math.floor(Math.random() * uppercase.length)],
      digits[Math.floor(Math.random() * digits.length)],
      specialChars[Math.floor(Math.random() * specialChars.length)],
    ];
  
    // Fill the rest of the password
    const allChars = lowercase + uppercase + digits + specialChars;
    while (password.length < length) {
      password.push(allChars[Math.floor(Math.random() * allChars.length)]);
    }
  
    // Shuffle the password to ensure randomness
    password = password.sort(() => Math.random() - 0.5).join('');
  
    // Update state
    setUpdateUserValues({ ...updateUserValues, password });
    await updateUser();
  
    try {
      await navigator.clipboard.writeText(password);
      ToastInfo("Wachtwoord gekopieerd naar clipboard", 1500);
    } catch (err) {
      console.error("Failed to copy password: ", err);
    }
  };

  const generateRandomSecurityCode = async event => {
    event.preventDefault();
    const length = 6;
    const charset = "0123456789";
    let code = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      let char = charset[randomIndex];      
      code += char;
    }

    setUpdateUserValues({...updateUserValues, "security_code": code});
    await updateUser();

    try {
      await navigator.clipboard.writeText(code);
      ToastInfo(`Beveiligingspin gekopieerd naar clipboard`, 1500);
    } catch (err) {
      console.error('Failed to copy password: ', err);
    }
  }

  const updateUser = async () => {
    try {
      if(hasChanges()) {
        if(emailError == "" && passwordError == "") {
          const [putUser] = await Promise.all([
            middleware.put('users', updateUserValues)
          ])
          ToastSuccess("Gebruikergegevens bijgewerkt")
        } else if (emailError !== "") {
          ToastError(emailError)
        } else if (passwordError !== "") {
          ToastError(passwordError)
        }
      }
      else {
        console.log(user, updateUserValues);
        
      }
    } catch (error) {
      ToastError(error.message);
    }
  }

  console.log(user);
  console.log(updateUserValues);
  
  

  const changeUser = async (column, event) => {
    const value = event.target.value;

    if (column === "email") validateEmail(value);
    if (column === "password") validatePassword(value);
    
    setUpdateUserValues({...updateUserValues, [column]: event.target.value})
  }


  return (
    <>
      <PasswordRequirementsModal show={modals.password} onHide={() => toggleModal("password")}/>
      <SecurityCodeModal show={modals.securityCode} onHide={() => toggleModal("securityCode")}/>
      <RoleInfoModal show={modals.role} onHide={() => toggleModal("role")}/>
      <DeactivateUserModal show={modals.deactivateUser} onHide={() => toggleModal("deactivateUser")} updateUserValues={updateUserValues} setState={setUpdateUserValues} updateUser={updateUser}/>

      <PageTemplate navbarTitle={"Gebruiker"} pageIsLoading={loading}>
        <div className="w-100">
          <div className="d-flex flex-row justify-content-between mb-3">
            <div className=" d-flex flex-row">
              <BackButton onClickFunction={() => navigate(-1)} text={"Terug"} disabled={false}/>
              <div className="d-flex flex-column align-items-center">
                {updateUserValues.account_status === 0 ? <p className="mb-1 p-0">Gepubliceerd</p> : <p className="mb-1 p-0">Gearchiveerd</p>}
                <label className="switchActiveProduct">
                  <input type="checkbox" checked={updateUserValues.account_status}
                    onChange={(e) => toggleModal("deactivateUser")} />
                  <span className="sliderActiveProduct round">
                  </span>
                </label>
              </div>
            </div>
            <ButtonBlue text={"Opslaan"} disabled={false} onClickFunction={(e) => updateUser()}/>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="whiteBox w-50 me-2 p-5">
              <p className="TableTitle ">Systeemgebruiker aanpassen</p>
              <div className="w-50 InputRow flex-column">
                <BorderedTextInput  placeholder='Voornaam' inputValue={updateUserValues.first_name} setInputValue={(e) => setUpdateUserValues(prev => ({...prev, 'first_name': e}))} size='l' label={"Voornaam"}/>
                <BorderedTextInput  placeholder='Achternaam' inputValue={updateUserValues.last_name} setInputValue={(e) => setUpdateUserValues(prev => ({...prev, 'last_name': e}))} size='l' label={"Achternaam"}/>
                <BorderedTextInput  placeholder='Geboortedatum' inputValue={updateUserValues.last_name} setInputValue={(e) => setUpdateUserValues(prev => ({...prev, 'last_name': e}))} size='l' label={"Geboortedatum"}/>
                <BorderedDropDown
                  placeholder="Selecteer een land"
                  label={"Taal"}
                  options={[
                    ...languageOptions.map((language) => ({
                      title: language.label,
                      value: language.value,
                      onClick: () => {
                        setUpdateUserValues({
                          ...updateUserValues,
                          language: language.value
                        });
                      }
                    }))
                  ]}
                  selectedValue={languageDropdown}
                  setSelectedValue={setLanguageDropdown}
                />
                <BorderedDropDown
                    placeholder="Selecteer een rol"
                    label={"Rol"}
                    options={[
                      ...roleOptions.map((roles) => ({
                        title: roles.label,
                        value: roles.value,
                        onClick: () => {
                          setUpdateUserValues({
                            ...updateUserValues,
                            roles: roles.value
                          });
                        }
                      }))
                    ]}
                    selectedValue={updateUserValues.role}
                    setSelectedValue={(e) => setUpdateUserValues(prev => ({...prev, 'role': e}))}
                />
              </div>
            </div>
            <div className="whiteBox w-50 ms-2 p-5">
            </div>
          </div>
        </div>
      </PageTemplate>
    </>
  );
};

export default EditUsers;