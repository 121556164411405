import React, { useMemo, useState, useEffect } from "react";
import TablePreset from "../../../Table/TablePreset";
import { useTable } from "react-table";
import NoteModal from "../CustomerModals/NoteModal";
import middleware from "../../../Api/Middleware";
import { useParams } from "react-router";
import Trash from "../Icons/trash-can.svg";
import Pin from "../Icons/thumbtack-solid.svg";
import CancelPin from "../Icons/thumbtack-slash-solid.svg";
import editIcon from "../Icons/pen-to-square-solid.svg";
import EditNoteModal from "../CustomerModals/EditNoteModal";
import ConfirmNoteDeleteModal from "../CustomerModals/ConfirmModalDeleteNote";
import ToastError from "../../../Toasts/ToastError";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import { ButtonBlue } from "../../../HouseStyle/Components/Buttons";
import AddNoteModal from "../CustomerModals/AddNoteModal";

const Notes = ({ dataUpdated, setDataUpdated }) => {
  const { internal_id } = useParams();
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [order, setOrder] = useState({ orderBy: "", direction: "" });
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noteData, setNoteData] = useState([]);
  const [showNoteEditModal, setShowNoteEditModal] = useState(false);
  const [showConfirmNoteDeleteModal, setShowConfirmNoteDeleteModal] =
    useState(false);
  const [showQuotationModal, setShowQuotationModal] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [formData, setFormData] = useState({
    internal_id: internal_id,
    note_title: "",
    note: "",
  });
  const [selectedNoteData, setSelectedNoteData] = useState(null); // State for selected note
  const [selectedIds, setSelectedIds] = useState([]);

  const fetchAll = async () => {
    setLoading(true);
    try {
      const [noteRes] = await Promise.all([
        middleware.get(
          `sp/consumerNotes?searchTerm=${internal_id}&specificSearchColumn=internal_id&page_size=${pagination.page_size}&page=${pagination.page}`
        ),
      ]);
      setNoteData(noteRes.data.data);
      setPagination({
        ...pagination,
        max_items: noteRes.data.total,
        begin_of_page: noteRes.data.from,
        end_of_page: noteRes.data.to,
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds((previous) => [...previous, productId]);
    } else {
      setSelectedIds((previous) => previous.filter((id) => id !== productId));
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    fetchAll();
  }, [pagination.page, pagination.page_size])

  useEffect(() => {
    fetchAll();
  }, [dataUpdated]);

  const handlePinClick = async (row) => {
    console.log(row);
    const [postNoteRes] = await Promise.all([
      middleware.put(`consumers/notes?client_note_id=${row.client_note_id}`, {
        pinned: !row.pinned,
      }),
    ]);
    fetchAll();
    setDataUpdated(true);
  };

  const handleDeleteClick = (row) => {
    setRowToDelete(row);
    setShowConfirmNoteDeleteModal(true);
  };

  const handleEditClick = (row) => {
    setRowToEdit(row);
    setShowNoteEditModal(true);
  };

  const handleDeleteConfirmed = async () => {
    if (rowToDelete) {
      console.log(rowToDelete);

      try {
        // Replace 'offer_id' with the correct identifier field for your data
        await middleware.delete(
          `consumers/notes?client_note_id=${rowToDelete.client_note_id}`
        );
        ToastSuccess("Notitie succesvol verwijderd");
        setShowConfirmNoteDeleteModal(false);
        fetchAll(); // Re-fetch the data to update the table
        setDataUpdated(true);
      } catch (error) {
        console.error("Error met het verwijderen van de notitie:", error);
        ToastError(
          "Er is een fout opgetreden bij het verwijderen van de notitie."
        );
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => <div className="fw-bold">Titel</div>,
        accessor: "noteTitel",
        Cell: ({ row }) => {
          const handleNoteClick = () => {
            setShowNoteModal(true);
            setSelectedNoteData(row.original); // Save the clicked note's data
          };

          return (
            <div className="d-flex flex-column">
              <p
                className="mb-0 text-break cursorPointer"
                onClick={handleNoteClick}
                style={{ color: "#009fe3" }}
              >
                {row.original.note_title}
              </p>
              {row.original.note?.length > 250 ? (
                <p className="text-break" style={{ maxWidth: "400px" }}>
                  {row.original.note?.slice(0, 250)}...
                </p>
              ) : (
                <p className="text-break" style={{ maxWidth: "400px" }}>{row.original.note}</p>
              )}
            </div>
          );
        },
      },
      {
        Header: () => <div className="text-center fw-bold">Datum</div>,
        accessor: "noteCreatedDate",
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              <p className="mb-0">{row.original.created_date}</p>
              <p>{row.original.created_time}</p>
            </div>
          );
        },
      },
      {
        Header: () => <div className="text-center fw-bold">Gemaakt door</div>,
        accessor: "noteOwner",
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              <p className="mb-0">{row.original.user}</p>
            </div>
          );
        },
      },
      {
        Header: () => "",
        accessor: "Pin",
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column">
              <img
                className="trashImg"
                onClick={() => handlePinClick(row.original)}
                src={row.original.pinned == 0 ? Pin : CancelPin}
              />
            </div>
          );
        },
      },
      {
        Header: () => "",
        accessor: "Edit",
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column">
              <img
                className="trashImg"
                onClick={() => handleEditClick(row.original)}
                src={editIcon}
              />
            </div>
          );
        },
      },
      {
        Header: () => "",
        accessor: "Delete",
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column">
              <img
                className="trashImg"
                onClick={() => handleDeleteClick(row.original)}
                src={Trash}
              />
            </div>
          );
        },
      },
    ],
    [order.orderBy, order.direction, selectedIds, setSelectedIds, noteData]
  );

  const tableInstance = useTable({ columns, data: noteData });

  if (loading) {
    return <h2 className="m-4 loader"></h2>;
  }
  console.log(pagination)
  return (
    <div className="my-2 px-4">
      <NoteModal
        openNote={showNoteModal}
        data={selectedNoteData}
        closeNote={() => setShowNoteModal(false)}
      />
      <AddNoteModal
        openAddNote={showAddNoteModal}
        closeAddNote={() => setShowAddNoteModal(false)}
        data={formData}
        setData={setFormData}
        setDataUpdated={setDataUpdated}
        fetchAll={fetchAll}
      />
      <EditNoteModal
        openEditNote={showNoteEditModal}
        setDataUpdated={setDataUpdated}
        data={rowToEdit}
        closeEditNote={() => setShowNoteEditModal(false)}
      />
      <ConfirmNoteDeleteModal
        openConfirmNoteDelete={showConfirmNoteDeleteModal}
        closeConfirmNoteDelete={() => setShowQuotationModal(false)}
        showConfirmNoteDeleteModal={showConfirmNoteDeleteModal}
        setShowConfirmNoteDeleteModal={setShowConfirmNoteDeleteModal}
        rowToDelete={rowToDelete}
        onDeleteConfirmed={handleDeleteConfirmed}
      />
      <div>
        <div className="d-flex flex-row">

        </div>
      </div>
      <div className="notesTable">
        <div className="my-3 tablesCustomerEdit h-100">
          <TablePreset
            data={[
              {
                'tableInstance': tableInstance,
                'pagination': pagination,
                'primary_key': 'client_note_id',
              },
            ]}
            rightButtons={[{ title: "Notitie aanmaken", function: setShowAddNoteModal }]}
            setPagination={setPagination}
            handleSelection={handleSelection}
            noCheckbox={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Notes;
