import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  faPencil,
  faPlus,
  faX,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";
import middleware from "../../../Api/Middleware";
import ToastError from "../../../Toasts/ToastError";
import { BorderedTextInput } from "../../../HouseStyle/Components/Inputs";
import { BorderedDropDown } from "../../../HouseStyle/Components/DropDowns";
import { ButtonBlue } from "../../../HouseStyle/Components/Buttons";

const AddPointsModal = ({
  openAddPoints,
  closeAddPoints,
  setUpdatedLoyalty,
}) => {
  const [showAddPoints, setShowAddPoints] = useState(false);
  const [points, setPoints] = useState("");
  const [reasons, setReasons] = useState([]);
  const [reason, setReason] = useState(0);
  const [newReason, setNewReason] = useState("");
  const [addNewReason, setAddNewReason] = useState(false);
  const { internal_id } = useParams();
  const fetchAll = async () => {
    const reasonsResponse = await middleware.get(
      "/loyaltypointreasons?type=0&base=1"
    );
    setReasons(reasonsResponse.data.data);
  };
  useEffect(() => {
    if (openAddPoints) {
      fetchAll();
      setShowAddPoints(true);
    }
  }, [openAddPoints]);

  const clearFields = () => {
    setPoints("");
    setAddNewReason(false);
    setReason("");
    setNewReason("");
  }

  useEffect(() => {
    if (reason == "overig" || reason == "overig") {
      setAddNewReason(true);
    } else {
      setAddNewReason(false);
    }
  }, [reason]);
  const closeAddPointsModal = () => {
    setShowAddPoints(false);
    clearFields();
    closeAddPoints(true);
  };

  const submitNewAddPoints = async (value) => {
    if (addNewReason && newReason == "") {
      ToastError("Vul een nieuwe reden in.");
      return;
    }
    if (reasons.some(r => r.reason === newReason)) {
      ToastError(`${newReason} bestaat al, selecteer het via de dropdown`);
      return;
    }
    if (points < 1) {
      ToastError("Je mag niet meer dan 1 miljoen punten toevoegen.");
      return;
    }
    if (points > 1000000) {
      ToastError("Je mag niet meer dan 1 miljoen punten toevoegen.");
      return;
    }
    try {
      // Convert points to a string with a plus sign
      const pointsWithPlus = `+${points}`;
      if (addNewReason) {
        let res;
        res = await middleware.post("loyaltypointreasons", {
          reason: newReason,
          type: 0,
        });
        let reason_id = res.data.reason_id;
        await middleware
          .post("loyaltymutations", {
            internal_id: internal_id,
            amount: pointsWithPlus, // Send points with a plus sign
            reason_id: reason_id,
          })
          .then(() => setUpdatedLoyalty(true) + closeAddPointsModal());
      } else {
        await middleware
          .post("loyaltymutations", {
            internal_id: internal_id,
            amount: pointsWithPlus, // Send points with a plus sign
            reason_id: reason,
          })
          .then(() => setUpdatedLoyalty(true) + closeAddPointsModal());
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Modal
      id="modalBlueprint"
      show={showAddPoints}
      onHide={() => closeAddPointsModal()}
      className="colormodals"
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header className="modalTop ps-5 pt-5 justify-content-between">
        <h3>Punten toevoegen</h3>
        <button
          variant="transparent"
          className=" btn"
          onClick={() => closeAddPointsModal()}
        >
          <FontAwesomeIcon icon={faXmark} className="closeModal" />
        </button>
      </Modal.Header>
      <Modal.Body className="modalCenter" style={{ height: "300px" }}>
        <div className="col-12">
          <div className="d-flex flex-column col-4 mb-3">
            <div className="d-flex align-items-center">
              <BorderedTextInput
                label={"Punten toevoegen"}
                inputValue={points}
                setInputValue={(value) => {
                  const reg = /^$|^[1-9][0-9]*$/;
                  const newValue = value;
                  if (reg.test(newValue)) {
                    setPoints(newValue);
                  }
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-2 col-12">
            <label className="mb-2 fw-medium">
              Reden
              <span className="ms-2 p-2" role="button"></span>
            </label>
            <BorderedDropDown
              selectedValue={reason}
              placeholder="Kies een reden"
              setSelectedValue={(value) => {
                setNewReason("");
                setReason(value, "reason")
              }

              }
              options={reasons.length > 0 ?
                [
                  ...reasons.map((reason, index) => ({
                    key: index,
                    value: reason.reason_id,
                    title: reason.reason,
                  })),
                  {
                    value: "overig",
                    title: "Overig",
                  }
                ] :
                [{
                  value: "overig",
                  title: "Overig",
                }]
              }
            />
            {addNewReason && (
              <BorderedTextInput
                placeholder="Vul een reden in"
                inputValue={newReason}
                setInputValue={(value) => setNewReason(value, "newReason")}
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottom">
        <div className="d-flex">
          <ButtonBlue
            size="xs"
            text={"Opslaan"}
            disabled={
              (reason == 0 && newReason === "") || points == "" || (reason == "overig" && newReason === "")
            }
            onClickFunction={(value) => submitNewAddPoints(value)}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPointsModal;
