import React, { useState, useEffect, useMemo, useCallback } from "react";
import middleware from "../../Api/Middleware";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import "../../Main/Main.css";
import "./EditBlueprints.css";
import Loading from "../../LoadingScreen/LoadingScreen";
import * as Icons from "../../HouseStyle/Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faPen,
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import TableDrag from "../../Table/TableDrag/TableDrag";
import { Modal } from "react-bootstrap";
import PageTemplate from "../../Templates/PageTemplate";
import TablePreset from "../../Table/TablePreset";
import CheckboxHeader from "../../Table/TableComponents/CheckboxHeader";
import SortableHeader from "../../Table/TableComponents/SortableHeader";
import { useTable } from "react-table";
import CheckboxCell from "../../Table/TableComponents/CheckboxCell";
import { BorderedTextInput, RegularTextInput } from "../../HouseStyle/Components/Inputs";
import { ButtonBlue, ButtonLightGrey } from "../../HouseStyle/Components/Buttons";
import ToastError from "../../Toasts/ToastError";
import ToastSuccess from "../../Toasts/ToastSuccess";

function EditBlueprints() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [show, setShow] = useState(false);
  const [selectedSizeIds, setSelectedSizeIds] = useState([]); // usestate for the selected checkboxes
  const [loading, setLoading] = useState(true); // useState for page loading
  const [searchengine, setSearchengine] = useState("");
  const [optionChosen, setOptionChosen] = useState("");
  const [editBlueprintName, setEditBlueprintName] = useState("");
  const [blueprintData, setBlueprintData] = useState([]);
  const [blueprintTitle, setBlueprintTitle] = useState("");
  const [oldSizes, setOldSizes] = useState([]); // useState for all products in the database
  const [sizes, setSizes] = useState([]); // useState for all products in the database
  const [dataEditSizeModal, setDataEditSizeModal] = useState([]);
  const [sizeName, setSizeName] = useState("");
  const [sizeNameNew, setSizeNameNew] = useState("");
  const [sizeNewSortingOrder, setSizeNewSortingOrder] = useState();
  const [showEditSizeModal, setShowEditSizeModal] = useState(false);
  const [showNewSizeModal, setShowNewSizeModal] = useState(false);
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  }); // the useState for the pagination for the table
  const [selectedIds, setSelectedIds] = useState([]);
  const [order, setOrder] = useState({ orderBy: '', direction: '' }); // useState for ordering the columns in the table
  const [searchBar, setSearchBar] = useState("");
  // handles to close the de-activate modal
  const handleCloseEditSize = () => setShowEditSizeModal(false);
  const handleCloseNewSize = () => setShowNewSizeModal(false);
  // fetched all the products and the stock an the colors from the database
  const fetchAll = async () => {
    try {
      const storedData = localStorage.getItem("Blueprintdata");
      if (!storedData) {
        console.log("Error: Blueprint data not found in local storage");
      } else {
        setBlueprintData(JSON.parse(storedData));
        setBlueprintTitle(JSON.parse(storedData).title);
      }
      setLoading(true);
      const searchQuery = searchBar.trim() ? `&searchTerm=${encodeURIComponent(searchBar.trim())}&noSearch=["primary_key","version","size_id","status","updated_at","created_at","updator_user_id","updator_user_id"]` : "";
      const [fetchSizes] = await Promise.all([
        middleware.get(`sp/sizesTableAll?page=${pagination.page}&page_size=${pagination.page_size}${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}` : ''}${searchQuery}&filtering=size_id=${JSON.parse(storedData).size_ids}|status=1`),
      ]);

      // const updatedSizesOrder = fetchSizes.data.data.map((item, index) => ({
      //   ...item,
      //   sorting_order:
      //     index +
      //     (pagination.page === 1
      //       ? 0
      //       : pagination.page_size * (pagination.page - 1)) +
      //     1, // updating the sorting order based on position
      // }));

      // const isOrderChanged = fetchSizes.data.data.some((item, index) => {
      //   return item.sorting_order !== updatedSizesOrder[index].sorting_order;
      // });

      // if (isOrderChanged) {
      //   await middleware.put(`products/sizes`, {
      //     bulk_data: updatedSizesOrder,
      //   });
      // }
      

      setSizes(fetchSizes.data.data);
      setOldSizes(fetchSizes.data.data);
      setPagination({ ...pagination, "max_items": fetchSizes.data.total, "begin_of_page": fetchSizes.data.from, "end_of_page": fetchSizes.data.to, })// sets the pagination usestate with the max items begin of the page and the end of the page
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  
  
  // function that does an api call to only get the sizes the user searched for and updates whenever the colorsearchengine changes
  useEffect(() => {
    try {
      if (searchBar.trim() === "" && searchBar === "") {
        // Code to fetch all when searchengine is empty
        fetchAll();
      } else if (searchBar.trim() !== "") {
        fetchAll();
      } 
    } catch (error) {
    if (error.response.status === 401) {
      cookies.remove("token", { path: "/" });
      navigate("/");
    }
  }
    
}, [searchBar]);

  // useEffect that fires every time the pagination.page_size or pagination.page changes and on every fire it uses the fetchall function
  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page, order]);

  const submitEditSizes = async (event) => {
    event.preventDefault();
  
    try {
      if (!sizeName || sizeName === dataEditSizeModal.main) {
        return;
      }
      console.log(dataEditSizeModal);
      
      await middleware.put(`products/sizes`, {
        primary_key: dataEditSizeModal.primary_key,
        main: sizeName,
      });

      setShowEditSizeModal(false);
      fetchAll();
    } catch (error) {
      console.error(error);
    }
  };

  const submitSizes = async (event) => {
    event.preventDefault();

    try {
      const sizeResponse = await middleware.post(`products/sizes`, {
        main: sizeNameNew,
        sorting_order: sizeNewSortingOrder,
      });

      const newData = await putSizesBlueprint(sizeResponse.data.size_id);
      if (!newData) {
        console.error("Error while updating");
        return;
      }

      localStorage.setItem("Blueprintdata", JSON.stringify(newData));
      setSizeNameNew("");
      setSizeNewSortingOrder("");
      setShowNewSizeModal(false);
      fetchAll();
    } catch (error) {
      console.error(error);
    }
  };

  const putSizesBlueprint = async (newSizeId) => {
    try {
      const res = await middleware.put(`products/sizes/blueprints`, {
        blueprint_id: blueprintData.blueprint_id,
        size_ids:
          (blueprintData.size_ids ? blueprintData.size_ids + "," : "") +
          newSizeId,
      });
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const putNameBlueprint = async (title) => {
    try {
      const res = await middleware.put(`products/sizes/blueprints`, {
        blueprint_id: blueprintData.blueprint_id,
        title: title,
      });
      localStorage.setItem("Blueprintdata", JSON.stringify(res.data));
      setBlueprintData(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const openSizeModal = (modal) => {
    if (modal == "maat") {
      setShowNewSizeModal(true);
    }
  };

  const handleSelection = (sizeId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, sizeId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== sizeId));
    }
  };

  const deactivateBlueprintSize = async () => {
    try {
      const promises = selectedIds.map(id => 
        middleware.put(`products/sizes`, {
        primary_key: id,
        status: 0,
      }));
      await Promise.all(promises);
      setSelectedIds([]);
      // Optionally, you can refresh the data or perform other actions here
      fetchAll();
    } catch (error) {
      console.error("Error deactivating sizes:", error);
    }
  }

  const handleSearchChange = useCallback((value) => {
    setSearchBar(value);
    setPagination(prev => ({ ...prev, page: 1 }));
  }, []);
  
  const columns = useMemo(
    () => {
      let columnsArray = [
        {
          Header: (
            <CheckboxHeader currentItem={sizes} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'primary_key'}>
            </CheckboxHeader>
          ),
          accessor: "select",
          Cell: ({ row }) => {
            return <CheckboxCell row={row} selectedIds={selectedIds} pk={'primary_key'} />
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field="main" pos={'left'}>
              Maatserie
            </SortableHeader>
          ),
          accessor: "main",
          Cell: ({ row }) => {
            return <div 
            className="text-left FlatTextLink "
            onClick={() => {
              setShowEditSizeModal(true);
              setSizeName(row.original.main);
              setDataEditSizeModal(row.original);
            }}>{row.original.main}</div>;
          }
        },
      ];
      return columnsArray;
    },
    [order.orderBy, order.direction, selectedIds, setSelectedIds, sizes]
  );

  //   Table instance
  const tableInstance = useTable({ columns, data: sizes });

  // if (loading) {
  //   return (
  //     <div
  //       className={`loading-container ${
  //         loadingScreen ? "fade-in" : "fade-out"
  //       }`}
  //     >
  //       <Loading />
  //     </div>
  //   );
  // }

  function handleShow() {
    if (selectedIds.length > 0) {
      setShow(true);
    } else {
      ToastError("Geen maten geselecteerd, selecteer een maat om te deactiveren");
    }
  }

  // const ChangeStatus = async (newStatus) => {
  //   try {
  //     const change = await middleware.put("/products", {
  //       blueprint_id: blueprintData.blueprint_id,
  //       status: newStatus,
  //     });
  //     setProduct(change.data);
  //     ToastSuccess("Status product aangepast");
  //   }
  //   catch (error) {
  //     ToastError(error);
  //     console.log(error);
  //   }
  // };

  const ChangeStatus = async (status) => {
    try {
      const res = await middleware.put(`products/sizes/blueprints`, {
        blueprint_id: blueprintData.blueprint_id,
        status: status,
      });
      localStorage.setItem("Blueprintdata", JSON.stringify(res.data));
      setBlueprintData(res.data);
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} centered backdrop="static" size="xl">
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className="ms-3 BlockTitle ">Let op!</Modal.Title>
          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={handleCloseEditSize}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter pb-0 justify-content-around">
          <div className="ms-3">
            U gaat {selectedIds.length}{" "}
            {selectedIds.length === 1 ? "item" : "items"} de-activeren. Weet U
            dat zeker?
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
          <div className="d-flex flex-row">
            <div className="me-3">
              <ButtonLightGrey text={"Annuleren"} className="ms-3 btn bg-danger text-white" onClickFunction={() => setShow(false)}/>
            </div>
            <ButtonBlue
              text={"De-activeren"}
              onClickFunction={() => {
                setShow(false);
                deactivateBlueprintSize();
              }}
            />
            </div>
        </Modal.Footer>
      </Modal>

      <Modal
        id="modalBlueprint"
        show={showEditSizeModal}
        onHide={handleCloseEditSize}
        centered
        size="xl"
        backdrop="static"
      >
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className="ms-3 BlockTitle ">Edit maat</Modal.Title>
          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={handleCloseEditSize}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <form>
          <Modal.Body className="modalCenter pb-0 justify-content-around">
            <div className="w-100">
              <div>
                <label className="FlatText">Maat naam*</label>
                <BorderedTextInput
                  className="optionsList optionListWidt text-center mb-3 w-100"
                  placeholder="Maat naam"
                  name="size_name"
                  inputValue={sizeName}
                  setInputValue={setSizeName}
                ></BorderedTextInput>
                <small className="BeneathFlatText">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="text-warning me-1"
                  ></FontAwesomeIcon>
                  Let op bij het opslaan van deze maat naam zou het doorgevoerd
                  worden naar waar deze naam aan gekoppelt is
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="text-warning ms-1"
                  ></FontAwesomeIcon>
                </small>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modalBottom">
            <ButtonBlue text={"Opslaan"} onClickFunction={(e) => submitEditSizes(e)}/>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        id="modalBlueprint"
        show={showNewSizeModal}
        onHide={handleCloseNewSize}
        centered
        size="xl"
        backdrop="static"
      >
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className="ms-3 BlockTitle">Nieuwe maat</Modal.Title>
          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={handleCloseNewSize}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <form>
          <Modal.Body className="modalCenter pb-0 justify-content-around">
            <div className="w-100">
              <div className="mb-3">
                <label className="FlatText">Maat naam*</label>
                <BorderedTextInput
                  placeholder="Maat naam"
                  required={true}
                  inputValue={sizeNameNew}
                  setInputValue={setSizeNameNew}
                ></BorderedTextInput>
              </div>
              <div>
                <label className="FlatText">Sorteer volgorde*</label>
                <BorderedTextInput 
                  placeholder="Sorteer volgorde"
                  required={true}
                  setInputValue={setSizeNewSortingOrder}
                  inputValue={sizeNewSortingOrder}
                ></BorderedTextInput>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modalBottom">
            <ButtonBlue text={"Opslaan"} onClickFunction={(e) => submitSizes(e)}/>
          </Modal.Footer>
        </form>
      </Modal>

      <PageTemplate navbarTitle={"Bewerken blueprint"} pageIsLoading={loading}>
        <div className="w-100">
          <div className="d-flex justify-content-between flex-column mb-4  editBlueprintOverview">
            <div className="d-flex flex-row justify-content-start">
              {editBlueprintName == false ? (
                <div className="d-flex flex-row mb-3">
                  <button
                    className="btn bg-white fw-semibold rounded-pill"
                    onClick={() => navigate("/warehouse/instellingen/blauwdrukken")}
                  >
                      <FontAwesomeIcon icon={faChevronLeft}/>
                  </button>
                  <h3 className="mb-0 ms-3 d-flex">
                    {blueprintData.title || blueprintTitle}
                  </h3>
                  <div className="editIconBlueprintName">
                    <FontAwesomeIcon
                      className=" ms-2 d-flex flex-row hover"
                      onClick={() => setEditBlueprintName(true)}
                      style={{ width: "15px", height: "15px" }}
                      icon={faPen}
                    />
                  </div>
                </div>
              ) : (
                <div className="mb-3 w-25">
                  <BorderedTextInput
                    placeholder="Blueprint titel"
                    name="colorName"
                    inputValue={blueprintTitle ?? ''}
                    setInputValue={setBlueprintTitle}
                    style={{ height: "38px" }}
                    updateFunction={() => {
                      putNameBlueprint(blueprintTitle);
                      setEditBlueprintName(false);
                    }}
                    updateWaitTimer={10000}
                  />
                </div>
              )}
              <div className="d-flex flex-column align-items-center ms-3">
                {blueprintData.status ? <p className="mb-1 p-0">Gepubliceerd</p> : <p className="mb-1 p-0">Gearchiveerd</p>}
                <label className="switchActiveProduct">
                  <input type="checkbox" checked={blueprintData.status ?? false}
                    onChange={(e) => ChangeStatus(e.target.checked)} />
                  <span className="sliderActiveProduct round">
                  </span>
                </label>
              </div>
            </div>
            <div className=" d-flex justify-content-start w-100 flex-wrap">
              <div className="editBlueprint">
                <TablePreset
                  enableDrag={true}
                  apiEndPoint={"products/sizes"}
                  data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'primary_key'}]}
                  tableLoading={loading}
                  actionOptions={[{title: "Deactiveren", function: () => handleShow()}]}
                  searchBar={[{ shown: true, searchFunction: handleSearchChange}]}
                  handleSelection={handleSelection}
                  setPagination={setPagination}
                  // rightDropdowns={[{
                  //     placeholder: 'Filter op categorie',
                  //     options: [{title: "Optie1"}, {title: "Optie2"}]
                  // }]}
                  leftButtons={[]}
                  rightButtons={[{ title: 'Nieuwe maat', function: () => openSizeModal("maat") }]}
                />
              </div>
            </div>
          </div>
        </div>
      </PageTemplate>
    </>
  );
}

export default EditBlueprints;
