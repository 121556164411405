import React, { useState } from "react";
import { BorderedNumberInput } from "../../../../HouseStyle/Components/Inputs";

const ThirdBox = (props) => {
  const [borderedNumberInputValue, setBorderedNumberInputValue] = useState(
    props?.stock?.amount ?? 0
  );

  return (
    <>
      <div
        className={
          !props.edit ? "whiteBox p-5 h-100" : "whiteBox p-5 h-100 ms-xxl-4"
        }
      >
        <h4 className="fw-bold">Voorraad</h4>
        <div className="d-flex flex-row">
          <div className="d-flex flex-column">
            <div className="d-flex flex-column">
              <label className="">Voorraad hoeveelheid</label>
              {props.edit ? (
                <>
                  <BorderedNumberInput
                    size="m"
                    inputValue={borderedNumberInputValue}
                    type="number"
                    placeholder="0"
                    setInputValue={(newValue) => {
                      setBorderedNumberInputValue(newValue);
                      props.setStock({
                        ...props.stock,
                        amount: newValue,
                        stock_behavior: 0,
                        deliverability: 0,
                      });
                      props.setUpdatedStock(true);
                    }}
                    onWheel={(e) => e.target.blur()}
                    className="inputField standardInput webkitRemoves w-50"
                  />
                </>
              ) : (
                <>
                  <BorderedNumberInput
                    size="s"
                    type="number"
                    inputValue={props?.stock?.amount}
                    placeholder="0"
                    setInputValue={(newValue) =>
                      props.setStock({
                        ...props.stock,
                        amount: newValue,
                        stock_behavior: 0,
                        deliverability: 0,
                      })
                    }
                    onWheel={(e) => e.target.blur()}
                    className="inputField standardInput webkitRemoves w-50"
                  />
                  {/*  WORD LATER WEER GEBRUIKT!!!!
                  <input
                    type="number"
                    placeholder="0"
                    value={props?.stock?.amount}
                    onChange={(e) =>
                      props.setStock({
                        ...props.stock,
                        amount: e.target.value,
                        stock_behavior: 0,
                        deliverability: 0,
                      })
                    }
                    onWheel={(e) => e.target.blur()}
                    className="inputField standardInput webkitRemoves w-50"
                  /> */}
                </>
              )}
            </div>
            {/* <div className="d-flex flex-row mt-4">
            <div className="d-flex flex-column">
              <label className="">Minimale voorraad</label>
              {props.edit ? (
                <input
                  type="number"
                  value={props?.stock?.minimal}
                  onChange={(e) =>
                    props.setStock({ ...props.stock, minimal: e.target.value, stock_behavior: 0, deliverability: 0 }) +
                    props.setUpdatedStock(true)
                  }
                  onWheel={(e) => e.target.blur()}
                  className="inputField standardInput webkitRemoves w-50"
                />
              ) : (
                <input
                  type="number"
                  value={props?.stock?.minimal}
                  onChange={(e) =>
                    props.setStock({ ...props.stock, minimal: e.target.value, stock_behavior: 0, deliverability: 0 })
                  }
                  onWheel={(e) => e.target.blur()}
                  className="inputField standardInput webkitRemoves w-50"
                />
              )}

            </div>
            <div className="d-flex flex-column">
              <label className="">Maximale voorraad</label>
              {props.edit ? (
                <input
                  type="number"
                  value={props?.stock?.maximal}
                  onChange={(e) =>
                    props.setStock({ ...props.stock, maximal: e.target.value, stock_behavior: 0, deliverability: 0 }) +
                    props.setUpdatedStock(true)
                  }
                  onWheel={(e) => e.target.blur()}
                  className="inputField standardInput webkitRemoves w-50"
                />
              ) : (
                <input
                  type="number"
                  value={props?.stock?.maximal}
                  onChange={(e) =>
                    props.setStock({ ...props.stock, maximal: e.target.value, stock_behavior: 0, deliverability: 0 })
                  }
                  onWheel={(e) => e.target.blur()}
                  className="inputField standardInput webkitRemoves w-50"
                />
              )}
            </div>
          </div> */}
          </div>
          {/* <div className="d-flex flex-column">
            <div className="d-flex flex-column">
              <label>Voorraad gedrag</label>
              {props.edit ? (
                <select
                  className="inputField px-2 standardInput webkitRemoves w-100"
                  value={props?.stock?.stock_behavior}
                  onChange={(e) =>
                    props.setStock({
                      ...props.stock,
                      stock_behavior: e.target.value,
                    }) + props?.setUpdatedStock(true) ?? null
                  }
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                </select>
              ) : (
                <select
                  className="inputField px-2 standardInput webkitRemoves w-100"
                  value={props?.stock?.stock_behavior}
                  onChange={(e) =>
                    props.setStock({
                      ...props.stock,
                      stock_behavior: e.target.value,
                    })
                  }
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                </select>
              )}
            </div>
            <div className="d-flex flex-column">
              <label>Bestelbaarheid</label>
              {props.edit ? (
                <select
                  className="inputField px-2 standardInput webkitRemoves w-100"
                  value={props?.stock?.deliverability}
                  onChange={(e) =>
                    props.setStock({
                      ...props.stock,
                      deliverability: e.target.value,
                    }) + props.setUpdatedStock(true)
                  }
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                </select>
              ) : (
                <select
                  className="inputField px-2 standardInput webkitRemoves w-100"
                  value={props?.stock?.deliverability}
                  onChange={(e) =>
                    props.setStock({
                      ...props.stock,
                      deliverability: e.target.value,
                    })
                  }
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                </select>
              )}
            </div> */}
          {/* <div className='d-flex flex-column'>
                <label>Heeft serienummers</label>
                <div className='d-flex flex-row'>
                    <select 
                        className=' w-25 productInputWidth'
                        value={props.stock.stock_behaviour}
                        onChange={e => props.setStock({...props.stock, stock_behaviour: e.target.value})}
                    >
                        <option>1</option>
                        <option>2</option>
                    </select>
                    <button type='button' className='btn btn-outline-primary col-1'>toon</button>
                </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default ThirdBox;
