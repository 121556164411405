import React, { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router";
import middleware from "../../Api/Middleware";
import ToastError from "../../Toasts/ToastError";
import ToastInfo from "../../Toasts/ToastInfo";
import ToastSuccess from "../../Toasts/ToastSuccess";
import PasswordRequirementsModal from "../Users/EditUsers/Modals/PasswordRequirementsModal";
import SecurityCodeModal from "../Users/EditUsers/Modals/SecurityCodeModal";
import DeactivateUserModal from "../Users/EditUsers/Modals/DeactivateUserModal";
import PageTemplate from "../../Templates/PageTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import "./EditProfile.css";
import NoPhoto from "../nopfp.png";
import FsLightbox from "fslightbox-react";
import { hourglass } from "ldrs";
import { BorderedTextInput } from "../../HouseStyle/Components/Inputs";
import {
  ButtonBlue,
  ButtonLightGrey,
} from "../../HouseStyle/Components/Buttons";
import { BorderedDropDown } from "../../HouseStyle/Components/DropDowns";
import ChangePasswordModal from "./ChangePasswordModal";

const languageOptions = [
  { value: "NL", label: "Nederlands" },
  { value: "EN", label: "English" },
  { value: "DE", label: "Deutsch" },
  { value: "FR", label: "Français" },
];

const EditProfile = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [updateUserValues, setUpdateUserValues] = useState({});
  const [lightBoxOpened, setLightBoxOpened] = useState(false);
  const [generatedSecurityCode, setGeneratedSecurityCode] = useState(false);
  const [showSecurityCode, setShowSecurityCode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [changedPfp, setChangedPfp] = useState(false);
  const [updatingPicture, setUpdatingPicture] = useState(false);
  const { user_id } = useParams();
  const imageInputRef = useRef(null);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [userPassword, setUserPassword] = useState({
    password: "",
    repeated_password: "",
    current_password: "",
  });
  const [pendingUserPassword, setPendingUserPassword] = useState({
    password: "",
    repeated_password: "",
  });
  const [modals, setModals] = useState({
    password: false,
    securityCode: false,
    deactivateUser: false,
  });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const toggleModal = (modal) =>
    setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));

  hourglass.register();

  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchUserRes, pfpRes] = await Promise.all([
        middleware.get(`users?api_token=${cookies.get("token")}`),
        middleware.get(`profilepicture`),
      ]);

      const userData = {
        ...fetchUserRes.data.data[0],
        profile_picture: pfpRes.data.profile_picture_url,
      };

      setUser(userData);
      setUpdateUserValues(userData);
      setLoading(false);
    } catch (error) {
      ToastError(error.message);
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const validateEmail = (email) => {
    console.log(email);
    if (!emailRegex.test(email)) {
      setEmailError("Ongeldig e-mailadres.");
    } else {
      setEmailError("");
    }
  };

  const hasChanges = () => {
    for (const key in updateUserValues) {
      if (updateUserValues[key] !== user[key]) {
        return true;
      }
    }
    return false;
  };

  const generateRandomSecurityCode = async (event) => {
    event.preventDefault();
    const length = 6;
    const charset = "0123456789";
    let code = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      let char = charset[randomIndex];
      code += char;
    }

    setUpdateUserValues({ ...updateUserValues, security_code: code });
    await updateUser();

    try {
      await navigator.clipboard.writeText(code);
      ToastInfo(`Beveiligingspin gekopieerd naar clipboard`, 1500);
    } catch (err) {
      console.error("Failed to copy password: ", err);
    }
  };

  const updateUser = async () => {
    try {
      if (updateUserValues.first_name === "" || updateUserValues === "") {
        ToastError("Naam velden moetten ingevuld zijn");
        return;
      }
      if (hasChanges()) {
        if (emailError == "") {
          const {
            profile_picture,
            profile_picture_path,
            ...filteredUpdateUserValues
          } = updateUserValues;
          const [putUser] = await Promise.all([
            middleware.put("users", filteredUpdateUserValues),
          ]);
          ToastSuccess("Gebruikergegevens bijgewerkt");
        } else if (emailError !== "") {
          ToastError(emailError);
        }
      }
    } catch (error) {
      ToastError(error.message);
    }
  };

  const changeUser = async (column, value) => {
    if (column === "email") validateEmail(value);

    setUpdateUserValues({ ...updateUserValues, [column]: value });
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    console.log(event)
    const file = event.dataTransfer.files[0];
    if (file) {
      const fileType = file.type;
      const validImageTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/webp",
        "image/gif",
      ];
      const maxSize = 2 * 1024 * 1024;

      if (!validImageTypes.includes(fileType)) {
        ToastError(
          "Ongeldig foto. Alleen PNG, JPG, JPEG, WEBP en GIF zijn toegestaan."
        );
        return;
      }

      const reader = new FileReader();

      reader.onload = async (e) => {
        const base64String = e.target.result;

        try {
          const response = await middleware.put(
            "profilepicture",
            { profile_picture: base64String },
            cookies.get("token")
          );

          ToastSuccess(
            response.data.message || "Profielafbeelding succesvol bijgewerkt."
          );

          if (response.data.profile_picture_url) {
            const url = response.data.profile_picture_url;
            const token = cookies.get("token");
            const navInfoKey = `navInfo[${token}]`;
            const navInfoString = localStorage.getItem(navInfoKey);
            let navInfo = JSON.parse(navInfoString) || {};
            navInfo.profile_picture_url = url;
            const updatedNavInfoString = JSON.stringify(navInfo);
            localStorage.setItem(navInfoKey, updatedNavInfoString);

            setUser((prevUser) => ({
              ...prevUser,
              profile_picture: url,
            }));
            setUpdateUserValues((prevValues) => ({
              ...prevValues,
              profile_picture: url,
            }));
          }
        } catch (error) {
          if (error.response && error.response.status === 422) {
            const validationErrors = error.response.data.errors;
            Object.values(validationErrors).forEach((errMessages) => {
              errMessages.forEach((msg) => ToastError(msg));
            });
          } else {
            ToastError(
              error.response?.data?.message ||
              "Fout bij het uploaden van de profielafbeelding."
            );
          }
          console.error(error);
        } finally {
          setUpdatingPicture(false);
          if (imageInputRef.current) {
            imageInputRef.current.value = null;
          }
        }
      };
      reader.readAsDataURL(file);
    }
  }

  const handleImageUpload = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setUpdatingPicture(true);

    if (file) {
      const fileType = file.type;
      const validImageTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/webp",
        "image/gif",
      ];
      const maxSize = 2 * 1024 * 1024;

      if (!validImageTypes.includes(fileType)) {
        ToastError(
          "Ongeldig foto. Alleen PNG, JPG, JPEG, WEBP en GIF zijn toegestaan."
        );
        return;
      }

      const reader = new FileReader();

      reader.onload = async (e) => {
        const base64String = e.target.result;

        try {
          const response = await middleware.put(
            "profilepicture",
            { profile_picture: base64String },
            cookies.get("token")
          );

          ToastSuccess(
            response.data.message || "Profielafbeelding succesvol bijgewerkt."
          );

          if (response.data.profile_picture_url) {
            const url = response.data.profile_picture_url;
            const token = cookies.get("token");
            const navInfoKey = `navInfo[${token}]`;
            const navInfoString = localStorage.getItem(navInfoKey);
            let navInfo = JSON.parse(navInfoString) || {};
            navInfo.profile_picture_url = url;
            const updatedNavInfoString = JSON.stringify(navInfo);
            localStorage.setItem(navInfoKey, updatedNavInfoString);

            setUser((prevUser) => ({
              ...prevUser,
              profile_picture: url,
            }));
            setUpdateUserValues((prevValues) => ({
              ...prevValues,
              profile_picture: url,
            }));
          }
        } catch (error) {
          if (error.response && error.response.status === 422) {
            const validationErrors = error.response.data.errors;
            Object.values(validationErrors).forEach((errMessages) => {
              errMessages.forEach((msg) => ToastError(msg));
            });
          } else {
            ToastError(
              error.response?.data?.message ||
              "Fout bij het uploaden van de profielafbeelding."
            );
          }
          console.error(error);
        } finally {
          setUpdatingPicture(false);
          if (imageInputRef.current) {
            imageInputRef.current.value = null;
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const dragOverHandler = (event) => {
    event.preventDefault();
  }

  const handleImageDelete = async () => {
    try {
      setUpdatingPicture(true);
      const response = await middleware.delete("profilepicture");

      ToastSuccess(response.data.message);
      const token = cookies.get("token");
      const navInfoKey = `navInfo[${token}]`;
      const navInfoString = localStorage.getItem(navInfoKey);
      let navInfo = JSON.parse(navInfoString) || {};
      navInfo.profile_picture_url = null;
      const updatedNavInfoString = JSON.stringify(navInfo);
      localStorage.setItem(navInfoKey, updatedNavInfoString);

      setUser((prevUser) => ({
        ...prevUser,
        profile_picture: null,
      }));
      setUpdateUserValues((prevValues) => ({
        ...prevValues,
        profile_picture: null,
      }));
      setUpdatingPicture(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const validationErrors = error.response.data.errors;
        Object.values(validationErrors).forEach((errMessages) => {
          errMessages.forEach((msg) => ToastError(msg));
        });
      } else {
        ToastError(
          error.response?.data?.message ||
          "Oeps. Er is iets mis gegaan. Probeer het nog een keer"
        );
      }
      console.error(error);
    }
  };

  useEffect(() => {
    updateUser();
  }, [updateUserValues.language, generatedSecurityCode]);

  useEffect(() => {
    if (changedPfp) {
      updateUser();
      setChangedPfp(false);
    }
  }, [changedPfp]);

  const closeChangePasswordModal = () => setShowChangePasswordModal(false);

  console.log(showChangePasswordModal);

  return (
    <>
      <PageTemplate
        navbarTitle={"Profiel bewerken"}
        pageIsLoading={loading}
        backValue={-1}
      >
        <ChangePasswordModal user={user} setUser={setUser} show={showChangePasswordModal} close={closeChangePasswordModal} />
        <form onSubmit={(event) => event.preventDefault()} className="w-100">
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-row gap-4">
              <div className="d-flex flex-column p-4 whiteBox" style={{ width: "50%" }}>
                <label>
                  <h4>Algemene gegevens</h4>
                </label>
                <div className="innerBox d-flex flex-column gap-2">
                  <div className="d-flex flex-row">
                    <BorderedTextInput
                      label={"Voornaam"}
                      size="l"
                      inputValue={updateUserValues.first_name}
                      setInputValue={(value) => changeUser("first_name", value)}
                      updateFunction={updateUser}
                    />
                  </div>
                  <div className="d-flex flex-row">
                    <BorderedTextInput
                      label={"Achternaam"}
                      size="l"
                      inputValue={updateUserValues.last_name}
                      setInputValue={(value) => changeUser("last_name", value)}
                      updateFunction={updateUser}
                    />
                  </div>
                  <div className="d-flex flex-row">
                    <BorderedDropDown
                      label={"Taal"}
                      size="m"
                      selectedValue={updateUserValues.language}
                      setSelectedValue={(value) =>
                        changeUser("language", value)
                      }
                      options={languageOptions.map((language, index) => {
                        return {
                          key: index,
                          value: language.value,
                          title: language.label,
                        };
                      })}
                    />
                  </div>
                  <div className="d-flex flex-row pt-4">
                    <ButtonBlue
                      text={"Wijzig wachtwoord"}
                      size="m"
                      onClickFunction={() => setShowChangePasswordModal(true)}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column gap-4 w-100">
                <div className="d-flex flex-column p-4 whiteBox">
                  <label>
                    <h4>Profielafbeelding wijzigen</h4>
                  </label>
                  <div className="innerBox">
                    <div className="d-flex col-12 gap-2 py-4">
                      {changedPfp || updatingPicture ? (
                        <div className="profilePictureLoadingBox">
                          <l-hourglass
                            size="40"
                            bg-opacity="0.1"
                            speed="1"
                            color="gray"
                          ></l-hourglass>
                        </div>
                      ) : (
                        <div className="d-flex flex-column gap-4 justify-content-center">
                          <div className="editProfilePicture">
                            <img
                              src={updateUserValues.profile_picture || NoPhoto}
                              onClick={() => setLightBoxOpened(!lightBoxOpened)}
                              alt="Profilepicture"
                            />
                          </div>
                          <div
                            type="button"
                            className={`btn deletePfp btn-danger ${updateUserValues.profile_picture ? "" : "disabled"
                              }`}
                            disabled={
                              updateUserValues.profile_picture ? false : true
                            }
                            onClick={handleImageDelete}
                          >
                            <FontAwesomeIcon icon={faTrashCan} />
                          </div>
                        </div>
                      )}
                      <div className="d-flex flex-row w-100">
                        <div className="uploadImageBox w-100">
                          <label
                            onDragOver={dragOverHandler}
                            onDrop={handleDrop}
                            className="fileDropBox d-flex flex-row justify-content-center align-items-center w-100">
                            <h5 className="p-0">
                              Klik hier of sleep een bestand hierheen om het te uploaden
                            </h5>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              accept=".png, .jpg, .jpeg"
                              onChange={(event) => handleImageUpload(event)}
                            />
                          </label>

                          {/* <input
                          type="file"
                          className="imageUpload"
                          name="imageUpload"
                          disabled={changedPfp ? true : false}
                          id="imageUpload"
                          ref={imageInputRef}
                          onChange={(event) => handleImageUpload(event)}
                        /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-between p-4 w-100 whiteBox">
              <label>
                <h4>Contact informatie</h4>
              </label>
              <div className="innerBox">
                <div className="d-flex flex-row gap-4">
                  <BorderedTextInput
                    label={"E-mailadres"}
                    size="s"
                    inputValue={updateUserValues.email}
                    setInputValue={(value) => changeUser("email", value)}
                    updateFunction={updateUser}
                  />
                </div>
              </div>
            </div>
            {/* <div className="d-flex flex-column justify-content-between p-4 w-100 whiteBox">
              <label>
                <h4>Wachtwoord wijzigen</h4>
              </label>
              <div className="innerBox d-flex flex-column gap-2">
                 <div className="d-flex flex-row">
                  </div>

                <div className="d-flex flex-column gap-4">
                  <div className="d-flex flex-column w-100 gap-2">
                    <BorderedTextInput
                      label="Huidig wachtwoord"
                      size="l"
                      updateWaitTimer={250}
                      updateFunction={() =>
                        setUserPassword((prev) => ({
                          ...prev,
                          current_password: pendingUserPassword.current_password,
                        }))
                      }
                      inputValue={pendingUserPassword.current_password}
                      setInputValue={(value) =>
                        setPendingUserPassword((prev) => ({
                          ...prev,
                          current_password: value,
                        }))
                      }
                    />
                    <BorderedTextInput
                      label="Nieuw wachtwoord"
                      size="l"
                      updateWaitTimer={250}
                      updateFunction={() =>
                        setUserPassword((prev) => ({
                          ...prev,
                          password: pendingUserPassword.password,
                        }))
                      }
                      inputValue={pendingUserPassword.password}
                      setInputValue={(value) =>
                        setPendingUserPassword((prev) => ({
                          ...prev,
                          password: value,
                        }))
                      }
                    />
                    <BorderedTextInput
                      label="Herhaal wachtwoord"
                      size="l"
                      inputValue={userPassword.repeated_password}
                      setInputValue={(value) =>
                        setUserPassword((prev) => ({
                          ...prev,
                          repeated_password: value,
                        }))
                      }
                    />
                  </div>
                  <div className="d-flex flex-row-reverse gap-2 w-100">
                    <ButtonBlue
                      size="l"
                      text={"Wijzig wachtwoord"}
                      onClickFunction={updatePassword}
                    />
                    <ButtonLightGrey
                      size="s"
                      text={"Willikeurig wachtwoord"}
                      onClickFunction={generateRandomPassword}
                    />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </form>
      </PageTemplate>
      {/* {!updatingPicture && ( 
        <FsLightbox
          toggler={lightBoxOpened}
          sources={[<img src={updateUserValues.profile_picture}></img>]}
        />
      )}
      <PasswordRequirementsModal
        show={modals.password}
        onHide={() => toggleModal("password")}
      />
      <SecurityCodeModal
        show={modals.securityCode}
        onHide={() => toggleModal("securityCode")}
      />
      <DeactivateUserModal
        show={modals.deactivateUser}
        onHide={() => toggleModal("deactivateUser")}
        updateUserValues={updateUserValues}
        setState={setUpdateUserValues}
        updateUser={updateUser}
      />

      <PageTemplate
        navbarTitle={"Profiel"}
        pageIsLoading={loading}
        backValue={-1}
      >
        <form className="w-100" onSubmit={(e) => e.preventDefault()}>
          <div className="whiteBox px-5 py-4">
            <div className="d-flex col-12 border-bottom py-4">
              {changedPfp || updatingPicture ? (
                <div className="profilePictureLoadingBox">
                  <l-hourglass
                    size="40"
                    bg-opacity="0.1"
                    speed="1"
                    color="gray"
                  ></l-hourglass>
                </div>
              ) : (
                <div className="editProfilePicture">
                  <img
                    src={updateUserValues.profile_picture || NoPhoto}
                    onClick={() => setLightBoxOpened(!lightBoxOpened)}
                    className=""
                    alt="Profilepicture"
                  />
                </div>
              )}
              <div className="d-flex flex-row gap-2">
                <div className="uploadImageBox">
                  <label htmlFor="imageUpload" className="btn btn-techdog">
                    Nieuwe foto
                  </label>
                  <input
                    type="file"
                    className="imageUpload"
                    name="imageUpload"
                    disabled={changedPfp ? true : false}
                    id="imageUpload"
                    ref={imageInputRef}
                    onChange={(event) => handleImageUpload(event)}
                  />
                </div>
                <div className="uploadImageBox">
                  <div
                    type="button"
                    className={`btn btn-danger ${updateUserValues.profile_picture ? "" : "disabled"
                      }`}
                    disabled={updateUserValues.profile_picture ? false : true}
                    onClick={handleImageDelete}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="d-flex col-12 border-bottom py-4">
              <h5 className="titlesEditUsers col-3 ps-3">Volledige naam</h5>
              <div className="d-flex gap-2 col-9">
                <BorderedTextInput
                  placeholder="Voornaam"
                  size="l"
                  inputValue={updateUserValues.first_name ?? ""}
                  setInputValue={(value) => changeUser("first_name", value)}
                  updateFunction={updateUser}
                />
                <BorderedTextInput
                  placeholder="Achternaam"
                  size="l"
                  inputValue={updateUserValues.last_name ?? ""}
                  setInputValue={(value) => changeUser("last_name", value)}
                  updateFunction={updateUser}
                />
              </div>
            </div>
            <div className="d-flex col-12 border-bottom py-4">
              <h5 className="titlesEditUsers col-3 ps-3">E-mail</h5>
              <div className="col-9 d-flex flex-column">
                <div className="d-flex flex-row">
                  <BorderedTextInput
                    placeholder="E-mail"
                    size="l"
                    inputValue={updateUserValues.email ?? ""}
                    setInputValue={(event) => changeUser("email", event)}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex col-12 border-bottom py-4">
              <h5 className="titlesEditUsers col-3 ps-3">
                Wijzig wachtwoord
                <FontAwesomeIcon
                  className="iconInfoStyle"
                  onClick={() => toggleModal("password")}
                  icon={faCircleInfo}
                />
              </h5>
              <div className="d-flex flex-column gap-3 col-12">
                <div className="d-flex flex-row col-9 gap-2">
                  <ButtonLightGrey
                    text={"Willikeurig wachtwoord"}
                    onClickFunction={generateRandomPassword}
                  />
                </div>
                <div className="d-flex flex-row col-9 gap-2">
                  <BorderedTextInput
                    placeholder="Nieuw wachtwoord"
                    size="l"
                    updateWaitTimer={250}
                    updateFunction={() =>
                      setUserPassword((prev) => ({
                        ...prev,
                        password: pendingUserPassword.password,
                      }))
                    }
                    inputValue={pendingUserPassword.password}
                    setInputValue={(value) =>
                      setPendingUserPassword((prev) => ({
                        ...prev,
                        password: value,
                      }))
                    }
                  />
                  <BorderedTextInput
                    placeholder="Herhaal wachtwoord"
                    size="l"
                    inputValue={userPassword.repeated_password}
                    setInputValue={(value) =>
                      setUserPassword((prev) => ({
                        ...prev,
                        repeated_password: value,
                      }))
                    }
                  />
                  <ButtonBlue
                    text={"Wijzig wachtwoord"}
                    onClickFunction={updatePassword}
                  />
                </div>
              </div>
              <div className="col-9 d-flex"></div>
            </div>
            <div className="d-flex col-12 border-bottom py-4">
              <h5 className="titlesEditUsers col-3 ps-3">
                Beveiligingspin instellen
                <FontAwesomeIcon
                  className="iconInfoStyle"
                  onClick={() => toggleModal("securityCode")}
                  icon={faCircleInfo}
                />
              </h5>
              <div className="col-9 d-flex">
                <BorderedTextInput
                  placeholder="Beveiligingspin instellen"
                  size="l"
                  inputValue={updateUserValues.securityCode ?? ""}
                  setInputValue={(value) => changeUser("security_code", value)}
                />
              </div>
            </div>
            <div className="d-flex col-12 py-4">
              <h5 className="titlesEditUsers mb-0 col-3 ps-3">Taal</h5>
              <div className="col-9 d-flex flex-column">
                <div className="d-flex flex-row">
                  <BorderedDropDown
                    size="s"
                    selectedValue={updateUserValues.language}
                    setSelectedValue={(value) => changeUser("language", value)}
                    options={languageOptions.map((language, index) => {
                      return {
                        key: index,
                        value: language.value,
                        title: language.label,
                      };
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </PageTemplate> */}
    </>
  );
};

export default EditProfile;
