import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useParams } from "react-router";
import InputComponent from "../../AddCustomers/InputComponent";
import Plus from "../../AddCustomers/Icons/Add.svg";
import Minus from "../../AddCustomers/Icons/minus.svg"; // You need to add this icon to your project
import middleware from "../../../Api/Middleware";
import ToastError from "../../../Toasts/ToastError";
import LoadingScreen from "../../../LoadingScreen/LoadingScreen";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import { useTable } from "react-table";
import TablePreset from "../../../Table/TablePreset";
import Trash from "../Icons/trash-can.svg";
import Pin from "../Icons/thumbtack-solid.svg";
import CancelPin from "../Icons/thumbtack-slash-solid.svg";
import editIcon from "../Icons/pen-to-square-solid.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddContactsModal from "../CustomerModals/AddContactsModal";
import {
  BorderedTextInput,
  RegularTextInput,
} from "../../../HouseStyle/Components/Inputs";
import { BorderedDropDown } from "../../../HouseStyle/Components/DropDowns";

const GeneralInfoTab = ({
  typeCustomer,
  setDataUpdated,
  dataUpdated,
  setConsumerInfoUpdated,
}) => {
  const { internal_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [consumerData, setConsumerData] = useState([]);
  const [businessInfo, setBusinessInfo] = useState({});
  const [emailSettings, setEmailSettings] = useState({});
  const [emails, setEmails] = useState([]);
  const [adress, setAdress] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [removedEmails, setRemovedEmails] = useState([]); // State for removed emails
  const [phoneNumbers, setPhoneNumbers] = useState([{}]);
  const [removedPhoneNumbers, setRemovedPhoneNumbers] = useState([]); // State for removed phone numbers
  const [showAddContactsModal, setShowAddContactsModal] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [formData, setFormData] = useState({});
  const timerRef = useRef(null);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [updatedContacts, setUpdatedContacts] = useState(false);
  const formRef = useRef(null); // Step 1: Create a ref for the form
  const vatPatterns = {
    AT: /^ATU\d{8}$/, // Austria
    BE: /^BE0\d{9}$/, // Belgium
    BG: /^BG\d{9,10}$/, // Bulgaria
    CY: /^CY\d{8}[A-Z]$/, // Cyprus
    CZ: /^CZ\d{8,10}$/, // Czech Republic
    DE: /^DE\d{9}$/, // Germany
    DK: /^DK\d{8}$/, // Denmark
    EE: /^EE\d{9}$/, // Estonia
    EL: /^EL\d{9}$/, // Greece
    ES: /^ES[A-Z0-9]\d{7}[A-Z0-9]$/, // Spain
    FI: /^FI\d{8}$/, // Finland
    FR: /^FR[A-Z0-9]{2}\d{9}$/, // France
    GB: /^GB(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/, // United Kingdom
    HR: /^HR\d{11}$/, // Croatia
    HU: /^HU\d{8}$/, // Hungary
    IE: /^IE\d[A-Z0-9+*]\d{5}[A-Z]$/, // Ireland
    IT: /^IT\d{11}$/, // Italy
    LT: /^LT(\d{9}|\d{12})$/, // Lithuania
    LU: /^LU\d{8}$/, // Luxembourg
    LV: /^LV\d{11}$/, // Latvia
    MT: /^MT\d{8}$/, // Malta
    NL: /^NL\d{9}B\d{2}$/, // Netherlands
    PL: /^PL\d{10}$/, // Poland
    PT: /^PT\d{9}$/, // Portugal
    RO: /^RO\d{2,10}$/, // Romania
    SE: /^SE\d{12}$/, // Sweden
    SI: /^SI\d{8}$/, // Slovenia
    SK: /^SK\d{10}$/, // Slovakia
    // Add more countries as needed
  };
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  });
  useEffect(() => {
    if (!loading) {
      fetchContacts();
      setUpdatedContacts(false);
    }
  }, [pagination.page, pagination.page_size, updatedContacts]);
  const fetchContacts = async () => {
    try {
      const [noteRes] = await Promise.all([
        middleware.get(
          `businessescontacts?internal_id=${internal_id}&page_size=${pagination.page_size}&page=${pagination.page}&orderBy=created_at&direction=desc`
        ),
      ]);
      setConsumerData(noteRes.data.data.data);
      setPagination({
        ...pagination,
        max_items: noteRes.data.data.total,
        begin_of_page: noteRes.data.data.from,
        end_of_page: noteRes.data.data.to,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCorrectConsumer = async (internalId) => {
    if (internalId) {
      setLoading(true);
      try {
        let initialData = {};
        if (internalId.startsWith("B")) {
          const [
            businessResponse,
            businessContactResponse,
            emailRes,
            phoneNumberRes,
            emailSettingsRes,
          ] = await Promise.all([
            middleware.get(`businesses?internal_id=${internalId}`),
            middleware.get(
              `businessescontacts?internal_id=${internalId}&page_size=${pagination.page_size}&page=${pagination.page}&orderBy=created_at`
            ),
            middleware.get(`emails?internal_id=${internalId}`),
            middleware.get(`phonenumbers?internal_id=${internalId}`),
            middleware.get(`emailsettings?internal_id=${internalId}`),
          ]);

          setBusinessInfo(
            businessResponse.data[0] ?? {
              cocnumber: "",
              company_id: "",
              company_name: "",
              fax_number: "",
              has_loyalty: 0,
              internal_id: internal_id,
              invoicemail: "",
              loyaltypoints: "",
              loyalty_signup_date: "",
              permanent_sale: "",
              status: "",
              url: "",
              vatnumber: "",
            }
          );
          setConsumerData(businessContactResponse.data.data.data);
          setPagination({
            ...pagination,
            max_items: businessContactResponse.data.data.total,
            begin_of_page: businessContactResponse.data.data.from,
            end_of_page: businessContactResponse.data.data.to,
          });

          setEmails(emailRes.data.data);
          setPhoneNumbers(phoneNumberRes.data.data);
          setEmailSettings(emailSettingsRes.data.data[0]);

          initialData = {
            business: businessResponse.data.data[0] ?? {
              cocnumber: "",
              company_id: "",
              company_name: "test",
              fax_number: "",
              has_loyalty: 0,
              internal_id: internal_id,
              invoicemail: "",
              loyaltypoints: "",
              loyalty_signup_date: "",
              permanent_sale: "",
              status: "",
              url: "",
              vatnumber: "",
            },
            business_contact: businessContactResponse.data.data,
            emails: emailRes.data.data,
            phone_numbers: phoneNumberRes.data.data,
            email_settings: emailSettingsRes.data.data[0],
          };
        } else if (internalId.startsWith("C")) {
          const [consumerResponse, emailRes, phoneNumberRes, emailSettingsRes] =
            await Promise.all([
              middleware.get(`consumers?internal_id=${internalId}`),
              middleware.get(`emails?internal_id=${internalId}`),
              middleware.get(`phonenumbers?internal_id=${internalId}`),
              middleware.get(`emailsettings?internal_id=${internalId}`),
            ]);

          setConsumerData(consumerResponse.data.data[0]);
          setEmails(emailRes.data.data);
          setPhoneNumbers(phoneNumberRes.data.data);
          setEmailSettings(emailSettingsRes.data.data[0]);

          initialData = {
            consumer: consumerResponse.data.data[0],
            emails: emailRes.data.data,
            phone_numbers: phoneNumberRes.data.data,
            email_settings: emailSettingsRes.data.data[0],
          };
        }
        const [adressesResponse, countrycodesResponses] = await Promise.all([
          middleware.get(`addresses?internal_id=${internalId}`),
          middleware.get(`countryCodes`),
        ]);
        if (adressesResponse.data.length) {
          countrycodesResponses.data.forEach((countrycode) => {
            if (
              countrycode.country_code_id ==
              adressesResponse.data[0].country_code_id
            ) {
              setCountryCodes(countrycode);
            }
          });
        }
        setInitialData(initialData);
        setFormData(structuredClone(initialData)); // Initialize formData with a deep copy of initial data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCorrectConsumer(internal_id);
  }, [dataUpdated]);
  const updateNestedFormData = useCallback((key, value, fieldType) => {
    setFormData((prev) => ({
      ...prev,
      [fieldType]: {
        ...structuredClone(prev[fieldType]), // Clone the nested object to avoid mutating the original
        [key]: value,
      },
    }));
  }, []);

  const getValueInput = useCallback(
    (value, label) => {
      if (label === "vatnumber") {
        if (!validateVATNumber(countryCodes.country_code_2, value)) {
          ToastError("BTW-nummer is niet geldig voor het geselecteerde land.");
          return; // Stop further processing if VAT number is invalid
        }
      }

      if (label in businessInfo) {
        updateNestedFormData(label, value, "business");
      } else if (label in consumerData) {
        updateNestedFormData(label, value, "consumer");
      } else if (label in emailSettings) {
        updateNestedFormData(label, value, "email_settings");
      }
    },
    [businessInfo, consumerData, emailSettings, countryCodes]
  );

  const handleBlurInput = useCallback(
    (value, label) => {
      if (label === "vatnumber") {
        if (!validateVATNumber(countryCodes.country_code_2, value)) {
          ToastError("BTW-nummer is niet geldig voor het geselecteerde land.");
          return; // Stop further processing if VAT number is invalid
        }
      }

      if (label in businessInfo) {
        updateNestedFormData(label, value, "business");
      } else if (label in consumerData) {
        updateNestedFormData(label, value, "business_contact");
      } else if (label in emailSettings) {
        updateNestedFormData(label, value, "email_settings");
      }
    },
    [businessInfo, consumerData, emailSettings, countryCodes]
  );

  const handleSubmit = useCallback(async () => {
    const changedData = {};
    if (formData.emails != null) {
      const newEmails = formData.emails.filter((email) => !email.email_id); // Detect new emails without ID
      const newPhoneNumbers = formData.phone_numbers.filter(
        (phone) => !phone.phone_number_id
      ); // Detect new phone numbers without ID

      // Handle changes in existing data
      if (typeCustomer === "B") {
        if (
          JSON.stringify(initialData.business) !==
          JSON.stringify(formData.business)
        ) {
          changedData.business = formData.business;
        }
        if (
          JSON.stringify(initialData.business_contact) !==
          JSON.stringify(formData.business_contact)
        ) {
          changedData.business_contact = formData.business_contact;
        }
      } else if (typeCustomer === "C") {
        if (
          JSON.stringify(initialData.consumer) !==
          JSON.stringify(formData.consumer)
        ) {
          changedData.consumer = formData.consumer;
        }
      }

      if (
        JSON.stringify(initialData.emails) !== JSON.stringify(formData.emails)
      ) {
        changedData.emails = formData.emails.filter((email) => email.email_id); // Only include existing emails
      }

      if (
        JSON.stringify(initialData.phone_numbers) !==
        JSON.stringify(formData.phone_numbers)
      ) {
        changedData.phone_numbers = formData.phone_numbers.filter(
          (phone) => phone.phone_number_id
        ); // Only include existing phone numbers
      }

      let hasError = false; // Track if any error occurs

      try {
        // Send POST requests for new emails and phone numbers
        if (newEmails.length > 0) {
          for (const newEmail of newEmails) {
            if (newEmail.email !== undefined && newEmail.email.trim() !== "") {
              try {
                const res = await middleware.post(`emails`, {
                  internal_id,
                  email: newEmail.email,
                });

                const emails = await middleware.get(
                  `emails?internal_id=${internal_id}`
                );
                setFormData({ ...formData, emails: emails.data });
                setEmails(emails.data);
              } catch (error) {
                hasError = true;
                ToastError("Email formaat is ongeldig");
                console.log(error);
              }
            } else {
              hasError = true;
            }
          }
        }
        if (newPhoneNumbers.length > 0) {
          for (const newNumber of newPhoneNumbers) {
            if (
              newNumber.phone_number !== undefined &&
              newNumber.phone_number.trim() !== ""
            ) {
              try {
                const res = await middleware.post(`phonenumbers`, {
                  internal_id,
                  phone_number: Number(newNumber.phone_number),
                  country_phone_prefix: newNumber.country_phone_prefix,
                  fullnumber:
                    "+" +
                    newNumber.country_phone_prefix +
                    "-" +
                    Number(newNumber.phone_number),
                });
                let phones = await middleware.get(
                  `phonenumbers?internal_id=${internal_id}`
                );

                setFormData({ ...formData, phone_numbers: phones.data });
                setPhoneNumbers(phones.data.data);
              } catch (error) {
                hasError = true;
                ToastError("Telefoonummer format is incorrect.");
                console.log(error);
              }
            } else {
              hasError = true;
            }
          }
        }

        // Handle PUT requests for existing data
        if (Object.keys(changedData).length > 0) {
          if (changedData.business) {
            await middleware.put(
              `businesses?internal_id=${internal_id}`,
              changedData.business
            );
          }
          if (changedData.business_contact) {
            await middleware.put(
              `businessescontacts?internal_id=${internal_id}`,
              changedData.business_contact
            );
          }
          if (changedData.consumer) {
            await middleware.put(
              `consumers?internal_id=${internal_id}`,
              changedData.consumer
            );
          }
          if (changedData.emails) {
            await Promise.all(
              changedData.emails.map((email) =>
                middleware
                  .put(`emails?internal_id=${internal_id}`, {
                    email_id: email.email_id,
                    email: email.email,
                  })
                  .catch((error) => {
                    hasError = true;
                    ToastError("Email formaat is ongeldig");
                  })
              )
            );
          }
          if (changedData.phone_numbers) {
            await Promise.all(
              changedData.phone_numbers.map((phone_number) =>
                middleware
                  .put(`phonenumbers?internal_id=${internal_id}`, {
                    phone_number_id: phone_number.phone_number_id,
                    phone_number: Number(phone_number.phone_number),
                    country_phone_prefix: phone_number.country_phone_prefix,
                    fullnumber:
                      "+" +
                      phone_number.country_phone_prefix +
                      "-" +
                      Number(phone_number.phone_number),
                  })
                  .catch((error) => {
                    hasError = true;
                    ToastError(`Telefoonnummer is ongeldig`);
                  })
              )
            );
          }
        }

        // Check for errors before showing success message
        if (hasError) {
        } else if (
          Object.keys(changedData).length > 0 ||
          newEmails.length > 0 ||
          newPhoneNumbers.length > 0
        ) {
          ToastSuccess("Veranderingen successvol opgeslagen!");
          // Update initialData to reflect the new state after save
          setInitialData(structuredClone(formData));
          setConsumerInfoUpdated(true);
        }

        // // Refetch the data to get the updated data including newly created IDs
      } catch (error) {
        console.error("Error submitting data:", error);
        ToastError("Error submitting data.");
      }
    }
  }, [formData, initialData, typeCustomer, internal_id]);

  const addEmailField = () => {
    if (emails.length < 3) {
      setEmails([...emails, ""]);
    } else {
      ToastError("Je kunt maximaal 3 e-mailadressen toevoegen");
    }
  };

  const handleEmailChange = (index, value) => {
    const newEmails = structuredClone(emails);
    if (typeof newEmails[index] === "object") {
      newEmails[index].email = value;
    } else {
      newEmails[index] = { email: value };
    }
    setEmails(newEmails);
    setFormData((prev) => ({
      ...prev,
      emails: newEmails,
    }));
  };

  const handleEmailBlur = (index, value) => {
    const newEmails = structuredClone(emails);
    if (typeof newEmails[index] === "object") {
      newEmails[index].email = value;
    } else {
      newEmails[index] = { email: value };
    }
    setEmails(newEmails);
    setFormData((prev) => ({
      ...prev,
      emails: newEmails,
    }));
  };

  const removeEmailField = async (index) => {
    const confirmDelete = window.confirm(
      "Weet je zeker dat je deze e-mail wilt verwijderen?"
    );
    if (confirmDelete) {
      const emailToRemove = emails[index];

      if (emailToRemove.email_id) {
        // Only delete if the email has an ID
        try {
          await middleware.delete(`/emails?email_id=${emailToRemove.email_id}`);
          ToastSuccess("E-mail succesvol verwijderd!");
          setConsumerInfoUpdated(true);
        } catch (error) {
          console.error("Error deleting email:", error);
          ToastError(
            "Er is een fout opgetreden bij het verwijderen van de e-mail."
          );
          return;
        }
      }

      // Update state after successful deletion
      const newEmails = emails.filter((_, i) => i !== index);
      setEmails(newEmails);
      setFormData((prev) => ({
        ...prev,
        emails: newEmails,
      }));
    }
  };

  const validateVATNumber = (countryCode, vatNumber) => {
    const pattern = vatPatterns[countryCode];
    if (!pattern) {
      ToastError("Country code is invalid or not supported.");
      return false;
    }
    return pattern.test(vatNumber);
  };

  const addPhoneNumberField = () => {
    if (phoneNumbers.length < 3) {
      setPhoneNumbers([...phoneNumbers, { country_phone_prefix: "31" }]);
    } else {
      ToastError("Je kunt maximaal 3 telefoonnummers toevoegen");
    }
  };

  const handlePhoneNumberChange = (index, value) => {
    console.log(index);

    const newPhoneNumbers = structuredClone(phoneNumbers); // Create a deep copy to avoid mutating initialData
    if (typeof newPhoneNumbers[index] === "object") {
      newPhoneNumbers[index].phone_number = value;
    } else {
      newPhoneNumbers[index] = { phone_number: value };
    }
    setPhoneNumbers(newPhoneNumbers);
    setFormData((prev) => ({
      ...prev,
      phone_numbers: newPhoneNumbers,
    }));
  };
  const setPhoneCode = (index, value) => {
    const newPhoneNumbers = structuredClone(phoneNumbers); // Create a deep copy to avoid mutating initialData
    if (typeof newPhoneNumbers[index] === "object") {
      newPhoneNumbers[index].country_phone_prefix = value;
      newPhoneNumbers[index].code_index = value;
    }
    setPhoneNumbers(newPhoneNumbers);
    setFormData((prev) => ({
      ...prev,
      phone_numbers: newPhoneNumbers,
    }));
  };
  const handlePhoneNumberBlur = (index, value) => {
    const newPhoneNumbers = structuredClone(phoneNumbers); // Create a deep copy to avoid mutating initialData
    if (typeof newPhoneNumbers[index] === "object") {
      newPhoneNumbers[index].phone_number = value;
    } else {
      newPhoneNumbers[index] = { phone_number: value };
    }
    setPhoneNumbers(newPhoneNumbers);
    setFormData((prev) => ({
      ...prev,
      phone_numbers: newPhoneNumbers,
    }));
  };

  const removePhoneNumberField = async (index) => {
    const confirmDelete = window.confirm(
      "Weet je zeker dat je dit telefoonnummer wilt verwijderen?"
    );
    if (confirmDelete) {
      const phoneNumberToRemove = phoneNumbers[index];

      if (phoneNumberToRemove.phone_number_id) {
        // Only delete if the phone number has an ID
        try {
          await middleware.delete(
            `phonenumbers?phone_number_id=${phoneNumberToRemove.phone_number_id}`
          );
          ToastSuccess("Telefoonnummer succesvol verwijderd!");
          setConsumerInfoUpdated(true);
        } catch (error) {
          console.error("Error deleting phone number:", error);
          ToastError(
            "Er is een fout opgetreden bij het verwijderen van het telefoonnummer."
          );
          return;
        }
      }

      // Update state after successful deletion
      const newPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
      setPhoneNumbers(newPhoneNumbers);
      setFormData((prev) => ({
        ...prev,
        phone_numbers: newPhoneNumbers,
      }));
    }
  };

  const handleEmailSettingsChange = async (settingKey, value) => {
    const updatedSettings = { ...emailSettings, [settingKey]: value ? 1 : 0 };
    setEmailSettings(updatedSettings);

    const res = await middleware.get(
      `emailsettings?internal_id=${internal_id}`
    );

    if (!res.data.length) {
      await middleware.put(
        `emailsettings?internal_id=${internal_id}`,
        updatedSettings
      );
    } else {
      try {
        await middleware.put(
          `emailsettings?internal_id=${internal_id}`,
          updatedSettings
        );
        ToastSuccess("Voorkeur successvol veranderd!");
      } catch (error) {
        console.error(
          "Er ging iets fout met het veranderen van de voorkeur:",
          error
        );
        ToastError("Er ging iets fout met het veranderen van de voorkeur");
      }
    }
  };
  const handleEditClick = (row) => {
    setRowToEdit(row);
    setShowAddContactsModal(true);
  };
  const handleDeleteClick = async (row) => {
    const confirm = window.confirm(
      "Weet je zeker dat je deze contactpersoon wil verwijderen?"
    );
    if (confirm) {
      await middleware.delete(
        `businessescontacts?business_contact_id=${row.business_contact_id}`
      );
      fetchContacts();
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => <div className="fw-bold">Naam</div>,
        accessor: "firstName",
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column">
              <p
                className="mb-0 text-nowrap text-center cursorPointer"
                onClick={() => { handleEditClick(row.original) }}
                style={{ color: "#009fe3" }}
              >
                {`${row.original.first_name} ${row.original.last_name}`}
              </p>
            </div>
          );
        },
      },
      {
        Header: () => <div className="text-center fw-bold">Geboortedatum</div>,
        accessor: "birthDay",
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              <p className="mb-0">{row.original.birthday}</p>
            </div>
          );
        },
      },
      {
        Header: () => "",
        accessor: "Edit",
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column">
              <img
                className="trashImg"
                onClick={() => handleEditClick(row.original)}
                src={editIcon}
              />
            </div>
          );
        },
      },
      {
        Header: () => "",
        accessor: "Delete",
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column">
              <img
                className="trashImg"
                onClick={() => handleDeleteClick(row.original)}
                src={Trash}
              />
            </div>
          );
        },
      },
    ],
    [consumerData]
  );

  const tableInstance = useTable({
    columns,
    data: consumerData.length ? consumerData : [],
  });

  if (loading) {
    return <h2 className="m-4 loader"></h2>;
  }

  return (
    <div className="px-4 py-4">
      <form className="col-12" ref={formRef}>
        {typeCustomer === "B" && (
          <div>
            <label className="fw-bold mb-2 secondaryTitles">
              Bedrijfsgegevens
            </label>
            <div className="col-12 d-flex flex-row align-content-end mb-3">
              <BorderedTextInput
                label={"Bedrijfsnaam"}
                size="l"
                inputValue={formData.business.company_name}
                setInputValue={(value) => getValueInput(value, "company_name")}
                updateFunction={handleSubmit}
              />
            </div>
            <div className="col-12 d-flex flex-row gap-4 align-content-end mb-4">
              <BorderedTextInput
                label={"KVK nummber"}
                size="l"
                inputValue={formData.business.cocnumber}
                setInputValue={(value) => getValueInput(value, "cocnumber")}
                updateFunction={handleSubmit}
              />
              <BorderedTextInput
                label={"BTW nummer"}
                size="l"
                inputValue={formData.business.vatnumber}
                setInputValue={(value) => getValueInput(value, "vatnumber")}
                updateFunction={handleSubmit}
              />
            </div>
          </div>
        )}

        <AddContactsModal
          rowToEdit={rowToEdit}
          setRowToEdit={setRowToEdit}
          openAddContacts={showAddContactsModal}
          setUpdatedContacts={setUpdatedContacts}
          closeAddContacts={() => setShowAddContactsModal(false)}
        />
        <div>
          {typeCustomer === "C" && (
            <>
              <div className="InputRow">
                <BorderedTextInput
                  inputValue={formData.consumer.first_name}
                  setInputValue={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      consumer: {
                        ...prev.consumer,
                        first_name: value,
                      },
                    }))
                  }
                  updateFunction={handleSubmit}
                  label="Voornaam"
                  size="l"
                  placeholder="Voornaam"
                />
                <BorderedTextInput
                  inputValue={formData.consumer.last_name}
                  setInputValue={(value) =>
                    setFormData((prev) => ({
                      ...prev,
                      consumer: {
                        ...prev.consumer,
                        last_name: value,
                      },
                    }))
                  }
                  updateFunction={handleSubmit}
                  label="Achternaam"
                  size="l"
                  placeholder="Achternaam"
                />
              </div>
            </>
          )}
          <div className="col-12 d-flex flex-row align-content-end my-3">
            <div className="d-flex flex-column col-6 pe-3">
              <div className="d-flex justify-content-between">
                <label className="mb-2">E-mailadres</label>
                <img
                  className="pe-3 addIconSizeModals"
                  src={Plus}
                  onClick={addEmailField}
                  alt="Add"
                  style={{ cursor: "pointer" }}
                />
              </div>
              {emails.map((email, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <BorderedTextInput
                    size="l"
                    inputValue={email.email || ""}
                    setInputValue={(value) => handleEmailChange(index, value)}
                    updateFunction={handleSubmit}
                  />
                  <img
                    className="ms-2 removeIconSizeModals"
                    src={Minus}
                    onClick={() => removeEmailField(index)}
                    alt="Remove"
                    style={{ cursor: "pointer", width: "3%" }}
                  />
                </div>
              ))}
            </div>
            <div className="d-flex flex-column col-6 ps-3">
              <div className="d-flex justify-content-between">
                <label className="mb-2">Telefoonnummer</label>
                <img
                  className="pe-3 addIconSizeModals"
                  src={Plus}
                  onClick={addPhoneNumberField}
                  alt="Add"
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div>
                <div className="d-flex flex-column gap-2">
                  {phoneNumbers.map((phoneNumber, index) => (
                    <div className="d-flex gap-1">
                      {
                        // Kan niet geleik updaten, er is geen updatefunction prop
                      }
                      <BorderedDropDown
                        size="l"
                        selectedValue={phoneNumber.country_phone_prefix.toString()}
                        setSelectedValue={(value) => setPhoneCode(index, value)}
                        options={[
                          { title: "Nederland (+31)", value: "31" },
                          { title: "België (+32)", value: "32" },
                          { title: "Duitsland (+49)", value: "49" },
                          { title: "Algerije (+213)", value: "213" },
                          { title: "Andorra (+376)", value: "376" },
                          { title: "Angola (+244)", value: "244" },
                          { title: "Anguilla (+1264)", value: "1264" },
                          { title: "Antigua & Barbuda (+1268)", value: "1268" },
                          { title: "Argentinië (+54)", value: "54" },
                          { title: "Armenië (+374)", value: "374" },
                          { title: "Aruba (+297)", value: "297" },
                          { title: "Australië (+61)", value: "61" },
                          { title: "Oostenrijk (+43)", value: "43" },
                          { title: "Azerbeidzjan (+994)", value: "994" },
                          { title: "Bahama's (+1242)", value: "1242" },
                          { title: "Bahrein (+973)", value: "973" },
                          { title: "Bangladesh (+880)", value: "880" },
                          { title: "Barbados (+1246)", value: "1246" },
                          { title: "Wit-Rusland (+375)", value: "375" },
                          { title: "Belize (+501)", value: "501" },
                          { title: "Benin (+229)", value: "229" },
                          { title: "Bermuda (+1441)", value: "1441" },
                          { title: "Bhutan (+975)", value: "975" },
                          { title: "Bolivia (+591)", value: "591" },
                          {
                            title: "Bosnië en Herzegovina (+387)",
                            value: "387",
                          },
                          { title: "Botswana (+267)", value: "267" },
                          { title: "Brazilië (+55)", value: "55" },
                          { title: "Brunei (+673)", value: "673" },
                          { title: "Bulgarije (+359)", value: "359" },
                          { title: "Burkina Faso (+226)", value: "226" },
                          { title: "Burundi (+257)", value: "257" },
                          { title: "Cambodja (+855)", value: "855" },
                          { title: "Kameroen (+237)", value: "237" },
                          { title: "Canada (+1)", value: "1" },
                          { title: "Kaapverdië (+238)", value: "238" },
                          { title: "Kaaimaneilanden (+1345)", value: "1345" },
                          {
                            title: "Centraal-Afrikaanse Republiek (+236)",
                            value: "236",
                          },
                          { title: "Chili (+56)", value: "56" },
                          { title: "China (+86)", value: "86" },
                          { title: "Colombia (+57)", value: "57" },
                          { title: "Comoren (+269)", value: "269" },
                          { title: "Congo (+242)", value: "242" },
                          { title: "Cookeilanden (+682)", value: "682" },
                          { title: "Costa Rica (+506)", value: "506" },
                          { title: "Kroatië (+385)", value: "385" },
                          { title: "Cuba (+53)", value: "53" },
                          { title: "Noord-Cyprus (+90392)", value: "90392" },
                          { title: "Zuid-Cyprus (+357)", value: "357" },
                          { title: "Tsjechië (+42)", value: "42" },
                          { title: "Denemarken (+45)", value: "45" },
                          { title: "Djibouti (+253)", value: "253" },
                          { title: "Dominica (+1809)", value: "1809" },
                          {
                            title: "Dominicaanse Republiek (+1809)",
                            value: "1809",
                          },
                          { title: "Ecuador (+593)", value: "593" },
                          { title: "Egypte (+20)", value: "20" },
                          { title: "El Salvador (+503)", value: "503" },
                          { title: "Equatoriaal-Guinea (+240)", value: "240" },
                          { title: "Eritrea (+291)", value: "291" },
                          { title: "Estland (+372)", value: "372" },
                          { title: "Ethiopië (+251)", value: "251" },
                          { title: "Falklandeilanden (+500)", value: "500" },
                          { title: "Faeröer (+298)", value: "298" },
                          { title: "Fiji (+679)", value: "679" },
                          { title: "Finland (+358)", value: "358" },
                          { title: "Frankrijk (+33)", value: "33" },
                          { title: "Frans-Guyana (+594)", value: "594" },
                          { title: "Frans-Polynesië (+689)", value: "689" },
                          { title: "Gabon (+241)", value: "241" },
                          { title: "Gambia (+220)", value: "220" },
                          { title: "Georgië (+7880)", value: "7880" },
                          { title: "Ghana (+233)", value: "233" },
                          { title: "Gibraltar (+350)", value: "350" },
                          { title: "Griekenland (+30)", value: "30" },
                          { title: "Groenland (+299)", value: "299" },
                          { title: "Grenada (+1473)", value: "1473" },
                          { title: "Guadeloupe (+590)", value: "590" },
                          { title: "Guam (+671)", value: "671" },
                          { title: "Guatemala (+502)", value: "502" },
                          { title: "Guinee (+224)", value: "224" },
                          { title: "Guinee-Bissau (+245)", value: "245" },
                          { title: "Guyana (+592)", value: "592" },
                          { title: "Haïti (+509)", value: "509" },
                          { title: "Honduras (+504)", value: "504" },
                          { title: "Hongkong (+852)", value: "852" },
                          { title: "Hongarije (+36)", value: "36" },
                          { title: "IJsland (+354)", value: "354" },
                          { title: "India (+91)", value: "91" },
                          { title: "Indonesië (+62)", value: "62" },
                          { title: "Iran (+98)", value: "98" },
                          { title: "Irak (+964)", value: "964" },
                          { title: "Ierland (+353)", value: "353" },
                          { title: "Israël (+972)", value: "972" },
                          { title: "Italië (+39)", value: "39" },
                          { title: "Jamaica (+1876)", value: "1876" },
                          { title: "Japan (+81)", value: "81" },
                          { title: "Jordanië (+962)", value: "962" },
                          { title: "Kazachstan (+7)", value: "7" },
                          { title: "Kenia (+254)", value: "254" },
                          { title: "Kiribati (+686)", value: "686" },
                          { title: "Noord-Korea (+850)", value: "850" },
                          { title: "Zuid-Korea (+82)", value: "82" },
                          { title: "Koeweit (+965)", value: "965" },
                          { title: "Kirgizië (+996)", value: "996" },
                          { title: "Laos (+856)", value: "856" },
                          { title: "Letland (+371)", value: "371" },
                          { title: "Libanon (+961)", value: "961" },
                          { title: "Lesotho (+266)", value: "266" },
                          { title: "Liberia (+231)", value: "231" },
                          { title: "Libië (+218)", value: "218" },
                          { title: "Liechtenstein (+417)", value: "417" },
                          { title: "Litouwen (+370)", value: "370" },
                          { title: "Luxemburg (+352)", value: "352" },
                          { title: "Macau (+853)", value: "853" },
                          { title: "Macedonië (+389)", value: "389" },
                          { title: "Madagaskar (+261)", value: "261" },
                          { title: "Malawi (+265)", value: "265" },
                          { title: "Maleisië (+60)", value: "60" },
                          { title: "Maldiven (+960)", value: "960" },
                          { title: "Mali (+223)", value: "223" },
                          { title: "Malta (+356)", value: "356" },
                          { title: "Marshalleilanden (+692)", value: "692" },
                          { title: "Martinique (+596)", value: "596" },
                          { title: "Mauritanië (+222)", value: "222" },
                          { title: "Mayotte (+269)", value: "269" },
                          { title: "Mexico (+52)", value: "52" },
                          { title: "Micronesië (+691)", value: "691" },
                          { title: "Moldavië (+373)", value: "373" },
                          { title: "Monaco (+377)", value: "377" },
                          { title: "Mongolië (+976)", value: "976" },
                          { title: "Marokko (+212)", value: "212" },
                          { title: "Mozambique (+258)", value: "258" },
                          { title: "Myanmar (+95)", value: "95" },
                          { title: "Namibië (+264)", value: "264" },
                          { title: "Nauru (+674)", value: "674" },
                          { title: "Nepal (+977)", value: "977" },
                          { title: "Nieuw-Caledonië (+687)", value: "687" },
                          { title: "Nieuw-Zeeland (+64)", value: "64" },
                          { title: "Nicaragua (+505)", value: "505" },
                          { title: "Niger (+227)", value: "227" },
                          { title: "Nigeria (+234)", value: "234" },
                          { title: "Niue (+683)", value: "683" },
                          { title: "Norfolk (+672)", value: "672" },
                          {
                            title: "Noordelijke Marianen (+670)",
                            value: "670",
                          },
                          { title: "Noorwegen (+47)", value: "47" },
                          { title: "Oman (+968)", value: "968" },
                          { title: "Palau (+680)", value: "680" },
                          { title: "Panama (+507)", value: "507" },
                          { title: "Papoea-Nieuw-Guinea (+675)", value: "675" },
                          { title: "Paraguay (+595)", value: "595" },
                          { title: "Peru (+51)", value: "51" },
                          { title: "Filipijnen (+63)", value: "63" },
                          { title: "Polen (+48)", value: "48" },
                          { title: "Portugal (+351)", value: "351" },
                          { title: "Puerto Rico (+1787)", value: "1787" },
                          { title: "Qatar (+974)", value: "974" },
                          { title: "Roemenië (+40)", value: "40" },
                          { title: "Rusland (+7)", value: "7" },
                          { title: "Rwanda (+250)", value: "250" },
                          { title: "San Marino (+378)", value: "378" },
                          { title: "Saoedi-Arabië (+966)", value: "966" },
                          { title: "Senegal (+221)", value: "221" },
                          { title: "Servië (+381)", value: "381" },
                          { title: "Seychellen (+248)", value: "248" },
                          { title: "Sierra Leone (+232)", value: "232" },
                          { title: "Singapore (+65)", value: "65" },
                          { title: "Slowakije (+421)", value: "421" },
                          { title: "Slovenië (+386)", value: "386" },
                          { title: "Salomonseilanden (+677)", value: "677" },
                          { title: "Somalië (+252)", value: "252" },
                          { title: "Zuid-Afrika (+27)", value: "27" },
                          { title: "Spanje (+34)", value: "34" },
                          { title: "Sri Lanka (+94)", value: "94" },
                          { title: "Sint-Helena (+290)", value: "290" },
                          {
                            title: "Saint Kitts en Nevis (+1869)",
                            value: "1869",
                          },
                          { title: "Saint Lucia (+1758)", value: "1758" },
                          { title: "Soedan (+249)", value: "249" },
                          { title: "Suriname (+597)", value: "597" },
                          { title: "Swaziland (+268)", value: "268" },
                          { title: "Zweden (+46)", value: "46" },
                          { title: "Zwitserland (+41)", value: "41" },
                          { title: "Syrië (+963)", value: "963" },
                          { title: "Taiwan (+886)", value: "886" },
                          { title: "Tadzjikistan (+7)", value: "7" },
                          { title: "Thailand (+66)", value: "66" },
                          { title: "Togo (+228)", value: "228" },
                          { title: "Tonga (+676)", value: "676" },
                          {
                            title: "Trinidad en Tobago (+1868)",
                            value: "1868",
                          },
                          { title: "Tunesië (+216)", value: "216" },
                          { title: "Turkije (+90)", value: "90" },
                          { title: "Turkmenistan (+7)", value: "7" },
                          { title: "Turkmenistan (+993)", value: "993" },
                          {
                            title: "Turks- en Caicoseilanden (+1649)",
                            value: "1649",
                          },
                          { title: "Tuvalu (+688)", value: "688" },
                          { title: "Oeganda (+256)", value: "256" },
                          { title: "VK (+44)", value: "44" },
                          { title: "Oekraïne (+380)", value: "380" },
                          {
                            title: "Verenigde Arabische Emiraten (+971)",
                            value: "971",
                          },
                          { title: "Uruguay (+598)", value: "598" },
                          { title: "VS (+1)", value: "1" },
                          { title: "Vanuatu (+678)", value: "678" },
                          { title: "Vaticaanstad (+379)", value: "379" },
                          { title: "Venezuela (+58)", value: "58" },
                          { title: "Vietnam (+84)", value: "84" },
                          { title: "Wallis en Futuna (+681)", value: "681" },
                          { title: "Jemen (+967)", value: "967" },
                          { title: "Zambia (+260)", value: "260" },
                          { title: "Zimbabwe (+263)", value: "263" },
                        ]}
                      />
                      <BorderedTextInput
                        size="l"
                        inputValue={phoneNumber.phone_number}
                        setInputValue={(newNum) => handlePhoneNumberChange(index, newNum)}
                        updateFunction={handleSubmit}
                      />
                      <img
                        className="ms-2 removeIconSizeModals"
                        src={Minus}
                        onClick={() => removePhoneNumberField(index)}
                        alt="Remove"
                        style={{ cursor: "pointer", width: "3%" }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex flex-row align-content-end my-3">
            {typeCustomer === "C" && (
              <InputComponent
                type={"date"}
                onValueChange={getValueInput}
                onBlur={handleBlurInput}
                parentCol={6}
                defaultValue={consumerData?.birthday || ""}
                labelValue={"birthday"}
                labelName={"Geboortedatum"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
            )}
            <div className="col-6 d-flex justify-content-start ps-3">
              <div className="col-4 d-flex flex-column">
                <label className="mb-2 fw-medium fw-semibold">
                  Nieuwsbrief
                </label>
                <label className="switchNewCustomer">
                  <input
                    type="checkbox"
                    checked={emailSettings?.newsletter === 1}
                    onChange={(e) =>
                      handleEmailSettingsChange("newsletter", e.target.checked)
                    }
                  />
                  <span className="sliderNewCustomer round"></span>
                </label>
              </div>
              <div className=" col-4  d-flex flex-column">
                <label className="mb-2 fw-semibold">Verjaardagactie</label>
                <label className="switchNewCustomer">
                  <input
                    type="checkbox"
                    checked={emailSettings?.birthday_promotion === 1}
                    onChange={(e) =>
                      handleEmailSettingsChange(
                        "birthday_promotion",
                        e.target.checked
                      )
                    }
                  />
                  <span className="sliderNewCustomer round"></span>
                </label>
              </div>
            </div>
          </div>
          {typeCustomer === "B" && (
            <>
              <label
                className={`fw-bold mb-2 secondaryTitles ${typeCustomer === "B" ? "" : "d-none"
                  }`}
              >
                Contactpersonen
                <span
                  className="p-2"
                  role="button"
                  onClick={() => setShowAddContactsModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </span>
              </label>
              <div className="px-0 tableContacts">
                <TablePreset
                  data={[
                    {
                      tableInstance: tableInstance,
                      pagination: pagination,
                      primary_key: "business_contact_id",
                    },
                  ]}
                  setPagination={setPagination}
                  handleSelection={() => { }}
                  noCheckbox={true}
                />
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default GeneralInfoTab;
