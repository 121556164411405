import React, { useState } from 'react';
import PageTemplate from '../Templates/PageTemplate';
import { BackButton } from '../HouseStyle/Components/Buttons';
import { useNavigate } from 'react-router';

const NewSalesChannel = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    return (
        <PageTemplate navbarTitle={"Pos HUB"} pageIsLoading={loading}>
            <div className="d-flex flex-column gap-5 w-100">
                <div>
                    <div className="d-flex mb-4 mt-4 justify-content-between">
                        <div className="d-flex gap-5">
                            <BackButton text={"Terug"} onClickFunction={() => navigate('/verkoopkanalen')} />
                        </div>
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

export default NewSalesChannel;