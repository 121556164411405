import React from "react";

const Policies = ({ companyInfo }) => {
    // Functie om een Base64 PDF te converteren en downloaden
    const downloadPDF = (base64String, fileName) => {
        if (!base64String) return;

        // Base64 string decoderen naar een Blob
        const byteCharacters = atob(base64String.split(",")[1] || base64String);
        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], { type: "application/pdf" });

        // Creëer een tijdelijke URL en simuleer een klik om te downloaden
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl); // Ruim geheugen op
    };

    return (
        <div className="p-4 pb-0 w-50 parentCompanyInfoColumn">
            {console.log(companyInfo)}
            <div className="d-flex justify-content-between pb-4">
                <h6 className="w-50 fw-bold">Algemene voorwaarden</h6>
                <h6 className="w-50">
                    {companyInfo.general_condition ? (
                        <a href="#" onClick={() => downloadPDF(companyInfo.general_condition, "Algemene_Voorwaarden.pdf")} className="FlatTextLink  text-decoration-none">
                            Algemene_Voorwaarden.pdf
                        </a>
                    ) : (
                        "Niet beschikbaar"
                    )}
                </h6>
            </div>
            <div className="d-flex justify-content-between pb-4">
                <h6 className="w-50 fw-bold">Privacy statement</h6>
                <h6 className="w-50">
                    {companyInfo.privacy_statement ? (
                        <a href="#" onClick={() => downloadPDF(companyInfo.privacy_statement, "Privacy_Statement.pdf")} className="FlatTextLink  text-decoration-none">
                            Privacy_Statement.pdf
                        </a>
                    ) : (
                        "Niet beschikbaar"
                    )}
                </h6>
            </div>
            <div className="d-flex justify-content-between pb-4">
                <h6 className="w-50 fw-bold">Disclaimer</h6>
                <h6 className="w-50">
                    {companyInfo.disclaimer ? (
                        <a href="#" onClick={() => downloadPDF(companyInfo.disclaimer, "Disclaimer.pdf")} className="FlatTextLink  text-decoration-none">
                            Disclaimer.pdf
                        </a>
                    ) : (
                        "Niet beschikbaar"
                    )}
                </h6>
            </div>
        </div>
    );
};

export default Policies;
