import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ModalStyle.css";
import middleware from "../Api/Middleware";
import { BorderedTextInput } from '../HouseStyle/Components/Inputs';
import { BorderedDropDown } from '../HouseStyle/Components/DropDowns';
import { ButtonBlue, ButtonLightGrey } from '../HouseStyle/Components/Buttons';
import { m } from 'framer-motion';
import { DisabledByDefault } from '@mui/icons-material';
import countries from 'i18n-iso-countries';
import nlLocale from "i18n-iso-countries/langs/nl.json";

countries.registerLocale(nlLocale);

const VATModal = ({ taxEdit, setTaxEdit, taxEditData, percentageFormat}) => {
  const [showTax, setShowTax] = useState(false);
  const [zero, setZero] = useState(taxEditData.zero_percentage);
  const [high, setHigh] = useState(taxEditData.high_percentage);
  const [reduced, setReduced] = useState(taxEditData.reduced_percentage);
  const [reduced2, setReduced2] = useState(taxEditData.reduced2_percentage);
  const [reduced3, setReduced3] = useState(taxEditData.reduced3_percentage);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedReduced, setSelectedReduced] = useState('')

  useEffect(() => {
    if (taxEditData) {
      setHigh(taxEditData.high_percentage);
      setZero(taxEditData.zero_percentage);
      setReduced(taxEditData.reduced_percentage);
      setReduced2(taxEditData.reduced2_percentage);
      setReduced3(taxEditData.reduced3_percentage);
      setIsEditing(true);
    }
  }, [taxEditData]);

  const dropDownOptions = [];
  if (reduced !== null && reduced !== undefined) {
    dropDownOptions.push({ title: `${percentageFormat(reduced)}`, value: taxEditData.reduced_tax_id });
  }
  if (reduced2 !== null && reduced2 !== undefined) {
    dropDownOptions.push({ title: `${percentageFormat(reduced2)}`, value: taxEditData.reduced2_tax_id });
  }
  if (reduced3 !== null && reduced3 !== undefined) {
    dropDownOptions.push({ title: `${percentageFormat(reduced3)}`, value: taxEditData.reduced3_tax_id });
  }

  const updateSelected = async (newVal) => {
    setSelectedReduced(newVal)
    try {
    await middleware.put(
      `products/taxrates`, {
        tax_id: newVal,
        selected: 1
      }
    );
  } catch (error) {
    console.log(error);
  }
  console.log(newVal)
}

  return (
    <>
      <Modal
        id="modalEditTax"
        show={taxEdit}
        onHide={() => setTaxEdit(false)}
        centered
        size="xl"
        backdrop="static"
      >
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className='BlockTitle'>
            {`Selecteer standaard waardes voor ${countries.getName(taxEditData.country, 'nl') || taxEditData.country}`}
          </Modal.Title>

          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={() => setTaxEdit(false)}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
          <div className="contendModal w-100">

            <label className="FlatText fw-bold">Hoog</label>
            <p className='ps-2'>{percentageFormat(high)}</p>

            {((reduced2 === undefined || reduced2 === null || reduced2 === '') &&
              (reduced3 === undefined || reduced3 === null || reduced3 === '')) ? (
              <div>
                <label className="FlatText fw-bold">Laag</label>
                <p className='ps-2'>{percentageFormat(reduced)}</p>
              </div>
            ) : (
              <div className='mb-4'>
                <label className="FlatText fw-bold">Laag</label>
                <BorderedDropDown
                  placeholder={percentageFormat(selectedReduced)}
                  options={dropDownOptions}
                  selectedValue={selectedReduced}
                  setSelectedValue={updateSelected}
                />
              </div>
            )}

            <label className="FlatTex fw-bold">Overig</label>
            <p className='ps-2 mb-0'>{percentageFormat(zero)}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom d-flex flex-row">
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default VATModal;