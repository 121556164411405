import React from "react";

const GeneralInfo = ({ companyInfo }) => {
    return <div className="p-4 pb-0 w-50 parentCompanyInfoColumn">
        {console.log(companyInfo)}
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-50 fw-bold">Bedrijfsnaam</h6>
            <h6 className="w-50">{companyInfo.company_name}</h6>
        </div>
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-50 fw-bold">Hoofdadres</h6>
            <h6 className="w-50">{companyInfo.street_name} {companyInfo.house_number}{companyInfo.addition} <br /> {companyInfo.postal_code} <br /> {companyInfo.country}</h6>
        </div>
        {companyInfo.phone_number &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">Telefoon</h6>
                <h6 className="w-50">{companyInfo.phone_number}</h6>
            </div>
        }
        {companyInfo.email &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">E-mail</h6>
                <h6 className="w-50">{companyInfo.email}</h6>
            </div>
        }
        {companyInfo.website &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">Website</h6>
                <h6 className="w-50">{companyInfo.website}</h6>
            </div>
        }
        {companyInfo.invoice_streetname &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">Factuuradres</h6>
                <h6 className="w-50">
                    {companyInfo.invoice_streetname} {companyInfo.invoice_house_number}{companyInfo.invoice_addition}
                    <br />
                    {companyInfo.invoice_postalcode} {companyInfo.invoice_city}
                    <br />
                    {companyInfo.country}
                </h6>
            </div>
        }
        {companyInfo.b2b_b2c.toString() &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">B2B / B2C</h6>
                <span className="Blue WhiteText px-3 py-1 rounded-pill">{companyInfo.b2b_b2c == 0 ? "B2B" : "B2C"}</span>
            </div>
        }
    </div>
}


export default GeneralInfo;