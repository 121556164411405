import middleware from "../../Api/Middleware";

const handleExcelDownloadCloud = async (exportId, getUrl, fileType) => {
    try {
        // Request the file URL from the API
        const response = await middleware.get(`${getUrl}/${exportId}${fileType ? `/${fileType}` : ''}`);
        const data = response.data;

        const fileUrl = data.file_url;

        // Create a temporary anchor element to initiate the download
        const a = document.createElement('a');
        a.href = fileUrl;

        // Set the `download` attribute to trigger download behavior
        a.download = fileUrl.split('/').pop(); // Use the filename from the URL
        a.target = '_blank'; // Ensures the download occurs in the same tab

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up by removing the element
    } catch (error) {
        console.error('Download failed:', error);
    }
};  

export default handleExcelDownloadCloud
