const allOptions = [
    {
        // General information
        TableName: "Warehouse\\Products",
        DisplayName: "Producten",

        // Columns in the database with their index being the model in the api and the string values in the array being the column names in the database
        TableColumns: {
            "Warehouse\\Products": [ // Primary table
                "product_name",
                "product_number",
                "product_description",
                "status",
                "type",
                "supplier_id",
                "head_product_id",
                "image",
            ],
            
            // Sub tables
            "Warehouse\\Barcodes": [
                "barcode"
            ],

            "Warehouse\\ProductStock": [
                "amount"
            ],

            // Custom fields that are not in the database and be customly handled in the backend
            "CUSTOM_FIELDS": [
                "purchase_price",
                "sell_price_excl",
                "sell_price_incl",
                "sale_price_excl",
                "sale_price_incl",
                "category",
                "vat_rate",
                "style_number",
                "size",
                "color",
                "color_hex",
                "gender",
                "brand",
            ]
        },

        // User-friendly display names for columns that are in TableColumns
        DisplayNames: {
            product_name: "Product naam",
            product_number: "Product nummer",
            product_description: "Product omschrijving",
            status: "Status",
            type: "Type",
            supplier_id: "Leverancier ID",
            head_product_id: "Hoofdproduct ID",
            barcode: "Barcode",
            image: "Afbeelding",
            amount: "Voorraad",

            // Custom fields
            purchase_price: "Inkoopprijs",
            sell_price_excl: "Verkoop prijs (excl btw)",
            sell_price_incl: "Verkoop prijs (incl btw)",
            sale_price_excl: "Kortingsprijs (excl btw)",
            sale_price_incl: "Kortingsprijs (incl btw)",
            vat_rate: "BTW tarief",
            style_number: "Stijlnummer",
            size: "Maat",
            color: "Kleur",
            color_hex: "Kleur (Hexcode)",
            gender: "Geslacht",
            category: "Categorie",
            brand: "Merk",
        },

        // Keys which can be used as leading key for updating.
        UpdateKeys: {
            "Warehouse\\Products": ["product_number"],
            "Warehouse\\Barcodes": ["barcode"],
        },

        // Display information to direct the user to the object that has been made
        DirectInfo: {
            displayValues: ["product_name"], // Values that will be displayed as the object, when multiple it will have a space between
            url: "/producten/bewerken", // Url of the edit/view page 
        },

        // Foreign key among all the tables
        SharedId: "product_id",

        // Columns that are required in order to process the import
        RequiredColumns: ["product_name"],
    },
    {
        // General information
        TableName: "Consumers",
        DisplayName: "Customers",

        // Columns in the database with their index being the model in the api
        TableColumns: {
            Consumers: [ // Primary table
                "prefix",
                "first_name",
                "last_name",
                "phone_number",
                "birthday",
                "language",
                "fax_number",
                "loyalty_points",
            ],
            
            // Sub tables
            Emails: ["email"],
            Addresses: ["city", "streetname", "housenumber", "addition", "postalcode"],
        },

        // Foreign key among all the tables
        SharedId: "internal_id",

        // Columns that are required in order to process the import
        RequiredColumns: ["first_name", "last_name"],

        // Keys which can be used as leading key for updating.
        UpdateKeys: { 
            consumers: ["first_name"] 
        },

        // Display information to direct the user to the object that has been made
        DirectInfo: {
            displayValues: ["first_name", "last_name"], // Values that will be displayed as the object, when multiple it will have a space between
            url: "/klanten/bewerken", // Url of the edit/view page 
        },

        // User-friendly display names for columns
        DisplayNames: {
            prefix: "Voorvoegsel",
            first_name: "Voornaam",
            last_name: "Achternaam",
            phone_number: "Telefoonnummer",
            birthday: "Geboortedatum",
            language: "Taal",
            fax_number: "Faxnummer",
            loyalty_points: "Loyaltypunten",
            email: "E-mail",
            city: "Stad",
            streetname: "Straatnaam",
            housenumber: "Huisnummer",
            addition: "Toevoeging",
            postalcode: "Postcode",
        },
    },
];

export default allOptions;