import React, { useState, useEffect, useMemo } from "react";
import middleware from "../Api/Middleware";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { faCaretLeft, faPrint, faXmark } from "@fortawesome/free-solid-svg-icons";
import * as Icons from "../HouseStyle/Icons";
import PageTemplate from "../Templates/PageTemplate";
import countries from 'i18n-iso-countries';
import SendEmailModal from '../Modals/Orders/SendEmailModal';
import ReceiveEmailModal from "../Modals/Orders/ReceiveEmailModal";
import InvoiceIconBlack from "./Icons/paper-black.png";
import PrinterIcon from "./Icons/printer.png";
import Backbutton from "../Products/subpages/images/BackIcon.svg";

// Component imports
import NotitieComponent from "./Components/NotitieComponent";
import CustomerComponent from "./Components/CustomerComponent";
import ReferentieComponent from "./Components/ReferentieComponent";
import EmailComponents from "./Components/EmailComponent";
import VerwerkOrderComponent from "./Components/VerwerkOrderComponent";
import CrediteringComponent from "./Components/CrediteringComponent";
import VerzendComponent from "./Components/VerzendComponent";
import BetalingComponent from "./Components/BetalingComponent";
import CancelModalComponent from "./Components/CancelModalComponent";
import TerugbetalenModalComponent from "./Components/TerugbetalenModalComponent";
import PayRequestModalComponent from "./Components/PayRequestModalComponent";
import { ButtonWhite } from "../HouseStyle/Components/Buttons";

countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

const OrdersEdit = () => {
  const { order_number } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [customer, setCustomer] = useState({});
  const [emails, setEmails] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [invoiceAddress, setInvoiceAddress] = useState(null);
  const [shipments, setShipments] = useState([]);
  const [shownShipments, setShownShipments] = useState({});
  const [productsToProcess, setProductsToProcess] = useState([]);
  const [productsToProcessFullyShown, setProductsToProcessFullyShown] = useState([]);
  const [soldProducts, setSoldProducts] = useState([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [paybackModalVisible, setPaybackModalVisible] = useState(false);
  const [payRequestModalVisible, setPayRequestModalVisible] = useState(false);
  const [prices, setPrices] = useState({});
  const [creditations, setCreditations] = useState([]);
  const [paybackAmount, setPaybackAmount] = useState(0);
  const fetchAll = async () => {
    try {
      setPageIsLoading(true);

      const [orderResponse, countryCodesResponse] = await Promise.all([
        middleware.get(`orders/fullorder?order_number=${order_number}`),
        middleware.get(`countryCodes`),
      ]);

      const order = orderResponse.data;
      setOrder(order);

      const customerType = order.internal_id.charAt(0) === "C" ? 'consumers' : 'businesses';
      const { order_id, internal_id } = order;

      const [
        customerResponse,
        addressResponse,
        soldProductsResponse,
        PriceResponse,
        emailResponse,
        phoneNumberResponse,
        shipmentsResponse
      ] = await Promise.all([
        middleware.get(`${customerType}?internal_id=${internal_id}`),
        middleware.get(`addresses?internal_id=${internal_id}`),
        middleware.get(`orders/soldproducts?order_id=${order_id}`),
        middleware.get(`/sp/orderPricesTally?order_id=${order_id}`),
        middleware.get(`emails?internal_id=${internal_id}`),
        middleware.get(`phonenumbers?internal_id=${internal_id}`),
        middleware.get(`shipments/order?order_id=${order_id}`),
      ]);

      // Handle addresses
      if (addressResponse.data.length > 0) {
        addressResponse.data.forEach((address) => {
          const country = countryCodesResponse.data.data.find(code => code.country_code_id === address.country_code_id)?.country_code_2;
          address.country = country;
          (address.address_kind === 0 || address.address_kind === 2) ? setDeliveryAddress(address) : setInvoiceAddress(address);
        });
      }

      // Handle sold products
      const soldProducts = orderResponse.data.orders_sold_products;
      const unprocessedProducts = soldProducts.filter(product => product.amount - product.items_processed > 0)
        .map(product => ({
          ...product,
          amount: product.amount - product.items_processed
        }));
      setCustomer(customerResponse.data.data[0] || {});
      setEmails(emailResponse.data.data || []);
      setPhoneNumbers(phoneNumberResponse.data.data || []);
      setPrices(PriceResponse.data[0] || {});
      setProductsToProcess(unprocessedProducts);
      setSoldProducts(orderResponse.data.orders_sold_products);
      // Handle shipments
      const fetchedShipments = shipmentsResponse.data?.data?.Response ?? [];
      setShipments(fetchedShipments);
      setShownShipments(fetchedShipments.reduce((acc, _, index) => ({ ...acc, [index]: true }), {}));

      setPageIsLoading(false);
    } catch (error) {
      console.error(error);
      // Handle error (e.g., show error message to user)
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const handleCustomerSelection = (selectedCustomer) => {
    setCustomer(selectedCustomer);
  };

  const calculateSalePrice = soldProduct => {
    switch (soldProduct.sale_unit) {
      case 0: // Percentage
        return Number(soldProduct.price_incl_tax) * Number(soldProduct.amount) / 100 * Number(soldProduct.sale_amount);
      case 1: // Euro
        return Number(soldProduct.sale_amount) * Number(soldProduct.amount);
      default:
        return 0;
    }
  };

  const showPrice = price => {
    return `€ ${Number(price).toFixed(2).replace('.', ',').replace(',00', ',-')}`;
  };

  const showSalePrice = soldProduct => {
    const salePrice = calculateSalePrice(soldProduct);
    return showPrice(Number(soldProduct.price_incl_tax) - salePrice / soldProduct.amount);
  };

  const handleBackButton = () => {
    if (searchParams.get("sc_return") != null) {
      navigate(`/verkoopkanalen/${searchParams.get("sc_return")}`);
    } else {
      navigate("/bestellingen");
    }
  }
  console.log(order.shipment_method)
  const canEdit = useMemo(() => productsToProcess.length > 0, [productsToProcess]);
  return (
    <PageTemplate navbarTitle={`Order Hub`} pageIsLoading={pageIsLoading}>
      <CancelModalComponent cancelModalVisible={cancelModalVisible} setCancelModalVisible={setCancelModalVisible} orderId={order.order_number} />
      <TerugbetalenModalComponent paybackAmount={paybackAmount} paybackModalVisible={paybackModalVisible} setPaybackModalVisible={setPaybackModalVisible} />
      <PayRequestModalComponent payRequestModalVisible={payRequestModalVisible} setPayRequestModalVisible={setPayRequestModalVisible} order={order}/>

      <div className="container-fluid py-4">
        <div className="row mb-4">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <img className="back-button-img" src={Backbutton} onClick={handleBackButton} />
                <h5 className="mb-0">{`#${order.order_number}`}</h5>
              </div>
              <div className="d-flex gap-3">
                <ButtonWhite text="Print pakbon" onClickFunction={() => { }} icon={PrinterIcon} />
                <ButtonWhite text="Print factuur" onClickFunction={() => { }} icon={InvoiceIconBlack} />
                <ButtonWhite disabled={!canEdit || !soldProducts.find((e) => e.amount > e.items_credited)} text="Bewerken" onClickFunction={() => navigate(`/bestellingen/bewerken/${order_number}`)} />
                <ButtonWhite disabled={!soldProducts.find((e) => e.amount > e.items_credited)} text="Retourneren" onClickFunction={() => { setCancelModalVisible(true) }} />
              </div>
            </div>
          </div>
        </div>

        <div className="row g-4">
          <div className="col-lg-8">
            {/* VerwerkOrderComponent */}
            {soldProducts.find(e => e.amount - e.items_processed - e.items_credited > 0) &&
              <div>
                <VerwerkOrderComponent
                  productsToProcess={soldProducts}
                  productPic={Icons.Size}
                  showPrice={showPrice}
                  showSalePrice={showSalePrice}
                  calculateSalePrice={calculateSalePrice}
                  statuses={[order.order_status, order.payment_status, order.delivery_status]}
                  orderId={order.order_number}
                // order={order}
                // middleware={middleware}
                // fetchAll={fetchAll}
                // emails={emails}
                />
              </div>
            }

            {/* BetalingComponent */}
            {soldProducts.find((e) => e.items_credited > 0) &&
              <div>
                <CrediteringComponent
                  setPaybackAmount={setPaybackAmount}
                  productsToProcess={soldProducts}
                  order={order}
                  prices={prices}
                  showPrice={showPrice}
                  setPaybackModalVisible={setPaybackModalVisible}
                />
              </div>}

            {/* VerzendComponent */}
            {soldProducts.find((e) => e.items_processed > 0) &&
              <div>
                <VerzendComponent
                  orderNumber={order.order_number}
                  orderId={order.order_id}
                  productsToProcess={soldProducts}
                  shipmentMethod={order.shipment_method}
                // shipments={shipments}
                // shownShipments={shownShipments}
                // setShownShipments={setShownShipments}
                // productPic={productPic}
                // productsToProcessFullyShown={productsToProcessFullyShown}
                // setProductsToProcessFullyShown={setProductsToProcessFullyShown}
                // showPrice={showPrice}
                // showSalePrice={showSalePrice}
                // calculateSalePrice={calculateSalePrice}
                />
              </div>
            }
            {/* BetalingComponent */}
            {/* {soldProducts.find((e) => e.items_credited != e.amount) && */}
            <div>
              <BetalingComponent
                order={order}
                setPayRequestModalVisible={setPayRequestModalVisible}
              />
            </div>
            {/* } */}
          </div>

          <div className="col-lg-4">
            {/* CustomerComponent */}
            <div className="mb-4">
              <CustomerComponent
                mode="show"
                editable={false}
                customer={customer}
                order={order}
                emails={emails}
                setOrder={setOrder}
                phoneNumbers={phoneNumbers}
                deliveryAddress={deliveryAddress}
                countries={countries}
                invoiceAddress={invoiceAddress}
              />
            </div>

            {/* ReferentieComponent */}
            {/* <div className="card shadow-sm mb-4">
              <div className="card-body">
                <ReferentieComponent reference={order.reference} />
              </div>
            </div> */}

            {/* EmailComponents */}
            <div className="mb-4">
              <EmailComponents
                ReceiveEmailModal={ReceiveEmailModal}
                SendEmailModal={SendEmailModal}
                order={order}
                emails={emails}
                productsToProcess={productsToProcess}
              />
            </div>

            {/* NotitieComponent */}
            <NotitieComponent
              order={order}
              setOrder={setOrder}
            />
          </div>
        </div>
      </div>
    </PageTemplate >
  );
};

export default OrdersEdit;