// src/components/HouseStyle/Components/CodeAccordion.jsx
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import CodeHighlight from './CodeHighlight';
import 'bootstrap/dist/css/bootstrap.min.css';

const CodeAccordion = ({ title = "Bekijk code", code = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState('0px');

  const togglePanel = () => {
    setIsOpen(prevState => !prevState);
  };

  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [isOpen]);

  return (
    <div className="collapsible-panel">
      <div className="collapsible-header" onClick={togglePanel}>
        <h3>{title}</h3>
        <span className={`arrow ${isOpen ? 'open' : ''}`}>{isOpen ? '▲' : '▼'}</span>
      </div>
      <div
        ref={contentRef}
        className="collapsible-content"
        style={{ maxHeight, transition: 'max-height 0.3s ease' }}
      >
        <div className="content-inner">
          <CodeHighlight code={code} />
        </div>
      </div>
    </div>
  );
};

CodeAccordion.propTypes = {
  title: PropTypes.string,
  code: PropTypes.string
};

export default CodeAccordion;
