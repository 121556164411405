import React from "react";
import TextEditor from "../../../../Main/TextEditor";
import {
  BorderedNumberInput,
  BorderedTextInput,
} from "../../../../HouseStyle/Components/Inputs";

const FirstBox = (props) => {
  // console.log(props);
  return (
    <>
      <div className="whiteBox p-5 mt-0 align-center">
        <div className="d-flex flex-column mb-3">
          <label className="inputTitle standardLabel fw-medium">
            Productnaam
          </label>

          <BorderedTextInput
            size="l"
            inputValue={props?.product?.product_name ?? ""}
            setInputValue={(newValue) => {
              props.setProduct({
                ...props.product,
                product_name: newValue,
              });
            }}
            updateFunction={props.edit ? props.handleChange : undefined} // If edit is true then use handleChange to dodge a bug
          />
        </div>
        <div className="d-flex flex-row productNumSelectLists col-12 justify-content-between">
          <div
            className="d-flex flex-column mb-3"
            style={
              props.product.type != 2 ? { width: "32.5%" } : { width: "48%" }
            }
          >
            <div className="d-flex flex-nowrap w-100">
              <label className="inputTitle standardLabel fw-medium">
                Productnummer
              </label>
            </div>
            <BorderedTextInput
              size="l"
              inputValue={props?.product?.product_number ?? ""}
              setInputValue={(newProductNumber) => {
                props.setProduct({
                  ...props.product,
                  product_number: newProductNumber,
                });
              }}
              updateFunction={
                props.edit
                  ? () => {
                      props.handleChange();
                    }
                  : undefined
              }
            />
          </div>
          {props.product.type != 2 ? (
            <div className="d-flex flex-column" style={{ width: "32.5%" }}>
              <div className="d-flex flex-row justify-content-between">
                <label className="inputTitle standardLabel fw-medium">
                  Barcode
                </label>

                <select
                  className="smallStandardInput standardDropdownArrow fitContent pe-4 me-2"
                  id="sel1"
                  name="sellist1"
                  value={props?.selectedOption ?? ""}
                  onChange={(e) => {
                    props.setSelectedOption(e.target.value);
                  }}
                >
                  <option value="3" hidden>
                    -- Selecteer een optie --
                  </option>
                  <option value="0">Aangepaste barcode</option>
                  <option value="2">Automatisch gegenereerd</option>
                </select>
              </div>

              <div className="d-flex flex-column mt-0 marginRight0">
                {props.selectedOption === "0" && (
                  <BorderedNumberInput
                    size="l"
                    placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
                    inputValue={props.barcode === null ? "" : props.barcode}
                    setInputValue={(Value) => {
                      props.setBarcode(Value);
                    }}
                    updateFunction={props.edit ? props.handleChange : undefined}
                    onBlur={(e) => props.handleChange(e)}
                    onWheel={(e) => e.target.blur()}
                  />
                )}
                {props.selectedOption === "2" && (
                  <BorderedNumberInput
                    size="l"
                    placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
                    inputValue={props.barcode === null ? "" : props.barcode}
                    updateFunction={props.edit ? props.handleChange : undefined}
                    onWheel={(e) => e.target.blur()}
                    onBlur={(e) => props.handleChange(e)}
                  />
                )}
                {props.selectedOption === "3" && (
                  <BorderedNumberInput
                    size="l"
                    placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
                    inputValue={props.barcode === null ? "" : props.barcode}
                    setInputValue={(Value) => {
                      props.setBarcode(Value);
                    }}
                    updateFunction={props.edit ? props.handleChange : undefined}
                    onBlur={(e) => props.handleChange(e)}
                    onWheel={(e) => e.target.blur()}
                  />
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            className="d-flex flex-column"
            style={
              props.product.type != 2 ? { width: "32.5%" } : { width: "48%" }
            }
          >
            <div className="d-flex flex-column">
              <label className="inputTitle standardLabel fw-medium">
                Merk
                {/* Add brand btn */}
                {/* <props.FontAwesomeIcon
                  icon={props.faPlus}
                  color="#6a6a6a"
                  className="hover"
                  onClick={() => props.setShowBrandModal(true)}
                  data-bs-toggle="exampleModal"
                  data-bs-target="#exampleModal"
                /> */}
              </label>
              {props.edit ? (
                // Edit brand
                <select
                  className="standardInput standardDropdownArrow"
                  id="sel1"
                  name="sellist1"
                  value={props.brand?.brand_id ?? ""}
                  onChange={(e) => {
                    props.setProductBrand({
                      ...props.brand,
                      brand_id: e.target.value,
                    });
                    props.edit && props.setUpdatedProduct(true);
                  }}
                >
                  <option hidden></option>
                  {props.brands.map((brands) => {
                    return (
                      <option key={brands.brand_id} value={brands.brand_id}>
                        {brands.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                // Add pages brand
                <select
                  className="standardInput standardDropdownArrow"
                  onChange={(e) => props.setBrandId(e.target.value)}
                >
                  <option hidden></option>
                  {props.brands.map((brands, index) => {
                    return (
                      <option value={brands.brand_id} key={index}>
                        {brands.name}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="mb-2">
            <label className="inputTitle ms-3 fw-medium">
              Productomschrijving
            </label>
            <TextEditor
              onChange={(e) => {
                props.setProduct({ ...props.product, product_description: e });
                props.edit && props.setUpdatedProduct(true);
              }}
              value={props.product.product_description}
              onBlur={(e) => {
                props.handleBlur(e);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstBox;
