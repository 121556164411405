import React, { useState, useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import middleware from "../../../Api/Middleware";
import "../css/AddProductPages.css";
import "../../../Main/Main.css";
import "../../product.css";
import ToastError from "../../../Toasts/ToastError";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import NavbarIcon from "../../../Navbars/Sidebar/Icons/globe.png";
import Backbutton from "../images/BackIcon.svg";
import ThirdBox from "../edit/edit_components/boxthird";
import PriceBox from "../edit/edit_components/boxPrice";
// Media
import PageTemplate from "../../../Templates/PageTemplate";

// boxes
import FirstBox from "../edit/edit_components/boxfirst";

import MediaUploaderComponent from "../../../MediaUpload/MediaUploaderComponent";
import Categories from "../sub_components/Categories";
import salesChannelsApi from "../../../Api/SalesChannels";

const AddSimple = () => {
  // Navigation hook
  const navigate = useNavigate();

  // Button
  const [buttonPressed, setbuttonPressed] = useState("");
  const [brands, setBrands] = useState([]);

  // Selected brand, Channels, Sales, Genders, Barcode
  const [brandId, setBrandId] = useState(null);
  const [salesChannels, setSalesChannels] = useState([]);
  const [selectedSalesChannels, setSelectedSalesChannels] = useState([]);
  const [genders, setGenders] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);

  const [barcode, setBarcode] = useState("");

  // Tax state
  const [tax, setTax] = useState("0");
  const [selectedTax, setSelectedTax] = useState("21");
  const [taxClass, setTaxClass] = useState(1);

  const [images, setImages] = useState([]);
  const [status, setStatus] = useState(false);
  const [stock, setStock] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("0");
  const [categories, setCategories] = useState([]);

  const [values, setValues] = useState({
    product_name: "",
    product_number: "",
    barcode: "",
  });
  // State for holding categories.
  const [initialCategories, setInitialCategories] = useState([
    { id: 1, headId: null, name: "Categories" },
  ]);

  const previousSelectedOption = useRef(selectedOption);
  const [checkedCategories, setCheckedCategories] = useState({});
  const [prices, setPrices] = useState([]);
  const [priceMargin, setPriceMargin] = useState();

  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () =>
        resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
      reader.onerror = (error) => reject(error);
    });

  const validateImages = async () => {
    //Check if there are any images that are faulty (like too big or unsupported file type)
    let faultyImages = false;
    for (let index = 0; index < images.length; index++) {
      const CurrentImage = images[index];
      if (CurrentImage.error) {
        faultyImages = true;
      }
    }
    if (faultyImages) {
      return "Verwijder de foto's die ongeldig zijn voordat je opslaat.";
    }

    return true;
  };

  const saveImages = async (productID) => {
    try {
      let currentProductId = productID;
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const currentYear = new Date().getFullYear();
      const folderPath = `warehouse/uploads/${currentYear}/${currentMonth}/${currentProductId}`;

      //The string with all the data that will be send to the API so it saves the image path, order and color
      let bulkDataString = "";
      let imagestrings = [];

      //Loop through the images and preparing them to upload
      for (let index = 0; index < images.length; index++) {
        const CurrentImage = images[index];
        const base64String = await blobToBase64(CurrentImage.file);
        imagestrings.push(base64String);

        // Increase the upload count to notify the user the image has been uploaded
        // setUploadCount((prev) => prev + 1);

        //Add the data to the bulkdata that will eventually be sent to the middleware API
        bulkDataString += `${
          CurrentImage.name
        }|${null}|${folderPath}/${CurrentImage.name.replace(/\s/g, "_")}|${
          index + 1
        }|${null}`;
        if (index < images.length - 1) {
          bulkDataString += ",";
        }
      }
      // Post request to the middleware api to save the image data to the database and delete the older information
      await middleware.post(
        `products/media/upload?product_id=${currentProductId}&bulk_data=${encodeURIComponent(
          bulkDataString
        )}`,
        { imagestrings: imagestrings }
      );
      return true;
    } catch (error) {
      // When something goes wrong the user gets logged out and send to the login page
      return (
        error.response?.data?.Message ?? error.response?.data?.error ?? false
      );
    }
  };

  const validate = (e) => {
    e.target.checkValidity();
    e.target.reportValidity();
  };

  const handleGenerateBarcode = async () => {
    // Generate a 13-digit barcode
    try {
      const res = await middleware.get("products/generateBarcode");
      const barcode = res.data.generatedBarcode;
      return barcode;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      let newBarcodeValue = "";
      // Check if the selectedOption has changed
      if (
        previousSelectedOption.current !== selectedOption &&
        selectedOption === "0"
      ) {
        setValues((prevValues) => ({
          ...prevValues,
          barcode: "",
        }));
      }

      if (selectedOption === "0") {
        newBarcodeValue = values.barcode;
      } else if (selectedOption === "2") {
        newBarcodeValue = await handleGenerateBarcode();
      } else if (selectedOption === "1") {
        newBarcodeValue = values.product_number;
      }

      setBarcode(newBarcodeValue);
      // Update the previous selected option to the current one
      previousSelectedOption.current = selectedOption;
    })();
  }, [selectedOption, values.barcode]);

  useEffect(() => {
    if (selectedOption === "1") {
      setBarcode(values.product_number);
    }
  }, [values.product_number]);

  const proccessCategorieIds = (obj) => {
    const regex = /^(\d+)-(\d+)-(\d+)-(\d+)$/;
    const categoryIds = [];
    const HeadIds = [];

    Object.entries(obj)
      .filter(([key, value]) => value === true && regex.test(key))
      .forEach(([key]) => {
        const match = key.match(regex);
        if (match) {
          categoryIds.push(match[3]); // Voeg het derde deel toe aan match3
          HeadIds.push(match[4]); // Voeg het vierde deel toe aan match4
        }
      });
    return {
      categoryIds,
      HeadIds,
    };
  };

  const handleChange = (event) => {
    event.preventDefault();
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleBarcodeChange = (event) => {
    event.preventDefault();
    // change automatic generated barcode
    // console.log(event.target.value);
    setBarcode(event.target.value);
  };

  const handleGendersChange = (event) => {
    const valueAsInt = parseInt(event.target.value, 10); // Zet de waarde om naar een integer

    if (event.target.checked) {
      setSelectedGenders([...selectedGenders, valueAsInt]); // Voeg de integer waarde toe
    } else {
      setSelectedGenders(selectedGenders.filter((e) => e !== valueAsInt)); // Verwijder de integer waarde
    }
  };

  const handleSalesChannelsChange = (event) => {
    const valueAsInt = parseInt(event.target.value, 10); // Zet de waarde om naar een integer

    if (event.target.checked) {
      setSelectedSalesChannels([...selectedSalesChannels, valueAsInt]); // Voeg de integer waarde toe
    } else {
      setSelectedSalesChannels(
        selectedSalesChannels.filter((e) => e !== valueAsInt)
      ); 
    }
  };

  const fetchAll = async () => {
    setIsLoading(true);
    const [fetchBrands, fetchCategories, fetchGenders, fetchSalesChannels] =
      await Promise.all([
        middleware.get(`products/brands?status=1`),
        middleware.get(`products/category`),
        middleware.get(`products/genders`),
        salesChannelsApi.get("saleschannels?status=1"),
      ]);
    const cat = fetchCategories.data.data.map((map) => {
      return {
        primary_key: map.primary_key,
        id: map.category_id,
        headId: map.head_category_id,
        name: map.title,
      };
    });
    setInitialCategories(cat);
    setBrands(fetchBrands.data.data);
    setCategories(fetchCategories.data.data);
    setGenders(fetchGenders.data.data);
    setSalesChannels(fetchSalesChannels.data);
    setIsLoading(false);
  };
  const MediaUploader = useRef();
  useEffect(() => {
    fetchAll();
  }, []);

  const submitNew = async (event) => {
    event.preventDefault(); // Voorkom de standaardactie van het formulier

    var newBarcode = null;
    newBarcode = barcode;

    const categoryIds = proccessCategorieIds(checkedCategories).categoryIds;
    const newprices = prices.reduce((acc, curr) => {
      acc[curr.type] = {
        price_id: curr.price_id,
        incl_tax:
          typeof curr.incl_tax === "string"
            ? Number(curr.incl_tax.replace(",", ".")).toFixed(2)
            : Number(curr.incl_tax).toFixed(2),
        excl_tax:
          typeof curr.excl_tax === "string"
            ? Number(curr.excl_tax.replace(",", ".")).toFixed(2)
            : Number(curr.excl_tax).toFixed(2),
      };
      return acc;
    }, {});
    let product_id;
    try {
      const validate = await validateImages();
      if (validate !== true) {
        ToastError(validate);
        return false;
      }
      const productName = values.product_name;
      if (productName == null || productName == "") {
        ToastError("Product naam is verplicht");
        return false;
      }
      await middleware
        .post(`products/create`, {
          product: {
            name: values.product_name,
            number: values.product_number,
            barcode: barcode,
            tax_id: taxClass,
            description: values.product_description,
          },
          prices: newprices,
          category_ids: categoryIds,
          gender_ids: selectedGenders,
          sales_channel_ids: selectedSalesChannels,
          brand_id: brandId,
          stock: stock,
        })
        .then(async (e) => {
          product_id = e.data.product.product_id;
          await saveImages(product_id);
        });
      ToastSuccess("Product is aangemaakt");
      if (buttonPressed == 1) {
        window.location.reload();
      } else if (buttonPressed == 2) {
        navigate("/producten/bewerken/" + product_id);
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.details) {
        const details = err.response.data.details;

        if (details["product.number"]) {
          ToastError(`Productnummer bestaat al`);
          return;
        }
        if (details["product.barcode"]) {
          ToastError("Barcode bestaat al");
          return;
        }
      } else {
        console.error(err);
      }
      ToastError("Er is een fout opgetreden bij het aanmaken van het product.");
    }
  };

  return (
    <PageTemplate
      navbarTitle={"Warehouse HUB"}
      // pageIsLoading={isLoading}
      iconNavbar={NavbarIcon}
    >
      <form
        onSubmit={submitNew}
        method="post"
        className="d-flex justify-content-center minFitContent w-100"
      >
        <div className="mb-3 d-flex flex-column formItems  minFitContent w-100">
          <div className="d-flex justify-content-between mb-4 w-100 flex-wrap-reverse">
            <div className="d-flex gap-5">
              <div className="d-flex flex-row gap-2 align-items-center">
                <img
                  className="back-button-img"
                  src={Backbutton}
                  onClick={() => navigate("/producten")}
                />
                <h5 className="p-0 m-0">Terug</h5>
              </div>
              {/* Published btn*/}
              <div className="d-flex flex-column align-items-center">
                {!status ? (
                  <p className="mb-1 p-0">Gepubliceerd</p>
                ) : (
                  <p className="mb-1 p-0">Gearchiveerd</p>
                )}
                <label className="switchActiveProduct">
                  <input
                    type="checkbox"
                    checked={!status}
                    onChange={(e) => setStatus(!e.target.checked)}
                  />
                  <span className="sliderActiveProduct round"></span>
                </label>
              </div>
            </div>
            <div className="fitContent text-nowrap">
              <button
                type="submit"
                id="1"
                className="btn darkBlueStandardButton me-3 Nunito w-auto px-5"
                onClick={(e) => setbuttonPressed(e.target.id)}
              >
                Aanmaken en nieuwe toevoegen
              </button>
              <button
                type="submit"
                id="2"
                className="btn lightBlueStandardButton Nunito"
                onClick={(e) => setbuttonPressed(e.target.id)}
              >
                Aanmaken
              </button>
            </div>
          </div>
          <div className="d-flex flex-column flex-xxl-row justify-content-between">
            <div className="col-xxl-8">
              <div>
                <div className="d-flex flex-column fitcontentHeight mb-4">
                  <FirstBox
                    handleChange={handleChange}
                    product={values}
                    setProduct={setValues}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    brands={brands}
                    barcode={barcode}
                    setBarcode={setBarcode}
                    validate={validate}
                    setBrandId={setBrandId}
                    handleBarcodeChange={handleBarcodeChange}
                    edit={false}
                  />
                </div>
              </div>
              <MediaUploaderComponent
                edit={false}
                setImages={setImages}
                images={images}
              ></MediaUploaderComponent>
            </div>
            <div className="d-flex flex-row flex-xxl-column col-xxl-4 row mt-4 mt-xxl-0">
              {/* category */}
              <div className="col-6 col-xxl-12 mb-4">
                <div className="whiteBox h-100">
                  {categories != null && categories.length ? (
                    <Categories
                      initialCategories={initialCategories}
                      setCheckedCategories={setCheckedCategories}
                      checkedCategories={checkedCategories}
                    />
                  ) : (
                    <div className="whiteBox boxCategories">
                      <div className="d-flex flex-column">
                        <div className=" widthBoxes w-100 p-3 categories">
                          <div className="d-flex justify-content-between flex-column">
                            <h5 className="inputTitle mb-3">Categorieën</h5>
                            <p>Er zijn geen categorieën gevonden</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* gender */}
              <div className="col-6 col-xxl-12 mb-4">
                <div className="whiteBox p-5 pb-2 pt-4 h-100">
                  <h5 className="inputTitle mb-4">Geslacht</h5>
                  <div>
                    {genders.map((gender, index) => (
                      <div key={index} className="d-flex flex-row mb-3">
                        <label className="checkbox-containerSimple">
                          <input
                            type="checkbox"
                            value={gender.gender_id}
                            onChange={handleGendersChange}
                          />
                          <span className="checkmarkSimple"></span>
                        </label>
                        <h5 className="inputTitle fw-medium">{gender.name}</h5>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* prices component */}
              <div className="col-6 col-xxl-12 pb-4">
                <PriceBox
                  selectedTax={selectedTax}
                  setSelectedTax={setSelectedTax}
                  setTax={setTax}
                  taxClass={taxClass}
                  setTaxClass={setTaxClass}
                  handleChange={handleChange}
                  prices={prices}
                  setPrices={setPrices}
                  priceMargin={priceMargin}
                  setPriceMargin={setPriceMargin}
                  validate={validate}
                  edit={false}
                />
              </div>
              {/* Stock */}
              <div className="mb-4 col-6 col-xxl-12">
                <ThirdBox
                  stock={stock}
                  setStock={setStock}
                  validate={validate}
                  edit={false}
                />
              </div>
              {/* sales channels */}
              <div className="mb-4 col-6 col-xxl-12">
                <div className="whiteBox p-4 h-100">
                  <div className="d-flex flex-column h-100">
                    <h5 className="inputTitle mb-4">Verkoopkanaal</h5>
                    <div className="px-2 d-flex h-100 flex-column justify-content-around">
                      {salesChannels.length > 0 ? (
                        salesChannels.map((salesChannel, index) => (
                          <div
                            key={index}
                            className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between"
                          >
                            <h6 className="switchText">
                              {salesChannel.sales_channel_name}
                            </h6>
                            <label className="defaultSwitch ms-4">
                              <input
                                type="checkbox"
                                value={salesChannel.sales_channel_id}
                                onChange={handleSalesChannelsChange}
                              />
                              <span className="defaultSlider round"></span>
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>Er zijn nog geen verkoopkanalen gemaakt.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Suppliers */}
              {/* <div className="pb-4 col-6 col-xxl-12">
                <FourthBox
                  suppliers={suppliers}
                  setSuppliers={setSuppliers}
                  businesses={businesses}
                  setBusinesses={setBusinesses}
                  validate={validate}
                  attributeConnections={attributeConnections}
                  setAttributeConnections={setAttributeConnections}
                  edit={false}
                />
              </div> */}
            </div>
          </div>
        </div>
      </form>
    </PageTemplate>
  );
};

export default AddSimple;
