import React, { useEffect, useState } from "react";
import "./components/Main/Darkmode.css";
import "./components/Main/Network.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cookies from "universal-cookie";
import MainPage from "./components/MainPage/MainPage";
import AddSimple from "./components/Products/subpages/add/AddSimple";
import AddVariation from "./components/Products/subpages/add/AddVariation";
import EditPage from "./components/Products/subpages/edit/EditPage";
import Orders from "./components/Orders/Orders";
import OrdersEdit from "./components/Orders/OrdersEdit";
import OrdersNew from "./components/Orders/OrdersNew";
import Login from "./components/Login/Login";
import NotFound from "./components/NotFound/NotFound";
import Products from "./components/Products/Products";
import WarehouseSettings from "./components/WarehouseSettings/WarehouseSettings";
import ColorSettings from "./components/ColorSettings/ColorSettings";
import Blueprints from "./components/Blueprints/Blueprints";
import EditBlueprints from "./components/Blueprints/EditBlueprints/EditBlueprints";
import Brands from "./components/Brands/Brands";
import BrandsEdit from "./components/Brands/BrandsEdit/BrandsEdit";
import Categories from "./components/Categories/Categories";
import GeneralSettings from "./components/GeneralSettings/GeneralSettings";
import Giftcards from "./components/GeneralSettings/Giftcards/Giftcards";
import GiftcardsEdit from "./components/GeneralSettings/Giftcards/GiftcardEdit";
import GiftcardsNew from "./components/GeneralSettings/Giftcards/GiftcardNew";
import Users from "./components/GeneralSettings/Users/Users";
import EditUsers from "./components/GeneralSettings/Users/EditUsers/EditUsers";
import { ToastContainer } from "react-toastify";
import CategoryEdit from "./components/Categories/CategoryEdit/CategoryEdit";
import AddUsers from "./components/GeneralSettings/Users/AddUsers/AddUser";
import ProccessOrders from "./components/Orders/ProccessOrders";
import Customer from "./components/Customer/Customer";
import EditCustomers from "./components/Customer/EditCustomers/EditCustomers";
import ForgotPassword from "./components/Login/pages/ForgotPassword";
import ResetPassword from "./components/Login/pages/ResetPassword";
import CatEdit from "./components/CategoryNew/CategoryEditen/CatEdit";
import CatAdd from "./components/CategoryNew/CategoryAdd/CatAdd";
import Labels from "./components/Labels/Labels";
import NetworkEventListener from "./components/Events/NetworkEventListener";
import UnauthorizedEventListener from "./components/Events/UnauthorizedEventListener";
import ImportExportHome from "./components/Import/importExportHome";
import ExportPage from "./components/Import/exportPage";
import ExportInfoPage from "./components/Import/exportInfoPage";
import ImportPage from "./components/Import/importPage";
import ColumnConfigration from "./components/Import/columnConfigurationPage";
import Results from "./components/Import/resultPage";
import middleware from "./components/Api/Middleware";
import NoAccesScreen from "./components/LoadingScreen/NoAccesScreen";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import Shipping from "./components/Shipping/Shipping";
import Packages from "./components/Shipping/Packages";
import ShowProfile from "./components/GeneralSettings/Profile/ShowProfile";
import EditProfile from "./components/GeneralSettings/Profile/EditProfile";
import CancelOrder from "./components/Orders/CancelOrder";
import HouseStyleShowCase from "./components/HouseStyle/HouseStyleShowCase";
import AnimatedLine from "./components/MainPage/line/AnimatedLineWithDoubleBump";
import EditOrder from "./components/Orders/EditOrder";
import Payments from "./components/Payments/Payments";
import AccountCreation from "./components/Payments/AccountCreation";
import AccountOverview from "./components/Payments/AccountOverview";
import CompanyInfo from "./components/GeneralSettings/CompanyInfo/CompanyInfo";
import GeneralInfo from "./components/GeneralSettings/CompanyInfo/EditPages/GeneralInfo";
import HouseStyle from "./components/GeneralSettings/CompanyInfo/EditPages/HouseStyle";
import FacturizationDetails from "./components/GeneralSettings/CompanyInfo/EditPages/FacturizationDetails";
import Policies from "./components/GeneralSettings/CompanyInfo/EditPages/Policies";
import TechnicalSettings from "./components/GeneralSettings/TechnicalSettings/TechnicalSettings";
import TechnicalSettingsEdit from "./components/GeneralSettings/TechnicalSettings/TechnicalSettingsEdit";
import SalesChannels from "./components/SalesChannels/SalesChannels";
import SalesChannel from "./components/SalesChannels/Edit/SalesChannel";
import CurrentProducts from "./components/SalesChannels/Edit/CurrentProducts";
import CurrentCategories from "./components/SalesChannels/Edit/CurrentCategories";
import CurrentBrands from "./components/SalesChannels/Edit/CurrentBrands";
import CurrentPaymentMethods from "./components/SalesChannels/Edit/CurrentPaymentMethods";
import ValidationPage from "./components/Import/validationPage";
import NewSalesChannel from "./components/SalesChannels/NewSalesChannel";
import VATSettings from "./components/GeneralSettings/VATSettings/VATSettings";
import PaymentUsers from "./components/Payments/SubAccounts/PaymentUsers";

function App() {
  const cookies = new Cookies();
  const [csvData, setCsvData] = useState([]);
  const [downloadfiles, setDownloadFiles] = useState([]);
  const [selectedTable, setSelectedTable] = useState("");
  const [importResponse, setImportResponse] = useState(() => {
    const saved = localStorage.getItem("importResponse");
    return saved ? JSON.parse(saved) : [];
  });
  const [navInfo, setNavInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const token = cookies.get("token");
      if (!token) {
        console.error("No token found. Cannot fetch navigation details.");
        setNavInfo(null);
        return;
      }

      // Cleanup navInfo entries not matching the current token
      const cleanupNavInfo = () => {
        const currentToken = token;
        const keysToRemove = [];

        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (key && key.startsWith("navInfo[") && key.endsWith("]")) {
            const storedToken = key.slice("navInfo[".length, -1);
            if (storedToken !== currentToken) {
              keysToRemove.push(key);
            }
          }
        }

        keysToRemove.forEach((key) => localStorage.removeItem(key));
      };

      cleanupNavInfo();

      // If navInfo is not available, fetch it
      if (!navInfo || navInfo == undefined || navInfo == null) {
        try {
          const response = await middleware.get("navInfo");

          const data = response.data;

          // Store navInfo in localStorage
          localStorage.setItem(`navInfo[${token}]`, JSON.stringify(data));
          setNavInfo(data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching navigation details:", error);
          setNavInfo(null);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <BrowserRouter>
      <NetworkEventListener />
      <UnauthorizedEventListener />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<MainPage />}></Route>
        <Route path="/HouseStyleShowCase" element={<HouseStyleShowCase />} ></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/wachtwoord-vergeten" element={<ForgotPassword />}></Route>
        <Route path="/wachtwoord-resetten" element={<ResetPassword />}></Route>
        <Route path="/*" element={<NotFound />}></Route>

        <Route path="/klanten" element={<Customer />}></Route> <Route path="/klanten/bewerken/:internal_id" element={<EditCustomers />} ></Route>

        <Route path="/producten" element={<Products />}></Route>
        <Route path="/producten/alles" element={<Products />}></Route>
        <Route path="/producten/nieuw" element={<AddSimple />}></Route>
        <Route path="/producten/nieuw/variatie" element={<AddVariation />} ></Route>
        <Route path="/producten/bewerken/:product_id" element={<EditPage />} ></Route>

        <Route path="/algemene/instellingen" element={!loading ? navInfo?.role === 4 ? <GeneralSettings /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/bedrijfsinformatie" element={!loading ? navInfo?.role === 4 ? <CompanyInfo /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/bedrijfsinformatie/algemeen" element={!loading ? navInfo?.role === 4 ? <GeneralInfo /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/bedrijfsinformatie/huisstijl" element={!loading ? navInfo?.role === 4 ? <HouseStyle /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/bedrijfsinformatie/facturatie" element={!loading ? navInfo?.role === 4 ? <FacturizationDetails /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/bedrijfsinformatie/voorwaarden" element={!loading ? navInfo?.role === 4 ? <Policies /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/bedrijfsinformatie" element={!loading ? navInfo?.role === 4 ? <CompanyInfo /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/technisch" element={!loading ? navInfo?.role === 4 ? <TechnicalSettings /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/technischBewerken" element={!loading ? navInfo?.role === 4 ? <TechnicalSettingsEdit /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/gebruikers" element={!loading ? navInfo?.role === 4 ? <Users /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/gebruikers/aanmaken" element={!loading ? navInfo?.role === 4 ? <AddUsers /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/gebruikers/bewerken/:user_id" element={!loading ? navInfo?.role === 4 ? <EditUsers /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/btw" element={!loading ? navInfo?.role === 4 ? <VATSettings /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/cadeaukaarten" element={!loading ? navInfo?.role === 4 ? <Giftcards /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/cadeaukaarten/aanmaken" element={!loading ? navInfo?.role === 4 ? <GiftcardsNew /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/cadeaukaarten/bewerken/:card_id" element={!loading ? navInfo?.role === 4 ? <GiftcardsEdit /> : <NoAccesScreen /> : <LoadingScreen />}></Route>


        <Route path="/profiel" element={!loading ? <ShowProfile /> : <LoadingScreen />}></Route>
        <Route path="/profiel/bewerken" element={!loading ? <EditProfile /> : <LoadingScreen />}></Route>
        <Route path="/warehouse/instellingen" element={<WarehouseSettings />} ></Route>
        <Route path="/warehouse/instellingen/kleuren" element={<ColorSettings />} ></Route>
        <Route path="/warehouse/instellingen/blauwdrukken" element={<Blueprints />} ></Route>
        <Route path="/warehouse/instellingen/blauwdrukken/bewerken" element={<EditBlueprints />} ></Route>
        <Route path="/warehouse/instellingen/merken" element={<Brands />} ></Route>
        <Route path="/warehouse/instellingen/merken/bewerken/:brand_id" element={<BrandsEdit />} ></Route>
        {/* <Route path="/warehouse/instellingen/categoriën" element={<Category />}></Route> */}
        {/* <Route path="/warehouse/instellingen/categorieën/bewerken/:primary_key" element={<CategoryEdit />}></Route> */}
        <Route path="/warehouse/instellingen/categorieën" element={<Categories />} ></Route>
        <Route path="/warehouse/instellingen/categorieën/bewerken/:primary_key" element={<CatEdit />} ></Route>
        <Route path="/warehouse/instellingen/categorieën/toevoegen" element={<CatAdd />} ></Route>
        <Route path="/warehouse/instellingen/labels" element={<Labels />} ></Route>

        <Route path="/bestellingen" element={<Orders />}></Route>
        <Route path="/bestellingen/nieuw" element={<OrdersNew />}></Route>
        <Route path="/bestellingen/overzicht/:order_number" element={<OrdersEdit />} ></Route>
        <Route path="/bestellingen/bewerken/:order_number" element={<EditOrder />} ></Route>
        <Route path="/bestellingen/annuleren/:order_number" element={<CancelOrder />} ></Route>
        <Route path="/bestellingen/verwerken/:order_number" element={<ProccessOrders />} ></Route>

        <Route path="/verzendingen" element={<Shipping />}></Route>
        <Route path="/verzendingen/:shipment_id" element={<Packages />}></Route>

        <Route path="/betalingen" element={<Payments />}></Route>
        <Route path="/betalingen/account/nieuw" element={<AccountCreation />} ></Route>
        <Route path="/betalingen/account/overzicht" element={<AccountOverview />} ></Route>

        <Route path="/verkoopkanalen" element={<SalesChannels />}></Route>
        <Route path="/verkoopkanalen/nieuw" element={<NewSalesChannel />} ></Route>
        <Route path="/verkoopkanalen/:salesChannelId" element={<SalesChannel />} ></Route>
        <Route path="/verkoopkanalen/:salesChannelId/producten" element={<CurrentProducts />} ></Route>
        <Route path="/verkoopkanalen/:salesChannelId/categorieën" element={<CurrentCategories />} ></Route>
        <Route path="/verkoopkanalen/:salesChannelId/merken" element={<CurrentBrands />} ></Route>
        <Route path="/verkoopkanalen/:salesChannelId/betaalmethoden" element={<CurrentPaymentMethods />} ></Route>

        <Route path="/import-export-center" element={<ImportExportHome />} ></Route>
        <Route path="/export" element={<ExportPage />}></Route>
        <Route path="/export/resultaat/:export_id" element={<ExportInfoPage />} ></Route>
        <Route path="/import" element={<ImportPage setCsvData={setCsvData} setDownloadFiles={setDownloadFiles} setSelectedTable={setSelectedTable} selectedTable={selectedTable} />} ></Route>
        <Route path="/import/kolom-configuratie" element={<ColumnConfigration csvData={csvData} downloadfiles={downloadfiles} selectedTable={selectedTable} importResponse={importResponse} setImportResponse={setImportResponse} />} ></Route>
        <Route path="/import/resultaat/:import_id" element={<Results />} ></Route>
        <Route path="/import/valideren/:import_id" element={<ValidationPage />} ></Route>
      </Routes >
    </BrowserRouter >
  );
}

export default App;
