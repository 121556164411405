import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from 'react-router';
import "../Main/Main.css";
import "./WarehouseSettings.css";
import * as Icons from "../HouseStyle/Icons";
import PageTemplate from "../Templates/PageTemplate";
import SettingsBox from "../GeneralSettings/SettingsBox";
import { BackButton } from "../HouseStyle/Components/Buttons";

function WarehouseSettings() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const token = cookies.get("token");
    if (!token) {
      cookies.remove("token", { path: "/" });
      navigate("/");      
    } else {
      setLoading(false);
    }
}, [navigate]);

  return (
    <PageTemplate pageIsLoading={loading} navbarTitle={"Warehouse instellingen"}>
      <div className="d-flex align-items-start w-100 flex-column mb-4">
      <BackButton text={"Terug"} disabled={false} onClickFunction={() => navigate("/algemene/instellingen")} />
        <div className="d-flex justify-content-start w-100 flex-wrap">
          <SettingsBox icon={Icons.Size} title={"Blauwdrukken"} link={"./blauwdrukken"}/>
          <SettingsBox icon={Icons.Colors} title={"Kleuren"} link={"./kleuren"}/>
          <SettingsBox icon={Icons.Copyright} title={"Merken"} link={"./merken"}/>
          <SettingsBox icon={Icons.Categories} title={"Categorieën"} link={"./categorieën"}/>
          <SettingsBox icon={Icons.Tag} title={"Labels"} link={"./labels"} disabled={true}/>
        </div>
      </div>
    </PageTemplate>
  );
}

export default WarehouseSettings;