import React, { useEffect, useState } from "react";
import "../Main/Main.css";
import "./GeneralSettings.css";
import Cookies from "universal-cookie";
import { useNavigate } from 'react-router';
import NavbarIcon from '../Navbars/Sidebar/Icons/gear.png';
import PageTemplate from "../Templates/PageTemplate";
import SettingsBox from "./SettingsBox";
import * as Icons from "../HouseStyle/Icons";

const GeneralSettings = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      setLoading(true);
      const token = cookies.get("token");
      
      if (!token) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      } else {
        setLoading(false);
      }
  }, []);

  return (
    <PageTemplate pageIsLoading={loading} navbarTitle={"Instellingen"}  hideBackButton={true}>
        <div className="d-flex justify-content w-100 flex-column mb-4">
          <div className="d-flex justify-content-start w-100 flex-wrap">
            <SettingsBox icon={Icons.Mails} title={"E-mails"} link={"./emails"}/>
            <SettingsBox icon={Icons.GeneralSettings} title={"Standaard instellingen"} link={"/warehouse/instellingen"} disabled={false} />
            <SettingsBox icon={Icons.CompanyInformation} title={"Bedrijfsinformatie"} link={"./bedrijfsinformatie"} disabled={false}/>
            <SettingsBox icon={Icons.TechnicalSettings} title={"Technische instellingen"} link={"./technisch"} disabled={false}/>
            <SettingsBox icon={Icons.TaxSettings} title={"BTW-instellingen"} link={"./btw"} disabled={false}/>
            <SettingsBox icon={Icons.ProductInstellingen} title={"Product instellingen"} link={"./gebruikers"} disabled={true} />
            <SettingsBox icon={Icons.Giftcards} title={"Cadeaukaarten"} link={"./cadeaukaarten"} disabled={false}/>
          </div>
        </div>
    </PageTemplate>
  );
}

export default GeneralSettings;