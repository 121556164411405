import React from "react";

const SystemInfo = ({ settings, user_count }) => {

    const GetStatusColor = (status) => {
        return status ? "GreenText" : "RedText"
    }
    const GetStatus = (status) => {
        return status ? "Actief" : "Inactief"
    }

    // console.log(settings.status)
    return <div className="p-4 pb-0 w-50">
        <h4 className="fw-semibold mb-4">Software informatie</h4>
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-50 fw-bold">Software pakket</h6>
            <h6 className={"w-50"}>{settings.software_package}</h6>
        </div>
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-50 fw-bold">Huidige status</h6>
            <h6 className={"w-50 " + GetStatusColor(settings.status)}>{GetStatus(settings.status)}</h6>
        </div>
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-50 fw-bold">Api build nummer</h6>
            <h6 className="w-50">{settings.api_build_number}</h6>
        </div>
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-50 fw-bold">Backoffice build nummer</h6>
            <h6 className="w-50">{settings.backoffice_build_number}</h6>
        </div>
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-50 fw-bold">E-mail instellen</h6>
            <h6 className="w-50">{"Lorem ipsum"}</h6>
        </div>
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-50 fw-bold">Gebruikers</h6>
            <h6 className="w-50">{user_count}</h6>
        </div>
    </div>
}


export default SystemInfo;