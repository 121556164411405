import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useTable } from "react-table";
import middleware from "../Api/Middleware";
import TablePreset from "../Table/TablePreset";
import "../Table/Table.css";
import "./Orders.css";
import { useNavigate } from "react-router";
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import NavbarIcon from "../Navbars/Sidebar/Icons/envelope.png";
import PageTemplate from "../Templates/PageTemplate";
import TDSTextNavigation from "../Templates/TDSTextNavigation";
import CheckboxHeader from "../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../Table/TableComponents/CheckboxCell";
import SortableHeader from "../Table/TableComponents/SortableHeader";
import Filters from "../Filters/Filters";
import { Link } from "react-router-dom";

// Status display components with proper sorting
export const GetOrderStatus = ({ orderStatus }) => {
  switch (orderStatus) {
    case 0:
      return <label className="RedText">Geannuleerd</label>;
    case 1:
      return <label className="BlueText">Concept</label>;
    case 2:
      return <label className="BlueText">Nieuw</label>;
    case 3:
      return <label className="GreenText">Voltooid</label>;
    default:
      return "-";
  }
};

const GetPaymentStatus = ({ paymentStatus }) => {
  switch (paymentStatus) {
    case 0:
      return <label className="BlueText">Concept</label>;
    case 1:
      return <label className="OrangeText">Openstaand</label>;
    case 2:
      return <label className="OrangeText">Deels betaald</label>;
    case 3:
      return <label className="GreenText">Volledig betaald</label>;
    default:
      return "-";
  }
};

const GetDeliveryStatus = ({ deliveryStatus }) => {
  switch (deliveryStatus) {
    case 0:
      return <label className="RedText">Nog niet verzonden</label>;
    case 1:
      return <label className="BlueText">Verzonden</label>;
    case 2:
      return <label className="GreenText">Aangekomen</label>;
    default:
      return "-";
  }
};

const Orders = () => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [data, setData] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [searchBar, setSearchBar] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  });
  const [order, setOrder] = useState({ orderBy: '', direction: '' });
  const defaultFilters = [
    { index: 0, order: 1, name: "Datum", field: 'time_of_order', type: "daterange" },
    { index: 1, order: 2, name: "Prijs", field: 'total_price_incl_tax', type: "range" },
    { index: 2, order: 3, name: "Verkoopkanaal", field: 'sales_channel_name', type: "hasradio", options: [{ name: "Ja", value: 1 }, { name: "Nee", value: 0 }] },
    { index: 3, order: 4, name: "Status", field: 'order_status', type: "dropdown", options: [{ name: "Geannuleerd", value: 0 }, { name: "Concept", value: 1 }, { name: "Nieuw", value: 2 }, { name: "Voltooid", value: 3 }] },
    { index: 4, order: 5, name: "Betaling", field: 'payment_status', type: "dropdown", options: [{ name: "Concept", value: 0 }, { name: "Openstaand", value: 1 }, { name: "Deels betaald", value: 2 }, { name: "Volledig betaald", value: 3 }] },
    { index: 5, order: 6, name: "Verzending", field: 'delivery_status', type: "dropdown", options: [{ name: "Nog niet verzonden", value: 0 }, { name: "Verzonden", value: 1 }, { name: "Aangekomen", value: 2 }] },
    { index: 6, order: 7, name: "Klant", field: 'internal_id', type: "dropdown", options: 'customers' },
  ];
  const [tempfilters, setTempFilters] = useState(defaultFilters);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const filterString = () => {
    if (filters.filter(e => e.value).length) {
      let string = "&filtering=";
      filters.filter(e => e.value).map((e) => {
        switch (e.type) {
          case 'radio':
            string += e.field;
            string += "=";
            string += e.value;
            string += "|"

            break;
          case 'hasradio':
            string += e.field;
            e.value == 0 ? string += "=,0" : string += '!=,0';
            string += "|"

            break;
          case 'range':
            if (e.value.min || e.value.max) {
              string += e.field;
              string += "<>";

              string += ((e.value.min != null && e.value.min != '') ? e.value.min : 'null') + ','
              string += ((e.value.max != null && e.value.max != '') ? e.value.max : 'null')
              string += "|"
            }
            break;
          case 'daterange':
            if (e.value.min || e.value.max) {
              string += e.field;
              string += "<>";

              string += ((e.value.min != null && e.value.min != '') ? e.value.min : 'null') + ','
              string += ((e.value.max != null && e.value.max != '') ? e.value.max : 'null')
              string += "|"
            }
            break;
          case 'dropdown':
            string += e.field;
            string += "=";
            string += e.value;
            string += "|"
            break;
          case 'category':
            string += e.field;
            string += "=";
            string += e.value;
            string += "|"
            break;
        }
      });
      return string;
    } else {
      return "";
    }
  }
  const CloseFilterModal = () => {
    setShowFilterModal(false);
  }

  const fetchAll = async () => {
    setLoading(true);
    try {
      const paginationQuery = `page=${pagination.page}&page_size=${pagination.page_size}`;

      // Enhanced order query handling for numeric fields
      let orderQuery = '';
      if (order.orderBy && order.direction) {
        const numericFields = ['order_status', 'payment_status', 'delivery_status'];
        const isNumericField = numericFields.includes(order.orderBy);

        orderQuery = `&orderBy=${order.orderBy}&orderDirection=${order.direction}${isNumericField ? '&numeric=true' : ''}`;
      }

      let searchQuery = '';
      if (searchBar.trim()) {
        const terms = searchBar
          .trim()
          .split(' ')
          .filter(term => term.length > 0);

        if (terms.length > 0) {
          searchQuery = `&searchTerm=${encodeURIComponent(terms.join(' '))}` +
            '&noSearch=["order_id","internal_id","time_of_order",' +
            '"items_sent","items_total","created_at","order_status",' +
            '"payment_status","delivery_status"]';
        }
      }

      const [ordersResponse, consumersResponse] = await Promise.all([
        middleware.get(`sp/ordersTableAll?${paginationQuery}${orderQuery}${searchQuery}` + filterString()),
        middleware.get(`sp/getAllCustomersFromOrders`),
      ]);

      const newData = ordersResponse.data.data;
      setCustomers(consumersResponse.data);
      setData(newData);
      setOrderIds(newData.map(data => data.order_id));
      setPagination(prev => ({
        ...prev,
        max_items: ordersResponse.data.total,
        begin_of_page: ordersResponse.data.from,
        end_of_page: ordersResponse.data.to
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingPage(false)
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAll();
  }, [pagination.page, pagination.page_size, order.orderBy, order.direction, searchBar, filters]);

  const handleSearchChange = useCallback((value) => {
    setSearchBar(value);
    setPagination(prev => ({ ...prev, page: 1 }));
  }, []);

  const handleSelection = useCallback((productId, isSelected) => {
    setSelectedIds(prev =>
      isSelected ? [...prev, productId] : prev.filter(id => id !== productId)
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: (
          <CheckboxHeader
            currentItem={data}
            selectedIds={selectedIds}
            setSelectedIds={() => setSelectedIds(selectedIds.length === orderIds.length ? [] : orderIds)}
            pk={'order_id'}
          />
        ),
        accessor: "select",
        Cell: ({ row }) => (
          <CheckboxCell
            row={row}
            handleSelection={handleSelection}
            selectedIds={selectedIds}
            pk={'order_id'}
          />
        )
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={(newOrder) => {
              setOrder(newOrder);
              setPagination(prev => ({ ...prev, page: 1 }));
            }}
            field="order_number"
          >
            Bestelling
          </SortableHeader>
        ),
        accessor: 'order_number',
        Cell: ({ row }) => {
          const orderNumber = row.original.order_number || "";
          const ownerName = row.original.owner_name || "";
          const internalId = row.original.internal_id || "";
          const date = format(new Date(row.original.time_of_order), 'dd MMMM yyyy HH:mm', { locale: nl });
          return (
            <div className="d-flex flex-column">
              <TDSTextNavigation
                text={orderNumber || '-'}
                extraClassNames={"text-nowrap"}
                link={`/bestellingen/overzicht/${orderNumber}`}
                openNewTab={false}
              />
              {ownerName || "-"}
              <div>
                <label>{date || "-"}</label>
              </div>
            </div>
          );
        }
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={(newOrder) => {
              setOrder(newOrder);
              setPagination(prev => ({ ...prev, page: 1 }));
            }}
            field="total_price_incl_tax"
          >
            Prijs incl.
          </SortableHeader>
        ),
        accessor: 'total_price_incl_tax',
        Cell: ({ row }) => {
          const price = `€ ${Number(row.original.total_price_incl_tax).toFixed(2).replace('.', ',').replace(',00', ',-')}`;
          return <div>{price}</div>;
        }
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={(newOrder) => {
              setOrder(newOrder);
              setPagination(prev => ({ ...prev, page: 1 }));
            }}
            field="sales_channel_name"
            pos={"center"}
          >
            Verkoopkanaal
          </SortableHeader>
        ),
        accessor: 'sales_channel_name',
        Cell: ({ row }) => (
          <div className="d-flex flex-column">
            {row.original.sales_channel_name || "-"}
          </div>
        )
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={(newOrder) => {
              setOrder(newOrder);
              setPagination(prev => ({ ...prev, page: 1 }));
            }}
            field="order_status"
          >
            Status
          </SortableHeader>
        ),
        accessor: 'order_status',
        sortType: 'number',
        Cell: ({ row }) =>
          <div>
            <GetOrderStatus orderStatus={row.original.order_status} />
          </div>
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={(newOrder) => {
              setOrder(newOrder);
              setPagination(prev => ({ ...prev, page: 1 }));
            }}
            field="payment_status"
            pos={"center"}
          >
            Betaling
          </SortableHeader>
        ),
        accessor: 'payment_status',
        sortType: 'number',
        Cell: ({ row }) =>
          <div>
            <GetPaymentStatus paymentStatus={row.original.payment_status} />
          </div>
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={(newOrder) => {
              setOrder(newOrder);
              setPagination(prev => ({ ...prev, page: 1 }));
            }}
            field="delivery_status"
            pos={"center"}
          >
            Verzending
          </SortableHeader>
        ),
        accessor: 'delivery_status',
        sortType: 'number',
        Cell: ({ row }) => (
          <div>
            <GetDeliveryStatus
              deliveryStatus={row.original.delivery_status}
            />
          </div>

        )
      },
    ],
    [order, selectedIds, data, handleSelection]
  );

  const tableInstance = useTable({ columns, data });

  const Action = useCallback((methode) => {
    setSelectedIds(methode === 'select all' ? orderIds : []);
  }, [orderIds]);


  return (
    <PageTemplate navbarTitle={"Order HUB"} pageIsLoading={loadingPage} >
      <div className="orderTableOverview w-100 mx-3">
        <div className="orderTable">
          <TablePreset
            data={[{ 'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'order_id' }]}
            tableLoading={loading}
            setPagination={setPagination}
            handleSelection={handleSelection}
            searchBar={[{ shown: true, searchFunction: handleSearchChange, placeholder: "Zoeken op bestelling, klant, etc." }]}
            actionOptions={[
              { title: "Print verzendlabel(s)", function: () => "" },
              { title: "Print pakbon(nen)", function: () => "" }
            ]}
            leftButtons={[{ title: "Filteren", function: () => setShowFilterModal(true) }]}
            rightButtons={[{ title: "Nieuwe bestelling", function: () => window.open('/bestellingen/nieuw') }]}
          />
        </div>
      </div>
      <Filters filters={tempfilters} props={{ customers: customers.map((e) => { return { name: e.customer_name, value: e.internal_id } }) }} showFilterModal={showFilterModal} closeFilterModal={CloseFilterModal} setTempFilters={setTempFilters} setFilters={setFilters} defaultFilters={defaultFilters} />
    </PageTemplate>
  );
}

export default Orders;