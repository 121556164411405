// React and related hooks
import React, { useMemo, useState, useEffect, Children, useRef } from "react";
import { useNavigate } from "react-router";

// React Table
import { useTable } from "react-table";

// API
import middleware from "../../../Api/Middleware";
import salesChannelsApi from "../../../Api/SalesChannels";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";

// CSS
import "../css/AddProductPages.css";
import "../../../Main/Main.css";
import "../../product.css";
import "../css/UploadArea.css";

// Components
import TablePreset from "../../../Table/TablePreset";
import ToastError from "../../../Toasts/ToastError";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import NavbarIcon from "../../../Navbars/Sidebar/Icons/globe.png";
import PriceBox from "../edit/edit_components/boxPrice";
import MediaUploaderComponent from "../../../MediaUpload/MediaUploaderComponent";
import Categories from "../sub_components/Categories";
import FirstBox from "../edit/edit_components/boxfirst";
import { BorderedSquareNumberInput } from "../../../HouseStyle/Components/Inputs";
import CurrencyInput from "react-currency-input-field";
import PageTemplate from "../../../Templates/PageTemplate";
import Backbutton from "../images/BackIcon.svg";

const AddVariation = (props) => {
  const navigate = useNavigate();

  // console.log(props + "props");

  // Pagination
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  });

  // Loading state
  const [isLoading, setIsLoading] = useState(true);
  const [loadingScreen, setLoadingScreen] = useState(true);

  // Products info
  const [values, setValues] = useState({
    product_name: "",
    product_number: "",
    type: 2,
  });
  const [status, setStatus] = useState(false);
  const [buttonPressed, setbuttonPressed] = useState("");
  const [brandId, setBrandId] = useState("");
  const [taxClass, setTaxClass] = useState(1);
  const [selectedTax, setSelectedTax] = useState("21");
  const [stock, setStock] = useState({});

  // Categories
  const [categories, setCategories] = useState([]);

  // Brands, genders, sales Channels
  const [brands, setBrands] = useState([]);
  const [genders, setGenders] = useState([]);
  const [salesChannels, setSalesChannels] = useState([]);
  const [selectedSalesChannels, setSelectedSalesChannels] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [product, setProduct] = useState([{}]);

  // Variatoons
  const [variations, setVariations] = useState([]);
  const [allVariations, setAllVariations] = useState([]);
  const [selectedVariationIds, setSelectedVariationIds] = useState([]);
  const [colorVariations, setColorVariations] = useState([]);
  const [sizeVariations, setSizeVariations] = useState([]);
  const [allSizes, setAllSizes] = useState([]);
  const [allColors, setAllColors] = useState([]);

  const [addVariation, setAddVariation] = useState({
    size: "",
    color: "",
  });
  const [variationSwitch, setVariationSwitch] = useState(false);
  const [variationPrice, setVariationPrice] = useState({});
  const [finalPrice, setFinalPrice] = useState({});
  const [focusedInput, setFocusedInput] = useState(null);

  const [colorFilter, setColorFilter] = useState("");
  const [sizeFilter, setSizeFilter] = useState("");

  // Images
  const [images, setImages] = useState([]);
  const [updatedMedia, setUpdatedMedia] = useState(false);
  const [requiredCheck, setRequiredCheck] = useState(false);

  // selection
  const [selectedIds, setSelectedIds] = useState([]);
  const [initialCategories, setInitialCategories] = useState([
    { primary_key: 1, id: 1, headId: null, name: "Categories" },
  ]);

  const [initialCategoriesNew, setInitialCategoriesNew] = useState([
    { primary_key: 1, id: 1, headId: null, name: "Categories" },
  ]);

  const organizeCategory = (categories, parentId = null) => {
    return categories
      .filter((category) => category.headId === parentId)
      .map((category) => {
        return {
          ...category,
          subcategories: organizeCategory(categories, category.id),
        };
      });
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryNew, setSearchQueryNew] = useState("");
  const [checkedCategories, setCheckedCategories] = useState({});
  const [categoryTree, setCategoryTree] = useState(
    organizeCategory(initialCategories)
  );
  const [categoryTreeNew, setCategoryTreeNew] = useState(
    organizeCategory(initialCategoriesNew)
  );
  const [filteredCategory, setFilteredCategory] = useState(categoryTree);
  const [filteredCategoryNew, setFilteredCategoryNew] =
    useState(categoryTreeNew);
  const [tax, setTax] = useState("0");
  const [prices, setPrices] = useState([]);
  const [priceMargin, setPriceMargin] = useState();

  const fetchAll = async () => {
    setIsLoading(true);
    const [
      fetchCategories,
      fetchBrands,
      fetchGenders,
      fetchSalesChannels,
      fetchSizes,
      fetchColors,
    ] = await Promise.all([
      middleware.get(`products/category`),
      middleware.get(`products/brands?status=1`),
      middleware.get(`products/genders`),
      salesChannelsApi.get("saleschannels?status=1"),
      middleware.get(`products/sizes`),
      middleware.get(`products/colors`),
    ]);
    const cat = fetchCategories.data.data.map((map) => {
      return {
        primary_key: map.primary_key,
        id: map.category_id,
        headId: map.head_category_id,
        name: map.title,
      };
    });
    setInitialCategories(cat);
    setInitialCategoriesNew(cat);
    setCategories(fetchCategories.data.data);
    setBrands(fetchBrands.data.data);
    setGenders(fetchGenders.data.data);
    setSalesChannels(fetchSalesChannels.data);
    setAllSizes(fetchSizes.data.data);
    setAllColors(fetchColors.data.data);
    setLoadingScreenFunc();
  };
  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () =>
        resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
      reader.onerror = (error) => reject(error);
    });
  const validateImages = async () => {
    //Check if there are any images that are faulty (like too big or unsupported file type)
    let faultyImages = false;
    for (let index = 0; index < images.length; index++) {
      const CurrentImage = images[index];
      if (CurrentImage.error) {
        faultyImages = true;
      }
    }
    let everythingFilledIn = true;
    //Check if every file has a color assigned to it
    for (let index = 0; index < images.length; index++) {
      const CurrentImage = images[index];
      if (CurrentImage.colorId === undefined) {
        everythingFilledIn = false;
      }
    }
    if (
      images.find((e) => {
        return (
          images.filter(
            (j) =>
              (e.colorId != null && j.colorId === e.colorId) ||
              (e.sizeId != null && j.sizeId === e.sizeId)
          ).length > 3
        );
      })
    ) {
      // check if its not id 0
      if (
        images.find(
          images.filter((j) => j.colorId !== 0 || j.sizeId !== 0).length > 3
        )
      ) {
        return "Je mag maar 3 images per variatie toevoegen.";
      }
    }
    //If there are any images that are faulty, a pop-up will appear and will stop the code from running any further
    if (faultyImages) {
      return "Verwijder de foto's die ongeldig zijn voordat je opslaat.";
    }
    // If there are any images that have no color assigned to them, a pop-up will appear and will stop the code from running any further
    else if (!everythingFilledIn) {
      return "Vul alle kleuren/maten in!";
    }
    return true;
  };
  const saveImages = async (productID) => {
    try {
      let currentProductId = productID;
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const currentYear = new Date().getFullYear();
      const folderPath = `warehouse/uploads/${currentYear}/${currentMonth}/${currentProductId}`;

      //The string with all the data that will be send to the API so it saves the image path, order and color
      let bulkDataString = "";
      let imagestrings = [];

      //Loop through the images and preparing them to upload
      for (let index = 0; index < images.length; index++) {
        // if(!images[index].changed) {
        //   continue;
        // }
        const CurrentImage = images[index];
        // This displays the image that is currently being uploaded
        // setUploadImgStatus(
        //   `${CurrentImage.name} (${CurrentImage.file.size.toString().length >= 6
        //     ? (CurrentImage.file.size / 1024 / 1000).toFixed(1) + " MB"
        //     : (CurrentImage.file.size / 1024).toFixed(1) + " kB"
        //   })`
        // );

        const base64String = await blobToBase64(CurrentImage.file);
        imagestrings.push(base64String);

        // Increase the upload count to notify the user the image has been uploaded
        // setUploadCount((prev) => prev + 1);

        //Add the data to the bulkdata that will eventually be sent to the middleware API
        bulkDataString += `${CurrentImage.name}|${
          CurrentImage.colorId
        }|${folderPath}/${CurrentImage.name.replace(/\s/g, "_")}|${index + 1}|${
          CurrentImage.sizeId
        }`;
        if (index < images.length - 1) {
          bulkDataString += ",";
        }
      }
      // Post request to the middleware api to save the image data to the database and delete the older information
      await middleware.post(
        `products/media/upload?product_id=${currentProductId}&bulk_data=${encodeURIComponent(
          bulkDataString
        )}`,
        { imagestrings: imagestrings }
      );

      return true;
    } catch (error) {
      // When something goes wrong the user gets logged out and send to the login page
      return (
        error.response?.data?.Message ?? error.response?.data?.error ?? false
      );
    }
  };

  useEffect(() => {
    setFilteredCategory(categoryTree);
  }, [categoryTree]);

  useEffect(() => {
    setFilteredCategoryNew(categoryTreeNew);
  }, [categoryTreeNew]);

  useEffect(() => {
    filterCategory(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    filterCategoryNew(searchQueryNew);
  }, [searchQueryNew]);

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    filterCategory();
  }, [initialCategories]);

  useEffect(() => {
    filterCategoryNew();
  }, [initialCategoriesNew]);

  const validate = (e) => {
    e.target.checkValidity();
    e.target.reportValidity();
  };

  useEffect(() => {
    const colorsCache = localStorage.getItem("colorsAddVariationCache");
    const sizesCache = localStorage.getItem("sizesAddVariationCache");

    if (!colorsCache || !sizesCache) {
      navigate("/producten");
      return;
    }

    const colors = JSON.parse(colorsCache);
    const sizes = JSON.parse(sizesCache);

    const variations = [];
    if (colors.length === 0 && sizes.length > 0) {
      sizes.forEach((size) => {
        const sizeId = parseInt(size.id, 10);
        const sizeName = size.name ?? null;

        variations.push({
          id: variations.length + 1,
          size_id: sizeId,
          size_name: sizeName,
          sizes:
            size != null
              ? [
                  {
                    size_id: sizeId ?? null,
                    name: sizeName ?? null,
                  },
                ]
              : [],
          barcode: null,
          stock: null,
        });
      });
    } else if (sizes.length === 0 && colors.length > 0) {
      colors.forEach((color) => {
        variations.push({
          id: variations.length + 1,
          color_id: color.color_id,
          color_name: color.name,
          hex: color.hex,
          colors:
            color != null
              ? [
                  {
                    color_id: color?.color_id ?? null,
                    name: color?.name ?? null,
                    hex: color?.hex ?? null,
                  },
                ]
              : [],
          barcode: null,
          stock: null,
        });
      });
    } else {
      colors.forEach((color) => {
        sizes.forEach((size) => {
          const sizeId = parseInt(size.id, 10);
          const sizeName = size.name ?? null;
          variations.push({
            id: variations.length + 1,
            color_id: color.color_id,
            color_name: color.name,
            hex: color.hex,
            colors:
              color != null
                ? [
                    {
                      color_id: color?.color_id ?? null,
                      name: color?.name ?? null,
                      hex: color?.hex ?? null,
                    },
                  ]
                : [],
            size_id: sizeId,
            size_name: sizeName,
            sizes:
              size != null
                ? [
                    {
                      size_id: sizeId ?? null,
                      name: sizeName ?? null,
                    },
                  ]
                : [],
            barcode: null,
            stock: null,
          });
        });
      });
    }

    setColorVariations(colors);
    setSizeVariations(sizes);
    setAllVariations(variations);
    generateVariations(variations);
  }, [navigate]);

  useEffect(() => {
    if (allVariations != null) {
      generateVariations();
    }
  }, [pagination.page_size, pagination.page, allVariations]);

  useEffect(() => {
    let filtered = allVariations ?? [];

    if (colorFilter) {
      filtered = filtered.filter(
        (v) => v.color_id === parseInt(colorFilter, 10)
      );
    }

    if (sizeFilter) {
      filtered = filtered.filter((v) => v.size_id === parseInt(sizeFilter, 10));
    }

    const totalVariations = filtered.length;
    const { page_size, page } = pagination;
    const maxPage = Math.ceil(totalVariations / page_size);
    const newPage = Math.max(1, page > maxPage ? maxPage : page);

    const begin_of_page = (newPage - 1) * page_size;
    const end_of_page = Math.min(begin_of_page + page_size, totalVariations);
    const paginated = filtered.slice(begin_of_page, end_of_page);

    setPagination((prev) => ({
      ...prev,
      page: newPage,
      max_items: totalVariations,
      begin_of_page: totalVariations === 0 ? 0 : begin_of_page + 1,
      end_of_page: end_of_page,
    }));
    setVariations(paginated);
  }, [
    allVariations,
    colorFilter,
    sizeFilter,
    pagination.page_size,
    pagination.page,
  ]);

  const generateVariations = (variations = null) => {
    variations = variations ? variations : allVariations;
    const totalVariations = variations.length;
    const { page_size, page } = pagination;
    const maxPage = Math.ceil(totalVariations / page_size);
    const newPage = Math.max(1, page > maxPage ? maxPage : page);

    const begin_of_page = (page - 1) * page_size;
    const end_of_page = Math.min(begin_of_page + page_size, totalVariations);

    const paginatedVariations = variations.slice(begin_of_page, end_of_page);

    setVariations(paginatedVariations);
  };

  const filterCategoryRecursively = (query, categories) => {
    const lowerCaseQuery = query.toLowerCase();

    return categories
      .map((category) => {
        let categoryMatches = category.name
          .toLowerCase()
          .includes(lowerCaseQuery);
        let filteredSubcategory = [];
        let includedDueToChild = false;

        if (category.subcategory) {
          filteredSubcategory = filterCategoryRecursively(
            query,
            category.subcategory
          );
          includedDueToChild = filteredSubcategory.length > 0;
        }

        if (categoryMatches || includedDueToChild) {
          return {
            ...category,
            subcategory: filteredSubcategory,
            isIncludedDueToChild: !categoryMatches && includedDueToChild,
          };
        }

        return null;
      })
      .filter(Boolean);
  };

  const filterCategoryRecursivelyNew = (query, categories) => {
    const lowerCaseQuery = query.toLowerCase();

    return categories
      .map((category) => {
        let categoryMatches = category.name
          .toLowerCase()
          .includes(lowerCaseQuery);
        let filteredSubcategory = [];
        let includedDueToChild = false;

        if (category.subcategory) {
          filteredSubcategory = filterCategoryRecursivelyNew(
            query,
            category.subcategory
          );
          includedDueToChild = filteredSubcategory.length > 0;
        }

        if (categoryMatches || includedDueToChild) {
          return {
            ...category,
            subcategory: filteredSubcategory,
            isIncludedDueToChild: !categoryMatches && includedDueToChild,
          };
        }

        return null;
      })
      .filter(Boolean);
  };

  const Category = ({ category }) => {
    if (
      Object.keys(checkedCategories).includes(
        `1-${category.primary_key}-${category.id}-${category.headId ?? 0}`
      )
    ) {
      return (
        <div style={{ marginLeft: category.headId ? "20px" : "0px" }}>
          <div className="d-flex flex-row align-items-center ms-2">
            {category.headId != undefined && (
              <div className="lineCategories me-2"></div>
            )}
            <p className="mb-0 fw-medium"> {category.name}</p>
          </div>
          {category.subcategories && category.subcategories.length > 0 && (
            <div>
              {category.subcategories.map((sub) => (
                <Category key={sub.id} category={sub} />
              ))}
            </div>
          )}
        </div>
      );
    }
  };

  // A function to filter products.
  const filterCategory = (query) => {
    if (!query) {
      setCategoryTree(organizeCategory(initialCategories));
      return;
    }

    setCategoryTree(filterCategoryRecursively(query, initialCategories));
  };

  const filterCategoryNew = (query) => {
    if (!query) {
      setCategoryTreeNew(organizeCategory(initialCategories));
      return;
    }

    setCategoryTreeNew(
      filterCategoryRecursivelyNew(query, initialCategoriesNew)
    );
  };

  const verwerkCategorieIds = (obj) => {
    const regex = /^(\d+)-(\d+)-(\d+)-(\d+)$/;
    const categoryIds = [];
    const HeadIds = [];

    Object.entries(obj)
      .filter(([key, value]) => value === true && regex.test(key))
      .forEach(([key]) => {
        const match = key.match(regex);
        if (match) {
          categoryIds.push(match[3]); // Voeg het derde deel toe aan match3
          HeadIds.push(match[4]); // Voeg het vierde deel toe aan match4
        }
      });

    return {
      categoryIds,
      HeadIds,
    };
  };

  const transformData = (data) => {
    let headCategoryMap = {};

    data.HeadIds.forEach((headId) => {
      const headCategory = categories.find(
        (cat) => cat.category_id === parseInt(headId)
      );
      if (headCategory) {
        headCategoryMap[headCategory.title] = data.categoryIds
          .map((catId) => {
            const category = categories.find(
              (cat) => cat.category_id === parseInt(catId)
            );
            return category &&
              category.head_category_id === headCategory.category_id
              ? category.title
              : null;
          })
          .filter((title) => title !== null);
      }
    });

    return headCategoryMap;
  };

  const transformedData = transformData(verwerkCategorieIds(checkedCategories));

  function setLoadingScreenFunc() {
    setIsLoading(false);
    setTimeout(() => {
      setLoadingScreen(false);
    }, 500);
  }

  const handleProductSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedVariationIds([...selectedVariationIds, productId]);
    } else {
      setSelectedVariationIds(
        selectedVariationIds.filter((id) => id !== productId)
      );
    }
  };

  const handleSelection = (id, isSelected) => {
    if (isSelected) {
      setSelectedIds((previous) => [...previous, id]);
    } else {
      setSelectedIds((previous) => previous.filter((id) => id !== id));
    }
  };

  const generateBarcode = async (event, id) => {
    if (event != null) {
      event.preventDefault();
    }
    const response = await middleware.get("products/generateBarcode");
    const newBarcode = response.data.generatedBarcode;

    handleBarcodeChange(newBarcode, id);

    // console.log(newBarcode, id);
    if (allVariations?.length) {
      const totalVariations = allVariations.length;
      const { page_size, page } = pagination;
      const maxPage = Math.ceil(totalVariations / page_size);
      const newPage = Math.max(1, page > maxPage ? maxPage : page);

      const begin_of_page = (page - 1) * page_size;
      const end_of_page = Math.min(begin_of_page + page_size, totalVariations);

      const paginatedVariations = allVariations.slice(
        begin_of_page,
        end_of_page
      );
      setPagination({
        page_size: page_size,
        max_items: totalVariations,
        page: newPage,
        begin_of_page: totalVariations === 0 ? 0 : begin_of_page + 1,
        end_of_page: end_of_page,
      });
      setVariations(paginatedVariations);
    }
  };

  const generateMultipleBarcodes = async (amount) => {
    const response = await middleware.get(
      `products/generateMultipleBarcodes?barcodeAmount=${amount}`
    );
    const newBarcodes = response.data.generatedBarcode;
    return newBarcodes;
  };

  // console.log("allVariations" + JSON.stringify(allVariations));

  const onPriceButtonClicked = (event, variationId) => {
    const pricesArray = prices ?? [];
    const invalidValues = [null, 0, "", " ", "0", "0.00"];
    const isValid = (val) => !invalidValues.includes(val);

    let basePrice = 0;
    const salePrice = pricesArray.find(
      (item) => item.type === "sale_price"
    )?.incl_tax;
    const regularPrice = pricesArray.find(
      (item) => item.type === "price"
    )?.incl_tax;
    if (isValid(salePrice)) {
      basePrice = Number(salePrice);
    } else if (isValid(regularPrice)) {
      basePrice = Number(regularPrice);
    }

    const productVariation = allVariations.find((v) => v.id === variationId);
    if (!productVariation) {
      console.error(`Geen variation gevonden voor variationId: ${variationId}`);
      return;
    }

    const operator = event.target.innerHTML.trim(); // "+" of "-"
    setAllVariations((prev) =>
      prev.map((v) =>
        v.id === variationId ? { ...v, priceOperator: operator } : v
      )
    );

    const newFinalPrice = calculateFinalPrice({
      ...productVariation,
      priceOperator: operator,
    });
    setFinalPrice((prev) => ({ ...prev, [variationId]: newFinalPrice }));
  };

  const calculateFinalPrice = (variation) => {
    const pricesArray = prices ?? [];
    const invalidValues = [null, 0, "", " ", "0", "0.00"];
    const isValid = (val) => !invalidValues.includes(val);

    let basePrice = 0;
    const salePrice = pricesArray.find(
      (item) => item.type === "sale_price"
    )?.incl_tax;
    const regularPrice = pricesArray.find(
      (item) => item.type === "price"
    )?.incl_tax;

    if (isValid(salePrice)) {
      basePrice = Number(salePrice);
    } else if (isValid(regularPrice)) {
      basePrice = Number(regularPrice);
    }

    // Extra info that has been added to the variation
    const extra = Number(variationPrice[variation.id]) || 0;
    const operator = variation.priceOperator || "+";

    if (operator === "+") {
      return basePrice + extra;
    } else if (operator === "-") {
      let result = basePrice - extra;
      if (result < 0) {
        ToastError("Prijs werd automatisch aangepast omdat hij negatief werd.");
        return 0;
      }
      return result;
    }
    return basePrice;
  };

  const PriceComp = ({ finalPrice }) => {
    // console log the total price of every product
    if (finalPrice != null && !isNaN(finalPrice) && finalPrice > 0) {
      return (
        <span className="align-self-center">= € {finalPrice.toFixed(2)}</span>
      );
    } else if (finalPrice === 0) {
      return <span className="align-self-center">= € 0,00</span>;
    } else {
      return <span className="align-self-center">= € -</span>;
    }
  };

  // Destructure table properties

  const submitNew = async (event) => {
    event.preventDefault(); // Prevent the default form action
    console.log(event.target);
    let newProductId = null;
    const newprices = prices.reduce((acc, curr) => {
      acc[curr.type] = {
        price_id: curr.price_id,
        incl_tax:
          typeof curr.incl_tax === "string"
            ? Number(curr.incl_tax.replace(",", ".")).toFixed(2)
            : Number(curr.incl_tax).toFixed(2),
        excl_tax:
          typeof curr.excl_tax === "string"
            ? Number(curr.excl_tax.replace(",", ".")).toFixed(2)
            : Number(curr.excl_tax).toFixed(2),
      };
      return acc;
    }, {});

    try {
      // Check if the product number is unique
      const existingProduct = (
        await middleware.get(
          `products?product_number=${encodeURIComponent(event.target.value)}`
        )
      ).data;
      if (existingProduct.length > 0) {
        ToastError("Productnummer bestaat al.");
        return;
      }

      let newvariations = allVariations.map((variation) => {
        return {
          ...(variation.barcode && { barcode: variation.barcode }),
          ...(variation.color_id && { color: variation.color_id }),
          ...(variation.stock && { stock: variation.stock }),
          ...(variation.size_id && { size: variation.size_id }),

          variation_price: variation.priceOperator === "+" ? variation.price ?? 0 : -variation.price ?? null,
            
      

        };
      });

      const validate = await validateImages();
      if (validate !== true) {
        ToastError(validate);
        return false;
      }

      const productName = values.product_name;
      if (productName == null || productName == "") {
        ToastError("Product naam is verplicht");
        return false;
      }

      // Create the product
      const productResponse = await middleware.post(
        "products/create/variation",
        {
          product: {
            name: event.target.product_name?.value ?? values.product_name,
            number: event.target.product_number?.value ?? values.product_number,
            description: values.product_description,
            tax_id: taxClass,
            status: status,
            variation_type: variationSwitch,
          },
          prices: newprices,
          stock: stock,
          variations: newvariations,
          category_ids: Object.keys(checkedCategories).map(
            (e) => e.split("-")[2]
          ),
          gender_ids: selectedGenders,
          brand_ids: brandId ?? null,
          sales_channel_ids: selectedSalesChannels,
        }
      );
      newProductId = productResponse.data.headProduct.product_id;
      await saveImages(newProductId);

      ToastSuccess("Product is aangemaakt");
      if (buttonPressed == 1) {
        window.location.reload();
      } else if (buttonPressed == 2) {
        navigate("/producten");
      }
    } catch (err) {
      console.error(err);
      console.error("Fout bij product aanmaken:", err);
      console.error("Respons data:", err.response?.data); // Extra info over API-fout
      console.error("Status code:", err.response?.status); // HTTP statuscode
      console.error("Foutmelding:", err.message); // Algemene foutmelding
      ToastError("Er is een fout opgetreden bij het aanmaken van het product.");
    }
  };

  const generate_barcodes = async () => {
    let count = 0;
    const generated_barcodes = await generateMultipleBarcodes(
      selectedVariationIds.length
    );
    // generate barcodes on selected fields without a barcode
    selectedVariationIds.forEach((selectedId) => {
      let vari = allVariations.find((variation) => variation.id == selectedId);
      if (vari.barcode == null || vari.barcode == "") {
        handleBarcodeChange(generated_barcodes[count], vari.id);
      }
      count++;
    });
  };

  const delete_barcodes = () => {
    // delete selected barcodes
    selectedVariationIds.forEach((selectedId) => {
      let vari = allVariations.find((variation) => variation.id == selectedId);
      if (vari.barcode != null || vari.barcode != "") {
        setAllVariations((prevVariations) =>
          prevVariations.map((variation) =>
            variation.id === vari.id
              ? { ...variation, barcode: null }
              : variation
          )
        );
        // way too much code just for a rerender :(
        if (allVariations?.length) {
          const totalVariations = allVariations.length;
          const { page_size, page } = pagination;
          const maxPage = Math.ceil(totalVariations / page_size);
          const newPage = Math.max(1, page > maxPage ? maxPage : page);

          const begin_of_page = (page - 1) * page_size;
          const end_of_page = Math.min(
            begin_of_page + page_size,
            totalVariations
          );
          setPagination({
            page_size: page_size,
            max_items: totalVariations,
            page: newPage,
            begin_of_page: totalVariations === 0 ? 0 : begin_of_page + 1,
            end_of_page: end_of_page,
          });
        }
      }
    });
  };

  const delete_variations = () => {
    setAllVariations((previous) =>
      previous.filter(
        (variation) => !selectedVariationIds.includes(variation.id)
      )
    );
    setSelectedVariationIds([]);
  };

  const handleAction = async (event) => {
    if (event === "generate-barcodes") {
      generate_barcodes();
    } else if (event === "delete-barcodes") {
      delete_barcodes();
    } else if (event === "delete") {
      delete_variations();
    }
  };

  const handleNewVariation = () => {
    const size = allSizes.filter(
      (size) => size.primary_key === Number(addVariation.size)
    )[0];
    const color = allColors.filter(
      (color) => color.primary_key === Number(addVariation.color)
    )[0];

    if (!size && !color) {
      ToastError("Je moet een maat of kleur selecteren");
      return;
    }
    if (
      allVariations.find(
        (e) => e.color_id == color?.color_id && e.size_id == size?.size_id
      )
    ) {
      ToastError("Die variatie bestaat al");
      return;
    }

    const newVariation = {
      id: allVariations.length + 1,
      size_id: size?.size_id ?? null,
      size_name: size?.main ?? null,
      color_id: color?.color_id ?? null,
      color_name: color?.name ?? null,
      hex: color?.hex ?? null,
      colors:
        color != null
          ? [
              {
                color_id: color?.color_id ?? null,
                name: color?.name ?? null,
                hex: color?.hex ?? null,
              },
            ]
          : [],
      sizes:
        size != null
          ? [
              {
                size_id: size?.size_id ?? null,
                name: size?.main ?? null,
              },
            ]
          : [],
    };
    const sizeVariation = {
      id: size?.size_id ?? null,
      name: size?.main ?? null,
    };
    const colorVariation = {
      id: color?.color_id ?? null,
      name: color?.name ?? null,
    };
    if (sizeVariation.id) {
      setSizeVariations([sizeVariation, ...sizeVariations]);
    }
    if (colorVariation.id) {
      setColorVariations([colorVariation, ...colorVariations]);
    }
    setAllVariations([newVariation, ...allVariations]);
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleGendersChange = (event) => {
    const valueAsInt = parseInt(event.target.value, 10);

    if (event.target.checked) {
      setSelectedGenders([...selectedGenders, valueAsInt]);
    } else {
      setSelectedGenders(selectedGenders.filter((e) => e !== valueAsInt));
    }
  };

  const handleSalesChannelsChange = (event) => {
    const valueAsInt = parseInt(event.target.value, 10);

    if (event.target.checked) {
      setSelectedSalesChannels([...selectedSalesChannels, null]);
    } else {
      setSelectedSalesChannels(
        // selectedSalesChannels.filter((e) => e !== valueAsInt)
      );
    }
  };

  const handleBarcodeChange = (newBarcode, id) => {
    setAllVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.id === id ? { ...variation, barcode: newBarcode } : variation
      )
    );

    if (allVariations?.length) {
      const totalVariations = allVariations.length;
      const { page_size, page } = pagination;
      const maxPage = Math.ceil(totalVariations / page_size);
      const newPage = Math.max(1, page > maxPage ? maxPage : page);

      const begin_of_page = (page - 1) * page_size;
      const end_of_page = Math.min(begin_of_page + page_size, totalVariations);

      const paginatedVariations = allVariations.slice(
        begin_of_page,
        end_of_page
      );
      setPagination({
        page_size: page_size,
        max_items: totalVariations,
        page: newPage,
        begin_of_page: totalVariations === 0 ? 0 : begin_of_page + 1,
        end_of_page: end_of_page,
      });
      setVariations(paginatedVariations);
    }
  };

  const handleStockChange = (newStock, variationId) => {
    setAllVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.id === variationId
          ? { ...variation, stock: newStock }
          : variation
      )
    );
  };

  const handleVariationPriceChange = (value, variationId) => {
    let price = String(value);
    if (!price || price === "NaN" || price === "null") {
      price = "0";
    }
    if (price.includes("€")) {
      price = price.replace("€", "");
    }
    price = price.replace(",", ".");
    price = isNaN(Number(price)) ? 0 : Number(price);

    setVariationPrice((prev) => ({ ...prev, [variationId]: price }));

    const productVariation = allVariations.find((v) => v.id === variationId);
    if (!productVariation) {
      console.error("No variation found for variationId:", variationId);
      return;
    }

    setAllVariations((prevVariations) =>
      prevVariations.map((variation) =>
      variation.id === variationId ? { ...variation, price: price } : variation
      )
    );

    const newFinalPrice = calculateFinalPrice(productVariation);
    setFinalPrice((prev) => ({ ...prev, [variationId]: newFinalPrice }));
  };
  const columns = useMemo(() => {
    const invalidValues = [null, 0, "", " ", "0", "0.00"];
    const isValid = (val) => !invalidValues.includes(val);

    // Basis-kolommen
    const cols = [
      {
        Header: (
          <label className="checkbox-container">
            <input
              type="checkbox"
              onChange={(e) => {
                const checked = e.target.checked;
                setSelectedVariationIds(
                  checked ? variations.map((v) => v.id) : []
                );
              }}
              checked={selectedVariationIds.length > 0}
            />
            <span className="checkmark"></span>
          </label>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          const isSelected = selectedVariationIds.includes(row.original.id);
          return (
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={isSelected}
                onChange={(e) =>
                  handleProductSelection(row.original.id, e.target.checked)
                }
              />
              <span className="checkmark"></span>
            </label>
          );
        },
      },

      {
        Header: "Voorraad",
        accessor: "stock",
        Cell: ({ row }) => {
          const variationId = row.original.id;
          const variationData = allVariations.find((v) => v.id === variationId);
          const stockValue = variationData?.stock || "";
          const [localStock, setLocalStock] = useState(stockValue);

          const updateStock = () => {
            handleStockChange(localStock, variationId);
          };

          return (
            <div
              className="d-flex justify-content-center"
              style={{ width: "60px" }}
            >
              <BorderedSquareNumberInput
                size="l"
                inputValue={localStock}
                setInputValue={setLocalStock}
                updateFunction={updateStock}
              />
            </div>
          );
        },
      },
      // Barcode
      {
        Header: "Barcode",
        accessor: "barcode",
        Cell: ({ row }) => {
          const variationId = row.original.id;
          const variationData = allVariations.find((v) => v.id === variationId);
          const barcodeValue = variationData?.barcode || "";
          const [localBarcode, setLocalBarcode] = useState(barcodeValue);

          const updateBarcode = () => {
            handleBarcodeChange(localBarcode, variationId);
          };

          return (
            <div className="d-flex flex-row gap-2">
              <div>
                <BorderedSquareNumberInput
                  size="s"
                  inputValue={localBarcode}
                  setInputValue={setLocalBarcode}
                  updateFunction={updateBarcode}
                  onWheel={(e) => e.target.blur()}
                />
              </div>
              <div
                className="d-flex justify-content-center align-items-center generateBarcodeBtn"
                onClick={(e) => generateBarcode(e, variationId)}
              >
                <FontAwesomeIcon icon={faBarcode} />
              </div>
            </div>
          );
        },
      },

      {
        Header: (
          <div className="d-flex justify-content-center">Variabele prijs</div>
        ),
        accessor: "incl_tax",
        Cell: ({ row }) => {
          const variationId = row.original.id;

          const pricesArray = prices ?? [];
          const salePrice = pricesArray.find(
            (item) => item.type === "sale_price"
          )?.incl_tax;
          const regularPrice = pricesArray.find(
            (item) => item.type === "price"
          )?.incl_tax;

          let basePrice = 0;
          if (isValid(salePrice)) {
            basePrice = Number(salePrice);
          } else if (isValid(regularPrice)) {
            basePrice = Number(regularPrice);
          }

          const variationData = allVariations.find((v) => v.id === variationId);
          const operator = variationData?.priceOperator || null;

          return (
            <div className="d-flex justify-content-center gap-1">
              <div className="d-flex justify-content-center flex-row">
                <div
                  className={
                    operator === "+"
                      ? "VariationPriceBtnPlusSelected"
                      : "VariationPriceBtnPlus"
                  }
                  onClick={(e) => onPriceButtonClicked(e, variationId)}
                >
                  +
                </div>
                <div
                  className={
                    operator === "-"
                      ? "VariationPriceBtnMinusSelected"
                      : "VariationPriceBtnMinus"
                  }
                  onClick={(e) => onPriceButtonClicked(e, variationId)}
                >
                  -
                </div>
              </div>
              <CurrencyInput
                placeholder="€ 0,00"
                className="stockBox variablePriceBox px-2"
                decimalsLimit={2}
                groupSeparator="."
                decimalSeparator=","
                prefix="€ "
                min={0}
                name={`${variationId}`}
                defaultValue={variationPrice[variationId] ?? ""}
                onFocus={() => {
                  if (variationId !== focusedInput) {
                    setFocusedInput(variationId);
                  }
                }}
                onBlur={(e) => {
                  handleVariationPriceChange(e.target.value, variationId);

                  // Check of focus naar een ander input gaat
                  if (
                    e.relatedTarget &&
                    e.relatedTarget.tagName === "INPUT" &&
                    e.relatedTarget !== e.target
                  ) {
                    setTimeout(() => {
                      setFocusedInput(e.relatedTarget.name);
                    }, 500);
                    return;
                  }
                  setFocusedInput(null);
                }}
                ref={(input) => {
                  if (input && focusedInput === variationId) {
                    input.focus();
                  }
                }}
              />
            </div>
          );
        },
      },

      {
        accessor: "finalPrice",
        Cell: ({ row }) => {
          const variationId = row.original.id;
          return (
            <div>
              <PriceComp finalPrice={finalPrice[row.original.id]} />
            </div>
          );
        },
      },
    ];

    if (colorVariations.length > 0) {
      cols.splice(1, 0, {
        Header: (
          <select
            className="smallStandardInput fw-bold standardDropdownArrow w-100"
            value={colorFilter}
            onChange={(e) => setColorFilter(e.target.value)}
          >
            <option value="">Filter kleur</option>
            {allColors.map((color) => (
              <option key={color.primary_key} value={color.color_id}>
                {color.name}
              </option>
            ))}
          </select>
        ),
        accessor: "color_name",
        Cell: ({ row }) => {
          return row.original.color_name ? (
            <span className="d-flex flex-row">
              <div
                className="rounded-circle me-2 mt-1 colorCircle"
                style={{
                  backgroundColor: row.original.hex,
                  width: "15px",
                  height: "15px",
                }}
              />
              <div className="text-nowrap colorNameVariationTable">
                {row.original.color_name}
              </div>
            </span>
          ) : (
            ""
          );
        },
      });
    }

    if (sizeVariations.length > 0) {
      const insertIndex = colorVariations.length > 0 ? 2 : 1;
      cols.splice(insertIndex, 0, {
        Header: (
          <select
            className="smallStandardInput fw-bold standardDropdownArrow w-100"
            value={sizeFilter}
            onChange={(e) => setSizeFilter(e.target.value)}
          >
            <option value="">Filter maat</option>
            {allSizes.map((size) => (
              <option key={size.primary_key} value={size.size_id}>
                {size.main}
              </option>
            ))}
          </select>
        ),
        accessor: "size_name",
        Cell: ({ row }) => {
          return (
            <span className="d-flex justify-content-center">
              {row.original.size_name}
            </span>
          );
        },
      });
    }

    return cols;
  }, [
    selectedVariationIds,
    pagination,
    allVariations,
    colorVariations,
    sizeVariations,
    finalPrice,
    variationPrice,
    focusedInput,
  ]);

  const tableInstance = useTable({ columns, data: variations });

  return (
    <PageTemplate
      navbarTitle={"Warehouse HUB"}
      pageIsLoading={isLoading}
      iconNavbar={NavbarIcon}
    >
      <form
        onSubmit={submitNew}
        method="post"
        className="d-flex justify-content-center minFitContent w-100"
      >
        <div className="mb-3 d-flex flex-column formItems  minFitContent w-100">
          <div className="d-flex justify-content-between mb-4 w-100 flex-wrap-reverse">
            <div className="d-flex gap-5">
              <div className="d-flex flex-row gap-2 align-items-center">
                <img
                  className="back-button-img"
                  src={Backbutton}
                  onClick={() => navigate("/producten")}
                />
                <h5 className="p-0 m-0">Terug</h5>
              </div>
              {/* Published btn*/}
              <div className="d-flex flex-column align-items-center">
                {!status ? (
                  <p className="mb-1 p-0">Gepubliceerd</p>
                ) : (
                  <p className="mb-1 p-0">Gearchiveerd</p>
                )}
                <label className="switchActiveProduct">
                  <input
                    type="checkbox"
                    checked={!status}
                    onChange={(e) => setStatus(!e.target.checked)}
                  />
                  <span className="sliderActiveProduct round"></span>
                </label>
              </div>
            </div>
            <div className=" fitContent text-nowrap">
              <button
                type="submit"
                id="1"
                className="btn darkBlueStandardButton Nunito me-3 fitContent px-4"
                onClick={(e) => setbuttonPressed(e.target.id)}
              >
                Aanmaken en nieuwe toevoegen
              </button>
              <button
                type="submit"
                id="2"
                className="btn lightBlueStandardButton Nunito"
                onClick={(e) => setbuttonPressed(e.target.id)}
              >
                Aanmaken
              </button>
            </div>
          </div>
          <div className="d-flex flex-column flex-xxl-row justify-content-between">
            <div className="col-xxl-8">
              <div>
                <div className="d-flex flex-column fitcontentHeight mb-4">
                  <FirstBox
                    handleChange={handleChange}
                    product={values}
                    setProduct={setValues}
                    brands={brands}
                    validate={validate}
                    setBrandId={setBrandId}
                    edit={false}
                  />
                </div>
              </div>
              <div className="whiteBox p-4 formVariationProduct mb-4">
                <h5 className="fw-semibold">Huidige variatie</h5>
                <div className="widthColorTable">
                  {!isLoading ? (
                    <TablePreset
                      data={[
                        {
                          selectedIds: selectedIds,
                          tableInstance: tableInstance,
                          pagination: pagination,
                        },
                      ]}
                      tableLoading={isLoading}
                      setPagination={(e) => setPagination(e)}
                      handleSelection={handleSelection}
                      actionOptions={[
                        {
                          title: "Genereer barcodes",
                          function: () => handleAction("generate-barcodes"),
                        },
                        {
                          title: "Verwijder barcodes",
                          function: () => handleAction("delete-barcodes"),
                        },
                        {
                          title: "Verwijder",
                          function: () => handleAction("delete"),
                        },
                      ]}
                      rightDropdowns={[
                        {
                          placeholder: "Selecteer een maat",
                          options: allSizes.map((size) => {
                            return {
                              title: size.main,
                              value: size.primary_key,
                              onClick: () =>
                                setAddVariation((prev) => ({
                                  ...prev,
                                  size: size.primary_key,
                                })),
                            };
                          }),
                          selectedValue: addVariation.size,
                        },
                        {
                          placeholder: "Selecteer een kleur",
                          options: allColors.map((color) => {
                            return {
                              title: color.name,
                              value: color.primary_key,
                              onClick: () =>
                                setAddVariation((prev) => ({
                                  ...prev,
                                  color: color.primary_key,
                                })),
                            };
                          }),
                          function: (event) =>
                            setAddVariation({
                              ...addVariation,
                              color: event.target.value,
                            }),
                          selectedValue: addVariation.color,
                        },
                      ]}
                      rightButtons={[
                        {
                          title: "Handmatig toevoegen",
                          function: handleNewVariation,
                        },
                      ]}
                    />
                  ) : (
                    <div className="table-container table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <td>
                              <div>Loading</div>
                            </td>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  )}
                </div>
              </div>
              <div className="whiteBox my-4">
                <MediaUploaderComponent
                  edit={true}
                  updatedMedia={true}
                  setUpdatedMedia={setUpdatedMedia}
                  setImages={setImages}
                  images={images}
                  useColors={true}
                  availableVariations={allVariations}
                  setCheck={setRequiredCheck}
                  product={product}
                  variationSwitch={variationSwitch}
                  setVariationSwitch={setVariationSwitch}
                ></MediaUploaderComponent>
              </div>
            </div>
            <div className="d-flex flex-row flex-xxl-column col-xxl-4 row mt-4 mt-xxl-0">
              {/* category */}
              <div className="col-6 col-xxl-12 mb-4">
                <div className="whiteBox h-100">
                  {categories != null && categories.length ? (
                    <Categories
                      initialCategories={initialCategories}
                      setCheckedCategories={setCheckedCategories}
                      checkedCategories={checkedCategories}
                    />
                  ) : (
                    <div className="whiteBox boxCategories">
                      <div className="d-flex flex-column">
                        <div className=" widthBoxes w-100 p-3 categories">
                          <div className="d-flex justify-content-between flex-column">
                            <h5 className="inputTitle mb-3">Categorieën</h5>
                            <p>Er zijn geen categorieën gevonden</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-6 col-xxl-12 mb-4">
                <div className="whiteBox p-5 pb-2 pt-4 h-100">
                  <h5 className="inputTitle mb-4">Geslacht</h5>
                  <div>
                    {genders.map((gender, index) => (
                      <div key={index} className="d-flex flex-row mb-3">
                        <label className="checkbox-containerSimple">
                          <input
                            type="checkbox"
                            value={gender.gender_id}
                            onChange={handleGendersChange}
                          />
                          <span className="checkmarkSimple"></span>
                        </label>
                        <h5 className="inputTitle fw-medium">{gender.name}</h5>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-6 col-xxl-12 pb-4">
                <PriceBox
                  selectedTax={selectedTax}
                  setSelectedTax={setSelectedTax}
                  setTax={setTax}
                  taxClass={taxClass}
                  setTaxClass={setTaxClass}
                  handleChange={handleChange}
                  prices={prices}
                  setPrices={setPrices}
                  priceMargin={priceMargin}
                  setPriceMargin={setPriceMargin}
                  validate={validate}
                  edit={false}
                />
              </div>

              <div className="mb-4 col-6 col-xxl-12">
                <div className="whiteBox p-4 h-100">
                  <div className="d-flex flex-column h-100">
                    <h5 className="inputTitle mb-4">Verkoopkanaal</h5>
                    <div className="d-flex h-100 flex-column">
                      {salesChannels.length > 0 ? (
                        salesChannels.map((salesChannel, index) => (
                          <div
                            key={index}
                            className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between"
                          >
                            <h6 className="switchText">
                              {salesChannel.sales_channel_name}
                            </h6>
                            <label className="defaultSwitch ms-4">
                              <input
                                type="checkbox"
                                value={salesChannel.sales_channel_id}
                                onChange={handleSalesChannelsChange}
                              />
                              <span className="sliderActiveProduct round"></span>
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>Er zijn nog geen verkoopkanalen gemaakt.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Suppliers */}
              {/* <div className="pb-4 col-6 col-xxl-12">
                <FourthBox
                  suppliers={suppliers}
                  setSuppliers={setSuppliers}
                  businesses={businesses}
                  setBusinesses={setBusinesses}
                  validate={validate}
                  attributeConnections={attributeConnections}
                  setAttributeConnections={setAttributeConnections}
                  edit={false}
                />
              </div> */}
            </div>
          </div>
        </div>
      </form>
    </PageTemplate>
  );
};
export default AddVariation;
