import React, { useEffect, useState, useMemo } from "react";
import PageTemplate from "../../Templates/PageTemplate";
import { Tab, Tabs, Toast } from "react-bootstrap";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router";
import TablePreset from "../../Table/TablePreset";
import { useTable } from "react-table";
import { BackButton, ButtonBlue } from "../../HouseStyle/Components/Buttons";
import Backbutton from "../Icons/BackIcon.svg";
import { BorderedTextInput } from '../../HouseStyle/Components/Inputs';
import middleware from "../../Api/Middleware";
import SystemInfo from "./Tabs/SytemInfo";
import EmailSettings from "./Tabs/EmailSettings";
import * as Icons from "../../HouseStyle/Icons";
import ToastError from "../../Toasts/ToastError";
import ToastSuccess from "../../Toasts/ToastSuccess";

const TechnicalSettingsEdit = () => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const [loading, setLoading] = useState(true);
    // Navigation setup
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [key, setKey] = useState(() => {
        return cookies.get('activeTabKey') ?? "tab1";
    });

    const [backoffice, setBackoffice] = useState({});
    const [companyName, setCompanyName] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [originalCompanyEmail, setOriginalCompanyEmail] = useState('')
    const [status, setStatus] = useState(false);

    const fetchSettings = async () => {
        try {
            const backofficeSettings = await middleware.get("/settings/backoffice?setting_id=1");
            const fetchedData = backofficeSettings.data.data;
            setBackoffice(fetchedData);
            setCompanyEmail(fetchedData.email);
            setOriginalCompanyEmail(fetchedData.email);
            setCompanyName(fetchedData.company_name);
            setStatus(fetchedData.public);
        } catch (err) {
            ToastError("Er is een fout opgetreden bij het ophalen van de data")
        } finally {
            setLoading(false);
        }
    }
console.log(companyEmail, companyName);
    const updateCompanyName = async () => {
        try {
            if (companyName.trim() === '') {
                ToastError('Vul de afzender naam in')
            } else {
                await middleware.put("/settings/backoffice?setting_id=1", {
                    company_name: companyName
                });
                ToastSuccess('Naam aangepast');
            }
        } catch (error) {
            ToastError("Fout bij bijwerken van de data");
        }
    };

    const updateCompanyEmail = async () => {
        if (companyEmail.trim() === '') {
            ToastError('Vul de email in');
            return;
        }
        if (!regex.test(companyEmail)) {
            ToastError('Vul de geldige email in');
            return;
        }
        if (companyEmail === originalCompanyEmail) {
            return;
        }
        
        try {
            await middleware.put("/settings/backoffice?setting_id=1", {
                email: companyEmail
            });
            ToastSuccess('Email aangepast');
            setOriginalCompanyEmail(companyEmail);
        } catch (error) {
            ToastError("Fout bij bijwerken van de data");
        }
    };

    const updateStatus = async (newStatus) => {
        try {
            await middleware.put("/settings/backoffice?setting_id=1", {
                public: newStatus
            });
        } catch (error) {
            ToastError("Fout bij het updaten van de status");
        }
    };

    useEffect(() => {
        fetchSettings();
    }, [])
    return <PageTemplate navbarTitle={"Technische instellingen"} pageIsLoading={loading}>
        <div className="d-flex w-100 flex-column">
            <div className="d-flex mb-2 mt-4">
                <BackButton size="l" text={"Terug"} onClickFunction={() => navigate("/algemene/instellingen/technisch")} />
                <div className="d-flex flex-column align-items-center ms-4">
                    {!status ? (
                        <p className="mb-1 p-0">Gepubliceerd</p>
                    ) : (
                        <p className="mb-1 p-0">Gearchiveerd</p>
                    )}
                    <label className="switchActiveProduct">
                        <input
                            type="checkbox"
                            checked={!status}
                            onChange={(e) => {
                                const newStatus = !e.target.checked;
                                setStatus(newStatus);
                                updateStatus(newStatus);
                            }}
                        />
                        <span className="sliderActiveProduct round"></span>
                    </label>
                </div>
            </div>
            <div className="whiteBox col-12 mt-4 px-4 py-5">
                <p className="BlockTitle">E-mail instellingen aanpassen</p>
                <div className="d-flex flex-row">
                    <div className="w-50 me-4">
                        <BorderedTextInput buttonLabel='Reset naar standaardwaarden' label="Van e-mail" inputValue={companyEmail} setInputValue={setCompanyEmail} size='l' updateFunction={updateCompanyEmail} updateWaitTimer={1000} />
                    </div>
                    <div className="w-50">
                        <BorderedTextInput label="Afzender naam" inputValue={companyName} setInputValue={setCompanyName} size='l' updateFunction={updateCompanyName} updateWaitTimer={1000} />
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>
}

export default TechnicalSettingsEdit;