import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTable } from "react-table";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import PageTemplate from "../../Templates/PageTemplate";
import VATModals from "../../Modals/VATModal";
import TablePreset from "../../Table/TablePreset";
import { BackButton } from "../../HouseStyle/Components/Buttons";
import middleware from "../../Api/Middleware";
import { RegularSearchBar } from "../../HouseStyle/Components/SearchBars";

import countries from "i18n-iso-countries";
import nlLocale from "i18n-iso-countries/langs/nl.json";

countries.registerLocale(nlLocale);

function VATSettings() {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  });
  const [loading, setLoading] = useState(true);
  const [taxes, setTaxes] = useState([]);
  const [showTaxNew, setShowTaxNew] = useState(false);
  const [showTaxEdit, setShowTaxEdit] = useState(false);
  const [taxEditData, setTaxEditData] = useState([]);
  const [searchBar, setSearchBar] = useState("");
  const [low, setLow] = useState('');

  function setLoadingScreenFunc() {
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }

  const fetchAll = async () => {
    try {
      let searchCountry = searchBar.trim();

      // Check of de zoekterm overeenkomt met een landnaam in het Nederlands
      if (searchCountry) {
        const code = countries.getAlpha2Code(searchCountry, "nl");
        // Als het bestaat, vervang de zoekterm door de country code
        if (code) {
          searchCountry = code;
        }
      }

      const searchQuery = searchCountry
        ? `&searchTerm=${encodeURIComponent(searchCountry)}`
        : "";

      const [fetchTax] = await Promise.all([
        middleware.get(
          `sp/taxRatesTableAll?page=${pagination.page}&page_size=${pagination.page_size}${searchQuery}`
        ),
      ]);

      setTaxes(fetchTax.data.data);
      setLow(taxes.reduced_percentage);
      setPagination({
        ...pagination,
        max_items: fetchTax.data.total,
        begin_of_page: fetchTax.data.from,
        end_of_page: fetchTax.data.to,
      });

      setLoadingScreenFunc();
    } catch (error) {
      console.error(error);
      if (error.response?.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };

  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page]);

  useEffect(() => {
    if (searchBar.trim() === "") {
      fetchAll();
    } else {
      fetchAll();
    }
  }, [searchBar]);

  const handleSearchChange = useCallback((value) => {
    setSearchBar(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, []);

  const percentageFormat = (percentage) => {
    if (percentage === '' || percentage === null || percentage === undefined) {
      return '-';
    }
    return (
      parseFloat(percentage) % 1 === 0
        ? parseInt(percentage) + '%'
        : parseFloat(percentage).toFixed(1) + '%'
    );
  };

  const columns = useMemo(() => {
    return [
      {
        Header: () => <p className="FlatText mb-0">Land</p>,
        accessor: "country",
        Cell: ({ row }) => {
          const countryCode = row.original.country;
          const translatedCountry =
            countries.getName(countryCode, "nl") || countryCode;

          return (
            <div
              className="text-start FlatTextLink"
              onClick={() => {
                setShowTaxEdit(true);
                setTaxEditData(row.original);
                setLow(row.original.reduced_percentage);
              }}
            >
              {translatedCountry}
            </div>
          );
        },
      },
      {
        Header: () => <p className="FlatText mb-0">Hoog</p>,
        accessor: "high_percentage",
        Cell: ({ row }) => {
          return (
            <div className="text-start BlackText">
              {percentageFormat(row.original.high_percentage)}
            </div>
          );
        },
      },
      {
        Header: () => <p className="FlatText mb-0">Laag</p>,
        accessor: "reduced",
        Cell: ({ row }) => {
            return (
            <div className="text-start BlackText">
              {percentageFormat(row.original.reduced_percentage)}
            </div>
            );
        },
      },
      {
        Header: () => <p className="FlatText mb-0">Overig</p>,
        accessor: "zero_percentage",
        Cell: ({ row }) => {
          return (
            <div className="text-start BlackText">
              {percentageFormat(row.original.zero_percentage)}
            </div>
          );
        },
      },
    ];
  }, [taxes, low]);

  // Table instance
  const tableInstance = useTable({ columns, data: taxes });

  return (
    <>
      <VATModals
        key={taxes.primary_key}
        taxEdit={showTaxEdit}
        setTaxEdit={(e) => setShowTaxEdit(e)}
        taxEditData={taxEditData}
        low={low}
        setLow={setLow}
        percentageFormat={percentageFormat}
      />
      <PageTemplate navbarTitle={"BTW-instellingen"} pageIsLoading={""}>
        <div className="w-100">
          <div className="d-flex w-50">
          <BackButton text={"Terug"} onClickFunction={() => navigate("/algemene/instellingen")}/>
            <RegularSearchBar
              placeholder={"Zoeken..."}
              size="l"
              searchFunction={handleSearchChange}
            />
          </div>

          <TablePreset
            data={[
              {
                tableInstance: tableInstance,
                pagination: pagination,
                primary_key: "primary_key",
              },
            ]}
            handleSelection={() => {}}
            tableLoading={loading}
            setPagination={setPagination}
            showcheckbox={false}
          />
        </div>
      </PageTemplate>
    </>
  );
}

export default VATSettings;
