import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faCamera } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import placeholder from "../Icons/empty-placeholder.jpg";
import deliveryTruck from "../Icons/delivery-truck.png";
import "./OrderComponents.css";
import { ButtonBlue } from "../../HouseStyle/Components/Buttons";
import middleware from "../../Api/Middleware";

const VerwerkOrderComponent = ({
  productsToProcess,
  productPic,
  showPrice,
  showSalePrice,
  calculateSalePrice,
  statuses,
  paymentStatus,
  orderId,
}) => {
  const navigate = useNavigate();

  const [quantities, setQuantities] = useState(
    productsToProcess.reduce(
      (acc, product) => ({
        ...acc,
        [product.sold_product_id]: 0,
      }),
      {}
    )
  );

  // Get status tags based on current status
  const getStatusTags = () => {
    const tags = [];

    //Order status tag
    if (statuses[0] === 0) {
      tags.push({ label: "geannuleerd", type: "danger" });
    } else if (statuses[0] === 1) {
      tags.push({ label: "Concept", type: "primary" });
    } else if (statuses[0] === 2) {
      tags.push({ label: "Nieuw", type: "info" });
    } else if (statuses[0] === 3) {
      tags.push({ label: "Voltooid", type: "succes" });
    }

    // Payment Status Tag
    if (statuses[1] === 0) {
      tags.push({ label: "Concept", type: "primary" });
    } else if (statuses[1] === 1) {
      tags.push({ label: "Open staand", type: "danger" });
    } else if (statuses[1] === 2) {
      tags.push({ label: "Deels Betaald", type: "warning" });
    } else if (statuses[1] === 3) {
      tags.push({ label: "Volledig betaald", type: "succes" });
    }

    // Delivery Status Tag
    if (statuses[2] === 0) {
      tags.push({ label: "Nog niet verzonden", type: "danger" });
    } else if (statuses[2] === 1) {
      tags.push({ label: "Verzonden", type: "warning" });
    } else if (statuses[2] === 2) {
      tags.push({ label: "Aangekomen", type: "success" });
    }

    return tags;
  };
  const strip = (html) => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    }).format(price);
  };

  const handleQuantityChange = (productId, value) => {
    const quantity = Math.max(
      0,
      Math.min(
        parseInt(value) || 0,
        productsToProcess.find((p) => p.sold_product_id === productId)
          ?.amount || 0
      )
    );
    setQuantities((prev) => ({
      ...prev,
      [productId]: quantity,
    }));
  };

  if (!productsToProcess.length) {
    return (
      <div className="card mb-3 rounded-4 border-0">
        <div className="card-body text-center py-4">
          <FontAwesomeIcon
            icon={faTruck}
            style={{ color: "#009fe3" }}
            size="2x"
            className="mb-3"
          />
          <p className="mb-0">Geen producten meer te verwerken</p>
        </div>
      </div>
    );
  }

  return (
    <div className="whiteBox p-5 pt-4 pb-4 mb-4 align-center">
      <div className="header d-flex justify-content-start align-items-center pb-4 bottom-border-line">
        <div>
          <h5 className="mb-0 fw-bold BlockTitle">Nog te verwerken</h5>
        </div>

        {/* Status Tags */}
        <div className="d-flex flex-wrap ms-2">
          {getStatusTags().map((tag, index) => (
            <span
              key={index}
              className={`badge rounded-pill alert alert-${tag.type} border border-${tag.type} mb-0 ms-2`}
              style={{
                padding: "6px 12px",
                fontSize: "0.7rem",
                fontWeight: "500",
              }}
            >
              {tag.label}
            </span>
          ))}
        </div>
      </div>
      {productsToProcess.length > 0 ? (
        <ul className="list-group list-group-flush">
          {console.log(productsToProcess)}
          {productsToProcess.map((item, index) => {
            const price =
              item.sale_price_incl_tax == null
                ? item.price_incl_tax
                : item.sale_price_incl_tax;
            console.log(price);
            if (item.amount - item.items_processed - item.items_credited > 0) {
              return (
                <li
                  key={index}
                  className="verwerk-list-group-item bottom-border-line d-flex justify-content-between align-items-center gap-3 pb-2 pt-2"
                >
                  <div className="d-flex flex-row gap-3 flex-grow-1">
                    <div className="d-flex align-items-center">
                      {/* Product image */}
                      <img
                        src={
                          item.image?.path
                            ? middleware.defaults.baseURL +
                              "/public/storage/" +
                              item.image?.path
                            : placeholder
                        }
                        className="rounded-circle p-2 me-3"
                        height={100}
                        width={100}
                        alt="placeholder..."
                      />
                    </div>

                    {/* Product info */}
                    <div className="d-flex flex-column justify-content-center align-items-start">
                      <a
                        href={`/producten/bewerken/${item.product_id}`}
                        target="_blank"
                        className="new-link-color"
                      >
                        {item.product_name}
                      </a>
                      {item.product_description && (
                        <p className="text-muted small mb-0">
                          {strip(item.product_description)?.length < 100
                            ? strip(item.product_description)
                            : (strip(item.product_description)?.slice(0, 100) ??
                                "") + "..."}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Product price info */}
                  <div className="d-flex align-items-center justify-content-end gap-3 gap-xxl-5 flex-grow-1">
                    {item.sale_amount > 0 ? (
                      <div className="d-flex flex-column">
                        <span className="text-nowrap text-muted text-decoration-line-through text-secondary">
                          {formatPrice(parseFloat(price).toFixed(2))}
                        </span>
                        <span>
                          {formatPrice(
                            parseFloat(
                              item.sale_unit
                                ? price - item.sale_amount
                                : (price * (100 - item.sale_amount)) / 100
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    ) : (
                      <span className="text-nowrap">
                        {formatPrice(parseFloat(price).toFixed(2))}
                      </span>
                    )}
                    <span className="text-nowrap">
                      x
                      {item.amount - item.items_credited - item.items_processed}
                    </span>
                    <span className="fw-semibold">
                      {formatPrice(
                        (
                          (item.sale_unit
                            ? price - item.sale_amount
                            : (price * (100 - item.sale_amount)) / 100) *
                          (item.amount -
                            item.items_processed -
                            item.items_credited)
                        ).toFixed(2)
                      )}
                    </span>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      ) : (
        <div className="text-center p-3">
          <div className="text-center" role="status">
            <h5>There are no product to process...</h5>
          </div>
        </div>
      )}

      <div className="d-flex justify-content-end mt-3">
        <ButtonBlue
          text="Verwerken"
          onClickFunction={() => navigate("/bestellingen/verwerken/" + orderId)}
          icon={deliveryTruck}
        />
      </div>
    </div>
  );
};

export default React.memo(VerwerkOrderComponent);
