import React, { useEffect, useState } from "react";
import PageTemplate from "../Templates/PageTemplate";
import { Tab, Tabs } from "react-bootstrap";
import { BorderedSearchBar } from "../HouseStyle/Components/SearchBars";
import { BorderedDropDown } from "../HouseStyle/Components/DropDowns";
import { ButtonBlue } from "../HouseStyle/Components/Buttons";
import { useNavigate } from "react-router";
import middleware from "../Api/Middleware";
import { BorderedTextInput } from "../HouseStyle/Components/Inputs";  // Assuming you created this component
import ToastSuccess from "../Toasts/ToastSuccess";

const AccountCreation = () => {
    const navigate = useNavigate();

    const rightsFormOptions = [
        { value: "eenmanszaak", title: "Eenmanszaak" },
        { value: "bv", title: "Besloten vennootschap" },
    ]

    const [onboardData, setOnboardData] = useState({
        "legal_name": "",
        "coc_number": "",
        "rights_form": "",
        "vat_number": "",
        "country": "",
        "zipcode": "",
        "house_number": "",
        "addition": "",
        "street": "",
        "city": "",
        "phone_number": "",
        "email": "",
    });

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        CheckForPreviousOnboarding();
    }, []);

    const CheckForPreviousOnboarding = async () => {
        try {
            const [onboardRes] = await Promise.all([middleware.get("payment/onboardings")]);
            if (onboardRes.data.data.length > 0) {
                navigate("/betalingen/account/overzicht");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const validateFields = () => {
        let isValid = true;
        let newErrors = [];

        // Loop through the onboardData and check for empty values
        Object.entries(onboardData).forEach(([key, value]) => {
            if (!value.trim() && key !== "phone_number" && key !== "email" && key !== "addition") {
                newErrors.push(key);
                isValid = false;
            }
        });

        // At least one of phone_number or email must be filled
        if (!onboardData.phone_number && !onboardData.email) {
            newErrors.push("phone_number");
            newErrors.push("email");
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const Onboarding = async () => {
        if (validateFields()) {
            try {
                const [onboardRes] = await Promise.all([middleware.post("payment/onboardings", onboardData)]);
                ToastSuccess("Onboarding succesvol gestart");
                if(onboardRes.data.data) {
                    navigate("/betalingen/account/overzicht");
                }
                // Handle success (if needed)
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleChange = (e, field) => {
        if(field == "phone_number" || field == "email") {
            setErrors(prev => ([...prev.filter(error => error !== "phone_number" && error !== "email")]))
        } else {
            setErrors(prev => ([...prev.filter(error => error !== field)]))
        }
        setOnboardData(prev => ({ ...prev, [field]: e }));
    };

    return (
        <PageTemplate navbarTitle={"Payment HUB"}>
            <div className="d-flex align-items-start w-100">
                <div className="d-flex flex-column payment-account-creation-main-container">
                    <div className="tabContainer whiteBox px-4 tabsOverviewPadding col-12 mb-4">
                        <Tabs activeKey={"Bedrijfsgegevens"}>
                            <Tab
                                key={"Bedrijfsgegevens"}
                                eventKey={"Bedrijfsgegevens"}
                                title={<span className={"tab-title active fw-semibold"}>Bedrijfsgegevens</span>}
                            ></Tab>
                        </Tabs>
                    </div>

                    <div className="whiteBox payment-account-creation-form">
                        <h5 className="fw-bold mb-3">Bedrijfsgegevens</h5>

                        <div className="w-100">
                            <label className="mb-2">Bedrijfsnaam</label>
                            <BorderedSearchBar 
                                inputValue={onboardData.legal_name} 
                                setInputValue={(e) => handleChange(e, 'legal_name')} 
                                error={errors.includes("legal_name")}
                            />
                        </div>

                        <div className="w-100 d-flex flex-row gap-4">
                            <div className="d-flex flex-column w-100">
                                <label className="mb-2">KVK nummer</label>
                                <BorderedTextInput
                                    inputValue={onboardData.coc_number}
                                    setInputValue={(e) => handleChange(e, 'coc_number')}
                                    error={errors.includes("coc_number")}
                                />
                            </div>
                            <div className="d-flex flex-column w-100">
                                <label className="mb-2">Rechtsvorm</label>
                                <BorderedDropDown
                                    placeholder="-- selecteer rechtvorm --"
                                    noOptionsMessage="geen mogelijke rechtvormen"
                                    options={rightsFormOptions}
                                    selectedValue={onboardData.rights_form}
                                    setSelectedValue={(e) => handleChange(e, 'rights_form')}
                                    error={errors.includes("rights_form")}
                                />
                            </div>
                        </div>

                        <div className="d-flex flex-column w-100">
                            <label className="mb-2">BTW Nummer</label>
                            <BorderedTextInput
                                inputValue={onboardData.vat_number}
                                setInputValue={(e) => handleChange(e, 'vat_number')}
                                error={errors.includes("vat_number")}
                            />
                        </div>

                        <div className="d-flex flex-column w-100">
                            <label className="mb-2">Land</label>
                            <BorderedTextInput
                                inputValue={onboardData.country}
                                setInputValue={(e) => handleChange(e, 'country')}
                                error={errors.includes("country")}
                            />
                        </div>

                        <div className="w-100 d-flex flex-column gap-4 flex-lg-row">
                            <div className="d-flex flex-column w-100">
                                <label className="mb-2">Postcode</label>
                                <BorderedTextInput
                                    inputValue={onboardData.zipcode}
                                    setInputValue={(e) => handleChange(e, 'zipcode')}
                                    error={errors.includes("zipcode")}
                                />
                            </div>
                            <div className="w-100 d-flex flex-row gap-4">
                                <div className="d-flex flex-column w-100">
                                    <label className="mb-2">Huisnummer</label>
                                    <BorderedTextInput
                                        inputValue={onboardData.house_number}
                                        setInputValue={(e) => handleChange(e, 'house_number')}
                                        error={errors.includes("house_number")}
                                        type="number"
                                    />
                                </div>
                                <div className="d-flex flex-column w-100">
                                    <label className="mb-2">Toevoeging</label>
                                    <BorderedTextInput
                                        inputValue={onboardData.addition}
                                        setInputValue={(e) => handleChange(e, 'addition')}
                                        error={errors.includes("addition")}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-100 d-flex flex-row gap-4 mb-3">
                            <div className="d-flex flex-column w-100">
                                <label className="mb-2">Straat</label>
                                <BorderedTextInput
                                    inputValue={onboardData.street}
                                    setInputValue={(e) => handleChange(e, 'street')}
                                    error={errors.includes("street")}
                                />
                            </div>
                            <div className="d-flex flex-column w-100">
                                <label className="mb-2">Stad</label>
                                <BorderedTextInput
                                    inputValue={onboardData.city}
                                    setInputValue={(e) => handleChange(e, 'city')}
                                    error={errors.includes("city")}
                                />
                            </div>
                        </div>

                        <div className="mb-2">
                            <p className="fw-bold mb-2">Publieke contact gegevens zodat klanten contact kunnen opnemen overbetalingen 1 van de 2 is minimaal verplicht:</p>

                            <div className="w-100 d-flex flex-row gap-4">
                                <div className="d-flex flex-column w-100">
                                    <label className="mb-2">Telefoonnummer</label>
                                    <BorderedTextInput
                                        inputValue={onboardData.phone_number}
                                        setInputValue={(e) => handleChange(e, 'phone_number')}
                                        error={errors.includes("phone_number")}
                                    />
                                </div>
                                <div className="d-flex flex-column w-100">
                                    <label className="mb-2">E-mailadres</label>
                                    <BorderedTextInput
                                        inputValue={onboardData.email}
                                        setInputValue={(e) => handleChange(e, 'email')}
                                        error={errors.includes("email")}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end">
                            <ButtonBlue text={"Aanmaken"} onClickFunction={Onboarding} />
                        </div>
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
};

export default AccountCreation;
