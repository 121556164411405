import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router";
import Cookies from "universal-cookie";
import middleware from "../../Api/Middleware";
import { useNavigate } from "react-router";
import PageTemplate from "../../Templates/PageTemplate";
import { BackButton } from "../../HouseStyle/Components/Buttons";
import { BorderedDropDown } from "../../HouseStyle/Components/DropDowns.js";
import ToastError from "../../Toasts/ToastError";
import ToastSuccess from "../../Toasts/ToastSuccess";
import { ButtonBlue } from "../../HouseStyle/Components/Buttons";
import { BorderedTextInput, BorderedMoneyInput, BorderedNumberInput } from "../../HouseStyle/Components/Inputs.js";
import saleschannels from "../../Api/SalesChannels.js";

function GiftcardNew() {
    const navigate = useNavigate();
    const [salesChannels, setSalesChannels] = useState([])
    const [status, setStatus] = useState(null);
    const [code, setCode] = useState('');
    const [onlinePin, setOnlinePin] = useState('');
    const [saldo, setSaldo] = useState(0);
    const [channel, setChannel] = useState(null);
    const [loadingPage, setLoadingPage] = useState(false);
    const [statusSwitch, setStatusSwitch] = useState(false);

    const fetchChannels = async () => {
        try {
            const [channels] = await Promise.all([
                middleware.get(`saleschannels`)
            ]);
            setSalesChannels(channels.data.data);
            console.log(channels.data);  // Log hier na het ophalen van de data
        } catch (err) {
            ToastError('Fout tijdens het ophalen van data');
        }
    };
    

    useEffect( () => {
        fetchChannels()
    }, [])

    const addNewGiftcard = async () => {
        if (status === null || code.trim() == '' || onlinePin.trim() == '' || channel === null) {
            ToastError('Vul alle velden in');
            return;
        }
        if (saldo == 0) {
            ToastError('Saldo moet hoger zijn dan 0')
            return;
        }
        try {
            await middleware.post(`giftcards`, { code: code, status: status, online_pin: onlinePin, sales_channel_id: channel, saldo: saldo });
            navigate('/algemene/instellingen/cadeaukaarten');
        } catch (error) {
            console.error(error);
            ToastError('Fout bij het aanmaken');
        }
    };

    
    const dropDownOptions = salesChannels.map(channel => {
        return { title: channel.sales_channel_name, value: channel.sales_channel_id };
      });      

    return (
        <>
            <PageTemplate navbarTitle={"Cadeaukaarten"} pageIsLoading={loadingPage}>
                <div className="w-100 d-flex flex-column">
                    <div className="w-100 d-flex flex-row mb-3">
                        <BackButton text={"Terug"} disabled={false} onClickFunction={() => navigate("/algemene/instellingen/cadeaukaarten")} />
                        <div className="d-flex flex-column align-items-center">
                            {!statusSwitch ? (
                                <p className="mb-1 p-0">Gepubliceerd</p>
                            ) : (
                                <p className="mb-1 p-0">Gearchiveerd</p>
                            )}
                            <label className="switchActiveProduct">
                                <input
                                    type="checkbox"
                                    checked={!status}
                                    onChange={(e) => setStatusSwitch(!e.target.checked)}
                                />
                                <span className="sliderActiveProduct round"></span>
                            </label>
                        </div>
                    </div>
                    <div className="whiteBox w-100 py-4">
                        <div className="w-50 px-3">
                        <p className="fw-bold mb-0">Verkoopkanaal</p>
                        <BorderedDropDown
                                placeholder="- Kies een verkoopkanaal -"
                                options={dropDownOptions}
                                selectedValue={channel}
                                setSelectedValue={setChannel}
                            />
                            <p className="fw-bold mb-0 mt-3">Code</p>
                            <BorderedNumberInput inputValue={code} setInputValue={setCode} placeholder='Code' size='m'/>
                            <p className="fw-bold mb-0 mt-3">Saldo</p>
                            <BorderedMoneyInput size='m' restrictMinus={true} minAmount={0} inputValue={saldo} setInputValue={setSaldo}/>
                            <p className="fw-bold mb-0 mt-3">Op voorraad</p>
                            <BorderedDropDown
                                placeholder=""
                                options={[
                                    { title: "Uitgegeven", value: "1" },
                                    { title: "Op voorraad", value: "0" },
                                ]}
                                selectedValue={status}
                                setSelectedValue={setStatus}
                            />
                            <p className="fw-bold mb-0 mt-3">Online pin</p>
                            <BorderedNumberInput inputValue={onlinePin} setInputValue={setOnlinePin} placeholder='Online pin' size='m'/>
                            <p className="BeneathFlatText DarkGreyText mt-1 ps-3">Deze pin kan gebruikt worden om online te betalen.</p>
                            <ButtonBlue text={"Aanmaken"} disabled={false} onClickFunction={addNewGiftcard} />
                        </div>
                    </div>
                </div>
            </PageTemplate>
        </>
    )
}

export default GiftcardNew;