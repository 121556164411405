import React from "react";
import Placeholder from "../../Icons/empty-placeholder.jpg";


const FacturizationDetails = ({ companyInfo }) => {
    return <div className="p-4 pb-0 w-50 parentCompanyInfoColumn">
        {console.log(companyInfo)}
        {companyInfo.start_invoicenumber &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">Volgende factuurnummer</h6>
                <h6 className="w-50">{companyInfo.start_invoicenumber}</h6>
            </div>
        }
        {companyInfo.payment_term &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">Factuur verval termijn</h6>
                <h6 className="w-50">{companyInfo.payment_term} Dagen</h6>
            </div>
        }
        {companyInfo.receipt_footnote &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">Standaard factuur omschrijving</h6>
                <h6 className="w-50" dangerouslySetInnerHTML={{ __html: companyInfo.receipt_footnote }}/>
            </div>
        }
        {companyInfo.product_number_display.toString() &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">Productnummer weergeven op factuur regels</h6>
                <h6 className="w-50">{companyInfo.product_number_display == 1 ? "Ja" : "Nee"}</h6>
            </div>
        }
        {companyInfo.logo_display.toString() &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">Logo weergeven op factuur</h6>
                <h6 className="w-50">{companyInfo.logo_display == 1 ? "Ja" : "Nee"}</h6>
            </div>
        }
        {companyInfo.address_display.toString() &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">Adresgegevens weergeven op factuur</h6>
                <h6 className="w-50">{companyInfo.address_display == 1 ? "Ja" : "Nee"}</h6>
            </div>
        }
        {companyInfo.invoice_background &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">Factuur brierpapier</h6>
                <img src={companyInfo.invoice_background ?? Placeholder} alt="Thermal Logo" style={{maxHeight: "30vh", maxWidth: "30vw"}}  />
            </div>
        }
    </div>
}


export default FacturizationDetails;