import React, { useEffect, useState } from "react";
import PageTemplate from "../../../Templates/PageTemplate";
import { useNavigate } from "react-router";
import Cookies from 'universal-cookie';
import middleware from "../../../Api/Middleware";
import { BorderedTextInput } from "../../../HouseStyle/Components/Inputs";
import { BorderedDropDown } from "../../../HouseStyle/Components/DropDowns";
import TextEditor from "../../../Main/TextEditor";
import { BackButton } from "../../../HouseStyle/Components/Buttons";
import Placeholder from "../../Icons/empty-placeholder.jpg";
import MediaUploaderComponent from "../../../MediaUpload/MediaUploaderComponent";
import NormalMediaUploader from "../../../MediaUpload/NormalMediaUpload/NormalMediaUpload";
import ToastSuccess from "../../../Toasts/ToastSuccess";

const HouseStyle = () => {
    const [loading, setLoading] = useState(true);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [uploadedImageTermisch, setUploadedImageTermisch] = useState(null);
    const [companyInfo, setCompanyInfo] = useState({});
    const [companyInfoOld, setCompanyInfoOld] = useState({});
    
    // Navigation setup
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [modals, setModals] = useState({
        deactivateCompanyInfo: false
    });
    const toggleModal = (modal) => setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));

    const fetchCompanyInfo = async () => {
        const settings = await middleware.get("/settings/backoffice?setting_id=1");
        setCompanyInfo(settings.data.data);
        setCompanyInfoOld(settings.data.data);
        // Zet ook de logo als uploadedImage
        setUploadedImageTermisch({ url: settings.data.data.blackwhite_logo, name: "black and white logo" });
        setUploadedImage({ url: settings.data.data.logo, name: "logo" });
        setLoading(false);
    };

    useEffect(() => {
        fetchCompanyInfo();
    }, []);

    // Wanneer de uploadedImage verandert, update dan de logo in companyInfo (als de URL verschilt)
    useEffect(() => {
        if (uploadedImage?.file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // reader.result will be a base64 encoded data URL
                const base64String = reader.result;
                if (base64String && base64String !== companyInfo.logo) {
                    setCompanyInfo((prev) => ({ ...prev, logo: base64String }));
                }
            };
            reader.readAsDataURL(uploadedImage.file);
        } else if (uploadedImage?.url && uploadedImage.url !== companyInfo.logo) {
            // fallback if there's no file object, use the URL directly
            setCompanyInfo((prev) => ({ ...prev, logo: uploadedImage.url }));
        }
    }, [uploadedImage]); 

    useEffect(() => {
        if (uploadedImageTermisch?.file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // reader.result will be a base64 encoded data URL
                const base64String = reader.result;
                if (base64String && base64String !== companyInfo.blackwhite_logo) {
                    setCompanyInfo((prev) => ({ ...prev, blackwhite_logo: base64String }));
                }
            };
            reader.readAsDataURL(uploadedImageTermisch.file);
        } else if (uploadedImageTermisch?.url && uploadedImageTermisch.url !== companyInfo.blackwhite_logo) {
            // fallback if there's no file object, use the URL directly
            setCompanyInfo((prev) => ({ ...prev, blackwhite_logo: uploadedImageTermisch.url }));
        }
      }, [uploadedImageTermisch]);

    // Wanneer companyInfo verandert, update de backend (maar alleen als er een echte wijziging is)
    useEffect(() => {
        if (JSON.stringify(companyInfo) === JSON.stringify(companyInfoOld)) return;
        UpdateCompanyInfo();
    }, [companyInfo]);

    const UpdateCompanyInfo = async () => {
        try {
          await middleware.put("/settings/backoffice", {
            setting_id: 1,
            font: companyInfo.font,
            logo: companyInfo.logo,
            blackwhite_logo: companyInfo.blackwhite_logo,
          });
          ToastSuccess('Company information updated successfully');
          // Update de oude state zodat de useEffect niet telkens triggert
          setCompanyInfoOld(companyInfo);
        } catch (error) {
          console.error('Error updating company information:', error);
        }
    };

    // console.log("CompanyInfo:", companyInfo);

    return (
      <PageTemplate pageIsLoading={loading} navbarTitle={"Bedrijfsinformatie"}>
        <div className="col-12 d-flex flex-column">
          <div className="d-flex mb-4 mt-4">
            <BackButton size="l" text={"Terug"} onClickFunction={() => navigate("/algemene/instellingen/bedrijfsinformatie")} />
            <div className="d-flex flex-column align-items-center">
              {companyInfo.public === 0 ? <p className="mb-1 p-0">Gepubliceerd</p> : <p className="mb-1 p-0">Gearchiveerd</p>}
              <label className="switchActiveProduct">
                <input type="checkbox" checked={companyInfo.public}
                  onChange={(e) => toggleModal("deactivateCompanyInfo")} />
                <span className="sliderActiveProduct round"></span>
              </label>
            </div>
          </div>
          <div className="whiteBox px-5 py-4">
            <h2 className="fw-semibold mt-2">Huisstijl aanpassen</h2>
            <h4 className="fw-bold pt-2">Jouw bedrijf</h4>
            <h6 className="fw-bold ms-4 py-2 pt-3">Lettertype</h6>
            <div className="col-2">
              <BorderedDropDown size="s" selectedValue={companyInfo.font || ""} setSelectedValue={(e) => setCompanyInfo((prev) => ({ ...prev, font: e }))} placeholder="e.g. Comic Sans" options={[
                { title: "Nunito", value: "Nunito" },
                { title: "Playfair Display", value: "Playfair Display" },
                { title: "Calibri", value: "Calibri" },
                { title: "Arial", value: "Arial" }
              ]} />
            </div>
            <h4 className="fw-bold pt-2">Logo</h4>
            <div className="row">
              <div className="col-4 pt-3">
                <p className="fw-bold">Logo</p>
                <img src={uploadedImage?.url ?? Placeholder} alt={uploadedImage?.name} width={250} height={150} />
                <p className="text-small text-secondary">
                  De aanbevolen grootte voor het uploaden van afbeeldingen is 500 bij 300 of hoger.
                </p>
              </div>
              <div className="pdfInput col-8 pt-5 mt-2">
                {/* Gebruik de NormalMediaUploader, die nu de image doorgeeft via onImageChange */}
                <NormalMediaUploader onImageChange={setUploadedImage} />
              </div>
              <div className="col-4 pt-3">
                <p className="fw-bold">Logo</p>
                <img src={uploadedImageTermisch?.url ?? Placeholder} alt={uploadedImageTermisch?.name} width={250} height={150} />
                <p className="text-small text-secondary">
                  De aanbevolen grootte voor het uploaden van afbeeldingen is 500 bij 300 of hoger. Alleen PNG wordt ondersteund voor afdrukken in de winkel.
                </p>
              </div>
              <div className="pdfInput col-8 pt-5 mt-2">
                <NormalMediaUploader onImageChange={setUploadedImageTermisch} allowedFormats={["image/png"]} />
              </div>
            </div>
          </div>
        </div>
      </PageTemplate>
    );
};

export default HouseStyle;
