import React, { useState, useEffect } from "react";
import PageTemplate from "../../Templates/PageTemplate";
import * as Icons from "../../HouseStyle/Icons";
import middleware from "../../Api/Middleware";
import { useNavigate, useParams } from "react-router";
import Cookies from "universal-cookie";
import "./BrandsEdit.css";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const BrandsEdit = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [brands, setBrands] = useState([]); // useState for all users in the database 
  const [loading, setLoading] = useState(true); // useState for page loading
  const [brandTitle, setBrandTitle] = useState(brands.name); // useState for all users in the database 
  const [brandDescription, setBrandDescription] = useState(brands.description); // useState for all users in the database 
  const [brandKeywords, setBrandKeywords] = useState(brands.keywords); // useState for all users in the database 
  const [loadingScreen, setLoadingScreen] = useState(true); // useState for the Loadingscreen
  const { brand_id } = useParams();

  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchBrands] = await Promise.all([
        middleware.get(`/products/brands?brand_id=${brand_id}`),
      ]);
      setBrands(fetchBrands.data); // sets the the data in the products usestate
      setLoadingScreenFunc();
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };

  // fetches all the data when pagination.page_size, pagination.page, order or location.pathname changes
  useEffect(() => {
    fetchAll();
  }, []);

  function setLoadingScreenFunc() {
    setLoading(false);
    setTimeout(() => {
      setLoadingScreen(false);
    }, 500);
  }

  const submitNewBrand = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }
          const res = await middleware.put("products/brands", {
            brand_id: brand_id,
            name: brandTitle,
            description: brandDescription,
            keywords: brandKeywords,
          });  
      
        
          window.location.reload();
      }
    catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <PageTemplate navbarTitle={"Merken bewerken"} pageIsLoading={loading}  backValue={"/warehouse/instellingen"}>
        <form onSubmit={submitNewBrand} method="POST" className="editBrandResponsive">
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn bg-white fw-semibold rounded-pill mb-4"
              onClick={() => navigate("/warehouse/instellingen/merken")}
            >
              <FontAwesomeIcon icon={faCaretLeft} /> Merken
            </button>
            <button type="submit" className="btn text-white fw-semibold rounded-pill mb-4" style={{backgroundColor: "#79b893"}}>Opslaan</button>
          </div>

          <div className="whiteBox parentBoxEditUser" >
            <div className="m-3">
              <h3>Merk wijzigen</h3>
              <div className="m-3 my-5">
                <div className="d-flex col-12 border-bottom py-4 labelInputEditResponive">
                  <h5 className="titlesEditUsers inputTitle col-3 ps-3 text-nowrap minFitContent labelEditBrandResponsive">Merk titel</h5>
                  <input className="inputFieldEditUser col-8 ps-2 inputEditBrandResponsive" type="text" placeholder="Titel" onChange={(e) => setBrandTitle(e.target.value)} defaultValue={brands.name ?? ''} ></input>
                </div>
                <div className="d-flex col-12 border-bottom py-4 labelInputEditResponive">
                  <h5 className="titlesEditUsers inputTitle col-3 ps-3 minFitContent labelEditBrandResponsive">Omschrijving</h5>
                  <textarea className="inputFieldEditUser col-8 ps-2 textareaStyle inputEditBrandResponsive" type="text" placeholder="Omschrijving" onChange={(e) => setBrandDescription(e.target.value)} defaultValue={brands.description ?? ''}></textarea>
                </div>
                {/* <div className="d-flex col-12 border-bottom py-4 labelInputEditResponive">
                  <h5 className="titlesEditUsers inputTitle col-3 ps-3 minFitContent labelEditBrandResponsive">Trefwoorden</h5>
                  <textarea className="inputFieldEditUser col-8 ps-2 textareaStyle inputEditBrandResponsive" type="text" placeholder="Trefwoorden" onChange={(e) => setBrandKeywords(e.target.value)} defaultValue={brands.keywords ?? ''} ></textarea>
                </div> */}
              </div>
            </div>
          </div>
        </form>
      </PageTemplate>
    </>
  );
};

export default BrandsEdit;