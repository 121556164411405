import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './sidebar.css';
import Dashboard from './Icons/Dashboard.svg';
import Customers from './Icons/Customer.svg';
import Orders from './Icons/Order.svg';
import Payments from './Icons/Payments.svg';
import Warehouse from './Icons/Warehouse.svg';
import envelope from './Icons/envelope.png';
import globe from './Icons/globe.png';
import gear from './Icons/gear.png';
// import money from './Icons/money.png';
import calendar from './Icons/calendar.png';
import sun from './Icons/sun.svg';
import moon from './Icons/moon.svg';
import logo from './Logo Unified SMB - Wit.svg';
import { useMediaQuery } from 'react-responsive'; // Media query hook
import warehouseInstellingen from './Icons/distribution-center.png';
import { removeLoginCookies } from '../../Login/Login';
import TDSTextNavigation from '../../Templates/TDSTextNavigation';
import { faAngleLeft, faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import middleware from '../../Api/Middleware';

const Sidebar = ({ navInfo, callbackFuncIsLoading, collapsed, darkmodeSystem, GetCollapsedSidebar }) => {
  const location = useLocation();
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const savedCollapsed = localStorage.getItem("CollapsedSidebar");
  const savedDarkmode = localStorage.getItem("Darkmode");
  const systemPrefersDark = useMediaQuery({ query: "(prefers-color-scheme: light)" });
  const [collapsedSidebar, setCollapsedSidebar] = useState(JSON.parse(savedCollapsed));
  const [darkmode, setDarkmode] = useState(false);
  const [iosDevice, setIosDevice] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [sideBarLinks, setSideBarLinks] = useState([]);
  const [shortcutLinks, setShortcutLinks] = useState([]);
  
  
  useEffect(() => {
    if (navInfo && navInfo.routes) {
      try {
        const formattedRoutes = navInfo.routes.map(route => ({
          to: route.to,
          text: route.text,
          icon: route.icon,
          dropdown: route.dropdown === "1",
          status: route.status,
          subPagesPath: []
        }));

        setSideBarLinks(formattedRoutes);
      } catch (error) {
        console.error('Error parsing navInfo.routes:', error);
      }
    }
  }, [navInfo]);
  
  useEffect(() => {
    if (navInfo && navInfo.routes_shortcuts) {
      try {
        const formattedRoutes = navInfo.routes_shortcuts.map(route => ({
          to: route.to,
          text: route.text,
          icon: route.icon,
        }));

        setShortcutLinks(formattedRoutes);
      } catch (error) {
        console.error('Error parsing navInfo.routes_shortcuts:', error);
      }
    }
  }, [navInfo]);

  useEffect(() => {
    callbackFuncIsLoading(false);

    const handleResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize[0] < 1458) {
     setCollapsedSidebar(true)     
    }
  }, [windowSize[0]]);

  // useEffect(() => {
  //   if (darkmode) {
  //     document.body.classList.add('dark');
  //   } else {
  //     document.body.classList.remove('dark');
  //   }
  // }, [darkmode]); 

  useEffect(() => {
    collapsed(collapsedSidebar)
    localStorage.setItem("CollapsedSidebar", collapsedSidebar);
    if (GetCollapsedSidebar !== null) {
        GetCollapsedSidebar(collapsedSidebar);
    }
    if (collapsedSidebar) {
      document.body.classList.add('collapsed');
    } else {
      document.body.classList.remove('collapsed');
    }
  }, [collapsedSidebar]);

  useEffect(() => {
    darkmodeSystem(darkmode)
    localStorage.setItem("Darkmode", darkmode);
  }, [darkmode]);

  useEffect(() => {
    // Check if it's an iOS device
    setIosDevice(isIOSDevice());
  }, []);

  const isIOSDevice = () => {
    return /iPad|iPhone|iPod/.test(navigator.platform) ||
           (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  }

  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const logout = queryParameters.get("logout");

  if (logout) {
    removeLoginCookies(navigate);
  }

  const isActiveLink = (linkPath, subPages) => {
    const locationPath = urlDecoding(location.pathname);
    // Split paths into segments
    const locationSegments = locationPath.split('/');
    const linkPathSegments = linkPath.split('/');

    // Compare base paths (excluding dynamic segments)
    const basePathMatches = locationSegments.slice(0, linkPathSegments.length).join('/') === linkPath;
    
    const isSubpageActive = subPages?.some(subpage => locationPath.includes(`${linkPath}/${subpage}`));
    return basePathMatches || isSubpageActive;
  };


  const shouldShowDropdown = (dropdown, subPagesPath, to) => {
    return dropdown && subPagesPath.length > 0 && !isActiveLink(to, subPagesPath);
  };

  const urlDecoding = urlPath => {
    return urlPath.replace('%C3%AB', 'ë');
  };

  let locationLastItem = location.pathname.split('/').pop();

  const SidebarLink = ({ to, text, icon, dropdown, status, subPagesPath }) => (
    status == 2 ? null : (
      <div className={`my-2 ${status == 1 ? "icon-container" : "inactive-icon-container"} d-flex flex-nowrap flex-column ${iosDevice ? "lastSidbarItemPlacment" : ""} ${isActiveLink(to, subPagesPath) ? (to == "" ? '' :'activeSidebarItem') : ''}`}>
        {status == 1 && (to !== "" && to !== null && to !== undefined) ?
          <Link className='text-decoration-none d-flex flex-nowrap iconsCenter w-100 align-items-center' to={to} style={{padding: "0px 30px"}}>
            <p className={`text-white alignCenter mb-0 user-select-none iconSidebarCenter  ${isActiveLink(to) ? 'active-icon' : ''}`}>{icon}</p>
            <p className={`icon-text alignCenter ${isActiveLink(to) ? (to == "" ? '' : 'active-text') : ''}`}>{text}</p>
          </Link>
          :
            <span className='text-decoration-none d-flex flex-nowrap iconsCenter w-100 align-items-center' style={{padding: "0px 30px"}}>
              <p className={`alignCenter mb-0 iconSidebarCenter user-select-none inactive-icon ${isActiveLink(to) ? 'active-icon' : ''}`}>{icon}</p>
              <p className={`inactive-icon-text alignCenter ${isActiveLink(to) ? (to == "" ? '' : 'active-text') : ''}`}>{text}</p>
            </span>
        }
        {shouldShowDropdown(dropdown, subPagesPath, to) ? (
          <div className="dropdownSidebar">
            <div className='dropdownSidebarContent'>
              {subPagesPath?.map((linkPath, index) => (
                <TDSTextNavigation key={index} text={`${linkPath.charAt(0).toUpperCase()}${linkPath.slice(1)}`} link={`${to}/${linkPath}`} extraClassNames={"ps-3 textColorSidebarDropdown"} />
              ))}
            </div>
          </div>
        ) : (
          <div>
            {dropdown ? (
              <div>
                <ul className='pb-2 d-flex flex-column mb-0 subLinksList'>
                  {subPagesPath?.map((linkPath, index) =>
                    <TDSTextNavigation key={index} text={`${linkPath.charAt(0).toUpperCase()}${linkPath.slice(1)}`} link={`${to}/${linkPath}`} extraClassNames={`ms-2 text-black subPages px-3 ${urlDecoding(locationLastItem) === linkPath ? 'pageIndicator' : ''}`} />
                  )}
                </ul>
              </div>
            ) : ""}
          </div>
        )}
      </div>
    )
  );
  // const SidebarLinks = [
  //   { to: routes[0].to, text: 'Dashboard', icon: <img src={Dashboard} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: [] },
  //   { to: routes[1].to, text: 'Customers', icon: <img src={Customers} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: [] },
  //   { to: routes[2].to, text: 'Orders', icon: <img src={Orders} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: [] },
  //   { to: routes[3].to, text: 'Payments', icon: <img src={Payments} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: [] },
  //   { to: routes[4].to, text: 'Warehouse', icon: <img src={Warehouse} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: [] },
  //   { to: routes[5].to, text: 'Warehouse-instelingen', icon: <img src={warehouseInstellingen} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: [] },
  //   // { to: '/instellingen', text: 'Instellingen', icon: <img src={gear} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: []  },
  //   // { to: '/algemene/instellingen', text: 'Algemene instellingen', icon: <img src={gear} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: true, subPagesPath: ['gebruikers', 'rechten']  },
  // ];

  return (
    <>
      <nav className={`${collapsedSidebar ? 'collapsed sidebar' : `sidebar`} ${darkmode ? " darkmode" : ""}`}>
      <div className='d-flex justify-content-center px-2 py-3 mb-0'>
        <img className='logo d-flex user-select-none justify-content-center' src={logo} alt='logo' />
      </div>
      <div className='d-flex flex-column align-items-center'>

        {sideBarLinks?.map((link, index) => (
          <SidebarLink
            key={index}
            to={link.to}
            text={link.text}
            icon={
              <img
                src={link.icon ?? "https://static.thenounproject.com/png/482114-200.png"}
                alt="icon"
                className='iconsSidebar text-white'
              />
            }
            dropdown={link.dropdown}
            subPagesPath={link.subPagesPath}
            status={link.status}
          />
        ))}

        {shortcutLinks && shortcutLinks.length > 0 && (
          <>
            <div className='sidebar-divider my-3' />
          </>
        )}

        {shortcutLinks?.map((link, index) => (
          <SidebarLink
            key={index}
            to={link.to}
            text={link.text}
            icon={
              <img
                src={link.icon ?? "https://static.thenounproject.com/png/482114-200.png"}
                alt="icon"
                className='iconsSidebar text-white'
              />
            }
            status={1}
          />
        ))}

      </div>
      <div className='position-absolute bottom-0 w-100 d-flex justify-content-center flex-column mb-3'>
        <div className=' d-flex justify-content-center'>
          {/* <div className=' d-flex flex-row mb-3'>
            <img src={moon} className='modeToggleIcons d-flex align-self-center'></img>
            <div className='d-flex align-self-center mx-2'>
          <label className="switchMode">
            <input type="checkbox" onChange={() => setDarkmode(!darkmode)} checked={!darkmode} />
            <span className="sliderMode round"></span>
              </label>
            </div>
            <img src={sun} className='modeToggleIconsSun'></img>
          </div> */}
        </div>
        <div className='d-flex justify-content-center'>
          <div className='d-flex flex-row cursorPointer' onClick={() => setCollapsedSidebar(!collapsedSidebar)}>
            <FontAwesomeIcon style={{fontSize: "20px"}} className='d-flex align-self-center text-white me-2' icon={faCircleChevronLeft} />
            <p className='text-white mb-0 collapseText'>Menu invouwen</p>
          </div>
        </div>
      </div>
    </nav>
        
    </>
  );
};

export default Sidebar;