import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import warning from "../Icons/Warning-sign.svg";
import middleware from "../../../Api/Middleware";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToastError from "../../../Toasts/ToastError";

const EditNoteModal = ({
  openEditNote,
  closeEditNote,
  data,
  setDataUpdated,
}) => {
  const [showEditNote, setShowEditNote] = useState(false);
  const [titleNote, setTitleNote] = useState("");
  const [note, setNote] = useState("");
  useEffect(() => {
    setTitleNote(data?.note_title);
    setNote(data?.note);
  }, []);
  useEffect(() => {
    if (openEditNote) {
      setShowEditNote(true);
    }
  }, [openEditNote]);

  const closeEditNoteModal = () => {
    setShowEditNote(false);
    closeEditNote();
  };

  const submitEditedNote = async (e) => {
    e.preventDefault();
    if (titleNote == "") {
      ToastError("Vul een titel in");
      return;
    }
    if (note == "") {
      ToastError("Vul een notitie in");
      return;
    }
    const [putNoteRes] = await Promise.all([
      middleware.put(`consumers/notes?client_note_id=${data.client_note_id}`, {
        note_title: titleNote || data.note_title,
        note: note || data.note,
      }),
    ]);
    setNote("");
    setTitleNote("");
    setDataUpdated(true);
    closeEditNoteModal();
  };

  return (
    <Modal
      id="modalBlueprint"
      show={showEditNote}
      onHide={closeEditNoteModal}
      className="colormodals"
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header className="modalTop justify-content-between">
        <h3>Notitie Bewerken</h3>
        <button
          variant="transparent"
          className=" btn"
          onClick={() => closeEditNoteModal()}
        >
          <FontAwesomeIcon icon={faXmark} className="closeModal" />
        </button>
      </Modal.Header>
      <Modal.Body className="modalCenter d-flex justify-content-center">
        <div className="d-flex flex-column justify-content-center w-100">
          <form onSubmit={submitEditedNote} className="w-100">
            <div className="d-flex flex-column mb-3">
              <label className="mb-2 fw-medium">Titel</label>
              <input
                className="standardInput p-3"
                onChange={(e) => setTitleNote(e.target.value)}
                defaultValue={data?.note_title}
              ></input>
            </div>
            <div className="d-flex flex-column mb-3">
              <label className="mb-2 fw-medium">Notitie</label>
              <textarea
                cols={10}
                rows={10}
                className="standardInput p-3"
                onChange={(e) => setNote(e.target.value)}
                style={{ minHeight: "150px" }}
                defaultValue={data?.note}
              ></textarea>
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn lightBlueStandardButton">
                Notitie bewerken
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottom"></Modal.Footer>
    </Modal>
  );
};

export default EditNoteModal;
