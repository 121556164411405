import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ModalStyle.css";
import middleware from "../Api/Middleware";
import { BorderedTextInput } from '../HouseStyle/Components/Inputs';
import { ButtonBlue, ButtonLightGrey } from '../HouseStyle/Components/Buttons';
import { m } from 'framer-motion';

const BrandModal = ({ brandEdit, setBrandEdit,  brandNew, setBrandNew, brandEditData,  AddedOrEditedBrand }) => {
  const [showBrand, setShowBrand] = useState(false);
  const [merkName, setMerkName] = useState("");
  const [description, setDescription] = useState("");
  // const [keywords, setKeywords] = useState("");  
  const [merkNameEdit, setMerkNameEdit] = useState(brandEditData.name);
  const [descriptionEdit, setDescriptionEdit] = useState(brandEditData.description);
  // const [keywordsEdit, setKeywordsEdit] = useState(brandEditData.keywords);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (brandEdit) {
      setMerkNameEdit(brandEditData.name);
      setDescriptionEdit(brandEditData.description);
      // setKeywordsEdit(brandEditData.keywords);
      setIsEditing(true);
    }
  }, [brandEditData, brandEdit]);

  const submitNewBrand = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }
      if (merkName || merkNameEdit) {
        if (isEditing) {
          await middleware.put(`products/brands`, {
            primary_key: brandEditData.primary_key,
            name: merkNameEdit,
            description: descriptionEdit,
            // keywords: keywordsEdit,
          });

          AddedOrEditedBrand();
        } else {
          // Create new brand
          await middleware.post("products/brands", {
            name: merkName,
            description: description,
            // keywords: keywords,
          })
          
          AddedOrEditedBrand();
        }
      }
      setMerkName("");
      setDescription("");
      // setKeywords("");
      setMerkNameEdit("");
      setDescriptionEdit("");
      // setKeywordsEdit("");
      setIsEditing(false);
    } catch (err) {
      console.error(err);
    }
  };
  
  return (
    <div>
      <Modal
        id="modalBlueprint"
        show={brandNew}
        onHide={() => setBrandNew(false)}
        centered
        size="xl"
        backdrop="static"
      >
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className="">Voeg een merk toe</Modal.Title>
          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={() => setBrandNew(false)}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
          <div className="contendModal w-100">
            <div className='mb-2'>
              <BorderedTextInput
                name="merkName"
                label={"Merk naam*"}
                inputValue={merkName}
                setInputValue={setMerkName}
              />
            </div>
            <div className='mb-2'>
              <BorderedTextInput
                name="merkDescription"
                label={"Beschrijving"}
                inputValue={description}
                setInputValue={setDescription}
              />
            </div>
            {/* <div>
              <BorderedTextInput
                name="merkKeywords"
                label={"Trefwoorden"}
                inputValue={keywords}
                setInputValue={setKeywords}
              />
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom d-flex flex-row">
          <ButtonLightGrey
            text={"Annuleren"}
            onClickFunction={() => setBrandNew(false)}
          />
          <ButtonBlue
            text={"Voeg toe"}
            onClickFunction={() => {
              submitNewBrand();
              setBrandNew(false)
            }}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        id="modalEditBrand"
        show={brandEdit}
        onHide={() => setBrandEdit(false)}
        centered
        size="xl"
        backdrop="static"
      >
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className="">Bewerken</Modal.Title>
          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={() => setBrandEdit(false)}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
          <div className="contendModal w-100">
            <div className='mb-2'>
              <BorderedTextInput
                name="merkName"
                label={"Merk naam*"}
                inputValue={merkNameEdit}
                setInputValue={setMerkNameEdit}
              />
            </div>
            <div className='mb-2'>
              <BorderedTextInput
                name="merkDescription"
                label={"Beschrijving"}
                inputValue={descriptionEdit}
                setInputValue={setDescriptionEdit}
              />
            </div>
            {/* <div>
              <BorderedTextInput
                name="merkKeywords"
                label={"Trefwoorden"}
                inputValue={keywordsEdit}
                setInputValue={setKeywordsEdit}
              />
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom d-flex flex-row">
          <ButtonBlue
            text={"Bewerk"}
            onClickFunction={() => {
              submitNewBrand();
              setBrandEdit(false)
            }}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BrandModal;
