import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const ToolTip = ({
  message,
  side = "top",
  color = "#57C0EC",
  size = 16,
  className = "",
}) => {
  return (
    <Tippy content={message} placement={side}>
      <FontAwesomeIcon
        width={size}
        icon={faInfoCircle}
        className={`cursor-help ${className}`}
        style={{ color }}
      />
    </Tippy>
  );
};

export default ToolTip;