// edit
import React, { useEffect, useState, useMemo, useCallback } from "react";
import middleware from "../../../../Api/Middleware";
import TablePreset from "../../../../Table/TablePreset";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faRotate, faBarcode } from "@fortawesome/free-solid-svg-icons";
import { useTable } from "react-table";
import { useNavigate, useParams } from "react-router-dom";
import ToastError from "../../../../Toasts/ToastError";
import { logDOM } from "@testing-library/react";
import { BarcodeIcon, Newspaper } from "lucide-react";
import barcodeIcon from "../../../../Main/Icons/BarcodeIcon.png";
import CurrencyInput from "react-currency-input-field";
import { set, size } from "lodash";
import { isValid } from "date-fns";
import {
  BorderedMoneyInput,
  BorderedNumberInput,
  BorderedSquareMoneyInput,
  BorderedSquareNumberInput,
} from "../../../../HouseStyle/Components/Inputs";

const PriceComp = ({ finalPrice }) => {
  // check if finalprice is in correct format and a positve number
  if (
    finalPrice != null &&
    finalPrice != 0 &&
    !isNaN(finalPrice) &&
    Number(finalPrice) > 0
  ) {
    return (
      <span className="align-self-center">= € {finalPrice.toFixed(2)}</span>
    );
  } else if (finalPrice == 0 || Number(finalPrice) < 0) {
    return <span className="align-self-center">= € 0,00</span>;
  } else {
    return <span className="align-self-center">= € -</span>;
  }
};

const BoxVariation = (props) => {
  // console.log(props);

  const [addVariation, setAddVariation] = useState({
    size: "",
    color: "",
  });
  const [sizeVariations, setSizeVariations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [variations, setVariations] = useState([]);
  const [colorVariations, setColorVariations] = useState([]);
  const [selectedVariationIds, setSelectedVariationIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [sizeFilter, setSizeFilter] = useState("");
  const [colorFilter, setColorFilter] = useState("");
  const [variationPrice, setVariationPrice] = useState({});
  const [focusedInput, setFocusedInput] = useState(null);
  const [finalPrice, setFinalPrice] = useState([{}]);
  const [isExecuted, setIsExecuted] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [updateTabel, setUpdateTabel] = useState(false);
  const [sizeVariation, setSizeVariation] = useState(null);
  const [colorVariation, setColorVariation] = useState(null);
  const [lastUpdatedVariation, setLastUpdatedVariation] = useState(null);
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  });

  useEffect(() => {
    // invalid prices
    const invalidValues = [null, 0, "", " ", "0", "0.00"];
    const isValid = (price) => !invalidValues.includes(price);

    // get baseprice (sale price if filled in)
    let basePrice;
    if (
      isValid(
        props.prices.find((item) => item.type === "sale_price")?.incl_tax ??
          null
      )
    ) {
      basePrice = props.prices
        ? Number(
            props.prices.find((item) => item.type === "sale_price")?.incl_tax
          )
        : 0;
    } else {
      basePrice = props.prices
        ? Number(props.prices.find((item) => item.type === "price")?.incl_tax)
        : 0;
    }
    // loop trough all variations
    props.allVariations.forEach((variation) => {
      // set + or - operator button
      // execute only 1 time when the page is loaded
      if (!isExecuted) {
        const varPrice = variation.prices.find(
          (item) => item.type === "price"
        )?.incl_tax;
        if (Number(varPrice) > Number(basePrice)) {
          props.setAllVariations((prev) =>
            prev.map((item) =>
              item.id === variation.id
                ? { ...item, variation_operator: "+" }
                : item
            )
          );
          const diff = Number(varPrice) - Number(basePrice);
          setVariationPrice((prev) => ({
            ...prev,
            [variation.id]: diff.toFixed(2),
          }));
        } else if (Number(varPrice) < Number(basePrice)) {
          props.setAllVariations((prev) =>
            prev.map((item) =>
              item.id === variation.id
                ? { ...item, variation_operator: "-" }
                : item
            )
          );
          const diff = Number(basePrice) - Number(varPrice);
          setVariationPrice((prev) => ({
            ...prev,
            [variation.id]: diff.toFixed(2),
          }));
        }
      }
      // Calculate final price for each variation if not updated already
      if (!isUpdated) {
        calculateFinalPrice(variation);
      }
    });
    if (props.allVariations.length > 0) {
      setIsExecuted(true);
    }
    setIsUpdated(false);
  }, [props.allVariations, props.prices]);

  useEffect(() => {
    // Update variation prices when prices are updated (TODO)
  }, [props.prices]);

  useEffect(() => {
    if (
      lastUpdatedVariation &&
      finalPrice[lastUpdatedVariation] !== undefined
    ) {
      // update the price of the variation
      props.setAllVariations((prevVariations) =>
        prevVariations.map((variation) =>
          variation.product_id === lastUpdatedVariation
            ? {
                ...variation,
                prices: variation.prices.map((price) =>
                  price.type === "price"
                    ? { ...price, incl_tax: finalPrice[lastUpdatedVariation] }
                    : price
                ),
              }
            : variation
        )
      );
      setIsUpdated(true);
    }
  }, [finalPrice, lastUpdatedVariation]);

  const updatePagination = useCallback(() => {
    // Filter de variaties op basis van de geselecteerde filters
    const colorFiltered =
      colorFilter !== "" && colorFilter !== null
        ? props.allVariations.filter(
            (e) => e.colors && e.colors[0]?.color_id == colorFilter
          )
        : props.allVariations;

    const sizeFiltered =
      sizeFilter !== "" && sizeFilter !== null
        ? colorFiltered.filter(
            (e) => e.sizes && e.sizes[0]?.size_id == sizeFilter
          )
        : colorFiltered;

    const totalVariations = sizeFiltered.length;

    const { page_size, page } = pagination;
    const maxPage = Math.ceil(totalVariations / page_size);
    const newPage = Math.max(1, page > maxPage ? maxPage : page);

    const begin_of_page = (newPage - 1) * page_size;
    const end_of_page = Math.min(begin_of_page + page_size, totalVariations);

    const paginatedVariations = sizeFiltered.slice(begin_of_page, end_of_page);

    setPagination((prev) => ({
      ...prev,
      max_items: totalVariations,
      page: newPage,
      begin_of_page: totalVariations === 0 ? 0 : begin_of_page + 1,
      end_of_page: end_of_page,
    }));

    setVariations(paginatedVariations);
    setUpdateTabel(true);
  }, [
    props.allVariations,
    colorFilter,
    sizeFilter,
    pagination.page_size,
    pagination.page,
  ]);

  // Update de variaties zodra de pagination verandert
  useEffect(() => {
    updatePagination();
  }, [
    pagination.page_size,
    pagination.page,
    props.allVariations,
    colorFilter,
    sizeFilter,
  ]);

  const generateVariations = (variations = null) => {
    variations = variations ? variations : props.allVariations;
    const totalVariations = variations.length;
    const { page_size, page } = pagination;
    const maxPage = Math.ceil(totalVariations / page_size);
    const newPage = Math.max(1, page > maxPage ? maxPage : page);

    const begin_of_page = (page - 1) * page_size;
    const end_of_page = Math.min(begin_of_page + page_size, totalVariations);

    const paginatedVariations = variations.slice(begin_of_page, end_of_page);

    setVariations(paginatedVariations);
  };

  const handleProductSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedVariationIds([...selectedVariationIds, productId]);
    } else {
      setSelectedVariationIds(
        selectedVariationIds.filter((id) => id !== productId)
      );
    }
  };
  const handleSelection = (id, isSelected) => {
    if (isSelected) {
      setSelectedIds((previous) => [...previous, id]);
    } else {
      setSelectedIds((previous) => previous.filter((id) => id !== id));
    }
  };
  const generate_barcodes = async () => {
    let count = 0;
    const generated_barcodes = await generateMultipleBarcodes(
      selectedVariationIds.length
    );
    // generate barcodes on selected fields without a barcode
    selectedVariationIds.forEach((selectedId) => {
      let vari = props.allVariations.find(
        (variation) => variation.id == selectedId
      );
      if (vari.barcode.barcode == null || vari.barcode.barcode == "") {
        handleBarcodeChange(generated_barcodes[count], vari.id);
      }
      count++;
    });
  };

  const delete_barcodes = () => {
    // delete selected barcodes
    selectedVariationIds.forEach((selectedId) => {
      let vari = props.allVariations.find(
        (variation) => variation.id == selectedId
      );
      if (vari.barcode != null || vari.barcode != "") {
        props.setAllVariations((prevVariations) =>
          prevVariations.map((variation) =>
            variation.id === vari.id
              ? {
                  ...variation,
                  barcode: { ...variation.barcode, barcode: null },
                }
              : variation
          )
        );
        updatePagination();
      }
    });
  };

  const delete_variations = () => {
    props.setAllVariations((previous) =>
      previous.filter(
        (variation) => !selectedVariationIds.includes(variation.id)
      )
    );
    setSizeFilter("");
    setColorFilter("");
    setSelectedVariationIds([]);
  };
  const handleAction = async (event) => {
    if (event === "generate-barcodes") {
      generate_barcodes();
    } else if (event === "delete-barcodes") {
      delete_barcodes();
    } else if (event === "delete") {
      props.setUpdatedVariations(true);
      delete_variations();
    }
  };
  const generateBarcode = async (event, id) => {
    if (event != null) {
      event?.preventDefault();
    }

    const response = await middleware.get("products/generateBarcode");
    const newBarcode = response.data.generatedBarcode;

    handleBarcodeChange(newBarcode, id);
    // Set the pagination so the generated barcode is visible
    updatePagination();
  };

  // Generates multiple barcodes
  const generateMultipleBarcodes = async (amount) => {
    const response = await middleware.get(
      `products/generateMultipleBarcodes?barcodeAmount=${amount}`
    );
    const newBarcodes = response.data.generatedBarcode;
    return newBarcodes;
  };

  const checkBarcodeFormat = (barcode) => {
    if (barcode == null || barcode == "") {
      return true;
    }
    return true;
    // checks removed because of diffrent barcode formats
    // // check correct barcode length
    // if (barcode.length == 13) {
    //   // check if prefix is between 021 - 029
    //   let isCorrectPrefix = true;
    //   const barcodePrefix = parseInt(barcode.toString().slice(0, 3), 10);
    //   isCorrectPrefix = barcodePrefix >= 21 && barcodePrefix <= 29;
    //   if (isCorrectPrefix) {
    //     // Check if checksum is correct
    //     const digits = barcode.toString().split('').map(Number);

    //     const sum = digits
    //       .slice(0, 12) // Get the first 12 digits
    //       .reduce((acc, digit, index) => {
    //         return acc + digit * (index % 2 === 0 ? 1 : 3);
    //       }, 0);

    //     const checksum = (10 - (sum % 10)) % 10;
    //     if (checksum === digits[12]) {
    //       return true;
    //     }
    //     else {
    //       ToastError("Het laatse berekende veld van de barcode (checksum) is niet correct ingevuld");
    //     }
    //   }
    //   else {
    //     ToastError("Eerste 3 getallen van de barcode voldoen niet aan het EAN-13 formaat (tussen 021 en 029)");
    //   }
    // } else {
    //   ToastError("Barcode voldoet niet aan het EAN-13 formaat");
    // }
    // return false;
  };

  const handleFilterChange = (value, field) => {
    if (field === "size") {
      setSizeFilter(value);
    } else if (field === "color") {
      setColorFilter(value);
    }
    checkIfVariationExists(value, field);
  };

  const checkIfVariationExists = (value, field) => {
    const { allVariations, allSizes, allColors } = props;

    if (field === "size") {
      // Als er geen maat in allSizes zit met dezelfde id, toon een alert.
      if (!allSizes.find((size) => size.size_id === value)) {
      }
    } else if (field === "color") {
      // Als er geen kleur in allColors zit met dezelfde id, toon een alert.
      if (!allColors.find((color) => color.color_id === value)) {
      }
    }
  };

  const handleBarcodeChange = async (newBarcode, id) => {
    props.setErrors([]);
    // Check if barcode is valid
    if (newBarcode != "" && newBarcode != null) {
      if (!checkBarcodeFormat(newBarcode)) {
        props.setErrors([...props.errors, id]);
      }
    } else if (newBarcode == "" || newBarcode == null) {
      props.setErrors([...props.errors, id]);
    }
    if (
      newBarcode !=
        props.allVariations.find((variation) => variation.id === id)?.barcode ??
      null
    ) {
      props.setUpdatedVariations(true);
    }
    props.setAllVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.id === id
          ? {
              ...variation,
              barcode: { ...variation.barcode, barcode: newBarcode },
            }
          : variation
      )
    );
    // Rerender variations
    updatePagination();
  };

  const handleStockChange = (newStock, id) => {
    // set the updated stock to true if the field changeds
    if (
      newStock !=
        props.allVariations.find((variation) => variation.id === id)?.stock ??
      null
    ) {
      props.setUpdatedVariations(true);
    }
    props.setAllVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.id === id
          ? { ...variation, stock: { amount: newStock } }
          : variation
      )
    );
  };

  const handleVariationPriceChange = (
    value,
    variationId,
    variation_operator
  ) => {
    let price = value;
    if (price != null && price !== 0 && price !== "") {
      price = price.toString().replace(",", ".").replace("€", "");
    }

    variation_operator =
      variation_operator === "-" ? (price = -price) : (price = price);

    props.setAllVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.id === variationId
          ? { ...variation, variation_price: price }
          : variation
      )
    );

    const variation = props.allVariations.find((v) => v.id === variationId);
    if (variation) {
      calculateFinalPrice(variation);
    }

    props.setUpdatedVariations(true);
  };

  const calculateWithoutTax = (price) => {
    // calculate price without tax
    if (price != null && price != 0) {
      return Number(price) / 1.21; //make it correct
    }
  };

  const calculateFinalPrice = (variation) => {
    const invalidValues = [null, 0, "", " ", "0", "0.00"];
    const isValid = (price) => !invalidValues.includes(price);
    let basePrice;
    if (
      isValid(
        props.prices.find((item) => item.type === "sale_price")?.incl_tax ??
          null
      )
    ) {
      basePrice = props.prices
        ? Number(
            props.prices.find((item) => item.type === "sale_price")?.incl_tax
          )
        : 0;
    } else {
      basePrice = props.prices
        ? Number(props.prices.find((item) => item.type === "price")?.incl_tax)
        : 0;
    }
    // Use variation.id for finalPrice keys

    if (variation.variation_operator === "+" && variation.variation_price > 0) {
      setFinalPrice((prev) => ({
        ...prev,
        [variation.id]:
          Number(basePrice) + Number(variation.variation_price || 0),
      }));
    } else if (variation.variation_operator === "-") {
      let calcPrice =
        Number(basePrice) + Number(variation.variation_price || 0);
      if (calcPrice < 0) {
        variation.variation_operator = "+"; // zorgt ervoor dat de operator weer op + komt te staan

        let size = variation.sizes[0]?.main ?? null;
        let color = variation.colors[0]?.name ?? null;
        let sizeColorMessage =
          size && color ? `${size} en ${color}` : size || color || "";

        ToastError(
          `Variatie (${sizeColorMessage}) mag niet lager zijn dan € ${basePrice}.`,
          "VariationPriceError"
        );
        return;
      }
      setFinalPrice((prev) => ({ ...prev, [variation.id]: calcPrice }));
    } else {
      // console.log("No operator found");
    }
  };

  const onPriceButtonClicked = (variationId, operator) => {
    const invalidValues = [null, 0, "", " ", "0", "0.00"];
    const isValid = (price) => !invalidValues.includes(price);
    let basePrice;

    const variationOperator =
      props.allVariations.find((v) => v.id === variationId)
        ?.variation_operator || "";

    let variationPrice =
      props.allVariations.find((v) => v.id === variationId)?.variation_price ||
      "";

    // if the variationOperator is the same it wont do anything
    if (variationOperator === operator) {
      return;
    } else {
      // sets the variation to negative if the operator is -
      if (variationOperator === "-") {
        variationPrice = -variationPrice;
      } else {
        variationPrice = -Math.abs(Number(variationPrice));
      }
      // Triggers calculate Function so it gets updated correctly
      calculateFinalPrice(variationId);
    }

    props.setAllVariations((prev) =>
      prev.map((item) =>
        item.id === variationId
          ? { ...item, variation_operator: operator }
          : item
      )
    );

    props.setAllVariations((prev) =>
      prev.map((item) =>
        item.id === variationId
          ? { ...item, variation_price: variationPrice }
          : item
      )
    );

    if (
      isValid(
        props.prices.find((item) => item.type === "sale_price")?.incl_tax ??
          null
      )
    ) {
      basePrice = props.prices
        ? Number(
            props.prices.find((item) => item.type === "sale_price")?.incl_tax
          )
        : 0;
    } else {
      basePrice = props.prices
        ? Number(props.prices.find((item) => item.type === "price")?.incl_tax)
        : 0;
    }

    const currentVar = props.allVariations.find(
      (variation) => variation.id === variationId
    );
    if (props.edit && currentVar?.variation_operator !== operator) {
      props.setUpdatedVariations(true);
    }
  };

  // table
  const columns = useMemo(() => {
    const cols = [
      {
        Header: (
          <>
            <label className="checkbox-container">
              <input
                type="checkbox"
                onChange={(e) => {
                  const checked = e.target.checked;
                  setSelectedVariationIds(
                    checked ? variations.map((v) => v.id) : []
                  );
                }}
                checked={selectedVariationIds.length > 0}
              />
              <span className="checkmark"></span>
            </label>
          </>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          const isSelected = selectedVariationIds.includes(row.original.id);
          return (
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={isSelected}
                onChange={(e) =>
                  handleProductSelection(row.original.id, e.target.checked)
                }
              />
              <span className="checkmark"></span>
            </label>
          );
        },
      },
      {
        Header: "Voorraad",
        accessor: "stock",
        Cell: ({ row }) => {
          const currentVariation =
            props.allVariations.find((v) => v.id === row.original.id) || {};

          const stockValue = currentVariation.stock?.amount ?? "";
          const [localStock, setLocalStock] = useState(stockValue);

          const updateStock = () => {
            handleStockChange(localStock, row.original.id);
          };
          return (
            <div
              className="d-flex justify-content-center"
              style={{ width: "80px" }}
            >
              <BorderedSquareNumberInput
                size="l"
                placeholder="0"
                inputValue={localStock}
                setInputValue={setLocalStock}
                updateFunction={updateStock}
              />
            </div>
          );
        },
      },
      {
        Header: <div className="text-center">Barcode</div>,
        accessor: "barcode",
        Cell: ({ row }) => {
          const currentVariation =
            props.allVariations.find((v) => v.id === row.original.id) || {};

          const barcodeValue = currentVariation.barcode?.barcode || "";
          const [localBarcode, setLocalBarcode] = useState(barcodeValue);

          const updateBarcode = () => {
            handleBarcodeChange(localBarcode, row.original.id);
          };

          return (
            <div className="d-flex flex-row gap-2">
              <div>
                <BorderedSquareNumberInput
                  size="l"
                  placeholder="Barcode (ISBN, etc.)"
                  inputValue={localBarcode}
                  setInputValue={setLocalBarcode}
                  updateFunction={updateBarcode}
                />
              </div>
              <div
                className="d-flex justify-content-center align-items-center generateBarcodeBtn"
                onClick={(e) => generateBarcode(e, row.original.id)}
              >
                <FontAwesomeIcon icon={faBarcode} />
              </div>
            </div>
          );
        },
      },
      {
        Header: (
          <div className="d-flex justify-content-center">Variabele prijs</div>
        ),
        accessor: "incl_tax",
        Cell: ({ row }) => {
          // Gets the current variation
          const currentVariation =
            props.allVariations.find((v) => v.id === row.original.id) || {};

          const priceValue = currentVariation.variation_price || 0.0;
          const [localPrice, setlocalPrice] = useState(priceValue);

          const updatePrice = () => {
            handleVariationPriceChange(localPrice, row.original.id);
          };

          let variationPrice = localPrice;

          // makes it so the - wont be shown inside the input
          variationPrice = variationPrice.toString().replace("-", "");
          variationPrice = parseFloat(variationPrice);

          const variationOperator = currentVariation.variation_operator || "";

          // console.log(variationOperator);

          const invalidValues = [null, 0, "", " ", "0", "0.00"];
          const isValid = (price) => !invalidValues.includes(price);
          let basePrice;
          if (
            isValid(
              props.prices.find((item) => item.type === "sale_price")
                ?.incl_tax ?? null
            )
          ) {
            basePrice = props.prices
              ? Number(
                  props.prices.find((item) => item.type === "sale_price")
                    ?.incl_tax
                )
              : 0;
          } else {
            basePrice = props.prices
              ? Number(
                  props.prices.find((item) => item.type === "price")?.incl_tax
                )
              : 0;
          }

          return (
            <div className="d-flex justify-content-center gap-1">
              <div className="d-flex justify-content-center flex-row">
                <div
                  className={
                    row.original.variation_operator === "+"
                      ? "VariationPriceBtnPlusSelected"
                      : "VariationPriceBtnPlus"
                  }
                  onClick={() => onPriceButtonClicked(row.original.id, "+")}
                >
                  +
                </div>
                <div
                  className={
                    row.original.variation_operator === "-"
                      ? "VariationPriceBtnMinusSelected"
                      : "VariationPriceBtnMinus"
                  }
                  onClick={() => onPriceButtonClicked(row.original.id, "-")}
                >
                  -
                </div>
              </div>

              <BorderedSquareMoneyInput
                size="l"
                placeholder="0,00"
                restrictMinus={true}
                decimalsLimit={2}
                inputValue={variationPrice}
                setInputValue={setlocalPrice}
                updateFunction={updatePrice}
              />
            </div>
          );
        },
      },
      {
        accessor: "finalPrice",
        Cell: ({ row }) => {
          return (
            <div>
              <PriceComp finalPrice={finalPrice[row.original.id]} />
            </div>
          );
        },
      },
    ];

    // Conditionally add a column for color if any variation has colors
    if (props.allVariations.find((e) => e.colors && e.colors.length !== 0)) {
      cols.splice(1, 0, {
        Header: (
          <select
            style={{ width: "130px" }}
            value={colorFilter}
            onChange={(e) => handleFilterChange(e.target.value, "color")}
            className="smallStandardInput fw-bold standardDropdownArrow"
          >
            <option value="">Filter kleur</option>
            {props.allColors
              .filter((e) =>
                props.allVariations.find((b) => b.color_name === e.name)
              )
              .map((e, index) => (
                <option key={index} value={e.color_id}>
                  {e.name}
                </option>
              ))}
          </select>
        ),
        accessor: "color_name",
        Cell: ({ row }) => {
          return row.original.colors.length > 0 &&
            row.original.colors[0]?.name ? (
            <span className="d-flex flex-row">
              <div
                className="rounded-circle me-2 mt-1 colorCircle"
                style={{
                  backgroundColor: row.original.colors[0]?.hex,
                  width: "15px",
                  height: "15px",
                }}
              ></div>
              <div
                className="text-nowrap colorNameVariationTable"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title={row.original.colors[0].name}
              >
                {row.original.colors[0].name}
              </div>
            </span>
          ) : (
            ""
          );
        },
      });
    }

    // Conditionally add a column for size if any variation has sizes
    if (props.allVariations.find((e) => e.sizes && e.sizes.length !== 0)) {
      cols.splice(
        props.allVariations.find((e) => e.colors && e.colors.length !== 0)
          ? 2
          : 1,
        0,
        {
          Header: (
            <select
              style={{ width: "120px" }}
              value={sizeFilter}
              onChange={(e) => handleFilterChange(e.target.value, "size")}
              className="smallStandardInput fw-bold standardDropdownArrow"
            >
              <option value="">Filter maat</option>
              {props.allSizes
                .filter((e) =>
                  props.allVariations.find(
                    (b) =>
                      b.sizes &&
                      b.sizes.length > 0 &&
                      b.sizes[0].main === e.main
                  )
                )
                .map((e, index) => (
                  <option key={index} value={e.size_id}>
                    {e.main}
                  </option>
                ))}
            </select>
          ),
          accessor: "size_name",
          Cell: ({ row }) => (
            <span
              className="d-flex justify-content-center"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title={row.original.sizes[0]?.main ?? ""}
            >
              {row.original.sizes[0]?.main ?? row.original.sizes[0]?.name ?? ""}
            </span>
          ),
        }
      );
    }
    return cols;
  }, [
    selectedVariationIds,
    pagination,
    props.errors,
    colorFilter,
    sizeFilter,
    focusedInput,
    variationPrice,
    finalPrice,
  ]);

  const tableInstance = useTable({ columns, data: variations });

  const handleNewVariation = async () => {
    const size = props.allSizes.find(
      (size) => size.primary_key === Number(addVariation.size)
    );
    const color = props.allColors.find(
      (color) => color.primary_key === Number(addVariation.color)
    );
    if (!size && !color) {
      ToastError("Je moet een maat of kleur selecteren");
      return;
    }
    if (
      props.allVariations.find(
        (e) =>
          e.colors[0]?.color_id === color?.color_id &&
          e.sizes[0]?.size_id === size?.size_id
      )
    ) {
      ToastError("Die variatie bestaat al");
      return;
    }
    const response = await middleware.get("products/generateBarcode");
    const newBarcode = response.data.generatedBarcode;
    const newVariation = {
      id: props.allVariations.length + 1,
      size: size?.size_id ?? null,
      size_name: size?.main ?? null,
      color: color?.color_id ?? null,
      color_name: color?.name ?? null,
      colors: color
        ? [
            {
              color_id: color?.color_id ?? null,
              name: color?.name ?? null,
              hex: color?.hex ?? null,
            },
          ]
        : [],
      sizes: size
        ? [
            {
              size_id: size?.size_id ?? null,
              name: size?.main ?? null,
            },
          ]
        : [],
      hex: color?.hex ?? null,
      barcode: newBarcode,
      prices: [{ type: "price", incl_tax: 0 }],
      stock: { amount: 0 },
      barcode: { barcode: newBarcode },
      variation_operator: "+",
      variation_price: 0.0,
    };
    if (size) {
      setSizeVariations([size, ...sizeVariations]);
    }
    if (color) {
      setColorVariations([color, ...colorVariations]);
    }
    props.setAllVariations([newVariation, ...props.allVariations]);
    props.setUpdatedVariations(true);
  };

  return (
    <>
      <div className="whiteBox p-4 formVariationProduct mb-4">
        <h5 className="fw-semibold">Huidige variaties</h5>
        <div className="widthColorTable">
          {isLoading ? (
            <TablePreset
              data={[
                {
                  selectedIds: selectedIds,
                  tableInstance: tableInstance,
                  pagination: pagination,
                },
              ]}
              tableLoading={false}
              setPagination={(e) => setPagination(e)}
              handleSelection={handleSelection}
              actionOptions={[
                {
                  title: "Genereer barcodes",
                  function: () => handleAction("generate-barcodes"),
                },
                {
                  title: "Verwijder barcodes",
                  function: () => handleAction("delete-barcodes"),
                },
                {
                  title: "Verwijder",
                  function: () => handleAction("delete"),
                },
              ]}
              rightDropdowns={[
                {
                  placeholder: "Selecteer een maat",
                  options: props.allSizes.map((size) => ({
                    title: size.main,
                    value: size.primary_key,
                    onClick: () =>
                      setAddVariation((prev) => ({
                        ...prev,
                        size: size.primary_key,
                      })),
                  })),
                  selectedValue: addVariation.size,
                },
                {
                  placeholder: "Selecteer een kleur",
                  options: props.allColors.map((color) => ({
                    title: color.name,
                    value: color.primary_key,
                    onClick: () =>
                      setAddVariation((prev) => ({
                        ...prev,
                        color: color.primary_key,
                      })),
                  })),
                  selectedValue: addVariation.color,
                },
              ]}
              rightButtons={[
                { title: "Variatie aanmaken", function: handleNewVariation },
              ]}
            />
          ) : (
            <div className="table-container table-responsive">
              <table>
                <thead>
                  <tr>
                    <td>
                      <div>Loading</div>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BoxVariation;
