import React, { useState, useEffect } from "react";
import { Button, Modal, Toast } from "react-bootstrap";
import warning from "../Icons/Warning-sign.svg";
import ToastWarning from "../../../Toasts/ToastWarning";
import ToastError from "../../../Toasts/ToastError";
import middleware from "../../../Api/Middleware";
import RandExp from "randexp";
import { BorderedTextInput } from "../../../HouseStyle/Components/Inputs";
import { BorderedDropDown } from "../../../HouseStyle/Components/DropDowns";
import {
  ButtonBlue,
  ButtonLightGrey,
} from "../../../HouseStyle/Components/Buttons";

const ConvertToBusiness = ({
  openConvertToBusiness,
  closeConvertToBusiness,
  onConvertConfirmed,
}) => {
  const [showConvertToBusiness, setShowConvertToBusiness] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const [formData, setFormData] = useState({
    companyName: "",
    companyNumber: "",
    vatNumber: "",
    countryCodeId: "",
  });
  const [btwPlaceholder, setBtwPlaceholder] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [countries, setCountries] = useState([]);

  const vatRegexPatterns = {
    AT: /^ATU\d{8}$/, // Austria
    BE: /^BE0\d{9}$/, // Belgium
    BG: /^BG\d{9,10}$/, // Bulgaria
    CY: /^CY\d{8}[A-Z]$/, // Cyprus
    CZ: /^CZ\d{8,10}$/, // Czech Republic
    DE: /^DE\d{9}$/, // Germany
    DK: /^DK\d{8}$/, // Denmark
    EE: /^EE\d{9}$/, // Estonia
    EL: /^EL\d{9}$/, // Greece
    ES: /^ES[A-Z0-9]\d{7}[A-Z0-9]$/, // Spain
    FI: /^FI\d{8}$/, // Finland
    FR: /^FR[A-Z0-9]{2}\d{9}$/, // France
    GB: /^GB(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/, // United Kingdom
    HR: /^HR\d{11}$/, // Croatia
    HU: /^HU\d{8}$/, // Hungary
    IE: /^IE\d[A-Z0-9+*]\d{5}[A-Z]$/, // Ireland
    IT: /^IT\d{11}$/, // Italy
    LT: /^LT(\d{9}|\d{12})$/, // Lithuania
    LU: /^LU\d{8}$/, // Luxembourg
    LV: /^LV\d{11}$/, // Latvia
    MT: /^MT\d{8}$/, // Malta
    NL: /^NL\d{9}B\d{2}$/, // Netherlands
    PL: /^PL\d{10}$/, // Poland
    PT: /^PT\d{9}$/, // Portugal
    RO: /^RO\d{2,10}$/, // Romania
    SE: /^SE\d{12}$/, // Sweden
    SI: /^SI\d{8}$/, // Slovenia
    SK: /^SK\d{10}$/, // Slovakia
  };

  useEffect(() => {
    if (selectedCountry != "") {
      let t = countries.find(
        (e) => e.country_code_id == selectedCountry
      ).country_code_2;
      let regex = vatRegexPatterns[t];
      const randexp = new RandExp(regex);
      setBtwPlaceholder(randexp.gen());
    }
  }, [selectedCountry]);

  const fetchCountries = async () => {
    const [countryRes] = await Promise.all([middleware.get(`/countryCodes`)]);
    setCountries(countryRes.data.data);
  };
  useEffect(() => {
    if (openConvertToBusiness) {
      fetchCountries();
      setShowConvertToBusiness(true);
      setConfirmationStep(false);
    }
  }, [openConvertToBusiness]);

  const clearFormFields = () => {
    setFormData({});
    setSelectedCountry([]);
  };

  const closeConvertToBusinessModal = () => {
    setShowConvertToBusiness(false);
    clearFormFields();
    closeConvertToBusiness();
  };

  const handleSetValue = (value, name) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const isValidForm = () => {
    const vatRegex = vatRegexPatterns[selectedCountry.country_code_2];

    if (formData.companyNumber.length > 12) {
      ToastError("KVK nummer mag niet langer zijn dan 12 nummers");
    } else if (
      formData.vatNumber &&
      vatRegex &&
      !vatRegex.test(formData.vatNumber)
    ) {
      ToastError(
        `Ongeldig BTW-nummer formaat voor ${selectedCountry.country}.`
      );
      return;
    } else if (
      formData.companyName &&
      formData.companyNumber &&
      formData.countryCodeId
    ) {
      return true;
    } else {
      ToastWarning("Vul alle velden in om de conversie te voltooien.");
    }
  };

  const handleConfirm = async () => {
    if (confirmationStep) {
      await onConvertConfirmed(formData);
      closeConvertToBusinessModal();
    } else {
      // Controleer of alle velden zijn ingevuld voordat je doorgaat

      if (isValidForm()) {
        setConfirmationStep(true);
      }
    }
  };

  console.log(selectedCountry);
  console.log(formData);
  return (
    <Modal
      id="modalBlueprint"
      show={showConvertToBusiness}
      onHide={closeConvertToBusinessModal}
      className="colormodals"
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header
        closeButton
        className="modalTop justify-content-end"
        style={{ height: "60px" }}
      ></Modal.Header>
      <Modal.Body className="modalCenter d-flex justify-content-center">
        {!confirmationStep ? (
          <div className="d-flex flex-column gap-4 justify-content-center w-100">
            <h3>Vul ontbrekende info in om te converteren naar bedrijf</h3>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-column">
                <BorderedTextInput
                  label={"Bedrijfs naam"}
                  size="l"
                  inputValue={formData.companyName}
                  setInputValue={(value) =>
                    handleSetValue(value, "companyName")
                  }
                />
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex">
                  <BorderedDropDown
                    size="s"
                    label={"Land"}
                    placeholder="Kies een land"
                    selectedValue={formData.countryCodeId}
                    setSelectedValue={(value) =>
                      setFormData((prev) => {
                        setSelectedCountry(value);
                        return {
                          ...prev,
                          countryCodeId: value,
                        };
                      })
                    }
                    options={
                      countries && countries?.length > 0
                        ? countries.map((country) => {
                          return {
                            key: country.country_code_id,
                            value: country.country_code_id,
                            title: country.country,
                          };
                        })
                        : []
                    }
                  />
                </div>
              </div>
              <div className="d-flex flex-column">
                <BorderedTextInput
                  label={"Kvk nummer"}
                  size="l"
                  inputValue={formData.companyNumber}
                  setInputValue={(value) =>
                    handleSetValue(value, "companyNumber")
                  }
                />
              </div>
              <div className="d-flex flex-column">
                <BorderedTextInput
                  label={"BTW nummer"}
                  size="l"
                  placeholder={"NL123456789B12"}
                  inputValue={formData.vatNumber}
                  setInputValue={(value) => handleSetValue(value, "vatNumber")}
                />
              </div>
            </div>
            <div className="d-flex">
              <ButtonBlue
                text={"Bevestigen"}
                size="s"
                onClickFunction={handleConfirm}
              />
              {/* <button
              className="btn lightBlueStandardButton"
              onClick={handleConfirm}
            >
              Bevestigen
            </button> */}
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center">
            <img
              style={{ width: "75px" }}
              className=" d-flex align-self-center mb-4"
              src={warning}
              alt="Warning"
            />
            <div className="w-100 d-flex flex-column justify-content-center mb-3">
              <h3 className="text-center">
                Weet je zeker dat je deze consumer wilt veranderen naar een
                bedrijf?
              </h3>
              <h3 className="text-center text-danger">
                Dit kan niet ongedaan gemaakt worden.
              </h3>
            </div>
            <div
              className="d-flex gap-4 justify-content-between align-self-center"
              style={{ width: "60%" }}
            >
              <ButtonLightGrey
                size="l"
                text={"Annuleren"}
                onClickFunction={closeConvertToBusinessModal}
              />
              <ButtonBlue
                size="l"
                text={"Ja, ik weet het zeker"}
                onClickFunction={handleConfirm}
              />
              {/* <button
                style={{ padding: "5px 15px" }}
                className="btn lightBlueStandardButton"
                onClick={handleConfirm}
              >
                Ja, ik weet het zeker
              </button> */}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="modalBottom"></Modal.Footer>
    </Modal>
  );
};

export default ConvertToBusiness;
