import React from 'react';
import * as Icons from "../Icons";
import SizeClassCorrespondance from './SizeClassCorrespondance';

export const ButtonBlue = ({text, textStyling = "WhiteText", onClickFunction, icon = null, secondIcon = null, iconPlacement = "left", disabled = false, size = "regular"}) => {
  return (
    <button disabled={disabled} className={`ButtonBlue ${textStyling} ${SizeClassCorrespondance[size]}`} type="button" onClick={onClickFunction}>
        {(icon && (iconPlacement === "left" || iconPlacement === "both")) && 
          <img src={icon} className="ButtonComponentImageStyling" alt="icon-left"/>
        }
        {text}
        {(secondIcon && (iconPlacement === "right" || iconPlacement === "both")) ? (
          <img src={secondIcon} className="ButtonComponentImageStyling" alt="second-icon"/>
        ) : (
          (icon && (iconPlacement === "right" || iconPlacement == "both")) && 
          <img src={icon} className="ButtonComponentImageStyling" alt="icon-right"/>
        )}
    </button>
  );
}

export const ButtonDarkBlue = ({text, textStyling = "WhiteText", onClickFunction, icon = null, secondIcon = null, iconPlacement = "left", disabled = false, size = "regular"}) => {
  return (
    <button disabled={disabled} className={`ButtonDarkBlue ${textStyling} ${SizeClassCorrespondance[size]}`} type="button" onClick={onClickFunction}>
      {(icon && (iconPlacement === "left" || iconPlacement === "both")) && 
        <img src={icon} className="ButtonComponentImageStyling" alt="icon-left"/>
      }
      {text}
      {(secondIcon && (iconPlacement === "right" || iconPlacement === "both")) ? (
        <img src={secondIcon} className="ButtonComponentImageStyling" alt="second-icon"/>
      ) : (
        (icon && (iconPlacement === "right" || iconPlacement == "both")) && 
        <img src={icon} className="ButtonComponentImageStyling" alt="icon-right"/>
      )}
    </button>
  )
}

export const ButtonLightGrey = ({text, textStyling = "BlackText", onClickFunction, icon = null, secondIcon = null, iconPlacement = "left", disabled = false, size = "regular"}) => {
  return (
    <button disabled={disabled} className={`ButtonLightGrey ${textStyling} ${SizeClassCorrespondance[size]}`} type="button" onClick={onClickFunction}>
      {(icon && (iconPlacement === "left" || iconPlacement === "both")) && 
        <img src={icon} className="ButtonComponentImageStyling" alt="icon-left"/>
      }
      {text}
      {(secondIcon && (iconPlacement === "right" || iconPlacement === "both")) ? (
        <img src={secondIcon} className="ButtonComponentImageStyling" alt="second-icon"/>
      ) : (
        (icon && (iconPlacement === "right" || iconPlacement == "both")) && 
        <img src={icon} className="ButtonComponentImageStyling" alt="icon-right"/>
      )}
    </button>
  )
}

export const ButtonWhite = ({text, textStyling = "BlackText", onClickFunction, icon = null, secondIcon = null, iconPlacement = "left", disabled = false, size = "regular"}) => {
  return (
    <button disabled={disabled} className={`ButtonWhite ${textStyling} ${SizeClassCorrespondance[size]}`} type="button" onClick={onClickFunction}>
      {(icon && (iconPlacement === "left" || iconPlacement === "both")) && 
        <img src={icon} className="ButtonComponentImageStyling" alt="icon-left"/>
      }
      {text}
      {(secondIcon && (iconPlacement === "right" || iconPlacement === "both")) ? (
        <img src={secondIcon} className="ButtonComponentImageStyling" alt="second-icon"/>
      ) : (
        (icon && (iconPlacement === "right" || iconPlacement == "both")) && 
        <img src={icon} className="ButtonComponentImageStyling" alt="icon-right"/>
      )}
    </button>
  )
}

export const BackButton = ({text, textStyling = "BlackText", onClickFunction, disabled = false, size = "regular"}) => {
  return (
    <button disabled={disabled} className={`ButtonBack`} type="button" onClick={onClickFunction}>
        <img src={Icons.ChevronLeftCircleDarkBlue} className="ButtonComponentImageStyling BackIcon"/><div className={textStyling}>{text}</div>
    </button>
  )
}