import React, { useState, useEffect, useMemo, useCallback } from "react";
import middleware from "../Api/Middleware";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import "../Main/Main.css";
import "./ColorSettings.css";
import Loading from "../LoadingScreen/LoadingScreen";
import * as Icons from "../HouseStyle/Icons";
import TableDrag from "../Table/TableDrag/TableDrag";
import { Modal } from "react-bootstrap";
import ModalColorAdd from "../Products/subpages/sub_components/VariationModals";
import PageTemplate from "../Templates/PageTemplate";
import { faChevronLeft, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TablePreset from "../Table/TablePreset";
import CheckboxHeader from "../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../Table/TableComponents/CheckboxCell";
import SortableHeader from "../Table/TableComponents/SortableHeader";
import { useTable } from "react-table";
import ConfirmationDeactivationModal from "../Modals/GeneralSettings/ConfirmationDeactivationModal";

function ColorSettings() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [show, setShow] = useState(false);
  const [selectedColorIds, setSelectedColorIds] = useState([]); // usestate for the selected checkboxes
  const [loading, setLoading] = useState(true); // useState for page loading
  const [closeModal, setCloseModal] = useState();
  const [addNewColor, setAddNewColor] = useState(false);
  const [editColor, setEditColor] = useState();  
  const [searchengine, setSearchengine] = useState("");
  const [timerId, setTimerId] = useState(null);
  const [searchBarInput, setSearchBarInput] = useState("");
  const [colors, setColors] = useState([]); // useState for all products in the database
  const [showEditColorModal, setShowEditColorModal] = useState(false);
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  }); // the useState for the pagination for the table

  const [selectedIds, setSelectedIds] = useState([]);
  const [order, setOrder] = useState({ orderBy: '', direction: '' }); // useState for ordering the columns in the table
  const [searchBar, setSearchBar] = useState("");

  // Sizes usetates

  // handles to close the de-activate modal
  const handleClose = () => setShow(false);

  // fetched all the products and the stock an the colors from the database
  const fetchAll = async () => {
    try {
      setLoading(true);
      const searchQuery = searchBar.trim() ? `&searchTerm=${encodeURIComponent(searchBar.trim())}&noSearch=["primary_key","version","color_id","status","updated_at","created_at","updator_user_id","updator_user_id"]` : "";
      const [fetchColors] = await Promise.all([
        middleware.get(`sp/colorsTableAll?page=${pagination.page}&page_size=${pagination.page_size}${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}` : ''}${searchQuery}`),
      ]);
      setColors(fetchColors.data.data); // sets the colors usestate with the fetched colors
      setPagination({
        ...pagination,
        max_items: fetchColors.data.total,
        begin_of_page: fetchColors.data.from,
        end_of_page: fetchColors.data.to,
      }); // sets the pagination usestate with the max items begin of the page and the end of the page
      setLoadingScreenFunc();
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };

  // function that does an api call to only get the colors the user searched for and updates whenever the colorsearchengine changes
  useEffect(() => {
    try {
      if (searchBar.trim() === "" && searchBar === "") {
        // Code to fetch all when searchengine is empty
        fetchAll();
      } else if (searchBar.trim() !== "") {
        fetchAll();
      } 
    } catch (error) {
    if (error.response.status === 401) {
      cookies.remove("token", { path: "/" });
      navigate("/");
    }
  }
    
}, [searchBar]);


  // useEffect that fires every time the pagination.page_size or pagination.page changes and on every fire it uses the fetchall function
  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page, order]);

  // function that first sets the set loading to fals and than setloading screen to give everything time to load in
  function setLoadingScreenFunc() {
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }

  // a function that delays something for 0,5sec
  function delayedFunction(Func) {
    setTimeout(() => {
      Func();
    }, 500);
  }

  const handleSearchChange = useCallback((value) => {
    setSearchBar(value);
    setPagination(prev => ({ ...prev, page: 1 }));
  }, []);
  

  const columns = useMemo(
    () => {
      let columnsArray = [
        {
          Header: (
            <CheckboxHeader currentItem={colors} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'primary_key'}>
            </CheckboxHeader>
          ),
          accessor: "select",
          Cell: ({ row }) => {
            return <CheckboxCell row={row} selectedIds={selectedIds} pk={'primary_key'} />
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field="name" pos={'start'}>
              Kleur naam
            </SortableHeader>
          ),
          accessor: "name",
          Cell: ({ row }) => {
            return <div className="text-start FlatTextLink" onClick={() => {setEditColor(row.original); setShowEditColorModal(true);}}>{row.original.name}</div>;
          }
        },

        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field="hex" pos={'start'}>
              Kleur
            </SortableHeader>
          ),
          accessor: "hex",
          Cell: ({ row }) => {
            return <div style={{width: "100%",height: "20px",backgroundColor: row.original.hex, border: "1px solid #000",}}></div>;
          }
        },
      ];
      return columnsArray;
    },
    [order.orderBy, order.direction, selectedIds, setSelectedIds, colors]
  );

  //   Table instance
  const tableInstance = useTable({ columns, data: colors });

  // function that suspends colors the are selected
  const suspend = async () => {
    // Iterate over each product id in the selectedColorIds array
    let bulkData = [];
    selectedColorIds.map(async (data) => {
      bulkData.push({
        primary_key: data,
        status: 1,
        sorting_order: null,
      });
    });

    await middleware.put("products/colors", {
      bulk_data: bulkData,
    });

    // After updating all products, call fetchAll function to get updated list of products
    await fetchAll();
  };
  
  const handleSelection = (sizeId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, sizeId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== sizeId));
    }
  };
  // const handleModal1Open = () => setShowBlueprintModal(true);

  // closes the editmodal
  const closingModal = () => {
    setShowEditColorModal(false);
  };

  const closeColorEditModal = (e) => {
    setShowEditColorModal(e);
  };
  

  const deactivateColors = async () => {
    try {
      const promises = selectedIds.map(id => 
        middleware.put(`products/colors`, {
        primary_key: id,
        status: 0,
      }));
      await Promise.all(promises);
      setSelectedIds([]);
      // Optionally, you can refresh the data or perform other actions here
      fetchAll();
    } catch (error) {
      console.error("Error deactivating sizes:", error);
    }
  }

  return (
    <>
      <ModalColorAdd
        handleClose={closingModal}
        openEditModal={showEditColorModal}
        editColor={editColor}
        callColors={false}
        closeEditModal={(e) => closeColorEditModal(e)}
        openAddColor={(e) => setAddNewColor(e)}
        addNewColor={addNewColor}
        redirect={true}
        updatedOrAdded={() => fetchAll()}
      />
      <ConfirmationDeactivationModal showConfirmaton={show} setShowConfirmation={(e) => setShow(e)} selectedIds={selectedIds} handleDeativation={() => deactivateColors()}/>
      <PageTemplate navbarTitle={"Kleuren Overzicht"}  backValue={"/warehouse/instellingen"}>
        <div className="w-100">
          <div className="d-flex justify-content-between flex-column mb-3 ">
            <div className=" d-flex justify-content-start w-100 flex-wrap">
              <div className="w-100 ">
                <TablePreset
                  data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'primary_key'}]}
                  tableLoading={loading}
                  actionOptions={[{title: "Deactiveren", function: () => setShow(true)}]
                  }
                  searchBar={[{ shown: true, searchFunction: handleSearchChange, }]}
                  handleSelection={handleSelection}
                  setPagination={setPagination}
                  // rightDropdowns={[{
                  //     placeholder: 'Filter op categorie',
                  //     options: [{title: "Optie1"}, {title: "Optie2"}]
                  // }]}
                  leftButtons={[]}
                  rightButtons={[{ title: 'Nieuwe kleur', function: () => setAddNewColor(true) }]}
                />
              </div>
            </div>
          </div>
        </div>
        
      </PageTemplate>
    </>
  );
}

export default ColorSettings;