import React, { useEffect, useState, useRef, forwardRef } from 'react'
import * as Icons from "../Icons.js";
import SizeClassCorrespondance from './SizeClassCorrespondance'

const formatNumberWithThousandSeparator = (number) => {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const RegularTextInput = ({
  inputValue,
  setInputValue,
  placeholder = "",
  label = null,
  size = "regular",
  updateFunction,
  updateWaitTimer = 500,
  error = false,
  errorTooltip = false,
}) => {
  const [internalValue, setInternalValue] = useState('');
  
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;

  const timerRef = useRef(null);
  const prevInputValue = useRef(value);
  const isUpdateCalled = useRef(false);

  useEffect(() => {
    if (value !== prevInputValue.current) {
      clearTimeout(timerRef.current);
      isUpdateCalled.current = false;

      timerRef.current = setTimeout(() => {
        if (!isUpdateCalled.current && updateFunction) {
          updateFunction();
          isUpdateCalled.current = true;
        }
      }, updateWaitTimer);

      prevInputValue.current = value;
    }

    return () => clearTimeout(timerRef.current);
  }, [value, updateFunction]);

  const handleBlur = () => {
    if (!isUpdateCalled.current && updateFunction) {
      updateFunction();
      isUpdateCalled.current = true;
    }
    clearTimeout(timerRef.current);
  };

  return (
    <div className={`TextInputWrapper ${SizeClassCorrespondance[size]}`}>
      {label && <label>{label}</label>}
      <div className="input-container">
        <input
          className={`RegularTextInput ${error ? "error" : ""}`}
          autoComplete='off'
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
        />
        {error && errorTooltip && <span className="tooltip">{errorTooltip}</span>}
      </div>
    </div>
  );
};

export const BorderedTextInput = forwardRef(({
  inputValue,
  setInputValue,
  required = false,
  placeholder = "",
  label = null,
  size = "regular",
  updateFunction,
  updateWaitTimer = 500,
  prefixLabel = null, // Optioneel label voor de prefix
  textBoxInfront = "", // Optioneel prefix-element (bijv. 'TXT')
  buttonLabel = "", // Label voor de knop
  disabled = false, // Of de knop uitgeschakeld moet zijn
  IconButton = null, // Standaard icoon voor de knop
  IconButtonHover = null, // Icoon voor hover-toestand
  error = false,
  errorTooltip = false,
}, ref) => {
  const [internalValue, setInternalValue] = useState('');
  const [isHovered, setIsHovered] = useState(false); // Voor hover-effect op de knop
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;

  const timerRef = useRef(null);
  const prevInputValue = useRef(value);
  const isUpdateCalled = useRef(false);

  useEffect(() => {
    if (value !== prevInputValue.current) {
      clearTimeout(timerRef.current);
      isUpdateCalled.current = false;

      timerRef.current = setTimeout(() => {
        if (!isUpdateCalled.current && updateFunction) {
          updateFunction();
          isUpdateCalled.current = true;
        }
      }, updateWaitTimer);

      prevInputValue.current = value;
    }

    return () => clearTimeout(timerRef.current);
  }, [value, updateFunction]);

  const handleBlur = () => {
    if (!isUpdateCalled.current && updateFunction) {
      updateFunction();
      isUpdateCalled.current = true;
    }
    clearTimeout(timerRef.current);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    alert("Tekst gekopieerd!"); // Optionele feedback
  };

  return (
    <div className={`TextInputWrapper ${SizeClassCorrespondance[size]}`}>
      {(prefixLabel || label) && (
        <div className="d-flex flex-row">
          {prefixLabel ? (
            <label style={{ width: "90px" }} className="pe-3 text-center">
              {prefixLabel}
            </label>
          ) : (
            textBoxInfront && <div style={{ width: "90px" }} className="pe-3"></div>
          )}
          {label && <label>{label}</label>}
        </div>
      )}
      <div className="input-container d-flex">
        {textBoxInfront && <span className="Prefix">{textBoxInfront}</span>}
        <input
          className={`BorderedTextInput ${error ? "error" : ""} ${buttonLabel ? "buttonBorderedRight" : ""} ${textBoxInfront ? "buttonBorderedLeft" : ""}`}
          autoComplete='off'
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
          ref={ref}
          disabled={disabled}
        />
        {error && errorTooltip && <span className="tooltip">{errorTooltip}</span>}
        {(buttonLabel || IconButton) && (
          <button
            className="CopyButton"
            onClick={handleCopy}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            disabled={disabled}
          >
            {IconButton != null ? 
              (<img
                src={
                  disabled
                    ? "" // Toon een uitgeschakeld icoon
                    : isHovered
                    ? IconButtonHover || Icons.Copy // Toon hover-icoon
                    : IconButton || Icons.CopyBlue // Toon standaard icoon
                }
                style={{ paddingRight: "5px", height: "17px", width: "17px" }}
                alt="Copy Icon"
              />) 
              : 
              ""
            }
            {buttonLabel}
          </button>
        )}
      </div>
    </div>
  );
});


export const BorderedTextInputWithButton = forwardRef(({
  inputValue,
  setInputValue,
  placeholder = "",
  prefixLabel = null, // Label above the prefix
  inputLabel = null, // Label above the input
  size = "regular",
  updateFunction,
  updateWaitTimer = 500,
  textBoxInfront = false,
  buttonLabel = "Kopiëren",
  disabled = false, // Prop to disable the button
  IconButton = Icons.CopyBlue, // Default icon
  IconButtonHover = Icons.Copy, // Hover icon
}, ref) => {
  const [internalValue, setInternalValue] = useState('');
  const [isHovered, setIsHovered] = useState(false); // State for hover effect
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;

  const timerRef = useRef(null);
  const prevInputValue = useRef(value);
  const isUpdateCalled = useRef(false);

  useEffect(() => {
    if (value !== prevInputValue.current) {
      clearTimeout(timerRef.current);
      isUpdateCalled.current = false;

      timerRef.current = setTimeout(() => {
        if (!isUpdateCalled.current && updateFunction) {
          updateFunction();
          isUpdateCalled.current = true;
        }
      }, updateWaitTimer);

      prevInputValue.current = value;
    }

    return () => clearTimeout(timerRef.current);
  }, [value, updateFunction]);

  const handleBlur = () => {
    if (!isUpdateCalled.current && updateFunction) {
      updateFunction();
      isUpdateCalled.current = true;
    }
    clearTimeout(timerRef.current);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    alert("Tekst gekopieerd!"); // Optional feedback
  };

  return (
    <div className={`TextInputWrapper ${SizeClassCorrespondance[size]}`}>
      {/* Label above the prefix */}
      
      <div className='d-flex flex-row'>
        {prefixLabel ? <label style={{width: "90px"}} className='pe-3 text-center'>{prefixLabel}</label> : <div  style={{width: "90px"}} className='pe-3'></div>}
        {inputLabel ? <label>{inputLabel}</label> : <div />}
      </div>
      <div className="InputContainer">
        {textBoxInfront && <span className="Prefix">TXT</span>}
        {/* Label above the input */}
        <input
          className="BorderedTextInputWithButton"
          autoComplete='off'
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
          ref={ref}
        />

        <button
          className="CopyButton"
          onClick={handleCopy}
          onMouseEnter={() => setIsHovered(true)} // Hover start
          onMouseLeave={() => setIsHovered(false)} // Hover end
          disabled={disabled} // Disable button when disabled is true
        >
          <img
            src={
              disabled
                ? "" // Show disabled icon
                : isHovered
                ? IconButtonHover // Show hover icon
                : IconButton // Show default icon
            }
            style={{ paddingRight: "5px", height: "17px", width: "17px" }}
            alt="Copy Icon"
          />
          Kopiëren
        </button>
      </div>
    </div>
  );
});

export const RegularMoneyInput = ({
  inputValue,
  setInputValue,
  placeholder = "0,00",
  label = null,
  size = "regular",
  maxAmount = null,
  minAmount = null,
  restrictMinus = true,
  updateFunction,
  updateWaitTimer = 500
}) => {
  const [internalValue, setInternalValue] = useState('');
  
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;

  const timerRef = useRef(null);
  const isUpdateCalled = useRef(false);
  const prevInputValue = useRef(value);
  const inputId = `regular-money-input-${label ? label.replace(/\s+/g, '-').toLowerCase() : 'default'}`;
  const num = value ? parseFloat(value.toString().replace(',', '.')) : 0;
  const isInvalid =
    (minAmount !== null && num < minAmount) ||
    (maxAmount !== null && num > maxAmount);

  const validateMinMax = () => {
    if (isNaN(num)) {
      setValue('');
    } else {
      if (minAmount !== null && num < minAmount) setValue(minAmount.toString());
      if (maxAmount !== null && num > maxAmount) setValue(maxAmount.toString());
    }
  }
  
  useEffect(() => {
    if (value !== prevInputValue.current) {
      clearTimeout(timerRef.current);
      isUpdateCalled.current = false;

      timerRef.current = setTimeout(() => {
        if (!isUpdateCalled.current && updateFunction) {
          updateFunction();
          isUpdateCalled.current = true;
        }
      }, updateWaitTimer);

      prevInputValue.current = value;
    }

    return () => clearTimeout(timerRef.current);
  }, [value, updateFunction]);

  const handleBlur = () => {
    if (!isUpdateCalled.current && updateFunction) {
      updateFunction();
      isUpdateCalled.current = true;
    }
    clearTimeout(timerRef.current);
    validateMinMax();
  };

  const handleChange = (e) => {
    let val = e.target.value.replace(/e/gi, '');
    const regex = restrictMinus ? /^[0-9.,]*$/ : /^-?[0-9.,]*$/;
    if (regex.test(val)) {
      setValue(val);
    }
  };

  return (
    <div className={`MoneyInputWrapper ${value != '' ? "filled" : "empty"} ${SizeClassCorrespondance[size]}`}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <div className="input-with-symbol">
        <input
          id={inputId}
          className={`RegularMoneyInput ${isInvalid ? 'RedText' : ""}`}
          autoComplete='off'
          type="text"
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (['e', 'E'].includes(e.key)) e.preventDefault();
            if (e.key === '-' && (restrictMinus || e.target.value.includes('-') || e.target.selectionStart !== 0)) e.preventDefault();
          }}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label={label || 'Money input'}
          inputMode="decimal"
        />
      </div>
    </div>
  );
};

export const BorderedMoneyInput = ({
  placeholder = "0,00",
  label = null,
  size = "regular",
  maxAmount = null,
  minAmount = null,
  restrictMinus = true,
  inputValue = null,
  setInputValue = null,
  updateFunction,
  updateWaitTimer = 500
}) => {
  const [internalValue, setInternalValue] = useState('');
  
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;
  const timerRef = useRef(null);
  const isUpdateCalled = useRef(false);
  const prevInputValue = useRef(value);
  const inputId = `regular-money-input-${label ? label.replace(/\s+/g, '-').toLowerCase() : 'default'}`;
  const num = value ? parseFloat(value.toString().replace(',', '.')) : 0;
  const isInvalid =
    (minAmount !== null && num < minAmount) ||
    (maxAmount !== null && num > maxAmount);

  const validateMinMax = () => {
    if (isNaN(num)) {
      setValue('');
    } else {
      if (minAmount !== null && num < minAmount) setValue(minAmount.toString());
      if (maxAmount !== null && num > maxAmount) setValue(maxAmount.toString());
    }
  }
  
  useEffect(() => {
    if (value !== prevInputValue.current) {
      clearTimeout(timerRef.current);
      isUpdateCalled.current = false;

      timerRef.current = setTimeout(() => {
        if (!isUpdateCalled.current && updateFunction) {
          updateFunction();
          isUpdateCalled.current = true;
        }
      }, updateWaitTimer);

      prevInputValue.current = value;
    }

    return () => clearTimeout(timerRef.current);
  }, [value, updateFunction]);

  const handleBlur = () => {
    if (!isUpdateCalled.current && updateFunction) {
      updateFunction();
      isUpdateCalled.current = true;
    }
    clearTimeout(timerRef.current);
    validateMinMax();
  };

  const handleChange = (e) => {
    let val = e.target.value.replace(/e/gi, '');
    const regex = restrictMinus ? /^[0-9.,]*$/ : /^-?[0-9.,]*$/;
    if (regex.test(val)) {
      setValue(val);
    }
  };

  return (
    <div className={`MoneyInputWrapper ${value != '' ? "filled" : "empty"} ${SizeClassCorrespondance[size]}`}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <div className="input-with-symbol">
        <input
          id={inputId}
          className={`BorderedMoneyInput ${isInvalid ? 'RedText' : ""}`}
          autoComplete='off'
          type="text"
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (['e', 'E'].includes(e.key)) e.preventDefault();
            if (e.key === '-' && (restrictMinus || e.target.value.includes('-') || e.target.selectionStart !== 0)) e.preventDefault();
          }}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label={label || 'Money input'}
          inputMode="decimal"
        />
      </div>
    </div>
  );
};

export const RegularSquareMoneyInput = ({
  placeholder = "0,00",
  label = null,
  size = "regular",
  maxAmount = null,
  minAmount = null,
  restrictMinus = true,
  inputValue = null,
  setInputValue = null
}) => {
  const [internalValue, setInternalValue] = useState('');
  
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;

  const timerRef = useRef(null);
  const inputId = `regular-money-input-${label ? label.replace(/\s+/g, '-').toLowerCase() : 'default'}`;
  const num = value ? parseFloat(value.toString().replace(',', '.')) : 0;
  const isInvalid =
    (minAmount !== null && num < minAmount) ||
    (maxAmount !== null && num > maxAmount);

  const validateMinMax = () => {
    if (isNaN(num)) {
      setValue('');
    } else {
      if (minAmount !== null && num < minAmount) setValue(minAmount.toString());
      if (maxAmount !== null && num > maxAmount) setValue(maxAmount.toString());
    }
  }
  
  useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      validateMinMax();
    }, 2000);
    return () => clearTimeout(timerRef.current);
  }, [value, minAmount, maxAmount]);

  const handleChange = (e) => {
    let val = e.target.value.replace(/e/gi, '');
    const regex = restrictMinus ? /^[0-9.,]*$/ : /^-?[0-9.,]*$/;
    if (regex.test(val)) {
      setValue(val);
    }
  };

  return (
    <div className={`MoneyInputWrapper ${value != '' ? "filled" : "empty"} ${SizeClassCorrespondance[size]}`}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <div className="input-with-symbol">
        <input
          id={inputId}
          className={`RegularSquareMoneyInput ${isInvalid ? 'RedText' : ""}`}
          autoComplete='off'
          type="text"
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (['e', 'E'].includes(e.key)) e.preventDefault();
            if (e.key === '-' && (restrictMinus || e.target.value.includes('-') || e.target.selectionStart !== 0)) e.preventDefault();
          }}
          value={value}
          onChange={handleChange}
          onBlur={() => validateMinMax()}
          aria-label={label || 'Money input'}
          inputMode="decimal"
        />
      </div>
    </div>
  );
};

export const BorderedSquareMoneyInput = ({
  placeholder = "0,00",
  label = null,
  size = "regular",
  maxAmount = null,
  minAmount = null,
  restrictMinus = true,
  inputValue = null,
  setInputValue = null,
  updateFunction,
  updateWaitTimer = 500
}) => {
  const [internalValue, setInternalValue] = useState('');
  
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;
  const timerRef = useRef(null);
  const isUpdateCalled = useRef(false);
  const prevInputValue = useRef(value);
  const inputId = `regular-money-input-${label ? label.replace(/\s+/g, '-').toLowerCase() : 'default'}`;
  const num = value ? parseFloat(value.toString().replace(',', '.')) : 0;
  const isInvalid =
    (minAmount !== null && num < minAmount) ||
    (maxAmount !== null && num > maxAmount);

  const validateMinMax = () => {
    if (isNaN(num)) {
      setValue('');
    } else {
      if (minAmount !== null && num < minAmount) setValue(minAmount.toString());
      if (maxAmount !== null && num > maxAmount) setValue(maxAmount.toString());
    }
  }
  
  useEffect(() => {
    if (value !== prevInputValue.current) {
      clearTimeout(timerRef.current);
      isUpdateCalled.current = false;

      timerRef.current = setTimeout(() => {
        if (!isUpdateCalled.current && updateFunction) {
          updateFunction();
          isUpdateCalled.current = true;
        }
      }, updateWaitTimer);

      prevInputValue.current = value;
    }

    return () => clearTimeout(timerRef.current);
  }, [value, updateFunction]);



  return (
    <div className={`MoneyInputWrapper ${value != '' ? "filled" : "empty"} ${SizeClassCorrespondance[size]}`}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <div className="input-with-symbol">
        <input
          id={inputId}
          className={`BorderedSquareMoneyInput ${isInvalid ? 'RedText' : ""}`}
          autoComplete='off'
          type="text"
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (['e', 'E'].includes(e.key)) e.preventDefault();
            if (e.key === '-' && (restrictMinus || e.target.value.includes('-') || e.target.selectionStart !== 0)) e.preventDefault();
          }}
          value={value}
          onChange={(e) => {
            let val = e.target.value.replace(/e/gi, '');
            const regex = restrictMinus ? /^[0-9.,]*$/ : /^-?[0-9.,]*$/;
            if (regex.test(val)) setValue(val);
          }}
          onBlur={() => validateMinMax()}
          aria-label={label || 'Money input'}
          inputMode="decimal"
        />
      </div>
    </div>
  );
};

export const RegularNumberInput = forwardRef(({
  inputValue,
  setInputValue,
  placeholder = 0,
  label = null,
  size = "regular",
  updateFunction,
  updateWaitTimer = 500,
  decimal = false,
  thousandSeparator = false,
  error = false,
  errorTooltip = false,
},ref) => {
  const [internalValue, setInternalValue] = useState('');
  
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;

  const timerRef = useRef(null);
  const prevInputValue = useRef(value);
  const isUpdateCalled = useRef(false);

  useEffect(() => {
      if (value !== prevInputValue.current) {
        clearTimeout(timerRef.current);
        isUpdateCalled.current = false;
  
        if (updateWaitTimer !== null) {
          timerRef.current = setTimeout(() => {
            if (!isUpdateCalled.current) {
              formatValue();
              if (updateFunction) {
                updateFunction();
                isUpdateCalled.current = true;
              }
            }
          }, updateWaitTimer);
        }
        prevInputValue.current = value;
      }
  
      return () => clearTimeout(timerRef.current);
  }, [value, updateFunction]);

  const handleBlur = () => {
    if (!isUpdateCalled.current) {
      formatValue();
      if (updateFunction) {
        updateFunction();
        isUpdateCalled.current = true;
      }
    }
    clearTimeout(timerRef.current);
  };

  const handleChange = (e) => {
    const input = e.target;
    if(input.value !== '') {
      const cursorPosition = input.selectionStart;
      let val = input.value.replace(/[a-zA-Z]/g, '');
      const regex = decimal && thousandSeparator ? /^[\d.,]+$/ :
                    decimal ? /^\d*[\.,]?\d*$/ :
                    thousandSeparator ? /^\d*\.?\d*$/ :
                    /^\d*$/;

      if (regex.test(val)) {
        setValue(val);
        // Attempt to restore caret position immediately (may be adjusted after formatting)
        setTimeout(() => {
          input.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
      }
    } else {
      setValue('');
    }
  };

  const handlePaste = (e) => {
    let paste = (e.clipboardData || window.clipboardData).getData('text');
    const regex = decimal ? /^\d*[\.,]?\d*$/ : /^\d*$/;
    if (!regex.test(paste)) {
      e.preventDefault();
    }
  };

  const formatValue = () => {
    if (thousandSeparator) {
      const parts = value.split(',');
      const integerPart = parts[0].replace(/\D/g, '');
      const decimalPart = parts[1] ? parts[1].replace(/\D/g, '') : '';
      let formattedValue = formatNumberWithThousandSeparator(integerPart);
      if (decimal && decimalPart) {
        formattedValue += ',' + decimalPart;
      }
      setValue(formattedValue);
    }
  };

  const formatNumberWithThousandSeparator = (number) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <div className={`TextInputWrapper ${SizeClassCorrespondance[size]}`}>
      {label && <label>{label}</label>}
      <div className="input-container">
        <input
          className={`BorderedTextInput ${error ? "error" : ""}`}
          autoComplete="off"
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
          ref={ref}
        />
        {/* Tooltip only when there's an error */}
        {error && errorTooltip && <span className="tooltip">{errorTooltip}</span>}
      </div>
    </div>
  );
});

export const BorderedNumberInput = forwardRef(({
  inputValue,
  setInputValue,
  placeholder = 0,
  label = null,
  size = "regular",
  maxAmount = null,
  minAmount = null,
  updateFunction,
  updateWaitTimer = 500,
  decimal = false,
  thousandSeparator = false,
  error = false,
  errorTooltip = false,
}, ref) => {
  const [internalValue, setInternalValue] = useState('');
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;

  const timerRef = useRef(null);
  const prevInputValue = useRef(value);
  const isUpdateCalled = useRef(false);

  const formatForDisplay = (numStr) => {
    if (!numStr) return '';

    let [integerPart, decimalPart] = numStr.toString().replace('.', ',').split(',');

    if (thousandSeparator) {
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    return decimalPart !== undefined ? `${integerPart},${decimalPart}` : integerPart;
  };

  const cleanForStorage = (numStr, isFinal = false) => {
    if (!numStr) return '';

    // Remove thousand separators and replace , with . (only if final value)
    let cleaned = numStr.toString().replace(/\./g, '').replace(',', '.');

    // If it's a temporary value (during typing), allow a trailing comma
    if (!isFinal && numStr.endsWith(',')) {
      return cleaned.replace('.', ',');
    }

    return cleaned;
  };

  const handleChange = (e) => {
    let val = e.target.value;

    // Allow numbers and a single comma
    val = val.replace(/[^0-9,]/g, '');

    // Ensure only one comma is allowed
    if (decimal) {
      const parts = val.split(',');
      if (parts.length > 2) {
        val = parts[0] + ',' + parts.slice(1).join('');
      }
    }

    setValue(cleanForStorage(val, false)); // Don't fully convert yet
  };

  const handleBlur = () => {
    if (!isUpdateCalled.current) {
      updateFunction?.();
      isUpdateCalled.current = true;
    }
    clearTimeout(timerRef.current);
    validateMinMax();
  };

  const handlePaste = (e) => {
    let paste = (e.clipboardData || window.clipboardData).getData('text');
    paste = paste.replace(/[^0-9,]/g, '');
    setValue(cleanForStorage(paste, false));
    e.preventDefault();
  };

  const validateMinMax = () => {
    let num = parseFloat(cleanForStorage(value, true)) || 0;
    if (isNaN(num)) return setValue('');
    num = Math.min(Math.max(num, minAmount ?? num), maxAmount ?? num);
    setValue(num.toString());
  };

  useEffect(() => {
    if (value !== prevInputValue.current) {
      clearTimeout(timerRef.current);
      isUpdateCalled.current = false;

      if (updateWaitTimer !== null) {
        timerRef.current = setTimeout(() => {
          updateFunction?.();
          isUpdateCalled.current = true;
        }, updateWaitTimer);
      }
      prevInputValue.current = value;
    }

    return () => clearTimeout(timerRef.current);
  }, [value]);

  return (
    <div className={`TextInputWrapper ${SizeClassCorrespondance[size]}`}>
      {label && <label>{label}</label>}
      <div className="input-container">
        <input
          className={`BorderedTextInput ${error ? "error RedText" : ""}`}
          autoComplete="off"
          type="text"
          placeholder={placeholder}
          value={formatForDisplay(value)}
          onChange={handleChange}
          onBlur={handleBlur}
          onPaste={handlePaste}
          onKeyDown={(e) => e.key === 'Enter' && handleBlur()}
          aria-label={label || "Number Input"}
          ref={ref}
        />
        {error && errorTooltip && <span className="tooltip">{errorTooltip}</span>}
      </div>
    </div>
  );
});



export const RegularSquareNumberInput = forwardRef(({
  inputValue,
  setInputValue,
  placeholder = 0,
  label = null,
  size = "regular",
  updateFunction,
  updateWaitTimer = null,
  decimal = false,
  thousandSeparator = false,
  error = false
},ref) => {
  const [internalValue, setInternalValue] = useState('');
  
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;

  const timerRef = useRef(null);
  const prevInputValue = useRef(value);
  const isUpdateCalled = useRef(false);

  useEffect(() => {
      if (value !== prevInputValue.current) {
        clearTimeout(timerRef.current);
        isUpdateCalled.current = false;
  
        if (updateWaitTimer !== null) {
          timerRef.current = setTimeout(() => {
            if (!isUpdateCalled.current) {
              formatValue();
              if (updateFunction) {
                updateFunction();
                isUpdateCalled.current = true;
              }
            }
          }, updateWaitTimer);
        }
        prevInputValue.current = value;
      }
  
      return () => clearTimeout(timerRef.current);
  }, [value, updateFunction]);

  const handleBlur = () => {
    if (!isUpdateCalled.current) {
      formatValue();
      if (updateFunction) {
        updateFunction();
        isUpdateCalled.current = true;
      }
    }
    clearTimeout(timerRef.current);
  };

  const handleChange = (e) => {
    const input = e.target;
    if(input.value !== '') {
      const cursorPosition = input.selectionStart;
      let val = input.value.replace(/[a-zA-Z]/g, '');
      const regex = decimal && thousandSeparator ? /^[\d.,]+$/ :
                    decimal ? /^\d*[\.,]?\d*$/ :
                    thousandSeparator ? /^\d*\.?\d*$/ :
                    /^\d*$/;

      if (regex.test(val)) {
        setValue(val);
        // Attempt to restore caret position immediately (may be adjusted after formatting)
        setTimeout(() => {
          input.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
      }
    } else {
      setValue('');
    }
  };

  const handlePaste = (e) => {
    let paste = (e.clipboardData || window.clipboardData).getData('text');
    const regex = decimal ? /^\d*[\.,]?\d*$/ : /^\d*$/;
    if (!regex.test(paste)) {
      e.preventDefault();
    }
  };

  const formatValue = () => {
    if (thousandSeparator) {
      const parts = value.split(',');
      const integerPart = parts[0].replace(/\D/g, '');
      const decimalPart = parts[1] ? parts[1].replace(/\D/g, '') : '';
      let formattedValue = formatNumberWithThousandSeparator(integerPart);
      if (decimal && decimalPart) {
        formattedValue += ',' + decimalPart;
      }
      setValue(formattedValue);
    }
  };

  const formatNumberWithThousandSeparator = (number) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <div className={`TextInputWrapper ${SizeClassCorrespondance[size]}`}>
      {label && <label>{label}</label>}
      <input
        className="RegularSquareNumberInput"
        autoComplete='off'
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onPaste={handlePaste}
        ref={ref}
      />
    </div>
  );
});

export const BorderedSquareNumberInput = forwardRef(({
  inputValue,
  setInputValue,
  placeholder = 0,
  label = null,
  size = "regular",
  updateFunction,
  updateWaitTimer = 500,
  decimal = false,
  thousandSeparator = false,
  error = false
},ref) => {
  const [internalValue, setInternalValue] = useState('');
  
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;

  const timerRef = useRef(null);
  const prevInputValue = useRef(value);
  const isUpdateCalled = useRef(false);

  useEffect(() => {
      if (value !== prevInputValue.current) {
        clearTimeout(timerRef.current);
        isUpdateCalled.current = false;
  
        if (updateWaitTimer !== null) {
          timerRef.current = setTimeout(() => {
            if (!isUpdateCalled.current) {
              formatValue();
              if (updateFunction) {
                updateFunction();
                isUpdateCalled.current = true;
              }
            }
          }, updateWaitTimer);
        }
        prevInputValue.current = value;
      }
  
      return () => clearTimeout(timerRef.current);
  }, [value, updateFunction]);

  const handleBlur = () => {
    if (!isUpdateCalled.current) {
      formatValue();
      if (updateFunction) {
        updateFunction();
        isUpdateCalled.current = true;
      }
    }
    clearTimeout(timerRef.current);
  };

  const handleChange = (e) => {
    const input = e.target;
    if(input.value !== '') {
      const cursorPosition = input.selectionStart;
      let val = input.value.replace(/[a-zA-Z]/g, '');
      const regex = decimal && thousandSeparator ? /^[\d.,]+$/ :
                    decimal ? /^\d*[\.,]?\d*$/ :
                    thousandSeparator ? /^\d*\.?\d*$/ :
                    /^\d*$/;

      if (regex.test(val)) {
        setValue(val);
        // Attempt to restore caret position immediately (may be adjusted after formatting)
        setTimeout(() => {
          input.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
      }
    } else {
      setValue('');
    }
  };

  const handlePaste = (e) => {
    let paste = (e.clipboardData || window.clipboardData).getData('text');
    const regex = decimal ? /^\d*[\.,]?\d*$/ : /^\d*$/;
    if (!regex.test(paste)) {
      e.preventDefault();
    }
  };

  const formatValue = () => {
    if (thousandSeparator) {
      const parts = value.split(',');
      const integerPart = parts[0].replace(/\D/g, '');
      const decimalPart = parts[1] ? parts[1].replace(/\D/g, '') : '';
      let formattedValue = formatNumberWithThousandSeparator(integerPart);
      if (decimal && decimalPart) {
        formattedValue += ',' + decimalPart;
      }
      setValue(formattedValue);
    }
  };

  const formatNumberWithThousandSeparator = (number) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <div className={`TextInputWrapper ${SizeClassCorrespondance[size]}`}>
      {label && <label>{label}</label>}
      <input
        className={`BorderedSquareNumberInput ${error && "error"}`}
        autoComplete='off'
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onPaste={handlePaste}
        ref={ref}
      />
    </div>
  );
});