import axios from "axios";
import Cookies from "universal-cookie";

// Initialize cookies for token management
const cookies = new Cookies();

// Create an Axios instance with a base URL
const middleware = axios.create({
  baseURL: 'https://demo.techdogsoftware.nl/api/'
});

// Request interceptor to add the ApiKey header
middleware.interceptors.request.use(config => {
  const token = cookies.get('token', { secure: false });
  if (token) {
    config.headers.ApiKey = token; // Adds the header to the request if it exists.
  }
  return config;
}, error => {
  // Handle request error
  return Promise.reject(error);
});

// Response interceptor to handle responses
middleware.interceptors.response.use(response => {
  return response;
}, error => {
  if (!error.response) {
    // Check the error message to differentiate between offline (network error) and server down
    window.dispatchEvent(new CustomEvent('network-error'));
  } else if (error.response && error.response.status === 401) {
    // Dispatch a custom event
    window.dispatchEvent(new CustomEvent('unauthorized-event'));
  }
  return Promise.reject(error);
});

export default middleware;