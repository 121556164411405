import Modal from "react-bootstrap/Modal";
import middleware from "../../../Api/Middleware";
import ToastError from "../../../Toasts/ToastError";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { hsvaToRgbString, hsvaToHex } from "@uiw/react-color";
import tinycolor from "tinycolor2";
import { useWindowSize } from "react-use";
import Wheel from "@uiw/react-color-wheel";
import EyeDropper from "./EyeDropper";
import { ButtonBlue } from "../../../HouseStyle/Components/Buttons";
import { BorderedTextInput } from "../../../HouseStyle/Components/Inputs";
import { BorderedDropDown } from "../../../HouseStyle/Components/DropDowns";

const Modals = ({
  show,
  handleClose,
  updatedOrAdded,
  redirect,
  openEditModal,
  closeEditModal,
  editColor,
  callColors,
  openAddColor,
  addNewColor,
}) => {
  const [colors, setColors] = useState([]); // useState for all the colors from the database
  const [sizeBlueprints, setSizeBlueprints] = useState([]);
  const [colorName, setColorName] = useState(""); // usestate for the name of the color the user gives it
  const [colorNameNew, setColorNameNew] = useState(""); // usestate for the name of the color the user gives it
  const [previewColor, setPreviewColor] = useState("#ffffff"); // usestate for the preview of the color so u can see it
  const [latest, setLatest] = useState(""); // usestate to see wich color picking methode whas chosen
  const [hexaValueRecent, setHexaValueRecent] = useState("#ffffff"); // usestate that has the most recent hex value for in the database
  const { width } = useWindowSize();
  const [rangeNumber, setRangeNumber] = useState(100); // usestate for the saturation range
  const [color, setColor] = useState({ h: 0, s: 0, v: 100, a: 0 }); // usestate for the outcome of the colorwheel because it can only accept hsva
  const [colorType, setColorType] = useState("1"); // usestate for picking colortype 1=HEX 2=HSL 3=RGB
  const [colorValue, setColorValue] = useState(null); // usestate for the inputfield with the name colorInput when the color is converted it stops it in the input
  const [rangeColor, setRangeColor] = useState("#ffffff"); // usestate for the hex color with full saturation
  const [showModal1, setShowModal1] = useState(false);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizeBlueprint, setSelectedSizeBlueprint] = useState("");
  const [showModal2, setShowModal2] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [iosDevice, setIosDevice] = useState(false);
  const navigate = useNavigate();

  // a function that fetches the data
  const fetchAll = async () => {
    try {
      if (callColors == false) {
        return;
      }
      // setLoading(true);
      const [fetchColors, fetchSizeBlueprints] = await Promise.all([
        middleware.get("sp/colorsTableAll"),
        middleware.get(`sp/blueprintsTableAll`),
      ]);
      const sortedColors = fetchColors.data.sort((a, b) => a.sorting_order - b.sorting_order); // sort on sorting_order
      setColors(sortedColors); // sets the colors with all the colors in the database
      setSizeBlueprints(fetchSizeBlueprints.data);
    } catch (error) {
      console.log(error);
    }
  };

  // a useEffect that uses the fetchall function
  useEffect(() => {
    fetchAll();
  }, []);

  // a useEffect that fires every time that the show parameter updates to set the set ShowModal1 to the value that show has could be true of false
  useEffect(() => {
    setShowModal1(show);
  }, [show]);

  // shows the edit modal and sets the name input and the value input
  useEffect(() => {
    if (openEditModal) {
      setColorName(editColor.name);
      convertToHex(editColor.hex);
      setShowModalEdit(true);
    }
  }, [openEditModal]);

  // opens the new color modal when addNewColor changes
  useEffect(() => {
    if (addNewColor) {
      handleModal2Open();
    }
  }, [addNewColor]);

  // closes the modal when showModal1 changes
  useEffect(() => {
    if (!showModal1 && show) {
      handleClose();
    }
  }, [showModal1]);

  // use effect that checkes if the latest = eyedropper and wich colortype is selected and based on that it chooses the colortype to convert it to. colortypes: 1=hex  2=hsl 3=rgb
  useEffect(() => {
    let newColorValue;
    if (colorType == 1) {
      newColorValue =
        latest === "eyeDropper" ? hsvaToHex(color) : hsvaToHex(color); // when the colortype is 1 then it converts it to HEX
    } else if (colorType == 2) {
      newColorValue =
        latest === "eyeDropper"
          ? tinycolor(color).toHslString()
          : tinycolor(color).toHslString(); // when the colortype is 2 then it converts it to HSL
    } else if (colorType == 3) {
      newColorValue =
        latest === "eyeDropper"
          ? hsvaToRgbString(color)
          : hsvaToRgbString(color); // when the colortype is 3 then it converts it to RGB
    }

    setColorValue(newColorValue);
  }, []);

  // sets the color input  to the color type chosen in the select and updates when colorType and hexavaluerecent changes colorTypes: 1=hex  2=hsl 3=rgb
  useEffect(() => {
    const color = convertColor(hexaValueRecent);

    switch (colorType) {
      case "1": // Hex color
        setColorValue(color.hex);
        break;
      case "2": // HSL color
        setColorValue(`hsl(${color.hsl})`);
        break;
      case "3": // RGB color
        setColorValue(tinycolor(color.rgb).toRgbString());
        break;
      default: // If the color type is not recognized, return early
        return;
    }
  }, [colorType, hexaValueRecent]);

  useEffect(() => {
    // Check if it's an iOS device
    setIosDevice(isIOSDevice());
  }, []);

  //calculates the size of the window and use it on the color wheel to help responsivenes
  const sizeWindow = Math.min(width * 0.7, 350);

  // handles to close the first modal
  const handleModal1Close = () => setShowModal1(false);

  const isIOSDevice = () => {
    return (
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    );
  };

  // sets the chosen colors in the first modal to the local storage for later usage at the addvariation page
  const addVariation = () => {
    if (
      Object.keys(selectedColors).length !== 0 ||
      selectedSizeBlueprint !== ""
    ) {
      localStorage.setItem(
        "colorsAddVariationCache",
        JSON.stringify(Object.values(selectedColors))
      );

      var sizeBlueprintCache = [];
      const sizeBlueprint = sizeBlueprints.find(
        (sizeBlueprint) =>
          sizeBlueprint.blueprint_id === Number(selectedSizeBlueprint)
      );
      if (sizeBlueprint) {
        const sizeIds = sizeBlueprint.size_ids.split(",");
        const sizeNames = sizeBlueprint.size_names.split(",");

        sizeBlueprintCache = sizeIds.map((id, index) => ({
          id: id.trim(),
          name: sizeNames[index]?.trim() ?? "",
        }));
      }

      localStorage.setItem(
        "sizesAddVariationCache",
        JSON.stringify(sizeBlueprintCache)
      );
      if (redirect) {
        navigate(redirect);
      }
      handleModal1Close();
    } else {
      navigate("/producten/nieuw");
    }
  };

  // handles to close the edit modal
  const handleModalEditClose = () => {
    setShowModalEdit(false);
    closeEditModal(false);
  };

  // handles to close the second modal
  const handleModal2Close = (openFirstModal) => {
    // if openAddColor is true then it closes the second modal
    if (openAddColor) {
      setShowModal2(false); // open the second modal
      openAddColor(false);
    }
    setShowModal2(false);
    // if openFirstModal is not false then it sets showModal1 to true
    if (!openFirstModal) {
      setShowModal1(true);
    }
  };

  // handles to open the color add modal
  const handleModal2Open = () => {
    // when openAddColor is true it open the modal  colorAddModal
    if (openAddColor) {
      setShowModal2(true); // open the second modal
    }
    setShowModal1(false); // close the first modal
    setShowModal2(true); // open the second modal
  };

  // handles to close the sizeModal and opens teh first modal agian
  const handleMaatTableClose = () => {
    setShowModal1(true);
  };

  // handles to open the sizeModal and sets the showModal1 to false
  const handleMaatTableOpen = () => {
    navigate("/warehouse/instellingen/blauwdrukken")
    setShowModal1(false); // close the first modal
  };

  // saves the picked color from the eyeDropper and converts it to an hsva
  const savePickedColor = (newColor) => {
    const hsvColor = tinycolor(newColor).toHsv();
    setLatest("eyeDropper"); // sets the latest to eyedropper
    setHexaValueRecent(newColor); // sets hexaValueRecent to the color picked with with the eyedropper

    setColor({
      h: hsvColor.h,
      s: hsvColor.s * 100,
      v: hsvColor.v * 100,
      a: 1,
    });

    const fullsaturationEyedropper = {
      h: hsvColor.h,
      s: 100,
      v: hsvColor.v * 100,
      a: hsvColor.a,
    };

    setRangeColor(hsvaToHex(fullsaturationEyedropper)); // sets the range color to the picked color but with fullsaturation
  };

  // handles the changes in the color wheel and uses the value of the brightness range
  const handleColorChange = (newColor) => {
    const { h, s, a } = newColor?.hsva ?? {};
    if (h !== undefined && s !== undefined && a !== undefined) {
      const updatedColor = {
        h,
        s,
        v: color.v, // Use the value from the brightness range input
        a,
      };

      // gives the color with full saturation
      const fullSaturationColor = {
        h: updatedColor.h,
        s: 100,
        v: updatedColor.v,
        a: updatedColor.a,
      };

      setRangeColor(hsvaToHex(fullSaturationColor)); // sets the fullsaturation color for the saturation range
      setColor(updatedColor); // sets the updated color in the color usestate
      setLatest("colorCircle");
      setHexaValueRecent(hsvaToHex(updatedColor)); // converts the most recent color to hex and puts it in the usestate HexaValueRecent
      convertToHex(hsvaToHex(updatedColor)); //  converts the updatedColor to HEX so the convertToHex can use it
    }
  };

  //handles the brightness range when u drag it hat the color updates
  const handleBrightnessChange = (event) => {
    const newBrightness = parseInt(event.target.value);
    setColor((prevColor) => {
      const updatedColor = {
        ...prevColor,
        v: newBrightness,
      };

      // create a new color with full saturation
      const fullSaturationColor = {
        ...updatedColor,
        s: 100,
      };

      setRangeColor(hsvaToHex(fullSaturationColor));
      setPreviewColor(hsvaToHex(color)); // convert to HEX format and set as rangeColor
      setHexaValueRecent(`#${tinycolor(updatedColor).toHex()}`);

      return updatedColor;
    }); // always return the new state at the end
  };

  // converts the value give with it to an hex color
  const convertToHex = (value) => {
    const hexValue = "#" + tinycolor(value).toHex(); // converts the color to hex
    let data = convertColor(hexValue); // uses the converted color and gives all color types back
    // setInputValueColorCode(hexValue); // uses the hex color converted earlier to set the inputValueColorCode
    setHexaValueRecent(hexValue); // sets hexaValueRecent with the hex value converted earlier
    setColor(data.hsva); // uses the data that came out of convertColor and used the hsva colortype and sets color with it
    setRangeColor(data.hex); // sets the rangeColor to the updated hex color
    // returning the color data
    return data.hsva;
  };

  // comverts the hexcolor parameter to every colortype tinycolor2 can convert to
  const convertColor = (hexColor) => {
    // converts the hexcolor to every colortype
    let color = tinycolor(hexColor);

    // sets the range number and brightness number to the correct number
    let hsv = color.toHsv();
    setRangeNumber(hsv.s * 100);

    let hsl = color.toHsl();

    let roundedHue = Math.round(hsl.h);

    // returns the hexcolor in  every colortype
    return {
      hex: color.toHexString(),
      hexa: color.toHex8String(),
      hsl: `${roundedHue}, ${Math.round(hsl.s * 100)}, ${Math.round(
        hsl.l * 100
      )}`,
      hsla: `${roundedHue}, ${Math.round(hsl.s * 100)}, ${Math.round(
        hsl.l * 100
      )}, ${hsl.a})`,
      hsv: { h: hsv.h, s: hsv.s * 100, v: hsv.v * 100 },
      hsva: { h: hsv.h, s: hsv.s * 100, v: hsv.v * 100, a: hsv.a },
      rgb: color.toRgb(),
      rgba: color.toRgb(),
    };
  };

  // The main function that is called when there is a change in the input field
  const handleInputChange = () => {
    // Get the current value of the input field
    const inputValue = colorValue;

    let formattedColorCode;
    // Handle the input based on the color type
    switch (colorType) {
      case "1": // Hex color
        if (!handleHexInput(inputValue)) return;
        formattedColorCode = inputValue;
        break;
      case "2": // HSL color
        formattedColorCode = handleHSLInput(inputValue);
        if (!formattedColorCode) return;
        break;
      case "3": // RGB color
        formattedColorCode = handleRGBInput(inputValue);
        if (!formattedColorCode) return;
        break;
      default: // If the color type is not recognized, return early
        return;
    }

    // Update the color-related states
    updateColorStates(formattedColorCode);
  };

  // checks if the inputed value is an hex value
  const validateHex = (inputValue) => {
    try {
      tinycolor(inputValue).toHex();
      return true;
    } catch (error) {
      ToastError(error);
      return false;
    }
  };

  // checks if the value the user put in is a valid Hsl value
  const validateHSL = (inputValue) => {
    try {
      tinycolor(inputValue).toHsl();
      return true;
    } catch (error) {
      ToastError(error);
      return false;
    }
  };

  // checks if the value the user put in is a valid rgb value
  const validateRGB = (inputValue) => {
    try {
      tinycolor(inputValue).toRgb();
      return true;
    } catch (error) {
      ToastError(error);
      return false;
    }
  };

  // Function to handle hex color input
  const handleHexInput = (value) => {
    // If the hex color is not valid, return false
    if (!validateHex(value)) return false;

    // Update the latest input type state
    setLatest("input");

    // Return true if everything is valid
    return true;
  };

  // Function to handle HSL color input
  const handleHSLInput = (value) => {
    // If the HSL color is not valid, return false
    if (!validateHSL(value)) return false;

    // Update the latest input type state
    setLatest("input");

    // Format the color code in HSL format and return
    return `${value}`;
  };

  // Function to handle RGB color input
  const handleRGBInput = (value) => {
    // If the RGB color is not valid, return false
    if (!validateRGB(value)) return false;

    // Update the latest input type state
    setLatest("input");

    // Format the color code in RGB format and return
    return `${value}`;
  };

  // Function to update color related states
  const updateColorStates = async (formattedColorCode) => {
    // Convert the color code to hex format
    const newColor = convertToHex(formattedColorCode);

    // If the conversion failed, return early
    if (!newColor) return;

    // Create a color with full saturation
    const fullSaturationColor = {
      h: newColor.h,
      s: 100,
      v: newColor.v,
      a: newColor.a,
    };
    // Update the rangeColor, previewColor, and colorValue states
    setRangeColor(hsvaToHex(fullSaturationColor));
    setPreviewColor(hsvaToHex(fullSaturationColor));
    setColorValue(formattedColorCode);
  };

  // to sumbit an new color
  const submitNew = async (e) => {
    e.preventDefault();

    try {
      if (colorNameNew && previewColor) {
        await middleware.post("products/colors", {
          name: colorNameNew,
          hex: hexaValueRecent,
        });
        setColorNameNew("");
        openAddColor(false);
        await fetchAll();

        setShowModal2(false);

        if (!addNewColor) {
          setShowModal1(true);
        } else {
          updatedOrAdded();
        }
      } else {
        ToastError(`Vul de kleur titel in`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // to edit an already existing color
  const editNew = async (event) => {
    event.preventDefault();

    try {
      await middleware.put(
        `products/colors?primary_key=${editColor.primary_key}`,
        {
          hex: colorValue,
          name: colorName,
        }
      );

      handleModalEditClose();
      updatedOrAdded();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {/* modalBlueprint */}
      <Modal
        id="modalBlueprint"
        show={showModal1}
        onHide={handleModal1Close}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-between pb-0">
          <h4 className="fw-semibold mb-0">Blauwdruk selecteren</h4>
          <Button variant="transparent" onClick={handleModal1Close}>
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </Button>
        </Modal.Header>
        <Modal.Body className="modalCenter px-4">
          <div className="contendModal w-100 mb-3">
            <div>
              <div className="d-flex ps-2 flex-row pb-2">
                <p className="inputTitle pt-2">Selecteer een maattabel</p>
                <p className="plusStyle" onClick={() => handleMaatTableOpen()}>+</p>
              </div>
              <select
                className="standardInput standardDropdownArrow mb-4 w-100 px-3"
                onChange={(event) =>
                  setSelectedSizeBlueprint(event.target.value)
                }
                value={selectedSizeBlueprint}
              >
                <option value="">Selecteer een maat</option>
                {sizeBlueprints.map((sizeBlueprint, index) => (
                  <option value={sizeBlueprint.blueprint_id} key={index}>
                    {sizeBlueprint.size_names}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <div className="d-flex flex-row ps-2">
                <p className="inputTitle pt-2">Selecteer de kleur</p>
                <p className="plusStyle" onClick={handleModal2Open}>
                  +
                </p>
              </div>
              <div className="whiteBox colorsBox colorGrid">
                {colors.map((color, index) => (
                  <div
                    key={index}
                    className="colorItem colorsHover"
                    title={color.name}
                    onClick={() => {
                      let colorId = color.color_id;
                      if (selectedColors.hasOwnProperty(colorId)) {
                        let newSelectedColors = { ...selectedColors };
                        delete newSelectedColors[colorId];
                        setSelectedColors(newSelectedColors);
                      } else {
                        setSelectedColors({
                          ...selectedColors,
                          [colorId]: {
                            color_id: colorId,
                            name: color.name,
                            hex: color.hex,
                          },
                        });
                      }
                    }}
                    style={
                      selectedColors.hasOwnProperty(color.color_id)
                        ? { backgroundColor: "#c2c2c2" }
                        : {}
                    }
                  >
                    <div className="colorCircleWrapper">
                      <div
                        className="colorCircle rounded-circle"
                        style={{ backgroundColor: color.hex }}
                      ></div>
                    </div>
                    <div className="colorName fw-semibold">{color.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom d-flex justify-content-end">
          <button
            className="btn lightBlueStandardButton border-0 w-auto px-4"
            onClick={addVariation}
          >
            Variabel product aanmaken
          </button>
        </Modal.Footer>
      </Modal>

      {/* add new color modal */}
      <Modal
        show={showModal2}
        onHide={handleModal2Close}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-between">
          <h3 className="mb-3 titleModal flex-wrap fw-semibold">
            Maak een nieuwe kleur
          </h3>
          <Button
            variant="transparent"
            onClick={() => handleModal2Close(addNewColor ?? false)}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </Button>
        </Modal.Header>
        <Modal.Body className="modalCenter px-4">
          <div className="modal-body modalColorBackdrop py-0">
            <div className="whiteBox sizeWhiteBox py-0">
              <div className="d-flex flex-row justify-content-evenly">
                <div className="me-3 mb-sm-3">
                  <Wheel
                    className="d-flex colorwheel"
                    color={color}
                    width={sizeWindow}
                    height={sizeWindow}
                    onChange={handleColorChange}
                  />
                </div>
                <div className="d-flex flex-column me-2 align-self-center">
                  <div className=" d-flex flex-column colorInfo flex-wrap mb-3">
                    <div className="mb-2 w-100">
                      <BorderedTextInput
                        className="optionsList optionListWidth mb-2 text-center pe-2 ps-0"
                        placeholder="Kleur titel"
                        label="Naam"
                        inputValue={colorNameNew ?? ""}
                        setInputValue={setColorNameNew}
                      />
                    </div>
                    <div className="mb-2 w-100">
                      <BorderedDropDown
                          label={"Kleurmodel"}
                          placeholder="BorderedDropDown"
                          options={[
                              { title: "HEX", value: "1"},
                              { title: "HSL", value: "2"},
                              { title: "RGB", value: "3"},
                          ]}
                          selectedValue={colorType}
                          setSelectedValue={setColorType}
                      />  
                    </div>
                    <div className="d-flex flex-column mb-2">
                      <div className="d-flex flex-row">
                        <BorderedTextInput
                          name="colorInput"
                          label={"Kleurcode"}
                          inputValue={colorValue || ""}
                          setInputValue={setColorValue}
                          updateFunction={handleInputChange}
                        />
                        <div
                          className={`${
                            iosDevice ? "d-none" : ""
                          } d-flex align-items-end ms-2 mb-2`}
                        >
                          <EyeDropper onColorPick={savePickedColor} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom d-flex justify-content-end">
          <ButtonBlue onClickFunction={(e) => submitNew(e)} text={"Nieuwe kleur aanmaken"} />  
        </Modal.Footer>
      </Modal>

      {/* edit color modal */}
      <Modal
        show={showModalEdit}
        onHide={handleModalEditClose}
        centered
        size="lg"
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-between">
          <Modal.Title className="ms-3 BlockTitle">Edit kleur</Modal.Title>

          <Button variant="transparent" onClick={handleModalEditClose}>
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </Button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
          <div className="modal-body modalColorBackdrop py-0">
            <div className="whiteBox sizeWhiteBox py-0">
              <div className="d-flex flex-row justify-content-evenly">
                <div className="me-3 mb-sm-3">
                  <Wheel
                    className="d-flex colorwheel"
                    color={color}
                    width={sizeWindow}
                    height={sizeWindow}
                    onChange={handleColorChange}
                  />
                </div>
                <div className="eyeDropper d-flex flex-column me-2 align-self-center">
                  <div className=" d-flex flex-column colorInfo flex-wrap mb-3">
                    <div className="mb-2">
                      <BorderedTextInput
                        className="optionsList optionListWidth mb-2 text-center pe-2 ps-0"
                        placeholder="Kleur titel"
                        label="Naam"
                        inputValue={colorName ?? ""}
                        setInputValue={setColorName}
                      />
                    </div>
                    <div className="mb-2">
                      <BorderedDropDown
                          label={"Kleurmodel"}
                          placeholder="BorderedDropDown"
                          options={[
                              { title: "HEX", value: "1"},
                              { title: "HSL", value: "2"},
                              { title: "RGB", value: "3"},
                          ]}
                          selectedValue={colorType}
                          setSelectedValue={setColorType}
                      />
                    </div>
                    <div className="d-flex flex-column me-2">
                      <div className="d-flex flex-row">
                        <BorderedTextInput
                          name="colorInput"
                          label={"Kleurcode"}
                          inputValue={colorValue || ""}
                          setInputValue={setColorValue}
                          updateFunction={handleInputChange}
                        />
                        <div
                          className={`${
                            iosDevice ? "d-none" : ""
                          } d-flex align-items-end ms-2 mb-2`}
                        >
                          <EyeDropper onColorPick={savePickedColor} />
                        </div>
                      </div>                          
                      <small className="smallText fw-semibold ">
                        {colorType === "1" && "Voorbeeld: #ffffff"}
                        {colorType === "2" && "Voorbeeld: hsl(0, 0%, 100%)"}
                        {colorType === "3" && "Voorbeeld: rgb(255, 255, 255)"}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom d-flex justify-content-end">
          <ButtonBlue onClickFunction={(e) => editNew(e)} text={"Opslaan"} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Modals;
