import { CSS } from "@dnd-kit/utilities";
import {
  MouseSensor as LibMouseSensor,
  KeyboardSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { faL, faXmark } from "@fortawesome/free-solid-svg-icons";
import warning from "./warning.png";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useMemo,
} from "react";
import PageTemplate from "../Templates/PageTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPenToSquare,
  faCaretLeft,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import TablePreset from "../Table/TablePreset";
import ToastError from "../Toasts/ToastError";
import ToastSuccess from "../Toasts/ToastSuccess";
import ToastWarning from "../Toasts/ToastWarning";
import middleware from "../Api/Middleware";
import Cookies from "universal-cookie";
import { useParams, useNavigate } from "react-router";

function shouldHandleEvent(element) {
  let cur = element;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement;
  }
  return true;
}

class MouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: "onMouseDown",
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      },
    },
  ];
}
function Grid({ children, columns }) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
        gridGap: 10,
        padding: 10,
      }}
    >
      {children}
    </div>
  );
}


function base64ToBlob(base64, sliceSize = 512) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays);
  return blob;
}

// This is used to render the photo's. For the actual styling and format of the 
//  draggable you need to look at the [Photo] function
const SortablePhoto = (props) => {
  //download image
  
  const sortable = useSortable({ id: props.position });
  const { attributes, listeners, setNodeRef, transform, transition } = sortable;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Photo
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      {...props}
    />
  );
};

// This is used to render the image draggable. This contains the styling and the html in order to render the photo.
const Photo = forwardRef(
  (
    {
      error,
      required,
      index,
      variationIndex,
      file,
      colorId,
      sizeId,
      url,
      name,
      handleVariationChange,
      handleDelete,
      useColors,
      variationOptions,
      variationSwitch,
      maxVariations,
      ...props
    },
    ref
  ) => {
    // check for duplicates
    const uniqueNames = new Set();
    let uniqueVariationOptions;
    if (variationOptions != null) {
      uniqueVariationOptions = variationOptions.filter((variation) => {
        const nameToCheck = variationSwitch
          ? variation.size_name
          : variation.color_name;

        if (!nameToCheck) return false; // Skip if name is null or undefined

        if (uniqueNames.has(nameToCheck)) {
          return false; // Name already exists, filter it out
        } else {
          uniqueNames.add(nameToCheck); // Add to the set to track it
          return true; // Include this option
        }
      });
    } else {
      // if non variation product
      uniqueVariationOptions = [];
    }

    //Return the image div
    return (
      <div ref={ref} {...props} className="objectWithinMU">
        <div className={error ? "errorImageBoxMU imageBoxMU" : "imageBoxMU"}>
          {/* If the file exists and it's under 10mb it will render it normally */}
          {file && file.size < 10 * 1024 * 1024 ? (
            <>
              <img
                className="col-12 imageInObjectMU p-3"
                src={url}
                draggable="false"
              />
              <div className="imageInformationContainerMU text-break">
                {/* Image size */}
                <b className="imageNameInObjectMU fw-medium" id="imageSize">
                  {file.size.toString().length >= 6 ? (
                    <span style={{ color: "#6a6a6a" }}>
                      {(file.size / 1024 / 1000).toFixed(1) + " MB"}{" "}
                    </span>
                  ) : (
                    <span style={{ color: "#6a6a6a" }}>
                      {(file.size / 1024).toFixed(1) + " kB"}{" "}
                    </span>
                  )}
                </b>

                {/* File name */}
                <div
                  className="imageNameInObjectMU p-2"
                  id="imageName"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title={name}
                >
                  <span style={{ color: "#6a6a6a" }}>{name}</span>
                </div>

                {/* Delete button */}
                <div
                  data-no-dnd="true"
                  className="imageNameInObjectMU"
                  id="deleteButton"
                >
                  <button
                    type="submit"
                    className="position-absolute d-flex justify-center px-1 deleteImageButtonMU"
                    onClick={() => handleDelete(index)}
                  >
                    <FontAwesomeIcon icon={faXmark} className="deleteIconMU" />
                  </button>
                </div>
              </div>
            </>
          ) : (
            // This renders the error image and styling
            <>
              <img
                className="col-12 imageInObjectMU"
                src={warning}
                draggable="false"
              />
              <div className="imageInformationContainerMU text-break">
                {/* File name */}
                <div className="imageNameInObjectMU px-2" id="imageName">
                  <span>{name}</span>
                </div>

                {/* Delete button */}
                <div
                  data-no-dnd="true"
                  className="imageNameInObjectMU"
                  id="deleteButton"
                >
                  <button
                    type="submit"
                    className="position-absolute d-flex justify-center px-1 deleteImageButtonMU"
                    onClick={() => handleDelete(index)}
                  >
                    <FontAwesomeIcon icon={faXmark} className="deleteIconMU" />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        {/* The select object for the color options */}
        {(useColors ?? false) && (
          <div data-no-dnd="true">
            {/* If there is nothing wrong with the image it will render out all the color options given in [colorOptions] */}
            {
              <select
                className="mb-3 py-1 col-12 standardInput standardDropdownArrow"
                value={variationIndex}
                disabled={!uniqueVariationOptions.length}
                id={variationIndex === undefined && required ? "required" : ""}
                onChange={(event) =>
                  handleVariationChange(index, event.target.value)
                }
              >
                <option defaultValue value={""}>
                  {uniqueVariationOptions.length
                    ? variationSwitch
                      ? "Link maat"
                      : "Link kleur"
                    : "Geen " + (variationSwitch ? "maten" : "kleuren")}
                </option>
                {uniqueVariationOptions.map((variation, index) => {
                  const optionValue = variationSwitch
                    ? variation.size_id
                    : variation.color_id;
                  const optionName = variationSwitch
                    ? variation.size_name
                    : variation.color_name;
                  return (
                    <option
                      key={index}
                      value={optionValue}
                      disabled={maxVariations.includes(optionValue)}
                    >
                      {optionName}
                    </option>
                  );
                })}
              </select>
            }
          </div>
        )}
      </div>
    );
  }
);

const MediaUploaderComponent = forwardRef(
  (
    {
      useColors,
      availableVariations,
      edit,
      updatedMedia,
      setUpdatedMedia,
      setImages,
      images,
      setCheck,
      product,
      variationSwitch,
      setVariationSwitch,
    },
    ref
  ) => {
    const sensors = useSensors(
      useSensor(MouseSensor),
      useSensor(TouchSensor),
      useSensor(KeyboardSensor)
    );
    const { product_id } = useParams();
    const fileInputRef = useRef(null);
    const hasFetchedImages = useRef(false);
    const [mouseDownX, setMouseDownX] = useState(null);
    const [mouseDownY, setMouseDownY] = useState(null);
    const [requiredCheck, setRequiredCheck] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [downloadCount, setDownloadCount] = useState(0);
    const [uploadCount, setUploadCount] = useState(0);
    const [uploadImgStatus, setUploadImgStatus] = useState("");
    const [variation, setVariation] = useState(null);
    const [variations, setVariations] = useState(availableVariations);
    const [variationsLoaded, setVariationsLoaded] = useState(false);
    const [switchDisabled, setSwitchDisabled] = useState(false);
    const [selectedColor, setSelectedColor] = useState("");
    const [selectedSize, setSelectedSize] = useState(null);
    const [uniqueOptions, setUniqueOptions] = useState([]);
    const [maxVariations, setMaxVariations] = useState([]);
    // if (images.filter(image => image.variationIndex == id).length >= 3) {
    //   setMaxVariations((prevMaxVariations) => {
    //     return prevMaxVariations.includes(id) ? prevMaxVariations
    //       : [...prevMaxVariations, id];
    //   });
    // }
    // else if (!maxVariations.length) {
    //   setMaxVariations((prevMaxVariations) =>
    //     prevMaxVariations.filter((item) => item !== id)
    //   );
    // }
    const allowedImageFormats = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpeg",
      "image/svg",
      "image/webp",
    ];

    function variationSwitchSet() {
      let hasColors = false;
      let hasSizes = false;
      // check if the variations contains no sizes or no colors
      if (availableVariations != null) {
        availableVariations.forEach((variation) => {
          if (variation.color != null) {
            hasColors = true;
          }
          if (variation.size != null) {
            hasSizes = true;
          }
        });
      }
    }
    useEffect(() => {
      // Set maxvariations if a variation is used 3 times so it can get disabled
      uniqueOptions.forEach((variation) => {
        if (variation.colors != null && variation.colors.length != 0) {
          const id = variation.colors[0].color_id;
          if (
            images.filter((image) => image.variationIndex == id).length >= 3
          ) {
            setMaxVariations((prevMaxVariations) => {
              return prevMaxVariations.includes(id)
                ? prevMaxVariations
                : [...prevMaxVariations, id];
            });
          } else if (maxVariations.length != 0) {
            setMaxVariations((prevMaxVariations) =>
              prevMaxVariations.filter((item) => item !== id)
            );
          }
        }
        if (variation.sizes != null && variation.sizes.length != 0) {
          const id = variation.sizes[0].size_id;
          if (
            images.filter((image) => image.variationIndex == id).length >= 3
          ) {
            setMaxVariations((prevMaxVariations) => {
              return prevMaxVariations.includes(id)
                ? prevMaxVariations
                : [...prevMaxVariations, id];
            });
          } else if (maxVariations.length != 0) {
            setMaxVariations((prevMaxVariations) =>
              prevMaxVariations.filter((item) => item !== id)
            );
          }
        }
      });
    }, [images]);

    useEffect(() => {
      if (availableVariations != null) {
        variationSwitchSet();
        if (edit && !hasFetchedImages.current) {
          getImages();
          hasFetchedImages.current = true;
        }
      }
    }, [availableVariations]);

    useEffect(() => {
      if (availableVariations != null) {
        const seenNames = new Set(); // Set to track unique names
        let newUniqueOptions = [];
        availableVariations.forEach((variation) => {
          if (variationSwitch) {
            // only sizes
            if (variation.sizes != null && variation.sizes.length !== 0) {
              const sizeName = variation.sizes[0]?.main;
              if (sizeName && !seenNames.has(sizeName)) {
                newUniqueOptions.push(variation);
                seenNames.add(sizeName);
              }
            }
          } else {
            // only colors
            if (variation.colors != null && variation.colors.length !== 0) {
              const colorName = variation.colors[0]?.name;
              if (colorName && !seenNames.has(colorName)) {
                seenNames.add(colorName); // Add name to the Set
                newUniqueOptions.push(variation); // Add unique variation to the array
              }
            }
          }
        });
        setUniqueOptions(newUniqueOptions);
      }
    }, [availableVariations, variationSwitch]);

    useEffect(() => {
      if (useColors) {
        setVariation(
          Array.from(
            new Set(
              availableVariations
                .filter((item) => {
                  const hasValidSize =
                    item.sizes !== null &&
                    item.sizes !== undefined &&
                    item.sizes.length !== 0 &&
                    item.sizes[0].main;
                  const hasValidColor =
                    item.colors !== null &&
                    item.colors !== undefined &&
                    item.colors.length !== 0 &&
                    item.colors[0].name;
                  return variationSwitch ? hasValidSize : hasValidColor;
                })
                .map((item) => {
                  return JSON.stringify(
                    variationSwitch
                      ? {
                          size_id: item.sizes[0]?.size_id,
                          size_name: item.size_name,
                          colors: item.colors,
                        }
                      : {
                          color_id: item.colors[0]?.color_id,
                          color_name: item.color_name,
                          sizes: item.sizes,
                        }
                  );
                })
            )
          ).map((v) => JSON.parse(v))
        );
      }
      setVariationsLoaded(true);
    }, [variationSwitch, availableVariations]);
    // switch functions

    window.isDeleteOrDragEndTriggered = true;
    window.mouseMoved = true;
    window.clickedOnTheColor = true;
    const handleVariationSwitch = (event) => {
      // Check if the switch isn't disabled
      const switchValue = event.target.value;
      if (!switchDisabled) {
        setVariationSwitch(switchValue);
        setImages(
          images.map((e) => ({
            ...e,
            variationIndex: "",
          }))
        );
      }
    };

    return (
      <div className={useColors ? "whiteBox pb-5 px-5" : "whiteBox p-5"}>
        <div className="whiteBox mb-1 w-100">
          <div className="d-flex flex-row h-100">
            <div className="d-flex h-100 w-100 flex-row justify-content-between">
              <div className="gap-4 d-flex flex-row mb-2">
                <h5 className="inputTitle p-0 m-0 align-self-end">
                  Afbeelding uploaden
                </h5>
                {useColors &&
                  (variationSwitch ? (
                    <p className="fw-bold p-0 m-0 align-self-end">
                      (Maximaal 3 afbeeldingen per maat)
                    </p>
                  ) : (
                    <p className="fw-bold p-0 m-0 align-self-end">
                      (Maximaal 3 afbeeldingen per kleur)
                    </p>
                  ))}
              </div>
              {useColors && (
                <div className="d-flex flex-row gap-3">
                  <div className="d-flex flex-column">
                    <p className="fw-bold p-0 m-0">Selecteer maat of kleur</p>
                    <div
                      className=""
                      onClick={(e) => {
                        // Show error if the user clicks while the option is disabled
                        if (switchDisabled) {
                          e.preventDefault();
                          ToastError(
                            "Deze optie kan niet worden veranderd wanneer er één of meerdere afbeeldingen geupload zijn",
                            "SizeAndColorError"
                          );
                        }
                      }}
                    >
                      <label className="">
                        <input
                          type="radio"
                          name="variation"
                          className="form-check-input me-2"
                          value={true}
                          checked={variationSwitch === true}
                          onChange={(e) =>
                            handleVariationSwitch(e) + setVariationSwitch(true)
                          }
                          disabled={switchDisabled}
                        />
                        Maat
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="variation"
                          className="form-check-input me-2 ms-3"
                          value={false}
                          checked={variationSwitch === false}
                          onChange={(e) =>
                            handleVariationSwitch(e) + setVariationSwitch(false)
                          }
                          disabled={switchDisabled}
                        />
                        Kleur
                      </label>
                    </div>
                  </div>
                  <select
                    onChange={(e) => {
                      if (variationSwitch) {
                        //maat
                        setSelectedSize(e.target.value);
                        setSelectedColor(null);
                      } else {
                        //kleur
                        setSelectedColor(e.target.value);
                        setSelectedSize(null);
                      }
                    }}
                    className="standardInput standardDropdownArrow"
                    style={{ minWidth: "200px" }}
                  >
                    <option defaultValue={variationSwitch}>
                      {variationSwitch
                        ? uniqueOptions.find((variation) => variation.sizes)
                          ? "Selecteer maat"
                          : "geen maten"
                        : uniqueOptions.find((variation) => variation.colors)
                        ? "Selecteer kleur"
                        : "Geen kleuren"}
                    </option>
                    {uniqueOptions.map((variation, index) => (
                      <option
                        key={index}
                        value={
                          variationSwitch
                            ? variation.sizes?.[0]?.size_id
                            : variation.colors?.[0]?.color_name
                        }
                      >
                        {variationSwitch
                          ? variation.sizes?.[0]?.main
                          : variation.colors?.[0]?.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="fileUploader fileUploaderWidthHeight">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <div
              className="mediaUploader"
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onClick={handleClick}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              {uploading || downloading ? (
                <div className="uploadScreenMU">
                  <div className="uploadScreenContainerMU">
                    <div className="spinner-border me-2" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <span className="uploadScreenTextMU">
                      {uploadImgStatus}
                    </span>
                    <span className="uploadScreenTextMU uploadScreenSubTextMU">
                      (
                      {uploading
                        ? `${uploadCount} / ${images.length}`
                        : downloading
                        ? `${uploadCount} / ${downloadCount}`
                        : ""}
                      )
                    </span>
                  </div>
                </div>
              ) : null}
              <input
                id="fileInput"
                ref={fileInputRef}
                hidden
                type="file"
                onChange={handleFileUpload}
                multiple
              />
              {images.length < 1 && (
                <div className="textForTheMU">
                  Sleep of selecteer uw media-bestanden om te uploaden
                </div>
              )}
              <SortableContext items={images} strategy={rectSortingStrategy}>
                <Grid columns={5}>
                  {variationsLoaded &&
                    images.map(
                      (
                        { id, file, name, error, url, variationIndex },
                        index
                      ) => (
                        <SortablePhoto
                          key={id ?? index}
                          error={error}
                          required={requiredCheck}
                          file={file}
                          url={url}
                          index={index}
                          useColors={useColors}
                          name={name}
                          variationIndex={variationIndex}
                          handleDelete={handleDelete}
                          variationOptions={variation}
                          handleVariationChange={handleVariationChange}
                          variationSwitch={variationSwitch}
                          position={id}
                          id={id}
                          maxVariations={maxVariations}
                        />
                      )
                    )}
                </Grid>
              </SortableContext>
            </div>
          </DndContext>
        </div>
      </div>
    );

    // Triggers everytime the user is dragging an image inside the drag and drop box (only when moving)
    function handleDragEnter(e) {
      e.preventDefault(); // Prevents the user from openening the image on a new page when dragging the image on the image upload field
      e.stopPropagation(); // Ensures that the event is not passed along to any higher-level elements in the DOM tree.
    }

    // Triggers everytime the user is holding an image inside the drag and drop box (even when holding still)
    function handleDragOver(e) {
      e.preventDefault(); // Prevents the user from openening the image on a new page when dragging the image on the image upload field
      e.stopPropagation(); // Ensures that the event is not passed along to any higher-level elements in the DOM tree.
    }

    function handleVariationChange(index, id) {
      const newImages = [...images];
      if (!variationSwitch) {
        if (id != null && id != "") {
          if (
            newImages.filter((newImage) => newImage.colorId == id).length >= 3
          ) {
            ToastError(
              "Er mogen maar 3 afbeeldingen dezelfde kleur hebben",
              "imageVariation"
            );
            return;
          } else {
            newImages[index].colorId = id;
          }
        } else {
          newImages[index].colorId = id;
        }
      } else {
        newImages[index].colorId = null;
      }
      if (variationSwitch) {
        if (
          id !== null &&
          newImages.filter((newImage) => newImage.sizeId == id).length >= 3
        ) {
          ToastError(
            "Er mogen maar 3 afbeeldingen dezelfde maat hebben",
            "imageVariation"
          );
          return;
        } else {
          newImages[index].sizeId = id;
        }
      } else {
        newImages[index].sizeId = null;
      }
      newImages[index].variationIndex = id;
      newImages[index].changed = true;
      setImages(newImages);
      // update product
      if (setUpdatedMedia != null) {
        setUpdatedMedia(true);
      }
    }

    // Removes image from the images variable
    function handleDelete(index) {
      if (setUpdatedMedia != null) {
        setUpdatedMedia(true);
      }
      const newImages = [...images];
      newImages.splice(index, 1);
      setImages(newImages);
      // Disable switch if theres are no images
      if (newImages.length == 0 || newImages == null) {
        setSwitchDisabled(false);
      }
      window.isDeleteOrDragEndTriggered = true;
    }

    // Triggers when the user holds down the left or right mouse button
    function handleMouseDown(event) {
      if (event.target.matches("select, option, button, span, svg, path")) {
        return;
      } else {
        setMouseDownX(event.clientX);
        setMouseDownY(event.clientY);
      }
    }

    // Triggers when the users lets go of their left or right mouse button
    function handleMouseUp(event) {
      if (event.clientX === mouseDownX && event.clientY === mouseDownY) {
        window.isDeleteOrDragEndTriggered = false;
        window.mouseMoved = false;
      } else {
        window.mouseMoved = true;
      }
      setMouseDownX(null);
      setMouseDownY(null);

      handleClick();
    }

    // Triggers when the user let's go of the mouse button their holding
    function handleClick() {
      if (!window.isDeleteOrDragEndTriggered && !window.mouseMoved) {
        fileInputRef.current.click();
      }
    }

    // Triggers when the user stops dragging an image
    function handleDragEnd(event) {
      const { active, over } = event;
      if (!over) {
        return;
      }
      if (active.id !== over.id) {
        setImages((images) => {
          // fixed issue with help of Tijn :D
          const oldIndex = images.indexOf(
            images.find((obj) => obj["id"] === active.id)
          );
          const newIndex = images.indexOf(
            images.find((obj) => obj["id"] === over.id)
          );
          return arrayMove(images, oldIndex, newIndex);
        });
        if (updatedMedia != null) {
          setUpdatedMedia(true);
        }
      }
    }
    function handleFileUpload(e, isDropEvent) {
      e.preventDefault(); // Prevents the user from openening the image on a new page when dragging the image on the image upload field
      e.stopPropagation(); // Ensures that the event is not passed along to any higher-level elements in the DOM tree.

      const droppedFiles = isDropEvent ? e.dataTransfer.files : e.target.files;
      const newImages = [...images];

      let counter = 0;
      let vari = selectedColor !== null ? selectedColor : selectedSize;
      // Loop through the dropped files
      for (const uploadedFile of droppedFiles) {
        // Check if selected color or size exists more than 3 times:
        const countVari = newImages.filter(
          (newImage) => Number(newImage.variationIndex) === Number(vari)
        ).length;
        if (vari == null || vari == "" || countVari < 3) {
          // check if image already exists based on name and size of the image
          if (
            !images.find((image) => image.name === uploadedFile.name) &&
            !images.find((image) => image.file && image.file.size === uploadedFile.size)
          ) {
            // set update to true
            if (product != null) {
              setUpdatedMedia(true);
            }
            // Check if contains , if so then remove it because it causes errors
            let newFileName = uploadedFile.name;
            if (uploadedFile.name.includes(",")) {
              newFileName = newFileName.replace(",", "");
            }

            //If the image exists, is smaller than 10 MB and is an allowed file type (check [allowedImageFormats] for the full list of allowed file types)
            if (
              uploadedFile &&
              uploadedFile.size <= 10 * 1024 * 1024 &&
              allowedImageFormats.includes(uploadedFile.type)
            ) {
              const newImage = {
                url: URL.createObjectURL(uploadedFile),
                file: uploadedFile, //The file containing the size and the image
                name: createUniqueName(newFileName.replace(/\s/g, "_"), images), // This function makes sure the name doesn't exist yet
                sizeId: selectedSize !== null ? Number(selectedSize) : null,
                colorId: selectedColor !== null ? Number(selectedColor) : null,
                variationIndex: vari !== null ? Number(vari) : null,
                id: `${Date.now()}${+counter}`, // A unique id for the image
              };
              //Push the new image object into the newImages list
              newImages.push(newImage);
              counter++;
            } else if (uploadedFile.size >= 10 * 1024 * 1024) {
              // If the image is bigger than 10 MB it will give the user a popup
              //Toast with the warning
              ToastWarning(
                `${uploadedFile.name} (${
                  Math.round((uploadedFile.size / 1024 / 1024) * 100) / 100
                } MB) is te groot! De maximale bestandsgrootte is 10 MB per afbeelding.`
              );

              //New image object
              const newImage = {
                file: { name: newFileName, size: uploadedFile.size }, // Makes sure it can still display the image name and the image size even when the file can't be loaded correctly
                name: createUniqueName(newFileName, images), // This function makes sure the name doesn't exist yet
                color: "default", // Default shows 'selecteer een kleur'
                id: `${Date.now()}${+counter}`, // A unique id for the image
                error: "Afbeelding is te groot", // This error will be shown in the 'select' menu under the image
              };
              //Push the error image object into the newImages list
              newImages.push(newImage);
              counter++;
            } else if (!allowedImageFormats.includes(uploadedFile.type)) {
              // If the image isn't allowed notify the user using a toast
              //Toast telling the user which file isn't allowed and which types are.
              ToastWarning(
                `${uploadedFile.name} is niet toegestaan. Bestanden met het type .png, .jpg, .jpeg, .webp, .svg en .gif zijn wel toegestaan. `
              );
              // New image object
              const newImage = {
                file: undefined, // Makes sure the [Photo] function doesn't try to display the faulty image
                name: createUniqueName(newFileName.replace(/\s/g, "_"), images), // This function makes sure the name doesn't exist yet
                color: "default", // Default shows 'selecteer een kleur'
                id: `${Date.now()}${+counter}`, // A unique id for the image
                error: "Ongeldig bestandtype", // This error will be shown in the 'select' menu under the image
              };

              //Push the error image object into the newImages list
              newImages.push(newImage);
              counter++;
            } else {
              //A fallback for when something goes wrong which can't be explained.
              ToastError(
                `Er ging iets fout tijdens het uploaden van ${uploadedFile.name}. Probeer het later opnieuw`
              );
            }
          } else {
            ToastError("Deze afbeelding bestaat al");
          }
        } else {
          ToastError(
            "Er mogen maar 3 afbeeldingen dezelfde maat/kleur hebben",
            "imageVariation"
          );
        }
      }
      // Sets the new list of images to the variable
      setImages(newImages);
      if (images.length != 0 || images != null) {
        setSwitchDisabled(true);
      }
      // When they selected images by clicking on the file uploader
      if (!isDropEvent) {
        clearFileInput(); // Clears the file input so the user can upload the same image again if they want to
      }
    }
    function clearFileInput() {
      var fileInput = document.getElementById("fileInput");
      fileInput.value = "";
    }
    function handleDrop(e) {
      handleFileUpload(e, true);
    }

    
    function fileExists(imageName, imagesArray) {
      return imagesArray.some((image) => image.name === imageName);
    }

    function createUniqueName(imageName, imagesArray) {
      const originalName = imageName; // The name of the image
      const lastDotIndex = originalName.lastIndexOf("."); // Index of the last .
      const filenameWithoutExt = originalName.substring(0, lastDotIndex); // Only the file name without the executor
      const fileExtension = originalName.substring(lastDotIndex + 1); // Only the executor so for example: png or jpeg
      let fileName = `${filenameWithoutExt}.${fileExtension}`;
      let fileCount = 0;

      // Loop through possible names until it doesn't exist in the current image array
      while (fileExists(fileName, imagesArray)) {
        fileCount++;
        fileName = `${filenameWithoutExt}(${fileCount}).${fileExtension}`;
      }

      return fileName;
    }

    async function getImages() {
      try {
        setDownloading(true);
        setUploadImgStatus("Foto's aan het ophalen");
        setUploadCount(0);
        setDownloadCount("?");

        const mediaRepsonse = await middleware.get(
          `products/media/upload?product_id=${product_id}`
        ); //Get the media from the database
        const mediaData = mediaRepsonse.data.images;
        const newImages = [];
        let selectedType = false;

        if (mediaData.length) {
          selectedType = mediaData.find((e) => e.color_id !== null)
            ? false
            : true;
        }

        // Loop through the given images
        setDownloadCount(mediaData.length);
        for (let i = 0; i < mediaData.length; i++) {
          let imgData = mediaData[i];
          // The path url to the current file
          const filePathURL = middleware.defaults.baseURL + "/public/storage/" + imgData.path;
          const lastSeparatorIndex = filePathURL.lastIndexOf("/");

          // Extract the file name using substring()
          const fileName = filePathURL.substring(lastSeparatorIndex + 1);
          // Turns the url into a blob so it can be processed by the image handler
          const blob = base64ToBlob(imgData.blob);
          const newImage = {
            url: URL.createObjectURL(blob),
            file: blob,
            name: createUniqueName(fileName, newImages),
            colorId: imgData.color_id,
            sizeId: imgData.size_id,
            variationIndex: variationSwitch
              ? imgData.size_id
              : imgData.color_id,
            variationSwitch: variationSwitch,
            id: `${Date.now()}${i + 1}`,
          };
          newImages[i] = newImage;

          setUploadCount((prev) => prev + 1);
        }

        setDownloading(false);
        setImages(newImages);

        if (newImages.length != 0) {
          setSwitchDisabled(true);
        }
        variationSwitchSet();
      } catch (error) {
        console.warn(error);
        // cookies.remove("token");
        // navigate("/");
      }
    }
  }
);

export default MediaUploaderComponent;
