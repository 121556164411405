import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router'
import logo from '../../Navbars/Sidebar/Logo Unified SMB - Wit.svg';
import middleware from '../../Api/Middleware';
import { useCookies } from 'react-cookie';
import ToastError from '../../Toasts/ToastError';
import ToastSuccess from '../../Toasts/ToastSuccess';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['reset_token']);
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [error, setError] = useState('');
    const [token, setToken] = useState('');
    const location = useLocation();
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const resetToken = new URLSearchParams(location.search).get('reset_token');
                if (resetToken) {
                    setCookie('reset_token', resetToken, { path: '/' });
                    clearResetTokenFromUrl();
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();
    }, [location, setCookie]);

    const clearResetTokenFromUrl = () => {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete('reset_token'); // Delete the reset_token query param
        window.history.replaceState(null, '', newUrl); // Update the URL without reloading the page
    };


    const handleSendRequest = async(e) => {
        try {
            e.preventDefault();
            const [updatePasswordRes] = await Promise.all([
                middleware.post('change-forgotten-password', {
                    token: cookies.reset_token,
                    new_password: password,
                    new_password_confirmation: passwordConfirmation,
                })
            ])
            if(updatePasswordRes.data.ValidationError) {
                ToastError(updatePasswordRes.data.ValidationError)
            }
            if(updatePasswordRes.data.Success) {
                ToastSuccess(updatePasswordRes.data.Success)
                navigate('/')
            }
        } catch (err) {
            console.warn(err)
        }
    } 

  return (
    <div className='backgroundGradient'>
        <div className='position-fixed btn btn-light rounded-circle m-4' onClick={() => navigate('/')}><FontAwesomeIcon icon={faArrowLeft}/></div>
        <div className='d-flex justify-content-center align-items-center h-100 flex-column'>
            <form onSubmit={handleSendRequest} className=''>
                <div className='h3 text-center text-white mb-5'>Stel je nieuwe wachtwoord in</div>
                <div className="mb-4 input-group-sm">
                    <input type="password" name='password' value={password} onChange={e => setPassword(e.target.value)} className="form-control inputsUserCredentials" placeholder="Wachtwoord"/>
                </div>
                <div className="mb-4 input-group-sm">
                    <input type="password" name='password_confirmation' value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} className="form-control inputsUserCredentials" placeholder="Wachtwoord herhalen"/>
                </div>
                <div className='mb-3 input-group-sm'>
                    <button type="submit" className="btn w-100 rounded-pill loginButton">Wachtwoord opslaan</button>
                </div>
            </form>
            <span className='text-danger fw-bolder fs-5 text-wrap'>{error}</span>
        </div>
    </div>
  )
}

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}   

export default ResetPassword