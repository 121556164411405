import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ItemNoteModalComponent = (props) => {
    const navigate = useNavigate();
    
    const [name, setName] = useState(props.product?.product_name ?? '');
    const [description, setDescription] = useState(props.product?.product_description ?? '');
    useEffect(() => {
        setName(props.product?.product_name ?? props.product?.name ?? '');
        setDescription(props.product?.product_description ?? '');
    }, [props.product])
    const save = () => {
        props.setOrderRegel(props.product?.sold_product_id ?? props.product.id, name, description);
        props.setItemNoteModalVisible(false)
    }
    return (
    <Modal
        id="modalItemNote"
        show={props.itemNoteModalVisible}
        className="colormodals"
        size="xl"
        centered
        backdrop="static"
    >
        <Modal.Header className="modalTop justify-content-between px-5" >
        <h3>Orderregel wijzigen</h3>
            <button variant="transparent" className=' btn' onClick={() => {props.setItemNoteModalVisible(false)}}>
                <FontAwesomeIcon icon={faXmark} className="closeModal" />
            </button>
        </Modal.Header>
        <Modal.Body className="modalCenter d-flex flex-column">
            <h6>Productnaam</h6>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="standardInput w-100 placeholderStyle mb-4" />
            <h6>Opmerking wordt op onderregel getoond</h6>
            <textarea
                className="form-control rounded-4"
                rows="4"
                value={description}
                onChange={(e) => setDescription(e.target.value)}></textarea>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
            <div className="d-flex justify-content-end" >
                <div className="buttonPresetStyle lightGrayStandardButton2 newButtons" onClick={() => {save()}}>Opslaan</div>
            </div>
        </Modal.Footer>
    </Modal>
  )
}

export default ItemNoteModalComponent
