import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useTable } from 'react-table';
import TablePreset from '../../../Table/TablePreset';
import AddPointsModal from '../CustomerModals/AddPointsModal';
import LoyaltyParticipationModal from '../CustomerModals/LoyaltyParticipationModal';
import RemovePointsModal from '../CustomerModals/RemovePointsModal';
import ToastWarning from '../../../Toasts/ToastWarning';
import ToastInfo from '../../../Toasts/ToastInfo';
import middleware from '../../../Api/Middleware';
import { ButtonLightGrey } from '../../../HouseStyle/Components/Buttons';

export default function Loyalty({ typeCustomer }) {

  const { internal_id } = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [order, setOrder] = useState({ orderBy: '', direction: '' });
  const [showAddPointsModal, setShowAddPointsModal] = useState(false);
  const [showRemovePointsModal, setShowRemovePointsModal] = useState(false);
  const [mutations, setMutations] = useState([]);
  const [totalPoints, setTotalPoints] = useState([]);
  const [consumerData, setConsumerData] = useState({});
  const [showLoyaltyParticipationModal, setShowLoyaltyParticipationModal] = useState(false);
  const [updatedLoyalty, setUpdatedLoyalty] = useState(false);
  const fetchAll = async () => {
    setLoading(true);
    try {
      const [mutationsResponse, loyaltyMutationsResponse, consumerResponse] = await Promise.all([
        middleware.get(`sp/consumerLoyaltyMutations?internal_id=${internal_id}&base=1&page=${pagination.page}&page_size=${pagination.page_size}`),
        middleware.get(`loyaltymutations?internal_id=${internal_id}`),
        internal_id.startsWith('C') ? middleware.get(`consumers?internal_id=${internal_id}`) : middleware.get(`businesses?internal_id=${internal_id}`)
      ])
      setMutations(mutationsResponse.data.data);
      setTotalPoints(loyaltyMutationsResponse.data.data);
      setConsumerData(consumerResponse.data.data[0]);
      setPagination({ ...pagination, "max_items": mutationsResponse.data.total, "begin_of_page": mutationsResponse.data.from, "end_of_page": mutationsResponse.data.to })
      setIsChecked(consumerResponse.data.data[0].has_loyalty === 1)
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchAll();
    setIsChecked(Number(consumerData.has_loyalty) === 1);
    setUpdatedLoyalty(false);
  }, [pagination.page_size, pagination.page, updatedLoyalty]);

  const calculateTotalPoints = () => {
    return totalPoints.reduce((total, mutation) => {
      const points = parseInt(mutation.amount);
      if (!isNaN(points)) {
        total += points;
      }
      return total;
    }, 0);
  };

  const handleCheckboxChange = (event) => {
    if (isChecked) {
      setShowLoyaltyParticipationModal(true);
      ToastInfo(`Loyaliteits systeem uitgeschakeld`);
      setIsChecked(!isChecked)
    } else if (!isChecked) {
      if (consumerData.internal_id.startsWith('C')) {
        middleware.put('consumers', {
          consumer_id: consumerData.consumer_id,
          has_loyalty: 1,
        })
      } else {
        middleware.put('businesses', {
          company_id: consumerData.company_id,
          has_loyalty: 1
        })
      }
      ToastInfo(`Loyaliteits systeem ingeschakeld`);
      setIsChecked(!isChecked)
    }
  };

  const [selectedIds, setSelectedIds] = useState([]);

  const handleDecision = (userDecision) => {
    if (userDecision) {
      if (consumerData.internal_id.startsWith('C')) {
        middleware.put('consumers', {
          consumer_id: consumerData.consumer_id,
          has_loyalty: 0
        });
      } else {
        middleware.put('businesses', {
          company_id: consumerData.company_id,
          has_loyalty: 0
        });
      }
    }
    setIsChecked(!userDecision);

    setShowLoyaltyParticipationModal(false);
  };

  const formatDate = (dateString) => {
    // Zet de invoerstring om in een Date-object
    const date = new Date(dateString);

    // Formatteer de datum in het gewenste formaat: dd-MM-yyyy
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Maanden zijn 0-gebaseerd
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };


  const closeModalLoyaltyParticipation = () => {
    setShowLoyaltyParticipationModal(false);
  };

  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  const closeModalAddPoints = () => setShowAddPointsModal(false)
  const closeModalRemovePoints = () => setShowRemovePointsModal(false)

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className='text-center fw-bold'>Datum</div>
        ),
        accessor: 'loyaltyCreatedDate',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              <p className='mb-0 text-nowrap'>{formatDate(row.original.created_date)}</p>
              <p>{row.original.created_time}</p>
            </div>
          )
        }
      },
      {
        Header: () => (
          <div className='text-center fw-bold'>Punten</div>
        ),
        accessor: 'loyaltyPoints',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              <p className='mb-0'>{row.original.points}</p>
            </div>
          )
        }
      },
      {
        Header: () => (
          <div className='text-center fw-bold'>Notitie</div>
        ),
        accessor: 'loyaltyNote',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              <p className='mb-0'>{row.original.note}</p>
            </div>
          )
        }
      },
      {
        Header: () => (
          <div className='text-center fw-bold'>Bestellingnummer</div>
        ),
        accessor: 'loyaltyBestelling',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              {row.original.order_id ?
                <p className='mb-0 linkColor'>{row.original.order_number}</p>
                :
                <p className='mb-0'>Handmatig toegevoegd</p>
              }
            </div>
          )
        }
      },
      {
        Header: () => (
          <div className='text-center fw-bold'>Medewerker</div>
        ),
        accessor: 'loyaltyOwner',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              <p className='mb-0'>{row.original.user}</p>
            </div>
          )
        }
      },
    ], [order.orderBy, order.direction, selectedIds, setSelectedIds, mutations]
  );

  // Table instance
  const tableInstance = useTable({ columns, data: mutations });

  if (loading) {
    return <h2 className="m-4 loader"></h2>
  }
  console.log(consumerData)

  return (
    <>
      <AddPointsModal setUpdatedLoyalty={setUpdatedLoyalty} openAddPoints={showAddPointsModal} closeAddPoints={closeModalAddPoints} consumerData={consumerData} />
      <LoyaltyParticipationModal openLoyaltyParticipation={showLoyaltyParticipationModal} closeLoyaltyParticipation={closeModalLoyaltyParticipation} decision={handleDecision} />
      <RemovePointsModal setUpdatedLoyalty={setUpdatedLoyalty} openRemovePoints={showRemovePointsModal} closeRemovePoints={closeModalRemovePoints} consumerData={consumerData} calculateTotalPoints={calculateTotalPoints} />
      {isChecked ?
        <div className='p-4 pe-4 mb-3'>
          <div className='d-flex flex-row justify-content-between mb-3'>
            <div className='d-flex align-items-center flex-row gap-2'>
              <ButtonLightGrey text={"+ Punten Toevoegen"} textStyling={"BlackTextBold"} onClickFunction={() => setShowAddPointsModal(true)} />
              <ButtonLightGrey text={"- Gebruik / verwijder punten"} textStyling={"BlackTextBold"} disabled={calculateTotalPoints() == 0} onClickFunction={() => setShowRemovePointsModal(true)} />
              {/* <button className='btn lightGrayStandardButton me-3' onClick={() => setShowAddPointsModal(true)}>+ Punten toevoegen</button>
              <button className='btn lightGrayStandardButton px-3' onClick={() => setShowRemovePointsModal(true)}>- Gebruik / verwijder punten</button> */}
            </div>
            <div className=''>
              <div className="d-flex flex-column align-content-center">
                <label className="fw-medium">Deelnemen</label>
                <label className="switchNewCustomer d-flex align-self-center">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span className="sliderNewCustomer round"></span>
                </label>
              </div>
            </div>
          </div>
          <div className='px-4'>
            <div>
              <p className='fw-medium mb-0'>Puntentotaal: {calculateTotalPoints()}</p>
              <p className='fw-medium'>Laatste keer aangemeld: {consumerData.loyalty_signup_date ? consumerData.loyalty_signup_date : formatDate(consumerData.created_at)}</p>
            </div>
            <div>
              <div className='loyaltyTable'>
                <div className="my-3 tablesCustomerEdit h-100">
                  <TablePreset
                    data={[
                      {
                        'tableInstance': tableInstance,
                        'pagination': pagination,
                        'primary_key': 'order_id'
                      }
                    ]}
                    setPagination={setPagination}
                    handleSelection={handleSelection}
                    noCheckbox={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className='p-4 pe-5 me-5 mb-3 d-flex flex-row justify-content-between'>
          <div>
            <p className='mb-0 fw-medium'>Deze klant maakt geen deel uit van het loyaliteitsprogramma.</p>
            <p className='fw-medium'>Hij/zijn zal zich automatisch aansluiten bij de eerste ontvangerspunten</p>
          </div>
          <div className='me-4'>
            <div className="d-flex flex-column align-content-center">
              <label className="fw-medium">Deelnemen</label>
              <label className="switchNewCustomer d-flex align-self-center">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <span className="sliderNewCustomer round"></span>
              </label>
            </div>
          </div>
        </div>
      }

    </>

  );
}
