import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import warning from "../Icons/Warning-sign.svg";
import { ButtonBlue, ButtonLightGrey } from '../../../HouseStyle/Components/Buttons';

const ConfirmNoteDeleteModal = ({ openConfirmNoteDelete, closeConfirmNoteDelete, showConfirmNoteDeleteModal, setShowConfirmNoteDeleteModal, rowToDelete, onDeleteConfirmed }) => {
  useEffect(() => {
    if (openConfirmNoteDelete) {
      setShowConfirmNoteDeleteModal(true);
    }
  }, [openConfirmNoteDelete]);

  const closeConfirmNoteDeleteModal = () => {
    setShowConfirmNoteDeleteModal(false);
    closeConfirmNoteDelete();
  }

  const handleDelete = async () => {
    if (onDeleteConfirmed) {
      await onDeleteConfirmed(); // Call the deletion function passed in as a prop
    }
    closeConfirmNoteDeleteModal(); // Close the modal after deletion
  }

  return (
    <Modal
      id="modalBlueprint"
      show={showConfirmNoteDeleteModal}
      onHide={closeConfirmNoteDeleteModal}
      className="colormodals"
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header className="modalTop justify-content-end" style={{ height: "60px" }}>
      </Modal.Header>
      <Modal.Body className="modalCenter d-flex justify-content-center">
        <div className='d-flex flex-column justify-content-center'>
          <img style={{ width: "75px" }} className=' d-flex align-self-center mb-4' src={warning} alt="Warning" />
          <div className='w-100 d-flex justify-content-center mb-3'>
            <h3 style={{ width: "60%" }} className='text-center'>Weet je zeker dat je deze notitie wilt verwijderen</h3>
          </div>
          <div className='d-flex justify-content-between gap-4 align-self-center' style={{ width: "60%" }}>
            <ButtonLightGrey text={"Annuleren"} size='m' onClickFunction={closeConfirmNoteDeleteModal} />
            <ButtonBlue text={"Ja, ik weet het zeker"} size='l' onClickFunction={handleDelete} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottom">
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmNoteDeleteModal;
