import React, { useState, useMemo } from 'react';
import PageTemplate from '../Templates/PageTemplate';
import './Css/HouseStyle.css';
import * as Icons from "./Icons.js";
import { ButtonBlue, ButtonDarkBlue, ButtonLightGrey, ButtonWhite, BackButton} from './Components/Buttons';
import { RegularCheckBox } from "./Components/Checkboxes.js";
import { RegularDropDown, BorderedDropDown } from './Components/DropDowns.js';
import TablePreset from '../Table/TablePreset.js';
import { useTable } from 'react-table';
import CheckboxHeader from '../Table/TableComponents/CheckboxHeader.js';
import CheckboxCell from '../Table/TableComponents/CheckboxCell.js';
import { BorderedSearchBar, RegularSearchBar } from './Components/SearchBars.js';
import { RegularTextInput, BorderedTextInput, RegularMoneyInput, BorderedMoneyInput, RegularSquareMoneyInput, BorderedSquareMoneyInput, RegularNumberInput, BorderedNumberInput, RegularSquareNumberInput, BorderedSquareNumberInput, BorderedTextInputWithButton } from './Components/Inputs.js';
import CodeAccordion from './Components/CodePreview/CodeAccordion.js';
import ToastSucces from '../Toasts/ToastSuccess.js';
import { hourglass } from 'ldrs'
import LoadingIcon from './Components/LoadingIcon.js';

const ColorBlock = ({ colorClass, text, textColor = "white", backgroundText = "White", backDrop}) => {
    return (
      <div className={`d-flex flex-column p-2 ${backDrop}`}>
        <div className={`text-center align-content-center color-block ${colorClass} text-${textColor}`}>{text}</div>
        <div className={`text-center mt-1 ${colorClass}Text ${backgroundText}`}>{text}Text</div>
        <div className={`text-center mt-1 ${colorClass}TextBold ${backgroundText}`}>{text}TextBold</div>
        <div className={`text-center mt-1 ${colorClass}Border BlackText`}>{text}Border</div>
        <div className={`text-center mt-1 ${colorClass}Border2 BlackText`}>{text}Border2</div>
      </div>
    );
};

const IconBlock = ({ src, label, backGroundColor = "DarkGrey", textColor = "WhiteTextBold" }) => {
    return (
      <div
        className={`p-4 ${backGroundColor} d-flex flex-column justify-content-center align-items-center`}
        style={{ width: "150px", height: "150px", textAlign: "center" }}
      >
        <img
          src={src}
          alt={label}
          style={{
            width: "80px",       // Fixed width
            height: "80px",      // Fixed height
            objectFit: "contain", // Ensures the image scales while maintaining aspect ratio
          }}
        />
        <label className={`${textColor} text-center`} style={{ fontSize: "11px", marginTop: "8px" }}>
          {label}
        </label>
      </div>
    );
  };

const tableColumns = [
    { row_id: 1, order_number: 'TDS-000023' },
    { row_id: 2, order_number: 'TDS-000024' },
    { row_id: 3, order_number: 'TDS-000025' },
  ];

const HouseStyleShowCase = () => {
    const [regularCheckBoxChecked, setRegularCheckBoxChecked] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);

    const [regularSelectedOption, setRegularSelectedOption] = useState("");
    const [borderedSelectedOption, setBorderedSelectedOption] = useState("");

    const [dropDownUseState, setDropDownUseState] = useState();

    const [rightDropDownTablePreset, setRightDropDownTablePreset] = useState();

    const [selectedIds, setSelectedIds] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        page_size: 10,
        begin_of_page: 1,
        end_of_page: 10,
        max_items: 10
    });
    hourglass.register()

    const [regularInputValue, setRegularInputValue] = useState("RegularValue");
    const [borderedInputValue, setBorderedInputValue] = useState("BorderValue");
    const [regularInputValue2, setRegularInputValue2] = useState("RegularValue2");
    const [borderedInputValue2, setBorderedInputValue2] = useState("BorderValue2");

    const [regularMoneyInputValue, setRegularMoneyInputValue] = useState("0.50");
    const [borderedMoneyInputValue, setBorderedMoneyInputValue] = useState("1.50");
    const [regularMoneyInputValue2, setRegularMoneyInputValue2] = useState(2.50);
    const [borderedMoneyInputValue2, setBorderedMoneyInputValue2] = useState(3.50);

    const [regularSearchBarValue, setRegularSearchBarValue] = useState("RegularSearchValue");
    const [borderedSearchBarValue, setBorderedSearchBarValue] = useState("BorderedSearchValue");

    const [regularNumberInputValue, setRegularNumberInputValue] = useState(10);
    const [borderedNumberInputValue, setBorderedNumberInputValue] = useState(20);
    const [regularSquareNumberInputValue, setRegularSquareNumberInputValue] = useState(30);
    const [borderedSquareNumberInputValue, setBorderedSquareNumberInputValue] = useState(40);

    const updateFunction = () => {
        ToastSucces("UpdateFunction Called")
    }

    const handleSelection = (rowId, isSelected) => {
        if (isSelected) {
            setSelectedIds((prev) => [...prev, rowId]);
        } else {
            setSelectedIds((prev) => prev.filter((id) => id !== rowId));
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: (
                    <CheckboxHeader currentItem={tableColumns} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'row_id'}></CheckboxHeader>
                ),
                accessor: "select",
                Cell: ({ row }) => {
                    return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'row_id'}/>
                }
            },
            {
                Header: "Bestelling",
                accessor: 'order_number',
                Cell: ({ row }) => {
                    return <div>{row.original.order_number}</div>
                },
            },
        ],
        [tableColumns]
    )

    const tableInstance = useTable({ columns, data: tableColumns });

  return (
    <PageTemplate navbarTitle={'HouseStyle ShowCase'}>
      <div className='w-100 White p-5'>
        <h1>Titels:</h1>
        <div className='mb-4'>
          <div className='HUBTitle'>HUBTitle - HUB Titel</div>
          <div className='BlockTitle'>BlockTitle - Bloktitel</div>
          <div className='TableTitle'>TableTitle - Tabletitel</div>
          <div className='ButtonText'>ButtonText - Knop tekst</div>
          <div className='FlatTextLink'>FlatTextLink - Platte tekst link</div>
          <div className='FlatText'>FlatText - Platte tekst</div>
          <div className='BeneathFlatText'>BeneathFlatText - Tekst onder platte tekst</div>
        </div>

        <h1 className='mt-5'>Kleuren:</h1>
        <p className='BeneathFlatText DarkGreyText'>Use as className</p>
        <div className='d-flex flex-row flex-wrap gap-3'>
            <ColorBlock colorClass="GradientBlue" text="GradientBlue" textColor="light"/>
            <ColorBlock colorClass="LightBlue" text="LightBlue" textColor="dark" backgroundText='Black' backDrop="bg-secondary"/>
            <ColorBlock colorClass="Blue" text="Blue" textColor="light"/>
            <ColorBlock colorClass="DarkBlue" text="DarkBlue" textColor="light"/>
            <ColorBlock colorClass="Black" text="Black" textColor="light"/>
            <ColorBlock colorClass="Red" text="Red" textColor="light"/>
            <ColorBlock colorClass="Orange" text="Orange" textColor="light"/>
            <ColorBlock colorClass="Green" text="Green" textColor="light"/>
            <ColorBlock colorClass="DarkGrey" text="DarkGrey" textColor="light"/>
            <ColorBlock colorClass="LightGrey" text="LightGrey" textColor="dark" backgroundText='Black' backDrop="LightBlue"/>
            <ColorBlock colorClass="LightRed" text="LightRed" textColor="dark" backgroundText='Black' backDrop="LightBlue"/>
            <ColorBlock colorClass="LightOrange" text="LightOrange" textColor="dark" backgroundText='Black' backDrop="LightBlue"/>
            <ColorBlock colorClass="LightGreen" text="LightGreen" textColor="dark" backgroundText='Black' backDrop="LightBlue"/>
            <ColorBlock colorClass="White" text="White" textColor="dark" backgroundText='Black' backDrop="LightBlue"/>
        </div>

        <h1 className='mt-5'>Icons:</h1>
        <p className='BeneathFlatText DarkGreyText'>Use as src: <strong>{"src={Icons.IconName}"}</strong></p>
        <div className='d-flex flex-row flex-wrap gap-1'>
            <IconBlock src={Icons.AccountancyHub} label={"AccountancyHub"}/>
            <IconBlock src={Icons.Analysis} label={"Analysis"}/>
            <IconBlock src={Icons.ChevronDown} label={"ChevronDown"}/>
            <IconBlock src={Icons.ChevronLeft} label={"ChevronLeft"}/>
            <IconBlock src={Icons.ChevronLeftCircle} label={"ChevronLeftCircle"}/>
            <IconBlock src={Icons.ChevronRight} label={"ChevronRight"}/>
            <IconBlock src={Icons.ChevronRightCircle} label={"ChevronRightCircle"}/>
            <IconBlock src={Icons.Close} label={"Close"}/>
            <IconBlock src={Icons.CustomerHub} label={"CustomerHub"}/>
            <IconBlock src={Icons.Dashboard} label={"Dashboard"}/>
            <IconBlock src={Icons.Day} label={"Day"}/>
            <IconBlock src={Icons.DoubleChevronLeft} label={"DoubleChevronLeft"}/>
            <IconBlock src={Icons.DoubleChevronRight} label={"DoubleChevronRight"}/>
            <IconBlock src={Icons.HelpCenter} label={"HelpCenter"}/>
            <IconBlock src={Icons.Home} label={"Home"}/>
            <IconBlock src={Icons.Info} label={"Info"}/>
            <IconBlock src={Icons.Invoice} label={"Invoice"}/>
            <IconBlock src={Icons.MagnifyingGlass} label={"MagnifyingGlass"}/>
            <IconBlock src={Icons.Mails} label={"Mails"}/>
            <IconBlock src={Icons.Notifications} label={"Notifications"}/>
            <IconBlock src={Icons.UnreadMails} label={"UnreadMails"}/>
            <IconBlock src={Icons.Night} label={"Night"}/>
            <IconBlock src={Icons.OrderHub} label={"OrderHub"}/>
            <IconBlock src={Icons.Package} label={"Package"}/>
            <IconBlock src={Icons.PaymentHub} label={"PaymentHub"}/>
            <IconBlock src={Icons.Phone} label={"Phone"}/>
            <IconBlock src={Icons.PosHub} label={"PosHub"}/>
            <IconBlock src={Icons.Print} label={"Print"}/>
            <IconBlock src={Icons.ShippingHub} label={"ShippingHub"}/>
            <IconBlock src={Icons.Sort} label={"Sort"}/>
            <IconBlock src={Icons.SortDown} label={"SortDown"}/>
            <IconBlock src={Icons.SortUp} label={"SortUp"}/>
            <IconBlock src={Icons.SpeechBubble} label={"SpeechBubble"}/>
            <IconBlock src={Icons.Verify} label={"Verify"}/>
            <IconBlock src={Icons.Copy} label={"Copy"}/>
            <IconBlock src={Icons.WarehouseHub} label={"WarehouseHub"}/>
            <IconBlock src={Icons.Sync} label={"Sync"}/>
            <IconBlock src={Icons.Warning} label={"Warning"}/>
            <IconBlock src={Icons.CompanyInformation} label={"CompanyInformation"}/>
            <IconBlock src={Icons.Giftcards} label={"Giftcards"}/>
            <IconBlock src={Icons.ProductInstellingen} label={"ProductInstellingen"}/>
            <IconBlock src={Icons.TechnicalSettings} label={"TechnicalSettings"}/>
            <IconBlock src={Icons.TaxSettings} label={"TaxSettings"}/>
            <IconBlock src={Icons.GeneralSettings} label={"GeneralSettings"}/>
            <IconBlock src={Icons.Size} label={"Size"}/>
            <IconBlock src={Icons.Copyright} label={"Copyright"}/>
            <IconBlock src={Icons.Colors} label={"Colors"}/>
            <IconBlock src={Icons.Categories} label={"Categories"}/>
            <IconBlock src={Icons.Tag} label={"Tag"}/>
        </div>

        <h1 className='mt-5'>Dark Icons:</h1>
        <p className='BeneathFlatText DarkGreyText'>Use as src: <strong>{"<img src={Icons.IconName}/>"}</strong></p>
        <div className='d-flex flex-row flex-wrap gap-1'>
            <IconBlock src={Icons.AccountancyHubDark} label={"AccountancyHubDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.AnalysisDark} label={"AnalysisDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.ChevronDownDark} label={"ChevronDownDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.ChevronLeftDark} label={"ChevronLeftDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.ChevronLeftCircleDark} label={"ChevronLeftCircleDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.ChevronRightDark} label={"ChevronRightDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.ChevronRightCircleDark} label={"ChevronRightCircleDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.CloseDark} label={"CloseDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.CustomerHubDark} label={"CustomerHubDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.DashboardDark} label={"DashboardDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.DayDark} label={"DayDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.DoubleChevronLeftDark} label={"DoubleChevronLeftDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.DoubleChevronRightDark} label={"DoubleChevronRightDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.HelpCenterDark} label={"HelpCenterDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.HomeDark} label={"HomeDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.InfoDark} label={"InfoDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.InvoiceDark} label={"InvoiceDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.MagnifyingGlassDark} label={"MagnifyingGlassDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.MailsDark} label={"MailsDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.NotificationsDark} label={"NotificationsDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.UnreadMailsDark} label={"UnreadMailsDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.NightDark} label={"NightDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.OrderHubDark} label={"OrderHubDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.PackageDark} label={"PackageDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.PaymentHubDark} label={"PaymentHubDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.PhoneDark} label={"PhoneDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.PosHubDark} label={"PosHubDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.PrintDark} label={"PrintDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.ShippingHubDark} label={"ShippingHubDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.SortDark} label={"SortDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.SortDownDark} label={"SortDownDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.SortUpDark} label={"SortUpDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.SpeechBubbleDark} label={"SpeechBubbleDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.VerifyDark} label={"VerifyDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.WarehouseHubDark} label={"WarehouseHubDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.SyncDark} label={"SyncDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
            <IconBlock src={Icons.WarningDark} label={"WarningDark"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
        </div>
        
        <h1 className='mt-5'>Dark blue Icons:</h1>
        <p className='BeneathFlatText DarkGreyText'>Use as src: <strong>{"<img src={Icons.IconName}/>"}</strong></p>
        <div className='d-flex flex-row flex-wrap gap-1'>
            <IconBlock src={Icons.ChevronLeftCircleDarkBlue} label={"ChevronLeftCircleDarkBlue"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
        </div>
        
        <h1 className='mt-5'>Blue Icons:</h1>
        <p className='BeneathFlatText DarkGreyText'>Use as src: <strong>{"<img src={Icons.IconName}/>"}</strong></p>
        <div className='d-flex flex-row flex-wrap gap-1'>
            <IconBlock src={Icons.CopyBlue} label={"CopyBlue"} backGroundColor="LightGrey" textColor="BlackTextBold"/>
        </div>

        <h1 className='mt-5'>Loading Icons:</h1>
        <div className='d-flex flex-row flex-wrap gap-1'>
            <LoadingIcon/>
        </div>
        <CodeAccordion
            title='Bekijk loading icon code'
            code={`<LoadingIcon color='Blue' size={100}/>`}/>

        <h1 className='mt-5'>Border Radiussen:</h1>
        <p className='BeneathFlatText DarkGreyText'>Use as className</p>
        <div className='d-flex flex-row gap-3'>
            <div className='BlackBorder2 p-4 BorderRadius'>BorderRadius</div>
            <div className='BlackBorder2 p-4 BorderRadius2'>BorderRadius2</div>
        </div>

        <h1 className='mt-5'>Buttons:</h1>
        <p className='BeneathFlatText DarkGreyText'>Use as Component: <strong>{"<ButtonBlue text={'Button Blue'} icon={Icons.IconName} secondIcon={Icons.IconName} iconPlacement={'right'/'left'/'both'}/>"}</strong></p>
        <div className='d-flex flex-column gap-5'>{/* ButtonBlue Variations */}
            <div className='d-flex flex-wrap'>
                <div className='p-4'>
                    <ButtonBlue text={"ButtonBlue"}/>
                </div>
                <div className='p-4'>
                    <ButtonBlue text={"ButtonBlue"} icon={Icons.AccountancyHub} iconPlacement={"left"} size='l'/>
                </div>
                <div className='p-4'>
                    <ButtonBlue text={"ButtonBlue"} icon={Icons.AccountancyHub} iconPlacement={"right"}/>
                </div>
                <div className='p-4'>
                    <ButtonBlue text={"ButtonBlue"} icon={Icons.AccountancyHub} iconPlacement={"both"}/>
                </div>
                <div className='p-4'>
                    <ButtonBlue text={"ButtonBlue"} icon={Icons.AccountancyHub} secondIcon={Icons.Analysis} iconPlacement={"both"} disabled={false}/>
                </div>
                <div className='p-4'>
                    <ButtonBlue text={"ButtonBlue - disabled"} disabled/>
                </div>
            </div>

            <CodeAccordion
            title='Bekijk ButtonBlue code'
            code={`<ButtonBlue text={"ButtonBlue"} icon={Icons.AccountancyHub} secondIcon={Icons.Analysis} iconPlacement={"both"} disabled={false}/>`}/>
            
            {/* ButtonDarkBlue Variations */}
            <div className='d-flex flex-wrap'>
                <div className='p-4'>
                    <ButtonDarkBlue text={"ButtonDarkBlue"}/>
                </div>
                <div className='p-4'>
                    <ButtonDarkBlue text={"ButtonDarkBlue"} icon={Icons.AccountancyHub} iconPlacement={"left"}/>
                </div>
                <div className='p-4'>
                    <ButtonDarkBlue text={"ButtonDarkBlue"} icon={Icons.AccountancyHub} iconPlacement={"right"}/>
                </div>
                <div className='p-4'>
                    <ButtonDarkBlue text={"ButtonDarkBlue"} icon={Icons.AccountancyHub} iconPlacement={"both"}/>
                </div>
                <div className='p-4'>
                    <ButtonDarkBlue text={"ButtonDarkBlue"} icon={Icons.AccountancyHub} secondIcon={Icons.Analysis} iconPlacement={"both"} disabled={false}/>
                </div>
                <div className='p-4'>
                    <ButtonDarkBlue text={"ButtonDarkBlue - disabled"} disabled/>
                </div>
            </div>

            <CodeAccordion
            title='Bekijk ButtonDarkBlue code'
            code={`<ButtonDarkBlue text={"ButtonDarkBlue"} icon={Icons.AccountancyHub} secondIcon={Icons.Analysis} iconPlacement={"both"} disabled={false}/>`}/>
            
            {/* ButtonLightGrey Variations */}
            <div className='d-flex flex-wrap'>
                <div className='p-4'>
                    <ButtonLightGrey text={"ButtonLightGrey"}/>
                </div>
                <div className='p-4'>
                    <ButtonLightGrey text={"ButtonLightGrey"} icon={Icons.AccountancyHubDark} iconPlacement={"left"}/>
                </div>
                <div className='p-4'>
                    <ButtonLightGrey text={"ButtonLightGrey"} icon={Icons.AccountancyHubDark} iconPlacement={"right"}/>
                </div>
                <div className='p-4'>
                    <ButtonLightGrey text={"ButtonLightGrey"} icon={Icons.AccountancyHubDark} iconPlacement={"both"}/>
                </div>
                <div className='p-4'>
                    <ButtonLightGrey text={"ButtonLightGrey"} icon={Icons.AccountancyHubDark} secondIcon={Icons.AnalysisDark} iconPlacement={"both"} disabled={false}/>
                </div>
                <div className='p-4'>
                    <ButtonLightGrey text={"ButtonLightGrey - disabled"} disabled/>
                </div>
            </div>

            <CodeAccordion
            title='Bekijk ButtonLightGrey code'
            code={`<ButtonLightGrey text={"ButtonLightGrey"} icon={Icons.AccountancyHubDark} secondIcon={Icons.AnalysisDark} iconPlacement={"both"} disabled={false}/>`}/>
            
            {/* ButtonWhite Variations */}
            <div className='d-flex flex-wrap LightBlue'>
                <div className='p-4'>
                    <ButtonWhite text={"ButtonWhite"}/>
                </div>
                <div className='p-4'>
                    <ButtonWhite text={"ButtonWhite"} icon={Icons.AccountancyHubDark} iconPlacement={"left"}/>
                </div>
                <div className='p-4'>
                    <ButtonWhite text={"ButtonWhite"} icon={Icons.AccountancyHubDark} iconPlacement={"right"}/>
                </div>
                <div className='p-4'>
                    <ButtonWhite text={"ButtonWhite"} icon={Icons.AccountancyHubDark} iconPlacement={"both"}/>
                </div>
                <div className='p-4'>
                    <ButtonWhite text={"ButtonWhite"} icon={Icons.AccountancyHubDark} secondIcon={Icons.AnalysisDark} iconPlacement={"both"}/>
                </div>
                <div className='p-4'>
                    <ButtonWhite text={"ButtonWhite - disabled"} disabled/>
                </div>
            </div>

            <CodeAccordion
            title='Bekijk ButtonWhite code'
            code={`<ButtonWhite text={"ButtonWhite"} icon={Icons.AccountancyHubDark} secondIcon={Icons.AnalysisDark} iconPlacement={"both"}/>`}/>
            
            
            <div className='d-flex flex-wrap'>
                <div className='p-4'>
                    <BackButton text={"ButtonBack"}/>
                </div>
            </div>

            <CodeAccordion
                title='Bekijk BackButton code'
                code={`<BackButton text={"ButtonBack - disabled"} disabled={false}/>`}/>
        </div>

        <h1 className='mt-5'>Checkboxes:</h1>
        <div>
            <RegularCheckBox 
                label={"RegularCheckBox"} 
                setValue={setRegularCheckBoxChecked} 
                value={regularCheckBoxChecked}
                />
        </div>
        <CodeAccordion
            title='Bekijk RegularCheckBox code'
            code={`<RegularCheckBox 
                label={"RegularCheckBox"} 
                setValue={setRegularCheckBoxChecked} 
                value={regularCheckBoxChecked}
                />`}/>

        <h1 className='mt-5'>Text Inputs:</h1>
        <div className='d-flex flex-column gap-4'>
            <div className='LightBlue p-5 InputRow'>
                <RegularTextInput placeholder='RegularTextInput' size='xs' updateFunction={updateFunction}/>
                <RegularTextInput placeholder='RegularTextInput' size='s' updateFunction={updateFunction}/>
                <RegularTextInput inputValue={regularInputValue} setInputValue={setRegularInputValue} placeholder='RegularTextInput' size='m' updateFunction={updateFunction}/>
                <RegularTextInput inputValue={regularInputValue2} setInputValue={setRegularInputValue2} placeholder='RegularTextInput' size='l' updateFunction={updateFunction}/>
            </div>
            <CodeAccordion
            title='Bekijk RegularTextInput code'
            code={`<RegularTextInput placeholder='RegularTextInput' size='l'/>`}/>
            <div className='p-5 InputRow'>
                <BorderedTextInput placeholder='BorderedTextInput' size='xs' updateFunction={updateFunction}/>
                <BorderedTextInput placeholder='BorderedTextInput' size='s' updateFunction={updateFunction}/>
                <BorderedTextInput inputValue={borderedInputValue} setInputValue={setBorderedInputValue} placeholder='BorderedTextInput' size='m' updateFunction={updateFunction}/>
                <BorderedTextInput buttonLabel='Kopieer' textBoxInfront='TXT' prefixLabel="Prefix" label="InputLabel" inputValue={borderedInputValue2} setInputValue={setBorderedInputValue2} placeholder='BorderedTextInput' size='l' updateFunction={updateFunction}/>
                {/* <BorderedTextInputWithButton  prefixLabel={"Prefix"} inputLabel={"label2"} inputValue={borderedInputValue2} setInputValue={setBorderedInputValue2} textBoxInfront={true} placeholder='BorderedTextInput' size='l' updateFunction={updateFunction}/> */}
            </div>
            <CodeAccordion
            title='Bekijk BorderedTextInput code'
            code={`<BorderedTextInput buttonLabel='Kopieer' textBoxInfront='TXT' prefixLabel="Prefix" label="InputLabel" inputValue={borderedInputValue2} setInputValue={setBorderedInputValue2} placeholder='BorderedTextInput' size='l' updateFunction={updateFunction}/>`}/>
        </div>

        
        <h1 className='mt-5'>Money Inputs:</h1>
        <div className='d-flex flex-column gap-4'>
            <div className='LightBlue p-5 InputRow'>
                <RegularMoneyInput size='xs' maxAmount={80} minAmount={0} updateFunction={updateFunction}/>
                <RegularMoneyInput size='s' maxAmount={80} minAmount={0} updateFunction={updateFunction}/>
                <RegularMoneyInput size='m' restrictMinus={false} maxAmount={200} minAmount={-200} inputValue={regularMoneyInputValue} setInputValue={setRegularMoneyInputValue} updateFunction={updateFunction}/>
                <RegularMoneyInput placeholder='RegularMoneyInput' size='l' updateFunction={updateFunction}/>
            </div>
            <CodeAccordion
            title='Bekijk RegularMoneyInput code'
            code={`<RegularMoneyInput size='m' restrictMinus={false} maxAmount={200} minAmount={-200} inputValue={regularMoneyInputValue} setInputValue={setRegularMoneyInputValue}/>`}/>
            <div className='p-5 InputRow'>
                <BorderedMoneyInput size='xs' maxAmount={80} minAmount={0} updateFunction={updateFunction}/>
                <BorderedMoneyInput size='s' maxAmount={80} minAmount={0} updateFunction={updateFunction}/>
                <BorderedMoneyInput size='m' restrictMinus={false} maxAmount={200} minAmount={-200} inputValue={borderedMoneyInputValue} setInputValue={setBorderedMoneyInputValue} updateFunction={updateFunction}/>
                <BorderedMoneyInput placeholder='BorderedMoneyInput' size='l' updateFunction={updateFunction}/>
            </div>
            <CodeAccordion
            title='Bekijk BorderedMoneyInput code'
            code={`<BorderedMoneyInput size='m' restrictMinus={false} maxAmount={200} minAmount={-200} inputValue={borderedMoneyInputValue} setInputValue={setBorderedMoneyInputValue}/>`}/>
            <div className='LightBlue p-5 InputRow'>
                <RegularSquareMoneyInput size='xs' maxAmount={80} minAmount={0} updateFunction={updateFunction}/>
                <RegularSquareMoneyInput size='s' maxAmount={80} minAmount={0} updateFunction={updateFunction}/>
                <RegularSquareMoneyInput size='m' restrictMinus={false} maxAmount={200} minAmount={-200} inputValue={regularMoneyInputValue} setInputValue={setRegularMoneyInputValue} updateFunction={updateFunction}/>
                <RegularSquareMoneyInput placeholder='RegularSquareMoneyInput' size='l' updateFunction={updateFunction}/>
            </div>
            <CodeAccordion
            title='Bekijk RegularSquareMoneyInput code'
            code={`<RegularMoneyInput size='m' restrictMinus={false} maxAmount={200} minAmount={-200} inputValue={regularMoneyInputValue} setInputValue={setRegularMoneyInputValue}/>`}/>
            <div className='p-5 InputRow'>
                <BorderedSquareMoneyInput size='xs' maxAmount={80} minAmount={0} updateFunction={updateFunction}/>
                <BorderedSquareMoneyInput size='s' maxAmount={80} minAmount={0} updateFunction={updateFunction}/>
                <BorderedSquareMoneyInput size='m' restrictMinus={false} maxAmount={200} minAmount={-200} inputValue={borderedMoneyInputValue} setInputValue={setBorderedMoneyInputValue} updateFunction={updateFunction}/>
                <BorderedSquareMoneyInput placeholder='BorderedSquareMoneyInput' size='l' updateFunction={updateFunction}/>
            </div>
            <CodeAccordion
            title='Bekijk BorderedSquareMoneyInput code'
            code={`<BorderedMoneyInput size='m' restrictMinus={false} maxAmount={200} minAmount={-200} inputValue={borderedMoneyInputValue} setInputValue={setBorderedMoneyInputValue}/>`}/>
        </div>

        
        <h1 className='mt-5'>Number Inputs:</h1>
        <div className='d-flex flex-column gap-4'>
            <div className='LightBlue p-5 InputRow'>
                <RegularNumberInput size='xs' updateFunction={updateFunction} decimal={true} thousandSeparator={true}/>
                <RegularNumberInput size='s' updateFunction={updateFunction} decimal={true} thousandSeparator={true} updateWaitTimer={null}/>
                <RegularNumberInput size='m' inputValue={regularNumberInputValue} setInputValue={setRegularNumberInputValue} updateFunction={updateFunction}/>
                <RegularNumberInput placeholder='RegularNumberInput' size='l' updateFunction={updateFunction}/>
            </div>
            <CodeAccordion
            title='Bekijk RegularNumberInput code'
            code={`<RegularNumberInput size='m' inputValue={regularNumberInputValue} setInputValue={setRegularNumberInputValue} updateFunction={updateFunction}/>`}/>
            <div className='p-5 InputRow'>
                <BorderedNumberInput size='xs' updateFunction={updateFunction} decimal={true} thousandSeparator={true}/>
                <BorderedNumberInput size='s' updateFunction={updateFunction} decimal={true} thousandSeparator={true} updateWaitTimer={null}/>
                <BorderedNumberInput size='m' inputValue={borderedNumberInputValue} setInputValue={setBorderedNumberInputValue} updateFunction={updateFunction}/>
                <BorderedNumberInput placeholder='BorderedNumberInput' size='l' updateFunction={updateFunction}/>
            </div>
            <CodeAccordion
            title='Bekijk BorderedNumberInput code'
            code={`<BorderedNumberInput size='m' inputValue={borderedNumberInputValue} setInputValue={setBorderedNumberInputValue} updateFunction={updateFunction}/>`}/>
            <div className='LightBlue p-5 InputRow'>
                <RegularSquareNumberInput size='xs' updateFunction={updateFunction} decimal={true}/>
                <RegularSquareNumberInput size='s' updateFunction={updateFunction}/>
                <RegularSquareNumberInput size='m' inputValue={regularSquareNumberInputValue} setInputValue={setRegularSquareNumberInputValue} updateFunction={updateFunction}/>
                <RegularSquareNumberInput placeholder='RegularSquareNumberInput' size='l' updateFunction={updateFunction}/>
            </div>
            <CodeAccordion
            title='Bekijk RegularSquareNumberInput code'
            code={`<RegularSquareNumberInput size='m' inputValue={regularSquareNumberInputValue} setInputValue={setRegularSquareNumberInputValue} updateFunction={updateFunction}/>`}/>
            <div className='p-5 InputRow'>
                <BorderedSquareNumberInput size='xs' updateFunction={updateFunction}/>
                <BorderedSquareNumberInput size='s' updateFunction={updateFunction}/>
                <BorderedSquareNumberInput size='m' inputValue={borderedSquareNumberInputValue} setInputValue={setBorderedSquareNumberInputValue} updateFunction={updateFunction}/>
                <BorderedSquareNumberInput placeholder='BorderedSquareNumberInput' size='l' updateFunction={updateFunction}/>
            </div>
            <CodeAccordion
            title='Bekijk BorderedSquareNumberInput code'
            code={`<BorderedSquareNumberInput size='m' inputValue={borderedSquareNumberInputValue} setInputValue={setBorderedSquareNumberInputValue} updateFunction={updateFunction}/>`}/>
        </div>

        <h1 className='mt-5'>Dropdowns:</h1>
        <div className='LightBlue InputRow p-5'>
            <RegularDropDown
                placeholder="RegularDropDown"
                options={[
                    { title: "Option 1", value: "option1", onClick: () => console.log("Option 1 selected!"), disabled: true },
                    { title: "Option 2", value: "option2", onClick: () => console.log("Option 2 selected!") },
                    { title: "Option 3", value: "option3", onClick: () => console.log("Option 3 selected!") },
                    { title: "Option 4", value: "option4", onClick: () => console.log("Option 4 selected!") },
                    { title: "Option 5", value: "option5", onClick: () => console.log("Option 5 selected!") },
                ]}
                selectedValue={regularSelectedOption}
                setSelectedValue={setRegularSelectedOption}
            />
        </div>
        <CodeAccordion
            title='Bekijk RegularDropDown code'
            code={`<RegularDropDown
                placeholder="RegularDropDown"
                options={[
                    { title: "Option 1", value: "option1", onClick: () => console.log("Option 1 selected!"), disabled: true },
                    { title: "Option 2", value: "option2", onClick: () => console.log("Option 2 selected!") },
                    { title: "Option 3", value: "option3", onClick: () => console.log("Option 3 selected!") },
                    { title: "Option 4", value: "option4", onClick: () => console.log("Option 4 selected!") },
                    { title: "Option 5", value: "option5", onClick: () => console.log("Option 5 selected!") },
                ]}
                selectedValue={regularSelectedOption}
                setSelectedValue={setRegularSelectedOption}
            />`}/>

        <div className='p-5'>
            <BorderedDropDown
                placeholder="BorderedDropDown"
                options={[
                    { title: "Option 1", value: "option1", onClick: () => console.log("Option 1 selected!"), disabled: true },
                    { title: "Option 2", value: "option2", onClick: () => console.log("Option 2 selected!") },
                    { title: "Option 3", value: "option3", onClick: () => console.log("Option 3 selected!") },
                    { title: "Option 4", value: "option4", onClick: () => console.log("Option 4 selected!") },
                    { title: "Option 5", value: "option5", onClick: () => console.log("Option 5 selected!") },
                ]}
                selectedValue={borderedSelectedOption}
                setSelectedValue={setBorderedSelectedOption}
            />
        </div>
        <CodeAccordion
            title='Bekijk BorderedDropDown code'
            code={`<BorderedDropDown
                placeholder="BorderedDropDown"
                options={[
                    { title: "Option 1", value: "option1", onClick: () => console.log("Option 1 selected!"), disabled: true },
                    { title: "Option 2", value: "option2", onClick: () => console.log("Option 2 selected!") },
                    { title: "Option 3", value: "option3", onClick: () => console.log("Option 3 selected!") },
                    { title: "Option 4", value: "option4", onClick: () => console.log("Option 4 selected!") },
                    { title: "Option 5", value: "option5", onClick: () => console.log("Option 5 selected!") },
                ]}
                selectedValue={borderedSelectedOption}
                setSelectedValue={setBorderedSelectedOption}
            />`}/>


        <h1 className='mt-5'>SearchBars:</h1>
        <div className='LightBlue p-5 InputRow'>
            <RegularSearchBar
                inputValue={regularSearchBarValue}
                setInputValue={setRegularSearchBarValue}
                placeholder={"RegularSearchBarZoeken..."}
                columnHeaders={{"product_name": "Productnaam", "price": "Prijs excl. btw"}}
                responseItems={[{"product_name": "product1", "price": "€0.00"}, {"product_name": "product2", "price": "€0.00"}, {"product_name": "product2", "price": "€0.00"}]}
                onItemClick={(e) => console.log(e.target.value)}
                size='l'
                searchFunction={updateFunction}/>
        </div>
        <CodeAccordion
            title='Bekijk RegularSearchBar code'
            code={`<RegularSearchBar
                inputValue={regularSearchBarValue}
                setInputValue={setRegularSearchBarValue}
                placeholder={"RegularSearchBarZoeken..."}
                columnHeaders={{"product_name": "Productnaam", "price": "Prijs excl. btw"}}
                responseItems={[{"product_name": "product1", "price": "€0.00"}, {"product_name": "product2", "price": "€0.00"}, {"product_name": "product2", "price": "€0.00"}]}
                onItemClick={(e) => console.log(e.target.value)}
                size='l'
                searchFunction={updateFunction}/>`}/>
        <div className='p-5 InputRow'>
            <BorderedSearchBar
                inputValue={borderedSearchBarValue}
                setInputValue={setBorderedSearchBarValue}
                placeholder={"BorderedSearchBarZoeken..."}
                columnHeaders={{"product_name": "Product Naam", "price": "Prijs excl. btw"}}
                responseItems={[{"product_name": "product1", "price": "€0.00"}, {"product_name": "product2", "price": "€0.00"}, {"product_name": "product2", "price": "€0.00"}]}
                onItemClick={(e) => console.log(e.target.value)}
                size='l'
                searchFunction={updateFunction}/>
        </div>
        <CodeAccordion
            title='Bekijk BorderedSearchBar code'
            code={`<BorderedSearchBar
                inputValue={borderedSearchBarValue}
                setInputValue={setBorderedSearchBarValue}
                placeholder={"BorderedSearchBarZoeken..."}
                columnHeaders={{"product_name": "Product Naam", "price": "Prijs excl. btw"}}
                responseItems={[{"product_name": "product1", "price": "€0.00"}, {"product_name": "product2", "price": "€0.00"}, {"product_name": "product2", "price": "€0.00"}]}
                onItemClick={(e) => console.log(e.target.value)}
                size='l'
                searchFunction={updateFunction}/>`}/>



        <h1 className='mt-5'>TablePreset:</h1>
        <div className='LightBlue p-5'>
            <TablePreset
                data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': [], 'primary_key': 'row_id'}]}
                emptyTableHeight='xs'
                tableLoading={tableLoading}
                actionOptions={[{title: "Delete"}]}
                searchBar={[{ shown: true }]}
                handleSelection={handleSelection}
                rightDropdowns={[{
                    placeholder: 'Filter op categorie',
                    options: [{title: "Optie1", function: () => console.log("sjaak")}, {title: "Optie2"}]
                }]}
                leftButtons={[{ title: 'Add New'}]}
                rightButtons={[{ title: 'Export'}]}
            />
        </div>

        <CodeAccordion 
            title='Bekijk TablePreset code'
            code={`<TablePreset
                data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'row_id'}]}
                tableLoading={tableLoading}
                actionOptions={[{title: "Delete"}]}
                searchBar={[{ shown: true }]}
                handleSelection={handleSelection}
                rightDropdowns={[{
                    placeholder: 'Filter op categorie',
                    options: [{title: "Optie1"}, {title: "Optie2"}]
                }]}
                leftButtons={[{ title: 'Add New'}]}
                rightButtons={[{ title: 'Export'}]}
            />`}/>

        <h1 className='mt-5'>TablePreset with drag function:</h1>
        <div className='LightBlue p-5'>
            <TablePreset
                enableDrag={true}
                apiEndPoint={'/api/endpoint'}
                data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'row_id'}]}
                tableLoading={tableLoading}
                actionOptions={[{title: "Delete"}]}
                searchBar={[{ shown: true }]}
                handleSelection={handleSelection}
                rightDropdowns={[{
                    placeholder: 'Filter op categorie',
                    options: [{title: "Optie1", function: () => console.log("sjaak")}, {title: "Optie2"}]
                }]}
                leftButtons={[{ title: 'Add New'}]}
                rightButtons={[{ title: 'Export'}]}
            />
        </div>

        <CodeAccordion 
            title='Bekijk TablePreset code'
            code={`<TablePreset
                enableDrag={true}
                apiEndPoint={'/api/endpoint'}
                data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'row_id'}]}
                tableLoading={tableLoading}
                actionOptions={[{title: "Delete"}]}
                searchBar={[{ shown: true }]}
                handleSelection={handleSelection}
                rightDropdowns={[{
                    placeholder: 'Filter op categorie',
                    options: [{title: "Optie1"}, {title: "Optie2"}]
                }]}
                leftButtons={[{ title: 'Add New'}]}
                rightButtons={[{ title: 'Export'}]}
            />`}/>
      </div>
    </PageTemplate>
  );
};

export default HouseStyleShowCase;
