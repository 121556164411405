import { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';
import Cookies from 'universal-cookie';
import ToastError from '../Toasts/ToastError';

const UnauthorizedEventListener = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const eventListenerAdded = useRef(false);
  const tokenName = "token";

  const allowedRoutes = ["/login", "/wachtwoord-vergeten", "/wachtwoord-resetten"];

  // Handle the case when the user is unauthorized
  const handleUnauthorized = () => {
    ToastError("Je sessie is verlopen of je hebt geen toegang. Je wordt nu doorgestuurd naar de login pagina.");
    cookies.remove(tokenName, { path: '/' });
    navigate('/login');
  };

  // Check for token and handle redirection logic
  const checkAuthentication = () => {
    const token = cookies.get(tokenName);

    if (!token && !allowedRoutes.includes(location.pathname)) {
      if (location.pathname !== "/") {
        ToastError("Je bent niet ingelogd. Je wordt nu doorgestuurd naar de login pagina.");
      }
      navigate('/login');
    }
  };

  useEffect(() => {
    if (eventListenerAdded.current) return; // Prevent multiple listeners
    eventListenerAdded.current = true;

    // Skip the token check for allowed routes
    if (allowedRoutes.includes(location.pathname)) {
      return;
    }

    // Perform the authentication check
    checkAuthentication();

    // Attach unauthorized event listener
    window.addEventListener('unauthorized-event', handleUnauthorized);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('unauthorized-event', handleUnauthorized);
      eventListenerAdded.current = false;
    };
  }, [navigate, location.pathname, cookies]);

  return null;
};

export default UnauthorizedEventListener;