import React, { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { faPencilAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import InputComponent from '../../Customer/AddCustomers/InputComponent';
import middleware from '../../Api/Middleware';
import ToastError from '../../Toasts/ToastError';
import ToastInfo from '../../Toasts/ToastInfo';
import ToastSuccess from '../../Toasts/ToastSuccess';

const AddAddressModal = (props) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [countries, setCountries] = useState([]);
    const [countryIdInfo, setCountryIdInfo] = useState({});
    const [editMode, setEditMode] = useState(false);
    const getValueInput = useCallback((value, label) => {
        const normalizedLabel = normalizeLabel(label);
        setFormData(prev => ({ ...prev, [normalizedLabel]: value }));
    }, []);
    const [debounceTimer, setDebounceTimer] = useState(null);

    const normalizeLabel = (label) => {
        return label.trim().replace(/[\s\-]+/g, "");
    };
    const fetchCountries = async () => {
        const countriesRes = await middleware.get("/countryCodes");
        setCountries(countriesRes.data);

    }
    useEffect(() => {
        fetchCountries();
    }, [])

    const addAddress = async () => {
        let adress_kind = 0;

        if (props.customer.internal_id.startsWith('B')) {
            if (formData.deliveryaddress && !formData.invoiceaddress) {
                adress_kind = 2;
            } else if (!formData.deliveryaddress && formData.invoiceaddress) {
                adress_kind = 3;
            } else if (formData.deliveryaddress && formData.invoiceaddress) {
                adress_kind = 5;
            } else {
                adress_kind = null
            }
        } else if (props.customer.internal_id.startsWith('C')) {
            if (formData.deliveryaddress && !formData.invoiceaddress) {
                adress_kind = 0;
            } else if (!formData.deliveryaddress && formData.invoiceaddress) {
                adress_kind = 1;
            } else if (formData.deliveryaddress && formData.invoiceaddress) {
                adress_kind = 4;
            } else {
                adress_kind = null
            }
        }
        let addressRes = await middleware.post("/editAddress", {
            internal_id: props.customer.internal_id,
            country_code_id: countryIdInfo.country_code_id,
            postalcode: formData.Postalcode,
            housenumber: formData.HouseNumber,
            addition: formData.Addition || null,
            streetname: formData.StreetName,
            city: formData.City,
            address_kind: adress_kind,
        });
        ToastSuccess("Adres is aangemaakt");
        
        props.address_type ? props.setSelectedDeliveryAddress(addressRes.data) : props.setSelectedInvoiceAddress(addressRes.data);
        
        if(props?.order?.client_addresses) {
            props.setOrder({...(props.order), client_addresses: [...props.order.client_addresses, addressRes.data]});
        } else {
            props.setAddresses([...props.addresses, addressRes.data]);
        }
        props.setAddAddressModalVisible(false);
    }
    useEffect(() => {
        if (countryIdInfo.country_code_2 == 'NL' && (!editMode)) {
            const { Postalcode, HouseNumber } = formData; // Destructure the relevant fields from formData
            const postalCodeRegex = /^\s*[0-9]{4}\s*[A-Za-z]{2}\s*$/;

            setPostalCodeError('');

            // Clear the previous debounce timer if it exists
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }

            if (Postalcode && HouseNumber) {

                // Set a new debounce timer
                const newDebounceTimer = setTimeout(() => {
                    // Check if Postalcode matches the regex
                    if (postalCodeRegex.test(Postalcode)) {
                        const fetchAdress = async () => {
                            if (formData.HouseNumber) {
                                try {
                                    const response = await fetch(`https://gratispostcodeapi.nl/api/v1/lookup/${Postalcode}/${HouseNumber}`);
                                    const data = await response.json();

                                    // If the API returns valid postal_code data, populate formData
                                    if (data && data.postal_code) {
                                        setFormData(prevData => ({
                                            ...prevData,
                                            StreetName: data.street,
                                            City: data.city,
                                        }));
                                        setPostalCodeError(false)
                                    } else {
                                        ToastError('Geen adres gevonden voor deze postcode.');
                                        setPostalCodeError(true)
                                    }
                                } catch (error) {
                                    ToastError('Er is een fout opgetreden bij het ophalen de adress gegevens');
                                    setPostalCodeError(true)
                                }
                            } else {
                                ToastInfo("Voer het huisnummer in om automatisch de straat en stad in te vullen")
                                setPostalCodeError(true)
                            }
                        };

                        fetchAdress(); // Call the function to fetch the house number
                    } else {
                        ToastError('Ongeldig postcodeformaat. Zorg ervoor dat je 4 cijfers en 2 letters invoert.');
                        setPostalCodeError(true)
                    }
                }, 1000); // 2 seconds debounce

                setDebounceTimer(newDebounceTimer); // Set the new timer
            }

            return () => clearTimeout(debounceTimer); // Cleanup timer on unmount or when dependencies change
        }
    }, [formData.Postalcode, formData.HouseNumber, countryIdInfo]);

    const [postalCodeError, setPostalCodeError] = useState('');
    const [adressData, setAdressData] = useState({});

    return (
        <Modal
            id="modalItemNote"
            show={props.addAddressModalVisible}
            onHide={{}}
            className="colormodals"
            size="xl"
            centered
            backdrop="static"
        >
            <Modal.Header className="modalTop justify-content-between px-5" >
                <h3>Adres</h3>
                <button variant="transparent" className=' btn' onClick={() => { props.setAddAddressModalVisible(false) }}>
                    <FontAwesomeIcon icon={faXmark} className="closeModal" />
                </button>
            </Modal.Header>
            <Modal.Body className="modalCenter d-flex flex-column">
                <div className="">
                    <div>
                        <div className=" d-flex flex-row mb-4">
                            <h5 className="me-5">{editMode ? "Adres bewerken" : "Adres toevoegen"}</h5>
                            <select
                                className="form-select inputFieldAddCustomer ms-5 dropdownsNewCustomers" style={{ width: "250px" }}
                                value={JSON.stringify(countryIdInfo)}
                                onChange={(e) => setCountryIdInfo(JSON.parse(e.target.value))}
                            >
                                <option hidden>Kies een land</option> 
                                {countries?.length > 0 && countries.map(country => (
                                    <option key={country.country_code_id} value={JSON.stringify(country)}>
                                        {country.country}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-3">
                            <label className="checkbox-containerAdressAdd me-2">
                                <input
                                    type="checkbox"
                                    name="invoiceaddress"
                                    checked={formData.invoiceaddress === true}
                                    onChange={(e) => setFormData(prev => ({ ...prev, invoiceaddress: e.target.checked }))}
                                />
                                <span className="checkmarkAdressAdd"></span>
                            </label>
                            <p className="text-black fitContent mb-0 fw-medium">Factuuradres</p>
                        </div>
                        <div className="d-flex flex-row align-items-center mb-3">
                            <label className="checkbox-containerAdressAdd me-2">
                                <input
                                    type="checkbox"
                                    name="deliveryaddress"
                                    checked={formData.deliveryaddress === true}
                                    onChange={(e) => setFormData(prev => ({ ...prev, deliveryaddress: e.target.checked }))}
                                />
                                <span className="checkmarkAdressAdd"></span>
                            </label>
                            <p className="text-black fitContent mb-0 fw-medium">Bezorgadres</p>
                        </div>
                        <div className="mb-4">
                            <div className="col-12 d-flex flex-row align-content-end my-3">
                                <InputComponent
                                    type={"text"}
                                    onValueChange={getValueInput}
                                    parentCol={6}
                                    labelName={"Postcode"}
                                    labelValue={"Postalcode"}
                                    disabled={!countryIdInfo.country_code_2}
                                    classnamesInput={"col-12 widthInputComponents px-3 " + (postalCodeError ? "border-danger border-2" : "")}
                                    defaultValue={adressData.Postalcode || formData.Postalcode}
                                />
                                <div className="col-6 d-flex justify-content-end">
                                    <div className="col-12 d-flex widthInputComponents flex-row justify-content-end">
                                        <InputComponent
                                            alignmentInputs={"justify-content-start"}
                                            onValueChange={getValueInput}
                                            type={"number"}
                                            parentCol={6}
                                            labelName={"Huisnummer"}
                                            labelValue={"HouseNumber"}
                                            disabled={!countryIdInfo.country_code_2}
                                            classnamesInput={"col-12 widthInputComponents px-3 " + (postalCodeError ? "border-danger border-2" : "")}
                                            defaultValue={adressData.HouseNumber || formData.HouseNumber}
                                        />
                                        <InputComponent
                                            alignmentInputs={"justify-content-end"}
                                            onValueChange={getValueInput}
                                            type={"text"}
                                            valueType={1}
                                            parentCol={6}
                                            labelName={"Toevoeging"}
                                            labelValue={"Addition"}
                                            disabled={!countryIdInfo.country_code_2}
                                            classnamesInput={"col-12 widthInputComponents px-3"}
                                            defaultValue={adressData.Addition || formData.Addition}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex flex-row align-content-end my-3">
                                <InputComponent
                                    type={"text"}
                                    onValueChange={getValueInput}
                                    alignmentInputs={"justify-content-start"}
                                    parentCol={6}

                                    labelName={
                                        <>
                                            Straat
                                            {countryIdInfo.country_code_2 === "NL" && formData.StreetName && (
                                                <FontAwesomeIcon
                                                    style={{ marginLeft: "5px", cursor: "pointer" }}
                                                    onClick={() => setFormData(prev => ({ ...prev, StreetNameEditable: !prev.StreetNameEditable }))}
                                                    icon={faPencilAlt}
                                                />
                                            )}
                                        </>
                                    }
                                    valueType={1}
                                    labelValue={"StreetName"}
                                    classnamesInput={"col-12 widthInputComponents px-3"}
                                    defaultValue={adressData.StreetName || formData.StreetName}
                                    disabled={(!countryIdInfo.country_code_2) || (!formData.StreetNameEditable && countryIdInfo.country_code_2 === "NL")}
                                />
                                <InputComponent
                                    type={"text"}
                                    onValueChange={getValueInput}
                                    alignmentInputs={"justify-content-end"}
                                    parentCol={6}
                                    labelName={
                                        <>
                                            Stad
                                            {countryIdInfo.country_code_2 === "NL" && formData.City && (
                                                <FontAwesomeIcon
                                                    style={{ marginLeft: "5px", cursor: "pointer" }}
                                                    onClick={() => setFormData(prev => ({ ...prev, CityEditable: !prev.CityEditable }))}
                                                    icon={faPencilAlt}

                                                />
                                            )}
                                        </>
                                    }
                                    valueType={1}
                                    labelValue={"City"}
                                    classnamesInput={"col-12 widthInputComponents px-3"}
                                    defaultValue={adressData.City || formData.City}
                                    disabled={(!countryIdInfo.country_code_2) || (!formData.CityEditable && countryIdInfo.country_code_2 === "NL")}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <button className="btn DarkStandardButton" type="button" onClick={() => { props.setAddAddressModalVisible(false) }}>Annuleren</button>
                        <button className="btn lightBlueStandardButton" onClick={() => addAddress()}>{"Toevoegen"}</button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modalBottom">
            </Modal.Footer>
        </Modal>
    )
}

export default AddAddressModal
