import React, { useState, useEffect, useCallback, useMemo } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import TableDrag from "../Table/TableDrag/TableDrag";
import middleware from "../Api/Middleware";
import PageTemplate from "../Templates/PageTemplate";
import * as Icons from "../HouseStyle/Icons";
import BrandModals from "../Modals/BrandModal";
import "./Brands.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPen } from "@fortawesome/free-solid-svg-icons";
import TablePreset from "../Table/TablePreset";
import { useTable } from "react-table";
import SortableHeader from "../Table/TableComponents/SortableHeader";
import CheckboxCell from "../Table/TableComponents/CheckboxCell";
import CheckboxHeader from "../Table/TableComponents/CheckboxHeader";
import ConfirmationDeactivationModal from "../Modals/GeneralSettings/ConfirmationDeactivationModal";

const Brands = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  }); // the useState for the pagination for the table
  const [loading, setLoading] = useState(true); // useState for page loading
  const [loadingPage, setLoadingPage] = useState(false);
  const [brands, setBrands] = useState([]); // useState for Brands
  const [show, setShow] = useState(false); // useState for the Loadingscreen
  const [searchengine, setSearchengine] = useState("");
  const [showBrandNew, setShowBrandNew] = useState(false);
  const [showBrandEdit, setShowBrandEdit] = useState(false);
  const [brandEditData, setBrandEditData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [order, setOrder] = useState({ orderBy: '', direction: '' }); // useState for ordering the columns in the table
  const [searchBar, setSearchBar] = useState("");

  const fetchAll = async () => {
    try {
      setLoading(true);
      const searchQuery = searchBar.trim() ? `&searchTerm=${encodeURIComponent(searchBar.trim())}&noSearch=["primary_key","version","brand_id","status","updated_at","created_at","updator_user_id","updator_user_id"]` : "";
      const [fetchBrands] = await Promise.all([
        middleware.get(
          `sp/brandsTableActive?page=${pagination.page}&page_size=${pagination.page_size}${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}` : ''}${searchQuery}`),
      ]);

      // const updatedBrandsOrder = fetchBrands.data.data.map((item, index) => ({
      //   ...item,
      //   sorting_order:
      //     index +
      //     (pagination.page === 1
      //       ? 0
      //       : pagination.page_size * (pagination.page - 1)) +
      //     1, // updating the sorting order based on position
      // }));

      // const isOrderChanged = fetchBrands.data.data.some((item, index) => {
      //   return item.sorting_order !== updatedBrandsOrder[index].sorting_order;
      // });

      // if (isOrderChanged) {
      //   await middleware.put(`products/brands`, {
      //     bulk_data: updatedBrandsOrder,
      //   });
      // }

      setBrands(fetchBrands.data.data); // sets the brands usestate with the fetched data
      setPagination({
        ...pagination,
        max_items: fetchBrands.data.total,
        begin_of_page: fetchBrands.data.from,
        end_of_page: fetchBrands.data.to,
      }); // sets the pagination usestate with the max items begin of the page and the end of the page
      setLoadingScreenFunc();
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };


  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page, order]);

  useEffect(() => {
    try {
      if (searchBar.trim() === "" && searchBar === "") {
        // Code to fetch all when searchengine is empty
        fetchAll();
      } else if (searchBar.trim() !== "") {
        fetchAll();
      }
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  }, [searchBar]);


  const handleSearchChange = useCallback((value) => {
    setSearchBar(value);
    setPagination(prev => ({ ...prev, page: 1 }));
  }, []);


  const columns = useMemo(
    () => {
      let columnsArray = [
        {
          Header: (
            <CheckboxHeader currentItem={brands} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'primary_key'}>
            </CheckboxHeader>
          ),
          accessor: "select",
          Cell: ({ row }) => {
            return <CheckboxCell row={row} selectedIds={selectedIds} pk={'primary_key'} />
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field="name" pos={'start'}>
              Merk naam
            </SortableHeader>
          ),
          accessor: "name",
          Cell: ({ row }) => {
            return <div className="text-start FlatTextLink " style={{ cursor: "pointer" }} onClick={() => { setShowBrandEdit(true); setBrandEditData(row.original); }}>{row.original.name}</div>;
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field="description" pos={'start'}>
              Omschrijving
            </SortableHeader>
          ),
          accessor: "description",
          Cell: ({ row }) => {
            return <div className="text-start BlackText">{row.original.description}</div>;;
          }
        },
      ];
      return columnsArray;
    },
    [order.orderBy, order.direction, selectedIds, setSelectedIds, brands]
  );

  //   Table instance
  const tableInstance = useTable({ columns, data: brands });

  const handleSelection = (sizeId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, sizeId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== sizeId));
    }
  };

  const deactivateBrands = async () => {
    try {
      const promises = selectedIds.map(id =>
        middleware.put(`products/brands`, {
          primary_key: id,
          status: 0,
        }));
      await Promise.all(promises);
      setSelectedIds([]);
      // Optionally, you can refresh the data or perform other actions here
      fetchAll();
    } catch (error) {
      console.error("Error deactivating sizes:", error);
    }
  };


  function setLoadingScreenFunc() {
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }

  return (
    <>
      <BrandModals brandEdit={showBrandEdit} setBrandEdit={(e) => setShowBrandEdit(e)} brandEditData={brandEditData} brandNew={showBrandNew} setBrandNew={(e) => setShowBrandNew(e)} AddedOrEditedBrand={() => fetchAll()} />
      <ConfirmationDeactivationModal showConfirmaton={show} setShowConfirmation={(e) => setShow(e)} selectedIds={selectedIds} handleDeativation={() => deactivateBrands()} />
      <PageTemplate navbarTitle={"Merken Overzicht"} pageIsLoading={loadingPage} backValue={"/warehouse/instellingen"}>
        <div className="w-100">
          <div className="brandDrag">
            {/* <TableDrag
              dataParameter={brands}
              tableLoading={loading}
              pagination={pagination}
              handlePaginationChange={(event) => setPagination(event)}
              primaryKey={"primary_key"}
              urlEndPoint={"products/brands"}
              newItemTitle={"merk"}
              openModal={(e) => openBrandModal(e)}
              dataEditModalSize={() => {}}
              searchTerm={(e) => searchDelay(e)}
              columns={[
                { key: "sorting_order", title: "Sorteer volgorde" },
                { key: "name", title: "Naam" },
                { key: "description", title: "Beschrijving", maxLength: 60 },
              ]}
            /> */}
            <TablePreset
              data={[{ 'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'primary_key' }]}
              tableLoading={loading}
              actionOptions={[{ title: "Deactiveren", function: () => setShow(true) }]
              }
              searchBar={[{ shown: true, searchFunction: handleSearchChange, }]}
              handleSelection={handleSelection}
              setPagination={setPagination}
              // rightDropdowns={[{
              //     placeholder: 'Filter op categorie',
              //     options: [{title: "Optie1"}, {title: "Optie2"}]
              // }]}
              leftButtons={[]}
              rightButtons={[{ title: 'Nieuw merk', function: () => setShowBrandNew(true) }]}
            />
          </div>
        </div>
      </PageTemplate>
    </>
  );
};

export default Brands;
