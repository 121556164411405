import React, { useState, useRef, useEffect, forwardRef } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SizeClassCorrespondance from './SizeClassCorrespondance'

export const RegularDropDown = forwardRef(({
  placeholder = "Actie",
  options = [],
  noOptionsMessage = "Geen acties mogelijk",
  onOptionSelect,
  selectedValue,
  setSelectedValue,
  size = "l"
},ref) => {
  const [showRegularDropDown, setShowRegularDropDown] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setShowRegularDropDown((prev) => !prev);
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowRegularDropDown(false);
    }
  };

  useEffect(() => {
    if (showRegularDropDown) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [showRegularDropDown]);

  const handleOptionClick = (option) => {
    if (option.function) {
      option.function(); // Execute the function in the options object
    }
    if (setSelectedValue) {
      setSelectedValue(option.value); // Set the external state if provided
    }
    setShowRegularDropDown(false); // Close the dropdown
  };

  return (
    <div className={`RegularDropDownContainer ${SizeClassCorrespondance[size]}`} ref={dropdownRef}>
      <div
        className={`RegularDropDownToggle ${showRegularDropDown ? "open" : ""}`}
        onClick={handleToggle}
        ref={ref}
      >
        <span className="RegularDropDownText">
          {selectedValue ? options.filter(filter => filter.value == selectedValue).map(option => option.title) : placeholder}
        </span>
        <FontAwesomeIcon
          className={`RegularDropDownIndicator ${
            showRegularDropDown ? "rotate" : ""
          }`}
          icon={faChevronDown}
        />
      </div>
      <div
        className={`RegularDropDownList ${
          showRegularDropDown ? "open" : "closed"
        }`}
      >
        {options.length === 0 ? (
      <div className="RegularDropDownItem">{noOptionsMessage}</div>
        ) : (
          options.map((option, index) => (
            <div
              key={index}
              className={`RegularDropDownItem ${selectedValue && option.value && selectedValue == option.value ? "selected" : ""} ${option.disabled ? "disabled" : ""}`}
              onClick={() => {
                if (!option.disabled) {
                  handleOptionClick(option);
                }
              }}
            >
              {option.title}
            </div>
          ))
        )}
      </div>
    </div>
  );
});

export const BorderedDropDown = forwardRef(({
  placeholder = "Actie",
  options = [],
  noOptionsMessage = "Geen acties mogelijk",
  onOptionSelect,
  label = null,
  selectedValue,
  setSelectedValue,
  size = 'l',
  error = false,
  errorTooltip = false
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [isOpen]);

  const handleOptionClick = (option) => {
    if (option.onClick) {
      option.onClick(); // Execute the function in the options object
    }
    if (setSelectedValue) {
      setSelectedValue(option.value); // Set the external state if provided
    }
    setIsOpen(false); // Close the dropdown
  };

  return (
    <div className={`BorderedDropDownContainer ${SizeClassCorrespondance[size]}`} ref={dropdownRef}>
      {label && <label>{label}</label>}
      <div className={`input-container w-100`}>
        <div
          className={`BorderedDropDownToggle ${isOpen ? "open" : ""} ${error && "error"}`}
          onClick={handleToggle}
          ref={ref}
        >
          <span className={`BorderedDropDownText`}>
            {selectedValue
              ? options.find((option) => option.value === selectedValue)?.title ||
                placeholder
              : placeholder}
          </span>
          <FontAwesomeIcon
            className={`BorderedDropDownIndicator ${isOpen ? "rotate" : ""}`}
            icon={faChevronDown}
          />
        </div>
        <div className={`BorderedDropDownList ${isOpen ? "open" : "closed"}`}>
          {options.length === 0 ? (
            <div className="BorderedDropDownItem">{noOptionsMessage}</div>
          ) : (
            options.map((option, index) => (
              <div
                key={index}
                className={`BorderedDropDownItem ${option.value && selectedValue && selectedValue === option.value ? "selected" : ""} ${option.disabled ? "disabled" : ""}`}
                onClick={() => {
                  if (!option.disabled) {
                    handleOptionClick(option);
                  }
                }}
              >
                {option.title}
              </div>
            ))
          )}
        </div>
        {error && errorTooltip && <div className="tooltip">{errorTooltip}</div>}
      </div>
    </div>
  );
});