export const data = [
    {
        records: 'test',
        ttl: '3403'
    }
];

export const dns = [
    {
        data: 'testdatagegevens',
        type: "TXT",
        ttl: '5423'
    }
];

export const spf = [
    {
        host: 'host',
        ip: '98479837',
        spf: 'pass',
        spfunedit: '+'
    }
]

export const dmarc = [
    {
        records: 'testdmarc',
        ttl: '340ds3'
    }
]