import { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { useTable } from "react-table";

import PageTemplate from "../Templates/PageTemplate";
import exportOptions from "./Components/exportOptions";
import TablePreset from "../Table/TablePreset";
import middleware from "../Api/Middleware";
import SortableHeader from "../Table/TableComponents/SortableHeader";
import ToastError from "../Toasts/ToastError";
import CheckboxGrid from "./Components/checkboxGrid";

function ExportPage() {
    const [tableLoading, setTableLoading] = useState(false);
    const [selectedTable, setSelectedTable] = useState(""); // Selected head table
    const [selectedGroupTables, setSelectedGroupTables] = useState([]); // Group checkboxes
    const [fileName, setFileName] = useState(""); // Description for the file
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]); // Data of the selected table
    const [pagination, setPagination] = useState({
        page_size: 10,
        page: 1,
        max_items: 0,
        begin_of_page: 0,
        end_of_page: 0,
    });
    const [order, setOrder] = useState({ orderBy: "", direction: "" });
    const navigate = useNavigate();

    // Fetch data for the currently selected main table or group
    const fetchTableData = async () => {
        setTableLoading(true);
        try {
            if (!exportOptions.exportTypes[selectedTable]) {
                return;
            }

            // Create an array of all selected tables (main table + selected groups)
            const withKeys = selectedGroupTables.reduce((acc, groupKey) => {
                return acc.concat(exportOptions.groupOptions[exportOptions.exportTypes[selectedTable].displayName][groupKey].withKeys);
            }, []);    
            
            const requestPayload = {
                main_model: exportOptions.exportTypes[selectedTable].model,
                foreign_key: exportOptions.exportTypes[selectedTable].foreignKey,
            };
            
            // Convert requestPayload and additional parameters to a query string, excluding models from URLSearchParams
            const queryParams = new URLSearchParams({
                main_model: requestPayload.main_model,
                foreign_key: requestPayload.foreign_key,
                page: pagination.page,
                page_size: pagination.page_size,
                ...(order.orderBy && order.direction ? { orderBy: order.orderBy, direction: order.direction } : {})
            }).toString();
            
            // Append models as an array in the request body instead
            const tableDataResults = await middleware.get(`/export?${queryParams}`, {
                params: { models: requestPayload.models, withkeys: withKeys || []}
            });
            


            console.log(tableDataResults);
    
            // Set table data (aggregated from all selected tables)
            // const aggregatedData = tableDataResults.map((result) => result.data || []);
            setTableData(tableDataResults.data.data);
            // console.log(aggregatedData);
    
            // Update pagination based on the primary table’s data (or modify as needed for aggregation)
            setPagination({
                ...pagination,
                max_items: tableDataResults.data.pagination.total_records,
                begin_of_page: (tableDataResults.data.pagination.page - 1) * parseInt(tableDataResults.data.pagination.page_size),
                end_of_page: tableDataResults.data.pagination.page * parseInt(tableDataResults.data.pagination.page_size),
            });
        } catch (error) {
            setTableData([]);
            console.error("Error fetching data:", error);

            if (error.response) {
                ToastError(error.response.data.error)
                console.error(error.response.data);
            }
        }
        setTableLoading(false);
    };

    // Start export for selected main table and any checked groups
    const StartExport = async () => {
        if (!selectedTable) return;
        try {
            setLoading(true);
            if (!exportOptions.exportTypes[selectedTable]) {
                return;
            }

            // Create an array of all selected tables (main table + selected groups)
            const withKeys = selectedGroupTables.reduce((acc, groupKey) => {
                return acc.concat(exportOptions.groupOptions[exportOptions.exportTypes[selectedTable].displayName][groupKey].withKeys);
            }, []);

            const groupOptionsDisplayNames = selectedGroupTables
            .reduce((acc, groupKey) => {
                return acc.concat(exportOptions.groupOptions[exportOptions.exportTypes[selectedTable].displayName][groupKey].displayName);
            }, [])
            .join(', ');
            
            const requestPayload = {
                selected_group_options_label: groupOptionsDisplayNames || "",
                main_model: exportOptions.exportTypes[selectedTable].model,
                foreign_key: exportOptions.exportTypes[selectedTable].foreignKey,
                withkeys: withKeys,
                description: fileName,
                table: selectedTable
            };
            
            const csvDataResult = await middleware.post(`/export`, requestPayload);

            navigate(`/export/resultaat/${csvDataResult.data.export_id}`);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    // Clear and fetch table data when changing the main table
    useEffect(() => {
        setSelectedGroupTables([]);
        fetchTableData();
    }, [selectedTable]);

    useEffect(() => {
        fetchTableData();
    }, [pagination.page_size, pagination.page, order, order.direction, selectedGroupTables]);

    // Table columns
    const columns = useMemo(() => {
        if (!tableData || tableData.length === 0) return [];
        // return [];
        // Gather all unique keys across all rows, including indexed fields
        const allKeys = new Set();
        tableData.forEach(row => {
            Object.keys(row).forEach(key => allKeys.add(key));
        });
    
        return Array.from(allKeys).map((key) => ({
            Header: key,
            accessor: key,
            Cell: ({ row }) =>
                key === "product_description" ? (
                    <div dangerouslySetInnerHTML={{ __html: row.original[key] && row.original[key].length > 40
                        ? `${row.original[key].slice(0, 40)}...`
                        : row.original[key] }} />
                ) : (
                    <p>{row.original[key] && row.original[key].length > 40
                        ? `${row.original[key].slice(0, 40)}...`
                        : row.original[key]}</p>
                ),
        }));
    }, [tableData, order.orderBy, order.direction]);
     

    const tableInstance = useTable({ columns, data: tableData });

    // Handle checkbox selection for grouped tables
    const handleCheckboxChange = (key) => {
        if (key === "all") {
            // If "all" is selected, either select all group options or clear all
            setSelectedGroupTables((prev) => 
                prev.length === Object.keys(exportOptions.groupOptions[exportOptions.exportTypes[selectedTable].displayName]).length
                    ? [] // Unselect all if all are selected
                    : Object.keys(exportOptions.groupOptions[exportOptions.exportTypes[selectedTable].displayName]) // Select all
            );
        } else {
            // Toggle individual group options
            setSelectedGroupTables((prev) =>
                prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]
            );
        }
    };

    const isAllSelected = 
    selectedTable &&
    selectedGroupTables &&
    exportOptions.exportTypes[selectedTable] &&
    exportOptions.groupOptions[exportOptions.exportTypes[selectedTable].displayName] &&
    selectedGroupTables.length === Object.keys(exportOptions.groupOptions[exportOptions.exportTypes[selectedTable].displayName]).length;

    return (
        <PageTemplate pageIsLoading={loading} navbarTitle={"Nieuwe Export"} backValue={"/import-export-center"}>
            <div className="d-flex flex-column justify-content-center w-100 gap-4">
                <div className="d-flex flex-column align-content-around whiteBox w-100 p-4 rounded shadow-sm" style={{ backgroundColor: "#f9f9f9" }}>
                    <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                        <p className="fw-bold w-25 mb-0">Exporteer type</p>
                        <select
                            className="form-select flex-grow-1"
                            value={selectedTable}
                            onChange={(e) => setSelectedTable(e.target.value)}
                            required
                        >
                            <option value={""} hidden>
                                -- Selecteer een export type --
                            </option>
                            {Object.entries(exportOptions.exportTypes).map(([key, value]) => (                                
                                <option value={key} key={key}>
                                    {value.displayName}
                                </option>
                            ))}
                        </select>
                    </div>

                    {selectedTable && exportOptions.groupOptions[exportOptions.exportTypes[selectedTable].displayName] && (
                        <div className="d-flex flex-column mb-3">
                            <p className="fw-bold mb-1">Groepopties</p>
                            
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkbox-all"
                                    checked={isAllSelected}
                                    onChange={() => handleCheckboxChange("all")}
                                />
                                <label className="form-check-label" htmlFor="checkbox-all">Alles</label>
                            </div>

                            {/* Container to display checkboxes in columns of 3 */}
                            <CheckboxGrid
                                exportOptions={exportOptions}
                                selectedTable={selectedTable}
                                selectedGroupTables={selectedGroupTables}
                                handleCheckboxChange={handleCheckboxChange}
                            />
                        </div>
                    )}

                    <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                        <p className="fw-bold w-25 mb-0">Beschrijving</p>
                        <input
                            type="text"
                            className="form-control flex-grow-1"
                            placeholder="Bestand beschrijving"
                            value={fileName}
                            onChange={(e) => setFileName(e.target.value)}
                        />
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        <button
                            onClick={StartExport}
                            disabled={!(selectedTable && fileName)}
                            className="btn btn-success d-flex align-items-center justify-content-center w-50 py-2"
                            style={{ background: "linear-gradient(145deg, #4CAF50, #66BB6A)", border: "none", borderRadius: "5px" }}
                        >
                            <FontAwesomeIcon icon={faCloudArrowDown} className="me-2" />
                            <span>Exporteer</span>
                        </button>
                    </div>
                </div>

                <div className="w-100">
                    {tableData.length > 0 ? (
                        <TablePreset
                            data={[
                                {
                                    tableInstance: tableInstance,
                                    pagination: pagination,
                                    primary_key: "consumer_id",
                                },
                            ]}
                            tableLoading={tableLoading}
                            setPagination={setPagination}
                            handleSelection={() => {}}
                        />
                    ) : (
                        <div className="whiteBox text-secondary text-center">Geen data om te exporteren</div>
                    )}
                </div>
            </div>
        </PageTemplate>
    );
}

export default ExportPage;
