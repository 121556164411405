import { toast } from 'react-toastify';

const ToastSuccess = (text, id, timeOpen = 5000) => {
  let status = true;
  if (toast.isActive(id)) {
    status = false;
  }
  if (status) {
    return (
      toast.success(text, {
        toastId: id,
        position: "top-right",
        autoClose: timeOpen,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        style: {
          top: '100px'
        }
      })
    )
  }
}

export default ToastSuccess;