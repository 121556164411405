import React, { useEffect, useState } from 'react';
import LoadingIcon from '../../HouseStyle/Components/LoadingIcon';
import salesChannelsApi from '../../Api/SalesChannels';
import ToastError from '../../Toasts/ToastError';
import { ButtonDarkBlue } from '../../HouseStyle/Components/Buttons';

const WooCommercePluginInformation = ({ salesChannel }) => {
    const [connectionLoading, setConnectionLoading] = useState(true);
    const [connection, setConnection] = useState([]);

    const loadConnection = async () => {
        try {
            const [connection] = await Promise.all([
                salesChannelsApi.get(`woocommerce/checkConnection?sales_channel_id=${salesChannel.sales_channel_id}`),
            ]);
            setConnection(connection.data);
            setConnectionLoading(false);
        } catch (error) {
            console.error("Error fetching connection data:", error);
        }
    };

    useEffect(() => {
        // Load connection data once
        loadConnection();

        // Set an interval to check the connection every 30 secondes
        const intervalId = setInterval(loadConnection, 30 * 1000);

        return () => clearInterval(intervalId);
    }, []);

    const handleLogin = async () => {
        try {
            const response = await salesChannelsApi.get(`saleschannels/wpLoginToken?sales_channel_id=${salesChannel.sales_channel_id}`);

            // Create a form element
            const form = document.createElement("form");
            form.method = "POST";
            form.action = `https://${salesChannel.domain_name}/wp-json/unifiedsmb/v1/login`;
            form.target = "_blank";

            // Create username input
            const usernameInput = document.createElement("input");
            usernameInput.type = "hidden";
            usernameInput.name = "username";
            usernameInput.value = response.data.username;
            form.appendChild(usernameInput);

            // This needs to be changed to the apikey.
            const passwordInput = document.createElement("input");
            passwordInput.type = "hidden";
            passwordInput.name = "Woocommerce-apiKey";
            passwordInput.value = response.data.apiKey;
            form.appendChild(passwordInput);

            // Append the form to the DOM and submit it
            document.body.appendChild(form);
            form.submit();

            // Remove the form from the DOM
            form.remove();
        } catch (error) {
            ToastError("Er is een fout opgetreden bij het inloggen.");
        }
    };

    return (
        <div>
            {connectionLoading ? (
                <div className="d-flex justify-content-center w-100 mb-3">
                    <LoadingIcon />
                </div>
            ) : (
                <>
                    <div className='mb-3'>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className="inputTitle">{salesChannel.domain_name}</h5>
                            <img className='rounded-5' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjor9YQrc2ttjk6vou_EYGEswuXZtC_pnvwA&s' width={32} height={32} />
                            {connection.plugin?.active ? <ButtonDarkBlue text={'Inloggen'} onClickFunction={handleLogin} /> : ''}
                        </div>
                        <div>
                            Alle informatie over uw verkoopkanaal.
                        </div>
                    </div>

                    {/* Status Sections */}
                    <div className="d-flex flex-column flex-lg-row mt-4">
                        {/* WooCommerce API Status */}
                        <div className="col-6">
                            <h6>WooCommerce API Status</h6>
                            <ul>
                                <li>Lezen: {connection.read ? '✅ Toegang verleend' : '❌ Geen toegang'}</li>
                                <li>Schrijven: {connection.write ? '✅ Toegang verleend' : '❌ Geen toegang'}</li>
                                <li>Status: {connection.connection ? '✅ Verbonden' : '❌ Niet verbonden'}</li>
                            </ul>
                        </div>

                        {/* Plugin Status */}
                        <div className='col-6'>
                            <h6>Plugin Status</h6>
                            <ul>
                                <li>Plugin Actief: {connection.plugin?.active ? '✅ Ja' : connection.connection ? '❌ Nee' : 'Niet beschikbaar'}</li>
                                <li>Huidige Versie: {connection.plugin?.version || 'Niet beschikbaar'}</li>
                                <li>Nieuwste Versie: {connection.plugin?.version_latest || 'Niet beschikbaar'}</li>
                            </ul>
                        </div>
                    </div>

                    {/* WooCommerce API not connected Notification */}
                    {!connection.connection && (
                        <div className="alert alert-danger mt-3">
                            Er is geen verbinding met de WooCommerce API. <br />
                            Controleer of de plugin correct is geïnstalleerd en geactiveerd.
                        </div>
                    )}

                    {/* Plugin Update Notification */}
                    {connection.plugin?.active && connection.plugin.version < connection.plugin.version_latest && (
                        <div className="alert alert-warning mt-3">
                            Er is een nieuwe versie van de plugin beschikbaar! <br />
                            <strong>Huidige versie:</strong> {connection.plugin.version} <br />
                            <strong>Nieuwste versie:</strong> {connection.plugin.version_latest} <br />
                            Werk de plugin bij voor de nieuwste functies en beveiligingsupdates.
                        </div>
                    )}

                    {/* Plugin Inactive Notification */}
                    {!connection.plugin?.active && connection.connection && (
                        <div className="alert alert-danger mt-3">
                            De plugin is momenteel niet actief. Activeer de plugin om alle functies te kunnen gebruiken.
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default WooCommercePluginInformation;