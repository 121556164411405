// MainPage.js
import React, { useEffect, useState } from "react";
import "./MainPage.css";
import Loading from "../LoadingScreen/LoadingScreen";
import Logo from "./Icons/Logo Unified SMB - Blauw.png";
import PageTemplate from "../Templates/PageTemplate";
import middleware from "../Api/Middleware";
import { useNavigate } from "react-router-dom";
import AnimatedLineWithDoubleBump from './line/AnimatedLineWithDoubleBump'; // Importeer de component

const MainPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hubs, setHubs] = useState([]);
  const savedCollapsed = localStorage.getItem("CollapsedSidebar");
  const [collapsedSidebar, setCollapsedSidebar] = useState(
    savedCollapsed ? JSON.parse(savedCollapsed) : false
  );

  const calculateViewWidth = (windowWidth, isCollapsed) => {
    if (windowWidth <= 1300) {
      return windowWidth - 100;
    } else {
      return windowWidth - (isCollapsed ? 100 : 270);
    }
  };

  const [viewWidth, setViewWidth] = useState(() =>
    calculateViewWidth(window.innerWidth, collapsedSidebar)
  );
  const [viewHeight, setViewHeight] = useState(window.innerHeight - 160);
  const navigate = useNavigate();
  
  // Definieer animatieklassen en hun bijbehorende parameters
  const animationMappings = [
    {
      className: "floatUpDown",
      x2Values: (x) => `${x}; ${x + 5}; ${x}`,
      y2Values: (y) => `${y}; ${y - 5}; ${y}`,
      cssAnimation: "floatUpDown",
      duration: "6000ms",
    },
    {
      className: "floatDownUp",
      x2Values: (x) => `${x}; ${x - 5}; ${x}`,
      y2Values: (y) => `${y}; ${y + 5}; ${y}`,
      cssAnimation: "floatDownUp",
      duration: "8000ms",
    },
    {
      className: "floatLeftRight",
      x2Values: (x) => `${x}; ${x - 10}; ${x}`,
      y2Values: (y) => `${y}; ${y + 5}; ${y}`,
      cssAnimation: "floatLeftRight",
      duration: "7000ms",
    },
    {
      className: "floatRightLeft",
      x2Values: (x) => `${x}; ${x + 10}; ${x}`,
      y2Values: (y) => `${y}; ${y - 5}; ${y}`,
      cssAnimation: "floatRightLeft",
      duration: "9000ms",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setViewWidth(calculateViewWidth(window.innerWidth, collapsedSidebar));
      setViewHeight(window.innerHeight - 160);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [collapsedSidebar]);
  

  // Functie om alle hubs op te halen
  const fetchAll = async () => {
    setIsLoading(true);
    try {
      const fetchHubs = await middleware.get("sp/dashboardHubs");
      setHubs(fetchHubs.data);
    } catch (error) {
      console.error("Error fetching hubs:", error);
    }
    setIsLoading(false);
  };

  // Functie om de staat van de sidebar te verkrijgen
  const GetCollapsedSidebar = (collapsed) => {
    setCollapsedSidebar(collapsed);
  };

  // Luister naar veranderingen in localStorage voor de sidebar
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "CollapsedSidebar") {
        setCollapsedSidebar(event.newValue ? JSON.parse(event.newValue) : false);
      }
    };

    const handleLocalStorageChange = () => {
      const saved = localStorage.getItem("CollapsedSidebar");
      setCollapsedSidebar(saved ? JSON.parse(saved) : false);
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("localStorageChange", handleLocalStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("localStorageChange", handleLocalStorageChange);
    };
  }, []);

  // Initialisatie van viewWidth en viewHeight
  useEffect(() => {
    setViewWidth(
      viewWidth <= 1300
        ? window.innerWidth - 100
        : window.innerWidth - (collapsedSidebar ? 100 : 270)
    );
    setViewHeight(window.innerHeight - 160);
  }, [collapsedSidebar]);

  // Haal hubs op bij component mount
  useEffect(() => {
    fetchAll();
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  // Schaalparameters
  const maxWidth = 1750; // Begin met schalen vanaf deze breedte
  const minWidth = 1180; // Eindig met schalen bij deze breedte
  let scaleFactor = 1;

    const range = maxWidth - minWidth; // 1750 - 1180 = 570
    const relativeWidth = viewWidth - minWidth;
    const fraction = relativeWidth / range; 
    // fraction: 0 bij 1180px, 1 bij 1750px
    scaleFactor = 0.6 + fraction * 0.4; 
    // bij 1750px fraction=1 -> 0.6+0.4=1
    // bij 1180px fraction=0 -> 0.6+0=0.6

  // Functie om de grootte van hubs aan te passen op basis van hun status, schermbreedte, en totaal aantal hubs

  const getNodeSize = (status) => {
    let baseSize = 150; // Standaard hubgrootte
    let minNodeSize = 100; // Minimale hubgrootte, pas dit aan naar wens
    
    if (viewHeight < 650) {
      minNodeSize = 85
    }
    // Schaal de hubgrootte verder op basis van scaleFactor
    baseSize *= scaleFactor;
  
    // Pas op basis van de status de hubgrootte aan
    let finalSize;
    switch (status) {
      case 0: // Actief
        finalSize = baseSize;
        break;
      case 1: // Installeren
      case 2: // Inactief
        finalSize = baseSize * 0.9;
        break;
      default:
        finalSize = baseSize;
        break;
    }
  
    // Zorg ervoor dat de hub niet kleiner wordt dan minNodeSize
    return Math.max(finalSize, minNodeSize);
  };
  

  // Functie om de status tekst te verkrijgen
  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Actief";
      case 1:
        return "Installing";
      case 2:
        return "Inactive";
      default:
        return "Onbekend";
    }
  };

  // Functie om de achtergrondkleur voor het statusicoon te verkrijgen
  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#009cde"; // Actief - Blauw
      case 1:
        return "#ffb36d"; // Installeren - Oranje/Geel
      case 2:
        return "#f97374"; // Inactief - Rood
      default:
        return "gray"; // Onbekende status
    }
  };

  // Functie om de lijndstijl te verkrijgen op basis van hub status
  const getLineStyle = (status) => {
    switch (status) {
      case 0: // Actief
        return {
          stroke: "#009cde", // Blauw
          strokeWidth: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          className: "active-line",
          filter: "url(#shadow)", // Gebruik drop shadow filter voor actieve lijnen
        };
      case 1: // Installeren
        return {
          stroke: "#abcadc",
          strokeWidth: 2,
          strokeDasharray: "7",
          strokeDashoffset: 7,
          className: "installing-line",
          filter: "none",
        };
      case 2: // Inactief
        return {
          stroke: "#abcadc",
          strokeWidth: 2,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          className: "inactive-line",
          filter: "none",
        };
      default:
        return {
          stroke: "gray",
          strokeWidth: 2,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          className: "default-line",
          filter: "none",
        };
    }
  };

  // Functie om posities langs een afgeronde rechthoek en binnenin te berekenen
  const getDynamicPositions = (hubs) => {
    const isNarrowScreen = viewWidth < 1600; // Snap moment bij 1700px
    const maxPerimeterHubsBase = 14; // Basis aantal perimeter hubs
    const maxInnerHubsPerSide = 2; // Altijd 2 per kant in de inner ring
    const maxInnerHubs = maxInnerHubsPerSide * 2; // Totaal aantal inner hubs (4)

    let maxPerimeterHubs = maxPerimeterHubsBase;
    let actualInnerHubs = maxInnerHubs;
    let maxFurtherOuterHubs = 0;

    if (isNarrowScreen) {
      // Bij breedte <1600px: verplaats altijd 4 inner hubs naar de outer ring
      maxFurtherOuterHubs = maxInnerHubs; // Zet alle inner hubs in de verdere outer ring
      actualInnerHubs = 0; // Geen inner hubs meer
    }

    // Slice hubs op basis van de berekende limieten
    const perimeterHubs = hubs.slice(0, maxPerimeterHubs);
    const innerHubs = hubs.slice(maxPerimeterHubs, maxPerimeterHubs + actualInnerHubs);
    const furtherOuterHubs = hubs.slice(maxPerimeterHubs + actualInnerHubs, maxPerimeterHubs + actualInnerHubs + maxFurtherOuterHubs);
    const outerHubs = hubs.slice(maxPerimeterHubs + actualInnerHubs + maxFurtherOuterHubs);

    // Bereken posities voor perimeter hubs
    const perimeterPositions = calculatePerimeterPositions(perimeterHubs);

    // Bereken posities voor inner hubs
    const innerPositions = calculateInnerPositions(innerHubs, maxInnerHubsPerSide);

    // Bereken posities voor verdere outer hubs
    const furtherOuterPositions = calculateFurtherOuterPositions(furtherOuterHubs, collapsedSidebar);

    // Bereken posities voor outer hubs
    const outerPositions = calculateOuterPositions(outerHubs, collapsedSidebar);

    // Combineer alle posities
    return [...perimeterPositions, ...innerPositions, ...furtherOuterPositions, ...outerPositions];
  };

  // Functie om posities voor perimeter hubs te berekenen
  const calculatePerimeterPositions = (hubs) => {
    let rectWidth = 0 // Trek marges af
    let rectHeight = 0; // Trek marges af
    let cornerRadius = 0; // Straal van de afgeronde hoeken

    if (viewHeight < 550) {
      rectHeight = viewHeight- 125
      rectWidth = viewWidth - 650;
      cornerRadius = 125;
    } else if (viewHeight < 650) {
      cornerRadius = 200;
      rectWidth = viewWidth - 550;
      rectHeight = viewHeight - 150
    } else {
      cornerRadius = 300;
      rectHeight = viewHeight - 200
      rectWidth = viewWidth - 550;
    }

    const startX = (viewWidth - rectWidth) / 2; // Gecentreerd
    const startY = (viewHeight - rectHeight) / 2; // Gecentreerd


    const sideLengthX = rectWidth - 2 * cornerRadius;
    const sideLengthY = rectHeight - 2 * cornerRadius;

    // Lengte van de hoekbogen (kwart cirkels)
    const arcLength = (Math.PI / 2) * cornerRadius;

    // Totale omtrek
    const perimeter = 2 * (sideLengthX + sideLengthY) + 4 * arcLength;

    // Afstand tussen hubs langs het pad
    const hubSpacing = perimeter / hubs.length;

    const positions = [];

    hubs.forEach((hub, index) => {
      let distance = index * hubSpacing;
      let x, y;

      if (distance < sideLengthX) {
        // Bovenkant
        x = startX + cornerRadius + distance;
        y = startY;
      } else if (distance < sideLengthX + arcLength) {
        // Top-right hoekboog
        const angle = (distance - sideLengthX) / cornerRadius;
        x =
          startX +
          rectWidth -
          cornerRadius +
          cornerRadius * Math.sin(angle);
        y =
          startY +
          cornerRadius -
          cornerRadius * Math.cos(angle);
      } else if (distance < sideLengthX + arcLength + sideLengthY) {
        // Rechterkant
        x = startX + rectWidth;
        y = startY + cornerRadius + (distance - sideLengthX - arcLength);
      } else if (distance < sideLengthX + 2 * arcLength + sideLengthY) {
        // Bottom-right hoekboog
        const angle =
          (distance - sideLengthX - arcLength - sideLengthY) /
          cornerRadius;
        x =
          startX +
          rectWidth -
          cornerRadius +
          cornerRadius * Math.cos(angle);
        y =
          startY +
          rectHeight -
          cornerRadius +
          cornerRadius * Math.sin(angle);
      } else if (distance < 2 * sideLengthX + 2 * arcLength + sideLengthY) {
        // Onderkant
        x =
          startX +
          rectWidth -
          cornerRadius -
          (distance - sideLengthX - 2 * arcLength - sideLengthY);
        y = startY + rectHeight;
      } else if (distance < 2 * sideLengthX + 3 * arcLength + sideLengthY) {
        // Bottom-left hoekboog
        const angle =
          (distance - 2 * sideLengthX - 2 * arcLength - sideLengthY) /
          cornerRadius;
        x =
          startX +
          cornerRadius -
          cornerRadius * Math.sin(angle);
        y =
          startY +
          rectHeight -
          cornerRadius +
          cornerRadius * Math.cos(angle);
      } else if (
        distance <
        2 * sideLengthX + 3 * arcLength + 2 * sideLengthY
      ) {
        // Linkerkant
        x = startX;
        y =
          startY +
          rectHeight -
          cornerRadius -
          (distance - 2 * sideLengthX - 3 * arcLength - sideLengthY);
      } else {
        // Top-left hoekboog
        const angle =
          (distance - 2 * sideLengthX - 3 * arcLength - 2 * sideLengthY) /
          cornerRadius;
        x =
          startX +
          cornerRadius -
          cornerRadius * Math.cos(angle);
        y =
          startY +
          cornerRadius -
          cornerRadius * Math.sin(angle);
      }

      // Selecteer de juiste animatie op basis van index
      const animMapping =
        animationMappings[index % animationMappings.length];

      positions.push({
        ...hub,
        position: { x, y },
        animationClass: animMapping.cssAnimation,
        animateX2Values: animMapping.x2Values(x),
        animateY2Values: animMapping.y2Values(y),
        animationDuration: animMapping.duration,
      });
    });

    return positions;
  };

  // Functie om posities voor inner hubs (variabel aantal hubs per kant) te berekenen
  const calculateInnerPositions = (hubs, maxInnerHubsPerSide) => {
    const positions = [];
    const centerX = viewWidth / 2;
    const centerY = viewHeight / 2;

    if (maxInnerHubsPerSide === 2) {
      // Posities voor 2 hubs per kant
      const offsets = [
        { x: -250, y: -90 },  // linkerboven
        { x: -250, y: 103 },  // linkeronder
        { x: 250, y: -95 },   // rechterboven
        { x: 250, y: 90 },    // rechteronder
      ];

      offsets.forEach((offset, index) => {
        if (index < hubs.length) {
          const hub = hubs[index];
          const x = centerX + offset.x * scaleFactor; // Schaal positie
          const y = centerY + offset.y * scaleFactor; // Schaal positie
          const animMapping = animationMappings[(index + 14) % animationMappings.length];
          positions.push({
            ...hub,
            position: { x, y },
            animationClass: animMapping.cssAnimation,
            animateX2Values: animMapping.x2Values(x),
            animateY2Values: animMapping.y2Values(y),
            animationDuration: animMapping.duration,
          });
        }
      });
    } else {
      // Posities voor 3 hubs per kant
      const offsets = [
        // Linkerkant (3 hubs)
        { x: -250, y: -100 },
        { x: -450, y: -25 },
        { x: -250, y: 100 },
        // Rechterkant (3 hubs)
        { x: 250, y: -125 },
        { x: 450, y: 0 },
        { x: 250, y: 100 },
      ];

      offsets.forEach((offset, index) => {
        if (index < hubs.length) {
          const hub = hubs[index];
          const x = centerX + offset.x * scaleFactor; // Schaal positie
          const y = centerY + offset.y * scaleFactor; // Schaal positie
          const animMapping = animationMappings[(index + 14) % animationMappings.length];
          positions.push({
            ...hub,
            position: { x, y },
            animationClass: animMapping.cssAnimation,
            animateX2Values: animMapping.x2Values(x),
            animateY2Values: animMapping.y2Values(y),
            animationDuration: animMapping.duration,
          });
        }
      });
    }

    return positions;
  };

  // Functie om posities voor verdere outer hubs te berekenen
const calculateFurtherOuterPositions = (hubs, collapsedSidebar) => {
  const positions = [];
  const centerX = viewWidth / 2;
  const centerY = viewHeight / 2;

  let furtherCornerOffsets;

  if (viewHeight < 550) {
    // Kleine hoogte: pas offsets aan om hubs dichter bij elkaar te zetten
    if (collapsedSidebar) {
      furtherCornerOffsets = [
        { x: -600, y: -250 },
        { x: 600, y: -250 },
        { x: -600, y: 250 },
        { x: 600, y: 250 },
      ];
    } else {
      furtherCornerOffsets = [
        { x: -600, y: -250},
        { x: 600, y: -250 },
        { x: -600, y: 250 },
        { x: 600, y: 250 },
      ];
    }
  } else if (viewWidth >= 1400) {
    if (collapsedSidebar) {
      furtherCornerOffsets = [
        { x: -725, y: -300 },
        { x: 725, y: -300 },
        { x: -725, y: 300 },
        { x: 725, y: 300 },
      ];
    } else {
      furtherCornerOffsets = [
        { x: -775, y: -125 },
        { x: 775, y: -125 },
        { x: -775, y: 100 },
        { x: 775, y: 100 },
      ];
    }
  } else {
    if (collapsedSidebar) {
      furtherCornerOffsets = [
        { x: -725, y: -375 },
        { x: 725, y: -375 },
        { x: -725, y: 375 },
        { x: 725, y: 375 },
      ];
    } else {
      furtherCornerOffsets = [
        { x: -775, y: -125 },
        { x: 775, y: -125 },
        { x: -775, y: 100 },
        { x: 775, y: 100 },
      ];
    }
  }

  furtherCornerOffsets.forEach((offset, index) => {
    if (index < hubs.length) {
      const hub = hubs[index];
      const x = centerX + offset.x * scaleFactor;
      const y = centerY + offset.y * scaleFactor;
      const animMapping = animationMappings[(index + 25) % animationMappings.length];
      positions.push({
        ...hub,
        position: { x, y },
        animationClass: animMapping.cssAnimation,
        animateX2Values: animMapping.x2Values(x),
        animateY2Values: animMapping.y2Values(y),
        animationDuration: animMapping.duration,
      });
    }
  });

  return positions;
};


const calculateOuterPositions = (hubs, collapsedSidebar) => {
  const positions = [];
  const centerX = viewWidth / 2;
  const centerY = viewHeight / 2;

  let cornerOffsets;
  if (viewHeight < 550) {
    // Kleine hoogte: gebruik aangepaste offsets
    if (collapsedSidebar) {
      cornerOffsets = [
        { x: -700, y: -100 },
        { x: 700, y: -100 },
        { x: -700, y: 100},
        { x: 700, y: 100 },
      ];
    } else {
      cornerOffsets = [
        { x: -700, y: -100 },
        { x: 700, y: -100 },
        { x: -700, y: 100 },
        { x: 700, y: 100 },
      ];
    }
  } else if (viewWidth >= 1400) {
    if (collapsedSidebar) {
      cornerOffsets = [
        { x: -765, y: -125 },
        { x: 765, y: -125 },
        { x: -765, y: 125 },
        { x: 765, y: 125 },
      ];
    } else {
      cornerOffsets = [
        { x: -675, y: -300 },
        { x: 690, y: -300 },
        { x: -675, y: 265 },
        { x: 690, y: 275 },
      ];
    }
  } else {
    if (collapsedSidebar) {
      cornerOffsets = [
        { x: -765, y: -125 },
        { x: 765, y: -125 },
        { x: -765, y: 125 },
        { x: 765, y: 125 },
      ];
    } else {
      cornerOffsets = [
        { x: -675, y: -300 },
        { x: 690, y: -300 },
        { x: -675, y: 300 },
        { x: 690, y: 300 },
      ];
    }
  }

  cornerOffsets.forEach((offset, index) => {
    if (index < hubs.length) {
      const hub = hubs[index];
      const x = centerX + offset.x * scaleFactor;
      const y = centerY + offset.y * scaleFactor;
      const animMapping = animationMappings[(index + 20) % animationMappings.length];
      positions.push({
        ...hub,
        position: { x, y },
        animationClass: animMapping.cssAnimation,
        animateX2Values: animMapping.x2Values(x),
        animateY2Values: animMapping.y2Values(y),
        animationDuration: animMapping.duration,
      });
    }
  });

  return positions;
};


  // Bereken alle hub posities
  const hubPositions = getDynamicPositions(hubs);

  return (
    <PageTemplate GetCollapsedSidebar={GetCollapsedSidebar}>
      <div className="hubsWeb">
        {/* Centrale Cirkel - blijft vast */}
        <div className="backgroundCenterCircle">
          <img
            className="logoCenterCircle user-select-none"
            src={Logo}
            alt="Hub Logo"
            // Geen schaling toegepast
          />
          <p
            className="text-white text-center user-select-none mb-0"
            // Geen schaling toegepast
          >
            Hub
          </p>
        </div>

        

        {/* SVG Filter Definities voor Glow Effect */}
        <svg width="0" height="0">
          <defs>
            <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow
                dx="3"
                dy="1"
                stdDeviation="5"
                floodColor="rgba(0, 156, 222)"
              />
            </filter>
          </defs>
        </svg>

        {/* Verbindingen en Nodes */}
        <svg className="hub-connections" width={viewWidth} height={viewHeight}>
          {/* Render lijnen eerst */}
          {hubPositions.map((hub, index) => {
            const {
              position,
              animationClass = "floatUpDown", // Standaard waarde
              animateX2Values,
              animateY2Values,
              animationDuration = "6000ms", // Standaard waarde
            } = hub;
            const {
              stroke,
              strokeWidth,
              strokeDasharray,
              strokeDashoffset,
              filter,
              className,
            } = getLineStyle(hub.status);

            const lineStartX = viewWidth / 2;
            const lineStartY = viewHeight / 2;
            const lineEndX = position.x;
            const lineEndY = position.y;
            const animationDelay = 0; // Geen vertraging, alle animaties starten tegelijkertijd

            if (hub.status === 0) {
              // Actieve lijn
              return (
                <AnimatedLineWithDoubleBump
                  key={`animated-line-${hub.id || index}`}
                  startX={lineStartX}
                  startY={lineStartY}
                  endX={lineEndX}
                  endY={lineEndY}
                  lineColor="#009cde"
                  lineWidth={4}
                  bumpAmplitude={5}
                  bumpLength={0.05}
                  duration={animationDuration}
                  svgWidth={viewWidth}
                  svgHeight={viewHeight}
                  randomStart={true}
                  animationClass={animationClass}
                  hubAnimationDuration={animationDuration}
                  animationDelay={animationDelay}
                />
              );
            }
            // Voor niet-actieve lijnen, render de standaard <line>
            return (
              <line
                key={`line-${hub.id || index}`}
                x1={lineStartX}
                y1={lineStartY}
                x2={lineEndX}
                y2={lineEndY}
                stroke={stroke}
                strokeWidth={strokeWidth}
                strokeDasharray={strokeDasharray}
                strokeDashoffset={strokeDashoffset}
                className={`${className}`}
                filter={filter}
              >
                {/* Animate x2 */}
                <animate
                  attributeName="x2"
                  values={animateX2Values}
                  dur={animationDuration}
                  repeatCount="indefinite"
                  begin="0s" // Start onmiddellijk
                />
                {/* Animate y2 */}
                <animate
                  attributeName="y2"
                  values={animateY2Values}
                  dur={animationDuration}
                  repeatCount="indefinite"
                  begin="0s" // Start onmiddellijk
                />
              </line>
            );
          })}

          {/* Render nodes vervolgens */}
          {hubPositions.map((hub, index) => {
            const {
              position,
              animationClass = "floatUpDown", // Standaard waarde
              animationDuration = "6000ms", // Standaard waarde
            } = hub;
            const nodeSize = getNodeSize(hub.status, hubs.length); // Pass totalHubs here
            const animationDelay = "0s"; // Geen vertraging

            return (
              <foreignObject
                key={`node-${hub.id || index}`}
                x={position.x - nodeSize / 2}
                y={position.y - nodeSize / 2}
                width={nodeSize}
                height={nodeSize}
                overflow="visible"
              >
                <div
                  className={`hub-node ${
                    hub.status === 0 ? "active-status" : ""
                  } ${animationClass}`}
                  style={{
                    width: `${nodeSize}px`,
                    height: `${nodeSize}px`,
                    cursor: hub.status === 0 ? "pointer" : "default",
                    animationDelay: animationDelay,
                    animationDuration: animationDuration,
                  }}
                  onClick={() => {
                    if (hub.status === 0) {
                      navigate(hub.redirect_path); // Navigeer naar de opgegeven route
                    }
                  }}
                >
                  {/* Status Icoon */}
                  <div
                    className="status-icon"
                    style={{
                      backgroundColor: getStatusColor(hub.status),
                      width: `${nodeSize * 0.33}px`,
                      height: `${nodeSize * 0.33}px`,
                      borderRadius: "50%",
                      position: "absolute",
                      top: "-3px",
                      left: "-3px",
                    }}
                  >
                    <img
                      className="status-icon"
                      src={hub.icon || "https://api-middleware.dev.techdogcloud.com/public/storage/hub_icons/HubPlaceholder.svg"}
                      alt="Icon"
                      style={{
                        width: "50%",
                        height: "50%",
                      }}
                    />
                  </div>

                  {/* Status en Hub Naam */}
                  <p className="mb-0" style={{ fontSize: `${Math.max(nodeSize * 0.07, 8)}px` }}>
                    {getStatusText(hub.status)}
                  </p>
                  <h3
                    className="mb-0 titlehubs"
                    style={{
                      fontSize: `${Math.min(Math.max(nodeSize / 8, 12), 18)}px`,
                    }}
                  >
                    {hub.name.endsWith("HUB")
                      ? hub.name.slice(0, -3)
                      : hub.name}
                  </h3>
                  <p className={`px-2 ${viewWidth <= 1450 ? 'd-none': ''}`} style={{ fontSize: `${Math.max(nodeSize * 0.06, 7)}px` }}>
                    {hub.description}
                  </p>
                </div>
              </foreignObject>
            );
          })}
        </svg>
      </div>
    </PageTemplate>
  );
};

export default MainPage;
