import React, { useEffect, useMemo, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTable } from "react-table";
import { format } from "date-fns";

import PageTemplate from "../Templates/PageTemplate";
import TablePreset from "../Table/TablePreset";
import exportOptions from "./Components/exportOptions";
import middleware from "../Api/Middleware";
import SortableHeader from "../Table/TableComponents/SortableHeader";

import {
    faCloudArrowDown,
    faFileImport,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router";
import getStatusText from "./Components/statusText";

function ImportExportHome() {
    const [viewing, setViewing] = useState("imports"); // Current table the user is viewing
    const [importData, setImportData] = useState([]);
    const [exportData, setExportData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [paginationImport, setPaginationImport] = useState({ // Pagination info for import
        page_size: 10,
        page: 1,
        max_items: 0,
        begin_of_page: 0,
        end_of_page: 0,
    });
    const [paginationExport, setPaginationExport] = useState({ // Pagination info for export
        page_size: 10,
        page: 1,
        max_items: 0,
        begin_of_page: 0,
        end_of_page: 0,
    });

    // Sorting order for import and export
    const [importOrder, setImportOrder] = useState({ orderBy: "", direction: "" });
    const [exportOrder, setExportOrder] = useState({ orderBy: "", direction: "" });

    const [searchBarImport, setSearchBarImport] = useState("");
    const [searchBarExport, setSearchBarExport] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        // Check the URL hash on load and set viewing accordingly
        const hash = window.location.hash;
        if (hash === "#exports") {
            setViewing("exports");
        } else {
            setViewing("imports");
        }
    }, []);

    useEffect(() => {
        window.location.hash = `#${viewing}`;
    }, [viewing])

    // Fetches all the imports that have been made
    const fetchAllImports = async () => {
        try {
            setTableLoading(true);
            const searchQuery = searchBarImport.trim() ? `&searchTerm=${encodeURIComponent(searchBarImport.trim())}` : "";

            // Fetch import data
            const importsResult = await middleware.get(
                `sp/importTableAll?page=${paginationImport.page}&page_size=${paginationImport.page_size}
                ${importOrder.orderBy !== "" && importOrder.direction !== "" ? `&orderBy=${importOrder.orderBy}&orderDirection=${importOrder.direction}` : ""}${searchQuery}`
            );

            // Set states with the fetched data
            setImportData(importsResult.data.data);
            setPaginationImport({
                ...paginationImport,
                max_items: importsResult.data.total,
                begin_of_page: importsResult.data.from,
                end_of_page: importsResult.data.to,
            });
            setTableLoading(false);
        } catch (error) {
            console.error("Error fetching import data:", error);
        }
    };

    // Fetches all the exports that have been made
    const fetchAllExports = async () => {
        try {
            setTableLoading(true);
            const searchQuery = searchBarExport.trim() ? `&searchTerm=${encodeURIComponent(searchBarExport.trim())}` : "";

            // Fetch export data
            const exportsResult = await middleware.get(
                `sp/exportsTableAll?page=${paginationExport.page}&page_size=${paginationExport.page_size}
                ${exportOrder.orderBy !== "" && exportOrder.direction !== "" ? `&orderBy=${exportOrder.orderBy}&orderDirection=${exportOrder.direction}` : ""}${searchQuery}`
            );

            // Set states with the fetched data
            setExportData(exportsResult.data.data);
            setPaginationExport({
                ...paginationExport,
                max_items: exportsResult.data.total,
                begin_of_page: exportsResult.data.from,
                end_of_page: exportsResult.data.to,
            });
            setTableLoading(false);
        } catch (error) {
            console.error("Error fetching export data:", error);
        }
    };

    // Refetch when pagination or sorting order changes
    useEffect(() => {
        if (viewing === "imports") {
            fetchAllImports();
        } else {
            fetchAllExports();
        }
    }, [viewing, paginationImport.page, paginationExport.page, paginationImport.page_size, paginationImport.page_size, importOrder, exportOrder, searchBarImport, searchBarExport]);

    const handleSearchChangeImport = useCallback((value) => {
        setSearchBarImport(value);
        setPaginationImport(prev => ({ ...prev, page: 1 }));
    }, []);

    const handleSearchChangeExport = useCallback((value) => {
        setSearchBarExport(value);
        setPaginationExport(prev => ({ ...prev, page: 1 }));
    }, []);

    // Columns for the import table
    const importColumns = useMemo(
        () => [
            {
                Header: () => (
                    <SortableHeader
                        currentItem={importOrder}
                        setItem={setImportOrder}
                        pos={"start"}
                        field="file_name"
                    >
                        Naam
                    </SortableHeader>
                ),
                accessor: "file_name",
                Cell: ({ row }) => {
                    const truncatedFileName =
                    row.original.file_name.length > 30
                        ? `${row.original.file_name.slice(0, 30)}...`
                        : row.original.file_name;

                    return (
                        <a
                            href={row.original.status === 5 || row.original.status === 6 ? `/import/valideren/${row.original.import_id}` : `/import/resultaat/${row.original.import_id}`}
                            className="text-info text-decoration-none text-nowrap"
                        >
                            {truncatedFileName}
                        </a>
                    );
                },
            },
            {
                Header: () => (
                    <SortableHeader
                        currentItem={importOrder}
                        setItem={setImportOrder}
                        pos={"start"}
                        field="status"
                    >
                        Status
                    </SortableHeader>
                ),
                accessor: "status",
                Cell: ({ row }) => {
                    return getStatusText(row.original.status);
                },
            },
            {
                Header: () => (
                    <SortableHeader
                        currentItem={importOrder}
                        setItem={setImportOrder}
                        pos={"start"}
                        field="processed_rows"
                    >
                        Taak %
                    </SortableHeader>
                ),
                accessor: "processed_rows",
                Cell: ({ row }) => {
                    const percentage =
                        (row.original.processed_rows / row.original.total_rows) * 100;
                    var colorClass = "";

                    if (percentage >= 100) {
                        colorClass = "status-text-green";
                    } else if (percentage >= 75) {
                        colorClass = "status-text-orange";
                    } else {
                        colorClass = "status-text-red";
                    }

                    return (
                        <p className={colorClass}>
                            {row.original.processed_rows} / {row.original.total_rows} (
                            {percentage.toFixed(2)}%)
                        </p>
                    );
                },
            },
            {
                Header: () => (
                    <SortableHeader
                        currentItem={importOrder}
                        setItem={setImportOrder}
                        pos={"start"}
                        field="successful_rows"
                    >
                        Success %
                    </SortableHeader>
                ),
                accessor: "successful_rows",
                Cell: ({ row }) => {
                    const percentage =
                        (row.original.successful_rows / row.original.total_rows) * 100;
                    var colorClass = "";

                    if (percentage >= 100) {
                        colorClass = "status-text-green";
                    } else if (percentage >= 75) {
                        colorClass = "status-text-orange";
                    } else {
                        colorClass = "status-text-red";
                    }

                    return (
                        <p className={colorClass}>
                            {row.original.successful_rows} / {row.original.total_rows} (
                            {percentage.toFixed(2)}%)
                        </p>
                    );
                },
            },
            {
                Header: () => (
                    <SortableHeader
                        currentItem={importOrder}
                        setItem={setImportOrder}
                        pos={"start"}
                        field="username"
                    >
                        Gestart door
                    </SortableHeader>
                ),
                accessor: "username",
                Cell: ({ row }) => {
                    return <p>{row.original.username}</p>;
                },
            },
            {
                Header: () => (
                    <SortableHeader
                        currentItem={importOrder}
                        setItem={setImportOrder}
                        pos={"start"}
                        field="created_at"
                    >
                        Gestart op
                    </SortableHeader>
                ),
                accessor: "created_at",
                Cell: ({ row }) => {
                    return (
                        <p>{format(Date.parse(row.original.created_at), "dd-MM-yyyy HH:mm:ss")}</p>
                    );
                },
            },
        ],
        [importOrder]
    );

    // Columns for the export table
    const exportColumns = useMemo(
        () => [
            {
                Header: () => (
                    <SortableHeader
                        currentItem={exportOrder}
                        setItem={setExportOrder}
                        pos={"start"}
                        field="description"
                    >
                        Beschrijving
                    </SortableHeader>
                ),
                accessor: "description",
                Cell: ({ row }) => {
                    const truncatedDescription =
                    row.original.description.length > 40
                        ? `${row.original.description.slice(0, 40)}...`
                        : row.original.description;

                    return (
                        <a
                            href={`/export/resultaat/${row.original.export_id}`}
                            className="text-info text-decoration-none text-nowrap"
                        >
                            {truncatedDescription}
                        </a>
                    );
                },
            },
            {
                Header: () => (
                    <SortableHeader
                        currentItem={exportOrder}
                        setItem={setExportOrder}
                        pos={"start"}
                        field="table_name"
                    >
                        Type
                    </SortableHeader>
                ),
                accessor: "table_name",
                Cell: ({ row }) => {
                    return (
                        <p>
                            {exportOptions.exportTypes[row.original.table_name] != undefined
                                ? exportOptions.exportTypes[row.original.table_name].displayName
                                : "Onbekend"}
                        </p>
                    );
                },
            },
            {
                Header: () => (
                    <SortableHeader
                        currentItem={exportOrder}
                        setItem={setExportOrder}
                        pos={"start"}
                        field="username"
                    >
                        Gestart door
                    </SortableHeader>
                ),
                accessor: "username",
                Cell: ({ row }) => {
                    return <p>{row.original.username}</p>;
                },
            },
            {
                Header: () => (
                    <SortableHeader
                        currentItem={exportOrder}
                        setItem={setExportOrder}
                        pos={"start"}
                        field="created_at"
                    >
                        Gemaakt op
                    </SortableHeader>
                ),
                accessor: "created_at",
                Cell: ({ row }) => {
                    return <p>{format(Date.parse(row.original.created_at), "dd-MM-yyyy HH:mm:ss")}</p>;
                },
            },
        ],
        [exportOrder]
    );

    // Table instances
    const tableInstanceImport = useTable({ columns: importColumns, data: importData });
    const tableInstanceExport = useTable({ columns: exportColumns, data: exportData });

    return (
        <PageTemplate navbarTitle={"Import & Export HUB"}>
            <div className="w-100">
                {/* Tables */}
                <div className="import-export-table">
                    {viewing === "imports" ? ( // Import Table
                        <TablePreset
                            data={[
                                {
                                    tableInstance: tableInstanceImport,
                                    pagination: paginationImport,
                                    primary_key: "import_id",
                                },
                            ]}
                            leftButtons={[{title: <><FontAwesomeIcon className="me-1" icon={faFileImport} /> Imports</>, function: () => setViewing("imports"), disabled: true}, {title: <><FontAwesomeIcon className="me-1" icon={faCloudArrowDown} /> Exports</>, function: () => setViewing("exports"), customStyling: {backgroundColor: "#bfbfbf"}}]}
                            rightButtons={[{title: <><FontAwesomeIcon className="me-1" icon={faFileImport} /> Nieuwe import</>, function: () => navigate("/import")}, {title: <><FontAwesomeIcon className="me-1" icon={faCloudArrowDown} /> Nieuwe export</>, function: () => navigate("/export")}]}
                            setPagination={setPaginationImport}
                            tableLoading={tableLoading}
                            handleSelection={() => {}}
                            searchBar={[{ shown: true, searchFunction: handleSearchChangeImport, placeholder: "Zoeken op naam, status, etc." }]}
                        />
                    ) : ( // Export Table
                        <TablePreset
                            data={[
                                {
                                    tableInstance: tableInstanceExport,
                                    pagination: paginationExport,
                                    primary_key: "export_id",
                                },
                            ]}
                            leftButtons={[{title: <><FontAwesomeIcon className="me-1" icon={faFileImport} /> Imports</>, function: () => setViewing("imports"), customStyling: {backgroundColor: "#bfbfbf"}}, {title: <><FontAwesomeIcon className="me-1" icon={faCloudArrowDown} /> Exports</>, function: () => setViewing("exports"), disabled: true}]}   
                            rightButtons={[{title: <><FontAwesomeIcon className="me-1" icon={faFileImport} /> Nieuwe import</>, function: () => navigate("/import")}, {title: <><FontAwesomeIcon className="me-1" icon={faCloudArrowDown} /> Nieuwe export</>, function: () => navigate("/export")}]}
                            setPagination={setPaginationExport}
                            tableLoading={tableLoading}
                            handleSelection={() => {}}
                            searchBar={[{ shown: true, searchFunction: handleSearchChangeExport, placeholder: "Zoeken op beschrijving, type, etc." }]}
                        />
                    )}
                </div>
            </div>
        </PageTemplate>
    );
}

export default ImportExportHome;
