import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonBlue } from "../../../HouseStyle/Components/Buttons";
import * as Icons from "../../../HouseStyle/Icons";


const EmailSettings = ({ backoffice }) => {
    const navigate = useNavigate();

    return <div className="p-4 pb-0 w-50">
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-50 fw-bold">Van e-mail</h6>
            <h6 className="w-50">{backoffice.email}</h6>
        </div>
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-50 fw-bold">Afzender naam</h6>
            <h6 className="w-50">{backoffice.company_name}</h6>
        </div>
        <div className="d-flex justiy-content-between pb-4">
            <div className="w-50 ">
                <ButtonBlue text={"Bewerken"} onClickFunction={() => {navigate('/algemene/instellingen/technischBewerken')}} />
            </div>
        </div>
        <div className="d-flex justiy-content-between pb-4">
            <p className="TableTitle"><img style={{ width: "25px", verticalAlign: "sub", marginRight: "10px" }} src={Icons.WarningDark} />Valideer jouw domein UnifiedSMB.com (laatste controle: vandaag om 9:30)</p>
        </div>
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-100">Je hebt verzocht om de 'afzender email' te veranderen in info@unifiedSMB.com</h6>
        </div>
    </div>
}

export default EmailSettings;