import React, { useEffect, useState, useMemo } from "react";
import PageTemplate from "../../Templates/PageTemplate";
import { Tab, Tabs } from "react-bootstrap";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router";
import TablePreset from "../../Table/TablePreset";
import { useTable } from "react-table";
import { BackButton, ButtonBlue } from "../../HouseStyle/Components/Buttons";
import Backbutton from "../Icons/BackIcon.svg";
import { BorderedTextInput } from '../../HouseStyle/Components/Inputs';
import middleware from "../../Api/Middleware";
import SystemInfo from "./Tabs/SytemInfo";
import EmailSettings from "./Tabs/EmailSettings";
import * as Icons from "../../HouseStyle/Icons";
import ToastError from "../../Toasts/ToastError";

import { data, dns, spf, dmarc } from './temporarydata.js';

const TechnicalSettings = () => {
    //loading
    const [loading, setLoading] = useState(true);
    // Navigation setup
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [key, setKey] = useState(() => {
        return cookies.get('activeTabKey') ?? "tab1";
    });

    // Company info code
    const [settings, setSettings] = useState({});
    const [backoffice, setBackoffice] = useState({});
    const [user_count, setUserCount] = useState(0);

    // temporary data voor de tabbelen
    const [testData, setTestData] = useState([]);
    const [dnsData, setDnsData] = useState([]);
    const [spfData, setSpfData] = useState([]);
    const [dmarcData, setDmarcData] = useState([]);

    const fetchSettings = async () => {
        try {
            const settings = await middleware.get("/settings/system?setting_id=1");
            const users = await middleware.get("/users");
            const backofficeSettings = await middleware.get("/settings/backoffice?setting_id=1");
            setSettings(settings.data.data);
            setUserCount(users.data.data.length);
            setBackoffice(backofficeSettings.data.data);

            setTestData(data);
            setDnsData(dns);
            setSpfData(spf);
            setDmarcData(dmarc);
        } catch (err) {
            ToastError("Er is een fout opgetreden bij het ophalen van de data")
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchSettings();
    }, [])

    // Tabs for navigation
    const tabs = [
        {
            eventKey: "tab1",
            title: "Systeem informatie",
            navbarTitle: "Systeem informatie",
            content: (key == "tab1" && <SystemInfo settings={settings} user_count={user_count} />),
        },
        {
            eventKey: "tab2",
            title: "E-mail instellingen",
            navbarTitle: "E-mail instellingen",
            content: (key == "tab2" && <EmailSettings backoffice={backoffice} />),
        },
    ];

    const columns = useMemo(() => {
        return [
          {
            Header: () => <p className="FlatText mb-0">TXT-records gevonden voor dit domein</p>,
            accessor: "records",
            Cell: ({ row }) => {
              return (
                <div className="text-start">
                  {row.original.records}
                </div>
              );
            },
          },
          {
            Header: () => <p className="FlatText mb-0">TTL</p>,
            accessor: "ttl",
            Cell: ({ row }) => {
              return (
                <div className="BlackText">
                  {row.original.ttl}
                </div>
              );
            },
          },
        ];
      }, [testData]);

      const columnsDNS = useMemo(() => {
        return [
          {
            Header: () => <p className="FlatText mb-0">Opgeslagen gegevens</p>,
            accessor: "data",
            Cell: ({ row }) => {
              return (
                <div className="text-start">
                  {row.original.data}
                </div>
              );
            },
          },
          {
            Header: () => <p className="FlatText mb-0"></p>,
            accessor: "type",
            Cell: ({ row }) => {
              return (
                <div className="BlackText fw-bold">
                  {row.original.type}
                </div>
              );
            },
          },
          {
            Header: () => <p className="FlatText mb-0">TTL</p>,
            accessor: "ttl",
            Cell: ({ row }) => {
              return (
                <div className="BlackText">
                  {row.original.ttl}
                </div>
              );
            },
          },
        ];
      }, [dnsData]);

      const columnsSPF = useMemo(() => {
        return [
          {
            Header: () => <p className="FlatText mb-0">Host</p>,
            accessor: "host",
            Cell: ({ row }) => {
              return (
                <div className="text-start">
                  {row.original.host}
                </div>
              );
            },
          },
          {
            Header: () => <p className="FlatText mb-0">IP / Doelwit</p>,
            accessor: "ip",
            Cell: ({ row }) => {
              return (
                <div className="BlackText">
                  IP: {row.original.ip}
                </div>
              );
            },
          },
          {
            Header: () => <p className="FlatText mb-0">SPF</p>,
            accessor: "spf",
            Cell: ({ row }) => {
              return (
                <div className="BlackText">
                  {row.original.spf}
                </div>
              );
            },
          },
          {
            Header: () => <p className="FlatText mb-0">SPF onbewerkt</p>,
            accessor: "spfunedit",
            Cell: ({ row }) => {
              return (
                <div className="BlackText">
                  {row.original.spfunedit}
                </div>
              );
            },
          },
        ];
      }, [spfData]);

      const columnsDMARC = useMemo(() => {
        return [
          {
            Header: () => <p className="FlatText mb-0">TXT-records gevonden voor dit domein</p>,
            accessor: "records",
            Cell: ({ row }) => {
              return (
                <div className="text-start">
                  {row.original.records}
                </div>
              );
            },
          },
          {
            Header: () => <p className="FlatText mb-0">TTL</p>,
            accessor: "ttl",
            Cell: ({ row }) => {
              return (
                <div className="BlackText">
                  {row.original.ttl}
                </div>
              );
            },
          },
        ];
      }, [dmarcData]);


    const pagination = [];
    const tableInstance = useTable({columns, data: testData});
    const tableInstanceDNS = useTable({columns: columnsDNS, data: dnsData});
    const tableInstanceSPF = useTable({columns: columnsSPF, data: spfData});
    const tableInstanceDMARC = useTable({columns: columnsDMARC, data: dmarcData});

    return <PageTemplate navbarTitle={"Technische instellingen"} pageIsLoading={loading}>
        <div className="d-flex w-100 flex-column">
            <div className="d-flex mb-4 mt-4 justify-content-between">
                <BackButton size="l" text={"Terug"} onClickFunction={() => navigate("/algemene/instellingen")} />
            </div>
            <div className='col-12'>
                <div className="tabContainer whiteBox companyInfoTabs px-4 tabsOverviewPadding col-12 justify-content-start">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => {
                            setKey(k);
                            const tab = tabs.find((t) => t.eventKey === k);
                            cookies.remove('activeTabKey');
                            cookies.set('activeTabKey', tab.eventKey, { maxAge: 5400 });
                        }}
                    >
                        {tabs.map((tab) => (
                            <Tab
                                key={tab.eventKey}
                                eventKey={tab.eventKey}
                                title={
                                    <span
                                        className={`tab-title ${key === tab.eventKey ? "active" : ""} fw-semibold`}
                                    >
                                        {tab.title}
                                    </span>
                                }
                                disabled={tab.disabled}
                            >
                                <div key={tab.eventKey} className="tabContent">{tab.content}</div>
                            </Tab>
                        ))}
                    </Tabs>
                </div>
                {key == 'tab1' ?
                    <div className="whiteBox col-12 mt-4 px-4">
                        <h4 className="fw-bold ms-4 py-2 pt-3">Ontwikkelaarsinformatie</h4>
                        <div className="d-flex  ms-4 py-2 pt-3 w-50 justiy-content-between pb-4">
                            <h6 className="w-25 fw-bold">API</h6>
                            <h6 className="w-75 BlueText">{middleware.getUri()}</h6>
                        </div>
                    </div> : ""
                }
                {key == 'tab2' ?
                    <div>
                        <div className="whiteBox col-12 mt-4 px-4">
                            <div className="d-flex justify-content-between">
                                <p className="fw-bold pt-2">Je moet het volgende TXT record toevoegen aan de DNs configuratie van jouw domein (UnifeidSMB.com)</p>
                                <ButtonBlue text={"Opnieuw valideren"} icon={Icons.Sync} iconPlacement={"left"} />
                            </div>
                            <div className="d-flex  ms-4 py-2 pt-3 w-50 justiy-content-between pb-4">
                                <div className="me-4">
                                    <BorderedTextInput
                                        buttonLabel='Kopieer'
                                        textBoxInfront='TXT'
                                        prefixLabel="Type"
                                        label="Host"
                                        inputValue={''}
                                        setInputValue={''}
                                        size='l'
                                        updateFunction={''}
                                    />
                                </div>
                                <BorderedTextInput
                                    buttonLabel='Kopieer'
                                    label="Waarde"
                                    inputValue={''}
                                    setInputValue={''}
                                    size='l'
                                    updateFunction={''}
                                />
                            </div>
                            <p className="BeneathFlatText DarkGreyText ms-4">Als u de DNS-instellingen al op een hierboven hebt geconfigureerd, duurt het even voordat het probleem is opgelost. Probeer het opnieuw na 24 uur. We zullen het op de achtergrond blijven controleren. U kunt deze pagina sluiten.</p>
                            <div>
                                <TablePreset
                                    data={[{ 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'row_id' }]}
                                    emptyTableHeight="xs"
                                    tableLoading={''}
                                    showcheckbox={false}
                                />
                            </div>
                        </div>
                        <div className="whiteBox col-12 mt-4 px-4">
                            <p className="TableTitle pt-2">DNS configuratie van unifiedSMB.com</p>
                            <TablePreset
                                    data={[{ 'tableInstance': tableInstanceDNS, 'pagination': pagination, 'primary_key': 'row_id' }]}
                                    emptyTableHeight="xs"
                                    tableLoading={''}
                                    showcheckbox={false}
                                />
                        </div>
                        <div className="whiteBox col-12 mt-4 px-4">
                            <p className="TableTitle pt-2">SPF van UnifiedsMB.com</p>
                            <TablePreset
                                    data={[{ 'tableInstance': tableInstanceSPF, 'pagination': pagination, 'primary_key': 'row_id' }]}
                                    emptyTableHeight="xs"
                                    tableLoading={''}
                                    showcheckbox={false}
                                />
                        </div>
                        <div className="whiteBox col-12 mt-4 px-4">
                            <p className="TableTitle pt-2">DMARC voor unifiedSMB.com</p>
                            <TablePreset
                                    data={[{ 'tableInstance': tableInstanceDMARC, 'pagination': pagination, 'primary_key': 'row_id' }]}
                                    emptyTableHeight="xs"
                                    tableLoading={''}
                                    showcheckbox={false}
                                />
                        </div>
                    </div>
                    : ""
                }
            </div>
        </div>
    </PageTemplate>
}

export default TechnicalSettings;