import React from "react";
import Placeholder from "../../Icons/empty-placeholder.jpg";


const HouseStyle = ({ companyInfo }) => {
    return <div className="p-4 pb-0 w-50 parentCompanyInfoColumn">
        {companyInfo.font &&
            <div className="d-flex justiy-content-between pb-4">
                <h6 className="w-50 fw-bold">Lettertype</h6>
                <h6 className="w-50">{companyInfo.font}</h6>
            </div>
        }
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-50 fw-bold">Logo</h6>
            <img src={companyInfo.logo ?? Placeholder} alt="Logo" style={{maxWidth: "15vw", maxHeight: "15vh"}}/>
        </div>
        <div className="d-flex justiy-content-between pb-4">
            <h6 className="w-50 fw-bold">Zwart logo witte achtergrond (voor thermische printers)</h6>
            <img src={companyInfo.blackwhite_logo ?? Placeholder} alt="Thermal Logo" style={{maxWidth: "15vw", maxHeight: "15vh"}} />
        </div>
    </div>
}


export default HouseStyle;