import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import middleware from "../../Api/Middleware";

const PriceSettingsModal = ({ setPriceModalVisible, priceModalVisible, taxClass, setTaxClass, setSelectedTax }) => {

  const [taxRates, setTaxRates] = useState([]);
  // countries used 
  const [taxCountries, setTaxCountries] = useState([
    { countryDomain: "NL", country: "Nederland" },
    { countryDomain: "DE", country: "Duitsland" },
    { countryDomain: "BE", country: "België" },
  ]);
  const fetchTaxRates = async () => {
    try {
      const taxrates = await middleware.get('products/taxrates');
      setTaxRates(taxrates.data.data);
    }
    catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchTaxRates();
  }, [])

  const handleTaxChange = (event) => {
    const taxRate = taxRates.find((e) => e.tax_id == event.target.value);
    if (taxRate.country == "NL") {
      setTaxClass(taxRate.tax_id);
      setSelectedTax(Number(taxRate.percentage));
    }
  }

  return (
    <Modal
      id="modalBlueprint"
      show={priceModalVisible}
      onHide={() => setPriceModalVisible()}
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header className="modalTop justify-content-between">
        <h4 className='ms-3 fw-bold'>Selecteer BTW-percentage</h4>
        <button variant="transparent" className=' btn' onClick={() => setPriceModalVisible(false)}>
          <FontAwesomeIcon icon={faXmark} className="closeModal" />
        </button>
      </Modal.Header>
      <Modal.Body className="modalCenter">
        <div className="contendModal w-100">
          {taxCountries.map(country => {
            return <div key={country.country}>
              <label className='fw-bold'>BTW {country.country}</label>
              <select className="standardInput standardDropdownArrow w-100 my-3"
                onChange={(e) => handleTaxChange(e)}
                defaultValue={taxClass}>
                {taxRates.map(taxRate => {
                  if (taxRate.country == country.countryDomain) {
                    return <option key={taxRate.tax_id} value={Number(taxRate.tax_id)}>[{Number(taxRate.percentage)}%]</option> //(Standaard)
                  }
                })}
              </select>
            </div>
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottom">
      </Modal.Footer>
    </Modal>
  );
}

export default PriceSettingsModal;
