import React, { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { faPencilAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import InputComponent from '../../Customer/AddCustomers/InputComponent';
import middleware from '../../Api/Middleware';
import ToastError from '../../Toasts/ToastError';
import ToastInfo from '../../Toasts/ToastInfo';
import ToastSuccess from '../../Toasts/ToastSuccess';
import { BorderedTextInput } from '../../HouseStyle/Components/Inputs';
import { BorderedDropDown } from '../../HouseStyle/Components/DropDowns';
import Phonenumber from '../../Customer/Components/Phonenumber';

const AddPhoneModal = (props) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({country_code: "", phone_number: ""});


    const addPhone = async () => {
        let addressRes = await middleware.post("/phonenumbers", {
            internal_id: props.customer.internal_id,
            country_phone_prefix: formData.country_code,
            phone_number: formData.phone_number
        });
        ToastSuccess("Email is aangemaakt");
        props.setSelectedPhone(addressRes.data);
        
        if(props?.order?.client_phonenumbers) {
            props.setOrder({...(props.order), client_phonenumbers: [...props.order.client_phonenumbers, addressRes.data]});
        } else {
            props.setPhones([...props.phones, addressRes.data]);
        }
        props.setAddPhoneModalVisible(false);
    }
    return (
        <Modal
            id="modalItemNote"
            show={props.addPhoneModalVisible}
            onHide={{}}
            className="colormodals"
            size="xl"
            centered
            backdrop="static"
        >
            <Modal.Header className="modalTop justify-content-between px-5" >
                <h3>Telefoonnummer toevoegen</h3>
                <button variant="transparent" className=' btn' onClick={() => { props.setAddPhoneModalVisible(false) }}>
                    <FontAwesomeIcon icon={faXmark} className="closeModal" />
                </button>
            </Modal.Header>
            <Modal.Body className="modalCenter d-flex flex-column">
                <div className="">
                    <div className='mb-3'>
                    <Phonenumber removable={false} phonenumber={{country_phone_prefix: formData.country_code, phone_number: formData.phone_number}} handlePhoneNumberChange={(index, value) => setFormData({...formData, phone_number: value})} setPhoneCode={(index, e) => setFormData({...formData, country_code: e.target.value})}/>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <button className="btn DarkStandardButton" type="button" onClick={() => { props.setAddPhoneModalVisible(false) }}>Annuleren</button>
                        <button className="btn lightBlueStandardButton" onClick={() => addPhone()}>{"Toevoegen"}</button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modalBottom">
            </Modal.Footer>
        </Modal>
    )
}

export default AddPhoneModal
