// PageTemplate.js

import React, { useEffect, useState } from 'react';
import Sidebar from '../Navbars/Sidebar/Sidebar';
import Navbar from '../Navbars/Navbar/Navbar';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import './Template.css';
import Cookies from 'universal-cookie';
import middleware from '../Api/Middleware';
import { Helmet } from 'react-helmet';

const PageTemplate = ({
  navbarTitle,
  pageIsLoading,
  children,
  disableBackFunc,
  loadingTitle,
  loadingLogoutOption,
  loadingRetryOption,
  hideBackButton,
  backValue,
  GetCollapsedSidebar = null,
}) => {
  const cookies = new Cookies();
  const [sidebarIsLoading, setSidebarIsLoading] = useState(false);
  const [navbarIsLoading, setNavbarIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [viewWidth, setViewWidth] = useState(window.innerWidth);
  const [sidebarSmall, setSidebarSmall] = useState(false);
  const [darkmode, setDarkmode] = useState(false);
  const [navInfo, setNavInfo] = useState(() => {
    const token = cookies.get('token');
    const storedNavInfo = localStorage.getItem(`navInfo[${token}]`);
    return storedNavInfo ? JSON.parse(storedNavInfo) : null;
  });

  useEffect(() => {
    const fetchData = async () => {
      const token = cookies.get('token');
      if (!token) {
        setNavInfo(null);
        return;
      }

      // Cleanup navInfo entries not matching the current token
      const cleanupNavInfo = () => {
        const currentToken = token;
        const keysToRemove = [];

        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (key && key.startsWith('navInfo[') && key.endsWith(']')) {
            const storedToken = key.slice('navInfo['.length, -1);
            if (storedToken !== currentToken) {
              keysToRemove.push(key);
            }
          }
        }

        keysToRemove.forEach(key => localStorage.removeItem(key));
      };

      cleanupNavInfo();

      // If navInfo is not available, fetch it
      if (!navInfo || navInfo == undefined || navInfo == null) {
        try {
          const response = await middleware.get('navInfo');
          
          const data = response.data;

          // Store navInfo in localStorage
          localStorage.setItem(`navInfo[${token}]`, JSON.stringify(data));
          setNavInfo(data);
        } catch (error) {
          console.error('Error fetching navigation details:', error);
          setNavInfo(null);
        }
      }
    };

    fetchData();

    const handleResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [navInfo, cookies]);

   useEffect(() => {
      const handleResize = () => {
        setViewWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []); // Zorg ervoor dat deze updatet bij een wijziging van de sidebar

  const sidebarIsLoadingChange = (event) => {
    setSidebarIsLoading(event);
  };

  const navbarIsLoadingChange = (event) => {
    setNavbarIsLoading(event);
  };

  // Show loading screen if navInfo is not yet loaded
  if (!navInfo) {
    return (
      <div className="d-flex flex-nowrap">
        <LoadingScreen
          title={loadingTitle || 'Loading...'}
          logoutOption={loadingLogoutOption}
          retryOption={loadingRetryOption}
        />
      </div>
    );
  }

  // Show loading screen if page, sidebar, or navbar is loading
  if (pageIsLoading || sidebarIsLoading || navbarIsLoading) {
    return (
      <div className="d-flex flex-nowrap">
        <LoadingScreen
          title={loadingTitle || 'Loading...'}
          logoutOption={loadingLogoutOption}
          retryOption={loadingRetryOption}
        />
      </div>
    );
  }

  

  return (
    <div className={`d-flex flex-nowrap ${darkmode ? 'darkmode' : ''}`}>
      <Helmet><title>UnifiedSMB{navbarTitle ? ` - ${navbarTitle}` : ''}</title></Helmet>
      <Sidebar
        navInfo={navInfo}
        shortcuts={[]}
        callbackFuncIsLoading={sidebarIsLoadingChange}
        collapsed={(e) => setSidebarSmall(e)}
        darkmodeSystem={(e) => setDarkmode(e)}
        GetCollapsedSidebar={GetCollapsedSidebar}
      />
      <div className="d-flex flex-column">
        <Navbar
          navInfo={navInfo}
          profilePicture={navInfo.profile_picture_url}
          title={navbarTitle}
          callbackFuncIsLoading={navbarIsLoadingChange}
          sidebarsmall={sidebarSmall}
          disableBackFunc={disableBackFunc}
          hideBackButton={hideBackButton}
          backValue={backValue}
        />
        <div
          className={`d-flex justify-content-center ${
            sidebarSmall ? 'mainBoxSmallSidebar mainBox' : 'mainBox'
          } ${viewWidth < 1458 ? 'mainBoxSmallSidebar' : ''}`}
        >
          <div className="mb-5 position-absolute pb-4 widthContentMainbox">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PageTemplate;