import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTemplate from "../Templates/PageTemplate";
import middleware from "../Api/Middleware";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BorderedDropDown, RegularDropDown } from "../HouseStyle/Components/DropDowns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faXmark, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ButtonBlue, ButtonDarkBlue } from "../HouseStyle/Components/Buttons";
import { Modal } from 'react-bootstrap';
import getStatusText from "./Components/statusText";
import ToastSuccess from "../Toasts/ToastSuccess";
import ToastError from "../Toasts/ToastError";
import { BorderedTextInput } from "../HouseStyle/Components/Inputs";

export default function ValidationPage() {
    const { import_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
    const [showAddBrandModal, setShowAddBrandModal] = useState(false); // New modal state for brands
    const [categoryInput, setCategoryInput] = useState("");
    const [brandInput, setBrandInput] = useState(""); // State for brand input
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [importResults, setImportResults] = useState([]);
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
    const [recordId, setRecordId] = useState(null);

    const navigate = useNavigate();

    // Fetch validation data
    const fetchAll = async () => {
        try {
            const response = await middleware.get(`import/validate?import_id=${import_id}`);
            setImportResults(response.data);

            if (response.data.importStatus !== 5 && response.data.importStatus !== 6) {
                ToastSuccess("Uw aangeleverde data is foutloos gevalideerd");
                navigate(`/import/resultaat/${import_id}`);
                return;
            }

            setLoading(false);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    // Submit the newly linked columns
    const submitData = async () => {
        setHasAttemptedSubmit(true);
        
        let hasErrors = false;
        for (const [_, categoryValues] of Object.entries(groupedInvalidFields)) {
            for (const [_, value] of Object.entries(categoryValues)) {
                if (!selectedOptions[value.recordId]) {
                    hasErrors = true;
                }
            }
        }
        
        if (!hasErrors) {
            const response = await middleware.post(`import/validate?import_id=${import_id}`, {selectedOptions});

            if (response.status === 200) {
                navigate(`/import/resultaat/${import_id}`);
            }
        } else {
            ToastError("Niet alle velden zijn aan een waarde gekoppeld");
        }
    };

    // Creates new category post
    const createNewCategory = async (recordId) => {
        try {
            const response = await middleware.post(`products/category`, { title: categoryInput });
            console.log(response);
    
            if (response.status === 200) {
                await fetchAll();
                ToastSuccess("Categorie is succesvol aangemaakt");
        
                // Update selected options to automatically select the newly created category
                setSelectedOptions((prevSelectedOptions) => ({
                    ...prevSelectedOptions,
                    [recordId]: categoryInput,
                }));
    
            } else {
                ToastError("Er is iets fout gegaan bij het aanmaken van de categorie");
            }
    
            setShowAddCategoryModal(false);
        } catch (error) {
            console.error(error);
            ToastError("Er is iets fout gegaan bij het aanmaken van de categorie");
        }
    };

    // Creates new brand post
    const createNewBrand = async (recordId) => {
        try {
            const response = await middleware.post(`products/brands`, { name: brandInput });
            console.log(response);
    
            if (response.status === 200) {
                await fetchAll();
                ToastSuccess("Merk is succesvol aangemaakt");
        
                // Update selected options to automatically select the newly created brand
                setSelectedOptions((prevSelectedOptions) => ({
                    ...prevSelectedOptions,
                    [recordId]: brandInput,
                }));
    
            } else {
                ToastError("Er is iets fout gegaan bij het aanmaken van het merk");
            }
    
            setShowAddBrandModal(false);
        } catch (error) {
            console.error(error);
            ToastError("Er is iets fout gegaan bij het aanmaken van het merk");
        }
    };
    
    // Deletes the current import
    const deleteImport = async () => {
        const response = await middleware.delete(`import?import_id=${import_id}`);

        if (response.status === 200) {
            navigate(`/import-export-center`);
        }
    }

    /**
     * Groups invalid fields by their field names and associates them with valid values.
     */
    const groupInvalidFields = (invalidFields, lookupTables) => {
        const groupedData = {};

        Object.entries(invalidFields).forEach(([recordId, details]) => {
            const field = details.fieldName;
            const fieldValue = details.fieldValue;
            const validValues = Object.keys(lookupTables[details.modelIndex]);

            groupedData[field] = groupedData[field] || [];
            groupedData[field].push({ 
                recordId,
                fieldValue,
                count: details.count,
                validValues
            });
        });

        return groupedData;
    };

    useEffect(() => {
        const fetchData = async () => {
            // Fetch data initially
            const response = await fetchAll();
            if (response && response.importStatus === 6) {
                setLoading(false); // Stop loading once status is 6
            }
        };
    
        fetchData(); // Initial fetch when component mounts
    
        // Fetch until the status is "Actie verreist"
        const interval = setInterval(() => {
            if (importResults && importResults.importStatus === 6) {
                clearInterval(interval);
            } else {
                fetchAll();
            }
        }, 5000);
    
        // Cleanup interval on unmount or when the status is 6
        return () => clearInterval(interval);
    }, [importResults.importStatus]); // This now tracks only the importStatus instead of the entire importResults
    
    

    if (!importResults || importResults.importStatus !== 6) {
        return (
            <PageTemplate
                navbarTitle={"Importeer validatie"}
                backValue={"/import-export-center"}
                pageIsLoading={loading}
            >
                <div className="d-flex align-items-center flex-column">
                    <div className="d-flex align-items-center">
                        <div
                            className="spinner-border spinner-border-sm ml-auto text-black me-2"
                            role="status"
                            aria-hidden="true"
                        ></div>
                        We zijn uw data aan het valideren, kom over een paar minuten terug.
                    </div>
                    <p className="DarkGreyText">(Deze pagina wordt automatisch ververst)</p>
                    <p className="DarkBlueText fw-bold">Huidige status: {getStatusText(importResults.importStatus)}</p>
                </div>
            </PageTemplate>
        );
    }

    const groupedInvalidFields = groupInvalidFields(
        importResults.validation.invalid_fields,
        importResults.validation.lookup_tables
    );

    console.log(importResults.validation.invalid_fields, importResults.validation.lookup_tables, groupedInvalidFields);


    return (
        <PageTemplate
            navbarTitle={"Importeer validatie"}
            backValue={"/import-export-center"}
            pageIsLoading={loading}
        >
            {/* Delete modal */}
            <Modal
                id="modalBlueprint"
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                
                size="lg"
                centered
                backdrop="static"
                >
                <Modal.Header className="modalTop justify-content-between" style={{ paddingBottom: "0px"}}>
                    <h3>Weet je zeker dat je deze import wilt verwijderen?</h3>
                    <button variant="transparent" className=' btn' onClick={() => setShowDeleteModal(false)}>
                    <FontAwesomeIcon icon={faXmark} className="closeModal" />
                    </button>
                </Modal.Header>
                <Modal.Body className="modalCenter">
                </Modal.Body>
                <Modal.Footer className="modalBottom d-flex">
                    <ButtonDarkBlue text={"Doorgaan met koppellen"} onClickFunction={(e) => setShowDeleteModal(false)}/>
                    <ButtonBlue text={"Import verwijderen"} onClickFunction={deleteImport}/>
                </Modal.Footer>
            </Modal>
            
            {/* Add category modal */}
            <Modal
                id="modalBlueprint"
                show={showAddCategoryModal}
                onHide={() => setShowAddCategoryModal(false)}
                
                size="lg"
                centered
                backdrop="static"
                >
                <Modal.Header className="modalTop justify-content-between">
                    <h3>Nieuwe categorie toevoegen</h3>
                    <button variant="transparent" className=' btn' onClick={() => setShowAddCategoryModal(false)}>
                    <FontAwesomeIcon icon={faXmark} className="closeModal" />
                    </button>
                </Modal.Header>
                <Modal.Body className="modalCenter">
                    <BorderedTextInput placeholder='Categorie naam' inputValue={categoryInput} setInputValue={setCategoryInput} size='s'/>
                </Modal.Body>
                <Modal.Footer className="modalBottom d-flex">
                    <ButtonDarkBlue text={"Terug"} onClickFunction={(e) => setShowAddCategoryModal(false)}/>
                    <ButtonBlue text={"Toevoegen"} onClickFunction={() => createNewCategory(recordId)}/>
                </Modal.Footer>
            </Modal>

            {/* Add brand modal */}
            <Modal
                id="modalBlueprint"
                show={showAddBrandModal}
                onHide={() => setShowAddBrandModal(false)}
                
                size="lg"
                centered
                backdrop="static"
                >
                <Modal.Header className="modalTop justify-content-between">
                    <h3>Nieuw merk toevoegen</h3>
                    <button variant="transparent" className=' btn' onClick={() => setShowAddBrandModal(false)}>
                    <FontAwesomeIcon icon={faXmark} className="closeModal" />
                    </button>
                </Modal.Header>
                <Modal.Body className="modalCenter">
                    <BorderedTextInput placeholder='Merk naam' inputValue={brandInput} setInputValue={setBrandInput} size='s'/>
                </Modal.Body>
                <Modal.Footer className="modalBottom d-flex">
                    <ButtonDarkBlue text={"Terug"} onClickFunction={(e) => setShowAddBrandModal(false)}/>
                    <ButtonBlue text={"Toevoegen"} onClickFunction={() => createNewBrand(recordId)}/>
                </Modal.Footer>
            </Modal>

            <div className="mb-3 d-flex flex-column align-items-center w-lg-50">
                <h2>Ongeldige kolommen gedetecteerd</h2>
                <p className="DarkGreyText">
                    De volgende kolommen zijn ongeldig en moeten worden gekoppeld voordat de import
                    kan worden voortgezet
                </p>
                {/* Accordions with linkable fields */}
                <div className="accordion d-flex flex-column w-100 mb-3" id="invalidFieldsAccordion">
                    {Object.entries(groupedInvalidFields).map(([field, issues], index) => (
                        <div
                            className="accordion-item"
                            key={field}
                            style={{ width: '100%' }}
                        >
                            {/* Accordion Header */}
                            <h2 className="accordion-header" id={`heading-${index}`}>
                                <button
                                    className={`accordion-button ${hasAttemptedSubmit && 
                                        issues.some(issue => !selectedOptions[issue.recordId]) ? 
                                        'pulse-error' : ''} collapsed`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-${index}`}
                                    aria-expanded="false"
                                    aria-controls={`collapse-${index}`}
                                    style={{ width: '100%' }}
                                >
                                    <span><span className="DarkBlueTextBold">{Object.entries(issues).length}</span> {field.charAt(0).toUpperCase() + field.slice(1)}</span>
                                </button>
                            </h2>
                            <div
                                id={`collapse-${index}`}
                                className={`accordion-collapse collapse`}
                                aria-labelledby={`heading-${index}`}
                                data-bs-parent="#invalidFieldsAccordion"
                                style={{ width: '100%' }}
                            >
                                {/* Acordion Body */}
                                <div className="accordion-body" style={{ width: '100%', padding: '0' }}>
                                    {issues.map((issue, issueIndex) => (
                                        <div
                                            key={issueIndex}
                                            className={`d-flex align-items-center justify-content-between InputRow ${hasAttemptedSubmit && !selectedOptions[issue.recordId] ? "pulse-error" : ""}`}
                                            style={{ borderBottom: issueIndex === issues.length - 1 ? "none" : "1px solid #ddd", padding: "15px" }}
                                        >
                                            {/* Amount */}
                                            <div style={{ flex: 0.1, textAlign: "center" }}>
                                                <span className="DarkGreyText">Aantal: </span> <br />
                                                <strong>{issue.count}</strong>
                                            </div>
                                            {/* Value */}
                                            <div style={{ flex: 1, textAlign: "center" }}>
                                                <span className="DarkGreyText">Ongeldige Waarde: </span> <br />
                                                <strong>{issue.fieldValue}</strong>
                                            </div>
                                            
                                            {/* Arrow */}
                                            <div style={{ flex: 0.1, textAlign: "center" }}>
                                                <FontAwesomeIcon icon={faArrowRight} />
                                            </div>
                                            
                                            {/* Dropdown */}
                                            <div style={{ flex: 1, textAlign: "center", display: "flex", alignItems: "center", gap: "10px" }}>
                                                <BorderedDropDown
                                                    placeholder="Selecteer"
                                                    options={issue.validValues.map((value) => ({
                                                        title: value,
                                                        value: value,
                                                    }))}
                                                    selectedValue={selectedOptions[issue.recordId]}
                                                    setSelectedValue={(e) =>
                                                        setSelectedOptions({ ...selectedOptions, [issue.recordId]: e })
                                                    }
                                                />
                                                
                                                {/* Add Category or Add Brand Button */}
                                                {field == "category" && (
                                                    <button
                                                        className="btn btn-outline-secondary btn-sm"
                                                        onClick={() => {
                                                            setCategoryInput(issue.fieldValue); 
                                                            setShowAddCategoryModal(true);
                                                            setRecordId(issue.recordId);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus}/>
                                                    </button>
                                                )}
                                                {field == "brand" && (
                                                    <button
                                                        className="btn btn-outline-secondary btn-sm"
                                                        onClick={() => {
                                                            setBrandInput(issue.fieldValue); 
                                                            setShowAddBrandModal(true);
                                                            setRecordId(issue.recordId);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus}/>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Continue button */}
                <div className="d-flex justify-content-start w-100 gap-2">
                    <ButtonDarkBlue text={"Doorgaan met verwerken"} onClickFunction={submitData}/>
                    <ButtonBlue text={"Import verwijderen"} onClickFunction={(e) => setShowDeleteModal(true)}/>
                </div>
            </div>
        </PageTemplate>
    );
}
