import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useTable } from "react-table";
import middleware from "../Api/Middleware";
import "./product.css";
import { useNavigate, useLocation } from "react-router";
import "../Main/Main.css";
import NavbarIcon from "../Navbars/Sidebar/Icons/globe.png";
import Loading from "../LoadingScreen/LoadingScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCaretUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";
import PageTemplate from "../Templates/PageTemplate";
import TablePreset from "../Table/TablePreset";
import SortableHeader from "../Table/TableComponents/SortableHeader";
import CheckboxHeader from "../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../Table/TableComponents/CheckboxCell";
import ErrorScreen from "../LoadingScreen/ErrorScreen";

//modals
import Modals from "./subpages/sub_components/VariationModals";
import Filters from "../Filters/Filters";
import ToastError from "../Toasts/ToastError";

function Products() {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const [loading, setLoading] = useState(true); // useState for page loading
  const [loadingPage, setLoadingPage] = useState(true);
  const [producten, setProducten] = useState([]); // useState for all products in the database
  const [brands, setBrands] = useState([]); // useState for all products in the database
  const [genders, setGenders] = useState([]); // useState for all products in the database
  const [suppliers, setSuppliers] = useState([]); // useState for all products in the database
  const [categories, setCategories] = useState([]); // useState for all products in the database
  const [barcodes, setBarcodes] = useState([]); // useState for all products in the database
  const [stock, setStock] = useState([]); // useState for the stock of every Product in the database
  const [prices, setPrices] = useState([]); // useState for the prices of every Product in the database
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  }); // the useState for the pagination for the table
  const [order, setOrder] = useState({ orderBy: "", direction: "" }); // useState for ordering the columns in the table
  const [selectedProductIds, setSelectedProductIds] = useState([]); // usestate for the selected checkboxes
  const [fullyShownProducts, setFullyShownProducts] = useState([]); // usestate fpr the productname that u can fully show and shortend it
  const [showBlueprintModal, setShowBlueprintModal] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [timerId, setTimerId] = useState(null);
  const [searchBarInput, setSearchBarInput] = useState("");
  const [productsSearchEngine, setProductsSearchEngine] = useState("");
  const [show, setShow] = useState(false);
  const [showReActivate, setShowReActivate] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [apiCheck, setApiCheck] = useState(true);
  const [apiError, setApiError] = useState("");
  const [searchBar, setSearchBar] = useState("");
  const defaultFilters = [
    {
      index: 0,
      order: 1,
      name: "Product type",
      field: "type",
      type: "radio",
      options: [
        { name: "Simpel", value: 1 },
        { name: "Variatie", value: 2 },
      ],
    },
    {
      index: 1,
      order: 2,
      name: "Afbeelding",
      field: "image_path",
      type: "hasradio",
      options: [
        { name: "Ja", value: 1 },
        { name: "Nee", value: 0 },
      ],
    },
    {
      index: 2,
      order: 3,
      name: "Prijs",
      field: "incl_tax",
      type: "range",
      min: 0,
      max: null,
    },
    {
      index: 3,
      order: 4,
      name: "Voorraad",
      field: "stock",
      type: "hasradio",
      options: [
        { name: "Ja", value: 1 },
        { name: "Nee", value: 0 },
      ],
    },
    {
      index: 4,
      order: 5,
      name: "Status product",
      field: "status",
      type: "checkbox",
      value: [0],
      options: [
        { name: "Gepubliceerd", value: 0 },
        { name: "Gearchiveerd", value: 1 },
      ],
    },
    {
      index: 5,
      order: 6,
      name: "Merk",
      field: "brand",
      type: "dropdown",
      placeholder: "Selecteer merk",
      options: "brands",
    },
    {
      index: 6,
      order: 0,
      name: "Categorie",
      field: "categories",
      type: "category",
      checkedcats: [],
      placeholder: "Selecteer categorie",
      options: "categories",
    },
    {
      index: 7,
      order: 8,
      name: "Leverancier",
      field: "supplier",
      type: "dropdown",
      placeholder: "Selecteer leverancier",
      options: "suppliers",
    },
    {
      index: 8,
      order: 9,
      name: "Gender",
      field: "gender",
      type: "dropdown",
      placeholder: "Selecteer gender",
      options: "genders",
    },
    {
      index: 9,
      order: 10,
      name: "Inkoopprijs",
      field: "buy_in_price",
      type: "hasradio",
      options: [
        { name: "Ja", value: 1 },
        { name: "Nee", value: 0 },
      ],
    },
    {
      index: 10,
      order: 11,
      name: "Barcode",
      field: "barcode",
      type: "hasradio",
      options: [
        { name: "Ja", value: 1 },
        { name: "Nee", value: 0 },
      ],
    },
    {
      index: 11,
      order: 12,
      name: "Korting",
      field: "discount",
      type: "hasradio",
      options: [
        { name: "Ja", value: 1 },
        { name: "Nee", value: 0 },
      ],
    },
  ];
  const [tempfilters, setTempFilters] = useState(defaultFilters);
  const [filters, setFilters] = useState(defaultFilters);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const filterString = () => {
    if (filters.filter((e) => e.value).length) {
      let string = "&filtering=type=1,2|";
      filters
        .filter((e) => e.value)
        .map((e) => {
          if (e.index == 4 && e.value.length == 0) {
            string += e.field;
            string += "=";
            string += "0";
            string += "|";
            console.log(string, e.value);
          }
          switch (e.type) {
            case "radio":
              string += e.field;
              string += "=";
              string += e.value;
              string += "|";

              break;
            case "hasradio":
              string += e.field;
              e.value == 0 ? (string += "=,0") : (string += "!=,0");
              string += "|";

              break;
            case "range":
              if (e.value.min || e.value.max) {
                string += e.field;
                string += "<>";

                string +=
                  (e.value.min != null && e.value.min != ""
                    ? e.value.min
                    : "null") + ",";
                string +=
                  e.value.max != null && e.value.max != ""
                    ? e.value.max
                    : "null";
                string += "|";
              }
              break;
            case "dropdown":
              string += e.field;
              string += "=";
              string += e.value;
              string += "|";
              break;
            case "category":
              string += e.field;
              string += "=";
              string += e.value;
              string += "|";
              break;
            case "checkbox":
              if (e.value.length > 0) {
                string += e.field;
                string += "=";
                string += e.value.join(",");
                string += "|";
              }
              break;
          }
        });
      return string;
    } else {
      return "";
    }
  };
  const CloseFilterModal = () => {
    setShowFilterModal(false);
  };
  // fetched all the products and the stock an the colors from the database
  const fetchAll = async () => {
    try {
      setLoading(true);
      const searchQuery = searchBar.trim()
        ? `&searchTerm=${encodeURIComponent(searchBar.trim())}`
        : "";
      const [
        fetchProducts,
        fetchBrands,
        fetchCategories,
        fetchGenders,
        fetchSuppliers,
      ] = await Promise.all([
        middleware.get(
          `sp/productsTableAll?page=${pagination.page}&page_size=${
            pagination.page_size
          }${
            order.orderBy !== "" && order.direction !== ""
              ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}`
              : ""
          }${searchQuery}` + filterString()
        ),
        middleware.get(`/products/brands?orderBy=name`),
        middleware.get(`/products/category?orderBy=title`),
        middleware.get(`/products/genders?orderBy=name`),
        middleware.get(`/suppliersdetail?orderBy=name`),
      ]);
      setProducten(fetchProducts.data.data); // sets the the data in the products usestate
      setBrands(fetchBrands.data.data);
      setCategories(fetchCategories.data.data);
      setGenders(fetchGenders.data.data);
      setSuppliers(fetchSuppliers.data);
      setPagination({
        ...pagination,
        max_items: fetchProducts.data.total,
        begin_of_page: fetchProducts.data.from,
        end_of_page: fetchProducts.data.to,
      }); // sets the pagination usestate with the max items begin of the page and the end of the page
      setLoadingPage(false);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      } else {
        setLoading(false);
        console.log(error);
        setApiCheck(false);
        if (error?.response?.data?.error != null) {
          setApiError(error.response.data.error);
        } else if (error.message != null) {
          setApiError(error.message);
        } else {
          setApiError("Er is een fout met het ophalen van de Api");
        }
      }
    }
  };

  // fetches all the data when pagination.page_size, pagination.page, order or location.pathname changes
  useEffect(() => {
    if (!loading) {
      fetchAll();
    }
  }, [
    pagination.page_size,
    pagination.page,
    order,
    location.pathname,
    filters,
    searchBar,
  ]);

  // If the user searches for something specific this will set all the results in the table.
  useEffect(() => {
    try {
      if (productsSearchEngine.trim() === "" && productsSearchEngine === "") {
        // Code to fetch all when productsSearchEngine is empty
        fetchAll();
      } else if (productsSearchEngine.trim() !== "") {
        // Code to perform the search when productsSearchEngine has actual characters
        const doIt = async () => {
          const [fetchProducts] = await Promise.all([
            middleware.get(
              `${
                location.pathname === "/producten/alles"
                  ? "sp/productsTableAll"
                  : "sp/productsTableActive"
              }?page=${pagination.page}&page_size=${
                pagination.page_size
              }&searchTerm=${productsSearchEngine}&noSearch=["product_id","product_description","updated_at","created_at"]` +
                filterString()
            ),
          ]);
          setProducten(fetchProducts.data.data);
          setPagination({
            ...pagination,
            max_items: fetchProducts.data.total,
            begin_of_page: fetchProducts.data.from,
            end_of_page: fetchProducts.data.to,
          });
        };
        doIt();
      }
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  }, [productsSearchEngine]);

  // Handle checkbox selection
  const handleProductSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedProductIds([...selectedProductIds, productId]);
    } else {
      setSelectedProductIds(
        selectedProductIds.filter((id) => id !== productId)
      );
    }
  };

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds((previous) => [...previous, productId]);
    } else {
      setSelectedIds((previous) => previous.filter((id) => id !== productId));
    }
  };

  const mouseDownHandler = (event) => {};

  useEffect(() => {
    setProducten([]);
    setTimeout(() => {
      setProducten(producten);
      setLoading(false);
    }, 1);
  }, [fullyShownProducts]);

  const handleSearchChange = useCallback((value) => {
    setSearchBar(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, []);

  //columns for the table
  const columns = useMemo(() => {
    let columnsArray = [
      {
        Header: (
          <CheckboxHeader
            currentItem={producten}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            pk={"product_id"}
          ></CheckboxHeader>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          return (
            <CheckboxCell
              row={row}
              handleSelection={handleSelection}
              selectedIds={selectedIds}
              pk={"product_id"}
            />
          );
        },
      },
      {
        Header: (
          // image header (not sortable)
          <SortableHeader
            currentItem={order}
            setItem={setOrder}
            field={"product_id"}
            pos={"center"}
          >
            Afbeelding
          </SortableHeader>
        ),
        accessor: "image_path",
        Cell: ({ row }) => {
          return (
            <>
              {/* Check if a product has a image, if yes then show the first image of the product if no then show a placeholder icon */}
              {row.original.image_path != null ? (
                <div
                  className="productPhoto align-content-center rounded d-flex justify-content-center align-items-center"
                  style={{ width: "45px", height: "45px" }}
                >
                  <img
                    src={
                      middleware.defaults.baseURL +
                      "public/storage/" +
                      row.original.image_path
                    }
                    className="productImage rounded d-flex"
                    style={{ maxWidth: "45px", maxHeight: "45px" }}
                  />
                </div>
              ) : (
                <FontAwesomeIcon
                  icon={faCamera}
                  size="3x"
                  className="LightBlueText productPhoto align-self-center"
                />
              )}
            </>
          );
        },
      },
      {
        Header: () => (
          <div className="ms-5">
            <SortableHeader
              currentItem={order}
              setItem={setOrder}
              field={"product_name"}
              pos={"start"}
            >
              Productnaam
            </SortableHeader>
          </div>
          // <div
          //   onClick={e => setOrder({...order, orderBy: "product_name" , direction: `${order.direction === 'asc' ? (order.orderBy === "product_name" ? 'desc' : 'asc') : order.direction === 'desc' ? order.orderBy === 'product_name' ? '' : 'asc' : 'asc'}`})}
          // className="header-filter">
          //   Naam {order.orderBy === 'product_name' ?
          //   (order.direction === 'asc' ? <FontAwesomeIcon icon={faCaretUp} /> : order.direction === 'desc' ? <FontAwesomeIcon icon={faCaretDown}/> : '')
          //   : ''}
          // </div>
        ),
        accessor: "product_name",
        Cell: ({ row }) => {
          return (
            <span
              className="d-block align-content-center ms-5"
              style={{ height: "45px" }}
            >
              {row.original.product_name.length > 100 ? (
                fullyShownProducts.some(
                  (element) => element === row.original.product_id
                ) ? (
                  <>
                    <span
                      className="BlueText"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          `/producten/bewerken/${row.original.product_id}`
                        );
                      }}
                      onMouseDown={(event) => {
                        // sent user to new blank page on middle mouse btn click
                        if (event.button === 1) {
                          window.open(
                            `/producten/bewerken/${row.original.product_id}`,
                            "_blank"
                          );
                        }
                      }}
                    >
                      {row.original.product_name}
                    </span>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setFullyShownProducts(
                          fullyShownProducts.filter(
                            (id) => id !== row.original.product_id
                          )
                        );
                      }}
                      className="text-info hover text-nowrap ms-1"
                    >
                      Toon minder
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="BlueText"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          `/producten/bewerken/${row.original.product_id}`
                        );
                      }}
                      onMouseDown={(event) => {
                        // sent user to new blank page on middle mouse btn click
                        if (event.button === 1) {
                          window.open(
                            `/producten/bewerken/${row.original.product_id}`,
                            "_blank"
                          );
                        }
                      }}
                    >
                      {row.original.product_name.slice(0, 90)}
                    </span>
                    <span
                      onClick={(e) => {
                        setFullyShownProducts([
                          ...fullyShownProducts,
                          row.original.product_id,
                        ]);
                        setLoading(true);
                      }}
                      className="text-info hover text-nowrap ms-2"
                    >
                      (...)
                    </span>
                  </>
                )
              ) : (
                <span
                  className="BlueText cursorPointer d-block h-100 align-content-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/producten/bewerken/${row.original.product_id}`);
                  }}
                  onMouseDown={(event) => {
                    // sent user to new blank page on middle mouse btn click
                    if (event.button === 1) {
                      window.open(
                        `/producten/bewerken/${row.original.product_id}`,
                        "_blank"
                      );
                    }
                  }}
                >
                  {row.original.product_name}
                </span>
              )}
            </span>
          );
        },
      },
      {
        Header: () => (
          <div className="">
            <SortableHeader
              currentItem={order}
              setItem={setOrder}
              field={"incl_tax"}
              pos={"center"}
            >
              Prijs (incl. BTW)
            </SortableHeader>
          </div>
        ),
        accessor: "incl_tax",
        Cell: ({ row }) => {
          return (
            <div className="text-center me-5 BlackText">
              € {row.original.incl_tax || "-"}
            </div>
          );
        },
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={setOrder}
            field={"stock"}
            pos={"center"}
          >
            Voorraad
          </SortableHeader>
        ),
        accessor: "stock",
        Cell: ({ row }) => {
          return (
            <div className="text-center BlackText">
              {row.original.stock || 0}
            </div>
          );
        },
      },
    ];
    return columnsArray;
  }, [order.orderBy, order.direction, selectedIds, setSelectedIds, producten]);

  //   Table instance
  const tableInstance = useTable({ columns, data: producten || [] });

  // Destructure table properties
  // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  function handleTdClick(index, row, cell) {
    // Als het de checkbox-kolom is
    if (index === 0) {
      const isSelected = selectedProductIds.includes(row.original.product_id);
      if (isSelected) {
        handleProductSelection(row.original.product_id, false);
      } else {
        handleProductSelection(row.original.product_id, true);
      }
      return;
    }

    // Als het de product_name kolom is
    if (cell.column.id === "product_name") {
      return;
    }

    // Voor alle andere kolommen
    navigate(`/producten/bewerken/${row.original.product_id}`);
  }

  const searchDelay = async (value) => {
    setSearchBar(value);
  };

  const suspend = async () => {
    try {
      setShow(false);
      const updatePromises = selectedProductIds
        .filter((filter) =>
          producten.some(
            (filt) => filt.product_id === filter && filt.status === 0
          )
        )
        .map((data) =>
          middleware.put("products", {
            product_id: data,
            status: 1,
          })
        );

      await Promise.all(updatePromises);
      window.location.reload();
    } catch (error) {
      console.error("Failed to unsuspend products:", error);
    }
  };

  const unsuspend = async () => {
    try {
      setShowReActivate(false);
      const updatePromises = selectedProductIds
        .filter((filter) =>
          producten.some(
            (filt) => filt.product_id === filter && filt.status === 1
          )
        )
        .map((data) =>
          middleware.put("products", {
            product_id: data,
            status: 0,
          })
        );

      await Promise.all(updatePromises);
      window.location.reload();
    } catch (error) {
      console.error("Failed to unsuspend products:", error);
    }
  };

  // a function that selects all the selects in the table
  const select_all = () => {
    setSelectedIds(producten.map((product) => product.product_id));
  };

  // a function that unselects all the selects in the table
  const unselect_all = () => {
    setSelectedIds([]);
  };
  const deactivate_selected = async () => {
    try {
      // Put the products so that the selected ids products gets disabled
      const bulkData = [];
      producten
        .filter((product) => selectedIds.includes(product.product_id))
        .map((product) => {
          if (product.status !== 1) {
            bulkData.push({
              product_id: product.product_id,
              status: 1,
            });
          } else {
            ToastError(
              "één of meerdere producten zijn al gearchiveerd",
              "activateProduct"
            );
          }
        });
      if (bulkData.length != 0) {
        await middleware.put(`/products`, {
          bulk_data: bulkData,
        });
      }
      // remove products from the products array
      selectedIds.forEach((selectedId) => {
        setProducten((prevproducts) => {
          return prevproducts.filter(
            (product) =>
              product.product_id !== selectedId || product.status === 1
          );
        });
      });
      // ToastSuccess("product is bijgewerkt");
    } catch (error) {
      console.log(error);
    }
  };

  const activate_selected = async () => {
    try {
      // Put the products so that the selected ids products gets disabled
      const bulkData = [];
      producten
        .filter((product) => selectedIds.includes(product.product_id))
        .map((product) => {
          if (product.status !== 0) {
            bulkData.push({
              product_id: product.product_id,
              status: 0,
            });
          } else {
            ToastError(
              "één of meerdere producten zijn al gepubliseerd",
              "activateProduct"
            );
          }
        });
      if (bulkData.length != 0) {
        await middleware.put(`/products`, {
          bulk_data: bulkData,
        });
      }
      // remove products from the products array
      selectedIds.forEach((selectedId) => {
        setProducten((prevproducts) => {
          return prevproducts.filter(
            (product) =>
              product.product_id !== selectedId || product.status === 0
          );
        });
      });
      // ToastSuccess("product is bijgewerkt");
    } catch (error) {
      console.log(error);
    }
  };

  // handles when and wich buton is clicked
  const handleAction = (event) => {
    if (event === "suspend") {
      setShow(!show);
    } else if (event === "unsuspend") {
      setShowReActivate(!showReActivate);
    } else if (event === "select-all") {
      select_all();
    } else if (event === "unselect-all") {
      unselect_all();
    } else if (event === "deactivate-selected") {
      deactivate_selected();
    } else if (event === "activate-selected") {
      activate_selected();
    }
  };
  if (!apiCheck) {
    return <ErrorScreen error={apiError} />;
  }
  return (
    <PageTemplate
      navbarTitle={"Warehouse HUB"}
      pageIsLoading={loadingPage}
      hideBackButton={true}
    >
      <div className="productOverview">
        <Modals
          show={show}
          handleClose={(e) => setShow(false)}
          redirect={"/producten/nieuw/variatie"}
        />
        <div className="productTable w-100">
          <TablePreset
            data={[
              {
                selectedIds: selectedIds,
                tableInstance: tableInstance,
                pagination: pagination,
              },
            ]}
            tableLoading={loading}
            setPagination={(e) => setPagination(e)}
            handleSelection={handleSelection}
            primary_key={"product_id"}
            searchBar={[
              {
                shown: true,
                searchFunction: handleSearchChange,
                placeholder: "Zoeken op product, merk, etc.",
              },
            ]}
            actionOptions={[
              {
                title: "Producten publiceren",
                function: () => handleAction("activate-selected"),
              },
              {
                title: "Producten archiveren",
                function: () => handleAction("deactivate-selected"),
              },
            ]}
            leftButtons={[
              { title: "Filteren", function: () => setShowFilterModal(true) },
            ]}
            rightButtons={[
              {
                title: "Nieuw simpel product",
                function: () => navigate("/producten/nieuw"),
              },
              {
                title: "Nieuw variatie product",
                function: () => setShow(true),
              },
            ]}
          />
        </div>
      </div>
      <Filters
        filters={tempfilters}
        props={{
          brands: brands.map((e) => {
            return { name: e.name, value: e.brand_id };
          }),
          categories: categories.map((e) => {
            return {
              primary_key: e.primary_key,
              id: e.category_id,
              headId: e.head_category_id,
              name: e.title,
            };
          }),
          genders: genders.map((e) => {
            return { name: e.name, value: e.gender_id };
          }),
          suppliers: suppliers.map((e) => {
            return { name: "", value: e.supplier_id };
          }),
          suppliers: suppliers.map((e) => {
            return { name: e.company.company_name, value: e.supplier_id };
          }),
        }}
        showFilterModal={showFilterModal}
        closeFilterModal={CloseFilterModal}
        setTempFilters={setTempFilters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
    </PageTemplate>
  );
}

export default Products;
