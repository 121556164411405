import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ToastWarning from "../../Toasts/ToastWarning";
import pdf from "./pdf.svg";
import ToastError from "../../Toasts/ToastError";

const NormalMediaUploader = ({ imageValue, onImageChange, allowedFormats = ["image/jpeg", "image/png", "image/svg", "image/webp"], imgPreview }) => {
  const [image, setImage] = useState(imageValue || null);
  const [uploading, setUploading] = useState(false);
  const [uploadImgStatus, setUploadImgStatus] = useState("");
  const fileInputRef = useRef(null);
  const isPdf = allowedFormats.includes('application/pdf');

  // Wanneer de image verandert, wordt deze via de callback naar de parent gestuurd
  useEffect(() => {
    if (image && onImageChange) {
      onImageChange(image);
    }
  }, [image, onImageChange]);

  const handleFileUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let file;
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      file = e.dataTransfer.files[0];
    } else if (e.target && e.target.files && e.target.files.length > 0) {
      file = e.target.files[0];
    }
    if (!file) return;

    if (file.size > 10 * 1024 * 1024) {
      ToastWarning(`${file.name} is te groot! Maximale bestandsgrootte is 10 MB.`);
      return;
    }
    if (!allowedFormats.includes(file.type)) {
      ToastWarning(`${file.name} is geen toegestaan bestandsformaat. Toegestane formaten: ${allowedFormats.join(", ")}`);
      return;
    }

    // Maak een image-object aan met een URL en andere gegevens
    const newImage = {
      url: URL.createObjectURL(file),
      file,
      name: file.name,
      id: `${Date.now()}`
    };

    console.log();
    

    setImage(newImage);
    clearFileInput();
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    handleFileUpload(e);
  };
  

  return (
    <div className="fileUploader fileUploaderWidthHeight">
      <div
        className="mediaUploader"
        onDragEnter={handleDragOver}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        {uploading && (
          <div className="uploadScreenMU">
            <div className="uploadScreenContainerMU">
              <div className="spinner-border me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <span className="uploadScreenTextMU">{uploadImgStatus}</span>
            </div>
          </div>
        )}
        <input
          id="fileInput"
          ref={fileInputRef}
          hidden
          accept={allowedFormats.join(",")}
          type="file"
          onChange={handleFileUpload}
        />
        {imgPreview ? (
          !image ? (
            <div className="textForTheMU">
              Sleep of selecteer uw media-bestand om te uploaden
            </div>
          ) : (
            <div className="singleImagePreview" style={{ position: "relative" }}>
              {isPdf &&
                <img src={pdf} alt={image.name} style={{ maxWidth: "100px", maxHeight: "100px"}} />}
              {!isPdf && 
              <img
                className="imageInObjectMU"
                src={isPdf ? null : (image.url || imageValue)}
                alt={image.name}
                style={{ padding: "3px", minWidth: "150px", minHeight: "150px" }}
              />}
              <button
                type="button"
                className="position-absolute d-flex justify-center px-1 deleteImageButtonMU"
                onClick={(e) => {
                  e.stopPropagation();
                  setImage(null);
                }}
                title="Verwijder afbeelding"
              >
                <FontAwesomeIcon icon={faXmark} className="deleteIconMU" />
              </button>
            </div>
          )
        ) : (
          <div className="textForTheMU">
            Sleep of selecteer uw media-bestand om te uploaden
          </div>
        )}
      </div>
    </div>
  );
};

export default NormalMediaUploader;
