import React, { useState, useMemo, useEffect } from 'react'
import PageTemplate from '../Templates/PageTemplate'
import { ButtonBlue } from '../HouseStyle/Components/Buttons'
import { BorderedDropDown } from '../HouseStyle/Components/DropDowns'
import * as Icons from '../HouseStyle/Icons.js';
import TablePreset from '../Table/TablePreset';
import { useTable } from 'react-table';
import middleware from '../Api/Middleware.js';
import { useNavigate } from 'react-router';
import { use } from 'react';
import ToastError from '../Toasts/ToastError.js';

const AccountOverview = () => {
    const [loading, setLoading] = useState(true);
    const [onboardingData, setOnboardingData] = useState([]);
    const [onboardingCompleted, setOnboardingCompleted] = useState(true);
    const navigate = useNavigate();

    const fetchAll = async() => {
        try {
            const [payRes] = await Promise.all([
                middleware.get("payment/onboardings")
            ]);

            setOnboardingData(payRes.data.data[0]);
            setOnboardingCompleted(Number(payRes.data.data[0].completed));
            if(payRes.data.data.length <= 0) {
                navigate("/betalingen/account/nieuw")
                return;
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const updateOnboardingData = async (column, val) => {
        try {
            console.log(column, val); // This correctly logs the new value
            
            setLoading(true);
            if (onboardingCompleted) {
                ToastError("Onboarding is al voltooid, je kan deze informatie niet meer aanpassen");
                setLoading(false);
                return;
            }
            
            // Immediately create the updated object
            const updatedOnboardingData = { ...onboardingData, [column]: val };
    
            // Update the state
            setOnboardingData(updatedOnboardingData);
    
            // Wait for the API request with the newly updated state
            await middleware.put("payment/onboardings", updatedOnboardingData);
    
            // Refresh the data from the API after the update
            fetchAll();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };    

    console.log(onboardingData);
    

    useEffect(() => {
        fetchAll();
    }, []);

    const columns = useMemo(
        () => [
        ],
        []
    );

    const tableInstance = useTable({ columns, data: [] });

  return (
    <PageTemplate navbarTitle={"Payment HUB"} pageIsLoading={loading}>
        <div className='w-100 d-flex flex-column justify-content-start'>
            <h4 className='onboarding-payment-information-header'>Uitbetaling vindt plaats na volledige onboarding</h4>
            <div className='account-overview-container'>
                {/* Merchant information */}
                <div className='whiteBox account-overview-merchant-info account-overiew-info-tile pb-4'>
                    <h4 className='account-overview-header'>Merchant:</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td>Merchant ID</td>
                                {onboardingData?.merchant_id ? <td>{onboardingData?.merchant_id}</td> : <td className='RedText'>Nog niet onboarded</td>}
                            </tr>
                            <tr>
                                <td>Jouw merchant naam:</td>
                                <td>{onboardingData?.legal_name}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td className='OrangeText'>Uploaden</td>
                            </tr>
                            <tr>
                                <td>Saldo</td>
                                <td>€ -</td>
                            </tr>
                            <tr>
                                <td className='d-flex'>Bankrekeningen</td>
                                <td><ButtonBlue text={"Bankrekening verifiëren"}/></td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='account-overview-bordered-div'>
                        <p>Uitbetalingsfrequentie</p>
                        <BorderedDropDown placeholder={"Selecteer"}
                        selectedValue={onboardingData?.clearingInterval}
                        setSelectedValue={(val) => updateOnboardingData("clearingInterval", val)}
                        options={[
                            {title: "Dagelijks", value: "day"},
                            {title: "Weekelijks", value: "week"},
                            {title: "Maandelijks", value: "month"},
                            {title: "Handmatig (TDS auto invoice system)", value: "manual"},

                        ]}/>
                    </div>
                </div>

                {/* Onboarding */}
                <div className='whiteBox account-overview-merchant-onboarding account-overiew-info-tile'>
                    <h4 className='account-overview-header'>Onboarding documenten:</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td><span>KVK uittreksel uploaden</span><br/><span>(niet ouder dan 1 maand)</span></td>
                                <td><ButtonBlue text={"KVK uittreksel uploaden"}/></td>
                            </tr>
                            <tr>
                                <td>Kopie rekeningafschrift</td>
                                <td><ButtonBlue text={"Kopie rekeningafschrift uploaden"}/></td>
                            </tr>
                            <tr>
                                <td>Tekenbevoegden en UBO's</td>
                                <td><ButtonBlue text={"Tekenbevoegden en Ubo's Beheren"} onClickFunction={() => navigate("/betalingen/account/gebruikers")}/></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='whiteBox account-overview-merchant-paymentmethods account-overiew-info-tile pt-4'>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <h4 className='account-overview-header mb-0 pb-0'>Merchant:</h4>
                    <ButtonBlue text={"Synchroniseer betaalmethodes"} icon={Icons.Sync} iconPlacement='left'/>
                </div>
                <table className='w-50'>
                    <tbody>
                        <tr>
                            <td>Service-ID</td>
                            <td>UF-1234-5678</td>
                        </tr>
                        <tr>
                            <td>Servicenaam</td>
                            <td>UnifiedSMB</td>
                        </tr>
                        <tr>
                            <td>Verkoop locatie status</td>
                            <td className='OrangeText'>Deels actief</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <TablePreset
                emptyTableHeight={"s"}
                emptyTableText={"Geen betaalmethodes gevonden"}
                data={[{'selectedIds': [], 'tableInstance': tableInstance, 'pagination': [], 'primary_key': 'payment_method_id'}]}
            />
        </div>
    </PageTemplate>
  )
}

export default AccountOverview