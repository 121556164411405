import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import warning from "../Icons/Warning-sign.svg";
import middleware from "../../../Api/Middleware";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToastError from "../../../Toasts/ToastError";
import { useParams } from "react-router";
import { BorderedTextInput } from "../../../HouseStyle/Components/Inputs";
import { BorderedDropDown } from "../../../HouseStyle/Components/DropDowns";
import { Title } from "@mui/icons-material";

const AddContactsModal = ({
  setDataUpdated,
  setUpdatedContacts,
  closeAddContacts,
  openAddContacts,
  rowToEdit,
  setRowToEdit,
}) => {
  const [showAddContacts, setShowAddContacts] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [prefix, setPrefix] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const { internal_id } = useParams();
  useEffect(() => {
    if (openAddContacts) {
      if (rowToEdit) {
        setFirstName(rowToEdit.first_name);
        setLastName(rowToEdit.last_name);
        setBirthDay(rowToEdit.birthday);
        setPrefix(rowToEdit.prefix);
      }
      setShowAddContacts(true);
    }
  }, [openAddContacts]);

  const closeAddContactsModal = () => {
    setFirstName("");
    setLastName("");
    setBirthDay("");
    setPrefix("");
    setRowToEdit(null);
    setShowAddContacts(false);
    closeAddContacts();
  }; console.log(birthDay)

  const submitNewContact = async (e) => {
    e.preventDefault();
    if (firstName == "") {
      ToastError("Vul een voornaam in");
      return;
    }
    if (lastName == "") {
      ToastError("Vul een achternaam in");
      return;
    }
    if (birthDay == "" || birthDay == null) {
      ToastError("Vul een geboortedatum in");
      return;
    }
    if (rowToEdit) {
      const [putContactsRes] = await Promise.all([
        middleware.put(`businessescontacts?internal_id=${internal_id}`, {
          first_name: firstName,
          last_name: lastName,
          birthday: birthDay,
          prefix: prefix,
          business_contact_id: rowToEdit.business_contact_id,
        }),
      ]);
    } else {
      const [postContactsRes] = await Promise.all([
        middleware.post(`businessescontacts?internal_id=${internal_id}`, {
          first_name: firstName,
          last_name: lastName,
          birthday: birthDay,
          prefix: prefix,
        }),
      ]);
    }
    setUpdatedContacts(true);
    closeAddContactsModal();
  };

  return (
    <Modal
      id="modalBlueprint"
      show={showAddContacts}
      onHide={closeAddContactsModal}
      className="colormodals"
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header className="modalTop justify-content-between">
        <h3>
          {rowToEdit
            ? "Bewerk een contactpersoon"
            : "Voeg een contactpersoon toe"}
        </h3>
        <button
          variant="transparent"
          className=" btn"
          onClick={() => closeAddContactsModal()}
        >
          <FontAwesomeIcon icon={faXmark} className="closeModal" />
        </button>
      </Modal.Header>
      <Modal.Body className="modalCenter d-flex justify-content-center">
        <div className="d-flex flex-column justify-content-center w-100">
          <form onSubmit={submitNewContact} className="w-100">
            <div className="d-flex flex-column mb-3">
              <div className="d-flex">
                <BorderedDropDown
                  label={"Aanhef"}
                  placeholder="Dhr/Mvr"
                  size="s"
                  selectedValue={prefix}
                  setSelectedValue={(value) => setPrefix(value)}
                  options={[
                    {
                      key: 0,
                      value: 0,
                      title: "Dhr",
                    },
                    {
                      key: 1,
                      value: 1,
                      title: "Mvr",
                    },
                    {
                      key: 2,
                      value: 2,
                      title: "Dhr/Mvr",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="d-flex flex-column mb-3">
              <BorderedTextInput
                label={"Voornaam"}
                size="l"
                inputValue={firstName}
                setInputValue={(value) => setFirstName(value)}
              />
            </div>
            <div className="d-flex flex-column mb-3">
              <BorderedTextInput
                label={"Achternaam"}
                size="l"
                inputValue={lastName}
                setInputValue={(value) => setLastName(value)}
              />
            </div>
            <div className="d-flex flex-column mb-3">
              <label className="mb-2 fw-medium">Geboortedatum</label>
              <input
                type="date"
                max={today}
                className="standardInput p-3"
                onChange={(e) => setBirthDay(e.target.value)}
                defaultValue={birthDay}
              ></input>
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn lightBlueStandardButton">
                {rowToEdit ? "Edit" : "Toevoegen"}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottom"></Modal.Footer>
    </Modal>
  );
};

export default AddContactsModal;
