import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import ToastError from "../../Toasts/ToastError";
import "../ModalStyle.css";
import middleware from "../../Api/Middleware";

function CreateNoteModal({ createNoteModalVisible, setCreateNoteModalVisible, order_id, setUpdatedNotes, existingnote, }) {
    const navigate = useNavigate();
    const [subject, setSubject] = useState('');
    const [note, setNote] = useState('');

    useEffect(() => {
        if (existingnote) {
            setSubject(existingnote.subject);
            setNote(existingnote.note);
        } else {
            setSubject('');
            setNote('');
        }
    }, [existingnote])
    const saveNote = () => {
        if (!subject.trim() || !note.trim()) {
            ToastError(`Vul zowel een titel als een bericht in.`);
            return;
        }
        if (existingnote) {
            middleware.put("/orders/notes", {
                client_order_note_id: existingnote.client_order_note_id,
                order_id: order_id,
                subject: subject,
                note: note
            })
        } else {
            if (subject.trim() === '' || note.trim() === '') {
                ToastError("Vul alle velden in");
                return;
            }
            middleware.post("/orders/notes", {
                order_id: order_id,
                subject: subject,
                note: note
            })
        }
        setNote('');
        setSubject('');
        setUpdatedNotes(true);
        setCreateNoteModalVisible(false);
    }
    console.log(existingnote)
    return (
        <Modal
            id="modalNoteCreation"
            show={createNoteModalVisible}
            className="colormodals"
            size="xl"
            centered
            backdrop="static"
        >
            <Modal.Header className="modalTop justify-content-between px-5" >
                <h2>Nieuwe notitie</h2>
                <button variant="transparent" className='btn' onClick={() => {
                    setCreateNoteModalVisible(false);
                }}>
                    <FontAwesomeIcon icon={faXmark} className="closeModal" />
                </button>

            </Modal.Header>
            <Modal.Body className="modalCenter">
                <label className="standardLabel mb-1">Titel</label>
                <input className="standardInput mb-4" value={subject} onChange={(e) => setSubject(e.target.value)} type="text" />
                <label className="standardLabel mb-1">Bericht</label>
                <textarea className="standardInput" value={note} onChange={(e) => setNote(e.target.value)} style={{ minHeight: "150px" }} />
            </Modal.Body>
            <Modal.Footer className="modalBottom">
                <div className="d-flex justify-content-end" >
                    <div className="buttonPresetStyle standardBlueButton newButtons" style={{ "width": "200px" }} onClick={() => { saveNote() }}>Opslaan</div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateNoteModal