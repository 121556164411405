import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import salesChannelsApi from '../../Api/SalesChannels';
import PageTemplate from '../../Templates/PageTemplate';
import { BackButton, ButtonLightGrey } from '../../HouseStyle/Components/Buttons';
import TDSTextNavigation from '../../Templates/TDSTextNavigation';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { GetOrderStatus } from '../../Orders/Orders';
import { BorderedDropDown } from '../../HouseStyle/Components/DropDowns';
import WooCommercePluginInformation from '../WooCommerce/PluginInformation';

const SalesChannel = () => {
    const navigate = useNavigate();
    const { salesChannelId } = useParams();
    const [loading, setLoading] = useState(true);
    const [salesChannel, setSalesChannel] = useState([]);
    
    const [recentOrdersDays, setRecentOrdersDays] = useState(localStorage.getItem('SalesChannelRecentOrdersDays') ?? '7');
    const [topSellersDays, setTopSellersDays] = useState(localStorage.getItem('SalesChannelTopSellersDays') ?? '7');

    const fetchAll = async () => {
        try {
            setLoading(true);
            const [response] = await Promise.all([
                salesChannelsApi.get(`saleschannels/${salesChannelId}?recent_orders_days=${recentOrdersDays}&top_sellers_days=${topSellersDays}`),
            ]);
            setSalesChannel(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchAll();
    }, [recentOrdersDays, topSellersDays]);

    return (
        <PageTemplate navbarTitle={"Pos HUB"} pageIsLoading={loading}>
            <div className="d-flex flex-column gap-5 w-100">
                <div>
                    <div className="d-flex mb-4 mt-4 justify-content-between">
                        <div className="d-flex gap-5">
                            <BackButton text={"Terug"} onClickFunction={() => navigate('/verkoopkanalen')} />
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-xxl-row">
                        <div className="col-xxl-6 col-12">
                            <div className="col-6 col-xxl-12 pb-4">
                                <div className="whiteBox h-100 pt-auto px-5 pt-5 pb-4 ms-xxl-4 my-auto">
                                    <h5 className="inputTitle mb-4">Inhoud van verkoopkanaal</h5>
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Producten</td>
                                                    <td>{salesChannel.product_count > 0 ? salesChannel.product_count : '-'}</td>
                                                    <td><ButtonLightGrey text={"Beheren"} onClickFunction={() => navigate('producten')} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Categorieën</td>
                                                    <td>{salesChannel.category_count > 0 ? salesChannel.category_count : '-'}</td>
                                                    <td><ButtonLightGrey text={"Beheren"} onClickFunction={() => navigate('categorieën')} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Merken</td>
                                                    <td>{salesChannel.brand_count > 0 ? salesChannel.brand_count : '-'}</td>
                                                    <td><ButtonLightGrey text={"Beheren"} onClickFunction={() => navigate('merken')} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Verzendmethoden</td>
                                                    <td>-</td>
                                                    <td><ButtonLightGrey text={"Beheren"} onClickFunction={() => { }} /></td>
                                                </tr>
                                                <tr>
                                                    <td>Betaalmethoden</td>
                                                    <td>{salesChannel.payments_count > 0 ? salesChannel.payments_count : '-'}</td>
                                                    <td><ButtonLightGrey text={"Beheren"} onClickFunction={() => navigate('betaalmethoden')} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-row flex-xxl-column row col-xxl-6">
                            <div className="col-6 col-xxl-12 pb-4">
                                <div className="whiteBox h-100 pt-auto px-5 pt-5 pb-4 ms-xxl-4 my-auto">
                                    {salesChannel.type === 1 ? <WooCommercePluginInformation salesChannel={salesChannel} /> : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-xxl-row">
                        <div className="col-xxl-6 col-12">
                            <div className="col-6 col-xxl-12 pb-4">
                                <div className="whiteBox h-100 pt-auto px-5 pt-5 pb-4 ms-xxl-4 my-auto">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h5 className="inputTitle">Recente bestellingen</h5>
                                        <BorderedDropDown
                                            size='xs'
                                            options={[
                                                { title: "dag", value: '1' },
                                                { title: "Week", value: '7' },
                                                { title: "Maand", value: '30' },
                                                { title: "Jaar", value: '365' },
                                            ]}
                                            selectedValue={recentOrdersDays}
                                            setSelectedValue={(value) => {
                                                if (recentOrdersDays !== value) setLoading(true); // Called for displaying the loading screen better.
                                                setRecentOrdersDays(value);
                                                localStorage.setItem('SalesChannelRecentOrdersDays', value);
                                            }}
                                        />
                                    </div>
                                    
                                    <p>Dit is gebaseerd op de bestellingen die de laatste {recentOrdersDays == 1 ? '24 uur' : recentOrdersDays + ' dagen'} zijn aangemaakt binnen dit verkoopkanaal.</p>
                                    
                                    {salesChannel.orders?.length > 0 ? (
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Bestelnummer</th>
                                                    <th>Status</th>
                                                    <th>Prijs incl.</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {salesChannel.orders.map((order, index) => (
                                                    <tr key={index}>
                                                        <td className="d-flex flex-column">
                                                            <TDSTextNavigation
                                                                text={order.order_number || '-'}
                                                                link={`/bestellingen/overzicht/${order.order_number}?sc_return=${salesChannelId}`}
                                                                extraClassNames={'text-nowrap'}
                                                            />
                                                            {order.client_consumer ? `${order.client_consumer.first_name} ${order.client_consumer.last_name}` : '-'}
                                                            <div>
                                                                <label>{format(new Date(order.created_at), 'dd MMMM yyyy HH:mm', { locale: nl }) || "-"}</label>
                                                            </div>
                                                        </td>
                                                        <td><GetOrderStatus orderStatus={order.order_status} /></td>
                                                        <td>€ {order.total_price_incl_tax.replace('.', ',')}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div>
                                            Er zijn geen recente bestellingen.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-row flex-xxl-column row col-xxl-6">
                            <div className="col-6 col-xxl-12 pb-4">
                                <div className="whiteBox h-100 pt-auto px-5 pt-5 pb-4 ms-xxl-4 my-auto">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <h5 className="inputTitle">Populaire producten</h5>
                                        <BorderedDropDown
                                            size='xs'
                                            options={[
                                                { title: "dag", value: '1' },
                                                { title: "Week", value: '7' },
                                                { title: "Maand", value: '30' },
                                                { title: "Jaar", value: '365' },
                                            ]}
                                            selectedValue={topSellersDays}
                                            setSelectedValue={(value) => {
                                                if (topSellersDays !== value) setLoading(true);
                                                setTopSellersDays(value);
                                                localStorage.setItem('SalesChannelTopSellersDays', value);
                                            }}
                                        />
                                    </div>

                                    <p>Dit is gebaseerd op de bestellingen die de laatste {topSellersDays == 1 ? '24 uur' : topSellersDays + ' dagen'} zijn aangemaakt binnen dit verkoopkanaal.</p>

                                    {salesChannel.top_sellers?.length > 0 ? (
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Productnaam</th>
                                                    <th>Aantal verkocht</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {salesChannel.top_sellers.map((topSeller, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {topSeller.product_name ? (
                                                                <TDSTextNavigation
                                                                    text={topSeller.product_name}
                                                                    link={`/producten/bewerken/${topSeller.type === 3 ? topSeller.head_product_id : topSeller.product_id}?sc_ts_return=${salesChannelId}`}
                                                                    extraClassNames={'text-nowrap'}
                                                                />
                                                            ) : '-'}
                                                        </td>
                                                        <td>{topSeller.total_amount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <div>
                                            Er zijn geen producten verkocht binnen deze periode.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

export default SalesChannel;