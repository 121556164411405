import React from 'react';
import { useNavigate } from 'react-router';


const SettingsBox = ({ title, icon, link, disabled }) => {

    const navigate = useNavigate();

    return (
        <div className='p-2 col-3 d-flex justify-content-center'>
            <div onClick={() => !disabled && navigate(link)} role='button' className={`d-flex bg-white rounded-4 pt-5 pb-4 w-100 h-100 flex-column justify-content-between align-items-center`} style={{ cursor: disabled ? 'not-allowed' : 'pointer', opacity: disabled ? 0.5 : 1 }}>
                <div className='rounded-circle Blue SettingsBox'>
                    <div className='h-100 w-100 align-items-center d-flex justify-content-center'>
                        <img src={icon} width={35} className='img-fluid' />
                    </div>
                </div>
                <h4 className={`d-flex mt-2 justify-content-center fw-bold text-black`}>{title}</h4>
            </div>
        </div>
    );
}

export default SettingsBox;
