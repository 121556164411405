import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTable } from 'react-table';
import PageTemplate from '../../Templates/PageTemplate';
import TablePreset from '../../Table/TablePreset';
import { BackButton } from '../../HouseStyle/Components/Buttons';
import salesChannels from '../../Api/SalesChannels';
import CheckboxHeader from '../../Table/TableComponents/CheckboxHeader';
import CheckboxCell from '../../Table/TableComponents/CheckboxCell';
import SortableHeader from '../../Table/TableComponents/SortableHeader';

const CurrentBrands = () => {
    const navigate = useNavigate();
    const { salesChannelId } = useParams();
    const [brands, setBrands] = useState([]);
    const [addingBrand, setAddingBrand] = useState(0);
    const [tableLoading, setTableLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    const [selectedIds, setSelectedIds] = useState([]);
    const [searchengine, setSearchengine] = useState("");
    const [searchBarInput, setSearchBarInput] = useState("");
    const [pagination, setPagination] = useState({
        page_size: 10,
        page: 1,
        max_items: 0,
        begin_of_page: 0,
        end_of_page: 0,
    });
    const [order, setOrder] = useState({ orderBy: "", direction: "" });
    const [fullyShownBrands, setFullyShownBrands] = useState([]);
    const [searchBar, setSearchBar] = useState("");

    const fetchAll = async () => {
        try {
            setTableLoading(true);
            const searchQuery = searchBar.trim() ? `&searchTerm=${encodeURIComponent(searchBar.trim())}` : '';
            const response = await salesChannels.get(`/sp/salesChannelBrands?sales_channel_id=${salesChannelId}&include_unlinked=${addingBrand}&page=${pagination.page}&page_size=${pagination.page_size}${searchQuery}&${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}` : ''}`);
            setBrands(response.data.data);
            setPagination({
                ...pagination,
                max_items: response.data.total,
                begin_of_page: response.data.from,
                end_of_page: response.data.to,
            });
            setTableLoading(false);
            setPageLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        fetchAll();
    }, [addingBrand, pagination.page_size, pagination.page, order, searchBar]);

    const columns = useMemo(
        () => [
            {
                Header: (
                    <CheckboxHeader
                        currentItem={brands}
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                        pk={"brand_id"}
                    ></CheckboxHeader>
                ),
                accessor: "select",
                Cell: ({ row }) => {
                    return (
                        <CheckboxCell
                            row={row}
                            selectedIds={selectedIds}
                            pk={"brand_id"}
                        />
                    );
                },
            },
            {
                Header: () => (
                    <div className="ms-5">
                        <SortableHeader
                            currentItem={order}
                            setItem={setOrder}
                            field={"name"}
                            pos={"start"}
                        >
                            Merk
                        </SortableHeader>
                    </div>
                ),
                accessor: "name",
                Cell: ({ row }) => {
                    const brandEditRoute = `/warehouse/instellingen/merken/bewerken/${row.original.primary_key}`;
                    return (
                        <span
                            className="d-block align-content-center ms-5"
                            style={{ height: "45px" }}
                        >
                            {row.original.name.length > 100 ? (
                                fullyShownBrands.some(
                                    (element) =>
                                        element === row.original.brand_id
                                ) ? (
                                    <>
                                        <span
                                            className="BlueText"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(brandEditRoute);
                                            }}
                                            onMouseDown={(event) => {
                                                if (event.button === 1) {
                                                    window.open(
                                                        brandEditRoute,
                                                        "_blank"
                                                    );
                                                }
                                            }}
                                        >
                                            {row.original.name}
                                        </span>
                                        <span
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setFullyShownBrands(
                                                    fullyShownBrands.filter(
                                                        (id) =>
                                                            id !==
                                                            row.original
                                                                .brand_id
                                                    )
                                                );
                                            }}
                                            className="text-info hover text-nowrap ms-1"
                                        >
                                            Toon minder
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span
                                            className="BlueText"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(brandEditRoute);
                                            }}
                                            onMouseDown={(event) => {
                                                if (event.button === 1) {
                                                    window.open(
                                                        brandEditRoute,
                                                        "_blank"
                                                    );
                                                }
                                            }}
                                        >
                                            {row.original.name.slice(0, 90)}
                                        </span>
                                        <span
                                            onClick={(e) => {
                                                setFullyShownBrands([
                                                    ...fullyShownBrands,
                                                    row.original.brand_id,
                                                ]);
                                            }}
                                            className="text-info hover text-nowrap ms-2"
                                        >
                                            (...)
                                        </span>
                                    </>
                                )
                            ) : (
                                <span
                                    className="BlueText cursorPointer d-block h-100 align-content-center"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(brandEditRoute);
                                    }}
                                    onMouseDown={(event) => {
                                        if (event.button === 1) {
                                            window.open(
                                                brandEditRoute,
                                                "_blank"
                                            );
                                        }
                                    }}
                                >
                                    {row.original.name}
                                </span>
                            )}
                        </span>
                    );
                },
            },
        ],
        [
            order.orderBy,
            order.direction,
            selectedIds,
            setSelectedIds,
            fullyShownBrands,
            brands,
        ]
    )

    const handleSelection = (brandId, isSelected) => {
        setSelectedIds((previous) =>
            isSelected
                ? [...previous, brandId]
                : previous.filter((id) => id !== brandId)
        );
    }

    const tableInstance = useTable({ columns, data: brands });

    const Action = async (methode) => {
        switch (methode) {
            case "add":
                if (selectedIds.length === 0) return;
                setTableLoading(true);
                await salesChannels.post(`saleschannels/${salesChannelId}/brands/add`, { brand_ids: selectedIds });
                setSelectedIds([]);
                fetchAll();
                break;
            case "delete":
                if (selectedIds.length === 0) return;
                setTableLoading(true);
                await salesChannels.post(`saleschannels/${salesChannelId}/brands/delete`, { brand_ids: selectedIds });
                setSelectedIds([]);
                fetchAll();
                break;
        }
    }

    const actionOptions = [
        addingBrand
            ? {
                title: "Voeg toe aan verkoopkanaal",
                function: () => Action("add"),
            }
            : {
                title: "Verwijder uit verkoopkanaal",
                function: () => Action("delete"),
            },
    ]

    const handleSearchChange = (value) => {
        setSearchBar(value);
        setPagination(prev => ({ ...prev, page: 1 }));
    };

    const handleBackButton = () => {
        if (addingBrand) {
            setTableLoading(true);
            setAddingBrand(0);
            setPagination({
                ...pagination,
                page: 1,
            });
            setSelectedIds([]);
        } else {
            navigate(`/verkoopkanalen/${salesChannelId}`);
        }
    }

    return (
        <PageTemplate
            navbarTitle={
                addingBrand === 0
                    ? "Merken in het verkoopkanaal"
                    : "Merken toevoegen aan verkoopkanaal"
            }
            pageIsLoading={pageLoading}
        >
            <div className="d-flex flex-column gap-5 w-100">
                <div>
                    <div className="d-flex mb-4 mt-4 justify-content-between">
                        <div className="d-flex gap-5">
                            <BackButton
                                text={"Terug"}
                                onClickFunction={handleBackButton}
                            />
                        </div>
                    </div>

                    <div className="">
                        <TablePreset
                            data={[
                                {
                                    selectedIds: selectedIds,
                                    tableInstance: tableInstance,
                                    pagination: pagination,
                                    primary_key: "brand_id",
                                },
                            ]}
                            setPagination={(e) => setPagination(e)}
                            tableLoading={tableLoading}
                            handleSelection={handleSelection}
                            searchBar={[{ shown: true, searchFunction: handleSearchChange, placeholder: "Zoeken op merk, etc." }]}
                            actionOptions={actionOptions}
                            leftButtons={[
                                { title: "Filteren", function: () => { } },
                            ]}
                            rightButtons={
                                addingBrand === 1
                                    ? []
                                    : [
                                        {
                                            title: "Voeg merken toe aan verkoopkanaal",
                                            function: () => {
                                                setTableLoading(true);
                                                setAddingBrand(1);
                                                setPagination({
                                                    ...pagination,
                                                    page: 1,
                                                });
                                                setSelectedIds([]);
                                            },
                                        },
                                    ]
                            }
                        />
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

export default CurrentBrands;