import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { ButtonBlue, ButtonLightGrey } from '../../HouseStyle/Components/Buttons';

const ConfirmationDeactivationModal = ({showConfirmaton, setShowConfirmation, selectedIds, handleDeativation}) => {
  return (
    <Modal show={showConfirmaton} onHide={() => setShowConfirmation(false)} centered backdrop="static" size="xl">
    <Modal.Header className="modalTop d-flex justify-content-between">
      <Modal.Title className="ms-3 BlockTitle ">Let op!</Modal.Title>
      <button
        className="border-0 bg-transparent"
        variant="transparent"
        onClick={() => setShowConfirmation(false)}
      >
        <FontAwesomeIcon icon={faXmark} className="closeModal" />
      </button>
    </Modal.Header>
    <Modal.Body className="modalCenter pb-0 justify-content-around">
      <div className="ms-3">
        U gaat {selectedIds.length}{" "}
        {selectedIds.length === 1 ? "item" : "items"} de-activeren. Weet U
        dat zeker?
      </div>
    </Modal.Body>
    <Modal.Footer className="modalBottom">
      <div className="d-flex flex-row">
        <div className="me-3">
          <ButtonLightGrey text={"Annuleren"} className="ms-3 btn bg-danger text-white" onClickFunction={() => setShowConfirmation(false)}/>
        </div>
        <ButtonBlue
          text={"De-activeren"}
          onClickFunction={() => {
            setShowConfirmation(false);
            handleDeativation();
          }}
        />
        </div>
    </Modal.Footer>
  </Modal>
  );
}

export default ConfirmationDeactivationModal;
