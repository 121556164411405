import React, { useMemo, useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { RegularDropDown, BorderedDropDown } from "../HouseStyle/Components/DropDowns.js";
import "./Table.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonBlue, ButtonDarkBlue, ButtonLightGrey, ButtonWhite, BackButton } from "../HouseStyle/Components/Buttons.js";
import { faCamera, faChevronRight, faChevronLeft, faMagnifyingGlass, faXmark, faChevronDown, faAnglesLeft, faAnglesRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { RegularSearchBar, BorderedSearchBar } from "../HouseStyle/Components/SearchBars.js";
import middleware from "../Api/Middleware.js";
import ToastSuccess from "../Toasts/ToastSuccess.js";

function TablePreset({
  data = [],
  tableLoading,
  actionOptions = [],
  showCheckbox = true,
  leftButtons,
  rightButtons,
  rightDropdowns,
  setPagination,
  handleSelection,
  searchBar,
  enableDrag = false,
  apiEndPoint,
  emptyTableHeight = "m",
  emptyTableText = "Geen gegevens voor deze tabel gevonden"
}) {
  // Voor paginering en extra opties
  const pageSizes = [10, 25, 50, 100, 250, 500];
  const tableInstance = data[0]?.tableInstance ?? {};
  const selectedIds = data[0]?.selectedIds ?? [];
  const pagination = data[0]?.pagination ?? {};
  // Stel ook de primary key in (bijv. voor het selecteren of als drag‑id)
  const primaryKey = data[0]?.primary_key ?? null;

  const {
    getTableProps = () => {},
    getTableBodyProps = () => {},
    headerGroups = [],
    rows = [],
    prepareRow = () => {},
  } = tableInstance;
  const options = actionOptions;

  // Voor drag‑functionaliteit: als deze ingeschakeld is, beheren we een lokale state voor de rijen
  const [dragRows, setDragRows] = useState([]);
  const [dragCellWidths, setDragCellWidths] = useState([]);

  useEffect(() => {
    if (enableDrag) {
      // Maak een kopie van de rijen (bijv. uit react‑table) en gebruik deze voor drag-and-drop
      setDragRows([...rows]);
    }
  }, [rows, enableDrag]);

  // Helper om props zonder key door te geven
  const getPropsWithoutKey = (props) => {
    const { key, ...restProps } = props;
    return restProps;
  };

  const handleDragStart = (start) => {
    const draggableId = start.draggableId;
  
    // Zoek de rij via draggableId
    const rowElement = document.querySelector(`[data-rfd-drag-handle-draggable-id="${draggableId}"]`);
  
    if (rowElement) {
      // Selecteer alle td's en meet hun breedte
      const cells = rowElement.querySelectorAll("td");
  
      const widths = Array.from(cells).map(cell => {
        const rect = cell.getBoundingClientRect(); // Dit geeft de zichtbare breedte
        const style = getComputedStyle(cell);
  
        // Bereken de werkelijke breedte door padding en borders af te trekken
        const paddingLeft = parseFloat(style.paddingLeft);
        const paddingRight = parseFloat(style.paddingRight);
        const borderLeft = parseFloat(style.borderLeftWidth);
        const borderRight = parseFloat(style.borderRightWidth);
  
        // Werkelijke breedte is de gemeten breedte min padding en borders
        const cellWidth = rect.width - 100 - paddingLeft - paddingRight - borderLeft - borderRight;
        
        // Return de breedte zonder de extra marges en voeg "px" toe
        return `${cellWidth}px`;
      });
      // Zet de gemeten breedtes in de state
      setDragCellWidths(widths);
    }
  };
  

  // Deze functie wordt aangeroepen wanneer een rij wordt versleept en neergezet
  const handleDragEnd = async (result) => {
    setDragCellWidths([]);
    if (!result.destination) return;
    const items = Array.from(dragRows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    

    // Update per rij de sorting_order op basis van de nieuwe positie.
    // Houd hierbij rekening met paginering (als dat nodig is).
    const updatedItems = items.map((row, index) => {
      const newOrder =
        index +
        (pagination.page === 1 ? 0 : pagination.page_size * (pagination.page - 1)) +
        1;
      row.original.sorting_order = newOrder;
      return row;
    });

    // Indien er een callback is meegegeven (bijv. om direct de backend te updaten)
    if (apiEndPoint) {
        handleDragUpdate(updatedItems);
    }

    setDragRows(updatedItems);
  };

  const handleDragUpdate = async (updatedItems) => {
    // Maak een array met de nodige info om op te slaan
    
    const sortedItems = updatedItems.map((item) => ({
      primary_key: item.original.primary_key, // of vervang "id" met je eigen primary key veld
      sorting_order: item.original.sorting_order,
    }));

    try {
      if (apiEndPoint === "/api/endpoint") {
        ToastSuccess("Volgorde aangepast");
        return;
      }
      await middleware.put(apiEndPoint, {
        bulk_data: sortedItems,
      });
      ToastSuccess("Sorteer volgorde opgeslagen");
    } catch (error) {
      console.error("Fout bij opslaan sorteer volgorde:", error);
    }
  };

  // Afhankelijk van of drag aan staat, renderen we de rijen uit de lokale state of uit de table instance
  const rowsToRender = enableDrag ? dragRows : rows;

  return (
    <>
      <div className="InputRow justify-content-between mb-4">
        <div className="InputRow w-50">
          {actionOptions.length >= 1 && options && (
            <RegularDropDown options={options} size="xs" />
          )}
          {leftButtons &&
            leftButtons.length > 0 &&
            leftButtons.map((btn, index) => {
              return btn.color === "LightGray" ? (
                <ButtonLightGrey
                  disabled={btn.disabled}
                  key={index}
                  text={btn.title}
                  onClickFunction={() => btn.function()}
                  size={btn.size ?? "s"}
                />
              ) : (
                <ButtonWhite
                  disabled={btn.disabled}
                  key={index}
                  text={btn.title}
                  onClickFunction={() => btn.function()}
                  size={btn.size ?? "xs"}
                />
              );
            })}
            
          {searchBar && searchBar[0].shown ? (
            searchBar[0].bordered === true ? (
              <BorderedSearchBar
                size="m"
                inputValue={searchBar[0].inputValue}
                setInputValue={searchBar[0].setInputValue}
                placeholder={searchBar[0].placeholder}
                searchFunction={searchBar[0].searchFunction}
                searchDelayTimer={searchBar[0].searchDelayTimer}
                responseItems={searchBar[0].responseItems}
                columnHeaders={searchBar[0].columnHeaders}
                resultsLoading={searchBar[0].resultsLoading}
              />
            ) : (
              <RegularSearchBar
                size="m"
                inputValue={searchBar[0].inputValue}
                setInputValue={searchBar[0].setInputValue}
                placeholder={searchBar[0].placeholder}
                searchFunction={searchBar[0].searchFunction}
                searchDelayTimer={searchBar[0].searchDelayTimer}
                responseItems={searchBar[0].responseItems}
                columnHeaders={searchBar[0].columnHeaders}
                resultsLoading={searchBar[0].resultsLoading}
              />
            )
          ) : null}
        </div>
        <div className="InputRow">
          {rightDropdowns && rightDropdowns.length > 0 &&
            rightDropdowns.map((dropdown, index) => (
              <BorderedDropDown
                key={index}
                placeholder={dropdown.placeholder || ""}
                options={dropdown.options}
                noOptionsMessage={dropdown.noOptionsMessage}
                selectedValue={dropdown.selectedValue}
                setSelectedValue={dropdown.setSelectedValue}
                size={dropdown.size}
              />
            ))}
          {rightButtons &&
            rightButtons.length > 0 &&
            rightButtons.map((btn, index) => (
              <ButtonBlue key={index} text={btn.title} onClickFunction={btn.function} />
            ))}
        </div>
      </div>

      {data[0].tableInstance.data && data[0].tableInstance.data.length > 0 ? (
        tableLoading ? (
          <div className="tableLoadingScreen flex-column">
            <div
              className="spinner-border text-white mb-3"
              role="status"
              style={{ height: "100px", width: "100px" }}
            />
            <h3 style={{ maxWidth: "fit-content" }} className="text-white mb-5">
              Laden...
            </h3>
          </div>
        ) : (
          <div className="tablePresetBackground">
            {enableDrag ? (
            <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                <table className="tablePreset" {...getPropsWithoutKey(getTableProps())}>
                <thead className="customTheadPreset">
                    {headerGroups.map((headerGroup, i) => (
                    <tr
                        key={`header-group-${i}`}
                        {...getPropsWithoutKey(headerGroup.getHeaderGroupProps())}
                    >
                        {headerGroup.headers.map((column) => (
                        <th
                            key={column.id}
                            className="px-3 py-3 fitContent header-filter tablePresetHeaderRow"
                            {...getPropsWithoutKey(column.getHeaderProps())}
                        >
                            {column.render("Header")}
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <Droppable droppableId="tbody">
                    {(provided) => (
                    <tbody
                        className="customTbodyPreset"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {rowsToRender.map((row, index) => {
                        prepareRow(row);
                        const firstKey =
                            row.original[primaryKey] ||
                            row.original[Object.keys(row.original)[0]];
                        return (
                            // Voeg een data attribute toe aan de rij zodat we die later kunnen vinden
                            <Draggable
                            key={row.id || `row-${index}`}
                            draggableId={firstKey.toString()}
                            index={index}
                            >
                            {(providedRow, snapshot) => (
                                <tr
                                data-draggable-id={firstKey.toString()} // voor het selecteren in onDragStart
                                ref={providedRow.innerRef}
                                {...providedRow.draggableProps}
                                {...providedRow.dragHandleProps}
                                className={`tablePresetDataRow ${
                                    snapshot.isDragging ? "dragging" : ""
                                }`}
                                {...getPropsWithoutKey(row.getRowProps())}
                                >
                                {row.cells.map((cell, cellIndex) => (
                                    <td
                                    key={cell.column.id || `cell-${index}-${cellIndex}`}
                                    onClick={
                                      showCheckbox && cellIndex === 0
                                        ? () => {
                                            const isSelected = selectedIds.includes(firstKey);
                                            handleSelection(firstKey, !isSelected);
                                            }
                                        : null
                                    }
                                    className={`px-3 py-2 tablePresetDataCol`}
                                    {...getPropsWithoutKey(cell.getCellProps())}
                                    >
                                    <div
                                        className={`cellWrapper ${
                                        snapshot.isDragging ? "draggingCell" : ""
                                        }`}
                                        // Als er breedtes gemeten zijn en de rij wordt gesleept,
                                        // pas dan de breedte van de betreffende cel toe
                                        style={
                                        snapshot.isDragging &&
                                        dragCellWidths &&
                                        dragCellWidths[cellIndex]
                                            ? { width: dragCellWidths[cellIndex] }
                                            : {}
                                        }
                                    >
                                        {cell.render("Cell")}
                                    </div>
                                    </td>
                                ))}
                                </tr>
                            )}
                            </Draggable>
                        );
                        })}
                        {provided.placeholder}
                    </tbody>
                    )}
                </Droppable>
                </table>
            </DragDropContext>
            ) : (
              <table className="tablePreset" {...getPropsWithoutKey(getTableProps())}>
                <thead className="customTheadPreset">
                  {headerGroups.map((headerGroup, i) => (
                    <tr
                      key={`header-group-${i}`}
                      {...getPropsWithoutKey(headerGroup.getHeaderGroupProps())}
                    >
                      {headerGroup.headers.map((column) => (
                        <th
                          key={column.id}
                          className="px-3 py-3 fitContent header-filter tablePresetHeaderRow"
                          {...getPropsWithoutKey(column.getHeaderProps())}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="customTbodyPreset" {...getPropsWithoutKey(getTableBodyProps())}>
                  {rowsToRender.map((row, i) => {
                    prepareRow(row);
                    const firstKey =
                      row.original[primaryKey] || row.original[Object.keys(row.original)[0]];
                    return (
                      <tr
                        key={row.id || `row-${i}`}
                        {...getPropsWithoutKey(row.getRowProps())}
                        className="tablePresetDataRow"
                      >
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            key={cell.column.id || `cell-${i}-${cellIndex}`}
                            onClick={
                              showCheckbox && cellIndex === 0
                                ? () => {
                                    const isSelected = selectedIds.includes(firstKey);
                                    handleSelection(firstKey, !isSelected);
                                  }
                                : null
                            }
                            className="px-3 py-2 tablePresetDataCol"
                            {...getPropsWithoutKey(cell.getCellProps())}
                          >
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        )
      ) : (
      <div className={`tableLoadingScreen ${emptyTableHeight} flex-column`}>
            {emptyTableText}
        </div>
      )}
      {pagination && pagination.length !== 0 &&
        <div className="d-flex justify-content-between py-1 w-100">
          <div className="d-flex gap-0 ">
            <select
              value={pagination.page_size}
              onChange={(e) =>
                setPagination({
                  ...pagination,
                  page_size: Number(e.target.value),
                  page: 1,
                })
              }
              className="page-sizer Nunito"
            >
              {pageSizes.map((pageSize, index) => {
                return (
                  <option key={index} value={pageSize}>
                    {pageSize}
                  </option>
                );
              })}
            </select>
            {pagination.begin_of_page &&
            pagination.end_of_page &&
            pagination.max_items ? (
              <div className="page-display text-nowrap me-4 Nunito">
                {pagination.begin_of_page}-{pagination.end_of_page} van {pagination.max_items}
              </div>
            ) : null}
          </div>
          <div className="d-flex gap-1">
            <button
              onClick={(e) => setPagination({ ...pagination, page: 1 })}
              disabled={pagination.page === 1}
              type="button"
              className="btn rounded-2 border-0 px-1 text-nowrap toFirstLastPage"
            >
              <FontAwesomeIcon className="text-white" icon={faAnglesLeft} />
            </button>
            <button
              onClick={(e) =>
                setPagination({ ...pagination, page: pagination.page - 1 })
              }
              disabled={pagination.page <= 1}
              type="button"
              className="btn rounded-2 border-0 pageBackNext"
            >
              <FontAwesomeIcon className="text-white" icon={faChevronLeft} />
            </button>
            <button
              onClick={(e) =>
                setPagination({ ...pagination, page: pagination.page - 1 })
              }
              hidden={pagination.page <= 1}
              type="button"
              className="btn rounded-2 border-0 bg-white text-center pageNumBlocks Nunito"
              style={{
                fontWeight: "600",
                color: "#009fe3",
                width: "30px",
                height: "30px",
              }}
            >
              {pagination.page - 1}
            </button>
            <button
              type="button"
              className="btn rounded-2 border-0 bg-white text-center pageNumBlocks Nunito"
              disabled
              style={{
                fontWeight: "600",
                color: "#009fe3",
                cursor: "default",
                width: "30px",
                height: "30px",
              }}
            >
              {pagination.page}
            </button>
            <button
              onClick={(e) =>
                setPagination({ ...pagination, page: pagination.page + 1 })
              }
              hidden={
                Math.ceil(pagination.max_items / pagination.page_size) <= pagination.page
              }
              type="button"
              className="btn rounded-2 border-0 bg-white text-center pageNumBlocks Nunito"
              style={{
                fontWeight: "600",
                color: "#009fe3",
                width: "30px",
                height: "30px",
              }}
            >
              {pagination.page + 1}
            </button>
            <button
              onClick={(e) =>
                setPagination({ ...pagination, page: pagination.page + 1 })
              }
              disabled={
                Math.ceil(pagination.max_items / pagination.page_size) === pagination.page ||
                pagination.max_items <= 0
              }
              type="button"
              className="btn rounded-2 border-0 pageBackNext"
            >
              <FontAwesomeIcon className="text-white" icon={faChevronRight} />
            </button>
            <button
              onClick={(e) =>
                Math.ceil(pagination.max_items / pagination.page_size) === 0
                  ? setPagination({ ...pagination, page: 1 })
                  : setPagination({
                      ...pagination,
                      page: Math.ceil(pagination.max_items / pagination.page_size),
                    })
              }
              disabled={
                Math.ceil(pagination.max_items / pagination.page_size) === pagination.page ||
                pagination.max_items <= 0
              }
              type="button"
              className="btn rounded-2 border-0 text-nowrap px-1 toFirstLastPage"
            >
              <FontAwesomeIcon className="text-white" icon={faAnglesRight} />
            </button>
          </div>
        </div>
      }
      
    </>
  );
}

export default TablePreset;
