import React, { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { faPencilAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import InputComponent from '../../Customer/AddCustomers/InputComponent';
import middleware from '../../Api/Middleware';
import ToastError from '../../Toasts/ToastError';
import ToastInfo from '../../Toasts/ToastInfo';
import ToastSuccess from '../../Toasts/ToastSuccess';
import { BorderedTextInput } from '../../HouseStyle/Components/Inputs';
import { BorderedDropDown } from '../../HouseStyle/Components/DropDowns';

const AddEmailModal = (props) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({email: "", type: "0"});


    const addEmail = async () => {
        let addressRes = await middleware.post("/emails", {
            internal_id: props.customer.internal_id,
            email: formData.email,
        });
        ToastSuccess("Email is aangemaakt");
        props.setSelectedEmail(addressRes.data);

        if(props?.order.client_emails) {
            props.setOrder({...(props.order), client_emails: [...props.order.client_emails, addressRes.data]});
        } else {
            props.setEmails([...props.emails, addressRes.data]);
        }
        props.setAddEmailModalVisible(false);
    }
    return (
        <Modal
            id="modalItemNote"
            show={props.addEmailModalVisible}
            onHide={{}}
            className="colormodals"
            size="xl"
            centered
            backdrop="static"
        >
            <Modal.Header className="modalTop justify-content-between px-5" >
                <h3>E-mail toevoegen</h3>
                <button variant="transparent" className=' btn' onClick={() => { props.setAddEmailModalVisible(false) }}>
                    <FontAwesomeIcon icon={faXmark} className="closeModal" />
                </button>
            </Modal.Header>
            <Modal.Body className="modalCenter d-flex flex-column">
                <div className="">
                    <div>
                        Type
                        <BorderedDropDown placeholder='Type' selectedValue={formData.type} setSelectedValue={(e) => setFormData({ ...formData, type: e})} options={[{value: '0', title: "Normaal"}, {value: '1', title: "Factuur"}]} />
                        <div className="mb-4">
                            <BorderedTextInput inputValue={formData.email} setInputValue={(e) => setFormData({ ...formData, email: e.target.value })} placeholder='test@test.nl' label={"E-mail"} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <button className="btn DarkStandardButton" type="button" onClick={() => { props.setAddEmailModalVisible(false) }}>Annuleren</button>
                        <button className="btn lightBlueStandardButton" onClick={() => addEmail()}>{"Toevoegen"}</button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modalBottom">
            </Modal.Footer>
        </Modal>
    )
}

export default AddEmailModal
