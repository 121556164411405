import React, { useState } from "react";


const RadioFilter = ({ setFilters, filter }) => {
    const setFilterValue = (e) => {
        setFilters((filters) => {
            const newFilters = [...filters];
            newFilters[filters.findIndex((e) => e.index == filter.index)].value = e.target.checked ? e.target.value : null;
            return newFilters
        })
    }
    return <div className="d-flex w-100 h-100">
        <div className="bg-white rounded-4 h-100 p-4 border border-gray w-100">
            <h6 className="fw-bold">{filter.name}</h6>
            <div className="d-flex flex-row w-100 flex-wrap">
                {filter.options.map((e, key) => <div key={key}>
                    <input className="me-2" type="checkbox" checked={e.value == filter.value} onChange={(e) => setFilterValue(e)} value={e.value} name={filter.index} id={filter.index} />
                    <label className="pe-2">{e.name}</label>
                </div>)
                }
            </div>
        </div>
    </div>
}

export default RadioFilter;