import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router";
import Cookies from "universal-cookie";
import middleware from "../../Api/Middleware";
import { useNavigate } from "react-router";
import PageTemplate from "../../Templates/PageTemplate";
import { BackButton } from "../../HouseStyle/Components/Buttons";
import { BorderedDropDown } from "../../HouseStyle/Components/DropDowns.js";
import ToastError from "../../Toasts/ToastError";
import ToastSuccess from "../../Toasts/ToastSuccess";

function GiftcardEdit() {
    const navigate = useNavigate();
    const { card_id } = useParams();
    const [giftcard, setGiftcard] = useState(null);
    const [cardStatus, setCardStatus] = useState(null);
    const [cardCode, setCardCode] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingPage, setLoadingPage] = useState(false);
    const [status, setStatus] = useState(false);

    const fetchcard = async () => {
        try {
            const [fetchCard] = await Promise.all([
                middleware.get(`giftcards?card_id=${card_id}`),
              ]);
              const fetchedData = fetchCard.data.data;
              setGiftcard(fetchedData);
              setCardStatus(fetchedData.status);
              setCardCode(fetchedData.code);
        } catch {
            ToastError('Fout bij het ophalen van de data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchcard();
    }, [card_id]);

    const updateGiftcard = async (newStatus) => {
        try {
          await middleware.put(`giftcards`, {card_id: card_id, status: newStatus });
          ToastSuccess('Status succesvol bijgewerkt');
        } catch (error) {
          ToastError('Fout bij het bijwerken van de status');
        }
      };
    
    return (
        <>
            <PageTemplate navbarTitle={"Cadeaukaarten"} pageIsLoading={loadingPage}>
                <div className="w-100 d-flex flex-column">
                    <div className="d-flex flex-row mb-3">
                        <BackButton text={"Terug"} disabled={false} onClickFunction={() => navigate("/algemene/instellingen/cadeaukaarten")} />
                        <div className="d-flex flex-column align-items-center">
                            {!status ? (
                                <p className="mb-1 p-0">Gepubliceerd</p>
                            ) : (
                                <p className="mb-1 p-0">Gearchiveerd</p>
                            )}
                            <label className="switchActiveProduct">
                                <input
                                    type="checkbox"
                                    checked={!status}
                                    onChange={(e) => setStatus(!e.target.checked)}
                                />
                                <span className="sliderActiveProduct round"></span>
                            </label>
                        </div>
                    </div>
                    <div className="whiteBox">
                        <div className="w-50 px-3 py-4">
                            <p className="TableTitle">Cadeaukaart: {cardCode}</p>
                            <p className="FlatText fw-bold mb-0 ps-3">Status</p>
                            <BorderedDropDown
                            placeholder={cardStatus == 1 ? 'Uitgegeven' : 'Op voorraad'}
                                options={[
                                    { title: "Uitgegeven", value: "1"},
                                    { title: "Op voorraad", value: "0"},
                                ]}
                                selectedValue={cardStatus}
                                setSelectedValue={(value) => {
                                    setCardStatus(value);
                                    updateGiftcard(value);
                                  }}
                            />
                        </div>
                    </div>
                </div>
            </PageTemplate>
        </>
    )
}

export default GiftcardEdit;