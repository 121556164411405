import React, { useEffect } from "react";
import RadioFilter from "./FilterComponents/RadioFilter";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Rangefilter from "./FilterComponents/Rangefilter";
import Dropdownfilter from "./FilterComponents/Dropdownfilter";
import Categoryfilter from "./FilterComponents/Categoryfilter";
import Daterangefilter from "./FilterComponents/Daterangefilter";
import CheckboxFilter from "./FilterComponents/CheckboxFilter";
import { ButtonBlue, ButtonDarkBlue } from "../HouseStyle/Components/Buttons";


const Filters = ({ props, setFilters, filters, defaultFilters, showFilterModal, setTempFilters, closeFilterModal }) => {
    useEffect(() => {
        ResetFilters();
    }, [])
    const ResetFilters = () => {
        setTempFilters([...defaultFilters].map((e) => { if (e.options && !Array.isArray(e.options)) { return { ...e, options: props[e.options] } } else { return e } }));
    }
    const SaveFilters = () => {
        setFilters(filters);
        closeFilterModal();
    }
    const FilterSwitch = (filter) => {
        switch (filter.type) {
            case 'radio': case 'hasradio':
                return <RadioFilter setFilters={setTempFilters} filter={filter} />
            case 'range':
                return <Rangefilter setFilters={setTempFilters} filter={filter} />
            case 'daterange':
                return <Daterangefilter setFilters={setTempFilters} filter={filter} />
            case 'dropdown':
                return <Dropdownfilter setFilters={setTempFilters} filter={filter} />
            case 'category':
                return <Categoryfilter setFilters={setTempFilters} filter={filter} />
            case 'checkbox':
                return <CheckboxFilter setFilters={setTempFilters} filter={filter} />
        }
    }
    return (
        <Modal
            id="modalBlueprint"
            show={showFilterModal}
            onHide={closeFilterModal}
            className="colormodals"
            size="xl"
            centered
            backdrop="static"
        >
            <Modal.Header className="modalTop justify-content-between px-5" >
                <h3>Filteren</h3>
                <button variant="transparent" className=' btn' onClick={closeFilterModal}>
                    <FontAwesomeIcon icon={faXmark} className="closeModal" />
                </button>
            </Modal.Header>
            <Modal.Body className="modalCenter">
                <div className='row w-100'>
                    {filters.sort((a, b) => a.order - b.order).map((e, index) => {
                        return <div key={index} className="col-3 mb-4">{FilterSwitch(e)}</div>
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer className="modalBottom">
                <div className="d-flex justify-content-end" >
                    <ButtonDarkBlue textStyling="WhiteText me-2" text={"Resetten"} onClickFunction={() => ResetFilters()}/>
                    <ButtonBlue text={"Filter(s) toepassen"} onClickFunction={() => SaveFilters()}/>
                </div>
            </Modal.Footer>
        </Modal>)
}

export default Filters;