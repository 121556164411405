// React and hooks
import React, { useMemo, useState, useRef, forwardRef, useEffect } from "react";

// Router
import {
  json,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

// API
import middleware from "../../../Api/Middleware";
import salesChannelsApi from "../../../Api/SalesChannels";

// CSS
import "../css/AddProductPages.css";
import "../../../Main/Main.css";
import "../../product.css";
import "../css/UploadArea.css";

// Components
import PageTemplate from "../../../Templates/PageTemplate";
import ToastError from "../../../Toasts/ToastError";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import LoadingScreen from "../../../LoadingScreen/LoadingScreen";
import ErrorScreen from "../../../LoadingScreen/ErrorScreen";
import { ButtonDarkBlue } from "../../../HouseStyle/Components/Buttons";
import DeleteConfirmModal from "../../modals/DeleteConfirmModal";
import FirstBox from "./edit_components/boxfirst";
import PriceBox from "./edit_components/boxPrice";
import ThirdBox from "./edit_components/boxthird";
import BoxVariation from "./edit_components/BoxVariation";
import MediaUploaderComponent from "../../../MediaUpload/MediaUploaderComponent";
import Categories from "../sub_components/Categories";

// Media
import Backbutton from "../images/BackIcon.svg";
import Cookies from "universal-cookie";
import { includes } from "lodash";

const EditPage = (props) => {
  // console.log(JSON.stringify(props));
  // Variables
  const cookies = new Cookies();
  const { product_id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // State variables
  const [loading, setLoading] = useState(true);
  const [showBrandModal, setShowBrandModal] = useState(true);
  const [selectedOption, setSelectedOption] = useState("3");
  const [tax, setTax] = useState("0");
  const [taxClass, setTaxClass] = useState(0);
  const [selectedTax, setSelectedTax] = useState("");
  const [product, setProduct] = useState([{}]);
  const [oldProduct, setOldProduct] = useState([{}]);
  const [barcode, setBarcode] = useState();
  const [adjustedBarcode, setAdjustedBarcode] = useState();
  const [oldBarcode, setOldBarcode] = useState();
  const [barcodeId, setBarcodeId] = useState([{}]);
  const [brands, setBrands] = useState([{}]);
  const [variationPrice, setVariationPrice] = useState([{}]);
  const [prices, setPrices] = useState({
    price: { incl_tax: "", excl_tax: "" },
    sale_price: { incl_tax: "", excl_tax: "" },
    buy_in_price: { incl_tax: "", excl_tax: "" },
  });
  const [priceMargin, setPriceMargin] = useState();
  const [stock, setStock] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [attributeConnections, setAttributeConnections] = useState([]);
  const [updatedProduct, setUpdatedProduct] = useState(false);
  const [updatedTax, setUpdatedTax] = useState(false);
  const [updatedBarcode, setUpdatedBarcode] = useState(false);
  const [updatedBrands, setUpdatedBrands] = useState(false);
  const [updatedPrices, setUpdatedPrices] = useState(false);
  const [updatedMedia, setUpdatedMedia] = useState(false);
  const [updatedGender, setUpdatedGender] = useState(false);
  const [updatedCategories, setUpdatedCategories] = useState(false);
  const [updatedStock, setUpdatedStock] = useState(false);
  const [updatedVariations, setUpdatedVariations] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [productsHasBrands, setProductsHasBrands] = useState([{}]);
  const [productBrand, setProductBrand] = useState({});
  const [generatedBarcode, setGeneratedBarcode] = useState({});
  const [categories, setCategories] = useState([]);
  const [requiredCheck, setRequiredCheck] = useState(false);
  const [genders, setGenders] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedVariations, setSelectedVariations] = useState([]);
  const [variationSwitch, setVariationSwitch] = useState();
  const [errors, setErrors] = useState([]);
  const [apiCheck, setApiCheck] = useState(true);
  const [apiError, setApiError] = useState("");
  const [images, setImages] = useState([]);
  const [salesChannels, setSalesChannels] = useState([]);
  const [selectedSalesChannels, setSelectedSalesChannels] = useState([]);
  const [deleteBtnDisabled, setDeleteBtnDisabled] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  // Variation state variables
  const [allVariations, setAllVariations] = useState([]);
  const [allSizes, setAllSizes] = useState([]);
  const [allColors, setAllColors] = useState([]);
  // state to track the last updated variation id
  const [lastUpdatedVariation, setLastUpdatedVariation] = useState(null);
  // set to track errors to prevent duplicate errors
  const processedErrors = new Set();

  // State for holding categories.
  const [initialCategories, setInitialCategories] = useState([
    { id: 1, headId: null, name: "Categories" },
  ]);

  const [initialCategoriesNew, setInitialCategoriesNew] = useState([
    { id: 1, headId: null, name: "Categories" },
  ]);

  // A helper function that recursively organizes the products based on their headId.
  const organizeCategory = (categories, parentId = null) => {
    // Ensure the input is an array
    return categories
      .filter((category) => category.headId === parentId)
      .map((category) => {
        return {
          ...category,
          subcategories: organizeCategory(categories, category.id),
        };
      });
  };
  const [checkedCategories, setCheckedCategories] = useState({});
  const [categoryTree, setCategoryTree] = useState(
    organizeCategory(initialCategories)
  );
  const [categoryTreeNew, setCategoryTreeNew] = useState(
    organizeCategory(initialCategoriesNew)
  );

  // --- New functions added to address missing definitions ---

  // A function to calculate final price for a variation.
  // Replace the logic below with your real calculation as needed.
  const calculateFinalPrice = (variation) => {
    // console.log("Calculating final price for variation:", variation);
    // Example: you might update the variation with a calculated field
    // const finalPrice = parseFloat(variation.basePrice) + 10; // dummy calculation
    // setAllVariations((prev) =>
    //   prev.map((v) =>
    //     v.id === variation.id ? { ...v, finalPrice } : v
    //   )
    // );
  };

  // Function to handle variation price change.
  const handleVariationPriceChange = (value, variationId) => {
    alert(`${value} ${variationId}`);
    let price = value;
    if (price != null && price !== 0 && price !== "") {
      price = price.toString().replace(",", ".");
    }
    setVariationPrice((prev) => ({ ...prev, [variationId]: price }));
    setLastUpdatedVariation(variationId);
    const variation = allVariations.find((v) => v.id === variationId);
    if (variation) {
      calculateFinalPrice(variation);
    }
    // Flag that variations were updated.
    setUpdatedVariations(true);
  };

  // --- End of new functions ---

  const fetchAll = async () => {
    try {
      const [fetchProduct, fetchGeneratedBarcode] = await Promise.all([
        middleware.get(`products/full?product_id=${product_id}`),
        middleware.get("products/generateBarcode"),
      ]);
      // console.log("fetchedProducts", fetchProduct);
      // check if the product exists
      if (
        fetchProduct.data?.Message != null ||
        fetchProduct.data.product.product.type === 3
      ) {
        // Display message and sent user back to the products page
        if (fetchProduct.data?.Message != null) {
          ToastError(fetchProduct.data.Message);
        } else {
          ToastError("Dit is geen geldig product");
        }
        navigate("/producten");
        return;
      }
      setProduct(fetchProduct.data.product.product);

      setVariationSwitch(
        fetchProduct.data.product.product.variation_type == 0
          ? false
          : true ?? null
      );
      setOldProduct(fetchProduct.data.product.product);
      setBarcode(fetchProduct.data.product.product.barcode?.barcode ?? null);
      setOldBarcode(fetchProduct.data.product.product.barcode?.barcode ?? null);
      setBarcodeId(
        fetchProduct.data.product.product.barcode?.barcode_id ?? null
      );
      setBrands(fetchProduct.data.data.brands);
      setSalesChannels(fetchProduct.data.data.salesChannels);
      setPrices(fetchProduct.data.product.product.prices);

      // set prices with 2 decimals
      setPrices((prevPrices) =>
        prevPrices.map((price) => ({
          ...price,
          incl_tax: Number(price.incl_tax).toFixed(2),
          excl_tax: Number(price.excl_tax).toFixed(2),
        }))
      );
      setStock(
        fetchProduct.data.product.product.stock ?? {
          amount: 0,
          stock_behavior: 0,
          deliverability: 0,
          maximal: 0,
          minimal: 0,
        }
      );
      setVariationPrice(fetchProduct.data.product.variations.variation_price);
      setSuppliers(fetchProduct.data.data.suppliers);
      setBusinesses(fetchProduct.data.data.businesses);
      setAttributeConnections(fetchProduct.data.product.product.attributes);
      setProductsHasBrands(fetchProduct.data.product.product.brand);
      setGeneratedBarcode(fetchGeneratedBarcode.data.generatedBarcode);
      setGenders(fetchProduct.data.data.genders);
      setSelectedCategories(fetchProduct.data.product.product.categories);
      // Select the genders that were selected before
      const oldGenders = fetchProduct.data.product.product.genders.map(
        (e) => e.gender_id
      );
      setSelectedGenders([...selectedGenders, ...oldGenders]);
      setTaxClass(fetchProduct.data.product.product.tax_id);
      if (fetchProduct.data.product.product.tax_id == 2) {
        setTax("9");
        setSelectedTax("9");
      } else if (fetchProduct.data.product.product.tax_id == 1) {
        setTax("21");
        setSelectedTax("21");
      } else {
        setTax("0");
        setSelectedTax("0");
      }

      // Remove delete button if product has orders
      setDeleteBtnDisabled(fetchProduct.data.data.ordersSoldProducts);

      // Category
      const cat = fetchProduct.data.data.categories.map((map) => {
        return {
          primary_key: map.primary_key,
          id: map.category_id,
          headId: map.head_category_id,
          name: map.title,
        };
      });
      setInitialCategories(cat);
      setInitialCategoriesNew(cat);
      setCategories(fetchProduct.data.data.categories);
      setAllSizes(fetchProduct.data.data.sizes);
      setAllColors(fetchProduct.data.data.colors);

      if (fetchProduct.data.product.product.type == 2) {
        // fetch selected variations when the product type is 1 and reverse the array so the most recent variations get showed first
        setSelectedVariations(fetchProduct.data.product.variations.reverse());
      }
      setFirstLoad(true);
      setLoading(false);
    } catch (error) {
      // show error when Api returns an error
      setLoading(false);
      console.log(error);
      setApiCheck(false);
      if (error?.response?.data?.error != null) {
        setApiError(error.response.data.error);
      } else if (error.message != null) {
        setApiError(error.message);
      } else {
        setApiError("Er is een fout met het ophalen van de Api");
      }
    }
  };
  const fetchMedia = async () => {
    try {
      const res = await middleware.get(
        `products/media?product_id=${product_id}`
      );
      setImages(res.data.data);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (updatedCategories) {
      UpdateAll();
      setUpdatedCategories(false);
    }
  }, [updatedCategories]);
  useEffect(() => {
    if (updatedProduct) {
      UpdateAll();
      setUpdatedProduct(false);
    }
  }, [updatedProduct]);
  useEffect(() => {
    if (updatedGender) {
      UpdateAll();
      setUpdatedGender(false);
    }
  }, [updatedGender]);
  useEffect(() => {
    if (updatedVariations) {
      UpdateAll();
      setUpdatedVariations(false);
    }
  }, [updatedVariations]);
  useEffect(() => {
    if (updatedMedia) {
      UpdateAll();
      setUpdatedMedia(false);
    }
  }, [updatedMedia]);
  useEffect(() => {
    if (updatedStock) {
      UpdateAll();
      setUpdatedStock(false);
    }
  }, [updatedStock]);
  useEffect(() => {
    if (updatedTax) {
      UpdateAll();
      setUpdatedTax(false);
    }
  }, [updatedTax]);

  useEffect(() => {
    let variations = [];
    let count = 0;
    if (
      allColors != null &&
      selectedVariations != null &&
      allColors.length != 0 &&
      allSizes != null &&
      allSizes.length != 0 &&
      selectedVariations.length != 0
    ) {
      // loop trough selectedVariations and replace the color and the size object to get the correct data
      selectedVariations.forEach((variation) => {
        // if variation_price is not empty and contains a - or + sign

        if (variation.variation_price != null) {
          if (variation.variation_price.includes("-")) {
            variation.variation_operator = "-";
          } else {
            variation.variation_operator = "+";
          }
        }

        // set colors
        if (variation.color != null) {
          const color = allColors.find(
            (color) => color.color_id === variation.colors[0].color_id
          );
          variation.colors = color;
        }
        // set sizes
        if (variation.size != null) {
          const size = allSizes.find(
            (size) => size.size_id === variation.sizes[0].size_id
          );
          variation.sizes = size;
        }
        const customVariation = {
          ...variation, // spread the original properties
          id: count, // add or update the id field
          color_name: variation.colors[0]?.name ?? null, // assign the color name from colors object
          size_name: variation.sizes[0]?.main ?? null,
          hex: variation.colors?.hex ?? null, // assign the size name from sizes object
        };
        customVariation.color = customVariation.colors?.color_id ?? null;
        customVariation.size = customVariation.sizes?.size_id ?? null;
        variations.push(customVariation);
        count++;
      });
      setAllVariations(variations);
    }
  }, [allColors, selectedVariations]);

  useEffect(() => {
    if (product.product_id != null) {
      setProductBrand(productsHasBrands);
      setSelectedOption("3");
    }
  }, [productsHasBrands]);

  // Set the fetched selected categories as selected
  useEffect(() => {
    if (selectedCategories.length) {
      const prev = { ...checkedCategories };
      initialCategories.forEach((initialCategory) => {
        if (
          selectedCategories.find((e) => e.category_id == initialCategory.id)
        ) {
          prev[
            `1-${initialCategory.primary_key}-${initialCategory.id}-${
              initialCategory.headId ?? 0
            }`
          ] = true;
        }
      });
      setCheckedCategories(prev);
    }
  }, [selectedCategories]);

  const proccessCategorieIds = (obj) => {
    const regex = /^(\d+)-(\d+)-(\d+)-(\d+)$/;
    const categoryIds = [];
    const HeadIds = [];

    Object.entries(obj)
      .filter(([key, value]) => value === true && regex.test(key))
      .forEach(([key]) => {
        const match = key.match(regex);
        if (match) {
          categoryIds.push(match[3]); // Voeg het derde deel toe
          HeadIds.push(match[4]); // Voeg het vierde deel toe
        }
      });

    return {
      categoryIds,
      HeadIds,
    };
  };

  // End category code
  useEffect(() => {
    if (selectedOption == 1) {
      setBarcode(product.product_number);
    } else if (selectedOption == 2) {
      setBarcode(generatedBarcode);
    } else if (selectedOption == 0) {
      setBarcode(adjustedBarcode);
    }
  }, [selectedOption]);

  const categoryIds = proccessCategorieIds(checkedCategories).categoryIds;
  useEffect(() => {
    if (selectedOption != 3) {
      UpdateAll();
    }
  }, [barcode]);

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  const validate = (e) => {
    e.target.checkValidity();
    e.target.reportValidity();
  };

  const timeout = useRef();

  const UpdateAll = async () => {
    const productName = product.product_name;
    if (productName == null || productName == "") {
      ToastError("Product naam mag niet leeg zijn");
      return false;
    }
    // check if a field changed
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(async () => {
      // Set prices
      const newprices = prices.reduce((acc, curr) => {
        acc[curr.type] = {
          price_id: curr.price_id,
          incl_tax:
            typeof curr.incl_tax === "string"
              ? Number(curr.incl_tax.replace(",", ".")).toFixed(2)
              : Number(curr.incl_tax).toFixed(2),
          excl_tax:
            typeof curr.excl_tax === "string"
              ? Number(curr.excl_tax.replace(",", ".")).toFixed(2)
              : Number(curr.excl_tax).toFixed(2),
        };
        return acc;
      }, {});
      // Validate input fields
      let errorMessage = [];
      let result = await validateImages();
      if (result && result.length) {
        errorMessage.push(result);
      }
      if (product.type == 2) {
        allVariations.forEach((element) => {
          if (element.barcode == "" || element.barcode == null) {
            errorMessage.push("Vul een barcode in");
          }
        });
      }
      // set stock amount correctly
      let newStock = stock;
      if (newStock != null && product.type != 2) {
        if (newStock.amount == "" || newStock.amount == null) {
          // set stock to 0 if stock is empty to prevent errors from the database
          newStock.amount = 0;
        }
      }

      if (errorMessage == "" || errorMessage == null) {
        //Check if barcode is null or 13

        if (errors.length == 0) {
          try {
            // console.log("allVariations" + JSON.stringify(allVariations)); // Debug to see all variations

            // Send the edit request to the api
            const response = await middleware.put("/products/edit", {
              product: {
                product_id: product.product_id,
                name: product.product_name,
                number: product.product_number,
                description: product.product_description,
                tax_id: taxClass,
                variation_type: variationSwitch,
                barcode: barcode,
                variationprice: variationPrice,
              },
              prices: newprices,
              category_ids: categoryIds,
              sales_channel_ids: selectedSalesChannels,
              brand_id: Number(productBrand?.brand_id) ?? null,
              gender_ids: selectedGenders,
              stock: product.type !== 2 ? newStock : null,
              variations: allVariations,
            });

            

            await saveImages(product.product_id);
            ToastSuccess("product is bijgewerkt", "productUpdate");
            // set prices and stock to the response in case the prices don't have a price id which results in errors
            setPrices(
              response.data.product.prices.map((curr) => ({
                ...curr,
                incl_tax: Number(curr.incl_tax).toFixed(2),
                excl_tax: Number(curr.excl_tax).toFixed(2),
              }))
            );

            setProduct(response.data.product);
            setStock(response.data.product.stock);
          } catch (error) {
            if (error.response?.data?.error) {
              // show all barcode duplicate errors
              if (error.response.data.error != null) {
                // loop trough all errors
                if (Array.isArray(error.response.data.error)) {
                  error.response.data.error.forEach((barcodeError) => {
                    if (!processedErrors.has(barcodeError.message)) {
                      ToastError(barcodeError.message);
                      console.log(error);
                      // Prevent to show the same error multiple times
                      processedErrors.add(barcodeError.message);
                    }
                  });
                  setErrors(error.response.data.error.map((er) => er.index));
                } else if (Array.isArray(error.response?.data?.details)) {
                  error.response.data.details.forEach((barcodeError) => {
                    if (!processedErrors.has(barcodeError.message)) {
                      ToastError(barcodeError.message);
                      console.log(error);
                      // Prevent to show the same error multiple times
                      processedErrors.add(barcodeError.message);
                    }
                  });
                } else {
                  console.log(error);
                  ToastError(error.response.data.error);
                }
              } else {
                ToastError(
                  "Er is een fout met het verturen van de data. Controleer de ingevulde gegevens en probeer opnieuw."
                );
              }
            } else {
              console.log(error);
            }
          }
        }
      } else {
        if (errorMessage.length > 1) {
          ToastError("Er zijn meerdere velden niet ingevuld!");
          console.log(errorMessage);
        } else {
          ToastError(errorMessage[0]);
        }
      }
    }, 1500);
  };

  // updatebutton function end
  const ShowPrice = (type, turned, fixed) => {
    var res = [];
    if (turned == undefined || turned == false) {
      res = prices
        .filter((filter) => filter.type == type)
        .map((map) => map[tax == 0 ? "incl_tax" : "excl_tax"]);
    } else {
      res = prices
        .filter((filter) => filter.type == type)
        .map((map) => map[tax == 0 ? "excl_tax" : "incl_tax"]);
    }
    return fixed !== undefined || fixed == false
      ? Number(res).toFixed(2)
      : String(Number(res));
  };

  const ChangeStatus = async (newStatus) => {
    try {
      const change = await middleware.put("/products", {
        product_id: product_id,
        status: newStatus,
      });
      setProduct(change.data.data);
      ToastSuccess("Status product aangepast");
    } catch (error) {
      ToastError(error);
      console.log(error);
    }
  };

  const handlePriceChange = (event) => {
    const priceType = event.target.name;
    const inclTax = event.target.value;
    const exclTax = inclTax ? (inclTax / (1 + tax / 100)).toFixed(3) : "";
    setPrices((prevPrices) => {
      // if price was not ever set then make a new object
      if (prevPrices == null || prevPrices.length == 0) {
        prevPrices = [
          {
            price_id: null,
            incl_tax: 0,
            excl_tax: 0,
            type: "price",
          },
          {
            price_id: null,
            incl_tax: 0,
            excl_tax: 0,
            type: "sale_price",
          },
          {
            price_id: null,
            incl_tax: 0,
            excl_tax: 0,
            type: "buy_in_price",
          },
          {
            price_id: null,
            incl_tax: 0,
            excl_tax: 0,
            type: "marge",
          },
        ];
      }
      setUpdatedPrices(true);
      if (event.target.value !== "") {
        return prevPrices.map((price) =>
          price.type === priceType
            ? {
                ...price,
                incl_tax: inclTax === "" ? "" : Number(inclTax), // Allow empty value
                excl_tax: exclTax === "" ? "" : Number(exclTax), // Allow empty value
              }
            : price
        );
      } else {
        return prevPrices.map((price) =>
          price.type === priceType
            ? {
                ...price,
                incl_tax: inclTax === "" ? "" : Number(inclTax),
                excl_tax: exclTax === "" ? "" : Number(exclTax),
              }
            : price
        );
      }
    });
  };

  useEffect(() => {
    fetchAll();
    fetchMedia();
  }, []);
  // if the loading state is true put on a loading screen
  if (loading) {
    return <LoadingScreen />;
  }
  // if the api returns an error then show it in the errorscreen
  if (!apiCheck) {
    return <ErrorScreen error={apiError} />;
  }
  // if the product doesn't exist or is a variation (type 3) then put on the error page instead of showing an empty edit page
  if (product == null || product.length == 0 || product.type == 3) {
    return (
      <ErrorScreen
        error={"Dit product bestaat niet of is niet een geldig product"}
      />
    );
  }

  const handleDelete = async (e) => {
    // Delete product if the product
    try {
      await middleware.delete(
        `/products/deleteProduct?product_id=${product_id}`
      );
      navigate("/producten");
      ToastSuccess("Het product is succesvol verwijderd");
    } catch (error) {
      ToastError("Er was een probleem met het verwijderen van het product");
      console.log(error);
    }
    // close the modal
    setDeleteModalVisible(false);
  };

  const handleBackButton = () => {
    if (searchParams.get("wc_return") != null) {
      navigate(
        `/verkoopkanalen/woocommerce/${searchParams.get("wc_return")}/producten`
      );
    } else if (searchParams.get("wc_ts_return") != null) {
      navigate(
        `/verkoopkanalen/woocommerce/${searchParams.get("wc_ts_return")}`
      );
    } else {
      navigate("/producten");
    }
  };

  const validateImages = async () => {
    //Check if there are any images that are faulty (like too big or unsupported file type)
    let faultyImages = false;
    for (let index = 0; index < images.length; index++) {
      const CurrentImage = images[index];
      if (CurrentImage.error) {
        faultyImages = true;
      }
    }
    let everythingFilledIn = true;
    const useColors = product.type == 2;
    if (useColors) {
      //Check if every file has a color assigned to it
      for (let index = 0; index < images.length; index++) {
        const CurrentImage = images[index];
        if (CurrentImage.colorId === undefined) {
          everythingFilledIn = false;
        }
      }
    }
    //If there are any images that are faulty, a pop-up will appear and will stop the code from running any further
    if (faultyImages) {
      return "Verwijder de foto's die ongeldig zijn voordat je opslaat.";
    }
    // If there are any images that have no color assigned to them, a pop-up will appear and will stop the code from running any further
    else if (useColors && !everythingFilledIn) {
      return true;
      // return "Vul alle kleuren/maten in!";
    } else {
      //This is so if the upload went succesful and you add a new image, it won't have a red ring around it.
    }
    return true;
  };
  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () =>
        resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
      reader.onerror = (error) => reject(error);
    });

  const saveImages = async (productID) => {
    try {
      let currentProductId = productID;
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const currentYear = new Date().getFullYear();
      const folderPath = `warehouse/uploads/${currentYear}/${currentMonth}/${currentProductId}`;

      //The string with all the data that will be send to the API so it saves the image path, order and color
      let bulkDataString = "";
      let imagestrings = [];
      const useColors = product.type == 2;

      //Loop through the images and preparing them to upload
      for (let index = 0; index < images.length; index++) {
        const CurrentImage = images[index];
        const base64String = await blobToBase64(CurrentImage.file);
        imagestrings.push(base64String);

        //Add the data to the bulkdata that will eventually be sent to the middleware API
        const colorId = CurrentImage.colorId != 0 ? CurrentImage.colorId : null;
        bulkDataString += `${CurrentImage.name}|${
          useColors ? colorId : null
        }|${folderPath}/${CurrentImage.name.replace(/\s/g, "_")}|${index + 1}|${
          useColors ? CurrentImage.sizeId : null
        }`;
        if (index < images.length - 1) {
          bulkDataString += ",";
        }
      }
      // Post request to the middleware api to save the image data to the database and delete the older information
      await middleware.post(
        `products/media/upload?product_id=${currentProductId}&bulk_data=${encodeURIComponent(
          bulkDataString
        )}`,
        { imagestrings: imagestrings }
      );

      return true;
    } catch (error) {
      return (
        error.response?.data?.Message ?? error.response?.data?.error ?? false
      );
    }
  };

  const handleChange = () => {
    UpdateAll();
  };
  // Gender change
  const handleGendersChange = (event) => {
    const valueAsInt = parseInt(event.target.value, 10);
    setUpdatedGender(true);

    if (event.target.checked) {
      setSelectedGenders([...selectedGenders, valueAsInt]);
    } else {
      setSelectedGenders(selectedGenders.filter((e) => e !== valueAsInt));
    }
  };

  const handleSalesChannelsChange = (event) => {
    const valueAsInt = parseInt(event.target.value, 10);

    if (event.target.checked) {
      setSelectedSalesChannels([...selectedSalesChannels, valueAsInt]);
    } else {
      setSelectedSalesChannels(
        selectedSalesChannels.filter((e) => e !== valueAsInt)
      );
    }
    UpdateAll();
  };

  const transformData = (data) => {
    let headCategoryMap = {};

    data.HeadIds.forEach((headId) => {
      const headCategory = categories.find(
        (cat) => cat.category_id === parseInt(headId)
      );
      if (headCategory) {
        headCategoryMap[headCategory.title] = data.categoryIds
          .map((catId) => {
            const category = categories.find(
              (cat) => cat.category_id === parseInt(catId)
            );
            return category &&
              category.head_category_id === headCategory.category_id
              ? category.title
              : null;
          })
          .filter((title) => title !== null);
      }
    });

    return headCategoryMap;
  };

  const transformedData = transformData(
    proccessCategorieIds(checkedCategories)
  );

  return (
    <PageTemplate navbarTitle={"Warehouse HUB"}>
      <DeleteConfirmModal
        deleteModalVisible={deleteModalVisible}
        setDeleteModalVisible={setDeleteModalVisible}
        handleDelete={handleDelete}
      />
      {/* back to products btn */}
      <div className="d-flex flex-column gap-5 w-100">
        <form onSubmit={UpdateAll}>
          <div className="d-flex mb-4 mt-4 justify-content-between">
            <div className="d-flex gap-5">
              <div className="d-flex flex-row gap-2 align-items-center">
                <img
                  className="back-button-img"
                  src={Backbutton}
                  onClick={handleBackButton}
                />
                <h5 className="p-0 m-0">Terug</h5>
              </div>
              {/* Published btn*/}
              <div className="d-flex flex-column align-items-center">
                {!product.status ? (
                  <p className="mb-1 p-0">Gepubliceerd</p>
                ) : (
                  <p className="mb-1 p-0">Gearchiveerd</p>
                )}
                <label className="switchActiveProduct">
                  <input
                    type="checkbox"
                    checked={!product.status}
                    onChange={(e) => ChangeStatus(!e.target.checked)}
                  />
                  <span className="sliderActiveProduct round"></span>
                </label>
              </div>
            </div>
            <div>
              {!deleteBtnDisabled && (
                <ButtonDarkBlue
                  text={"Verwijderen"}
                  onClickFunction={() => setDeleteModalVisible(true)}
                />
              )}
            </div>
          </div>
          <div className="d-flex flex-column flex-xxl-row">
            <div className="col-xxl-8 col-12">
              <FirstBox
                handleChange={handleChange}
                product={product}
                setProduct={setProduct}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                setShowBrandModal={setShowBrandModal}
                brands={brands}
                barcode={barcode}
                setBarcode={setBarcode}
                setUpdatedProduct={setUpdatedProduct}
                setUpdatedBarcode={setUpdatedBarcode}
                setUpdatedBrands={setUpdatedBrands}
                brand={productBrand}
                setProductBrand={setProductBrand}
                generatedBarcode={generatedBarcode}
                setGeneratedBarcode={setGeneratedBarcode}
                adjustedBarcode={adjustedBarcode}
                setAdjustedBarcode={setAdjustedBarcode}
                oldBarcode={oldBarcode}
                validate={validate}
                edit={true}
              />
              {product.type == 2 ? (
                <div className="mt-4">
                  <BoxVariation
                    allVariations={allVariations}
                    setAllVariations={setAllVariations}
                    allSizes={allSizes}
                    allColors={allColors}
                    setUpdatedVariations={setUpdatedVariations}
                    errors={errors}
                    setErrors={setErrors}
                    prices={prices}
                    // Pass handleVariationPriceChange to BoxVariation if needed
                    handleVariationPriceChange={handleVariationPriceChange}
                    edit={true}
                  />
                </div>
              ) : (
                <></>
              )}

              <div className="whiteBox my-4">
                <MediaUploaderComponent
                  edit={true}
                  updatedMedia={true}
                  setUpdatedMedia={setUpdatedMedia}
                  setImages={setImages}
                  images={images}
                  useColors={product.type == 2}
                  availableVariations={allVariations}
                  setCheck={setRequiredCheck}
                  product={product}
                  variationSwitch={variationSwitch}
                  setVariationSwitch={setVariationSwitch}
                ></MediaUploaderComponent>
              </div>
            </div>
            <div className="d-flex flex-row flex-xxl-column row col-xxl-4">
              <div className="col-6 col-xxl-12">
                <div className="whiteBox boxCategories mb-4 ms-xxl-4">
                  <div className="d-flex flex-column">
                    <div className="widthBoxes categories">
                      {categories != null && categories.length ? (
                        <Categories
                          initialCategories={initialCategories}
                          checkedCategories={checkedCategories}
                          setCheckedCategories={setCheckedCategories}
                          setUpdatedCategories={setUpdatedCategories}
                        />
                      ) : (
                        <div className="whiteBox boxCategories">
                          <div className="d-flex flex-column">
                            <div className=" widthBoxes w-100 p-3 categories">
                              <div className="d-flex justify-content-between flex-column">
                                <h5 className="inputTitle mb-3">Categorieën</h5>
                                <p>Er zijn geen categorieën gevonden</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-xxl-12 pb-4">
                <div className="whiteBox h-100 pt-auto px-5 pt-5 pb-4 ms-xxl-4 my-auto">
                  <h5 className="inputTitle mb-4">Geslacht</h5>
                  <div>
                    {genders.map((gender, index) => (
                      <div key={index} className="d-flex flex-row mb-3">
                        <label className="checkbox-containerSimple">
                          <input
                            type="checkbox"
                            value={gender.gender_id}
                            defaultChecked={selectedGenders.find(
                              (e) => e == gender.gender_id
                            )}
                            onChange={(e) => handleGendersChange(e)}
                          />
                          <span className="checkmarkSimple"></span>
                        </label>
                        <h5 className="inputTitle fw-medium">{gender.name}</h5>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-6 col-xxl-12 pb-4">
                <PriceBox
                  selectedTax={selectedTax}
                  setSelectedTax={setSelectedTax}
                  setTax={setTax}
                  taxClass={taxClass}
                  setTaxClass={setTaxClass}
                  ShowPrice={ShowPrice}
                  HandlePriceChange={handlePriceChange}
                  handleChange={handleChange}
                  prices={prices}
                  setPrices={setPrices}
                  priceMargin={priceMargin}
                  setPriceMargin={setPriceMargin}
                  setUpdatedTax={setUpdatedTax}
                  validate={validate}
                  edit={true}
                />
              </div>
              {product.type != 2 && (
                <div className="col-6 col-xxl-12 pb-4">
                  <ThirdBox
                    stock={stock}
                    setStock={setStock}
                    validate={validate}
                    setUpdatedStock={setUpdatedStock}
                    edit={true}
                  />
                </div>
              )}
              <div className="pb-4 col-6 col-xxl-12">
                <div className="whiteBox p-5 h-100 ms-xxl-4">
                  <div className="d-flex flex-column h-100">
                    <h4 className="inputTitle mb-4">Verkoopkanaal</h4>
                    <div className="d-flex h-100 flex-column">
                      {salesChannels.length > 0 ? (
                        salesChannels.map((salesChannel, index) => (
                          <div
                            key={index}
                            className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between"
                          >
                            <h6 className="switchText">
                              {salesChannel.sales_channel_name}
                            </h6>
                            <label className="defaultSwitch ms-4">
                              <input
                                type="checkbox"
                                checked={product?.sales_channels?.find(
                                  (e) =>
                                    e.sales_channel_id ===
                                    salesChannel.sales_channel_id
                                )}
                                value={salesChannel.sales_channel_id}
                                onChange={handleSalesChannelsChange}
                              />
                              <span className="sliderActiveProduct round"></span>
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>Er zijn nog geen verkoopkanalen gemaakt.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </PageTemplate>
  );
};

export default EditPage;
