import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import middleware from "../../../Api/Middleware";
import salesChannels from "../../../Api/SalesChannels";
import TablePreset from "../../../Table/TablePreset";
import "../../../Table/Table.css";
import "../../../Orders/Orders.css";
import { useNavigate, useParams } from "react-router";
import CheckboxHeader from "../../../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../../../Table/TableComponents/CheckboxCell";
import SortableHeader from "../../../Table/TableComponents/SortableHeader";
import Trash from "../Icons/trash-can.svg";
import QuotationModal from "../CustomerModals/QuotationModal";
import ToastError from "../../../Toasts/ToastError";
import ToastSuccess from "../../../Toasts/ToastSuccess";

const Quotation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // New state for filtered data
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  });
  const [order, setOrder] = useState({ orderBy: "", direction: "" });
  const [searchBar, setSearchBar] = useState("");
  const [showQuotationModal, setShowQuotationModal] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const { internal_id } = useParams();

  const fetchAll = async () => {
    setLoading(true);
    try {
      const [offersResponse] = await Promise.all([
        middleware.get(
          `offers?page=${pagination.page
          }&internal_id=${internal_id}&page_size=${pagination.page_size}${order.orderBy !== "" && order.direction !== ""
            ? `&orderBy=${order.orderBy}&direction=${order.direction}`
            : ""
          }`
        ),
      ]);
      setData(offersResponse.data.data.data);
      setFilteredData(offersResponse.data.data.data); // Set initial filtered data
      setPagination({
        ...pagination,
        max_items: offersResponse.data.data.total,
        begin_of_page: offersResponse.data.data.from,
        end_of_page: offersResponse.data.data.to,
      });
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const closeModalQuotation = () => setShowQuotationModal(false);

  useEffect(() => {
    try {
      fetchAll();
    } catch (error) {
      console.warn(error);
    }
  }, [pagination.page_size, pagination.page, order, order.direction]);

  const [selectedIds, setSelectedIds] = useState([]);

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds((previous) => [...previous, productId]);
    } else {
      setSelectedIds((previous) => previous.filter((id) => id !== productId));
    }
  };

  useEffect(() => {
    const statusMapping = {
      0: "openstaand",
      1: "getekend",
    };

    if (searchBar) {
      const lowerCaseSearch = searchBar.toLowerCase();
      setFilteredData(
        data.filter((item) => {
          const statusLabel =
            statusMapping[item.offer_status]?.toLowerCase() || "";

          return (
            item.order_number.toLowerCase().includes(lowerCaseSearch) ||
            item.expires_at.toLowerCase().includes(lowerCaseSearch) ||
            item.created_at.toLowerCase().includes(lowerCaseSearch) ||
            statusLabel.includes(lowerCaseSearch)
          );
        })
      );
    } else {
      setFilteredData(data); // Reset to original data if search term is empty
    }
  }, [searchBar, data]);

  const GetStatus = ({ Status }) => {
    switch (Status) {
      case 0:
        return <label className="text-info">Openstaand</label>;
      case 1:
        return <label className="text-success">Getekend</label>;
    }
  };

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString("nl-NL"); // Formats the date
    const formattedTime = date.toLocaleTimeString("nl-NL", {
      hour: "2-digit",
      minute: "2-digit",
    });

    return { formattedDate, formattedTime };
  };

  const handleDeleteClick = (row) => {
    setRowToDelete(row);
    setShowQuotationModal(true);
  };

  const handleDeleteConfirmed = async () => {
    if (rowToDelete) {
      try {
        // Replace 'offer_id' with the correct identifier field for your data
        await middleware.delete(`offers?offer_id=${rowToDelete.offer_id}`);
        ToastSuccess("Offerte succesvol verwijderd");
        setShowQuotationModal(false);
        fetchAll(); // Re-fetch the data to update the table
      } catch (error) {
        console.error("Error deleting offer:", error);
        ToastError(
          "Er is een fout opgetreden bij het verwijderen van de offerte."
        );
      }
    }
  };

  // Columns for the table
  const columns = useMemo(
    () => [
      {
        Header: (
          <CheckboxHeader
            currentItem={data}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            pk={"offer_id"}
          ></CheckboxHeader>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          return (
            <CheckboxCell
              row={row}
              handleSelection={handleSelection}
              selectedIds={selectedIds}
              pk={"offer_id"}
            />
          );
        },
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={setOrder}
            pos={"start"}
            field="offer_number"
          >
            Offertenummer
          </SortableHeader>
        ),
        accessor: "QuotationNumber",
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column" style={{ color: "#009fe3" }}>
              <p className="mb-0">{row.original.offer_number}</p>
            </div>
          );
        },
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={setOrder}
            classNameHeader={"TypeAdressInfoHeader"}
            field="delivery_status"
          >
            Aanmaak datum
          </SortableHeader>
        ),
        accessor: "CreatedAtDate",
        Cell: ({ row }) => {
          const { formattedDate, formattedTime } = formatDateTime(
            row.original.created_at
          );
          return (
            <div className="text-center">
              <div>{formattedDate}</div>
              <div>{formattedTime}</div>
            </div>
          );
        },
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={setOrder}
            classNameHeader={"TypeAdressInfoHeader"}
            field="delivery_status"
          >
            Verloopdatum
          </SortableHeader>
        ),
        accessor: "ExpiresAtDate",
        Cell: ({ row }) => {
          const { formattedDate, formattedTime } = formatDateTime(
            row.original.expires_at
          );
          return (
            <div className="text-center">
              <div>{formattedDate}</div>
              <div>{formattedTime}</div>
            </div>
          );
        },
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={setOrder}
            classNameHeader={"TypeAdressInfoHeader"}
            field="delivery_status"
          >
            Status
          </SortableHeader>
        ),
        accessor: "status",
        Cell: ({ row }) => {
          return (
            <div className="text-start">
              <GetStatus Status={row.original.offer_status} />
            </div>
          );
        },
      },
      {
        Header: () => "",
        accessor: "Delete",
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column">
              <img
                className="trashImg"
                onClick={() => handleDeleteClick(row.original)}
                src={Trash}
              />
            </div>
          );
        },
      },
    ],
    [order.orderBy, order.direction, selectedIds, setSelectedIds, data]
  );

  // Table instance
  const tableInstance = useTable({ columns, data: data });

  return (
    <div className="QuotationOverviewTab h-100 mt-4">
      <QuotationModal
        openQuotation={showQuotationModal}
        closeQuotation={closeModalQuotation}
        rowToDelete={rowToDelete}
        onDeleteConfirmed={handleDeleteConfirmed}
      />
      <div className="mb-3 componentMarginTop2_5 tablesCustomerEdit  h-100">
        <TablePreset
          data={[
            {
              selectedIds: selectedIds,
              tableInstance: tableInstance,
              pagination: pagination,
              primary_key: "order_id",
            },
          ]}
          setPagination={setPagination}
          handleSelection={handleSelection}
          searchBar={[
            {
              shown: true,
              value: searchBar,
              function: setSearchBar,
              bordered: true,
            },
          ]}
          // leftButtons={[
          //   {
          //     title: "Filteren",
          //     function: () => console.log("a"),
          //     color: "LightGray",
          //   },
          // ]} // leftButtons={[{title: "a", function: () => console.log('a')}, {title: "b", function: () => console.log('b')}]}
          rightButtons={[
            { title: "Nieuwe offerte", function: () => navigate(-1) },
          ]} // rightButtons={[{title: "c", function: () => console.log('c')}]}
        />
      </div>
    </div>
  );
};

export default Quotation;
