import React, { useEffect, useRef, useState } from "react";
import PageTemplate from "../../../Templates/PageTemplate";
import { useNavigate } from "react-router";
import Cookies from 'universal-cookie';
import middleware from "../../../Api/Middleware";
import { BackButton } from "../../../HouseStyle/Components/Buttons";
import { BorderedTextInput } from "../../../HouseStyle/Components/Inputs";
import { BorderedDropDown } from "../../../HouseStyle/Components/DropDowns";
import TextEditor from "../../../Main/TextEditor";
import { RegularCheckBox } from "../../../HouseStyle/Components/Checkboxes";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import ToastError from "../../../Toasts/ToastError";
import ToastInfo from "../../../Toasts/ToastInfo";

const GeneralInfo = () => {
    const [loading, setLoading] = useState(true);
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [companyInfo, setCompanyInfo] = useState({});
    const [companyInfoOld, setCompanyInfoOld] = useState({});
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [modals, setModals] = useState({
        deactivateCompanyInfo: false
    });
    const isInitialRender = useRef(true);
    const toggleModal = (modal) => setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));
    const [isDifferentInvoiceAddress, setIsDifferentInvoiceAddress] = useState(false);

    // Maak een pending state voor de invoer
    const [pendingCompanyInfo, setPendingCompanyInfo] = useState(companyInfo);
    useEffect(() => {
        setPendingCompanyInfo(companyInfo);
    }, [companyInfo]);

    const fetchCompanyInfo = async () => {
        const settings = await middleware.get("/settings/backoffice?setting_id=1");
        setCompanyInfo(settings.data.data);
        setCompanyInfoOld(settings.data.data);
        setLoading(false);
    };

    useEffect(() => {
        fetchCompanyInfo();
    }, []);

    useEffect(() => {
        // Vergelijk de nieuwe companyInfo met de oude. Als ze gelijk zijn, doe niets.
        if (JSON.stringify(companyInfo) === JSON.stringify(companyInfoOld)) return;
        
        UpdateCompanyInfo();
        // Werk companyInfoOld bij zodat volgende wijzigingen wel herkend worden.
        setCompanyInfoOld(companyInfo);
    }, [companyInfo]);

    useEffect(() => {
        if (companyInfo.diffBillAddress === 0) {
            setPendingCompanyInfo((prev) => ({
                ...prev,
                invoice_postalcode: prev.postal_code,
                invoice_house_number: prev.house_number,
                invoice_addition: prev.addition,
                invoice_streetname: prev.street_name,
                invoice_city: prev.city,
            }));
        }
    }, [companyInfo.diffBillAddress, companyInfo.postal_code, companyInfo.house_number, companyInfo.addition, companyInfo.street_name, companyInfo.city]);
    
    const UpdateCompanyInfo = async () => {
        try {
          await middleware.put("/settings/backoffice", {
            setting_id: 1,
            B2B_B2C: companyInfo.B2B_B2C,
            addition: companyInfo.addition,
            city: companyInfo.city,
            cocnumber: companyInfo.cocnumber,
            company_name: companyInfo.company_name,
            country: companyInfo.country,
            email: companyInfo.email,
            house_number: companyInfo.house_number,
            diff_bill_address: companyInfo.diff_bill_address,
            invoice_addition: companyInfo.invoice_addition,
            invoice_postalcode: companyInfo.invoice_postalcode,
            invoice_city: companyInfo.invoice_city,
            invoice_house_number: companyInfo.invoice_house_number,
            invoice_streetname: companyInfo.invoice_streetname,
            phone_number: companyInfo.phone_number,
            postal_code: companyInfo.postal_code,
            street_name: companyInfo.street_name,
            vatnumber: companyInfo.vatnumber,
            website: companyInfo.website,
          });
          ToastSuccess('Company information updated successfully');
        } catch (error) {
          console.error('Error updating company information:', error);
        }
    };

    const checkAndFetchAddress = (postalCode, houseNumber, setAddressFields) => {
        if (companyInfo.country === 'Nederland') {
          const postalCodeRegex = /^\s*[0-9]{4}\s*[A-Za-z]{2}\s*$/;

          console.log(postalCode);
            console.log(houseNumber);
    
          // Clear the previous debounce timer if it exists
          if (debounceTimer) {
            clearTimeout(debounceTimer);
          }
    
          if (postalCode) {
            // Set a new debounce timer
            const newDebounceTimer = setTimeout(() => {
              // Check if Postalcode matches the regex
              if (postalCodeRegex.test(postalCode)) {
                const fetchAddress = async () => {
                  if (houseNumber) {
                    try {
                      const response = await fetch(`https://gratispostcodeapi.nl/api/v1/lookup/${postalCode}/${houseNumber}`);
                      const data = await response.json();
    
                      // If the API returns valid postal_code data, populate formData
                      if (data && data.postal_code) {
                        setAddressFields({
                          street: data.street,
                          city: data.city,
                          addition: data.available_suffixes.length > 0 ? data.available_suffixes[0] : '',
                        });
                      } else {
                        ToastError('Geen adres gevonden voor deze postcode.');
                      }
                    } catch (error) {
                        console.log(error);
                        
                      ToastError('Er is een fout opgetreden bij het ophalen van de adresgegevens.');
                    }
                  } else {
                    ToastInfo('Voer het huisnummer in om automatisch de adres en stad in te vullen.');
                  }
                };
    
                fetchAddress(); // Call the function to fetch the house number
              } else {
                ToastError('Ongeldig postcodeformaat. Zorg ervoor dat je 4 cijfers en 2 letters invoert.');
              }
            }, 1000); // 1 second debounce
    
            setDebounceTimer(newDebounceTimer); // Set the new timer
          }
    
          return () => clearTimeout(debounceTimer); // Cleanup timer on unmount or when dependencies change
        }
      };
    
      useEffect(() => {
        if (isInitialRender.current) {
          isInitialRender.current = false;
          return;
        }
        checkAndFetchAddress(companyInfo.postal_code, companyInfo.house_number, (fields) => {
          if (
            companyInfo.street_name !== fields.street ||
            companyInfo.city !== fields.city ||
            companyInfo.addition !== fields.addition
          ) {
            setCompanyInfo((prev) => ({
              ...prev,
              street_name: fields.street,
              city: fields.city,
              addition: fields.addition,
            }));
          }
        });
      }, [companyInfo.postal_code, companyInfo.house_number]);
    
      useEffect(() => {
        if (isInitialRender.current) {
          isInitialRender.current = false;
          return;
        }
        checkAndFetchAddress(companyInfo.invoice_postal_code, companyInfo.invoice_house_number, (fields) => {
          if (
            companyInfo.invoice_streetname !== fields.street ||
            companyInfo.invoice_city !== fields.city ||
            companyInfo.invoice_addition !== fields.addition
          ) {
            setCompanyInfo((prev) => ({
              ...prev,
              invoice_streetname: fields.street,
              invoice_city: fields.city,
              invoice_addition: fields.addition,
            }));
          }
        });
      }, [companyInfo.invoice_postal_code, companyInfo.invoice_house_number]);
    

    return (
        <PageTemplate pageIsLoading={loading} navbarTitle={"Bedrijsinformatie"}>
            <div className="col-12 d-flex flex-column">
                <div className="d-flex mb-4 mt-4">
                    <BackButton size="l" text={"Terug"} onClickFunction={() => navigate("/algemene/instellingen/bedrijfsinformatie")} />
                    <div className="d-flex flex-column align-items-center">
                        {companyInfo.public === 0 ? <p className="mb-1 p-0">Gepubliceerd</p> : <p className="mb-1 p-0">Gearchiveerd</p>}
                        <label className="switchActiveProduct">
                            <input type="checkbox" checked={companyInfo.public}
                                onChange={(e) => toggleModal("deactivateCompanyInfo")} />
                            <span className="sliderActiveProduct round"></span>
                        </label>
                    </div>
                </div>
                <div className="whiteBox px-5 py-4">
                    <h2 className="fw-semibold">Bedrijfsinformatie aanpassen</h2>
                    <h5 className="fw-bold pt-3">Jouw bedrijf</h5>
                    <div className="row">
                        <div className="col-4">
                            <h6 className="fw-bold ms-4 py-2 pt-4">Bedrijfsnaam *</h6>
                            <BorderedTextInput 
                                placeholder="e.g. 0000"
                                size="l"
                                inputValue={pendingCompanyInfo.company_name || ""}
                                setInputValue={(value) =>
                                    setPendingCompanyInfo((prev) => ({ ...prev, company_name: value }))
                                }
                                updateFunction={() =>
                                    setCompanyInfo((prev) => ({ ...prev, company_name: pendingCompanyInfo.company_name }))
                                }
                                updateWaitTimer={1000}
                            />
                        </div>
                        <div className="col-2">
                            <h6 className="fw-bold ms-4 py-2 pt-4">KvK nummer</h6>
                            <BorderedTextInput 
                                placeholder="e.g. 0000"
                                size="l"
                                inputValue={pendingCompanyInfo.cocnumber || ""}
                                setInputValue={(value) =>
                                    setPendingCompanyInfo((prev) => ({ ...prev, cocnumber: value }))
                                }
                                updateFunction={() =>
                                    setCompanyInfo((prev) => ({ ...prev, cocnumber: pendingCompanyInfo.cocnumber }))
                                }
                                updateWaitTimer={1000}
                            />
                        </div>
                        <div className="col-2 d-flex align-items-end">
                            <BorderedDropDown 
                                placeholder="e.g. Nederland" 
                                selectedValue={companyInfo.country || ""} 
                                setSelectedValue={(e) => setCompanyInfo((prev) => ({ ...prev, country: e }))}
                                options={[
                                    { title: "Nederland", value: "Nederland" }, 
                                    { title: "Duitsland", value: "Duitsland" }
                                ]} 
                            />
                        </div>
                        <div className="col-3">
                        </div>
                        <div className="col-2">
                            <h6 className="fw-bold ms-4 py-2 pt-4">E-mailadres</h6>
                            <BorderedTextInput 
                                placeholder="email"
                                size="l"
                                inputValue={pendingCompanyInfo.email || ""}
                                setInputValue={(value) =>
                                    setPendingCompanyInfo((prev) => ({ ...prev, email: value }))
                                }
                                updateFunction={() =>
                                    setCompanyInfo((prev) => ({ ...prev, email: pendingCompanyInfo.email }))
                                }
                                updateWaitTimer={1000}
                            />
                        </div>
                        <div className="col-2">
                            <h6 className="fw-bold ms-4 py-2 pt-4">Telefoon</h6>
                            <BorderedTextInput 
                                placeholder="e.g. 0000"
                                size="l"
                                inputValue={pendingCompanyInfo.phone_number || ""}
                                setInputValue={(value) =>
                                    setPendingCompanyInfo((prev) => ({ ...prev, phone_number: value }))
                                }
                                updateFunction={() =>
                                    setCompanyInfo((prev) => ({ ...prev, phone_number: pendingCompanyInfo.phone_number }))
                                }
                                updateWaitTimer={1000}
                            />
                        </div>
                        <div className="col-2">
                            <h6 className="fw-bold ms-4 py-2 pt-4">Website</h6>
                            <BorderedTextInput 
                                placeholder="e.g. 0000"
                                size="l"
                                inputValue={pendingCompanyInfo.website || ""}
                                setInputValue={(value) =>
                                    setPendingCompanyInfo((prev) => ({ ...prev, website: value }))
                                }
                                updateFunction={() =>
                                    setCompanyInfo((prev) => ({ ...prev, website: pendingCompanyInfo.website }))
                                }
                                updateWaitTimer={1000}
                            />
                        </div>
                    </div>
                </div>
                <div className="whiteBox px-5 py-4 mt-3">
                    <div className="row">
                        <div className="col-5"></div>
                        <div className="col-7 pb-4">
                            <RegularCheckBox 
                                value={pendingCompanyInfo.diff_bill_address === 1 ? true : false} 
                                setValue={(value) => setCompanyInfo((prev) => ({ ...prev, diff_bill_address: value === true ? 1 : 0 }))} 
                                label={"Afwijkend factuuradres"} 
                            />
                        </div>
                        <div className="col-4 row">
                            <h5 className="fw-bold">Hoofdadres</h5>
                            <div className="col-4">
                                <h6 className="fw-bold ms-4 py-2 pt-4">Postcode</h6>
                                <BorderedTextInput 
                                    placeholder="e.g. 0000"
                                    inputValue={pendingCompanyInfo.postal_code || ""}
                                    setInputValue={(value) =>
                                        setPendingCompanyInfo((prev) => ({ ...prev, postal_code: value }))
                                    }
                                    updateFunction={() =>
                                        setCompanyInfo((prev) => ({ ...prev, postal_code: pendingCompanyInfo.postal_code }))
                                    }
                                    updateWaitTimer={1000}
                                />
                            </div>
                            <div className="col-4">
                                <h6 className="fw-bold ms-4 py-2 pt-4">Huisnummer</h6>
                                <BorderedTextInput 
                                    placeholder="e.g. 0000"
                                    inputValue={pendingCompanyInfo.house_number || ""}
                                    setInputValue={(value) =>
                                        setPendingCompanyInfo((prev) => ({ ...prev, house_number: value }))
                                    }
                                    updateFunction={() =>
                                        setCompanyInfo((prev) => ({ ...prev, house_number: pendingCompanyInfo.house_number }))
                                    }
                                    updateWaitTimer={1000}
                                />
                            </div>
                            <div className="col-4">
                                <h6 className="fw-bold ms-4 py-2 pt-4">Toevoeging</h6>
                                <BorderedTextInput 
                                    size="regular"
                                    placeholder="e.g. 0000"
                                    inputValue={pendingCompanyInfo.addition || ""}
                                    setInputValue={(value) =>
                                        setPendingCompanyInfo((prev) => ({ ...prev, addition: value }))
                                    }
                                    updateFunction={() =>
                                        setCompanyInfo((prev) => ({ ...prev, addition: pendingCompanyInfo.addition }))
                                    }
                                    updateWaitTimer={1000}
                                />
                            </div>
                            <div className="col-12">
                                <h6 className="fw-bold ms-4 py-2 pt-4">Adres</h6>
                                <BorderedTextInput 
                                    size="regular"
                                    placeholder="e.g. 0000"
                                    inputValue={pendingCompanyInfo.street_name || ""}
                                    setInputValue={(value) =>
                                        setPendingCompanyInfo((prev) => ({ ...prev, street_name: value }))
                                    }
                                    updateFunction={() =>
                                        setCompanyInfo((prev) => ({ ...prev, street_name: pendingCompanyInfo.street_name }))
                                    }
                                    updateWaitTimer={1000}
                                />
                                <h6 className="fw-bold ms-4 py-2 pt-4">Stad</h6>
                                <BorderedTextInput 
                                    size="regular"
                                    placeholder="e.g. 0000"
                                    inputValue={pendingCompanyInfo.city || ""}
                                    setInputValue={(value) =>
                                        setPendingCompanyInfo((prev) => ({ ...prev, city: value }))
                                    }
                                    updateFunction={() =>
                                        setCompanyInfo((prev) => ({ ...prev, city: pendingCompanyInfo.city }))
                                    }
                                    updateWaitTimer={1000}
                                />
                            </div>
                        </div>
                        <div className="col-1"/>
                        {companyInfo.diff_bill_address === 1 && (
                            <div className="col-4 row">
                                <h5 className="fw-bold">Factuuradres</h5>
                                <div className="col-4">
                                    <h6 className="fw-bold ms-4 py-2 pt-4">Postcode</h6>
                                    <BorderedTextInput 
                                        size="regular"
                                        placeholder="e.g. 0000"
                                        inputValue={pendingCompanyInfo.invoice_postalcode || ""}
                                        setInputValue={(value) =>
                                            setPendingCompanyInfo((prev) => ({ ...prev, invoice_postalcode: value }))
                                        }
                                        updateFunction={() =>
                                            setCompanyInfo((prev) => ({ ...prev, invoice_postalcode: pendingCompanyInfo.invoice_postalcode }))
                                        }
                                        updateWaitTimer={1000}
                                        disabled={companyInfo.diffBillAddress === 0}
                                    />
                                </div>
                                <div className="col-4">
                                    <h6 className="fw-bold ms-4 py-2 pt-4">Huisnummer</h6>
                                    <BorderedTextInput 
                                        size="regular"
                                        placeholder="e.g. 0000"
                                        inputValue={pendingCompanyInfo.invoice_house_number || ""}
                                        setInputValue={(value) =>
                                            setPendingCompanyInfo((prev) => ({ ...prev, invoice_house_number: value }))
                                        }
                                        updateFunction={() =>
                                            setCompanyInfo((prev) => ({ ...prev, invoice_house_number: pendingCompanyInfo.invoice_house_number }))
                                        }
                                        updateWaitTimer={1000}
                                        disabled={companyInfo.diffBillAddress === 0}
                                    />
                                </div>
                                <div className="col-4">
                                    <h6 className="fw-bold ms-4 py-2 pt-4">Toevoeging</h6>
                                    <BorderedTextInput 
                                        size="regular"
                                        placeholder="e.g. 0000"
                                        inputValue={pendingCompanyInfo.invoice_addition || ""}
                                        setInputValue={(value) =>
                                            setPendingCompanyInfo((prev) => ({ ...prev, invoice_addition: value }))
                                        }
                                        updateFunction={() =>
                                            setCompanyInfo((prev) => ({ ...prev, invoice_addition: pendingCompanyInfo.invoice_addition }))
                                        }
                                        updateWaitTimer={1000}
                                        disabled={companyInfo.diffBillAddress === 0}
                                    />
                                </div>
                                <div className="col-12">
                                    <h6 className="fw-bold ms-4 py-2 pt-4">Adres</h6>
                                    <BorderedTextInput 
                                        size="regular"
                                        placeholder="e.g. 0000"
                                        inputValue={pendingCompanyInfo.invoice_streetname || ""}
                                        setInputValue={(value) =>
                                            setPendingCompanyInfo((prev) => ({ ...prev, invoice_streetname: value }))
                                        }
                                        updateFunction={() =>
                                            setCompanyInfo((prev) => ({ ...prev, invoice_streetname: pendingCompanyInfo.invoice_streetname }))
                                        }
                                        updateWaitTimer={1000}
                                        disabled={companyInfo.diffBillAddress === 0}
                                    />
                                    <h6 className="fw-bold ms-4 py-2 pt-4">Stad</h6>
                                    <BorderedTextInput 
                                        size="regular"
                                        placeholder="e.g. 0000"
                                        inputValue={pendingCompanyInfo.invoice_city || ""}
                                        setInputValue={(value) =>
                                            setPendingCompanyInfo((prev) => ({ ...prev, invoice_city: value }))
                                        }
                                        updateFunction={() =>
                                            setCompanyInfo((prev) => ({ ...prev, invoice_city: pendingCompanyInfo.invoice_city }))
                                        }
                                        updateWaitTimer={1000}
                                        disabled={companyInfo.diffBillAddress === 0}
                                    />
                                </div>
                            </div> 
                        )}
                    </div>
                </div>
                <div className="whiteBox px-5 py-4 mt-3">
                    <div className="row">
                        <div className="col-7">
                            Bij het instellen van uw UnifiedSMB omgeving kunt u kiezen tussen B2C (Business-to-Consumer) en B2B
                            (Business-to-Business). Deze keuze bepaalt hoe het systeem omgaat met BTW-berekeningen, afhankelijk van uw
                            bedrijfsmodel:
                            <br /><br />
                            <span className="fw-bold">B2C:</span> Het systeem werkt met prijzen inclusief BTW op basis van het BTW-tarief van het land waarin
                            uw bedrijf gevestigd is. Ideaal voor bedrijven die direct aan consumenten verkopen.
                            <br /><br />
                            <span className="fw-bold">B2B:</span> Het systeem werkt met prijzen exclusief BTW en geeft u de mogelijkheid om BTW-gegevens
                            handmatig in te voeren. Perfect voor zakelijke transacties en verkoop aan bedrijven.
                            <br /><br />
                            Kies de optie die het beste aansluit bij uw bedrijfsactiviteiten, zodat het systeem optimaal afgestemd is
                            op uw behoeften.
                        </div>
                        <div className="col d-flex justify-content-center align-items-center">
                            <div className="d-flex flex-column">
                                <h6 className="fw-bold text-center">Selecteer de hoofddoelgroep</h6>
                                <BorderedDropDown 
                                    size="s" 
                                    selectedValue={companyInfo.b2b_b2c} 
                                    setSelectedValue={(e) => setCompanyInfo((prev) => ({ ...prev, b2b_b2c: e }))} 
                                    options={[
                                        { title: "B2C", value: 1 }, 
                                        { title: "B2B", value: 0, disabled: true }
                                    ]} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
};

export default GeneralInfo;
