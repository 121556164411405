import React, { useState, useCallback, useRef, useEffect } from 'react';
import { debounce, isEmpty, isUndefined } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonBlue, ButtonDarkBlue } from '../../HouseStyle/Components/Buttons';
import TDSTextNavigation from "../../Templates/TDSTextNavigation";
import middleware from '../../Api/Middleware';
import AddCustomerModal from '../../Customer/AddCustomers/AddCustomerModal';
import AddCompanyModal from '../../Customer/AddCustomers/AddCompanyModal';
import { faSearch, faPencilAlt, faX, faCross, faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import MailIcon from '../Icons/mail.png'
import PhoneIcon from '../Icons/telephone.png'
import TruckIcon from '../Icons/delivery-truck.png'
import PaperIcon from '../Icons/paper-white.png'
import { BorderedDropDown } from '../../HouseStyle/Components/DropDowns';
import ToastSuccess from '../../Toasts/ToastSuccess';
import AddAddressModal from './AddAddressModal';
import AddEmailModal from './AddEmailModal';
import AddPhoneModal from './AddPhoneModal';
const IconSquare = ({ icon }) => (
  <div
    className="d-flex align-items-center justify-content-center"
    style={{
      backgroundColor: '#009FE3',
      width: '36px',
      height: '36px',
      borderRadius: '8px',
      minWidth: '36px'
    }}
  >
    <FontAwesomeIcon icon={icon} className="text-white" />
  </div>
);

const CustomerComponent = ({
  mode = 'add',
  editable = true,
  customer,
  emails = [],
  phoneNumbers = [],
  addresses = [],
  setAddresses,
  setPhones,
  setEmails,
  order,
  countries,
  onSelectCustomer,
  setOrder,
  loading
}) => {
  const navigate = useNavigate();
  const [searchResponse, setSearchResponse] = useState([]);
  const [isSearching, setIsSearching] = useState(mode === 'add' && !customer);
  const [searchTerm, setSearchTerm] = useState('');
  const [customers, setCustomers] = useState({ businesses: [], consumers: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [editingCustomerInformation, setEditingCustomerInformation] = useState(false);
  const dropdownRef = useRef(null);
  const [editingCustomer, setEditingCustomer] = useState(false);
  const [editingDelivery, setEditingDelivery] = useState(false);
  const [editingInvoice, setEditingInvoice] = useState(false);
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingPhone, setEditingPhone] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  
  const [selectedInvoiceAddress, setSelectedInvoiceAddress] = useState('');
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState('');
    
  const [selectedNewInvoiceAddress, setSelectedNewInvoiceAddress] = useState(null);
  const [selectedNewDeliveryAddress, setSelectedNewDeliveryAddress] = useState(null);

  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedPhone, setSelectedPhone] = useState('');
  const [showNewAddressModal, setShowNewAddressModal] = useState(false);
  const [showNewEmailModal, setShowNewEmailModal] = useState(false);
  const [showNewPhoneModal, setShowNewPhoneModal] = useState(false);
  const [typeToAdd, setTypeToAdd] = useState(0);
  const addressTitles = {
    0: "Persoonlijk verzendadres",
    1: "Persoonlijk factuuradres",
    2: "Zakelijk verzendadres",
    3: "Zakelijk factuuradres",
    4: "Persoonlijk verzend- en factuuradres",
    5: "Zakelijk verzend- en factuuradres",
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (!order?.phone_number_id) {

      setSelectedPhone((order?.phone_numbers ?? phoneNumbers)[0]);
    } else {
      setSelectedPhone((order?.phone_numbers ?? phoneNumbers).find(e => e.phone_number_id == order.phone_number_id));
    }
    if (!order?.email_address_id) {
      setSelectedEmail((order?.emails ?? emails).find(e => e.type == 1) ?? (order?.emails ?? emails).find(e => e.type == 0));
    } else {
      setSelectedEmail((order?.emails ?? emails).find(e => e.email_id == order.email_address_id));
    }
    if (!order?.invoice_address_id) {
      setSelectedInvoiceAddress((order?.client_addresses ?? addresses).find(e => [1, 3, 4, 5].includes(e.address_kind)) ?? addresses[0] ?? {});
    } else {
      setSelectedInvoiceAddress((order?.client_addresses ?? addresses).find(e => e.address_id == order.invoice_address_id))
    }
    if (!order?.delivery_address_id) {
      setSelectedDeliveryAddress((order?.client_addresses ?? addresses).find(e => [0, 2, 4, 5].includes(e.address_kind)) ?? addresses[0] ?? {});
    } else {
      setSelectedDeliveryAddress((order?.client_addresses ?? addresses).find(e => e.address_id == order.delivery_address_id))
    }

  }, [customer])
  const setEditing = (t) => {
    if(t == "cancel") {
      setEditingCustomer(false);
      setEditingDelivery(false);
      setEditingInvoice(false);
      setEditingEmail(false);
      setEditingPhone(false);
    } else if('save') {
      setEditingCustomer(!editingCustomer);
      setEditingDelivery(!editingCustomer);
      setEditingInvoice(!editingCustomer);
      setEditingEmail(!editingCustomer);
      setEditingPhone(!editingCustomer);
      setSelectedNewDeliveryAddress(selectedDeliveryAddress)
      setSelectedNewInvoiceAddress(selectedInvoiceAddress)
    }

    if (editingCustomer && t != "cancel") {
      saveOrder();
    }
  }


  const debouncedSearch = useCallback(
    debounce(async (term) => {
      if (!term) {
        setCustomers({ businesses: [], consumers: [] });
        setIsDropdownOpen(false);
        return;
      }

      setIsLoading(true);
      try {


        const [searchResponse] = await Promise.all([
          middleware.get(`sp/orderCustomerSearch?page=1&page_size=5&searchTerm=${term}&noSearch=["internal_id","created_at","updated_at"]`)
        ]);



        setSearchResponse(searchResponse.data.data || []);
        setIsDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching customers:", error);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  const handleCustomerSelect = (selectedCustomer) => {
    setSelectedDeliveryAddress('');
    setSelectedInvoiceAddress('');
    if (onSelectCustomer) {

      setSelectedDeliveryAddress(addresses.find(e => [0, 2, 4, 5].includes(e.address_kind)))
      setSelectedInvoiceAddress(addresses.find(e => [1, 3, 4, 5].includes(e.address_kind)))
      onSelectCustomer(selectedCustomer);
    }

    setIsSearching(false);
    setSearchTerm('');
    setEditingCustomerInformation(false);
    setIsDropdownOpen(false);
  };

  const closeModalCustomer = () => setShowCustomerModal(false)
  const closeModalCompany = () => setShowCompanyModal(false)
  const saveOrder = async () => {
    if (order) {
      const orderRes = await middleware.put(`/orders?order_id=${order.order_id}`, { invoice_address_id: selectedInvoiceAddress?.address_id ?? null, delivery_address_id: selectedDeliveryAddress?.address_id ?? null, email_address_id: selectedEmail?.email_id ?? null, phone_number_id: selectedPhone?.phone_number_id ?? null });
    }

    setOrder({ ...order, invoice_address_id: selectedInvoiceAddress?.address_id ?? null, delivery_address_id: selectedDeliveryAddress?.address_id, email_address_id: selectedEmail?.email_id ?? null, phone_number_id: selectedPhone?.phone_number_id ?? null });
    ToastSuccess("Bestelling aangepast.");
  }
  const renderSearchView = () => (
    <div>
      {!isEmpty(customer) && editable ? <FontAwesomeIcon style={{ position: "absolute", right: "35px" }} onClick={(e) => { setEditingCustomerInformation(false) }} icon={faX} /> : ""}
      <h5 className="mb-4 fw-bold">Klant</h5>

      {mode === 'add' && (
        <div className="d-flex gap-2 mb-4">
          <ButtonDarkBlue onClickFunction={() => setShowCustomerModal(true)} text={"Nieuwe consument"} />
          <ButtonBlue text={"Nieuw bedrijf"} onClickFunction={() => setShowCompanyModal(true)} />
        </div>
      )}

      <div className="mb-3">
        <p className="mb-2">Klantnaam</p>
        <div className="position-relative" ref={dropdownRef}>
          <div className="position-relative">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Zoek klant op naam, e-mail etc."
              className="form-control rounded-pill"
              autoComplete="off"
              data-bitwarden-watching="false"
              style={{
                padding: '10px 40px 10px 20px',
                border: '1px solid #E5E7EB'
              }}
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
            />
          </div>

          {isDropdownOpen && (searchResponse.length > 0 || isLoading) && (
            <div
              className="position-absolute w-100 bg-white border rounded mt-1 shadow-lg overflow-hidden"
              style={{
                zIndex: 1050,
                maxHeight: '300px',
                overflowY: 'auto'
              }}
            >
              {isLoading ? (
                <div className="text-center p-3">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                searchResponse.map((customer, index) => (
                  <button
                    key={customer.internal_id}
                    className="w-100 text-start px-3 py-2 border-0 bg-transparent"
                    onClick={() => handleCustomerSelect(customer)}
                    style={{
                      borderBottom: index < searchResponse.length - 1 ? '1px solid #E5E7EB' : 'none',
                      transition: 'background-color 0.2s'
                    }}
                    onMouseEnter={e => e.currentTarget.style.backgroundColor = '#F3F4F6'}
                    onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
                  >
                    <div className="fw-medium">{customer.customer_name}</div>
                    <div className="text-muted small">
                      {customer.email || customer.invoicemail || 'Geen email'}
                    </div>
                  </button>
                ))
              )}
            </div>
          )}
        </div>
      </div>
      <AddCustomerModal show={showCustomerModal} close={closeModalCustomer} reloadOnSubmit={false} />
      <AddCompanyModal show={showCompanyModal} close={closeModalCompany} reloadOnSubmit={false} />
    </div>
  );


  const renderCustomerInfo = () => {
    if (!customer || !customer.internal_id || editingCustomerInformation) return renderSearchView();
    const customerName = customer.customer_name || customer.company_name ||
      (customer.first_name && customer.last_name ?
        `${customer.first_name} ${customer.last_name}` : "Geen naam");

    return (
      <div className='whiteBox p-2 d-flex flex-column h-100'>
        {editingCustomer && <FontAwesomeIcon style={{ position: "absolute", right: "95px" }} onClick={(e) => { setEditing('cancel') }} icon={faX} />}
        <FontAwesomeIcon style={{ position: "absolute", right: "65px" }} onClick={(e) => { setEditing('save') }} icon={editingCustomer ? faCheck : faPencilAlt} />
        {editable && <FontAwesomeIcon style={{ position: "absolute", right: "35px" }} onClick={(e) => { setEditingCustomerInformation(true) }} icon={faSearch} />}

        <h5 className='fw-bold mb-4 BlockTitle'>
          Klant informatie
        </h5>

        {order?.sales_channel ? (
          <h6 className="pb-2 fw-semibold">
            Bestelling aangemaakt via: {order.sales_channel.sales_channel_name}
          </h6>
        ) : (
          ""
        )}

        <h5 className='linkColor pb-2' role='button' onClick={() => navigate(`/klanten/bewerken/${customer.internal_id}`)} >
          {customerName}
        </h5>
        {selectedEmail &&
          <div className='d-flex w-100 flex-column pb-3'>
            <div className='d-flex align-items-center'>
              <div className='verwerkIcon d-flex align-items-center justify-content-center rounded-2' style={{ width: '30px', height: '30px' }}>
                <img className='' style={{ width: "20px" }} src={MailIcon} />
              </div>
              {!editingEmail &&
                <h6 className='ms-3 fw-semibold align-middle m-0'>{selectedEmail?.email}</h6>
              }
              {editingEmail &&
                <div className='d-flex align-items-center justify-content-between ps-3'>
                  <BorderedDropDown selectedValue={selectedEmail.email_id} placeholder='Selecteer email'
                    options={(order?.client_emails ?? emails).map((e) => { return { value: e.email_id, title: e.email, onClick: () => setSelectedEmail(e) } }
                    )} />
                  <FontAwesomeIcon className='ms-2' icon={faPlus} onClick={() => setTypeToAdd(0) + setShowNewEmailModal(true)} />
                </div>}
            </div>
          </div>
        }

        {!editingPhone && selectedPhone &&
          <div className='d-flex w-100 flex-column pb-3'>
            <div className='d-flex align-items-center'>
              <div className='verwerkIcon d-flex align-items-center justify-content-center rounded-2' style={{ width: '30px', height: '30px' }}>
                <img className='' width={20} src={PhoneIcon} />
              </div>
              <h6 className='ms-3 fw-semibold align-middle m-0'>{"+" + selectedPhone.country_phone_prefix + " " + selectedPhone.phone_number}</h6>
              {editingPhone &&
                <div className='d-flex align-items-center justify-content-between ps-3'>
                  <BorderedDropDown selectedValue={selectedPhone?.phone_number_id} placeholder='Selecteer telefoonnnummer'
                    options={(order?.client_phonenumbers ?? phoneNumbers).map((e) => { return { value: e.phone_number_id, title: "+" + e.country_phone_prefix + " " + e.phone_number, onClick: () => setSelectedPhone(e) } }
                    )} />
                  <FontAwesomeIcon className='ms-2' icon={faPlus} onClick={() => setTypeToAdd(0) + setShowNewPhoneModal(true)} />
                </div>}
            </div>
          </div>
        }
        {editingPhone &&
          <div className='d-flex w-100 flex-column pb-3'>
            <div className='d-flex align-items-center'>
              <div className='verwerkIcon d-flex align-items-center justify-content-center rounded-2' style={{ width: '30px', height: '30px' }}>
                <img className='' width={20} src={PhoneIcon} />
              </div>
              <div className='d-flex align-items-center justify-content-between ps-3'>
                <BorderedDropDown selectedValue={selectedPhone?.phone_number_id} placeholder='Selecteer telefoonnummer'
                  options={(order?.client_phonenumbers ?? phoneNumbers).map((e) => { return { value: e.phone_number_id, title: "+" + e.country_phone_prefix + " " + e.phone_number, onClick: () => setSelectedPhone(e) } }
                  )} />
                <FontAwesomeIcon className='ms-2' icon={faPlus} onClick={() => setTypeToAdd(0) + setShowNewPhoneModal(true)} />
              </div>
            </div>
          </div>
        }

        <div className='d-flex w-100 flex-column '>
          {!editingInvoice && selectedInvoiceAddress && selectedInvoiceAddress.streetname &&
            <div className='d-flex align-items-start pb-2'>
              <div className='align-self-start verwerkIcon mb-3 d-flex align-items-center justify-content-center rounded-2' style={{ width: '30px', height: '30px' }}>
                <img width={25} src={TruckIcon} />
              </div>
              <div className='ms-3'>
                <p className='pt-1 mb-1 fw-semibold'>Factuuradres</p>
                {selectedInvoiceAddress && selectedInvoiceAddress.streetname && !editingInvoice &&
                  <div className='p-0'>
                    <p className='mb-0'>{(selectedNewInvoiceAddress?.streetname ?? selectedInvoiceAddress.streetname) + " " + (selectedNewInvoiceAddress?.housenumber ?? selectedInvoiceAddress.housenumber)}</p>
                    <p className='mb-0'>{(selectedNewInvoiceAddress?.postalcode ?? selectedInvoiceAddress.postalcode) + " " + (selectedNewInvoiceAddress?.city ?? selectedInvoiceAddress.city)}, {selectedNewInvoiceAddress?.country?.country ?? selectedInvoiceAddress?.country?.country}</p>
                  </div>
                }
              </div>
            </div>}

          {editingInvoice &&
            <div className='d-flex align-items-start pb-2'>
              <div className='align-self-start verwerkIcon mb-3 d-flex align-items-center justify-content-center rounded-2' style={{ width: '30px', height: '30px' }}>
                <img width={25} src={TruckIcon} />
              </div>
              <div className='ms-3'>
                <p className='pt-1 mb-1 fw-semibold'>Factuuradres</p>
                <div className='d-flex align-items-center justify-content-between pb-2'>
                  <BorderedDropDown selectedValue={selectedInvoiceAddress?.address_id} placeholder='Selecteer adres'
                    options={(order?.client_addresses ?? addresses).map((e) => { return { value: e.address_id, title: e.streetname + " " + e.housenumber + " " + e.city, onClick: () => setSelectedInvoiceAddress(e) } }
                    )} />
                  <FontAwesomeIcon className='ms-2' icon={faPlus} onClick={() => setTypeToAdd(0) + setShowNewAddressModal(true)} />
                </div>
              </div>
            </div>}

          {!editingDelivery && selectedDeliveryAddress && selectedDeliveryAddress.streetname &&
            <div className='d-flex align-items-start pb-2'>
              <div className='align-self-start verwerkIcon mb-3 d-flex align-items-center justify-content-center rounded-2' style={{ width: '30px', height: '30px' }}>
                <img width={25} src={TruckIcon} />
              </div>

              <div className='ms-3'>
                <p className='pt-1 mb-1 fw-semibold'>Verzendadres</p>
                <div className='p-0'>
                  <p className='mb-0'>{selectedDeliveryAddress.streetname + " " + selectedDeliveryAddress.housenumber}</p>
                  <p className='mb-0'>{selectedDeliveryAddress.postalcode + " " + selectedDeliveryAddress.city}, {selectedDeliveryAddress?.country?.country}</p>
                </div>
              </div>
            </div>}

          {editingDelivery &&
            <div className='d-flex align-items-start pb-2'>
              <div className='align-self-start verwerkIcon mb-3 d-flex align-items-center justify-content-center rounded-2' style={{ width: '30px', height: '30px' }}>
                <img width={25} src={TruckIcon} />
              </div>

              <div className='ms-3'>
                <p className='pt-1 mb-1 fw-semibold'>Verzendadres</p>
                <div className='d-flex align-items-center justify-content-between'>
                  <BorderedDropDown selectedValue={selectedDeliveryAddress?.address_id} placeholder='Selecteer adres'
                    options={(order?.client_addresses ?? addresses).map((e) => { return { value: e.address_id, title: e.streetname + " " + e.housenumber + " " + e.city, onClick: () => setSelectedDeliveryAddress(e) } }
                    )} />
                  <FontAwesomeIcon className='ms-2' icon={faPlus} onClick={() => setTypeToAdd(1) + setShowNewAddressModal(true)} />
                </div>
              </div>
            </div>}
        </div>
      </div>
    );
  };

  const shouldShowSearch = mode === 'add' && (!customer || !customer.internal_id) || isSearching;

  return (
    <div className="whiteBox p-4">
      <AddPhoneModal setPhones={setPhones} phones={phoneNumbers} setOrder={setOrder} order={order} customer={customer} setAddPhoneModalVisible={setShowNewPhoneModal} setSelectedPhone={setSelectedPhone} addPhoneModalVisible={showNewPhoneModal} />
      <AddEmailModal setEmails={setEmails} emails={emails} setOrder={setOrder} order={order} customer={customer} setAddEmailModalVisible={setShowNewEmailModal} setSelectedEmail={setSelectedEmail} addEmailModalVisible={showNewEmailModal} />
      <AddAddressModal setAddresses={setAddresses} addresses={addresses} setOrder={setOrder} order={order} customer={customer} setAddAddressModalVisible={setShowNewAddressModal} setSelectedDeliveryAddress={setSelectedDeliveryAddress} setSelectedInvoiceAddress={setSelectedInvoiceAddress} address_type={0} addAddressModalVisible={showNewAddressModal} />
      {shouldShowSearch ? renderSearchView() : renderCustomerInfo()}
    </div>
  );
};

export default CustomerComponent;