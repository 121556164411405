import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill1 } from "@fortawesome/free-solid-svg-icons";
import './OrderComponents.css';
import placeholder from '../Icons/empty-placeholder.jpg';
import { ButtonBlue, ButtonLightGrey } from "../../HouseStyle/Components/Buttons";
import middleware from "../../Api/Middleware";

const CrediteringComponent = ({ productsToProcess = [], order, prices, showPrice, setPaybackModalVisible, setPaybackAmount }) => {
  const {
    product_amount,
    subtotal_incl_tax,
    total_incl_tax,
    total_excl_tax,
    sale_amount,
  } = prices;
  function getDate(amount) {
    const today = new Date();
    const months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
    const month = months[today.getMonth()];
    const year = today.getFullYear();
    const date = today.getDate() - amount;
    return `${date} ${month} ${year}`;
  }
  const strip = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }

  const credit = async (amount) => {
    setPaybackAmount(amount);
    setPaybackModalVisible(true);
  }
  return order.creditations.map(e => (
    <div className="whiteBox p-5 pt-4 mb-4 align-center">
      <div className="d-flex justify-content-between align-items-center pb-3 creditering-header">
        <h5 className="mb-0 fw-bold BlockTitle">Crediteren: #{order.order_number}</h5>
        <div className="d-flex flex-column ms-auto text-end">
          <a href={`https://calendar.google.com/calendar/u/0/r?date=${getDate(0).replace(/ /g, '+')}`} target="_blank" rel="noopener noreferrer" className="new-link-color fs-6">
            Gecrediteerd: {new Date(e.created_at).toLocaleDateString('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' })}
          </a>
          {/* <a href={`https://calendar.google.com/calendar/u/0/r?date=${getDate(-1).replace(/ /g, '+')}`} target="_blank" rel="noopener noreferrer" className="new-link-color fs-6">
            Terugbetaald op: {getDate(-1)}
          </a> */}
        </div>
      </div>
      <div className="row g-0">
        {e.creditation_products.length > 0 ? (
          <ul className='list-group list-group-flush'>
            {e.creditation_products.map((item, index) => {
              return (
                <li key={index} className='verwerk-list-group-item bottom-border-line d-flex justify-content-between align-items-center gap-3 pb-2 pt-2'>
                  <div className='d-flex flex-row gap-3 flex-grow-1'>
                    <div className='d-flex align-items-center'>
                      {/* Product image */}
                      <img src={item?.product?.image?.path ? middleware.defaults.baseURL + "/public/storage/" + item?.product?.image?.path : placeholder} className="rounded-circle p-2 me-3" height={100} width={100} alt="placeholder..." />

                      {/* Product info */}
                      <div className='d-flex flex-column justify-content-center align-items-start'>
                        <a href={`/producten/bewerken/${item?.product?.product_id}`} className='new-link-color'>{item?.product?.product_name}</a>
                        {item?.product?.product_description &&
                          <p className='text-muted small mb-0'>{strip(item?.product?.product_description)?.length < 100 ? strip(item?.product?.product_description) : (strip(item?.product?.product_description)?.slice(0, 100) ?? '') + "..."}</p>
                        }
                      </div>
                    </div>
                  </div>

                  {/* Product price info */}
                  <div className='d-flex align-items-center justify-content-end gap-3 gap-xxl-5 flex-grow-1'>
                    <span className='text-nowrap'>x{item.amount}</span>
                    <span className='fw-semibold'>€{parseFloat(item.restitution).toFixed(2)}</span>
                  </div>
                </li>
              )
            })}
          </ul>
        ) : (
          <div className="text-center p-3">
            <div className="text-center" role="status">
              <h5>There are no product to process...</h5>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end gap-3 pt-4">
        <ButtonLightGrey text={"Print creditnota"} />
        <ButtonBlue text={"Terugbetalen"} onClickFunction={() => credit(e.restitution_total)} />
      </div>
    </div>
  ))
};
export default React.memo(CrediteringComponent);