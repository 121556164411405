import React, { useEffect, useState } from "react";
import gearIcon from "../../../../Main/Icons/setting.png";
import PriceSettingsModal from "../../../modals/PriceSettingsModal";
import { BorderedMoneyInput } from "../../../../HouseStyle/Components/Inputs";

const PriceBox = (props) => {
  const [priceModalVisible, setPriceModalVisible] = useState(false);
  const [inclVAT, setInclVAT] = useState(1);
  const [priceChanged, setPriceChanged] = useState(false);

  useEffect(() => {
    // call margin change once when the page loads to do the calculations if its in the edit page
    props.edit && handleMarginChange();
  }, []);

  useEffect(() => {
    // update margin every time prices change
    handleMarginChange();
  }, [props.prices]);

  useEffect(() => {
    if (props.prices != null && props.prices.length) {
      let updatedPrices;
      // Check for buy in price
      // set the prices correct arcording to the tax change
      if (inclVAT == "1") {
        updatedPrices = props.prices.map((item) => ({
          ...item,
          excl_tax:
            item.type !== "buy_in_price"
              ? item.incl_tax
                ? (
                    item.incl_tax.replace(",", ".") /
                    (1 + props.selectedTax / 100)
                  ).toFixed(2)
                : ""
              : item.excl_tax,
        }));
      } else if (inclVAT == "0") {
        updatedPrices = props.prices.map((item) => ({
          ...item,
          incl_tax: item.excl_tax
            ? typeof item.excl_tax === "string"
              ? (
                  item.excl_tax.replace(",", ".") *
                  (1 + props.selectedTax / 100)
                ).toFixed(2)
              : item.excl_tax
            : "",
        }));
      } else {
        updatedPrices = props.prices;
      }
      // don't update the product and prices if there are no changes
      let isPriceNotSame = false;
      for (let i = 0; i < props.prices.length; i++) {
        if (
          props.prices[i].incl_tax != updatedPrices[i].incl_tax ||
          props.prices[i].excl_tax != updatedPrices[i].excl_tax
        ) {
          isPriceNotSame = true;
        }
      }
      if (isPriceNotSame || !props.edit) {
        props.setPrices(updatedPrices);
        props.edit && props.setUpdatedTax(true);
      }
    }
  }, [props.taxClass]);

  const roundToTwoDecimals = (event) => {
    if (
      (inclVAT == "1" || inclVAT == 1) &&
      event.target.name !== "buy_in_price"
    ) {
      props.setPrices((prevPrices) =>
        prevPrices.map(
          (price) =>
            price.type === event.target.name
              ? {
                  ...price,
                  incl_tax:
                    typeof price.incl_tax === "string"
                      ? Number(price.incl_tax.replace(",", ".")).toFixed(2)
                      : Number(price.incl_tax).toFixed(2),
                } // update only the matching type
              : price // return the other prices unchanged
        )
      );
    } else {
      props.setPrices((prevPrices) =>
        prevPrices.map(
          (price) =>
            price.type === event.target.name
              ? {
                  ...price,
                  excl_tax:
                    typeof price.excl_tax === "string"
                      ? Number(price.excl_tax.replace(",", ".")).toFixed(2)
                      : Number(price.excl_tax).toFixed(2),
                } // update only the matching type
              : price // return the other prices unchanged
        )
      );
    }
    if (!props.edit) {
      // add pages
      // props.handleChange();
    } else {
      // edit
      priceChanged && props.setUpdatedTax(true);
      setPriceChanged(false);
    }
  };

  const handleMarginChange = () => {
    // check for invalid values before setting the price margin
    const invalidValues = [null, 0, "", " ", "0", "0.00"];
    const isValid = (price) => !invalidValues.includes(price);

    // Marge price = sale price or normal price minus buyinprice
    if (props.prices != null && props.prices.length != 0) {
      let price;
      let buyInPrice =
        props.prices.find((item) => item.type === "buy_in_price")?.excl_tax ??
        null;
      if (
        isValid(
          props.prices.find((item) => item.type === "sale_price")?.excl_tax
        )
      ) {
        // Check if its an string or numeric
        if (
          isNaN(
            props.prices.find((item) => item.type === "sale_price")?.excl_tax ??
              null
          )
        ) {
          price = Number(
            props.prices
              .find((item) => item.type === "sale_price")
              .excl_tax.replace(",", ".")
          );
        } else {
          price =
            props.prices.find((item) => item.type === "sale_price")?.excl_tax ??
            null;
        }
      } else if (
        isValid(props.prices.find((item) => item.type === "price")?.excl_tax)
      ) {
        if (
          isNaN(
            props.prices.find((item) => item.type === "price")?.excl_tax ?? null
          )
        ) {
          price = Number(
            props.prices
              .find((item) => item.type === "price")
              .excl_tax.replace(",", ".")
          );
        } else {
          price = props.prices.find((item) => item.type === "price").excl_tax;
        }
      }
      if (isValid(buyInPrice) && isValid(price)) {
        props.setPriceMargin((price - buyInPrice).toFixed(2));
      } else {
        props.setPriceMargin(null);
      }
    }
  };

  const handlePriceValueChange = (value, name) => {
    value = value.replace(",", ".");
    let priceType;
    let inclTax;
    let exclTax;
    let oldPrice;
    console.log("value", value);
    console.log("name", name);

    if (inclVAT == "1") {
      priceType = name;
      inclTax = value;
      exclTax = inclTax
        ? (inclTax.replace(",", ".") / (1 + props.selectedTax / 100)).toFixed(2)
        : "";
      // set old price to compare to the new price
      oldPrice =
        props.prices.find((price) => price.type === priceType)?.incl_tax ??
        null;
    } else {
      priceType = name;
      exclTax = value;
      inclTax = exclTax
        ? (
            parseFloat(exclTax.replace(",", ".")) *
            (1 + props.selectedTax / 100)
          ).toFixed(2)
        : "";
      // set old price to compare to the new price
      oldPrice =
        props.prices.find((price) => price.type === priceType)?.excl_tax ??
        null;
    }
    //check if old price is not the same as new price for unnesesary updates
    if (oldPrice !== value) {
      setPriceChanged(true);
    }
    // set prices
    props.setPrices((prevPrices) => {
      let newprices = [...prevPrices];
      let index = newprices.findIndex((e) => e.type == priceType);
      if (index == -1) {
        newprices.push({
          incl_tax: inclTax,
          excl_tax: exclTax,
          type: priceType,
        });
      } else {
        newprices[index].incl_tax = inclTax;
        newprices[index].excl_tax = exclTax;
      }
      return newprices;
    });
  };

  const handleBuyInPriceValueChange = (value, name) => {
    value = value.replace(",", "."); // Replaces , with a . so the marge calculation updates immediately
    let oldPrice =
      props.prices.find((price) => price.type === name)?.excl_tax ?? null;
    //check if old price is not the same as new price for unnesesary updates
    if (oldPrice !== value) {
      setPriceChanged(true);
    }
    let buyInPriceExists = false;
    const updatedPrices = props.prices.map((price) => {
      if (price.type === name) {
        buyInPriceExists = true;
        return {
          ...price,
          incl_tax: value
            ? (
                parseFloat(value.replace(",", ".")) *
                (1 + props.selectedTax / 100)
              ).toFixed(2)
            : "",
          excl_tax: value,
        };
      }
      return price; // No change if the type doesn't match
    });
    // if buyinprice doesn't exists create it
    if (!buyInPriceExists) {
      updatedPrices.push({
        type: name,
        incl_tax: value
          ? (
              parseFloat(value.replace(",", ".")) *
              (1 + props.selectedTax / 100)
            ).toFixed(2)
          : "",
        excl_tax: value,
      });
    }
    props.setPrices(updatedPrices);
  };
  const handleTaxChange = (event) => {
    
    setInclVAT(event.target.value);
    if (!props.edit) {
      // Set prices
      const newPrice = props.prices.reduce((acc, curr) => {
        acc.push({
          price_id: curr.price_id,
          type: curr.type,
          incl_tax:
            typeof curr.incl_tax === "string"
              ? Number(curr.incl_tax.replace(",", ".")).toFixed(2)
              : Number(curr.incl_tax).toFixed(2),
          excl_tax:
            typeof curr.excl_tax === "string"
              ? Number(curr.excl_tax.replace(",", ".")).toFixed(2)
              : Number(curr.excl_tax).toFixed(2),
        });
        return acc;
      }, []);
      props.setPrices(newPrice);
    }
  };

  return (
    <>
      <PriceSettingsModal
        setPriceModalVisible={setPriceModalVisible}
        priceModalVisible={priceModalVisible}
        taxClass={props.taxClass}
        setTaxClass={props.setTaxClass}
        setSelectedTax={props.setSelectedTax}
      />
      <div
        className={
          !props.edit ? "whiteBox p-5 h-100" : "whiteBox p-5 h-100 ms-xxl-4"
        }
      >
        <div className="d-flex justify-content-between mb-4">
          <h4 className="fw-bold m-0">Prijzen</h4>
          <div className="d-flex flex-row align-items-center">
            <select
              className="smallStandardInput standardDropdownArrow me-2 pe-4"
              onChange={(e) => handleTaxChange(e)}
              value={inclVAT}
            >
              <option value={0}>Prijzen excl. BTW</option>
              <option value={1}>Prijzen incl. BTW</option>
            </select>

            <button
              className="rounded-pill px-2 text-secondary gearButtonPrices d-flex align-items-center h-100"
              type="button"
            >
              <img
                src={gearIcon}
                onClick={() => {
                  setPriceModalVisible(true);
                }}
                height="17px"
                width="17px"
              />
            </button>
          </div>
        </div>
        {/* bovenste rij prijzen box */}
        <div className="">
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between standardSaleParent">
              <div className="d-flex flex-column me-4">
                <label className="fw-medium mb-2">Standaard prijs</label>

                <BorderedMoneyInput
                  size="m"
                  restrictMinus={true}
                  decimalsLimit={2}
                  inputValue={
                    props.prices
                      ? inclVAT == "1"
                        ? props.prices.find((item) => item.type === "price")
                            ?.incl_tax ?? null
                        : props.prices.find((item) => item.type === "price")
                            ?.excl_tax ?? null
                      : null
                  }
                  setInputValue={(val) => handlePriceValueChange(val, "price")}
                  updateFunction={props.edit ? props.handleChange : undefined}
                  onBlur={(e) => roundToTwoDecimals(e)}
                />

                {props.prices && inclVAT == "1" ? (
                  <p className="bottomText fw-medium">
                    €{" "}
                    {parseFloat(
                      props.prices.find((item) => item.type === "price")
                        ?.excl_tax ?? 0
                    ).toFixed(2)}{" "}
                    Excl. BTW
                  </p>
                ) : (
                  <p className="bottomText fw-medium">
                    €{" "}
                    {parseFloat(
                      props.prices.find((item) => item.type === "price")
                        ?.incl_tax ?? 0
                    ).toFixed(2)}{" "}
                    Incl. BTW
                  </p>
                )}
              </div>
              <div className="d-flex flex-column me-2">
                <label className="fw-medium mb-2">Kortingsprijs</label>
                <BorderedMoneyInput
                  size="m"
                  restrictMinus={true}
                  minAmount={0}
                  decimalsLimit={2}
                  inputValue={
                    props.prices
                      ? inclVAT == "1"
                        ? props.prices.find(
                            (item) => item.type === "sale_price"
                          )?.incl_tax ?? null
                        : props.prices.find(
                            (item) => item.type === "sale_price"
                          )?.excl_tax ?? null
                      : null
                  }
                  setInputValue={(val) =>
                    handlePriceValueChange(val, "sale_price")
                  }
                  updateFunction={props.edit ? props.handleChange : undefined}
                  onBlur={(e) => roundToTwoDecimals(e)}
                />

                {props.prices && inclVAT == "1" ? (
                  <p className="bottomText fw-medium">
                    €{" "}
                    {parseFloat(
                      props.prices.find((item) => item.type === "sale_price")
                        ?.excl_tax ?? 0
                    ).toFixed(2)}{" "}
                    Excl. BTW
                  </p>
                ) : (
                  <p className="bottomText fw-medium">
                    €{" "}
                    {parseFloat(
                      props.prices.find((item) => item.type === "sale_price")
                        ?.incl_tax ?? 0
                    ).toFixed(2)}{" "}
                    Incl. BTW
                  </p>
                )}
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between standardSaleParent">
              <div className="d-flex flex-column me-4">
                <label className="fw-medium mb-2">Inkoopprijs excl. BTW</label>
                <BorderedMoneyInput
                  size="m"
                  restrictMinus={true}
                  minAmount={0}
                  decimalsLimit={2}
                  inputValue={
                    props.prices.find((item) => item.type === "buy_in_price")
                      ?.excl_tax
                  }
                  setInputValue={(val) =>
                    handleBuyInPriceValueChange(val, "buy_in_price")
                  }
                  updateWaitTimer="0"
                  updateFunction={props.edit ? props.handleChange : undefined}
                  // onBlur={(e) => roundToTwoDecimals(e)}
                />
              </div>
              <div className="d-flex flex-column heightSalePrice me-2">
                <label className="fw-medium mb-2">Marge excl. BTW</label>
                <BorderedMoneyInput
                  size="m"
                  restrictMinus={true}
                  minAmount={0}
                  decimalsLimit={2}
                  inputValue={
                    props.priceMargin != null && !isNaN(props.priceMargin)
                      ? props.priceMargin
                      : null
                  }
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceBox;
