import { faCamera, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import Pen from "./IconsAccordion/Pen.svg";
import Xmark from "./IconsAccordion/xmark.svg";
import '../../Main/Main.css';
import { useNavigate } from 'react-router';
import CheckboxCell from '../../Table/TableComponents/CheckboxCell';
import noimage from '../noimage.jpg'
import subCategoryIcon from '../../Main/Icons/90angle.png';

const AccordionCategory = ({ title, content, statusCategory, active, setActive, data, openCategory, handleSelection, selectedIds, layer }) => {
  const [isActive, setIsActive] = useState(active.find((e) => e == data.original.category_id) ?? false);
  const navigate = useNavigate();
  const panelRef = useRef(null);

  const toggleAccordion = () => {
    if (content !== "") {
      setIsActive(prevState => !prevState);
    }
  };

  useEffect(() => {
    const panel = panelRef.current;
    if (panel) {
      if (isActive) {
      } else {
        requestAnimationFrame(() => {
          panel.style.height = "0px"; // Then set it to 0 to trigger the transition
        });
      }

      const handleTransitionEnd = () => {
        if (isActive) {
          panel.style.height = "auto";
        }
      };

      panel.addEventListener('transitionend', handleTransitionEnd);
      return () => {
        panel.removeEventListener('transitionend', handleTransitionEnd);
      };
    }
  }, [isActive]);

  return (
    <div className='containerAccordion'>

      <div
        className={`d-flex flex-row ${content != "" ? 'cursorPointer' : ''}`}
        style={{ cursor: content !== "" ? 'pointer' : 'default' }}
      >
        <div className='d-flex align-items-center pe-5'>
          <CheckboxCell row={data} handleSelection={handleSelection} selectedIds={selectedIds} pk={'primary_key'} />
        </div>
        <div  className={'d-flex position-relative justify-content-between w-100 py-2  ' + (content ? '' : 'hoverPointer')} onClick={(e) => content && toggleAccordion(e) + setActive(data.original.category_id, !isActive)}>
          <div className='d-flex flex-row align-items-center position-relative' style={{left: Math.max(0, (layer - 1) * 30) + "px"}} >
            {/* {data.original.image ?
              <img src={data.original.image || noimage} width={50} height={50} className='me-2' /> :
              <FontAwesomeIcon icon={faCamera} color="#e2eefb" size="3x" className="align-self-center me-2" />
            } */}

            <button onClick={() => openCategory(data.original.category_id)} className={`fitContent text-info accordion ps-0 fs-5 ${isActive ? 'active' : ''}`}>
              {data.original.head_category_id && <img src={subCategoryIcon} width='18px' height='18px' className='me-2 bluefiltered' />} {title}
            </button>
            <span className={`arrow fitcontentHeight ms-2 d-flex align-self-center ${!content ? "d-none" : ""} ${isActive ? 'active' : ''}`}>
              <FontAwesomeIcon color='#0dcaf0' icon={faCaretDown} />
            </span>
          </div>
          <div className='d-flex align-items-center'>
            <div style={{ color: data.original.status ? "#A9D974" : "#ffcc9d" }}>{statusCategory}</div>
          </div>
        </div>
      </div>
      <div className={`panel position-relative px-0 ${(isActive && content !== "") ? "h-auto active" : ""}`} ref={panelRef}>
        <div className={`panel-content px-0`}>
          {typeof content === 'string' ? <span className='mb-0'>{content}</span> : content}
        </div>
      </div>
    </div>
  );
};

export default AccordionCategory;
