import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
import "./Navbar.css";
import ProfilePic from "./nopfp.png";
import Bell from "./IconsNavbar/Bell.svg";
import Mail from "./IconsNavbar/Message.svg";
import SearchIcon from "../../Customer/AddCustomers/Icons/Search.svg";
import Help from "./IconsNavbar/Help.svg";
import gear from "./IconsNavbar/gear.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faArrowRightFromBracket,
  faChevronDown,
  faUsers,
  faUsersGear,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircleQuestion,
  faEnvelope,
  faAddressCard,
} from "@fortawesome/free-regular-svg-icons";
import middleware from "../../Api/Middleware";
import Backbutton from "./BackIcon.svg";

const Navbar = ({ navInfo, title, callbackFuncIsLoading, sidebarsmall, hideBackButton, backValue }) => {
  
  const cookies = new Cookies();
  const navigate = useNavigate();

  const dropdownContentRef = useRef(null);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [containerWidth, setContainerWidth] = useState("auto");
  const lineRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const logout = () => {
    const token = cookies.get('token')
    localStorage.removeItem(`navInfo[${token}]`)
    cookies.remove("token", { path: "/" });
    navigate("/login");
  };

  useEffect(() => {
    //Get data from database
    callbackFuncIsLoading(false);
  }, []);

  useEffect(() => {
    if (dropdownContentRef.current) {
      const container = dropdownContentRef.current.previousSibling;
      setContainerWidth(`${container.offsetWidth}px`);
    }
  }, [showDropdown]);

  useEffect(() => {
    if (lineRef.current) {
      lineRef.current.style.width = "249px";
    }
  }, [containerWidth]);

  return (
    <nav
      className={`${sidebarsmall == true ? "navbarSmall navbar" : "navbar"} ${
        windowSize[0] < 1458 ? " navbarSmall" : ""
      } py-2 pe-5 ps-5 col-lg-10 d-flex flex-row flex-nowrap justify-content-between`}
    >
      <div className="d-flex flex-row justify-content-center align-items-center">
        { !hideBackButton && backValue ? <img className="back-button-img" onClick={() => navigate(backValue)} src={Backbutton} alt="Back" /> : ""}
        <h2 className="mb-0 pageTitle text-nowrap fw-bold">
          {title !== null ? title : ""}
        </h2>
      </div>
      <div className="iconHeightNav d-flex pt-1">
        <div className="iconBox me-3 d-flex flex-row">
          <div className={`d-flex flex-nowrap position-relative`}>
            <div className="d-flex align-self-center">
              <input
                type="text"
                placeholder="Zoeken..."
                name="search"
                className="searchbarNav bg-transparent border-0"
              ></input>
            </div>
            <img src={SearchIcon} alt="Zoekicon" className="searchIcon user-select-none me-5" />
          </div>
          <div className="indicatorIcons">
            <img src={Help} className="iconsNav user-select-none" />
              <img
                role="button"
                onClick={() => navigate("/algemene/instellingen")}
                src={gear}
                className="iconsNav user-select-none"

              />
            <img src={Bell} className="iconsNav user-select-none" />
          </div>
        </div>
        <div className={`dropdownNav ps-4 ${showDropdown ? "clicked" : ""}`}>
          <div
            className="userInfoMenu hover d-flex flex-row"
            onClick={toggleDropdown}
          >
            <div className="profile-container d-flex align-items-center">
              <div className="profile-picture">
              <img
                src={navInfo.profile_picture_url || ProfilePic}
                alt="Profile"
                className="profile-picture user-select-none"
              />
              </div>
              <p className="fw-bold mb-4 text-nowrap userName">
                {`Hi, ${
                  navInfo?.first_name
                    ? navInfo?.first_name +
                      " " +
                      (navInfo?.last_name ? navInfo?.last_name : "")
                    : "Gebruiker"
                }`}
              </p>
            </div>
            <FontAwesomeIcon
              className={`px-1 dropdownIndicator ${
                showDropdown ? "rotate" : ""
              }`}
              icon={faCaretDown}
            />
          </div>
          <div className="hover-area" onClick={toggleDropdown}></div>
          <div
            className="dropdown-content"
            style={{
              display: showDropdown ? "flex" : "none",
              width: "249px",
            }}
            ref={dropdownContentRef}
          >
          <div className="menuListedItems w-100" role="menuitem">
            <div className="navbarDropDownItem" onClick={() => navigate("/profiel")}>
              <FontAwesomeIcon icon={faAddressCard} className="me-2"/>
              Mijn profiel
            </div>
            {navInfo?.role == 4 &&
              <div className="navbarDropDownItem" onClick={() => navigate("/algemene/instellingen/gebruikers")}>
                <FontAwesomeIcon icon={faUsersGear} className="me-2"/>
                Gebruikers Beheren
              </div>
            }
            
          </div>
            <p className="line" ref={lineRef}></p>
            <div className="logOut d-flex flex-nowrap" onClick={logout}>
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                className="logOutIcon"
              />{" "}
              <p className="text-black logOutText fw-semibold">Log out</p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
