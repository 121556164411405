import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import middleware from "../../Api/Middleware";
import { ButtonBlue, ButtonDarkBlue, ButtonLightGrey } from '../../HouseStyle/Components/Buttons';

const DeleteConfirmModal = ({ setDeleteModalVisible, deleteModalVisible, handleDelete }) => {

    return (
        <Modal
            id="modalBlueprint"
            show={deleteModalVisible}
            onHide={() => setDeleteModalVisible()}
            size="lg"
            centered
            backdrop="static"
        >
            <Modal.Header className="modalTop justify-content-between">
                <h4 className='ms-3 fw-bold'>Dit product verwijderen?</h4>
                <button variant="transparent" className=' btn' onClick={() => setDeleteModalVisible(false)}>
                    <FontAwesomeIcon icon={faXmark} className="closeModal" />
                </button>
            </Modal.Header>
            <Modal.Body className="modalCenter">
                <p className='FlatText'>Weet je zeker dat je dit product wilt verwijderen?</p>
                <div className="contendModal d-flex flex-row gap-3">
                    <ButtonDarkBlue text="Ja, ik weet het zeker" onClickFunction={() => handleDelete()} />
                    <ButtonLightGrey text="Annuleren" onClickFunction={() => setDeleteModalVisible(false)} />
                </div>
            </Modal.Body>
            <Modal.Footer className="modalBottom">
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteConfirmModal;