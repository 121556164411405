import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ButtonBlue } from "../../../HouseStyle/Components/Buttons";
import { BorderedTextInput } from "../../../HouseStyle/Components/Inputs";
import ToastError from "../../../Toasts/ToastError";
import middleware from "../../../Api/Middleware";

const AddNoteModal = ({
    closeAddNote,
    openAddNote,
    data,
    setData,
    setDataUpdated,
    fetchAll
}) => {
    // Variables
    const { internal_id } = useParams();
    const [showAddNote, setShowAddNote] = useState();

    // State
    useEffect(() => {
        if (openAddNote) {
            setShowAddNote(true);
        }
    }, [openAddNote]);

    // Functions
    const closeAddNoteModal = () => {
        setShowAddNote(false);
        closeAddNote(true)
    }

    const submitNote = async () => {
        if (data.note_title == "") {
            ToastError("Vul een titel in");
            return;
        }
        if (data.note == "") {
            ToastError("Vul een notitie in");
            return;
        }
        const [postNoteRes] = await Promise.all([
            middleware.post("consumers/notes", data),
        ]);
        setData({
            internal_id: internal_id,
            note_title: "",
            note: "",
        });
        setDataUpdated(true);
        setShowAddNote(false);
        closeAddNoteModal();
        fetchAll();
    };

    return (
        <Modal
            id="modalBlueprint"
            show={showAddNote}
            onHide={closeAddNoteModal}
            className="colormodals"
            size="lg"
            centered
            backdrop="static"
        >
            <Modal.Header className="modalTop">
                <div className="d-flex flex-row justify-content-between w-100">
                    <h3 className="ms-3 text-nowrap">Notitie toevoegen</h3>
                    <button className="btn" onClick={closeAddNoteModal}>
                        <FontAwesomeIcon className="closeModal" icon={faXmark} />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className="modalCenter">
                <div className="d-flex flex-column mb-3">
                    <BorderedTextInput
                        label={"Titel"}
                        size="l"
                        inputValue={data?.note_title}
                        setInputValue={(value) =>
                            setData((prev) => ({
                                ...prev,
                                note_title: value,
                            }))
                        }
                    />
                </div>
                <div className="d-flex flex-column mb-3">
                    {
                        // Textarea nog niet beschikbaar in de housestyle
                    }
                    <label className="mb-2 fw-medium">Notitie</label>
                    <textarea
                        cols={10}
                        rows={10}
                        className="standardInput p-3 "
                        style={{
                            minHeight: "150px", height: "155px", maxHeight: "50dvh"
                        }}
                        value={data?.note}
                        onChange={(e) =>
                            setData((prev) => ({ ...prev, note: e.target.value }))
                        }
                    ></textarea>
                </div>
            </Modal.Body>
            <Modal.Footer className="modalBottom">
                <div className="d-flex flex-row-reverse">
                    <ButtonBlue
                        text={"Notitie toevoegen"}
                        size="s"
                        onClickFunction={submitNote}
                    />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default AddNoteModal;