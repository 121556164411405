import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { faPencilAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import middleware from "../../Api/Middleware";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Customer.css";
import InputComponent from "./InputComponent";
import ToastSuccess from "../../Toasts/ToastSuccess";
import ToastError from "../../Toasts/ToastError";
import Plus from "./Icons/Add.svg";
import Minus from "./Icons/minus.svg";
import * as RandExp from "randexp";
import ToastInfo from "../../Toasts/ToastInfo";
import { BorderedTextInput } from "../../HouseStyle/Components/Inputs";
import { BorderedDropDown } from "../../HouseStyle/Components/DropDowns";
import { ButtonDarkBlue } from "../../HouseStyle/Components/Buttons";
import { useNavigate } from "react-router";

const AddCompanyModal = ({ show, close, reloadOnSubmit = true }) => {
  const navigate = useNavigate();
  const [debounceTimer, setDebounceTimer] = useState(null);

  const [showCompany, setShowCompany] = useState(false);
  const [countryInfo, setCountryInfo] = useState({});
  const [countryIdInfo, setCountryIdInfo] = useState([]);
  const [emails, setEmails] = useState([""]);
  const [phoneNumbers, setPhoneNumbers] = useState([
    { country_phone_prefix: "31", phone_number: "" },
  ]);
  const [countryId, setCountryId] = useState();

  const [formData, setFormData] = useState({
    newsPaper: false,
    birthday: false,
  });
  const [errorFields, setErrorFields] = useState({
    company_name: false,
    kvk_number: false,
    btw_number: false,
    first_name: false,
    last_name: false,
    emails: [false, false, false],
    phone_numbers: [false, false, false],
    postal_code: false,
    house_number: false,
    addition: false,
    street_name: false,
    city: false,
    country: false,
  });
  const [address, setAddress] = useState({});
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const vatRegexPatterns = {
    AT: /^ATU\d{8}$/, // Austria
    BE: /^BE0\d{9}$/, // Belgium
    BG: /^BG\d{9,10}$/, // Bulgaria
    CY: /^CY\d{8}[A-Z]$/, // Cyprus
    CZ: /^CZ\d{8,10}$/, // Czech Republic
    DE: /^DE\d{9}$/, // Germany
    DK: /^DK\d{8}$/, // Denmark
    EE: /^EE\d{9}$/, // Estonia
    EL: /^EL\d{9}$/, // Greece
    ES: /^ES[A-Z0-9]\d{7}[A-Z0-9]$/, // Spain
    FI: /^FI\d{8}$/, // Finland
    FR: /^FR[A-Z0-9]{2}\d{9}$/, // France
    GB: /^GB(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/, // United Kingdom
    HR: /^HR\d{11}$/, // Croatia
    HU: /^HU\d{8}$/, // Hungary
    IE: /^IE\d[A-Z0-9+*]\d{5}[A-Z]$/, // Ireland
    IT: /^IT\d{11}$/, // Italy
    LT: /^LT(\d{9}|\d{12})$/, // Lithuania
    LU: /^LU\d{8}$/, // Luxembourg
    LV: /^LV\d{11}$/, // Latvia
    MT: /^MT\d{8}$/, // Malta
    NL: /^NL\d{9}B\d{2}$/, // Netherlands
    PL: /^PL\d{10}$/, // Poland
    PT: /^PT\d{9}$/, // Portugal
    RO: /^RO\d{2,10}$/, // Romania
    SE: /^SE\d{12}$/, // Sweden
    SI: /^SI\d{8}$/, // Slovenia
    SK: /^SK\d{10}$/, // Slovakia
  };

  const normalizeLabel = (label) => {
    return label.trim().replace(/[\s\-]+/g, "");
  };

  const getValueInput = useCallback((value, label) => {
    const normalizedLabel = normalizeLabel(label);
    setFormData((prev) => ({ ...prev, [normalizedLabel]: value }));
  }, []);

  const fetchCountry = async () => {
    const [fetchCountries] = await Promise.all([
      middleware.get(`/countryCodes`),
    ]);
    const sorted = fetchCountries.data.data.sort((a, b) => b.order_by - a.order_by);
    const insertIndex = sorted.findIndex((country) => country.order_by === 0);
    setCountryInfo(sorted);
    if (insertIndex !== -1) {
      console.log(insertIndex);
      const before = sorted.slice(0, insertIndex);
      const after = sorted.slice(insertIndex);

      const newOptions = [...before, { disabled: true }, ...after];
      console.log(newOptions)
      setCountryInfo(newOptions);
    }
  };
  useEffect(() => {
    if (countryIdInfo && countryIdInfo.country_code_2) {
      setIsCountrySelected(true);
    } else {
      setIsCountrySelected(false);
    }
  }, [countryIdInfo]);
  const setPhoneCode = (index, value) => {
    const newPhoneNumbers = [...phoneNumbers];
    newPhoneNumbers[index].country_phone_prefix = value;
    setPhoneNumbers(newPhoneNumbers);
  };
  useEffect(() => {
    if (countryIdInfo.country_code_2 == "NL") {
      const { PostalCode, HouseNumber } = formData; // Destructure the relevant fields from formData
      const postalCodeRegex = /^\s*[0-9]{4}\s*[A-Za-z]{2}\s*$/;

      // Clear the previous debounce timer if it exists
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      if (PostalCode) {
        // Set a new debounce timer
        const newDebounceTimer = setTimeout(() => {
          // Check if Postalcode matches the regex
          if (postalCodeRegex.test(PostalCode)) {
            const fetchAdress = async () => {
              if (formData.HouseNumber) {
                try {
                  const response = await fetch(
                    `https://gratispostcodeapi.nl/api/v1/lookup/${PostalCode}/${HouseNumber}`
                  );
                  const data = await response.json();

                  // If the API returns valid postal_code data, populate formData
                  if (data && data.postal_code) {
                    setFormData((prevData) => ({
                      ...prevData,
                      StreetName: data.street,
                      City: data.city,
                      Addition:
                        data.available_suffixes.length > 0
                          ? data.available_suffixes[0]
                          : "",
                    }));
                    setErrorFields((prev) => {
                      return {
                        ...prev,
                        street_name: false,
                        city: false,
                      }
                    });
                  } else {
                    ToastError("Geen adres gevonden voor deze postcode.");
                  }
                } catch (error) {
                  ToastError(
                    "Er is een fout opgetreden bij het ophalen de adress gegevens"
                  );
                }
              } else {
                ToastInfo(
                  "Voer het huisnummer in om automatisch de straat en stad in te vullen"
                );
              }
            };

            fetchAdress(); // Call the function to fetch the house number
          }
        }, 1000); // 2 seconds debounce

        setDebounceTimer(newDebounceTimer); // Set the new timer
      }

      return () => clearTimeout(debounceTimer); // Cleanup timer on unmount or when dependencies change
    }
  }, [formData.PostalCode, formData.HouseNumber]);
  useEffect(() => {
    try {
      fetchCountry();
    } catch (error) {
      console.warn(error);
    }
  }, [showCompany]);
  const removeEmailField = async (id) => {
    const confirmDelete = window.confirm(
      "Weet je zeker dat je deze e-mail wilt verwijderen?"
    );
    if (confirmDelete) {
      // Update state after successful deletion

      setEmails(emails.filter((e, index) => index != id));
    }
  };
  const removePhoneNumberField = async (id) => {
    const confirmDelete = window.confirm(
      "Weet je zeker dat je dit telefoonnummer wilt verwijderen?"
    );
    if (confirmDelete) {
      // Update state after successful deletion
      setPhoneNumbers(phoneNumbers.filter((e, index) => index != id));
    }
  };

  const validateFormData = () => {
    let selectedCountryCode = "NL";
    if (countryIdInfo.country_code_2 != undefined) {
      selectedCountryCode = countryIdInfo.country_code_2;
    }
    const vatRegex = vatRegexPatterns[selectedCountryCode];
    const postalCodeRegex = /^\s*[0-9]{4}\s*[A-Za-z]{2}\s*$/;
    const phoneRegex = /^\d+$/;
    const cocRegex = /^\d{1,12}(\/\d{1,12})*$/;
    let errors = [];
    let unfilled_fields = [];

    // Checken voor algemene errors
    if (
      formData.CocNumber &&
      (!cocRegex.test(formData.CocNumber))
    ) {
      errors.push("Mag niet meer dan 12 characters en niet minder dan 8 characters bij KVK nummer hebben");
      setErrorFields((prev) => {
        return {
          ...prev,
          kvk_number: true
        }
      });
    }


    setEmails(prev => prev.map(email => email.trim()));

    const formEmails = emails.map(email => email.trim());

    // Checken voor algemene errors
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formEmails.length > 0) {
      for (let index = 0; index < formEmails.length; index++) {
        if (formEmails[index] == "") { // Haal lege velden weg
          formEmails.splice(index, 1);
          index--;
          continue;
        }
        if (formEmails != 0) {
          if (!emailPattern.test(formEmails[index])) {
            errors.push("Email formaat is ongeldig");
            setErrorFields((prev) => {
              let oldEmails = [...prev.emails];
              oldEmails[index] = true;
              return {
                ...prev,
                emails: oldEmails
              }
            });
          }
        }
      }
    }

    if (phoneNumbers.length > 0) {
      for (let index = 0; index < phoneNumbers.length; index++) {
        if (phoneNumbers[index].phone_number == "") { // Haal lege velden weg
          phoneNumbers.splice(index, 1);
          index--;
          continue;
        }
        if (phoneNumbers != 0) {
          if (!phoneRegex.test(phoneNumbers[index].phone_number)) {
            errors.push("Telefoonnummer formaat is ongeldig");
            setErrorFields((prev) => {
              let updatedPhone = [...prev.phone_numbers];
              updatedPhone[index] = true;
              return {
                ...prev,
                phone_numbers: updatedPhone
              }
            });
          }
        }
      }
    }

    if (formData.PostalCode && postalCodeRegex && !postalCodeRegex.test(formData.PostalCode)) {
      errors.push("Postcode formaat is ongeldig");
      setErrorFields((prev) => {
        return {
          ...prev,
          postal_code: true
        }
      })
    }

    if (formData.VatNumber && vatRegex && !vatRegex.test(formData.VatNumber)) {
      errors.push(`Ongeldig BTW-nummer formaat voor ${countryIdInfo.country}.`);
      setErrorFields((prev) => {
        return {
          ...prev,
          btw_number: true
        }
      });
    }

    const errorPush = (field, userText) => {
      console.log(field)
      unfilled_fields.push(userText);
      setErrorFields((prev) => {
        return {
          ...prev,
          [field]: true
        }
      });
    }

    // Lege velden checken
    if (countryIdInfo?.country_code_id == undefined) errorPush("country", "land");
    if (!formData.CompanyName) errorPush("company_name", "bedrijfsnaam");
    if (!formData.CocNumber) errorPush("kvk_number", "KVK nummer");
    if (!formData.VatNumber) errorPush("btw_number", "BTW nummer");
    if (!formData.FirstName) errorPush("first_name", "voornaam");
    if (!formData.LastName) errorPush("last_name", "achternaam");
    if (!formData.PostalCode) errorPush("postal_code", "postcode");
    if (!formData.HouseNumber) errorPush("house_number", "huisnummer");
    if (!formData.StreetName) errorPush("street_name", "straat");
    if (!formData.City) errorPush("city", "stad");
    if (unfilled_fields.length > 0) {
      errors.push("De volgende velden zijn veplicht: " + unfilled_fields.join(", "));
    }
    return errors;
  }

  const handleSubmit = async (e) => {
    e.preventDefault(); // Voorkom de standaardactie van het formulier
    e.stopPropagation();

    const error_messages = validateFormData();
    if (error_messages.length > 0) {
      for (let i = 0; i < error_messages.length; i++) {
        ToastError(error_messages[i]);
      }
      return;
    }

    let userEmails;
    let userPhone;
    userEmails = emails;
    userPhone = phoneNumbers.map((number) => ({
      ...number,
      fullnumber: "+" + number.country_phone_prefix + "-" + number.phone_number,
    }));
    if (userEmails.length == 1 && userEmails[0] === "") {
      userEmails = [];
    }
    if (userPhone.length == 1 && userPhone[0].phone_number == undefined) {
      userPhone = [];
    }

    storeBusiness(userEmails, userPhone);

  };

  const storeBusiness = async (userEmails, userPhone) => {
    let businessCreated = false; // Houd bij of het bedrijf succesvol is aangemaakt
    let businessId = null; // Sla het ID van het aangemaakte bedrijf op voor mogelijke compensatieactie
    let newphones = phoneNumbers.map((number) => ({
      ...number,
      fullnumber: "+" + number.country_phone_prefix + "-" + number.phone_number,
    }));
    try {
      const response = await middleware.post("/addBusinesses", {
        company_name: formData.CompanyName,
        cocnumber: formData.CocNumber,
        vatnumber: formData.VatNumber,
        first_name: formData.FirstName,
        last_name: formData.LastName,
        country_code_id: countryIdInfo.country_code_id || null,
        emails: userEmails,
        phone_numbers: userPhone,
        city: formData.City,
        streetname: formData.StreetName,
        birthday: formData.BirthDate,
        housenumber: formData.HouseNumber,
        addition: formData.Addition === "" ? null : formData.Addition,
        postalcode: formData.PostalCode,
        newsletter: formData.newsPaper == false ? 0 : 1,
        birthday_promotion: formData.birthday_promotion == false ? 0 : 1,
        address_kind: 5,
      });

      ToastSuccess("Bedrijf is aangemaakt");
      if (reloadOnSubmit) {
        // console.log(response.data["Successfully added data"][1][1].internal_id)
        navigate(`/klanten/bewerken/${response.data["Successfully added data"][1][1].internal_id}`);
      } else {
        closeCompanyModal();
      }
    } catch (err) {
      ToastError("Er is iets fout gegaan bij het aanmaken van een bedrijf.");
      console.error(err);
    }

  }

  useEffect(() => {
    if (show) {
      setShowCompany(true);
    }
  }, [show]);

  const clearFormFields = () => {
    setFormData({});
    setErrorFields({
      company_name: false,
      kvk_number: false,
      btw_number: false,
      first_name: false,
      last_name: false,
      emails: [false, false, false],
      phone_numbers: [false, false, false],
      postal_code: false,
      house_number: false,
      addition: false,
      street_name: false,
      city: false,
      country: false,
    });
    setPhoneNumbers([{ country_phone_prefix: 31, phone_number: "" }]);
    setEmails([""]);
  };

  const closeCompanyModal = (event) => {
    event.preventDefault();
    setShowCompany(false);
    clearFormFields();
    close(true);
  };

  const addEmailField = () => {
    if (emails.length < 3) {
      setEmails([...emails, ""]);
    }
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;


    setEmails(newEmails);
  };

  const addPhoneNumberField = () => {
    if (phoneNumbers.length < 3) {
      setPhoneNumbers([...phoneNumbers, { country_phone_prefix: "31", phone_number: "" }]);
    }
  };

  const handlePhoneNumberChange = (index, value) => {
    const newPhoneNumbers = [...phoneNumbers];
    newPhoneNumbers[index].phone_number = value;
    setPhoneNumbers(newPhoneNumbers);
  };

  console.log(formData);
  return (
    <Modal
      id="modalBlueprint"
      show={showCompany}
      onHide={() => closeCompanyModal()}
      size="xl"
      centered
      backdrop="static"
    >
      <form>
        <Modal.Header className="modalTopCustomers justify-content-between">
          <div className="d-flex flex-row gap-4 flex-wrap">
            <h3 className="ms-3 text-nowrap ">Bedrijf aanmaken</h3>
            <BorderedDropDown
              placeholder="Kies een land"
              error={errorFields.country}
              selectedValue={countryIdInfo}
              setSelectedValue={(value) => {
                setCountryIdInfo(value);
                setErrorFields((prev) => {
                  return {
                    ...prev,
                    country: false
                  }
                })
              }
              }
              options={
                countryInfo && countryInfo?.length > 0
                  ? countryInfo.map((country, index) => {
                    return {
                      key: index,
                      value: country,
                      title: country.country,
                      disabled: country.disabled
                    };
                  })
                  : []
              }
            />
          </div>

          <button
            variant="transparent"
            className=" btn"
            onClick={(event) => closeCompanyModal(event)}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal text-dark" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenterCustomers pt-0">
          <div className="contendModal w-100">
            <div>
              <label className=" fw-bold mb-2 secondaryTitles pt-3">
                Bedrijfsgegevens
              </label>
              <div className="col-12 d-flex flex-row align-content-end mb-3">
                <BorderedTextInput
                  label={"Bedrijfsnaam"}
                  size="l"
                  error={errorFields.company_name}
                  inputValue={formData.CompanyName}
                  setInputValue={(value) => {
                    getValueInput(value, "CompanyName");
                    setErrorFields((prev) => {
                      return {
                        ...prev,
                        company_name: false
                      }
                    })
                  }
                  }
                />
              </div>
              <div className="col-12 d-flex flex-row gap-4 align-content-end mb-4">
                <BorderedTextInput
                  label={"KVK nummer"}
                  placeholder="Bijv. 123456789012"
                  error={errorFields.kvk_number}
                  size="l"
                  inputValue={formData.CocNumber}
                  setInputValue={(value) => {
                    getValueInput(value, "CocNumber");
                    setErrorFields((prev) => {
                      return {
                        ...prev,
                        kvk_number: false
                      }
                    })
                  }
                  }
                />
                <BorderedTextInput
                  label={"BTW nummer"}
                  error={errorFields.btw_number}
                  placeholder={"Bijv. NL12345678B90"}
                  size="l"
                  inputValue={formData.VatNumber}
                  setInputValue={(value) => {
                    getValueInput(value, "VatNumber");
                    setErrorFields((prev) => {
                      return {
                        ...prev,
                        btw_number: false
                      }
                    })
                  }
                  }
                />
              </div>
            </div>
            <div>
              <label className=" fw-bold mb-2 secondaryTitles">
                Contactpersoon
              </label>
              <div className="col-12 d-flex flex-row gap-4 align-content-end mb-3">
                <BorderedTextInput
                  label={"Voornaam"}
                  error={errorFields.first_name}
                  size="l"
                  inputValue={formData.FirstName}
                  setInputValue={(value) => {
                    getValueInput(value, "FirstName");
                    setErrorFields((prev) => {
                      return {
                        ...prev,
                        first_name: false
                      }
                    })
                  }
                  }
                />
                <BorderedTextInput
                  label={"Achternaam"}
                  size="l"
                  error={errorFields.last_name}
                  inputValue={formData.LastName}
                  setInputValue={(value) => {
                    getValueInput(value, "LastName");
                    setErrorFields((prev) => {
                      return {
                        ...prev,
                        last_name: false
                      }
                    })
                  }
                  }
                />
              </div>
              <div className="col-12 d-flex flex-row align-content-end my-3">
                <div className="d-flex flex-column gap-2 col-6 pe-3">
                  <div className="d-flex justify-content-between">
                    <label className="mb-2">Emails</label>
                    <img
                      className="pe-3 addIconSizeModals"
                      src={Plus}
                      onClick={addEmailField}
                      alt="Add"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {emails.map((email, index) => (
                    <div key={index} className="d-flex">
                      <BorderedTextInput
                        size={"l"}
                        error={errorFields.emails[index]}
                        inputValue={email}
                        setInputValue={(value) => {
                          setEmails((prevEmails) => {
                            const updatedEmails = [...prevEmails];
                            updatedEmails[index] = value;
                            return updatedEmails;
                          });
                          setErrorFields((prev) => {
                            const updatedError = [...prev.emails];
                            updatedError[index] = false;
                            return {
                              ...prev,
                              emails: updatedError
                            }
                          })
                        }
                        }
                      />
                      <img
                        className="ms-2 removeIconSizeModals"
                        src={Minus}
                        onClick={() => removeEmailField(index)}
                        alt="Remove"
                        style={{ cursor: "pointer", width: "3%" }}
                      />
                    </div>
                  ))}
                </div>
                <div className="d-flex flex-column gap-2 col-6 ps-3">
                  <div className="d-flex justify-content-between">
                    <label className="mb-2">Telefoonnummers</label>
                    <img
                      className="pe-3 addIconSizeModals"
                      src={Plus}
                      onClick={addPhoneNumberField}
                      alt="Add"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {phoneNumbers.map((phoneNumber, index) => (
                    <div key={index} className="d-flex gap-1">
                      <BorderedDropDown
                        selectedValue={phoneNumber.country_phone_prefix}
                        setSelectedValue={(value) => setPhoneCode(index, value)}
                        options={[
                          { title: "Nederland (+31)", value: "31" },
                          { title: "België (+32)", value: "32" },
                          { title: "Duitsland (+49)", value: "49" },
                          { title: "Algerije (+213)", value: "213" },
                          { title: "Andorra (+376)", value: "376" },
                          { title: "Angola (+244)", value: "244" },
                          { title: "Anguilla (+1264)", value: "1264" },
                          { title: "Antigua & Barbuda (+1268)", value: "1268" },
                          { title: "Argentinië (+54)", value: "54" },
                          { title: "Armenië (+374)", value: "374" },
                          { title: "Aruba (+297)", value: "297" },
                          { title: "Australië (+61)", value: "61" },
                          { title: "Oostenrijk (+43)", value: "43" },
                          { title: "Azerbeidzjan (+994)", value: "994" },
                          { title: "Bahama's (+1242)", value: "1242" },
                          { title: "Bahrein (+973)", value: "973" },
                          { title: "Bangladesh (+880)", value: "880" },
                          { title: "Barbados (+1246)", value: "1246" },
                          { title: "Wit-Rusland (+375)", value: "375" },
                          { title: "Belize (+501)", value: "501" },
                          { title: "Benin (+229)", value: "229" },
                          { title: "Bermuda (+1441)", value: "1441" },
                          { title: "Bhutan (+975)", value: "975" },
                          { title: "Bolivia (+591)", value: "591" },
                          {
                            title: "Bosnië en Herzegovina (+387)",
                            value: "387",
                          },
                          { title: "Botswana (+267)", value: "267" },
                          { title: "Brazilië (+55)", value: "55" },
                          { title: "Brunei (+673)", value: "673" },
                          { title: "Bulgarije (+359)", value: "359" },
                          { title: "Burkina Faso (+226)", value: "226" },
                          { title: "Burundi (+257)", value: "257" },
                          { title: "Cambodja (+855)", value: "855" },
                          { title: "Kameroen (+237)", value: "237" },
                          { title: "Canada (+1)", value: "1" },
                          { title: "Kaapverdië (+238)", value: "238" },
                          { title: "Kaaimaneilanden (+1345)", value: "1345" },
                          {
                            title: "Centraal-Afrikaanse Republiek (+236)",
                            value: "236",
                          },
                          { title: "Chili (+56)", value: "56" },
                          { title: "China (+86)", value: "86" },
                          { title: "Colombia (+57)", value: "57" },
                          { title: "Comoren (+269)", value: "269" },
                          { title: "Congo (+242)", value: "242" },
                          { title: "Cookeilanden (+682)", value: "682" },
                          { title: "Costa Rica (+506)", value: "506" },
                          { title: "Kroatië (+385)", value: "385" },
                          { title: "Cuba (+53)", value: "53" },
                          { title: "Noord-Cyprus (+90392)", value: "90392" },
                          { title: "Zuid-Cyprus (+357)", value: "357" },
                          { title: "Tsjechië (+42)", value: "42" },
                          { title: "Denemarken (+45)", value: "45" },
                          { title: "Djibouti (+253)", value: "253" },
                          { title: "Dominica (+1809)", value: "1809" },
                          {
                            title: "Dominicaanse Republiek (+1809)",
                            value: "1809",
                          },
                          { title: "Ecuador (+593)", value: "593" },
                          { title: "Egypte (+20)", value: "20" },
                          { title: "El Salvador (+503)", value: "503" },
                          { title: "Equatoriaal-Guinea (+240)", value: "240" },
                          { title: "Eritrea (+291)", value: "291" },
                          { title: "Estland (+372)", value: "372" },
                          { title: "Ethiopië (+251)", value: "251" },
                          { title: "Falklandeilanden (+500)", value: "500" },
                          { title: "Faeröer (+298)", value: "298" },
                          { title: "Fiji (+679)", value: "679" },
                          { title: "Finland (+358)", value: "358" },
                          { title: "Frankrijk (+33)", value: "33" },
                          { title: "Frans-Guyana (+594)", value: "594" },
                          { title: "Frans-Polynesië (+689)", value: "689" },
                          { title: "Gabon (+241)", value: "241" },
                          { title: "Gambia (+220)", value: "220" },
                          { title: "Georgië (+7880)", value: "7880" },
                          { title: "Ghana (+233)", value: "233" },
                          { title: "Gibraltar (+350)", value: "350" },
                          { title: "Griekenland (+30)", value: "30" },
                          { title: "Groenland (+299)", value: "299" },
                          { title: "Grenada (+1473)", value: "1473" },
                          { title: "Guadeloupe (+590)", value: "590" },
                          { title: "Guam (+671)", value: "671" },
                          { title: "Guatemala (+502)", value: "502" },
                          { title: "Guinee (+224)", value: "224" },
                          { title: "Guinee-Bissau (+245)", value: "245" },
                          { title: "Guyana (+592)", value: "592" },
                          { title: "Haïti (+509)", value: "509" },
                          { title: "Honduras (+504)", value: "504" },
                          { title: "Hongkong (+852)", value: "852" },
                          { title: "Hongarije (+36)", value: "36" },
                          { title: "IJsland (+354)", value: "354" },
                          { title: "India (+91)", value: "91" },
                          { title: "Indonesië (+62)", value: "62" },
                          { title: "Iran (+98)", value: "98" },
                          { title: "Irak (+964)", value: "964" },
                          { title: "Ierland (+353)", value: "353" },
                          { title: "Israël (+972)", value: "972" },
                          { title: "Italië (+39)", value: "39" },
                          { title: "Jamaica (+1876)", value: "1876" },
                          { title: "Japan (+81)", value: "81" },
                          { title: "Jordanië (+962)", value: "962" },
                          { title: "Kazachstan (+7)", value: "7" },
                          { title: "Kenia (+254)", value: "254" },
                          { title: "Kiribati (+686)", value: "686" },
                          { title: "Noord-Korea (+850)", value: "850" },
                          { title: "Zuid-Korea (+82)", value: "82" },
                          { title: "Koeweit (+965)", value: "965" },
                          { title: "Kirgizië (+996)", value: "996" },
                          { title: "Laos (+856)", value: "856" },
                          { title: "Letland (+371)", value: "371" },
                          { title: "Libanon (+961)", value: "961" },
                          { title: "Lesotho (+266)", value: "266" },
                          { title: "Liberia (+231)", value: "231" },
                          { title: "Libië (+218)", value: "218" },
                          { title: "Liechtenstein (+417)", value: "417" },
                          { title: "Litouwen (+370)", value: "370" },
                          { title: "Luxemburg (+352)", value: "352" },
                          { title: "Macau (+853)", value: "853" },
                          { title: "Macedonië (+389)", value: "389" },
                          { title: "Madagaskar (+261)", value: "261" },
                          { title: "Malawi (+265)", value: "265" },
                          { title: "Maleisië (+60)", value: "60" },
                          { title: "Maldiven (+960)", value: "960" },
                          { title: "Mali (+223)", value: "223" },
                          { title: "Malta (+356)", value: "356" },
                          { title: "Marshalleilanden (+692)", value: "692" },
                          { title: "Martinique (+596)", value: "596" },
                          { title: "Mauritanië (+222)", value: "222" },
                          { title: "Mayotte (+269)", value: "269" },
                          { title: "Mexico (+52)", value: "52" },
                          { title: "Micronesië (+691)", value: "691" },
                          { title: "Moldavië (+373)", value: "373" },
                          { title: "Monaco (+377)", value: "377" },
                          { title: "Mongolië (+976)", value: "976" },
                          { title: "Marokko (+212)", value: "212" },
                          { title: "Mozambique (+258)", value: "258" },
                          { title: "Myanmar (+95)", value: "95" },
                          { title: "Namibië (+264)", value: "264" },
                          { title: "Nauru (+674)", value: "674" },
                          { title: "Nepal (+977)", value: "977" },
                          { title: "Nieuw-Caledonië (+687)", value: "687" },
                          { title: "Nieuw-Zeeland (+64)", value: "64" },
                          { title: "Nicaragua (+505)", value: "505" },
                          { title: "Niger (+227)", value: "227" },
                          { title: "Nigeria (+234)", value: "234" },
                          { title: "Niue (+683)", value: "683" },
                          { title: "Norfolk (+672)", value: "672" },
                          {
                            title: "Noordelijke Marianen (+670)",
                            value: "670",
                          },
                          { title: "Noorwegen (+47)", value: "47" },
                          { title: "Oman (+968)", value: "968" },
                          { title: "Palau (+680)", value: "680" },
                          { title: "Panama (+507)", value: "507" },
                          { title: "Papoea-Nieuw-Guinea (+675)", value: "675" },
                          { title: "Paraguay (+595)", value: "595" },
                          { title: "Peru (+51)", value: "51" },
                          { title: "Filipijnen (+63)", value: "63" },
                          { title: "Polen (+48)", value: "48" },
                          { title: "Portugal (+351)", value: "351" },
                          { title: "Puerto Rico (+1787)", value: "1787" },
                          { title: "Qatar (+974)", value: "974" },
                          { title: "Roemenië (+40)", value: "40" },
                          { title: "Rusland (+7)", value: "7" },
                          { title: "Rwanda (+250)", value: "250" },
                          { title: "San Marino (+378)", value: "378" },
                          { title: "Saoedi-Arabië (+966)", value: "966" },
                          { title: "Senegal (+221)", value: "221" },
                          { title: "Servië (+381)", value: "381" },
                          { title: "Seychellen (+248)", value: "248" },
                          { title: "Sierra Leone (+232)", value: "232" },
                          { title: "Singapore (+65)", value: "65" },
                          { title: "Slowakije (+421)", value: "421" },
                          { title: "Slovenië (+386)", value: "386" },
                          { title: "Salomonseilanden (+677)", value: "677" },
                          { title: "Somalië (+252)", value: "252" },
                          { title: "Zuid-Afrika (+27)", value: "27" },
                          { title: "Spanje (+34)", value: "34" },
                          { title: "Sri Lanka (+94)", value: "94" },
                          { title: "Sint-Helena (+290)", value: "290" },
                          {
                            title: "Saint Kitts en Nevis (+1869)",
                            value: "1869",
                          },
                          { title: "Saint Lucia (+1758)", value: "1758" },
                          { title: "Soedan (+249)", value: "249" },
                          { title: "Suriname (+597)", value: "597" },
                          { title: "Swaziland (+268)", value: "268" },
                          { title: "Zweden (+46)", value: "46" },
                          { title: "Zwitserland (+41)", value: "41" },
                          { title: "Syrië (+963)", value: "963" },
                          { title: "Taiwan (+886)", value: "886" },
                          { title: "Tadzjikistan (+7)", value: "7" },
                          { title: "Thailand (+66)", value: "66" },
                          { title: "Togo (+228)", value: "228" },
                          { title: "Tonga (+676)", value: "676" },
                          {
                            title: "Trinidad en Tobago (+1868)",
                            value: "1868",
                          },
                          { title: "Tunesië (+216)", value: "216" },
                          { title: "Turkije (+90)", value: "90" },
                          { title: "Turkmenistan (+7)", value: "7" },
                          { title: "Turkmenistan (+993)", value: "993" },
                          {
                            title: "Turks- en Caicoseilanden (+1649)",
                            value: "1649",
                          },
                          { title: "Tuvalu (+688)", value: "688" },
                          { title: "Oeganda (+256)", value: "256" },
                          { title: "VK (+44)", value: "44" },
                          { title: "Oekraïne (+380)", value: "380" },
                          {
                            title: "Verenigde Arabische Emiraten (+971)",
                            value: "971",
                          },
                          { title: "Uruguay (+598)", value: "598" },
                          { title: "VS (+1)", value: "1" },
                          { title: "Vanuatu (+678)", value: "678" },
                          { title: "Vaticaanstad (+379)", value: "379" },
                          { title: "Venezuela (+58)", value: "58" },
                          { title: "Vietnam (+84)", value: "84" },
                          { title: "Wallis en Futuna (+681)", value: "681" },
                          { title: "Jemen (+967)", value: "967" },
                          { title: "Zambia (+260)", value: "260" },
                          { title: "Zimbabwe (+263)", value: "263" },
                        ]}
                      />
                      <BorderedTextInput
                        key={index}
                        error={errorFields.phone_numbers[index]}
                        inputValue={phoneNumber.phone_number}
                        setInputValue={(newNum) => {
                          setPhoneNumbers((prevNums) => {
                            const updatedNums = [...prevNums];
                            updatedNums[index].phone_number = newNum;
                            return updatedNums;
                          });
                          setErrorFields((prev) => {
                            let updatedError = [...prev.phone_numbers];
                            updatedError[index] = false;
                            return {
                              ...prev,
                              phone_numbers: updatedError
                            }
                          });
                        }
                        }
                      />
                      <img
                        className="ms-2 removeIconSizeModals"
                        src={Minus}
                        onClick={() => removePhoneNumberField(index)}
                        alt="Remove"
                        style={{ cursor: "pointer", width: "3%" }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 d-flex flex-row align-content-end my-3">
                <InputComponent
                  type={"date"}
                  onValueChange={getValueInput}
                  parentCol={6}
                  labelName={"Geboortedatum"}
                  labelValue={"BirthDate"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
                <div className="col-6 d-flex justify-content-start">
                  <div className="col-4 d-flex flex-column">
                    <label className="mb-2 ms-4 fw-medium">Nieuwsbrief</label>
                    <label className="switchNewCustomer ms-4">
                      <input type="checkbox" />
                      <span className="sliderNewCustomer round"></span>
                    </label>
                  </div>
                  <div className=" col-4  d-flex flex-column">
                    <label className="mb-2 ms-4 fw-medium">
                      Verjaardagactie
                    </label>
                    <label className="switchNewCustomer ms-4">
                      <input type="checkbox" />
                      <span className="sliderNewCustomer round"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex flex-row gap-3 align-content-end my-3">
                <BorderedTextInput
                  label={"Postcode"}
                  size="l"
                  error={errorFields.postal_code}
                  inputValue={formData.PostalCode}
                  setInputValue={(value) => {
                    getValueInput(value, "PostalCode");
                    setErrorFields((prev) => {
                      return {
                        ...prev,
                        postal_code: false
                      }
                    })
                  }
                  }
                  disabled={!isCountrySelected}
                />
                <div className="col-6 d-flex justify-content-end">
                  <div className="col-12 d-flex widthInputComponents flex-row gap-1 justify-content-end">
                    <BorderedTextInput
                      label={"Huisnummer"}
                      size="l"
                      error={errorFields.house_number}
                      inputValue={formData.HouseNumber}
                      setInputValue={(value) => {
                        getValueInput(value, "HouseNumber");
                        setErrorFields((prev) => {
                          return {
                            ...prev,
                            house_number: false
                          }
                        })
                      }
                      }
                      disabled={!isCountrySelected}
                    />
                    <BorderedTextInput
                      label={"Toevoeging"}
                      size="l"
                      error={errorFields.addition}
                      inputValue={formData.Addition}
                      setInputValue={(value) => {
                        getValueInput(value, "Addition");
                        setErrorFields((prev) => {
                          return {
                            ...prev,
                            addition: false
                          }
                        })
                      }
                      }
                      disabled={!isCountrySelected}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex flex-row gap-4 align-content-end my-3 ">
                <BorderedTextInput
                  type={"text"}
                  error={errorFields.street_name}
                  onValueChange={getValueInput}
                  size={"l"}
                  label={
                    <>
                      Straat
                      {countryIdInfo.country_code_2 === "NL" &&
                        formData.StreetName && (
                          <FontAwesomeIcon
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            onClick={() =>
                              setFormData((prev) => ({
                                ...prev,
                                StreetNameEditable: !prev.StreetNameEditable,
                              }))
                            }
                            icon={faPencilAlt}
                          />
                        )}
                    </>
                  }
                  inputValue={formData.StreetName}
                  setInputValue={(value) => {
                    getValueInput(value, "StreetName");
                    setErrorFields((prev) => {
                      return {
                        ...prev,
                        street_name: false
                      }
                    })
                  }
                  }
                  disabled={
                    !countryIdInfo.country_code_2 ||
                    (!formData.StreetNameEditable &&
                      countryIdInfo.country_code_2 === "NL")
                  }
                  defaultValue={formData.Street}
                />
                <BorderedTextInput
                  size={"l"}
                  error={errorFields.city}
                  labelName={
                    <>
                      Stad
                      {countryIdInfo.country_code_2 === "NL" &&
                        formData.City && (
                          <FontAwesomeIcon
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            onClick={() =>
                              setFormData((prev) => ({
                                ...prev,
                                CityEditable: !prev.CityEditable,
                              }))
                            }
                            icon={faPencilAlt}
                          />
                        )}
                    </>
                  }
                  label={"City"}
                  inputValue={formData.City}
                  setInputValue={(value) => {
                    getValueInput(value, "City");
                    setErrorFields((prev) => {
                      return {
                        ...prev,
                        city: false
                      }
                    })
                  }
                  }
                  disabled={
                    !countryIdInfo.country_code_2 ||
                    (!formData.CityEditable &&
                      countryIdInfo.country_code_2 === "NL")
                  }
                  defaultValue={formData.City}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottomCustomers">
          <div className="mx-3 my-0">
            <ButtonDarkBlue
              onClickFunction={handleSubmit}
              text={"Bedrijf aanmaken"}
            />
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddCompanyModal;
