const options = {
    exportTypes: {
        products: {
            displayName: "Producten",
            model: "Warehouse\\Products",
            foreignKey: "product_id",
        },
        client_consumers: {
            displayName: "Consumenten",
            model: "Consumers",
            foreignKey: "internal_id",
        },
        client_businesses: {
            displayName: "Bedrijven",
            model: "Businesses",
            foreignKey: "internal_id",
        },
        client_orders: {
            displayName: "Bestellingen",
            model: "Orders",
            foreignKey: "order_id",
        },
        product_brands: {
            displayName: "Merken",
            route: "/products/brands",
            model: "Warehouse\\ProductBrands",
        },
        product_categories: {
            displayName: "Product Categorieën",
            route: "/products/category",
            model: "Warehouse\\ProductCategories",
        },
        product_colors: {
            displayName: "Product Kleuren",
            route: "/products/colors",
            model: "Warehouse\\ProductColors",
        },
        product_genders: {
            displayName: "Product Geslachten",
            route: "/products/genders",
            model: "Warehouse\\ProductGenders",
        },
        product_sizes: {
            displayName: "Product Maten",
            route: "/products/sizes",
            model: "Warehouse\\ProductSizes",
        },
        product_sizes_blueprints: {
            displayName: "Product Maten blauwdrukken",
            route: "/products/sizes/blueprints",
            model: "Warehouse\\ProductSizesBlueprints",
        },
        suppliers: {
            displayName: "Leveranciers",
            route: "/suppliers",
            model: "Suppliers",
        },
        tax_rates: {
            displayName: "Belasting types",
            route: "/taxrates",
            model: "VatRates",
        },
        client_businesses_contact: {
            displayName: "Bedrijf contacten",
            route: "/businessescontacts",
            model: "BusinessesContacts",
        },
        currencies: {
            displayName: "Geldeenheden",
            route: "/currencies",
            model: "Currencies",
        },
        invoices: {
            displayName: "Facturen",
            route: "/invoices",
            model: "Invoices",
        },
        marketings: {
            displayName: "Marketing",
            route: "/marketings",
            model: "Marketing",
        },
        payment_providers: {
            displayName: "Betaal providers",
            route: "/paymentproviders",
            model: "PaymentProviders",
        },
    },

    groupOptions: {
        Producten: {
            product_discounts: {
                displayName: "Kortingen",
                withKeys: ["discounts"],
            },
            product_media: {
                displayName: "Media",
                withKeys: ["media", "media.color", "media.size"],
            },
            product_prices: {
                displayName: "Prijzen",
                withKeys: ["prices"],
            },
            product_stock: {
                displayName: "Voorraad",
                withKeys: ["stock"],
            },
            product_barcode: {
                displayName: "Barcodes",
                withKeys: ["barcode"],
            },
            product_has_categories: {
                displayName: "Categorieën",
                withKeys: ["categories"],
            },
            product_has_colors: {
                displayName: "Kleuren",
                withKeys: ["colors"],
            },
            product_has_genders: {
                displayName: "Geslachten",
                withKeys: ["genders"],
            },
            product_has_seasons: {
                displayName: "Seizoenen",
                withKeys: ["seasons"],
            },
            product_has_sizes: {
                displayName: "Maten",
                withKeys: ["sizes"],
            },
            // product_has_suppliers: {
            //     displayName: "Leveranciers",
            //     withKeys: ["suppliers.supplier.company"],
            // },
            product_has_style_numbers: {
                displayName: "Stijl nummers",
                withKeys: ["styleNumber"],
            },
            serial_numbers: {
                displayName: "Serienummers",
                withKeys: ["serialnumbers"],
            },
        },
        Consumenten: {
            client_adresses: {
                displayName: "Adressen",
                withKeys: ["addresses"],
            },
            client_notes: {
                displayName: "Notities",
                withKeys: ["client_notes"],
            },
            client_orders: {
                displayName: "Bestellingen",
                withKeys: ["orders"],
            },
            client_phone_numbers: {
                displayName: "Telefoonnummers",
                withKeys: ["phone_numbers"],
            },
            client_emails: {
                displayName: "Emails",
                withKeys: ["emails"],
            },
            client_email_settings: {
                displayName: "Email instellingen",
                withKeys: ["email_settings"],
            },
            client_invoices: {
                displayName: "Facturen",
                withKeys: ["invoices"],
            },
        },
        Bedrijven: {
            client_adresses: {
                displayName: "Adressen",
                withKeys: ["addresses"],
            },
            client_notes: {
                displayName: "Notities",
                withKeys: ["client_notes"],
            },
            businesses_contacts: {
                displayName: "Contactpersonen",
                withKeys: ["businesses_contacts"],
            },
            client_orders: {
                displayName: "Bestellingen",
                withKeys: ["orders"],
            },
            client_phone_numbers: {
                displayName: "Telefoonnummers",
                withKeys: ["phone_numbers"],
            },
            client_emails: {
                displayName: "Emails",
                withKeys: ["emails"],
            },
            client_email_settings: {
                displayName: "Email instellingen",
                withKeys: ["email_settings"],
            },
            client_invoices: {
                displayName: "Facturen",
                withKeys: ["invoices"],
            },
        },
        Bestellingen: {
            order_notes: {
                displayName: "Notities",
                withKeys: ["orderNotes"],
            },
            order_emails: {
                displayName: "Emails",
                withKeys: ["orderEmails"],
            },
            order_product: {
                displayName: "Verkochte producten",
                withKeys: ["ordersSoldProducts"],
            },
            shipments: {
                displayName: "Verzendingen",
                withKeys: ["shipments"],
            },
        },
    },
};

export default options;
