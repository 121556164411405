import React, { useEffect, useMemo, useState } from 'react';
import CheckboxHeader from '../../Table/TableComponents/CheckboxHeader';
import CheckboxCell from '../../Table/TableComponents/CheckboxCell';
import SortableHeader from '../../Table/TableComponents/SortableHeader';
import { useTable } from 'react-table';
import TablePreset from '../../Table/TablePreset';
import salesChannels from '../../Api/SalesChannels';
import { useNavigate, useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import middleware from '../../Api/Middleware';
import PageTemplate from '../../Templates/PageTemplate';
import { BackButton } from '../../HouseStyle/Components/Buttons';

const CurrentProducts = () => {
    const navigate = useNavigate();
    const { salesChannelId } = useParams();
    const [products, setProducts] = useState([]);
    const [addingProduct, setAddingProduct] = useState(0);
    const [tableLoading, setTableLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    const [selectedIds, setSelectedIds] = useState([]);
    const [searchengine, setSearchengine] = useState("");
    const [searchBarInput, setSearchBarInput] = useState("");
    const [pagination, setPagination] = useState({
        page_size: 10,
        page: 1,
        max_items: 0,
        begin_of_page: 0,
        end_of_page: 0,
    });
    const [order, setOrder] = useState({ orderBy: '', direction: '' });
    const [fullyShownProducts, setFullyShownProducts] = useState([]); // usestate for the productname that u can fully show and shortend it
    const [searchBar, setSearchBar] = useState("");

    const fetchAll = async () => {
        try {
            setTableLoading(true);
            const searchQuery = searchBar.trim() ? `&searchTerm=${encodeURIComponent(searchBar.trim())}` : '';
            const response = await salesChannels.get(`/sp/salesChannelProducts?sales_channel_id=${salesChannelId}&include_unlinked=${addingProduct}&page=${pagination.page}&page_size=${pagination.page_size}${searchQuery}&${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}` : ''}`);
            setProducts(response.data.data);
            setPagination({
                ...pagination,
                max_items: response.data.total,
                begin_of_page: response.data.from,
                end_of_page: response.data.to,
            });
            setTableLoading(false);
            setPageLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        fetchAll();
    }, [addingProduct, pagination.page_size, pagination.page, order, searchBar]);

    const columns = useMemo(
        () => [
            {
                Header: (
                    <CheckboxHeader currentItem={products} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'product_id'}>
                    </CheckboxHeader>
                ),
                accessor: "select",
                Cell: ({ row }) => {
                    return <CheckboxCell row={row} selectedIds={selectedIds} pk={'product_id'} />
                }
            },
            {
                Header: (
                    // image header (not sortable)
                    <SortableHeader currentItem={order} setItem={setOrder} field={'product_id'} pos={'center'}>
                        Afbeelding
                    </SortableHeader>
                ),
                accessor: "image_path",
                Cell: ({ row }) => {
                    return (
                        <>
                            {/* Check if a product has a image, if yes then show the first image of the product if no then show a placeholder icon */}
                            {row.original.image_path != null ? (
                                <div className="productPhoto align-content-center rounded d-flex justify-content-center align-items-center" style={{ width: "45px", height: "45px" }}>
                                    <img src={middleware.defaults.baseURL + "/public/storage/" + row.original.image_path}
                                        className="productImage rounded d-flex"
                                        style={{ maxWidth: "45px", maxHeight: "45px" }} />
                                </div>
                            ) : (
                                <FontAwesomeIcon icon={faCamera} size="3x" className="LightBlueText productPhoto align-self-center" />
                            )}
                        </>
                    );

                }
            },
            {
                Header: () => (
                    <div className="ms-5">
                        <SortableHeader currentItem={order} setItem={setOrder} field={'product_name'} pos={'start'}>
                            Productnaam
                        </SortableHeader>
                    </div>
                ),
                accessor: 'product_name',
                Cell: ({ row }) => {
                    const productEditRoute = `/producten/bewerken/${row.original.product_id}?sc_return=${salesChannelId}`;
                    return (
                        <span className="d-block align-content-center ms-5" style={{ height: "45px" }}>
                            {row.original.product_name.length > 100 ? (
                                fullyShownProducts.some(element => element === row.original.product_id) ?
                                    <>
                                        <span className="BlueText" onClick={e => {
                                            e.stopPropagation();
                                            navigate(productEditRoute);
                                        }}
                                            onMouseDown={(event) => {
                                                // sent user to new blank page on middle mouse btn click
                                                if (event.button === 1) {
                                                    window.open(productEditRoute, '_blank');
                                                }
                                            }}>
                                            {row.original.product_name}
                                        </span>
                                        <span
                                            onClick={e => {
                                                e.stopPropagation();
                                                setFullyShownProducts(fullyShownProducts.filter(id => id !== row.original.product_id));
                                            }}
                                            className="text-info hover text-nowrap ms-1">
                                            Toon minder
                                        </span>
                                    </>
                                    :
                                    <>
                                        <span className="BlueText" onClick={e => {
                                            e.stopPropagation();
                                            navigate(productEditRoute);
                                        }}
                                            onMouseDown={(event) => {
                                                // sent user to new blank page on middle mouse btn click
                                                if (event.button === 1) {
                                                    window.open(productEditRoute, '_blank');
                                                }
                                            }}>
                                            {row.original.product_name.slice(0, 90)}
                                        </span>
                                        <span
                                            onClick={e => {
                                                setFullyShownProducts([...fullyShownProducts, row.original.product_id]);
                                            }}
                                            className="text-info hover text-nowrap ms-2">
                                            (...)
                                        </span>
                                    </>
                            ) : (
                                <span className="BlueText cursorPointer d-block h-100 align-content-center" onClick={e => {
                                    e.stopPropagation();
                                    navigate(productEditRoute);
                                }}
                                    onMouseDown={(event) => {
                                        // sent user to new blank page on middle mouse btn click
                                        if (event.button === 1) {
                                            window.open(productEditRoute, '_blank');
                                        }
                                    }}>
                                    {row.original.product_name}
                                </span>
                            )
                            }
                        </span>
                    );
                },
            },
            {
                Header: () => (
                    <div className="">
                        <SortableHeader currentItem={order} setItem={setOrder} field={'incl_tax'} pos={'center'}>
                            Prijs (incl. BTW)
                        </SortableHeader>
                    </div>
                ),
                accessor: "incl_tax",
                Cell: ({ row }) => {
                    return <div className="text-center me-5 BlackText">€ {row.original.incl_tax || "-"}</div>;
                }
            },
            {
                Header: () => (
                    <SortableHeader currentItem={order} setItem={setOrder} field={'stock'} pos={'center'}>
                        Voorraad
                    </SortableHeader>
                ),
                accessor: "stock",
                Cell: ({ row }) => {
                    return <div className="text-center BlackText">{row.original.stock || 0}</div>;
                }
            },
        ],
        [order.orderBy, order.direction, selectedIds, setSelectedIds, fullyShownProducts, products]
    )

    const handleSelection = (salesChannelId, isSelected) => {
        setSelectedIds(previous =>
            isSelected ? [...previous, salesChannelId] : previous.filter(id => id !== salesChannelId)
        );
    }

    const tableInstance = useTable({ columns, data: products });

    const Action = async (methode) => {
        switch (methode) {
            case 'add':
                if (selectedIds.length === 0) return;
                setTableLoading(true);
                await salesChannels.post(`saleschannels/${salesChannelId}/products/add`, { product_ids: selectedIds });
                setSelectedIds([]);
                fetchAll();
                break;
            case 'delete':
                if (selectedIds.length === 0) return;
                setTableLoading(true);
                await salesChannels.post(`saleschannels/${salesChannelId}/products/delete`, { product_ids: selectedIds });
                setSelectedIds([]);
                fetchAll();
                break;
        }
    }

    const actionOptions = [
        addingProduct
            ? { title: "Voeg toe aan verkoopkanaal", function: () => Action('add') }
            : { title: "Verwijder uit verkoopkanaal", function: () => Action('delete') },
    ]

    const [timerId, setTimerId] = useState(null);
    const searchDelay = async (value) => {
        setSearchBarInput(value)
        if (timerId) {
            clearTimeout(timerId);
        }
        const newTimerId = setTimeout(() => {
            setSearchengine(value);
        }, 500);

        setTimerId(newTimerId);
    }

    const handleBackButton = () => {
        if (addingProduct) {
            setTableLoading(true);
            setAddingProduct(0);
            setPagination({
                ...pagination,
                page: 1,
            });
            setSelectedIds([]);
        } else {
            navigate(`/verkoopkanalen/${salesChannelId}`);
        }
    }

    const handleSearchChange = (value) => {
        setSearchBar(value);
        setPagination(prev => ({ ...prev, page: 1 }));
    };

    return (
        <PageTemplate navbarTitle={addingProduct === 0 ? "Producten in het verkoopkanaal" : "Producten toevoegen aan verkoopkanaal"} pageIsLoading={pageLoading}>
            <div className="d-flex flex-column gap-5 w-100">
                <div>
                    <div className="d-flex mb-4 mt-4 justify-content-between">
                        <div className="d-flex gap-5">
                            <BackButton text={"Terug"} onClickFunction={handleBackButton} />
                        </div>
                    </div>

                    <div className="productTable">
                        <TablePreset
                            data={[{ 'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'product_id' }]}
                            setPagination={(e) => setPagination(e)}
                            tableLoading={tableLoading}
                            handleSelection={handleSelection}
                            searchBar={[{ shown: true, searchFunction: handleSearchChange, placeholder: "Zoeken op product, etc." }]}
                            actionOptions={actionOptions}
                            leftButtons={[{ title: "Filteren", function: () => { } }]}
                            rightButtons={
                                addingProduct === 1
                                    ? []
                                    : [{
                                        title: "Voeg producten toe aan verkoopkanaal", function: () => {
                                            setTableLoading(true);
                                            setAddingProduct(1);
                                            setPagination({ ...pagination, page: 1 });
                                            setSelectedIds([]);
                                        }
                                    }]
                            }
                        />
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

export default CurrentProducts;