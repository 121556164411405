import React, { useEffect, useRef, useState } from 'react';
import PageTemplate from '../../Templates/PageTemplate';
import TextEditor from '../../Main/TextEditor';
import { useNavigate } from 'react-router';
import ToastError from '../../Toasts/ToastError';
import ToastSuccess from '../../Toasts/ToastSuccess';
import Backbutton from "../BackIcon.svg";
import middleware from '../../Api/Middleware';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import noimage from '../noimage.jpg'
const CatAdd = () => {
  const [editorContentSummary, setEditorContentSummary] = useState('');
  const navigate = useNavigate();
  const [editorContentDescription, setEditorContentDescription] = useState('');
  const [availableCategories, setAvailableCategories] = useState([]);
  const [category, setCategory] = useState({ status: 0, image: null, title: '', description: '' });
  const [seo, setSeo] = useState({ title: '', keywords: '', description: '', slug: '' });
  const [loading, setLoading] = useState(true);
  const [changedImage, setChangedImage] = useState(false);

  const imageInputRef = useRef(null);

  const fetchCategory = async () => {
    const [availableCategoriesRes] = await Promise.all([
      middleware.get(`/products/category`)
    ])
    setAvailableCategories(availableCategoriesRes.data.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchCategory();
  }, []);
  useEffect(() => {
    setSeo({...seo, slug: '/' + (category.title || '').toLowerCase().replaceAll(' ', '-') + '/' })
    
  }, [category.title])
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    console.log(file);

    if (file) {
      const fileType = file.type;
      const validImageTypes = ["image/png", "image/jpg", "image/jpeg", "image/webp", "image/gif"];

      if (!validImageTypes.includes(fileType)) {
        ToastError("Ongeldig foto. Alleen PNG, JPG, JPEG, WEBP, en GIF zijn toegestaan.");
        return; // Exit if the file type is not valid
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setCategory({ ...category, image: e.target.result })
        setChangedImage(true);
        if (imageInputRef.current) {
          imageInputRef.current.value = null; // Clear the file input
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = () => {
    setCategory({ ...category, image: null });
    setChangedImage(true);
  };


  const saveCategorie = async (createnew) => {
    try {
      let missing = [];
      let wrong = [];
      if (!category.title || category.title == '') {
        missing.push('Naam')
      }
      // if (!category.description || category.description == '') {
      //   missing.push('Omschrijving')
      // }
      // if (!category.slug || category.slug == '') {
      //   missing.push('Slug')
      // }

      if (missing && missing.length) {
        if (missing.length > 1) {
          ToastError("Vul de volgende velden in: " + missing.join(', '));
        } else {
          ToastError("Vul het " + missing[0] + ' veld in.')
        }
        return;
      }
      if (category.title && category.title != '') {
        if (seo.title && seo.description && seo.keywords) {
          const res = await middleware.post(`/products/category`, category);
          await middleware.post(`/products/seos?category_id=${res.data.category_id}`, seo);
        } else {
          ToastError("Vul de SEO velden in.");
          return;
        }
      } else {
        ToastError("Vul de Naam veld in.");
        return;
      }

      if (createnew) {
        navigate('/');
      } else {
        navigate('/warehouse/instellingen/categorieën');
      }
    } catch (e) {
      console.log(e);
      
      ToastError('Er is een fout opgetreden tijdens het aanmaken.');
    }

  }

  return (
    <PageTemplate pageIsLoading={loading} navbarTitle={"Categorie Toevoegen"} backValue={"/warehouse/instellingen/categorieën"}>
      <div className='d-flex w-100 flex-column'>
        <div className='d-flex mb-4 mt-4 flex-row justify-content-between'>
          <div className='d-flex'>
            <div className="d-flex flex-row me-5 align-items-center">
              <img className="back-button-img" src={Backbutton} onClick={() => navigate(-1)} />
              <h5 className="p-0 m-0">Terug</h5>
            </div>
            {/* Published btn*/}
            <div className="d-flex flex-column align-items-center">
              <p className="mb-1 p-0">Gepubliceerd</p>
              <label className="switchActiveProduct">
                <input type="checkbox" checked={category.status || false}
                  onChange={(e) => setCategory({ ...category, status: !category.status })} />
                <span className="sliderActiveProduct round">
                </span>
              </label>
            </div>
          </div>
          <div>
            <button className="darkBlueStandardButton me-2 text-nowrap px-4" style={{ width: 'fit-content' }} onClick={() => saveCategorie(true)}>Aanmaken en nieuwe toevoegen</button>
            <button className="lightBlueStandardButton" onClick={() => saveCategorie(false)}>Aanmaken</button>
          </div>
        </div>
        <div className='whiteBox w-100 px-5 py-4 fitcontentHeight' style={{ minheight: '80vh' }}>
          <div className='d-flex flex-row justify-content-between pb-3 mb-3'>
            <h3 className='mb-0 fw-bold'>Categorie aanmaken</h3>
          </div>

          <div>
            <div className='d-flex flex-row w-100 mb-3'>
              <div className='d-flex flex-column w-50 pe-3'>
                <label className='mb-2 fw-semibold'>Naam</label>
                <input className='standardInput' onChange={(e) => setCategory({ ...category, title: e.target.value })} value={category?.title || ''} />
              </div>
              <div className='d-flex flex-column w-50'>
                <label className='mb-2 fw-semibold'>Slug</label>
                {/* <input className='standardInput' onChange={(e) => setCategory({ ...category, slug: e.target.value })} value={category.slug || ''} /> */}
                <input className='standardInput' value={seo.slug} onChange={(e) => setSeo({ ...seo, slug: e.target.value })} />
              </div>
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-semibold'>Hoofdcategorie</label>
              <select value={category.head_category_id} onChange={(e) => setCategory({ ...category, head_category_id: e.target.value })} className='standardInput standardDropdownArrow w-100'>
                <option hidden>Selecteer een hoofdcategorie</option>
                <option value={""}>Geen</option>
                {availableCategories && availableCategories?.map((e) =>
                  <option key={e.category_id} value={e.category_id}>
                    {e.title}
                  </option>
                )}
              </select>
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-semibold'>Omschrijving</label>
              <TextEditor value={category.description} onChange={(e) => setCategory({ ...category, description: e })} />
            </div>
            <label className='mb-2 fw-semibold'>Afbeelding</label>

            <div className="d-flex flex-row gap-2">
              <div className="editCategoryImage">
                <img src={category.image || noimage} alt="Categorie" />
              </div>
              <div className="uploadImageBox">
                <label htmlFor="imageUpload" className="btn btn-techdog">Nieuwe foto</label>
                <input
                  type="file"
                  className="imageUpload"
                  name="imageUpload"
                  id="imageUpload"
                  ref={imageInputRef}
                  onChange={(event) => handleImageUpload(event)}
                />
              </div>
              <div className="uploadImageBox">
                <div
                  type="button"
                  className={`btn btn-danger ${category?.image ? "" : "disabled"}`}
                  disabled={category.image ? false : true}
                  onClick={handleImageDelete}>
                  <FontAwesomeIcon icon={faTrashCan} />
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className='mt-4'>
            <h3 className=' fw-semibold mb-3'>SEO</h3>
            <div className='d-flex flex-column mb-3'>
              <label className='mb-2 fw-semibold'>SEO titel</label>
              <input className='standardInput' value={seo.title} onChange={(e) => setSeo({ ...seo, title: e.target.value })} />
            </div>
            <div className='d-flex flex-column mb-3'>
              <label className='mb-0 fw-semibold'>SEO zoekwoorden</label>
              <small style={{ color: "#c9c9c9" }} className='mb-2'>Vul een zoekwoord in en druk op enter om het zoekwoord toe te voegen.</small>
              <textarea className='standardInput p-2' style={{ height: "125px" }} value={seo.keywords} onChange={(e) => setSeo({ ...seo, keywords: e.target.value })} />
            </div>
            <div className='d-flex flex-column mb-4'>
              <label className='mb-2 fw-semibold'>SEO omschrijving</label>
              <textarea className='standardInput p-2' style={{ height: "125px" }} value={seo.description} onChange={(e) => setSeo({ ...seo, description: e.target.value })} />
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default CatAdd;
