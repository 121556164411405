import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ButtonBlue, ButtonLightGrey } from '../../HouseStyle/Components/Buttons';

const CancelModalComponent = (props) => {
    const navigate = useNavigate();

    return (
    <Modal
        id="modalCancelOrder"
        show={props.cancelModalVisible}
        onHide={{}}
        className="colormodals"
        size="lg"
        centered
        backdrop="static"
    >
        <Modal.Header className="modalTop justify-content-between px-5" >
        <h3>Weet je zeker dat je deze order wil annuleren?</h3>
            <button variant="transparent" className=' btn' onClick={() => {props.setCancelModalVisible(false)}}>
                <FontAwesomeIcon icon={faXmark} className="closeModal" />
            </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
        </Modal.Body>
        <Modal.Footer className="modalBottom">
            <div className="InputRow justify-content-center px-5 mx-lg-5" >
                <ButtonLightGrey text={"Annuleren en crediteren"} size='m' onClickFunction={() => {navigate("/bestellingen/annuleren/" + props.orderId)}}/>
                <ButtonBlue text={"Order behouden"} size='m' onClickFunction={() => {props.setCancelModalVisible(false)}}/>
            </div>
        </Modal.Footer>
    </Modal>
  )
}

export default CancelModalComponent
