import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import SortUp from "../../HouseStyle/DarkIcons/SortUp.svg"
import SortDown from "../../HouseStyle/DarkIcons/SortDown.svg"
import Sort from "../../HouseStyle/DarkIcons/Sort.svg"

const SortableHeader = ({ currentItem, setItem, field, children, pos }) => {
    const position = pos ?? 'center';
    const generateOrderDirection = () => {
      if (currentItem.direction === 'asc') {
        return currentItem.orderBy === field ? 'desc' : 'asc';
      }
      if (currentItem.direction === 'desc') {
        return currentItem.orderBy === field ? '' : 'asc';
      }
      return 'asc';
    }
  
    const renderSortIcon = () => {
      if (currentItem.orderBy !== field) return <img src={Sort} className="SortingIcon"/>;
      return currentItem.direction === 'asc' ? <img src={SortUp} className="SortingIcon"/> : currentItem.direction === 'desc' ? <img src={SortDown} className="SortingIcon"/> : <img src={Sort} className="SortingIcon"/>;
    }
  
    return (
      <div 
        onClick={() => setItem({ ...currentItem, orderBy: field, direction: generateOrderDirection() })}
        className={"header-filter tablePresetHeader SortingHeaderContainer text-" + position}
      >
        {children} {renderSortIcon()}
      </div>
    );
  };

  export default SortableHeader;