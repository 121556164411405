import React from 'react'

export const RegularCheckBox = ({label, setValue, value}) => {
  const handleChange = () => {
    setValue(!value);
  };

  return (
    <div className='d-flex flex-row gap-4'>
        <label className="d-flex flex-row gap-4 align-items-center">
            <input 
                type="checkbox" 
                name="checkbox" 
                className='CheckBox' 
                checked={value} 
                onChange={handleChange}
            />
            {label ?? ""}
        </label>
    </div>
  )
}