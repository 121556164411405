import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useTable } from "react-table";
import Modal from "react-bootstrap/Modal";
import middleware from "../Api/Middleware";
import { useNavigate, useLocation } from "react-router";
import "../Main/Main.css";
import "./Blueprints.css";
import * as Icons from "../HouseStyle/Icons";
import Loading from "../LoadingScreen/LoadingScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPen, faXmark,} from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";
import SortableHeader from '../Table/TableComponents/SortableHeader';
import CheckboxHeader from "../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../Table/TableComponents/CheckboxCell";
import PageTemplate from "../Templates/PageTemplate";
import TablePreset from "../Table/TablePreset";
import { BorderedDropDown } from "../HouseStyle/Components/DropDowns";
import { ButtonBlue, ButtonLightGrey } from "../HouseStyle/Components/Buttons";
import { BorderedTextInput } from "../HouseStyle/Components/Inputs";
import { set } from "lodash";

function Blueprints() {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const [loading, setLoading] = useState(true); // useState for page loading  
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(true); // useState for the Loadingscreen
  const [blueprints, setBlueprints] = useState([]); // useState for all products in the database
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  }); // the useState for the pagination for the table
  const [order, setOrder] = useState({ orderBy: "", direction: "" }); // useState for ordering the columns in the table
  const [selectedProductIds, setSelectedProductIds] = useState([]); // usestate for the selected checkboxes
  const [timerId, setTimerId] = useState(null);
  const [blueprintsSearchengine, setBlueprintsSearchengine] = useState("");
  const [inputChangeInternalCode, setInputChangeInternalCode] = useState("");
  const [searchBar, setSearchBar] = useState("");
  // const [blueprintData, setBlueprintData] = useState([]);
  const [show, setShow] = useState(false);
  const [blueprintName, setBlueprintName] = useState("");
  const [showModalBlueprint, setShowModalBlueprint] = useState(false);
  const [showModalBlueprintNew, setShowModalBlueprintNew] = useState(false);
  const [borderedSelectedOption, setBorderedSelectedOption] = useState(false);

  // fetched all the products and the stock an the colors from the database
  
  const fetchAll = async () => {
    try {
      setLoading(true);
      const searchQuery = searchBar.trim() ? `&searchTerm=${encodeURIComponent(searchBar.trim())}&noSearch=["blueprint_id","size_ids","status","created_at"]` : "";
      const [fetchBlueprints] = await Promise.all([
        middleware.get(`sp/blueprintsTableAll?page=${pagination.page}&page_size=${pagination.page_size}${order.orderBy !== "" && order.direction !== "" ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}` : "" }${searchQuery}`),
      ]);
      setBlueprints(fetchBlueprints.data.data);
      setPagination({
        ...pagination,
        max_items: fetchBlueprints.data.total,
        begin_of_page: fetchBlueprints.data.from,
        end_of_page: fetchBlueprints.data.to,
      }); // sets the pagination usestate with the max items begin of the page and the end of the page
      setLoadingScreenFunc();
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };

  // fetches all the data when pagination.page_size, pagination.page, order or location.pathname changes
  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page, order, location.pathname]);

  useEffect(() => {
    fetchAll();
  }, []);

//   useEffect(() => {
//     try {
//       if (blueprintsSearchengine.trim() === "" && blueprintsSearchengine === "") {
//         // Code to fetch all when searchengine is empty
//         fetchAll();
//       } else if (blueprintsSearchengine.trim() !== "") {
//         // Code to perform the search when searchengine has actual characters
//         const doIt = async () => {
//           const [fetchBlueprints] = await Promise.all([
//             middleware.get(
//               `products/sizes/blueprints?page=${pagination.page}&page_size=${pagination.page_size}&searchTerm=${blueprintsSearchengine}&noSearch=[updated_at,created_at]`
//             ),
//           ]);
//           setBlueprints(fetchBlueprints.data.data);
//           setPagination({
//             ...pagination,
//             max_items: fetchBlueprints.data.total,
//             begin_of_page: fetchBlueprints.data.from,
//             end_of_page: fetchBlueprints.data.to,
//           }); // sets the pagination usestate with the max items begin of the page and the end of the page
//         };
//         doIt();
//       } 
//     } catch (error) {
//       if (error.response.status === 401) {
//         cookies.remove("token", { path: "/" });
//         navigate("/");
//       }
//     }
// }, [blueprintsSearchengine]);


useEffect(() => {
    try {
      if (searchBar.trim() === "" && searchBar === "") {
        // Code to fetch all when searchengine is empty
        fetchAll();
      } else if (searchBar.trim() !== "") {
        fetchAll();
      } 
    } catch (error) {
    if (error.response.status === 401) {
      cookies.remove("token", { path: "/" });
      navigate("/");
    }
  }
    
}, [searchBar]);

  const EditIcon = <FontAwesomeIcon icon={faPen} />;

  // handles to close the de-activate modal
  const handleClose = () => setShow(false);
  const handleCloseBlueprint = () => setShowModalBlueprint(false);
  const handleCloseBlueprintNew = () => setShowModalBlueprintNew(false);

  //handles to show the de-activate modal
  const handleShow = () => setShow(true);
  const handleShowBlueprint = () => setShowModalBlueprint(true);


  // sets loading on false an waits 0.5 sec to also set the Loadingscreen to false
  function setLoadingScreenFunc() {
    setLoading(false);
    setTimeout(() => {
      setLoadingScreen(false);
    }, 500);
  }

  const handleBlueprintName = () => {
    localStorage.setItem("BlueprintName", blueprintName);
  };

  const handleBlueprintData = (data) => {
    localStorage.setItem("Blueprintdata", JSON.stringify(data));
    navigate("./bewerken");
  };

  // Handle checkbox selection
  const handleProductSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedProductIds([...selectedProductIds, productId]);
    } else {
      setSelectedProductIds(
        selectedProductIds.filter((id) => id !== productId)
      );
    }
  };

  // a function that delays
  function delayedFunction(Func) {
    setTimeout(() => {
      Func();
    }, 500);
  }


  

  const columns = useMemo(
    () => [
        {
          Header: (
            <CheckboxHeader currentItem={blueprints} selectedIds={selectedProductIds} setSelectedIds={setSelectedProductIds} pk={'blueprint_id'}>
            </CheckboxHeader>
          ),
          accessor: "select",
          Cell: ({ row }) => {
            return <CheckboxCell row={row} handleSelection={handleProductSelection} selectedIds={selectedProductIds} pk={'blueprint_id'}/>
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field="title">
              Naam
            </SortableHeader>
          ),
          accessor: 'title',
          Cell: ({ row }) => {
            return <a className="FlatTextLink text-decoration-none text-nowrap" onClick={() => handleBlueprintData(row.original)}>{row.original.title}</a>
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field="size_names">
              Maten
            </SortableHeader>
          ),
          accessor: 'size_names',
          Cell: ({ row }) => {
            return row.original.size_names
          }
        },
    ],
    [order.orderBy, order.direction, selectedProductIds, setSelectedProductIds, blueprints,]
  );

  //   Table instance
  const tableInstance = useTable({ columns, data: blueprints });

  // Destructure table properties

  const handleSearchChange = useCallback((value) => {
    setSearchBar(value);
    setPagination(prev => ({ ...prev, page: 1 }));
  }, []);

  const suspend = async () => {
    // Iterate over each product id in the selectedProductIds array
    selectedProductIds.map(async (data) => {
      // For each product id, send a PUT request to the 'products' endpoint
      // to update the status of the product to 1 (this might signify that the product is suspended)
      await middleware.put("products/sizes", {
        primary_key: data,
        status: 1,
      });
    });

    // After updating all products, call fetchAll function to get updated list of products
    await fetchAll();

    // Reload the page to show the updated product statuses to the user
    window.location.reload();
  };

  // a function that selects all the selects in the table
  const select_all = () => {
    setSelectedProductIds(blueprints.map((blueprint) => blueprint.primary_key));
  };

  // a function that unselects all the selects in the table
  const unselect_all = () => {
    setSelectedProductIds(blueprints.map((blueprint) => blueprint.primary_key));
    setSelectedProductIds([]);
  };

  const submitBlueprint = async (event) => {
    event.preventDefault();

    try {
      const blueprintResponse = await middleware.post(
        `products/sizes/blueprints`,
        {
          title: blueprintName,
        }
      );

      localStorage.setItem(
        "Blueprintdata",
        JSON.stringify(blueprintResponse.data)
      );
      setBlueprintName("");
      navigate("./bewerken");
    } catch (error) {
      console.error(error);
    }
  };

  const Action = (methode) => {
    if (methode == 'select-all') {
      setSelectedProductIds([...selectedProductIds, ...blueprints.filter(cons => !selectedProductIds.includes(cons.blueprint_id)).map(e => e.blueprint_id)])
    }
    else if (methode == 'unselect-all') {
      setSelectedProductIds([])
    }
    else  if (methode === "suspend") {
      handleShow();
    }
  }

  const deactivateBlueprintSize = async () => {
    try {
      const promises = selectedProductIds.map(id => 
        middleware.put(`products/sizes/blueprints`, {
        blueprint_id: id,
        status: 0,
      }));
      await Promise.all(promises);
      setSelectedProductIds([]);
      // Optionally, you can refresh the data or perform other actions here
      fetchAll();
    } catch (error) {
      console.error("Error deactivating sizes:", error);
    }
  }

  console.log();
  

  const handleAddTemplate = async () => {
    try {
      // Split the selected value into an array of sizes
      const [title, sizesString] = borderedSelectedOption.split(': ');
      const sizes = sizesString.split(' | ');

      let bulk_data = [];
      sizes.forEach((size, index) => {
        bulk_data.push({
          main: size,
          sorting_order: index + 1 // Start sorting order from 1 and increment
        });
      });

      const sizeResponse = await middleware.post(`products/sizes`, {
          bulk_data: bulk_data,
      });

      // Extract size IDs from the responses
      const sizeIds = sizeResponse.data.map(size => size.size_id).join(',');

      // Create the blueprint with the size IDs
      await middleware.post('products/sizes/blueprints', { title: title, size_ids: sizeIds });

      console.log('Blueprint and sizes created successfully');
      handleCloseBlueprint();
      fetchAll();
    } catch (error) {
      console.error('Error creating blueprint and sizes:', error);
    }
  };
  

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} centered backdrop="static" size="xl">
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className="ms-3 BlockTitle ">Let op!</Modal.Title>
          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={() => setShow(false)}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter pb-0 justify-content-around">
          <div className="ms-3">
            U gaat {selectedProductIds.length}{" "}
            {selectedProductIds.length === 1 ? "item" : "items"} de-activeren. Weet U
            dat zeker?
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
          <div className="d-flex flex-row">
            <div className="me-3">
              <ButtonLightGrey text={"Annuleren"} className="ms-3 btn bg-danger text-white" onClickFunction={() => setShow(false)}/>
            </div>
            <ButtonBlue
              text={"De-activeren"}
              onClickFunction={() => {
                setShow(false);
                deactivateBlueprintSize();
              }}
            />
            </div>
        </Modal.Footer>
      </Modal>

      <Modal
        id="modalBlueprint"
        show={showModalBlueprint}
        onHide={handleCloseBlueprint}
        centered
        size="xl"
        backdrop="static"
      >
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className="ms-2">Kies een Template</Modal.Title>
          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={handleCloseBlueprint}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter justify-content-around">
          <div className="ms-2">
            <div className="FlatText">Kies een template dat je wilt toevoegen als blauwdruk</div>
            <BorderedDropDown
                placeholder="Templates"
                options={[
                  { title: "Internationale maten 1: XXS | XS | S | M | L | XL | XXL | XXXL | 4XL | 5XL | 6XL", value: "Internationale maten 1: XXS | XS | S | M | L | XL | XXL | XXXL | 4XL | 5XL | 6XL" },
                  { title: "Internationale maten 2: XXS | XS | S | M | L | XL | XXL | XXXL", value: "Internationale maten 2: XXS | XS | S | M | L | XL | XXL | XXXL" },
                  { title: "Kindermaten: 104 | 110 | 116 | 122 | 128 | 134 | 140 | 146 | 152 | 154 | 158 | 164 | 170 | 176", value: "Kindermaten: 104 | 110 | 116 | 122 | 128 | 134 | 140 | 146 | 152 | 154 | 158 | 164 | 170 | 176" },
                  { title: "Riemmaten: 80 | 85 | 90 | 95 | 100 | 105 | 110 | 115 | 120 | 125 | 130", value: "Riemmaten: 80 | 85 | 90 | 95 | 100 | 105 | 110 | 115 | 120 | 125 | 130" },
                  { title: "Schoenmaten 1: 36 | 37 | 38 | 39 | 40 | 41 | 42", value: "Schoenmaten 1: 36 | 37 | 38 | 39 | 40 | 41 | 42" },
                  { title: "Schoenmaten 2: 36 | 36.5 | 37 | 37.5 | 38 | 38.5 | 39 | 39.5 | 40 | 40.5 | 41 | 41.5 | 42", value: "Schoenmaten 2: 36 | 36.5 | 37 | 37.5 | 38 | 38.5 | 39 | 39.5 | 40 | 40.5 | 41 | 41.5 | 42" },
                  { title: "Duomaten: XS/S | S/M | M/L | L/XL", value: "Duomaten: XS/S | S/M | M/L | L/XL" },
                  { title: "32-34-36: 32 | 34 | 36 | 38 | 40 | 42", value: "32-34-36: 32 | 34 | 36 | 38 | 40 | 42" },
                ]}
                selectedValue={borderedSelectedOption}
                setSelectedValue={setBorderedSelectedOption}
            />         
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
          <ButtonBlue text="Toevoegen" onClickFunction={() => handleAddTemplate()} />
        </Modal.Footer>
      </Modal>

      <Modal
        id="modalBlueprint"
        show={showModalBlueprintNew}
        onHide={handleCloseBlueprintNew}
        centered
        size="xl"
        backdrop="static"
      >
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className="ms-2">Nieuwe Blueprint</Modal.Title>
          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={handleCloseBlueprintNew}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <form>
          <Modal.Body className="modalCenter justify-content-around pb-2">
            <BorderedTextInput
              className="optionsList optionListWidt text-center mt-4 w-100 border-black"
              placeholder="Blueprint naam"
              name="blueprint_name"
              inputValue={blueprintName}
              setInputValue={setBlueprintName}
              required={true}
            ></BorderedTextInput>
          </Modal.Body>
          <Modal.Footer className="modalBottom">
            <ButtonBlue text={"aanmaken"} onClickFunction={(e) => submitBlueprint(e)} className="btn bg-info mt-3 text-white"/>
          </Modal.Footer>
        </form>
      </Modal>

      <PageTemplate navbarTitle={"Blauwdrukken Overzicht"}  backValue={"/warehouse/instellingen"}>
        <div className="w-100">
          <div className="blueprintsOverview">
            <div className="tableBlueprints">
              <TablePreset 
                data={[{'selectedIds': selectedProductIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'blueprint_id'}]}
                tableLoading={loading}
                setPagination={setPagination} 
                handleSelection={handleProductSelection} 
                searchBar={[{ shown: true, searchFunction: handleSearchChange, }]}
                actionOptions={[{title: "Deactiveer", function: () => Action("suspend")}, {title: "Selecteer alles", function: () => Action("select-all")}, {title: "De-selecteer alles", function: () => Action("unselect-all")}]} // actionOptions={[{title: "selecteer alles", function: () => console.log(true)}]}
                // leftButtons={[{title: "a", function: () => console.log('a')}, {title: "b", function: () => console.log('b')}]} 
                rightButtons={[{title: "Handmatig aanmaken", function: () => setShowModalBlueprintNew(true)}, {title: "Templates", function: () => handleShowBlueprint()}]} // rightButtons={[{title: "c", function: () => console.log('c')}]}
              />
            </div>
          </div>
        </div>
      </PageTemplate>
    </>
  );
}

export default Blueprints;
