import React, { useEffect, useState } from "react";
import PageTemplate from "../../Templates/PageTemplate";
import { Tab, Tabs } from "react-bootstrap";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router";
import { BackButton, ButtonBlue } from "../../HouseStyle/Components/Buttons";
import "./CompanyInfo.css";
import middleware from "../../Api/Middleware";
import GeneralInfo from "./Tabs/GeneralInfo";
import HouseStyle from "./Tabs/HouseStyle";
import FacturizationDetails from "./Tabs/FacturizationDetails";
import Policies from "./Tabs/Policies";

const CompanyInfo = () => {
    //loading
    const [loading, setLoading] = useState(true);
    // Navigation setup
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [key, setKey] = useState(() => {
        return cookies.get('activeTabKey') || "tab1";
    });


    const navigatetabs = () => {
        switch (key) {
            case "tab1":
                navigate("./algemeen");
                break;
            case "tab2":
                navigate("./huisstijl");
                break;
            case "tab3":
                navigate("./facturatie");
                break;
            case "tab4":
                navigate("./voorwaarden");
                break;
        }

    }
    // Company info code
    const [companyInfo, setCompanyInfo] = useState({});

    const fetchCompanyInfo = async () => {
        const settings = await middleware.get("/settings/backoffice?setting_id=1",);

        setCompanyInfo(settings.data.data);

        setLoading(false);
    }

    useEffect(() => {
        fetchCompanyInfo();
    }, [])

    // Tabs for navigation
    const tabs = [
        {
            eventKey: "tab1",
            title: "Algemene informatie",
            navbarTitle: "Algemene informatie",
            content: (key == "tab1" && <GeneralInfo companyInfo={companyInfo} />),
        },
        {
            eventKey: "tab2",
            title: "Huisstijl",
            navbarTitle: "Huisstijl",
            content: (key == "tab2" && <HouseStyle companyInfo={companyInfo} />),
        },
        {
            eventKey: "tab3",
            title: "Facturatie gegevens",
            navbarTitle: "Facturatie gegevens",
            content: (key == "tab3" && <FacturizationDetails companyInfo={companyInfo} />),
        },
        {
            eventKey: "tab4",
            title: "Voorwaarden",
            navbarTitle: "Voorwaarden",
            content: (key == "tab4" && <Policies companyInfo={companyInfo} />),
        },

    ];


    return <PageTemplate navbarTitle={"Bedrijfsinformatie"} pageIsLoading={loading}>
        <div className="d-flex w-100 flex-column">
            <div className="d-flex mb-4 mt-4 justify-content-between">
                <BackButton size="l" text={"Terug"} onClickFunction={() => navigate("/algemene/instellingen")} />
                <div>
                    <ButtonBlue size="m" text={'Bewerken'} onClickFunction={() => navigatetabs()} />
                </div>
            </div>
            <div className='col-12'>
                <div className="tabContainer whiteBox companyInfoTabs px-4 tabsOverviewPadding col-12 justify-content-start">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => {
                            setKey(k);
                            const tab = tabs.find((t) => t.eventKey === k);
                            cookies.remove('activeTabKey');
                            cookies.set('activeTabKey', tab.eventKey, { maxAge: 5400 });
                        }}
                    >
                        {tabs.map((tab) => (
                            <Tab
                                key={tab.eventKey}
                                eventKey={tab.eventKey}
                                title={
                                    <span
                                        className={`tab-title ${key === tab.eventKey ? "active" : ""} fw-semibold`}
                                    >
                                        {tab.title}
                                    </span>
                                }
                                disabled={tab.disabled}
                            >
                                <div key={tab.eventKey} className="tabContent">{tab.content}</div>
                            </Tab>
                        ))}
                    </Tabs>
                </div>
            </div>
        </div>
    </PageTemplate>
}

export default CompanyInfo;