import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./Giftcard.css";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import PageTemplate from "../../Templates/PageTemplate";
import VATModals from "../../Modals/VATModal";
import TablePreset from "../../Table/TablePreset";
import { useTable } from "react-table";
import SortableHeader from "../../Table/TableComponents/SortableHeader";
import CheckboxCell from "../../Table/TableComponents/CheckboxCell";
import CheckboxHeader from "../../Table/TableComponents/CheckboxHeader";
import { BackButton } from "../../HouseStyle/Components/Buttons";
import { ButtonBlue } from "../../HouseStyle/Components/Buttons";
import middleware from "../../Api/Middleware";
import ToastError from "../../Toasts/ToastError";
import ConfirmationDeactivationModal from "../../Modals/GeneralSettings/ConfirmationDeactivationModal";

function Giftcards() {
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({
        page_size: 10,
        page: 1,
        max_items: 0,
        begin_of_page: 0,
        end_of_page: 0,
    });
    const [giftcards, setGiftcards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingPage, setLoadingPage] = useState(false);
    const [show, setShow] = useState(false); //ConfirmationModal
    const [selectedIds, setSelectedIds] = useState([]);
    const [order, setOrder] = useState({ orderBy: '', direction: '' });
    const [searchBar, setSearchBar] = useState("");

    const fetchAll = async () => {
        try {
            const searchQuery = searchBar.trim() ? `&searchTerm=${encodeURIComponent(searchBar.trim())}&noSearch=["card_id"]` : "";
            const [fetchGiftcards] = await Promise.all([
                middleware.get(
                    `sp/giftcardsTableAll?page=${pagination.page}&page_size=${pagination.page_size}${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}` : ''}${searchQuery}`),
            ]);
            setGiftcards(fetchGiftcards.data.data);
            setPagination({
                ...pagination,
                max_items: fetchGiftcards.data.total,
                begin_of_page: fetchGiftcards.data.from,
                end_of_page: fetchGiftcards.data.to,
            });
        } catch {
            ToastError('Fout bij het ophalen van de data');
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchAll()
    }, [pagination.page_size, pagination.page, order, searchBar]);


    const handleSearchChange = useCallback((value) => {
        setSearchBar(value);
        setPagination(prev => ({ ...prev, page: 1 }));
    }, []);

    const deactivateGiftcard = async () => {
        try {
            const promises = selectedIds.map(id =>
                middleware.put(`giftcards`, {
                    card_id: id,
                    status: 0,
                }));
            await Promise.all(promises);
            setSelectedIds([]);
            fetchAll();
        } catch (error) {
            console.error("Error deactivating giftcards:", error);
        }
    };

    const columns = useMemo(
        () => {
            let columnsArray = [
                {
                    Header: (
                        <CheckboxHeader currentItem={giftcards} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'card_id'}>
                        </CheckboxHeader>
                    ),
                    accessor: "select",
                    Cell: ({ row }) => {
                        return <CheckboxCell row={row} selectedIds={selectedIds} pk={'card_id'} handleSelection={handleSelection} />
                    }
                },
                {
                    Header: () => (
                        <SortableHeader currentItem={order} setItem={setOrder} field="code" pos={'start'}>
                            Code
                        </SortableHeader>
                    ),
                    accessor: "code",
                    Cell: ({ row }) => {
                        return <div className="text-start FlatTextLink" onClick={() => navigate(`/algemene/instellingen/cadeaukaarten/bewerken/${row.original.card_id}`)}>{row.original.code}</div>;
                    }
                },
                {
                    Header: () => (
                        <SortableHeader currentItem={order} setItem={setOrder} field="saldo" pos={'start'}>
                            Saldo
                        </SortableHeader>
                    ),
                    accessor: "saldo",
                    Cell: ({ row }) => {
                        return <div className="text-start BlackText">€{row.original.saldo}</div>;
                    }
                },
                {
                    Header: () => (
                        <SortableHeader currentItem={order} setItem={setOrder} field="status" pos={'start'}>
                            Status
                        </SortableHeader>
                    ),
                    accessor: "status",
                    Cell: ({ row }) => {
                        return <div className="text-start BlackText">{row.original.status == "1" ? 'Uitgegeven' : 'Op voorraad'}</div>;
                    }
                },
            ];
            return columnsArray;
        },
        [order.orderBy, order.direction, selectedIds, setSelectedIds, giftcards]
    );

    //   Table instance
    const tableInstance = useTable({ columns, data: giftcards });

    const handleSelection = (card_id, isSelected) => {
        if (isSelected) {
            setSelectedIds(previous => [...previous, card_id]);
        } else {
            setSelectedIds(previous => previous.filter(id => id !== card_id));
        }
    };

    return (
        <>
            <PageTemplate navbarTitle={"Cadeaukaarten"} pageIsLoading={loadingPage}>
                <ConfirmationDeactivationModal showConfirmaton={show} setShowConfirmation={(e) => setShow(e)} selectedIds={selectedIds} handleDeativation={() => deactivateGiftcard()} />
                <div className="w-100 d-flex flex-column">
                    <div className="d-flex justify-content-between mb-4">
                        <BackButton text={"Terug"} disabled={false} onClickFunction={() => navigate("/algemene/instellingen")} />
                        <ButtonBlue text={"Toevoegen"} disabled={false} onClickFunction={() => navigate("/algemene/instellingen/cadeaukaarten/aanmaken")} />
                    </div>
                    <div className="w-100 d-flex flex-row justify-content-between mt-2 col-12">
                        <div className="card">
                            <p className="cardTitle mb-0">Openstaand Saldo</p>
                            <p className="cardText">€1028,99</p>
                        </div>
                        <div className="card">
                            <p className="cardTitle mb-0">Verkocht dit jaar</p>
                            <p className="cardText mb-0">€1028,99</p>
                            <p className="BlueText">(Vorige: €1403,60)</p>
                        </div>
                        <div className="card">
                            <p className="cardTitle mb-0">Ingewisseld dit jaar</p>
                            <p className="cardText mb-0">€1028,99</p>
                            <p className="BlueText">(Vorige: €1403,60)</p>
                        </div>
                        <div className="card">
                            <p className="cardTitle mb-0">Cadeaukaarten in omloop</p>
                            <p className="cardText">5</p>
                        </div>
                    </div>
                    <div className="w-100 mt-5">
                        <TablePreset
                            data={[{ 'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'card_id' }]}
                            tableLoading={loading}
                            setPagination={setPagination}
                            actionOptions={[{ title: "Deactiveren", function: () => setShow(true) }]}
                            searchBar={[{ shown: true, searchFunction: handleSearchChange }]}
                            handleSelection={handleSelection}
                        />
                    </div>
                </div>
            </PageTemplate>
        </>
    )
}

export default Giftcards;