import { toast } from 'react-toastify';

const ToastError = (text, id, timeOpen = 5000) => {
  const closeMultipleIds = ["SizeAndColorError", "imageVariation", "activateProduct"];
  let status = true;
  if (closeMultipleIds.includes(id)) {
    if (toast.isActive(id)) {
      status = false;
    }
  }
  if (status) {
    return (
      toast.error(text, {
        toastId: id,
        position: "top-right",
        autoClose: timeOpen,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        limit: 5,
        style: {
          top: '100px'
        },
      })
    )
  }
}

export default ToastError;