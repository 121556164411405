import React, { useEffect, useState } from "react";
import PageTemplate from "../../../Templates/PageTemplate";
import { useNavigate } from "react-router";
import Cookies from 'universal-cookie';
import middleware from "../../../Api/Middleware";
import { BackButton } from "../../../HouseStyle/Components/Buttons";
import NormalMediaUploader from "../../../MediaUpload/NormalMediaUpload/NormalMediaUpload";
import ToastSuccess from "../../../Toasts/ToastSuccess";

const Policies = () => {
    const [loading, setLoading] = useState(true);
    const [uploadedAV, setUploadedAV] = useState(null);
    const [uploadedPS, setUploadedPS] = useState(null);
    const [uploadedDisclamer, setUploadedDisclamer] = useState(null);
    // Navigation setup
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [companyInfoOld, setCompanyInfoOld] = useState({});
    const [companyInfo, setCompanyInfo] = useState({});

    const fetchCompanyInfo = async () => {
        const settings = await middleware.get("/settings/backoffice?setting_id=1",);

        setCompanyInfo(settings.data.data);
        setCompanyInfoOld(settings.data.data);
        setLoading(false);
    }

    useEffect(() => {
        if (JSON.stringify(companyInfo) === JSON.stringify(companyInfoOld)) return;
        UpdateCompanyInfo();
    }, [companyInfo]);

    const UpdateCompanyInfo = async () => {
        try {
          await middleware.put("/settings/backoffice", {
            setting_id: 1,
            general_condition: companyInfo.general_condition,
            privacy_statement: companyInfo.privacy_statement,
            disclaimer: companyInfo.disclaimer,
          });
          ToastSuccess('Company information updated successfully');
          // Update de oude state zodat de useEffect niet telkens triggert
          setCompanyInfoOld(companyInfo);
        } catch (error) {
          console.error('Error updating company information:', error);
        }
    };

    useEffect(() => {
        const handleFileUpload = (file, key) => {
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result;
                    setCompanyInfo((prev) => ({ ...prev, [key]: base64String }));
                };
                reader.readAsDataURL(file);
            }
        };
    
        if (uploadedAV?.file) handleFileUpload(uploadedAV.file, "general_condition");
        if (uploadedPS?.file) handleFileUpload(uploadedPS.file, "privacy_statement");
        if (uploadedDisclamer?.file) handleFileUpload(uploadedDisclamer.file, "disclaimer");
    
    }, [uploadedAV, uploadedPS, uploadedDisclamer]); 

    useEffect(() => {
        fetchCompanyInfo();
    }, [])

    return <PageTemplate pageIsLoading={loading} navbarTitle={"Bedrijfsinformatie"}>
        <div className="col-12 d-flex flex-column">
            <div className="d-flex mb-4 mt-4 justify-content-between">
                <BackButton size="l" text={"Terug"} onClickFunction={() => navigate("/algemene/instellingen/bedrijfsinformatie")} />
            </div>
            <div className="whiteBox px-5 py-4">
                <h2 className="fw-semibold">Voorwaarden aanpassen</h2>
                <div className="pdfInput">
                    <h6 className="fw-bold ms-4 py-2 pt-4">Algemene voorwaarden</h6>
                    <NormalMediaUploader imageValue={companyInfo.general_condition} onImageChange={setUploadedAV} allowedFormats={["application/pdf"]} imgPreview={true} />
                </div>
                <div className="pdfInput">
                    <h6 className="fw-bold ms-4 py-2 pt-4">Privacy statement</h6>
                    <NormalMediaUploader imageValue={companyInfo.privacy_statement} onImageChange={setUploadedPS} allowedFormats={["application/pdf"]} imgPreview={true} />
                </div>
                <div className="pdfInput">
                    <h6 className="fw-bold ms-4 py-2 pt-4">Disclaimer</h6>
                    <NormalMediaUploader imageValue={companyInfo.disclaimer || null} onImageChange={setUploadedDisclamer} allowedFormats={["application/pdf"]} imgPreview={true} />
                </div>
            </div>
        </div>
    </PageTemplate>
}

export default Policies;