import React from 'react';
import { Link } from 'react-router-dom';

const TDSTextNavigation = ({ text, link, extraClassNames, status = 1, openNewTab = false }) => {
  let extraAttributes = {};
  if (openNewTab) {
    extraAttributes = {
      target: "_blank",
      rel: "noopener noreferrer"
    }
  }

  return status ? (
    <Link
      to={link}
      className={`BlueText hover text-decoration-none ${extraClassNames}`}
      {...extraAttributes}
    >
      {text ?? "-"}
    </Link>
  ) : (
    <span className={`text-muted ${extraClassNames}`} style={{ cursor: 'not-allowed' }}>
      {text}
    </span>
  );
};

export default TDSTextNavigation;
