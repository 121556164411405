import React, { useState, useEffect, useRef } from "react";
import * as Icons from '../Icons';
import SizeClassCorrespondance from "./SizeClassCorrespondance";
import { hourglass } from 'ldrs';

hourglass.register();

export const RegularSearchBar = ({
  inputValue,
  setInputValue,
  placeholder = "Zoeken...",
  responseItems = [],
  columnHeaders = {},
  size = "regular",
  searchFunction,
  searchDelayTimer = 500,
  resultsLoading
}) => {
  const [internalValue, setInternalValue] = useState('');
  
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;

  const [isFocused, setIsFocused] = useState(false);
  const searchBarRef = useRef(null);
  const timerRef = useRef(null);
  const prevInputValue = useRef(value);
  const isUpdateCalled = useRef(false);

  const handleOutsideClick = (e) => {
    if (searchBarRef.current && !searchBarRef.current.contains(e.target)) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    if (isFocused) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [isFocused]);

  useEffect(() => {
    if (value !== prevInputValue.current) {
      clearTimeout(timerRef.current);
      isUpdateCalled.current = false;

      timerRef.current = setTimeout(() => {
        if (!isUpdateCalled.current && searchFunction) {
          searchFunction(value);
          isUpdateCalled.current = true;
        }
      }, searchDelayTimer);

      prevInputValue.current = value;
    }

    return () => clearTimeout(timerRef.current);
  }, [value, searchFunction]);

  const handleBlur = () => {
    if (!isUpdateCalled.current && searchFunction) {
      searchFunction(value);
      isUpdateCalled.current = true;
    }
    clearTimeout(timerRef.current);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div
      className={`RegularSearchbarContainer ${SizeClassCorrespondance[size]}`}
      ref={searchBarRef}
      style={{ position: "relative" }}
    >
      <div>
        <img
          src={Icons.MagnifyingGlassDark}
          alt="search"
          className='SearchBarIcon'
        />
        <input
          type="text"
          placeholder={placeholder}
          className="RegularSearchBar"
          onFocus={() => setIsFocused(true)}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      {value.length > 0 && (
        resultsLoading ? (
          <div className={`RegularSearchBarResponseList ${isFocused ? "open d-flex justify-content-center align-items-center py-4" : "closed"}`}>
            <l-hourglass size="40" bg-opacity="0.1" speed="1.5" color="gray"></l-hourglass>
          </div>
        ) : (
          responseItems.length > 0 && (
            <table
              className={`RegularSearchBarResponseList ${isFocused ? "open" : "closed"}`}
            >
              <thead>
                <tr>
                  {Object.entries(columnHeaders).map(([key, label]) => (
                    <th key={key}>{label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {responseItems.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.keys(columnHeaders).map((key) => (
                      <td key={key}>{item[key] || ''}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )
        )
      )}
    </div>
  );
};

export const BorderedSearchBar = ({
  inputValue,
  setInputValue,
  placeholder = "Zoeken...",
  responseItems = [],
  columnHeaders = {},
  size = "regular",
  searchFunction,
  searchDelayTimer = 500,
  resultsLoading,
  error = false
}) => {
  const [internalValue, setInternalValue] = useState('');
  
  const value = inputValue ?? internalValue;
  const setValue = setInputValue ?? setInternalValue;

  const [isFocused, setIsFocused] = useState(false);
  const searchBarRef = useRef(null);
  const timerRef = useRef(null);
  const prevInputValue = useRef(value);
  const isUpdateCalled = useRef(false);

  const handleOutsideClick = (e) => {
    if (searchBarRef.current && !searchBarRef.current.contains(e.target)) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    if (isFocused) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [isFocused]);

  useEffect(() => {
    if (value !== prevInputValue.current) {
      clearTimeout(timerRef.current);
      isUpdateCalled.current = false;

      timerRef.current = setTimeout(() => {
        if (!isUpdateCalled.current && searchFunction) {
          searchFunction(value);
          isUpdateCalled.current = true;
        }
      }, searchDelayTimer);

      prevInputValue.current = value;
    }

    return () => clearTimeout(timerRef.current);
  }, [value, searchFunction]);

  const handleBlur = () => {
    if (!isUpdateCalled.current && searchFunction) {
      searchFunction(value);
      isUpdateCalled.current = true;
    }
    clearTimeout(timerRef.current);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className={`BorderedSearchbarContainer ${SizeClassCorrespondance[size]}`} ref={searchBarRef} style={{ position: "relative" }}>
      <div>
        <img
          src={Icons.MagnifyingGlassDark}
          alt="search"
          className="SearchBarIcon"
        />
        <input
          type="text"
          placeholder={placeholder}
          className={`BorderedSearchBar  ${error && "error"}`}
          onFocus={() => setIsFocused(true)}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      {value.length > 0 && (
        resultsLoading ? (
          <div className={`BorderedSearchBarResponseList ${isFocused ? "open d-flex justify-content-center align-items-center py-4" : "closed"}`}>
            <l-hourglass size="40" bg-opacity="0.1" speed="1.5" color="gray"></l-hourglass>
          </div>
        ) : (
          responseItems.length > 0 && (
            <table className={`BorderedSearchBarResponseList ${isFocused && responseItems?.length > 0 ? "open" : "closed"}`}>
              <thead>
                <tr>
                  {Object.entries(columnHeaders).map(([key, label]) => (
                    <th className="LightGreyBorder" key={key}>{label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {responseItems.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.keys(columnHeaders).map((key, index) => (
                      <td className="LightGreyBorder" key={index}>{item[key] || ""}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )
        )
      )}
    </div>
  );
};
