import React from 'react';
import { hourglass } from 'ldrs'

const LoadingIcon = ({color = "#009FE3", size = 80}) => {
  hourglass.register()
  return (
    <div>
      <l-hourglass
        size={size}
        bg-opacity="0.1"
        speed="1.75"
        color={color}
      ></l-hourglass>
    </div>
  );
};

export default LoadingIcon;