import { useEffect, useState } from "react";
import PageTemplate from "../../Templates/PageTemplate";
import "./ShowProfile.css"
import middleware from "../../Api/Middleware";
import Cookies from "universal-cookie";
import { ButtonBlue } from "../../HouseStyle/Components/Buttons";
import { useNavigate } from "react-router";

const ShowProfile = () => {
    const navigate = useNavigate();
    const cookies = new Cookies;
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState([]);
    const [language, setlanguage] = useState("");

    const fetchAll = async () => {
        try {
            setLoading(true);
            const [fetchUserRes, pfpRes] = await Promise.all([
                middleware.get(`users?api_token=${cookies.get("token")}`),
                middleware.get(`profilepicture`),
            ]);

            const userData = {
                ...fetchUserRes.data.data[0],
                profile_picture: pfpRes.data.profile_picture_url,
            };
            setUser(userData);
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        fetchAll();
    }, []);

    useEffect(() => {
        setlanguage(parseLanguage());
    }, [user]);

    const parseLanguage = () => {
        if (user.language === "NL") {
            return "Nederlands";
        } else if (user.language === "EN") {
            return "Engels";
        } else if (user.language === "DE") {
            return "Duits";
        } else if (user.language === "FR") {
            return "Frans";
        }
    }

    console.log(user)

    return (
        <PageTemplate navbarTitle={"Mijn profiel"} pageIsLoading={loading} backValue={-1}>
            <div className="d-flex flex-column gap-4 w-100">
                <div className="d-flex justify-content-end">
                    <ButtonBlue text={"Profiel bewerken"} onClickFunction={() => navigate("/profiel/bewerken")} />
                </div>
                <div className="whiteBox px-4 py-5">
                    <div className="d-flex flex-column">
                        <h4 className="text-subTitle">Algemene informatie</h4>
                        <div className="infoData">
                            <div>Voornaam</div>
                            <div>{user.first_name}</div>

                            <div>Achternaam</div>
                            <div>{user.last_name}</div>

                            <div>Taal</div>
                            <div>{language}</div>
                        </div>
                    </div>
                </div>
                <div className="whiteBox px-4 py-5">
                    <div className="d-flex flex-column">
                        <h4 className="text-subTitle">Contactinformatie</h4>
                        <div className="infoData">
                            <div>E-mail</div>
                            <div>{user.email}</div>
                        </div>
                    </div>
                </div>
            </div>
        </PageTemplate>
    )
}

export default ShowProfile;