import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PageTemplate from '../Templates/PageTemplate'
import TablePreset from '../Table/TablePreset';
import middleware from '../Api/Middleware';
import CheckboxCell from '../Table/TableComponents/CheckboxCell';
import CheckboxHeader from '../Table/TableComponents/CheckboxHeader';
import SortableHeader from '../Table/TableComponents/SortableHeader';
import TDSTextNavigation from "../Templates/TDSTextNavigation";
import { useTable } from 'react-table';

const Shipping = () => {
    const [loadingPage, setLoadingPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [shipments, setShipments] = useState([]);
    const [searchBar, setSearchBar] = useState("");
    const [pagination, setPagination] = useState({
        "page_size": 10,
        "page": 1,
        "max_items": 0,
        "begin_of_page": 0,
        "end_of_page": 0,
    });
    const [order, setOrder] = useState({
        orderBy: "",
        direction: ""
    })

    const fetchAll = async () => {
        setLoading(true);
        try {
            const searchQuery = searchBar.trim() ? `&searchTerm=${encodeURIComponent(searchBar.trim())}` : "";
            const [shippingRes] = await Promise.all([
                middleware.get(`sp/shipmentsTableAll?page=${pagination.page}&page_size=${pagination.page_size}${order.direction !== '' ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}` : ""}${searchQuery}`)
            ]);
            setShipments(shippingRes.data.data);
            setPagination({
                ...pagination,
                max_items: shippingRes.data.total,
                begin_of_page: shippingRes.data.from,
                end_of_page: shippingRes.data.to,
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAll();
    }, [order, pagination.page, pagination.page_size, searchBar]);

    const handleSearchChange = useCallback((value) => {
        setSearchBar(value);
        setPagination(prev => ({ ...prev, page: 1 }));
    }, []);

    //columns for the table
    const columns = useMemo(
        () => {
        let columnsArray = [
            {
            Header: (
                <CheckboxHeader currentItem={shipments} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'shipment_id'}>
                </CheckboxHeader>
            ),
            accessor: "select",
            Cell: ({ row }) => {
                return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'shipment_id'} />
            }
            },
            {
                Header: () => (
                    <SortableHeader currentItem={order} setItem={setOrder} field={'shipment_number'} pos={'center'}>
                    Verzending
                    </SortableHeader>
                ),
                accessor: 'shipment_number',
                Cell: ({ row }) => {
                    return (
                        <div className='d-flex flex-column text-center'>
                            <TDSTextNavigation text={row.original.shipment_number} link={`${row.original.shipment_id}`}/>
                            <div>{row.original.internal_id}</div>
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <SortableHeader currentItem={order} setItem={setOrder} field={'order_number'} pos={'start'}>
                    Ordernummer
                    </SortableHeader>
                ),
                accessor: 'order_number',
                Cell: ({ row }) => {
                    return (
                        row.original.order_number
                    )
                },
            },
            {
                Header: () => (
                    <SortableHeader currentItem={order} setItem={setOrder} field={'packages_count'} pos={'start'}>
                    Pakketjes
                    </SortableHeader>
                ),
                accessor: 'packages_count',
                Cell: ({ row }) => {
                    return (
                        row.original.packages_count
                    )
                },
            },
            {
                Header: () => (
                    <SortableHeader currentItem={order} setItem={setOrder} field={'created_at'} pos={'start'}>
                    Aangemaakt op
                    </SortableHeader>
                ),
                accessor: 'created_at',
                Cell: ({ row }) => {
                    return (
                        row.original.created_at
                    )
                },
            },
        ];
        return columnsArray;
        },
        [setOrder, order.orderBy, order.direction, selectedIds, setSelectedIds, shipments]
    );


    const handleSelection = (shippingId, isSelected) => {
        if (isSelected) {
            setSelectedIds(previous => [...previous, shippingId]);
        } else {
            setSelectedIds(previous => previous.filter(id => id !== shippingId));
        }
    };

    const tableInstance = useTable({ columns, data: shipments || [] });

    return (
        <PageTemplate navbarTitle={"Shipping HUB"} pageIsLoading={loadingPage}>
            <div className="productOverview">
                <div className="productTable w-100">
                    <TablePreset
                        data={[{ 'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination }]}
                        tableLoading={loading}
                        setPagination={(e) => setPagination(e)}
                        handleSelection={handleSelection}
                        primary_key={'shipment_id'}
                        searchBar={[{ shown: true, searchFunction: handleSearchChange, placeholder: "Zoeken op verzending, ordernummer, etc." }]}
                        actionOptions={[{"title": "De-activeer alle geselecteerde"}]}
                    />
                </div>
            </div>
        </PageTemplate>
    )
}

export default Shipping