import React, { useEffect, useRef, useState } from 'react';
import PageTemplate from '../Templates/PageTemplate';
import { useNavigate, useParams } from 'react-router';
import * as Icons from "../HouseStyle/Icons";
import middleware from '../Api/Middleware';
import ToastWarning from '../Toasts/ToastWarning';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import countries from 'i18n-iso-countries';
import ToastError from '../Toasts/ToastError';
import MailIcon from './Icons/mail.png';
import PhoneIcon from './Icons/telephone.png';
import TruckIcon from './Icons/delivery-truck.png';
import PaperIcon from './Icons/paper-white.png';
import placeholder from './Icons/empty-placeholder.jpg';
import deliveryTruckBlue from './Icons/delivery-truck-blue.png';
import Backbutton from "../Products/subpages/images/BackIcon.svg";
import ToastInfo from '../Toasts/ToastInfo';
import CurrencyInput from 'react-currency-input-field';
import { BorderedDropDown } from '../HouseStyle/Components/DropDowns';
import { BorderedSquareMoneyInput } from '../HouseStyle/Components/Inputs';


countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

const CancelOrder = () => {
    const { order_number } = useParams();

    const navigate = useNavigate();
    const [pageIsLoading, setPageIsLoading] = useState(true);

    const [soldProducts, setSoldProducts] = useState([]);
    const [order, setOrder] = useState([]);

    const fetchAll = async () => {
        const ordersResponse = await middleware.get(`orders/fullorder?order_number=${order_number}`);
        if (!ordersResponse.data) {
            navigate(`/bestellingen/bewerken/${order_number}`);
            return;
        }

        setOrder(ordersResponse.data);
        setSoldProducts(ordersResponse.data.orders_sold_products);
        if (!ordersResponse.data.orders_sold_products.find((e) => e.amount > e.items_credited)) {
            ToastInfo("Alle producten zijn al gecrediteerd")
            navigate("/bestellingen/overzicht/" + order_number)
        }
        setPageIsLoading(false);
    }
    const [cancelProcessedProducts, setCancelProcessedProducts] = useState([]);
    const [cancelUnProcessedProducts, setCancelUnProcessedProducts] = useState([]);
    const [cancelExtraProducts, setCancelExtraProducts] = useState([]);
    const [productRestitution, setProductRestitution] = useState(0);
    const [restitutionCost, setRestitutionCost] = useState(0);
    const [extracost, setExtraCost] = useState(0);
    const [extra, setExtra] = useState(0);
    const [cancelReason, setCancelReason] = useState(undefined);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        if (extra == 1) {
            if (order?.delivery_cost != null) {
                setExtraCost(order.delivery_cost);
            }
        } else {
            setExtraCost(0);
        }
    }, [order, extra]);

    const changeProcessedProductCancelValue = (event, productToProcces, field) => {
        const price = productToProcces.sale_price_incl_tax == null ? productToProcces.price_incl_tax : productToProcces.sale_price_incl_tax;

        let value = event?.target?.value ?? event;
        if (field == "addToStock") {
            value = event.target.checked
        }
        else if (value == '') {
            value = 0;
            value = parseFloat(value);
        }

        let max;
        if (field == 'value') {
            max = productToProcces.items_processed;
        } else if (field == 'restitution') {
            max = Number(price * cancelProcessedProducts[productToProcces.sold_product_id].value ?? 1).toFixed(2);
        }

        if (value > max) {
            let updated = { ...cancelProcessedProducts[productToProcces.sold_product_id] }
            if (field == "value") {
                updated['restitution'] = Number(max * price).toFixed(2);
            }
            updated[field] = max;

            setCancelProcessedProducts({ ...cancelProcessedProducts, [productToProcces.sold_product_id]: updated });
        } else {
            let updated = { ...cancelProcessedProducts[productToProcces.sold_product_id] }
            if (field == "value") {
                updated['restitution'] = Number(value * price).toFixed(2);
            }
            updated[field] = value;

            setCancelProcessedProducts({ ...cancelProcessedProducts, [productToProcces.sold_product_id]: updated });
        }
    }


    const changeUnProcessedProductCancelValue = (event, productToProcces, field) => {
        let value = event?.target?.value ?? event;
        if (field == "addToStock") {
            value = event.target.checked
        }
        const price = productToProcces.sale_price_incl_tax == null ? productToProcces.price_incl_tax : productToProcces.sale_price_incl_tax;

        let max;
        if (field == 'value') {
            max = productToProcces.amount - productToProcces.items_processed - productToProcces.items_credited;
        } else if (field == 'restitution') {
            max = Number(price * cancelUnProcessedProducts[productToProcces.sold_product_id].value ?? 1).toFixed(2);
        }
        if (Number(parseFloat(value)) > max) {
            let updated = { ...cancelUnProcessedProducts[productToProcces.sold_product_id] }
            updated[field] = max;
            if (field == "value") {
                updated['restitution'] = Number(max * price).toFixed(2);
            }
            setCancelUnProcessedProducts({ ...cancelUnProcessedProducts, [productToProcces.sold_product_id]: updated });
        } else {
            let updated = { ...cancelUnProcessedProducts[productToProcces.sold_product_id] }
            updated[field] = value;
            if (field == "value") {
                updated['restitution'] = Number(value * price).toFixed(2);
            }
            setCancelUnProcessedProducts({ ...cancelUnProcessedProducts, [productToProcces.sold_product_id]: updated });
        }
    }


    const handleSelectAllChange = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);

        // Werk alle producten bij om 'addToStock' bij te werken naar 'checked' status
        setCancelProcessedProducts(prev => {
            const updatedProducts = { ...prev };
            Object.keys(updatedProducts).forEach(productId => {
            if (updatedProducts[productId].value > 0) {
                updatedProducts[productId].addToStock = checked;
            }
            });
            return updatedProducts;
        });

        setCancelUnProcessedProducts(prev => {
            const updatedProducts = { ...prev };
            Object.keys(updatedProducts).forEach(productId => {
            if (updatedProducts[productId].value > 0) {
                updatedProducts[productId].addToStock = checked;
            }
            });
            return updatedProducts;
        });

        setCancelExtraProducts(prev => {
            const updatedProducts = { ...prev };
            Object.keys(updatedProducts).forEach(productId => {
                updatedProducts[productId].addToStock = checked;
            });
            return updatedProducts;
        });
    };


    // const changeExtraProductCancelValue = (event, productToProcces, field) => {
    //     let value = event.target.value;
    //     if (value == '') {
    //         value = 0;
    //     }

    //     value = parseFloat(value);

    //     let max;
    //     if (field == 'value') {
    //         max = productToProcces.amount - productToProcces.items_processed - productToProcces.items_credited;
    //     } else if (field == 'restitution') {
    //         max = Number(productToProcces.price_incl_tax * cancelExtraProducts[productToProcces.sold_product_id].value).toFixed(2);
    //     }

    //     if (value > max) {
    //         let updated = { ...cancelExtraProducts[productToProcces.sold_product_id] }
    //         updated[field] = max;
    //         setCancelExtraProducts({ ...cancelExtraProducts, [productToProcces.sold_product_id]: updated });
    //     } else {
    //         let updated = { ...cancelExtraProducts[productToProcces.sold_product_id] }
    //         updated[field] = value;
    //         setCancelExtraProducts({ ...cancelExtraProducts, [productToProcces.sold_product_id]: updated });
    //     }
    // }
    useEffect(() => {
        fetchAll();

    }, [])
    
    const calculateProductRestitution = () => {
        let total = 0;
        Object.entries(cancelUnProcessedProducts).forEach((e) => {
            total += Number(e[1].restitution ?? 0);
        })
        Object.entries(cancelProcessedProducts).forEach((e) => {
            total += Number(e[1].restitution ?? 0);
        })
        Object.entries(cancelExtraProducts).forEach((e) => {
            total += Number(e[1].restitution ?? 0);
        })

        if (isNaN(total)) {
            total = 0;
        }
        console.log('Total restitution:', total);

        setProductRestitution(total + Number(extracost));
        return total;
    }

    const cancelOrder = async () => {
        let t = [];
        t.push(
            ...Object.entries(cancelUnProcessedProducts)
              .filter(([key, val]) => val.value > 0)
              .map(([key, val]) => ({
                amount: val.value,
                sold_product_id: key,
                restitution: val.restitution,
                addToStock: val.addToStock ? 1 : 0
              }))
          );

          t.push(
            ...Object.entries(cancelProcessedProducts)
              .filter(([key, val]) => val.value > 0)
              .map(([key, val]) => ({
                amount: val.value,
                sold_product_id: key,
                restitution: val.restitution,
                addToStock: val.addToStock ? 1 : 0,
                type: 'processed'
              }))
          );

          t.push(
            ...Object.entries(cancelExtraProducts)
              .filter(([key, val]) => val.value > 0)
              .map(([key, val]) => ({
                amount: val.value,
                restitution: val.restitution,
                addToStock: val.addToStock ? 1 : 0
              }))
          );

        const res = await middleware.post("/orders/addcreditation", {
            order_id: order.order_id,
            index: 1,
            restitution_total: calculateProductRestitution() - parseFloat(restitutionCost),
            creditation_products: t
        })

        navigate("/bestellingen/overzicht/" + order_number)
    }

    useEffect(() => {
        if (cancelExtraProducts, cancelProcessedProducts, cancelUnProcessedProducts) {
            calculateProductRestitution();
        }
    }, [cancelExtraProducts, cancelProcessedProducts, cancelUnProcessedProducts, extracost, restitutionCost])

    return (
        <PageTemplate pageIsLoading={pageIsLoading} navbarTitle={"Order HUB"}>
            <div className='w-100'>
                <div className="d-flex flex-row mb-4 pb-3 pt-5 justify-content-between mx-4">
                    <div role='button' className="d-flex flex-row align-items-center cursorPointer" onClick={() => navigate(`/bestellingen/overzicht/${order_number}`)}>
                        <img style={{ width: "32px" }} className="me-3" src={Backbutton} alt="Back" />
                        <p className="mb-0 fs-5">Annuleren bestelling #{order.order_number}</p>
                    </div>
                </div>
                <div className='row pe-3'>
                    <div className='d-flex flex-column col-xxl-8 col-12'>
                        {soldProducts.find((e) => e.items_processed + e.items_credited < e.amount) &&
                            <div className='whiteBox mb-4 me-2 p-4 pb-5'>
                                <h5 className='fw-semibold'>Niet verwerkt</h5>
                                <div className=''>
                                    <div>
                                        <table className='w-100 border-0 mb-0'>
                                            <thead className='bottom-border-gray'>
                                                <tr className='ps-0'>
                                                    <th className='ps-0'>Producten</th>
                                                    <th className='text-center text-wrap'>Aantal</th>
                                                    <th className='text-center text-wrap'>Restitutie bedrag</th>
                                                    <th className='text-center text-wrap'>Voorraad toevoegen</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {soldProducts.map((product, index) => {
                                                    if (product.items_processed < product.amount) {

                                                        return (
                                                            <tr key={index} className='bottom-border-gray'>
                                                                <td className="text-start w-75 ps-0">
                                                                    <div className='d-flex align-items-center linkColor'>
                                                                        <div className='pe-4'>
                                                                            <img src={placeholder} className="rounded-circle" height={80} width={80} alt="placeholder..." />
                                                                        </div>
                                                                        {product.product_name}

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex justify-content-center'>
                                                                        <CurrencyInput
                                                                            step="1"
                                                                            className='stockInput standardInput fw-semibold  py-2 text-center'
                                                                            placeholder="-"
                                                                            allowDecimals={false}
                                                                            allowNegativeValue={false}
                                                                            min={0}
                                                                            onValueChange={(event) => changeUnProcessedProductCancelValue(event, product, "value")}
                                                                            value={cancelUnProcessedProducts[product.sold_product_id]?.value ?? ''}
                                                                        />
                                                                        <div className='stockInput3 standardInput py-2'>van {product.amount - product.items_processed - product.items_credited}</div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex justify-content-center'>
                                                                        <input disabled={
                                                                            (cancelUnProcessedProducts[product.sold_product_id]?.value ?? 0) == 0}
                                                                            value={cancelUnProcessedProducts[product.sold_product_id]?.restitution}
                                                                            onChange={(event) => changeProcessedProductCancelValue(event, product, "restitution")}
                                                                            type='number'
                                                                            placeholder='€ 0,00'
                                                                            className='standardInput fw-semibold squareInput packagesInput fit-content text-nowrap text-center py-2 px-2'
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex justify-content-center'>
                                                                        <label className="checkbox-container">
                                                                            <input
                                                                                type='checkbox'
                                                                                checked={cancelUnProcessedProducts[product.sold_product_id]?.addToStock ?? false}
                                                                                onChange={(event) => changeUnProcessedProductCancelValue(event, product, "addToStock")}
                                                                            />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                            </tr>)
                                                    }
                                                }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {soldProducts.find((e) => e.items_processed > 0) &&
                            <div className='whiteBox mb-4 me-2 p-4 pb-5'>
                                <h5 className='fw-semibold'>Verwerkt</h5>
                                <div className=''>
                                    <div>
                                        <table className='w-100 border-0 mb-0'>
                                            <thead className='bottom-border-gray'>
                                                <tr>
                                                    <th className='p-0'>Producten</th>
                                                    <th className='text-center text-wrap'>Aantal</th>
                                                    <th className='text-center text-wrap'>Restitutie bedrag</th>
                                                    <th className='text-center text-wrap'>Voorraad toevoegen</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {soldProducts.map((product, index) => {
                                                    if (product.items_processed) {
                                                        return (
                                                            <tr key={index} className='bottom-border-gray'>
                                                                <td className="text-start w-75 ps-0">
                                                                    <div className='d-flex align-items-center linkColor'>
                                                                        <div className='pe-4'>
                                                                            <img src={placeholder} className="rounded-circle" height={80} width={80} alt="placeholder..." />
                                                                        </div>
                                                                        {product.product_name}

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex justify-content-center'>
                                                                        <CurrencyInput
                                                                            step="1"
                                                                            className='stockInput standardInput fw-semibold  py-2 text-center'
                                                                            placeholder="-"
                                                                            allowDecimals={false}
                                                                            allowNegativeValue={false}
                                                                            min={0}
                                                                            onValueChange={(event) => changeProcessedProductCancelValue(event, product, "value")}
                                                                            value={cancelProcessedProducts[product.sold_product_id]?.value ?? ''}
                                                                        />
                                                                        <div className='stockInput3 standardInput py-2'>van {product.items_processed}</div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex justify-content-center'>
                                                                        <input disabled={
                                                                            (cancelProcessedProducts[product.sold_product_id]?.value ?? 0) == 0}
                                                                            value={cancelProcessedProducts[product.sold_product_id]?.restitution}
                                                                            onChange={(event) => changeProcessedProductCancelValue(event, product, "restitution")}
                                                                            type='number'
                                                                            placeholder='€ 0,00'
                                                                            className='standardInput fw-semibold squareInput packagesInput fit-content text-nowrap text-center py-2 px-2'
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='d-flex justify-content-center'>
                                                                        <label className="checkbox-container">
                                                                            <label className="checkbox-container">
                                                                                <input
                                                                                    type='checkbox'
                                                                                    checked={cancelProcessedProducts[product.sold_product_id]?.addToStock ?? false}
                                                                                    onChange={(event) => changeProcessedProductCancelValue(event, product, "addToStock")}
                                                                                />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                            </tr>)
                                                    }
                                                }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='whiteBox mb-4 me-2 p-4 h-100'>
                            <h5 className='fw-semibold'>Extras</h5>
                            <table className='w-100 border-0 mb-0'>
                                <thead className='bottom-border-gray'>
                                    <tr>
                                        <th className='p-0'>Producten</th>
                                        <th className='text-center text-wrap'>Aantal</th>
                                        <th className='text-center text-wrap'>Restitutie bedrag</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr className='bottom-border-gray'>
                                        <td className="text-start w-75 ps-0">
                                            <div className='d-flex align-items-center linkColor'>
                                                <div className='pe-4'>
                                                    <img src={deliveryTruckBlue} className="p-2 ps-0 pe-5" height={80} alt="placeholder..." />
                                                    <span>Verzendkosten</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center'>
                                                <CurrencyInput
                                                    step="1"
                                                    className='stockInput standardInput fw-semibold  py-2 text-center'
                                                    placeholder="-"
                                                    allowDecimals={false}
                                                    allowNegativeValue={false}
                                                    min={0}
                                                    maxLength={1}
                                                    value={extra}
                                                    onValueChange={(e) => setExtra(e)}
                                                />
                                                <div className='stockInput3 standardInput py-2'>van 1</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center'>
                                                <input
                                                    disabled={true}
                                                    type='number'
                                                    placeholder='€ 0,00'
                                                    value={extracost}
                                                    className='standardInput fw-semibold squareInput packagesInput fit-content text-nowrap text-center py-2 px-2'
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='whiteBox mb-4 me-2 p-4 h-100'>
                            <h5 className='fw-semibold pb-4'>Annuleringsreden</h5>
                            <BorderedDropDown selectedValue={cancelReason} setSelectedValue={setCancelReason} placeholder='Selecteer een annuleringsreden' options={[
                                { value: 1, title: "Beschadigd of defect bij ontvangst" },
                                { value: 2, title: "Foutieve maat of pasvorm" },
                                { value: 3, title: "Niet zoals beschreven" },
                                { value: 3, title: "Verlies van interesse of wijziging van voorkeur" },
                                { value: 4, title: "Verkeerde kleur of stijl" }]} />
                        </div>
                    </div>
                    <div className='d-flex col-xxl-4 col-7 h-100'>
                        <div className='whiteBox w-100 mb-4 me-3 p-4'>
                            <h5 className='fw-semibold pb-3'>Samenvatting</h5>
                            <div className='d-flex justify-content-between '>
                                <h6>
                                    Producten restitutie
                                </h6>
                                <h6>
                                    € {Number(productRestitution).toFixed(2)}
                                </h6>
                            </div>
                            <div className='d-flex justify-content-between align-items-center pb-4 bottom-border-gray'>
                                <h6 className='m-0'>
                                    Restitutiekosten
                                </h6>
                                <div className='d-flex align-items-center justify-content-end'>
                                    -
                                    <BorderedSquareMoneyInput
                                        size='m'
                                        restrictMinus={false}
                                        maxAmount={100}
                                        minAmount={0}
                                        inputValue={restitutionCost}
                                        setInputValue={setRestitutionCost}
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center pt-4 pb-4'>
                                <h6 className='m-0 fw-bold'>
                                    Totaal incl. BTW
                                </h6>
                                <h6 className='fw-bold'>
                                    € {Number(productRestitution - (isNaN(restitutionCost) ? 0 : restitutionCost)).toFixed(2)}
                                </h6>
                            </div>
                            <div className='d-flex justify-content-start align-items-center pb-4'>
                                <label className="checkbox-container">
                                    <input
                                        type='checkbox'
                                        checked={selectAll}
                                        onChange={handleSelectAllChange}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                                <h6 className='m-0 pt-1'>Alle producten aan voorraad toevoegen</h6>
                            </div>
                            <button className='lightBlueStandardButton' onClick={() => cancelOrder()}>Crediteren</button>
                        </div>
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

export default CancelOrder;