import React, { useEffect, useState } from 'react'
import PageTemplate from '../Templates/PageTemplate'
import Terminal from './Images/Terminal.png'
import './Payments.css';
import { ButtonBlue, ButtonDarkBlue } from '../HouseStyle/Components/Buttons';
import { useNavigate } from "react-router";
import middleware from '../Api/Middleware';

const Payments = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    
    const checkForOngoingOnboarding = async() => {
        try {
            const [onboardingcheckRes] = await Promise.all([
                middleware.get("payment/onboardings")
            ]);
            if(onboardingcheckRes.data.data.length > 0) {
                navigate("/betalingen/account/overzicht")
            }
            setLoading(false)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        checkForOngoingOnboarding();
    }, [])
    
    return (
        <PageTemplate navbarTitle={"Payment HUB"} pageIsLoading={loading}>
            <div className='payment-mainpage-maincontainer'>
                <div className='payment-mainpage-textcontainer-left'>
                    <img className='payment-mainpage-terminal-img' src={Terminal}/>
                </div>
                <div className='payment-mainpage-textcontainer-right'>
                    <h1 className='payment-mainpage-title'>Welkom bij de Payment HUB!</h1>
                    <p>Een Payment Hub is een centraal platform dat betalingsverkeer binnen een organisatie stroomlijnt. Het biedt één overzichtelijke plek voor alle inkomende en uitgaande betalingen, ongeacht het kanaal of de valuta.</p>
                    <p>Met automatisering, verhoogde efficiëntie en sterke beveiliging helpt een Payment Hub bedrijven kosten te besparen en te voldoen aan regelgeving.</p>
                    <div>
                        <ButtonBlue text={"Account aanmaken"} onClickFunction={(e) => {navigate("./account/nieuw")}}/>
                    </div>
                </div>
            </div>
        </PageTemplate>
    )
}

export default Payments
