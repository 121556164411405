import React, { useMemo, useCallback } from 'react';
import JoditEditor from 'jodit-react';

const TextEditor = React.memo(({ value, onChange }) => {
  // Memoize the config object so it's not recreated on every render
  const config = useMemo(() => ({
    readonly: false,
    placeholder: "",
    toolbarSticky: false,
    toolbarButtonSize: "medium",
    "buttons": "paragraph,|,brush,|,bold,italic,underline,strikethrough,|,link,source,|,ul,ol,|,fontsize",
    "buttonsMD": "paragraph,|,brush,|,bold,italic,underline,strikethrough,|,link,source,|,ul,ol,|,fontsize",
    "buttonsSM": "paragraph,|,brush,|,bold,italic,underline,strikethrough,|,link,source,|,ul,ol,|,fontsize",
    "buttonsXS": "paragraph,|,brush,|,bold,italic,underline,strikethrough,|,link,source,|,ul,ol,|,fontsize",
  }), []);

  // Memoize the onBlur handler
  const handleBlur = useCallback((newContent) => {
    onChange(newContent);
  }, [onChange]);

  return (
    <div className="jodit-editor text-break">
      <JoditEditor
        value={value}
        config={config}
        tabIndex={1}
        onBlur={handleBlur}
      />
    </div>
  );
});

export default TextEditor;
